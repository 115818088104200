import Super from "../../services/super";

export default {
  name: "Dashboard",

  created() {
    this.fetchGrowcifyCounts();
  },

  computed: {
    canAccess() {
      return (this.isSuperadmin() || this.amIGrowcifyEmployee()) && this.isGD;
    },

    isFullpage() {
      return ["new-business"].includes(this.$route.name);
    },

    isGD() {
      return this.$store.state.isGD;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    isSuperadmin() {
      return this.$store.state.userInfo.roles.includes("superadmin");
    },

    amIGrowcifyEmployee() {
      return this.$store.state.userInfo.roles.includes("growcify-employee");
    },

    fetchGrowcifyCounts() {
      return Super.getGrowcifyCounts(false)
        .then((res) => (this.$store.state.growcifyCounts = res.data))
        .catch((e) => {
          console.log(e);
          this.cfetching = false;
        });
    },
  },

  data() {
    return {
      isMini: true,
    };
  },
};

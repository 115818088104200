import _ from "underscore";
import moment from "moment";

import Store from "../../../services/store";
import Global from "../../../services/global";
import config from "../../../config";
import FileUpload from "../../../services/file-upload";
import i18n from "../../../services/i18n";
import router from "../../../router";

import AppColorPicker from "../../../components/app-color-picker/app-color-picker.vue";
import DomainInstructions from "../../../components/domain-instructions/domain-instructions.vue";
import OnboardingScreens from "../../../components/onboarding-screens/onboarding-screens.vue";
import GrowcifyAddons from "../../../components/growcify-addons/growcify-addons.vue";
import KYCDocuments from "../../../components/kyc-documents/kyc-documents.vue";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "Settings",

  // sockets: {
  //   subscriptionActivated(data = {}) {
  //     if (data && data.storeId == this._id && data.paymentId) this.processPayment(data.paymentId);
  //   }
  // },

  components: {
    AppColorPicker,
    DomainInstructions,
    OnboardingScreens,
    GrowcifyAddons,
    KYCDocuments,
  },

  created() {
    this._id = this.$route.params._id;
    if (!Store.doIHaveAdminAccess(this.$store) && !this.amIGrowcifyEmployee)
      return router.push("/business/" + this._id);

    //this.initPusher();
    this.processing = true;
    const p1 = this.fetchStore();
    const p2 = this.fetchStoreEnums();
    const p3 = this.fetchActiveLanguages();
    const p4 = this.fetchCommonEnums();
    const p5 = this.fetchActiveCountries();
    const p6 = this.fetchHeaderRequest();
    const p7 = this.fetchShipmentWallet();
    const p8 = this.fetchEmailConfig();
    const p9 = this.fetchPricingEnums();

    return Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9])
      .then(() => (this.processing = false))
      .catch(() => (this.processing = false));
  },

  watch: {
    "header.sender"(n) {
      if (n) this.header.sender = n.toUpperCase();
    },

    walletModified(n) {
      if (n) this.fetchShipmentWallet();
    },

    $route(to) {
      if (to.name === "store-settings-slug") {
        this.activeSetting = this.settingsMenuItems.find(
          (x) => x.slug == to.params.slug
        );
        if (to.params.slug == "sound-and-notifications") {
          this.$notification.requestPermission();
        }
        if (to.params.slug == "sms") {
          this.fetchSMSHistory(1);
        }
      } else {
        this.activeSetting = {};
      }
    },

    input: _.debounce(function(val) {
      if (
        val === this.lastInput ||
        !val ||
        val == this.selectedPlace.description
      )
        return;

      this.isLoading = true;
      return Store.searchPlaces(this._id, val)
        .then((res) => {
          this.places = res.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }, 500),

    selectedPlace(val) {
      if (!val || !val.placeId || !val.description) return;

      this.store.placeId = val.placeId;
      this.store.placeDescription = val.description;
      if (!this.store.address) this.store.address = val.description;
    },

    "store.country"(n) {
      if (n) this.fetchStatesByCountry(n);
    },

    "store.state"(n) {
      if (n) this.fetchCitiesByState(n);
    },

    shoptions: {
      handler(options) {
        if (!this.firstTime)
          this.fetchSMSHistory(
            options.page,
            options.itemsPerPage,
            this.shsearch
          );
      },
      deep: true,
    },

    shsearch: _.debounce(function(nv) {
      this.fetchSMSHistory(1, this.shoptions.itemsPerPage, nv);
    }, 500),
  },

  methods: {
    initPusher() {
      const channelName = this._id;
      const eventName = "subscriptionActivated";

      const pusher = this.$store.state.pusher;
      let channel = pusher.channel(channelName);
      if (!channel) channel = pusher.subscribe(channelName);

      if (
        this.$store.state.pusherBusinessEvents.includes(
          "dashboard:" + eventName
        )
      )
        return;
      this.$store.state.pusherBusinessEvents.push("dashboard:" + eventName);

      channel.bind(eventName, (data) => {
        if (
          this.$route.name == "store-settings" &&
          data &&
          data.storeId == this._id &&
          data.paymentId
        )
          this.processPayment(data.paymentId);
      });
    },

    fetchStore() {
      this.processing = true;
      return Store.getStoreSettings(this._id, false)
        .then((x) => {
          if (x.data && (!x.data.businessHours || !x.data.businessHours.length))
            x.data.businessHours = this.businessHours;

          this.store = x.data;
          this.orignalStore = JSON.parse(JSON.stringify(x.data));
          this.processing = false;
          this.mloadingdone = true;

          if (this.store.placeId) {
            this.selectedPlace = {
              placeId: this.store.placeId,
              description: this.store.placeDescription || this.store.address,
            };
          }

          if (!this.store.notificationSettings) {
            this.store.notificationSettings = {
              newOrder: { notification: false, sound: false },
            };
          }

          if (!this.store.referralSettings) {
            this.store.referralSettings = {
              referralEnabled: false,
              referredByDiscount: 0,
              referredToDiscount: 0,
            };
          }

          if (!this.store.whatsAppSettings) {
            this.store.whatsAppSettings = {
              orderUpdatesEnabled: false,
              chatAPIInstance: "",
              chatAPIToken: "",
            };
          }

          if (!this.store.webSettings) {
            this.store.webSettings = { webTitle: this.store.appName };
          }

          if (!this.store.purchaseSettings) {
            this.store.purchaseSettings = {
              showEmptySignatureBox: true,
            };
          }

          this.$store.state.activeStore.paid = this.store.paid;
          this.$store.state.activeStore.bypassDashboardAccess = this.store.bypassDashboardAccess;

          const isPE = [
            "growcify-pay-as-you-grow",
            "growcify-premium",
            "growcify-enterprise",
          ].includes(this.store.plan);
          if (
            [
              "buy-growcify-premium-singlevendor",
              "buy-growcify-premium",
              "buy-growcify-enterprise",
            ].includes(this.store.plan) ||
            this.store.bypassEmailAccess ||
            isPE
          ) {
            this.pushSetting("email");
          }

          if (
            ["buy-growcify-premium", "buy-growcify-enterprise"].includes(
              this.store.plan
            ) ||
            this.store.bypassMultivendorAccess ||
            isPE
          ) {
            this.pushSetting("multivendor");
          }

          if (this.store.bypassDomainSettings || isPE) {
            this.pushSetting("domain");
          }

          if (this.store.pickupDropAccess || isPE) {
            this.pushSetting("pickup-drop");
          }

          if (this.store.bypassWAIAccess) {
            this.pushSetting("whatsapp");
          }

          this.verifyRouting();
          if (
            this.$route.query.pay &&
            !this.isVendor &&
            (!this.store.paid || this.store.ownership === "rent")
          ) {
            this.pay();
            delete this.$route.query.pay;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    verifyRouting() {
      const slug = this.$route.params.slug;
      if (this.isVendor) this.resetSettingsMenu();
      if (slug) {
        if (!this.settingsMenuItems.some((x) => x.slug === slug))
          return this.$router.back();
        const as = this.settingsMenuItems.find((x) => x.slug == slug);
        if (as) this.activeSetting = as;
        if (slug === "sms") {
          if (this.$route.query.buy) this.creditsModal = true;
          this.fetchSMSHistory(1);
        }
      }
    },

    manageSettingSlugRedirection() {
      if (!this.store.paid) return this.pay();
    },

    pushSetting(setting) {
      if (setting == "whatsapp") {
        this.settingsMenuItems.splice(3, 0, {
          title: "WhatsApp Settings",
          description: "Manage your WhatsApp settings",
          slug: "whatsapp",
        });
      } else if (setting === "domain") {
        this.settingsMenuItems.splice(4, 0, {
          title: "Domain Settings",
          description: "Access admin from your custom domain",
          slug: "domain",
        });
      } else if (setting === "email") {
        this.settingsMenuItems.splice(5, 0, {
          title: "Email Settings",
          description: "Manage your email settings",
          slug: "email",
        });
      } else if (setting === "multivendor") {
        this.settingsMenuItems.splice(8, 0, {
          title: "Multivendor Settings",
          description: "Update or manage your multivendor settings",
          slug: "multivendor",
        });
      } else if (setting === "pickup-drop") {
        this.settingsMenuItems.splice(9, 0, {
          title: "Pickup & Drop Settings",
          description: "Manage your pickup & drop settings",
          slug: "pickup-drop",
        });
      }
    },

    fetchStoreStatus() {
      return Store.getRetnalStatus(this._id, true)
        .then((x) => (this.$store.state.planInfo = x.data))
        .catch((e) => console.log(e));
    },

    fetchStoreEnums() {
      return Store.getStoreEnums(this._id)
        .then((res) => (this.senums = res.data))
        .catch((e) => console.log(e));
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.enums = ce);

      return Store.getCommonEnums()
        .then((x) => {
          this.enums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => console.log(e));
    },

    fetchPricingEnums() {
      return Store.getPricingEnums()
        .then((res) => (this.penums = res.data))
        .catch((e) => console.log(e));
    },

    fetchActiveCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      return Store.getActiveCountries()
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
        })
        .catch((e) => console.log(e));
    },

    fetchActiveLanguages() {
      return i18n
        .getActiveLanguages(true)
        .then((res) => (this.languages = res.data))
        .catch((e) => console.log(e));
    },

    getPlanPrice(tempAmount, currencyCode) {
      let amount = tempAmount;
      let pobj = {};

      const isYearly = this.store.billingCycle == "yearly";
      const pricingEnums = this.penums.growcifyPricing || [];
      if (!pricingEnums.length) return amount;

      let index = 0;
      if (this.store.plan == "growcify-premium") index = 1;
      else if (this.store.plan == "growcify-enterprise") index = 2;
      pobj = pricingEnums[index][currencyCode || "INR"];

      const newPrice = isYearly ? pobj.yearly : pobj.quarterly;
      amount = Global.getTaxAmount(newPrice);

      // if the amount is greater than newPrice
      if (tempAmount > amount) amount = tempAmount;

      return amount;
    },

    getTaxedAmount(tempAmount, currencyCode) {
      let amount = tempAmount;
      if (["quarterly", "yearly"].includes(this.store.billingCycle)) {
        amount = this.getPlanPrice(amount, currencyCode);
      }

      if (this.applyGST) return Global.getTaxAmount(amount);
      return amount;
    },

    pay() {
      this.isBuyingCredits = false;
      let amount = this.store.transaction.amount;
      const currencyCode = this.currencyCode;
      amount = this.getTaxedAmount(amount, currencyCode);
      //if (currencyCode == "INR") amount = this.getTaxedAmount(amount);

      const options = Global.getRazOptions(
        amount,
        "App Purchase Transaction",
        currencyCode
      );
      const newthis = this;
      options.handler = function(response) {
        newthis.processPayment(response.razorpay_payment_id);
      };

      new window.Razorpay(options).open();
    },

    processPayment(paymentId) {
      if (!paymentId) return;

      if (this.isBuyingCredits) {
        this.processing = true;
        this.buyCredit.paymentId = paymentId;
        this.buyCredit.amount = this.calculateCreditPayable();
        this.buyCredit.perCredit = this.getPerCreditPrice;

        return Store.processCredits(this._id, this.buyCredit, false)
          .then(() => {
            this.fetchStore();
            this.creditsModal = this.processing = this.isBuyingCredits = false;
          })
          .catch((e) => console.log(e));
      } else if (this.isActivatingHeader) {
        const headerObj = {
          paymentId: paymentId,
          sender: this.header.sender,
          amount: this.header.amount,
          currencyCode: this.header.currencyCode,
        };

        this.processing = true;
        return Store.processHeader(this.header._id, headerObj, false)
          .then(() => {
            this.fetchHeaderRequest();
            this.processing = this.isActivatingHeader = false;
          })
          .catch((e) => {
            console.log(e);
            this.processing = false;
          });
      } else {
        let amount = this.store.transaction.amount;
        const currencyCode = this.currencyCode;
        amount = this.getTaxedAmount(amount);
        //if (currencyCode == "INR") amount = this.getTaxedAmount(amount);

        const object = {
          transactionId: !this.store.paid ? this.store.transaction._id : "",
          paymentId: paymentId,
          amount: amount,
        };
        this.processing = true;
        return Store.processPayment(this._id, object, false)
          .then(() => {
            this.fetchStore();
            this.fetchStoreStatus();
            this.processing = false;
          })
          .catch((e) => console.log(e));
      }
    },

    save() {
      //if (JSON.stringify(this.store) === JSON.stringify(this.orignalStore)) return;
      Store.snackbarStart();
      this.processing = true;
      return Store.updateStore(this._id, this.store, false)
        .then(() => {
          document.title = this.store.name;
          this.$store.state.activeStore.name = this.store.name;
          this.$store.state.activeStore.deliveryFreeAbove = this.store.deliveryFreeAbove;
          this.$store.state.activeStore.deliveryCharge = this.store.deliveryCharge;
          this.$store.state.activeStore.deliveryModes = this.store.deliveryModes;
          this.$store.state.activeStore.paymentMethods = this.store.paymentMethods;
          this.$store.state.activeStore.subscriptionEnabled = this.store.subscriptionEnabled;
          this.$store.state.activeStore.isMultivendor = this.store.isMultivendor;
          this.$store.state.activeStore.categoryWiseDeliveryCharge = this.store.categoryWiseDeliveryCharge;
          this.$store.state.activeStore.mainCategoryWiseDeliveryCharge = this.store.mainCategoryWiseDeliveryCharge;
          this.$store.state.activeStore.invoiceVendorName = this.store.invoiceVendorName;
          this.$store.state.activeStore.hideDeliveryChargeFromVendors = this.store.hideDeliveryChargeFromVendors;
          this.$store.state.activeStore.thermalInvoiceColumns = this.store.thermalInvoiceColumns;
          this.$store.state.activeStore.thermalInvoiceHeading = this.store.thermalInvoiceHeading;
          this.$store.state.activeStore.thermalInvoiceShowInstructions = this.store.thermalInvoiceShowInstructions;
          this.$store.state.activeStore.applyTax = this.store.applyTax;
          this.$store.state.activeStore.taxType = this.store.taxType;
          this.$store.state.activeStore.invoiceColumns = this.store.invoiceColumns;
          this.$store.state.activeStore.thermalInvoicePrinter = this.store.thermalInvoicePrinter;
          this.$store.state.activeStore.fssaiRegistrationNumber = this.store.fssaiRegistrationNumber;
          this.$store.state.activeStore.drugLicenceNumber = this.store.drugLicenceNumber;
          this.$store.state.activeStore.appColor = this.store.appColor;
          this.$store.state.activeStore.dashboardColor = this.store.dashboardColor;
          this.$store.state.activeStore.paymentQRCode = this.store.paymentQRCode;
          this.$store.state.activeStore.hideSavingsFromThermalInvoice = this.store.hideSavingsFromThermalInvoice;
          this.$store.state.activeStore.hideProductPriceIfUserNotApproved = this.store.hideProductPriceIfUserNotApproved;
          this.processing = this.colorDialog = false;
          if (this.hoursModal) this.hoursModal = false;

          //update country information
          if (this.store.country != this.orignalStore.country)
            this.updateCountry();

          this.ss = true;
          setTimeout(() => (this.ss = false), 2500);
          Store.snackbarSuccess();
          Store.setStoreAppearance();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    fetchEmailConfig() {
      this.processing = true;
      return Store.getEmailConfig(this._id, false)
        .then((x) => {
          if (x.data) this.emailConfig = x.data;
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    saveEmailSettings() {
      if (!this.$refs.emailForm.validate()) return;
      this.processing = true;
      Store.snackbarStart();

      let request;
      if (this.emailConfig._id)
        request = Store.editEmailConfig(
          this.emailConfig._id,
          this.emailConfig,
          false
        );
      else request = Store.addEmailConfig(this._id, this.emailConfig, false);

      return request
        .then((x) => {
          if (!this.emailConfig._id) this.emailConfig._id = x.data._id;
          this.processing = false;
          this.ss = true;
          Store.snackbarSuccess();
          setTimeout(() => (this.ss = false), 2500);
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    saveWebSettings(type) {
      if (!type) return;
      if (type == "dashboard" && !this.$refs.dashForm.validate()) return;
      if (type == "website" && !this.$refs.websiteForm.validate()) return;

      this.processing = true;
      Store.snackbarStart();

      return Store.updateStoreWebSettings(
        this._id,
        type,
        this.store.webSettings,
        false
      )
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    text(item) {
      return (
        item.name + " (" + this.$options.filters.currency(item.price, "₹") + ")"
      );
    },

    subscriptionPeriodText(item) {
      return item.value + " (" + item.description + ")";
    },

    getRef() {
      return this.$refs.inputUpload[0]
        ? this.$refs.inputUpload[0]
        : this.$refs.inputUpload;
    },

    pickImage() {
      this.getRef().click();
    },

    isFileImage(file) {
      return file && file["type"].split("/")[0] === "image";
    },

    getPhotoURL(data) {
      if (data && data.split(".")[1] === "png") {
        return getS3URL(this._id, data);
      }

      return data;
    },

    invalidImageError() {
      this.showError = "Invalid Image";
      setTimeout(() => {
        this.showError = false;
      }, 2000);
    },

    fileSelected(type = "logos") {
      let obj;
      if (type == "qr") obj = this.getQRRef();
      else if (type == "purchases") obj = this.getPLRef();
      else if (type == "signatures") obj = this.getSignatureRef();
      else obj = this.getRef();

      const file = obj.files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.uploading = true;

      if (type == "purchases") this.puploading = true;
      else if (type == "signatures") this.suploading = true;

      return FileUpload.upload(this._id, type, file)
        .then((x) => {
          if (x && x.data && x.data.file) {
            const data = x.data.file;
            if (type == "banners") {
              this.store.mvTab1Banner = data;
            } else if (type == "qr") {
              this.store.paymentQRCode = data;
            } else if (type == "purchases") {
              this.store.purchaseSettings.logo = data;
            } else if (type == "signatures") {
              this.store.purchaseSettings.signature = data;
            } else {
              this.store.appLogo = data;
            }
            this.save();
          }
          this.uploading = this.puploading = this.suploading = false;
        })
        .catch(() => {
          this.uploading = this.puploading = this.suploading = false;
        });
    },

    getQRRef() {
      return this.$refs.inputQRUpload[0]
        ? this.$refs.inputQRUpload[0]
        : this.$refs.inputQRUpload;
    },

    pickQRImage() {
      this.getQRRef().click();
    },

    deletePaymentQR() {
      this.store.paymentQRCode = "";
      this.save();
    },

    calculateCreditAmount() {
      if (
        this.buyCredit.type == "Transactional" &&
        this.penums.transactionalSMS &&
        this.penums.transactionalSMS[this.$store.state.country.currencyCode]
      )
        this.perCredit = this.penums.transactionalSMS[
          this.$store.state.country.currencyCode
        ];
      else if (
        this.buyCredit.type == "Promotional" &&
        this.penums.promotionalSMS &&
        this.penums.promotionalSMS[this.$store.state.country.currencyCode]
      )
        this.perCredit = this.penums.promotionalSMS[
          this.$store.state.country.currencyCode
        ];
      return (this.buyCredit.credits | 0) * this.getPerCreditPrice;
    },

    calculateCreditTax() {
      return parseFloat(
        ((this.calculateCreditAmount() * config.gst) / 100).toFixed(2)
      );
    },

    calculateCreditPayable() {
      return this.calculateCreditAmount() + this.calculateCreditTax();
    },

    checkoutCredits() {
      this.isBuyingCredits = true;
      const amount = this.calculateCreditPayable();
      const description = "SMS Credits";

      const options = Global.getRazOptions(
        amount,
        description,
        this.$store.state.country.currencyCode
      );
      const newthis = this;
      options.handler = function(response) {
        newthis.processPayment(response.razorpay_payment_id);
      };

      new window.Razorpay(options).open();
    },

    cancelBusinessHours() {
      this.store.businessHours = this.orignalStore.businessHours;
      this.hoursModal = false;
    },

    saveBusinessHours() {
      if (!this.$refs.formBusinessHours.validate()) return;
      this.save();
    },

    getHoursAt(key) {
      return key && this.enums.businessHours
        ? this.enums.businessHours.find((x) => x.key === key)
        : { key: "", value: "" };
    },

    getHourObject(day) {
      return this.store && this.store.businessHours
        ? this.store.businessHours.find((x) => x.day === day)
        : { day: day, isOpen: true, opensAt: "24", closesAt: "" };
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      this.save();
    },

    fetchHeaderRequest() {
      return Store.getHR(this._id, false)
        .then((x) => (this.header = x.data || {}))
        .catch((e) => console.log(e));
    },

    submitHeader() {
      if (!this.$refs.headerForm.validate()) return;

      this.processing = true;
      this.header.amount = this.getSenderIDAmount;
      this.header.stage = "pending";
      this.header.currencyCode =
        this.$store.state.country.currencyCode || "INR";
      const request = this.header._id
        ? Store.editHR(this.header._id, this.header, false)
        : Store.createHR(this._id, this.header, false);

      return request
        .then(() => {
          this.processing = this.headerModal = false;
          this.fetchHeaderRequest();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    activateHeader() {
      this.isActivatingHeader = true;
      const amount = this.header.amount;
      const currencyCode =
        this.header.currencyCode || this.$store.state.country.currencyCode;
      const description = "Growcify SMS Header";

      const options = Global.getRazOptions(amount, description, currencyCode);
      const newthis = this;
      options.handler = function(response) {
        newthis.processPayment(response.razorpay_payment_id);
      };

      new window.Razorpay(options).open();
    },

    fetchShipmentWallet() {
      return Store.getShipmentWallet(this._id, false)
        .then((x) => {
          if (x && x.data) {
            this.shipmentWallet = x.data;
            this.walletModified = false;
            this.fetchShipmentWalletHistory(this.shipmentWallet._id);
          }
        })
        .catch((e) => console.log(e));
    },

    fetchShipmentWalletHistory(shipmentWalletId) {
      return Store.getShipmentWalletHistory(this._id, shipmentWalletId, false)
        .then((x) => {
          if (x && x.data && x.data.length) {
            this.shipmentWalletHistory = x.data;
            const records = x.data.filter((x) => x.txn === "debit");
            this.shipmentWalletTotalSpent = records.length
              ? records.reduce((a, b) => a + b.amount, 0)
              : 0;
          }
        })
        .catch((e) => console.log(e));
    },

    verifyDomain(domain) {
      if (!domain) {
        this.domaine = "Your domain is missing";
        setTimeout(() => (this.domaine = ""), 2500);
        return;
      }

      this.processing = true;
      return Store.verifyDomain(this._id, { domain: domain }, false)
        .then((x) => {
          if (x.data.ok) {
            this.domains = "Connection verified successfully";
          } else
            this.domaine =
              "We could not verify your connection. Make sure you have followed all steps correctly and also saved settings. Try again later.";

          setTimeout(() => {
            this.domaine = this.domains = "";
            if (x.data.ok) this.store.csVerified = true;
          }, 5000);
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    resetSettingsMenu() {
      this.settingsMenuItems = this.settingsMenuItems.filter((x) =>
        [
          "general",
          "business-hours",
          "sound-and-notifications",
          "shipment",
          "invoice",
          "purchase",
        ].includes(x.slug)
      );
    },

    toggleEye(type) {
      if (type === "key") this.showECKey = !this.showECKey;
      else if (type === "secret") this.showECSecret = !this.showECSecret;
    },

    updateCountry() {
      return Store.getCountry(this.store.country, false)
        .then(
          (x) =>
            (this.$store.state.country = x.data || this.$store.state.country)
        )
        .catch((e) => console.log(e));
    },

    formatAmount(amount, currencyCode) {
      const country = this.countries.find(
        (x) => x.currencyCode === currencyCode
      );
      if (!country) return this.$options.filters.currency(amount, currencyCode);
      return this.$options.filters.currency(
        amount,
        country.currencySymbol || country.currencyCode
      );
    },

    fetchStatesByCountry(country = this.store.country) {
      this.sfetching = true;
      return Store.getStatesByCountry(country, false)
        .then((x) => {
          this.states = x.data;
          this.sfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.sfetching = false;
        });
    },

    fetchCitiesByState(state = this.store.state) {
      this.cfetching = true;
      return Store.getCitiesByState(state, false)
        .then((x) => {
          this.cities = x.data;
          this.cfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.cfetching = false;
        });
    },

    viewDomainInstructions(domain, isWeb = false) {
      this.isWeb = isWeb;
      this.growcifyDomain = domain;
      this.$store.state.domainInstructionModal = true;
    },

    selectColorDashboard() {
      if (typeof this.selectedColorDashboard === "string")
        this.store.dashboardColor = this.selectedColorDashboard;
      else this.store.dashboardColor = this.selectedColorDashboard.hex;
      this.colorDialogDashboard = false;
    },

    fetchSMSHistory(
      pageNumber,
      itemsPerPage = this.smsHistoryObject.itemsPerPage,
      keyword = ""
    ) {
      this.shloading = true;
      return Store.getSMSStoreHistory(
        this._id,
        pageNumber,
        itemsPerPage,
        keyword
      )
        .then((res) => {
          this.smsHistoryObject = res.data;
          this.smsHistory = this.smsHistoryObject.items.map((x, index) => {
            x.index = index + 1 + ".";
            x.mobiled = "+" + x.callingCode + " " + x.mobile;
            x.createdAtd = moment(x.createdAt).format("lll");
            return x;
          });
          this.shloading = this.firstTime = false;
        })
        .catch((e) => {
          console.log(e);
          this.shloading = false;
        });
    },

    getRefDoc() {
      return this.$refs.inputDocUpload;
    },

    pickFileDoc() {
      this.getRefDoc().click();
    },

    getURLDoc(data) {
      if (!data) return data;
      const ext = data.split(".")[1];
      if (
        data &&
        (this.supportedFormats.includes(ext) ||
          this.soundSupportedFormats.includes(ext) ||
          ext == "png")
      )
        return `https://${this._id}.s3.amazonaws.com/${data}`;
      return data;
    },

    async isValidDocument(file) {
      if (!file) return false;
      const name = file["name"].split(".");
      if (name && name[1] && this.supportedFormats.includes(name[1])) {
        const fileBuffer = Buffer.from(await file.arrayBuffer());
        this.fileObject = {
          name: file["name"],
          size: file["size"],
          type: name[1],
          keyString: fileBuffer.toString(),
        };
        return true;
      }
      return false;
    },

    invalidDocError() {
      this.$store.state.snackbar.text = "Unsupported File Format";
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelectedDoc() {
      const file = this.getRefDoc().files[0];
      if (!this.isValidDocument(file)) return this.invalidDocError();

      this.fileObject._id = "";
      this.fileObject._id = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(this._id, "ios", file, "p8")
        .then((x) => {
          if (!this.store.appleAPNSKeyFile) this.store.appleAPNSKeyFile = {};
          this.fileObject._id = x.data.file;
          this.store.appleAPNSKeyFile = this.fileObject;
          this.uploading = false;
          this.save();
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    openAPNSDocument() {
      const item = this.store.appleAPNSKeyFile;
      window.open(this.getURLDoc(item._id), "_blank");
    },

    removeAPNSDocument() {
      this.store.appleAPNSKeyFile = {};
      this.save();
    },

    getPLRef() {
      return this.$refs.inputPLUpload[0]
        ? this.$refs.inputPLUpload[0]
        : this.$refs.inputPLUpload;
    },

    pickPurchaseLogo() {
      this.getPLRef().click();
    },

    getSignatureRef() {
      return this.$refs.inputSignatureUpload[0]
        ? this.$refs.inputSignatureUpload[0]
        : this.$refs.inputSignatureUpload;
    },

    pickSignatureLogo() {
      this.getSignatureRef().click();
    },

    deletePurchaseLogo() {
      this.store.purchaseSettings.logo = "";
      this.save();
    },

    deleteSignature() {
      this.store.purchaseSettings.signature = "";
      this.save();
    },

    getRefSoundFile() {
      return this.$refs.inputSoundFileUpload;
    },

    pickSoundFile() {
      this.getRefSoundFile().click();
    },

    isValidSoundFile(file) {
      if (!file) return false;
      const name = file["name"].split(".");
      if (name && name[1] && this.soundSupportedFormats.includes(name[1])) {
        return true;
      }
      return false;
    },

    fileSelectedSound() {
      const file = this.getRefSoundFile().files[0];
      if (!this.isValidSoundFile(file)) return this.invalidDocError();

      this.uploading = true;
      return FileUpload.upload(this._id, "sound", file, "mp3")
        .then((x) => {
          const data = x.data;
          this.store.notificationSettings.newOrder.file = data.file;
          this.uploading = false;
          this.save();
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    openOrderSoundFile() {
      const item = this.store.notificationSettings.newOrder.file;
      window.open(getS3URL(this._id, item), "_blank");
    },

    removeOrderSoundFile() {
      this.store.notificationSettings.newOrder.file = "";
      this.save();
    },

    getSoundFileName(data) {
      let str = data;
      let arr = str.split("/");
      str = arr[arr.length - 1];
      return str;
    },
  },

  computed: {
    amISuperadmin() {
      return Store.amISuperadmin(this.$store);
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    amIGrowcifyEmployee() {
      return Store.amIGrowcifyEmployee(this.$store);
    },

    getSenderIDAmount() {
      const currencyCode = this.$store.state.country.currencyCode;
      let amount = this.penums.senderID[currencyCode] || 2499;
      if (currencyCode == "INR") amount = Global.getTaxAmount(amount);
      return amount;
    },

    getPerCreditPrice() {
      let price = this.perCredit;
      if (this.buyCredit.credits > 50000 && this.buyCredit.credits < 100000)
        price =
          this.perCredit - parseFloat(((this.perCredit * 10) / 100).toFixed(2));
      else if (
        this.buyCredit.credits >= 100000 &&
        this.buyCredit.credits <= 200000
      )
        price =
          this.perCredit - parseFloat(((this.perCredit * 25) / 100).toFixed(2));
      else if (
        this.buyCredit.credits > 200000 &&
        this.buyCredit.credits <= 500000
      )
        price =
          this.perCredit - parseFloat(((this.perCredit * 35) / 100).toFixed(2));
      else if (this.buyCredit.credits > 500000)
        price =
          this.perCredit - parseFloat(((this.perCredit * 50) / 100).toFixed(2));

      return price;
    },

    applyGST() {
      const amount = this.store.transaction.amount;
      if (this.currencyCode == "INR") {
        return [999, 2499, 4499, 5999].includes(amount);
      }
      return false;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    pplans() {
      return this.enums.plans.concat(this.penums.growcifyPricing);
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    isOwner() {
      return this.$store.state.activeStore.whoAmI == "owner";
    },

    hideAmount() {
      return this.$store.state.activeStore.isWhitelabeled && !this.isOwner;
    },

    dcPrefix() {
      return this.store.deliveryChargeBy == "amount"
        ? this.$store.state.country.currencySymbol
        : "";
    },

    dcSuffix() {
      return this.store.deliveryChargeBy == "percent" ? "%" : "";
    },

    currencyCode() {
      return this.store.transaction.currencyCode
        ? this.store.transaction.currencyCode.toUpperCase()
        : "INR";
    },
  },

  data() {
    const businessHours = [
      this.getHourObject("Sunday"),
      this.getHourObject("Monday"),
      this.getHourObject("Tuesday"),
      this.getHourObject("Wednesday"),
      this.getHourObject("Thursday"),
      this.getHourObject("Friday"),
      this.getHourObject("Saturday"),
    ];
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      businessHours: businessHours,
      languages: [],

      translationModal: false,
      translationField: "",

      store: {
        apps: [],
        appsDelivered: [],
        transaction: {},
        businessHours: [],
        notificationSettings: {
          newOrder: {
            notification: false,
            sound: false,
          },
        },
        referralSettings: {
          referralEnabled: false,
          referredByDiscount: 0,
          referredToDiscount: 0,
        },
      },
      orignalStore: {},
      _id: "",
      showError: false,

      plans: [],
      enums: {
        plans: [],
      },
      senums: {},
      penums: {
        transactionalSMS: {},
        promotionalSMS: {},
        senderID: {},
        growcifyPricing: [],
      },

      countries: [],
      states: [],
      cities: [],
      sfetching: false,
      cfetching: false,

      loading: false,
      valid: false,
      saving: false,
      processing: false,
      uploading: false,
      mloadingdone: false,

      isBuyingCredits: false,
      creditsModal: false,
      perCredit: 0.25,
      buyCredit: {
        type: "Transactional",
        credits: 5000,
      },

      validBusinessHours: false,
      hoursModal: false,

      headerModal: false,
      header: {},
      isActivatingHeader: false,

      colorDialog: false,
      templatesModal: false,
      templates: config.templates,

      shipmentWallet: { balance: 0 },
      shipmentWalletTotalSpent: 0,
      shipmentWalletHistory: [],
      walletModified: false,

      lastInput: "",
      isLoading: false,
      places: [],
      selectedPlace: {},
      input: "",

      domains: false,
      domaine: false,

      ss: false,

      modal2: false,
      modal3: false,
      modal4: false,

      settingTab: 0,
      settingItems: [
        "App Information",
        "Business Information",
        "Order Settings",
        "Subscription Settings",
        "Wallet Settings",
        "Referral Settings",
        "Invoice Settings",
        "Localization Settings",
        "Sound & Notification Settings",
        "App UI Settings",
        "Domain Settings",
      ],

      activeSetting: {},

      emailConfig: {},
      showECKey: false,
      showECSecret: false,

      dsdExpanded: 0,
      dswExpanded: 1,

      isWeb: false,
      growcifyDomain: "",
      growcifyDomainWeb: "gcfy-zone-ii-deterxx.growcifydns.com", //'75.2.60.5'
      growcifyDomainDashbaord: "gcfy-zone-deterx.growcifydns.com", //'75.2.60.5'

      selectedColorDashboard: "",
      colorDialogDashboard: false,

      shloading: false,
      shsearch: "",
      shoptions: {},
      smsHistoryObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      supportedFormats: ["p8"],
      fileObject: {},

      soundSupportedFormats: ["mp3"],

      yesNoArray: [
        { key: true, value: "Yes" },
        { key: false, value: "No" },
      ],

      suploading: false,
      puploading: false,

      smsHistory: [],
      shcolumns: [
        { text: "#", align: "start", value: "index" },
        { text: "Mobile", value: "mobiled" },
        { text: "Template ID", value: "templateId" },
        { text: "Message", value: "message", width: "50%" },
        { text: "Date & Time", value: "createdAtd" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],
        deliveryCharge: [(v) => v >= 0 || "Must be valid amount"],
        walletBalance: [(v) => v > 0 || "Must be a valid number"],
        creditsRule: [(v) => v > 99 || "Minimum 100 credits"],
        walletBalanceRule: [
          (v) => v >= config.shipment.minBalance || "Minimum amount 200",
        ],
        headerRule: [
          (v) =>
            (v && v.length === 6 && isNaN(v)) ||
            "Invalid header name; must be a valid string like GROCFY, GOOGLE",
        ],
        statusRule: [(v) => v === false || v === true || "It can not be empty"],
        emailRule: [
          (v) =>
            (!!v && emailregex.test(v)) ||
            "Must be a valid email: mail@example.com",
        ],
        invoiceRule: [(v) => (!!v && !!v.length) || "It can not be empty"],
        numberRule: [(v) => !v || v >= 0 || "Must be valid number"],
        addressRule: [(v) => (!!v && !!v.description) || "It is required"],
      },

      orderTab: "",
      ordersTab: [
        { _id: "charges", title: "Charges Settings" },
        { _id: "tax", title: "Order Tax" },
        { _id: "notifications", title: "Order Notifications" },
        { _id: "checkout", title: "Checkout" },
        { _id: "returns", title: "Returns" },
        { _id: "address", title: "Address" },
        { _id: "other", title: "Other" },
      ],

      settingsMenuItems: [
        {
          title: "General Settings",
          description: "View and modify your business information",
          slug: "general",
        },
        {
          title: "App Information",
          description: "View and modify your app information",
          slug: "app-information",
        },
        {
          title: "Add-ons",
          description: "Manage additional add-ons",
          slug: "add-ons",
        },
        {
          title: "Order Settings",
          description: "Update or manage your orders settings",
          slug: "orders",
        },
        {
          title: "WhatsApp Settings",
          description: "Update or manage your WhatsApp settings",
          slug: "whatsapp",
        },
        {
          title: "Manage Business Hours",
          description: "Manage your business hours",
          slug: "business-hours",
        },
        {
          title: "Subscription Settings",
          description: "Manage your subscriptions settings",
          slug: "subscriptions",
        },
        {
          title: "Wallet Settings",
          description: "Update or manage your wallet settings",
          slug: "wallet",
        },
        {
          title: "Referral Settings",
          description: "Update or manage your referral settings",
          slug: "referral",
        },
        {
          title: "Invoice Settings",
          description: "Update or manage your invoice settings",
          slug: "invoice",
        },
        {
          title: "Purchase Settings",
          description: "Update or manage your purchase settings",
          slug: "purchase",
        },
        {
          title: "Localization Settings",
          description: "Update or manage app localization settings",
          slug: "localization",
        },
        {
          title: "Sound & Notifications Settings",
          description: "Manage sound & notification settings",
          slug: "sound-and-notifications",
        },
        {
          title: "App UI Settings",
          description: "Update or manage your app ui settings",
          slug: "app-ui",
        },
        {
          title: "SMS Settings",
          description: "Manage your SMS settings and credits",
          slug: "sms",
        },
        // {
        //   title: "Shipment Wallet",
        //   description: "Manage your shipment wallet",
        //   slug: "shipment",
        // },
        {
          title: "Social Media",
          description: "Add your social media handles",
          slug: "social-media",
        },
        {
          title: "KYC Documents",
          description: "Upload your documents for KYC",
          slug: "documents",
        },
      ],
    };
  },
};

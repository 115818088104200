import moment from "moment";
import Store from "../../../services/store";
import Order from "../../../services/order";
import router from "../../../router";
import config from "../../../config";

import CouriersDialog from "../../../components/couriers-dialog/couriers-dialog.vue";
import PickupAddress from "../../../components/pickup-address/pickup-address.vue";
import PickupAddressPicker from "../../../components/pickup-address-picker/pickup-address-picker.vue";
import ShipmentPackage from "../../../components/shipment-package/shipment-package.vue";
import CustomerForm from "../../../components/customer-form/customer-form.vue";
import ProdutQuickForm from "../../../components/product-quick-form/product-quick-form.vue";
import ShipmentSuccess from "../../../components/shipment-success/shipment-success.vue";
import ThermalInvocie from "../../../components/thermal-invoice/thermal-invoice.vue";
import OrderRefundDialog from "../../../components/order-refund-dialog/order-refund-dialog.vue";
import InfoCard from "../../../components/general/info-card.vue";
import { getS3URL } from "../../../helpers/misc";

import AddressString from "../../../components/general/address-string.vue";
import AddressNavigation from "../../../components/general/address-navigation.vue";
import Dunzo from "../../../components/dunzo/dunzo.vue";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb.vue";
import PaymentDetails from "../../../components/general/payment-details.vue";

export default {
  name: "Order",

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add more items to this order"
        : "Edit items of this order";
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    isMultivendor() {
      return this.$store.state.activeStore.isMultivendor;
    },

    getOrderAmount() {
      let amount = this.order.amount;
      if (
        this.isVendor &&
        this.$store.state.activeStore.vendorOwner.hideDeliveryChargeFromVendors
      )
        amount = amount - this.order.deliveryCharge || 0;
      return amount;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    store() {
      return this.$store.state.activeStore;
    },

    hcifv() {
      return (
        this.isVendor && this.store.vendorOwner.hideCustomerInfoFromVendors
      );
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },

    reorderedItems() {
      if (!this.order || !this.order.items) return [];
      const items = [];
      this.order.items.forEach((x) => {
        items.push(x);
        if (x.isCombo) {
          x.comboItems.forEach((c) => {
            const cobject = {
              _id: c._id,
              qty: c.deductQty,
              name: c._id.name,
              size: c._id.size,
              unit: c._id.unit,
              color: c._id.color,
              sku: c._id.sku,
              ignore: true,
            };
            if (c._id.category && c._id.category.name)
              cobject.category = c._id.category.name;
            items.push(cobject);
          });
        }
      });

      return items;
    },

    getOrderTypeFormatted() {
      if (this.order.type == "pos") return "POS";
      return this.capitalize(this.order.type);
    },

    breadcrumbItems() {
      let items = [
        {
          title: "Orders",
          to: { name: "store-orders", params: { _id: this._id } },
        },
      ];

      if (this.order._id) {
        items.push({
          title: this.getOrderTypeFormatted + " Orders",
          to: {
            name: "store-orders",
            params: { _id: this._id },
            query: { tab: this.order.type },
          },
        });
      }

      items.push({
        title: this.loading
          ? "Loading..."
          : !this.hcifv
          ? this.order.address
            ? this.order.address.name
            : this.order.user.name
          : "OID: " + this.order.oid,
        isLast: true,
      });

      return items;
    },
  },

  components: {
    CouriersDialog,
    PickupAddress,
    PickupAddressPicker,
    ShipmentPackage,
    CustomerForm,
    ProdutQuickForm,
    ShipmentSuccess,
    ThermalInvocie,
    OrderRefundDialog,
    InfoCard,
    AddressString,
    AddressNavigation,
    Dunzo,
    Breadcrumb,
    PaymentDetails,
  },

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Orders")) return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.orderId = this.$route.params.orderId;
    this.fetchOrder();
    this.fetchOrderEnums();
    this.fetchDeliverymen();
    if (this.isMultivendor && !this.isVendor) this.fetchVendors();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    pushSKUColumn() {
      if (this.headers.some((x) => x.value == "_id.sku")) return;
      this.headers.push({ text: "SKU", value: "_id.sku" });
    },

    pushColorColumn() {
      if (this.headers.some((x) => x.value == "color")) return;
      this.headers.push({ text: "Color", value: "color", sortable: false });
    },

    pushTaxColumn() {
      if (this.headers.some((x) => x.value == "gst")) return;
      this.headers.push({ text: "Tax %", value: "gst", sortable: false });
    },

    pushAddonsColumn() {
      if (this.headers.some((x) => x.value == "addons")) return;
      this.headers.push({ text: "Add-ons", value: "addons", sortable: false });
    },

    pushRemarksColumn() {
      if (this.headers.some((x) => x.value == "_id.remarks")) return;
      this.headers.push({
        text: "Remarks",
        value: "_id.remarks",
        sortable: false,
      });
    },

    fetchOrder(onlyFetch = true) {
      if (onlyFetch) this.loading = true;
      return Order.getOrder(this.orderId, false)
        .then((res) => {
          this.order = res.data;
          this.order.ago = moment(this.order.createdAt).format("lll");
          if (!this.order.package) this.order.package = {};
          this.order.items.forEach((x, i) => {
            x.index = i + 1 + ".";
            x.itemTotal = x.price * x.qty;
            x.vendors =
              x._id.vendors && x._id.vendors.length
                ? x._id.vendors.map((x) => x.businessName).join(", ")
                : "-";

            if (x.color) this.pushColorColumn();
            if (x._id.sku) this.pushSKUColumn();
            if (x.gst) this.pushTaxColumn();
            if (x.selectedAddons && x.selectedAddons.length)
              this.pushAddonsColumn();
            if (x._id.remarks) this.pushRemarksColumn();
          });

          if (this.order.deliveryDate)
            this.order.deliveryDated = moment
              .parseZone(this.order.deliveryDate)
              .format(
                this.$store.state.activeStore.canSelectDeliveryTime
                  ? "lll"
                  : "ll"
              );

          if (this.order.paymentMethod == "PL")
            this.order.readablePM = "Pay Later";
          else if (this.order.paymentMethod == "BT")
            this.order.readablePM = "Bank Transfer";
          else if (this.order.paymentMethod == "CPU")
            this.order.readablePM = "Custom Payment URL";
          else if (this.order.paymentMethod == "PAS")
            this.order.readablePM = "Pay at the store";
          else if (this.order.paymentMethod == "MRC")
            this.order.readablePM = "Maharajya Recharge Card";
          else this.order.readablePM = this.order.paymentMethod || "-";

          this.oo = JSON.parse(JSON.stringify(this.order));
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchOrderEnums() {
      this.eloading = true;
      return Order.getOrderEnums(this._id)
        .then((res) => {
          this.enums = res.data;
          this.enums.status = this.enums.status.map((x) => {
            return {
              key: x,
              value: x.charAt(0).toUpperCase() + x.slice(1),
            };
          });
          this.eloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.eloading = false;
        });
    },

    fetchDeliverymen() {
      this.dloading = true;
      return Store.getEmployeesByRole(this._id, "deliveryman")
        .then((res) => {
          this.deliverymen = res.data.filter((x) => x.isActive);
          this.dloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.dloading = false;
        });
    },

    openMap(lat, long) {
      const url = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      window.open(url, "_system");
    },

    close() {
      this.$store.state.customerFormModal = this.processing = this.loading = this.sloading = this.$store.state.produtQuickFormModal = false;
      this.editedUserItem = Object.assign({}, this.defaultUserItem);
    },

    save(notify = false) {
      Store.snackbarStart();
      this.processing = true;
      return Order.changeStatus(this.orderId, this.order.status, notify, false)
        .then(() => {
          this.processing = false;
          this.dialog = false;
          this.fetchOrder(false);
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    assignDeliveryman() {
      Store.snackbarStart();
      this.processing = true;
      return Order.assignDeliveryman(
        this.orderId,
        this.order.deliveryman,
        false
      )
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    fetchVendors() {
      return Store.getVendors(this._id, false)
        .then((res) => (this.vendors = res.data.filter((x) => x.isActive)))
        .catch((e) => console.log(e));
    },

    vendorText(item) {
      return `${item.businessName} (${item.name}) - ${item.mobile}`;
    },

    assignVendor() {
      if (!this.$refs.vform.validate()) return;
      Store.snackbarStart();
      this.processing = true;
      return Order.assignVendor(this.orderId, this.order.vendor, false)
        .then(() => {
          this.processing = this.vendorAssignModal = false;
          this.fetchOrder(false);
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    generateInvoice(isKOT = false) {
      return Order.generateInvoice(
        this.orderId,
        this.$store.state.token,
        isKOT === true
      );
    },

    closeError() {
      this.errorModal = false;
      this.errorType = "";
    },

    editProduct(item) {
      //this.errorModal = false;
      this.editedProductItem = item;
      this.$store.state.produtQuickFormModal = true;
    },

    processShipment() {
      if (!this.order.user) return;

      //if it's shipped/delivered, check the shipment for this
      //order if it was shipped by couriers
      if (["shipped", "delivered", "cancelled"].includes(this.order.status)) {
        this.sloading = true;
        return this.getShipment();
      } else if (!this.order.address.email && !this.order.user.email) {
        const user = this.order.user;
        if (!user.name) user.name = this.order.address.name;
        this.editedUserItem = user;
        this.$store.state.customerFormModal = true;
      } else {
        if (!this.order.items.every((x) => x._id.sku)) {
          this.errorType = "sku-missing";
          this.errorModal = true;
          this.errorItems = this.order.items.filter((x) => !x._id.sku);
          return;
        }

        this.$store.state.produtQuickFormModal = this.errorModal = false;
        this.sloading = true;
        this.validateOwnShipmentAccount();
      }
    },

    validateOwnShipmentAccount() {
      return Store.getIntegration(this._id, "shipment", false)
        .then((x) => {
          if (x && x.data) this.$store.state.shipmentPackageModal = true;
          else this.getShipmentWallet();
        })
        .catch((e) => console.log(e));
    },

    getShipmentWallet() {
      return Store.getShipmentWallet(this._id, false)
        .then((x) => {
          if (x && x.data) {
            this.shipmentWallet = x.data;
            this.$store.state.customerFormModal = false;
            if (this.shipmentWallet.balance >= config.shipment.minBalance)
              this.$store.state.shipmentPackageModal = true;
            else this.$store.state.shipmentBalanceModal = true;
          } else {
            this.$store.state.shipmentBalanceModal = true;
          }
        })
        .catch((e) => console.log(e));
    },

    getActivePickupAddresses() {
      this.processing = true;
      return Store.getPickupAddresses(this._id)
        .then((r) => {
          this.processing = false;
          this.pickupAddresses = r.data;
          if (this.pickupAddresses && this.pickupAddresses.length) {
            this.pickupAddresses = this.pickupAddresses.filter(
              (x) => x.isActive
            );
            this.pickupAddresses.forEach((x, index) => (x.index = index));
            if (this.pickupAddresses.length === 1) {
              //go ahead and find couriers
              this.selectedPickupAddress = this.pickupAddresses[0];
              this.fetchCouriers();
            } else if (this.pickupAddresses.length > 1) {
              //pick a pickup address
              this.$store.state.shipmentPackageModal = false;
              this.$store.state.pickupAddressPickerModal = true;
            } else {
              //add new pickup address
              this.$store.state.shipmentPackageModal = false;
              this.$store.state.pickupAddressModal = true;
            }
          } else {
            this.$store.state.shipmentPackageModal = false;
            this.$store.state.pickupAddressModal = true;
          }
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    fetchCouriers() {
      this.processing = true;
      return Order.getCouriers(
        this._id,
        this.orderId,
        this.selectedPickupAddress._id,
        false
      )
        .then((r) => {
          this.processing = false;
          this.$store.state.shipmentPackageModal = false;
          this.$store.state.pickupAddressPickerModal = false;
          this.couriers = r.data;
          this.allCouriers = r.data ? JSON.parse(JSON.stringify(r.data)) : [];
          this.couriersDialog = true;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          this.sloading = false;
        });
    },

    continueFromShipmentPackage(shipmentPackage) {
      this.order.package = shipmentPackage;
      this.getActivePickupAddresses();
    },

    cancelAtShipmentPackage() {
      this.$store.state.shipmentPackageModal = false;
      this.sloading = false;
    },

    continueFromPickupAddress() {
      this.fetchCouriers();
    },

    previousFromPickupAddress() {
      this.$store.state.pickupAddressPickerModal = false;
      this.$store.state.shipmentPackageModal = true;
    },

    cancelAtPickupAddress() {
      this.$store.state.pickupAddressPickerModal = false;
      this.sloading = false;
    },

    cancelAtCouriersDialog() {
      this.couriersDialog = false;
      this.sloading = false;
    },

    previousFromCouriersModal() {
      this.couriersDialog = false;
      this.$store.state.pickupAddressPickerModal = true;
    },

    continueFromCouriersModal() {
      this.processing = true;
      const shipmentObj = {
        courierCompanyId: this.selectedCourier.courier_company_id,
        pickupAddressId: this.selectedPickupAddress._id,
        rate: this.selectedCourier.rate,
      };
      return Order.createShipment(this._id, this.orderId, shipmentObj, false)
        .then((r) => {
          //console.log(r.data);
          this.shipmentInfo = r.data;
          this.processing = false;
          this.couriersDialog = false;
          this.shipmentSuccess();
          this.fetchOrder();
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 400) {
            this.error = e.response.data.error;
            setTimeout(() => {
              this.error = "";
            }, 25000);
          }
          this.processing = false;
        });
    },

    shipmentSuccess() {
      this.sloading = false;
      this.$store.state.shipmentSuccessModal = true;
    },

    closeShipmentSuccess() {
      this.processing = false;
      this.sloading = false;
      this.$store.state.shipmentSuccessModal = false;
    },

    getShipment() {
      this.processing = true;
      return Order.getShipment(this._id, this.orderId, false)
        .then((r) => {
          if (r.data && r.data._id) {
            this.shipmentInfo = r.data;
            this.shipmentSuccess();
          } else {
            this.$store.state.notification.text =
              "This order was not shipped via Growcify.";
            this.$store.state.notification.duration = 5000;
            this.$store.state.notification.show = true;
          }
          this.processing = false;
          this.sloading = false;
        })
        .catch((e) => {
          console.log(e.response);
          this.processing = false;
          this.sloading = false;
        });
    },

    amountFormatted(amount) {
      let formattedAmount = amount;
      if (this.order.currencyCode === "INR") {
        formattedAmount = this.currencyPipe(amount, "₹");
      } else if (this.order.currencyCode && this.order.currencyCode !== "INR") {
        formattedAmount = this.currencyPipe(amount, this.order.currencyCode);
      } else if (
        !this.$store.state.country.currencyCode &&
        !this.order.currencyCode
      ) {
        formattedAmount = this.currencyPipe(amount, "₹");
      } else {
        formattedAmount = this.currencyPipe(
          amount,
          this.$store.state.country.currencySymbol
        );
      }

      return formattedAmount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    getTimeDifference(startAt, endAt) {
      return this.secondsToHms(moment(endAt).diff(startAt, "seconds"));
    },

    secondsToHms(d) {
      d = Number(d);
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);

      let hDisplay = h > 0 ? h + (h == 1 ? "h, " : "h, ") : "";
      let mDisplay = m > 0 ? m + (m == 1 ? "m, " : "m, ") : "";
      let sDisplay = s >= 0 ? s + (s == 1 ? "s" : "s") : "";
      return hDisplay + mDisplay + sDisplay;
    },

    getNumberWithOrdinal(n) {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },

    refundCheckAndContinue() {
      if (
        this.isVendor ||
        this.order.paymentMethod != "Online" ||
        this.order.status != "cancelled" ||
        this.order.isRefundProcessed
      )
        return this.save(true);
      this.refundAmount = this.getOrderAmount;
      this.$store.state.refundConfirmationModal = true;
    },

    formatDate(date, format = "") {
      return moment(date).format(format || "MMM D, h:mm A");
    },

    openCourierTrackingURL() {
      const url = this.order.courierTrackingURL;
      window.open(url, "_system");
    },

    savecourierInfo() {
      if (this.$refs.citForm && !this.$refs.citForm.validate()) return;
      const info = {
        courierName: this.order.courierName,
        courierAWBCode: this.order.courierAWBCode,
        courierTrackingURL: this.order.courierTrackingURL,
      };

      Store.snackbarStart();
      this.processing = true;
      return Order.updateCourierInfo(this.orderId, info, false)
        .then(() => {
          this.processing = this.courierInfoModal = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    getCallingCode(item) {
      let cc = this.$store.state.country.callingCode || "91";
      if (item.address && item.address.callingCode)
        cc = item.address.callingCode;
      else if (!item.address && item.user && item.user.callingCode)
        cc = item.user.callingCode;
      return cc;
    },

    fileURL(file) {
      return getS3URL(this.order.store, file);
    },

    getURL(data) {
      if (!data) return data;
      return this.fileURL(data);
    },

    downloadFile(data) {
      const url = this.fileURL(data);
      window.open(url, "_system");
    },

    capitalize(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    initDunzo() {
      this.$store.state.dunzoModal = true;
    },
  },

  watch: {
    "order.status"(n) {
      if (n === this.oo.status) return;
      //this.save(true);
      this.refundCheckAndContinue();
    },

    "order.deliveryman"(n) {
      if (n === this.oo.deliveryman) return;
      this.assignDeliveryman();
    },

    walletModified(n) {
      if (n) this.getShipmentWallet();
    },

    addressModified(n) {
      if (n) this.getActivePickupAddresses();
    },

    userModified(n) {
      if (n) {
        this.userModified = false;
        this.processShipment();
      }
    },

    productModified(n) {
      if (n) {
        this.productModified = false;
        this.processShipment();
      }
    },

    refundProcessed(n) {
      if (n) this.fetchOrder(false);
    },
  },

  data() {
    return {
      _id: "",
      orderId: "",

      dialog: false,
      loading: false,
      processing: false,
      order: {
        address: {},
        package: {},
      },
      oo: {},

      eloading: false,
      enums: {},

      dloading: false,
      deliverymen: [],

      addItems: [],
      enableStatusEdit: false,

      sloading: false,
      dzloading: false,

      couriers: [],
      allCouriers: [],
      couriersDialog: false,
      selectedCourier: {},

      shipmentWallet: {},
      walletModified: false,

      addressModified: false,

      pickupAddresses: [],
      selectedPickupAddress: {},

      userModified: false,
      editedUserItem: {},
      defaultUserItem: {},

      error: "",
      errorModal: "",
      errorType: "",
      errorItems: [],

      editedProductItem: {},
      productModified: false,

      vendorAssignModal: false,
      vendors: [],

      refundConfirmationModal: false,
      refundAmount: 0,

      shipmentInfo: {},

      courierInfoModal: false,
      refundProcessed: false,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "_id.name" },
        { text: "Category", value: "_id.category.name", sortable: false },
        { text: "Size", value: "size", sortable: false },
        { text: "Qty", value: "qty" },
        { text: "Price", value: "price", sortable: false },
        { text: "Total", value: "itemTotal" },
        //{ text: 'Vendors', value: 'vendors', sortable: false}
        /*{ text: 'Actions', value: 'actions', sortable: false},*/
      ],

      editedIndex: -1,
      deliverymanDialog: false,

      expanded: [],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        amountRule: [(v) => v >= 0 || "Enter valid amount"],
      },
    };
  },
};

import moment from 'moment';
import _ from 'underscore';

import Store from '../../../services/store';
import router from '../../../router';
import config from '../../../config';

export default {
  name: 'PaymentLinks',

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed('Payment Links') || this.isVendor) return router.push('/business/'+this._id);
    if (!this.doIHaveAccess('actions')) this.headers = this.headers.filter(x => x.text !== 'Actions');
    this.fetchPaymentLinks();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchPaymentLinks(pageNumber = this.paymentLinksObject.pageNumber, itemsPerPage = this.paymentLinksObject.itemsPerPage, keyword = '') {
      this.plfetching = true;
      return Store
        .getPaymentLinks(this._id, pageNumber, itemsPerPage, keyword, false)
        .then(x => {
          if (x.data && x.data.items) x.data.items.forEach((x, index) => x.index = (index + 1)+'.');
          this.paymentLinksObject = x.data;
          this.paymentLinks = this.paymentLinksObject.items;
          this.plfetching = false;
          this.firstLoad = true;
        })
        .catch(e => {
          console.log(e);
          this.plfetching = false;
        })
    },
    
    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.modifyPaymentLink(this.beingDeleted._id, 'delete');
    },

    amountFormatted(order) {
      let formattedAmount = order.amount;
      if (order.currencyCode === 'INR') formattedAmount = this.currencyPipe(order.amount, '₹');
      else if (order.currencyCode && order.currencyCode !== 'INR') formattedAmount = this.currencyPipe(order.amount, order.currencyCode);
      else if (!this.$store.state.country.currencyCode && !order.currencyCode) formattedAmount = this.currencyPipe(order.amount, '₹');
      else formattedAmount = this.currencyPipe(order.amount, this.$store.state.country.currencySymbol);

      return formattedAmount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    formatDate(date, format = 'lll') {
      return moment(date).format(format);
    },

    modifyPaymentLink(_id, action) {
      this.processing = true;
      Store.snackbarStart();

      let request;
      if (action == 'cancel') request = Store.cancelPaymentLink(_id, false);
      else if (action == 'delete') request = Store.deletePaymentLink(_id, false);
      else if (action == 'notify') request = Store.notifyPaymentLink(_id, false);
      else if (action == 'paid') request = Store.paymentLinkPaid(_id, false);

      return request
        .then(() => {
          this.processing = this.modifyPaymentLinkDialog = false;
          Store.snackbarSuccess();
          this.closeConfirm();
          this.fetchPaymentLinks();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    sendPaymentLink(order, type = 'order') {
      this.paymentLinkObject = {
        type: type,
        order: order._id,
        oid: order.oid,
        amount: order.amount,
        customer: {
          name: order.user.name,
          email: order.user.email,
          mobile: order.user.mobile,
          callingCode: order.user.callingCode,
          currencyCode: order.currencyCode || 'INR'
        },
      }
      if (order.oid) this.paymentLinkObject.description = this.$store.state.activeStore.appName + ' Order: '+order.oid
      this.$store.state.paymentLinkModal = true;
    }
  },

  computed: {
    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  watch: {
    'plsearch':  _.debounce(function(nv) {
      this.fetchPaymentLinks(1, this.ploptions.itemsPerPage, nv)
    }, 500),

    ploptions: {
      handler(options) {
        if (this.firstLoad) this.fetchPaymentLinks(options.page, options.itemsPerPage, this.plsearch);
      },
      deep: true,
    },

    paymentLinkModified(n) {
      if (n) this.fetchPaymentLinks();
      this.paymentLinkModified = false
    }
  },

  data() {
    return {
      _id: '',
      search: '',
      processing: false,
      rowItems: config.rowItems,

      addressModified: false,
      confirmDialog: false,
      beingDeleted: {},

      plfetching: false,
      plloading: true,
      ploptions: {},

      paymentLinkObject: {},
      plsearch: '',
      copyPaymentLinkText: 'Copy Payment Link',
      paymentLinks: [],
      paymentLinksObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10
      },
      paymentLinkModified: false,
      modifyPaymentLinkDialog: false,
      beingModifiedPLItem: {},

      plheaders: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name',  align: 'start', value: 'name' },
        { text: 'Mobile', value: 'mobile', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Created By', value: 'createdBy', sortable: false },
        { text: 'Created At', value: 'createdAt', sortable: false },
        { text: 'Actions', align: 'right', value: 'actions', sortable: false}
      ],
    }
  }
}
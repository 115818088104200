import Store from "../../services/store";
import Global from "../../services/global";

export default {
  name: "Banner",

  computed: {
    currentPath() {
      return this.$route.path;
    },

    isLoggedOut() {
      return this.$store.state.isLoggedOut;
    },

    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },

    showBanner() {
      return (
        !this.noBanner &&
        (!this.$store.state.planInfo.paid ||
          this.showPlanInfo ||
          this.isAMCDue ||
          this.isBillingDue)
      );
    },

    authStatus() {
      return this.$store.getters.authStatus;
    },

    showPlanInfo() {
      return (
        this.$store.state.planInfo.paid &&
        (this.$store.state.planInfo.rentDue ||
          this.$store.state.planInfo.isTenDaysToGoOrPassed)
      );
    },

    planInfo() {
      return this.$store.state.planInfo;
    },

    showActivationBanner() {
      return (
        this.$store.state.activeStore._id &&
        this.$store.state.planInfo.amount &&
        !this.$store.state.activeStore.isVendor &&
        !this.$store.state.planInfo.paid
      );
    },

    isAMCDue() {
      return this.planInfo.isAMCDue || this.planInfo.isTenDaysToGoOrPassedAMC;
    },

    isBillingDue() {
      return this.planInfo.isBillingDue;
    },

    amcObject() {
      return {
        amount: this.planInfo.amcAmount,
        currencyCode: this.planInfo.amcCurrencyCode,
      };
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    isOwner() {
      return this.$store.state.activeStore.whoAmI == "owner";
    },

    noBanner() {
      return !this.isOwner && this.$store.state.activeStore.isWhitelabeled;
    },
  },

  methods: {
    payAMC() {
      const options = Global.getRazOptions(
        this.amcObject.amount,
        "AMC Transaction",
        this.amcObject.currencyCode
      );
      const newthis = this;
      options.handler = function(response) {
        newthis.processPayment(response.razorpay_payment_id);
      };
      new window.Razorpay(options).open();
    },

    processPayment(paymentId) {
      if (!paymentId) return;
      const object = {
        transactionId: "",
        paymentId: paymentId,
        amount: this.amcObject.amount,
      };

      this.processing = true;
      const _id = this.$route.params._id;
      return Store.processPayment(_id, object, false)
        .then(() => {
          this.fetchStoreStatus();
          this.processing = false;
        })
        .catch((e) => console.log(e));
    },

    fetchStoreStatus() {
      const _id = this.$route.params._id;
      return Store.getRetnalStatus(_id, true)
        .then((x) => (this.$store.state.planInfo = x.data))
        .catch((e) => console.log(e));
    },

    hideNotice() {
      this.$store.state.noticeHidden = true;
    },
  },
};

export default {
  name: 'AppColorPicker',
  props: [
    'store'
  ],

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  data() {
    return {
      drawer: false,
      group: null,

      banners: [
        require('@/assets/imgs/b1.jpg'),
        require('@/assets/imgs/b2.jpg'),
        require('@/assets/imgs/b3.jpg')
      ],

      linkItems: [
        ['mdi-home', 'Home'],
        ['mdi-email', 'Orders'],
        ['mdi-clock', 'Subscriptions'],
        ['mdi-account-supervisor-circle', 'Addresses'],
        ['mdi-qrcode', 'QR Code'],
        ['mdi-share', 'Refer & Earn'],
        ['mdi-translate', 'Change Language'],
        ['mdi-whatsapp', 'WhatsApp Support'],
        ['mdi-logout', 'Logout'],
      ],

      categories: [
        {
          name: 'Fruits & Vegetables',
          src: require('@/assets/imgs/cats/1.jpg'),
        },
        {
          name: 'Grocery',
          src: require('@/assets/imgs/cats/2.png')
        }
      ],

      products: [
        {
          name: 'Banana',
          src: require('@/assets/imgs/products/1.jpg'),
          qty: 0,
          marketPrice: '₹60.00',
          price: '₹50.00',
          save: '₹10.00',
          size: '1kg',
          class: 'border-bottom'
        },
        {
          name: 'Saffola Oil 5lt',
          src: require('@/assets/imgs/products/3.jpg'),
          qty: 0,
          marketPrice: '290.00',
          price: '₹280.00',
          save: '₹10.00',
          size: '5lt',
          class: 'border-bottom'
        }
      ]
    }
  }
}
import Vue from "vue";
import config from "../config";

export default {
  getSubscriptions(storeId, noProgress = true) {
    return Vue.axios.get(`subscription/list/${storeId}`, {
      noProgress: noProgress,
    });
  },

  getSubscriptionsV2(
    storeId,
    tabID,
    pageNumber,
    itemsPerPage,
    keyword = "",
    filters = {},
    noProgress = true
  ) {
    let endpoint = `subscription/list/${storeId}/${tabID}/${pageNumber}/${itemsPerPage}`;
    if (keyword) endpoint += "?keyword=" + keyword;

    return Vue.axios.post(endpoint, filters, { noProgress: noProgress });
  },

  getCounts(storeId, noProgress = true) {
    return Vue.axios.get(`subscription/counts/${storeId}`, {
      noProgress: noProgress,
    });
  },

  getSubscription(subscriptionId, noProgress = true) {
    return Vue.axios.get(`subscription/${subscriptionId}`, {
      noProgress: noProgress,
    });
  },

  createSubscription(_id, body, noProgress = true) {
    return Vue.axios.post(`subscription/${_id}`, body, {
      noProgress: noProgress,
    });
  },

  editSubscription(subscriptionId, body, noProgress = true) {
    return Vue.axios.put(`subscription/${subscriptionId}`, body, {
      noProgress: noProgress,
    });
  },

  deleteSubscription(subscriptionId, noProgress = true) {
    return Vue.axios.delete(`subscription/${subscriptionId}`, {
      noProgress: noProgress,
    });
  },

  skipSubscription(subscriptionId, object, noProgress = true) {
    return Vue.axios.post(
      `subscription/schedule/skip/${subscriptionId}`,
      object,
      { noProgress: noProgress }
    );
  },

  undoSubscription(subscriptionId, object, noProgress = true) {
    return Vue.axios.post(
      `subscription/schedule/undo/${subscriptionId}`,
      object,
      { noProgress: noProgress }
    );
  },

  addSubscription(subscriptionId, object, noProgress = true) {
    return Vue.axios.post(
      `subscription/schedule/add/${subscriptionId}`,
      object,
      { noProgress: noProgress }
    );
  },

  removeSubscription(subscriptionId, object, noProgress = true) {
    return Vue.axios.post(
      `subscription/schedule/remove/${subscriptionId}`,
      object,
      { noProgress: noProgress }
    );
  },

  getSubscriptionEnums(_id, noProgress = true) {
    return Vue.axios.get(`subscription/enums/${_id}`, {
      noProgress: noProgress,
    });
  },

  generateInvoice(_id, token) {
    let endpoint = `${
      process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
    }subscription/invoice/${_id}`;
    return window.open(
      endpoint + "?x-request-user-agent=application/admin-app&token=" + token,
      "_blank"
    );
  },

  generateOrders(_id, object, noProgress = true) {
    return Vue.axios.post(`subscription/generate-orders/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  sendReminders(_id, object, noProgress = true) {
    return Vue.axios.post(`subscription/send-reminders/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  renewSubscriptions(_id, object, noProgress = true) {
    return Vue.axios.post(`subscription/renew/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  massUpdate(_id, object, noProgress = true) {
    return Vue.axios.post(`subscription/mass/${_id}`, object, {
      noProgress: noProgress,
    });
  },

  downloadInvoices(_id, _ids, token) {
    let endpoint = `${
      process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
    }subscription/invoices/${_id}`;
    return window.open(
      endpoint +
        "?_ids=" +
        _ids +
        "&x-request-user-agent=application/admin-app&token=" +
        token,
      "_blank"
    );
  },
};

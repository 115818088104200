import Order from '../../services/order';
import config from '../../config';
import moment from 'moment';

export default {
  
  props: [
    '_id',
    'order',
    'shipment'
  ],

  methods: {
    generate(type) {
      if (type == 'label') this.gl = true;
      else this.gm = true;

      const request = type == 'label' ? Order.generateLabel(this.shipment._id, false) : Order.generateManifest(this.shipment._id, false);
      return request
        .then(r => {
          //console.log(r.data);
          window.open(r.data.url, '_blank');
          this.gl = this.gm = false;
        })
        .catch(e => {
          if (e && e.response && e.response.status === 400) {
            this.error = e.response.data.error;
            setTimeout(() => { this.error = '' }, 2500);
          }
          this.gl = this.gm = false;
        });
    },

    copyAWB() {
      navigator.clipboard.writeText(this.shipment.awbCode);
      this.$store.state.notification.text = 'Copied successfully.';
      this.$store.state.notification.show = true;
    },

    getFormattedDate(date) {
      return moment(date).format('ll');
    },

    getFormattedTime(date) {
      return moment(date).format('h:m a');
    },

    cancelShipment() {
      this.processing = true;
      return Order
        .cancelShipment(this.shipment._id, false)
        .then(r => {
          if (!r.data.ok) {
            this.$store.state.notification.text = 'We could not complete your cancellation request. Please contact our team. Thanks';
            this.$store.state.notification.duration = 5000;
            this.$store.state.notification.show = true;
          } else {
            this.hasCancelled = true;
          }
          this.processing = this.confirmDialog = false;
        })
        .catch(e => {
          if (e && e.response && e.response.status === 400) {
            this.error = e.response.data.error;
            setTimeout(() => { this.error = '' }, 2500);
          }
          this.processing = false;
        });
    },

    trackShipment() {
      if (this.trackingView) return this.trackingView = false;
      else if (this.trackingObject.activities) return this.trackingView = true;

      this.tracking = true;
      return Order
        .trackShipment(this.shipment._id, false)
        .then(r => {
          this.trackingView = true;
          this.trackingObject = r.data;
          this.tracking = false;
        })
        .catch(e => {
          if (e && e.response && e.response.status === 400) {
            this.$store.state.notification.text = 'No data available yet, please try later.';
            this.$store.state.notification.show = true;
          }
          this.tracking = false;
        });
    }
  },

  data() {
    return {
      processing: false,
      gl: false,
      gm: false,
      error: '',
      hasCancelled: false,

      confirmDialog: false,
      trackingObject: {},
      trackingView: false,
      tracking: false,

      checkmarkAnimationJson: config.checkmarkAnimationJson
    }
  }
}
import Store from "../../../services/store";
import router from "../../../router";

import config from "../../../config";

import BannerForm from "../../../components/banner-form/banner-form.vue";

export default {
  name: "Banners",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Banners"))
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchBanners();
  },

  components: {
    BannerForm,
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchBanners() {
      this.processing = this.loading = true;
      return Store.getBanners(this._id, false)
        .then((x) => {
          this.banners = x.data;
          this.banners.forEach((x, index) => (x.index = index + 1 + "."));
          this.processing = this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.banners.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.order = this.editedIndex >= 0 ? this.editedIndex + 1 : 1;
      if (!this.editedItem.bannerType) this.editedItem.bannerType = "regular";
      this.$store.state.bannerModel = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteBanner(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchBanners();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true, true);
    },

    save(closeModal = true, fetchBanners = true, forceSave) {
      if (
        !forceSave &&
        (!this.editedItem.file ||
          (this.$refs.form && !this.$refs.form.validate()))
      ) {
        if (!this.editedItem.file) {
          this.showErr = "Video or image is required";
          setTimeout(() => {
            this.showErr = false;
          }, 2500);
        }

        this.$refs.form.validate();
        return;
      }

      Store.snackbarStart();
      this.processing = this.editedItem.processing = true;
      let request;

      const object = Object.assign({}, this.editedItem);
      if (!object.redirectToCategory || object.redirectToCategory === "")
        delete object.redirectToCategory;
      if (!object.redirectToProduct || object.redirectToProduct === "")
        delete object.redirectToProduct;
      if (this.editedItem._id)
        request = Store.editBanner(this.editedItem._id, object, false);
      else request = Store.createBanner(this._id, object, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          if (fetchBanners) this.fetchBanners();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = this.editedItem.processing = false;
          Store.snackbarError();
        });
    },
  },

  watch: {
    bannerUpdated(n) {
      if (n) this.fetchBanners();
      this.bannerUpdated = false;
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data() {
    return {
      _id: "",
      banners: [],

      valid: false,
      processing: false,
      loading: false,
      uploading: false,
      showErr: false,
      dialog: false,
      search: "",

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
        type: "image",
        file: undefined,
        position: 1,
        bannerType: "regular",
        showContent: false,
      },

      confirmDialog: false,
      beingDeleted: {},
      rowItems: config.rowItems,
      bannerUpdated: false,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        { text: "Type", value: "type" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],
      },
    };
  },
};

import moment from "moment";
import { api as fullscreen } from "vue-fullscreen";

import LineChart from "./line-chart.js";
import Store from "../../../services/store";
import POS from "../../../services/pos";
import Util from "../../../services/util";

import User from "../../../services/user";
import Order from "../../../services/order";
import StatusChip from "../../../components/general/status-chip.vue";

export default {
  name: "Dashboard",

  // sockets: {
  //   // eslint-disable-next-line
  //   newOrder(data = {}) {
  //     this.notify();
  //     this.fetchRecentOrders();
  //   }
  // },

  created() {
    if (!Store.amIAllowed(this.$store, "Dashboard"))
      return Store.redirectToHome();
    this._id = this.$route.params._id;
    this.store = this.$store.state.activeStore;

    this.initPusher();

    this.loading = true;
    //const p1 = this.fetchStore();
    const p2 = this.fetchRecentOrders();
    const p3 = !this.isVendor ? this.fetchRecentCustomers() : [];
    const p4 = this.fetchChartData();
    const p5 = this.fetchOrderEnums();
    const p6 = this.fetchNumbers();

    if (this.doIHaveAccess("edit"))
      this.odHeaders.push({
        text: "Action",
        align: "center",
        value: "actions",
        sortable: false,
      });

    return Promise.all([p2, p3, p4, p5, p6])
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false));
  },

  computed: {
    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    userInfo() {
      return this.$store.getters.userInfo;
    },
    loggedInName() {
      return this.userInfo.name || this.userInfo.mobile;
    },

    greetingMessage() {
      const today = new Date();
      const curHr = today.getHours();

      if (curHr < 12) return "Good Morning!";
      else if (curHr < 18) return "Good Afternoon!";
      return "Good Evening!";
    },

    posAccess() {
      return POS.posAccess() && this.amIAllowed("POS");
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    lcolor() {
      return this.isDT ? "grey darken-4" : "grey lighten-4";
    },

    hcifv() {
      return (
        this.isVendor &&
        this.store &&
        this.store.vendorOwner.hideCustomerInfoFromVendors
      );
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  components: {
    LineChart,
    StatusChip,
  },

  methods: {
    initPusher() {
      const channelName = this._id;
      const eventName = "newOrder";

      const pusher = this.$store.state.pusher;
      let channel = pusher.channel(channelName);
      if (!channel) channel = pusher.subscribe(channelName);

      if (
        this.$store.state.pusherBusinessEvents.includes(
          "dashboard:" + eventName
        )
      )
        return;
      this.$store.state.pusherBusinessEvents.push("dashboard:" + eventName);

      channel.bind(eventName, () => {
        if (this.$route.name == "store-dashboard") this.refreshDashboard();
      });
    },

    amIAllowed(module) {
      //return this.store.amIOwner || this.$store.state.userInfo.roles.includes('superadmin') || this.store.modules && this.store.modules.includes(module);
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    notify() {
      this.$notification.show("You've received a new order! ✌️", {}, {});
    },

    refreshDashboard() {
      this.fetchNumbers();
      this.fetchChartData();
      this.fetchRecentOrders();
      this.fetchRecentCustomers();
    },

    fetchStore() {
      this.sfetching = true;
      return Store.getStore(this._id, false)
        .then((x) => {
          this.store = x.data;
          this.sfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.sfetching = false;
        });
    },

    fetchNumbers() {
      this.nfetching = true;
      return Store.getStoreNumbers(this._id, false)
        .then((x) => {
          this.numbers = x.data;
          this.nfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.nfetching = false;
        });
    },

    toggleFullscreen() {
      fullscreen.toggle(this.$el.querySelector(".fullscreen-wrapper"), {
        teleport: true,
        callback: () => {
          this.$store.state.fullscreenMode = !this.$store.state.fullscreenMode;
        },
      });
    },

    fetchOrderEnums() {
      return Order.getOrderEnums(this._id)
        .then((res) => {
          this.enums = res.data;
          this.enums.status = this.enums.status.map((x) => {
            return {
              key: x,
              value: x.charAt(0).toUpperCase() + x.slice(1),
            };
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    changeStatus(order, status) {
      this.processing = true;
      Store.snackbarStart();
      order.sd = status.charAt(0).toUpperCase() + status.slice(1);
      return Order.changeStatus(order._id, status, true, false)
        .then(() => {
          order.status = status;
          this.processing = false;
          Util.refreshCounts(this._id);
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    fetchRecentOrders() {
      this.oloading = true;
      return Order.getRecentOrders(this._id, false, this.isVendor)
        .then((res) => {
          this.orders = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            x.ago = moment(x.createdAt).format("lll");
            x.name = x.user ? x.user.name : "-";
            x.amount = x.amount || "-";
            x.paymentStatus = x.paymentStatus || "-";
            x.sd = x.status
              ? x.status.charAt(0).toUpperCase() + x.status.slice(1)
              : "-";
            return x;
          });
          this.oloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.oloading = false;
        });
    },

    fetchRecentCustomers() {
      this.cloading = true;
      return User.getRecentUsers(this._id, false)
        .then((res) => {
          this.customers = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            x.joined = moment(x.createdAt).format("lll");
            x.named = x.name || "-";
            x.emaild = x.email || "-";
            x.orders = x.orders || 0;
            x.status = !(x.disabled && x.deactivated && x.deleted)
              ? "Active"
              : "Inactive";
            return x;
          });
          this.cloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.cloading = false;
        });
    },

    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },

    fetchChartData() {
      return Store.getStoreCharts(this._id, false)
        .then((x) => {
          const chartInfo = x.data;
          this.customersForChart = chartInfo.customers;
          this.orderssForChart = chartInfo.orders;

          this.fillData();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    getDaysInMonth() {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      return new Date(year, month, 0).getDate();
    },

    lightenColor(color, percent = 10) {
      let num = parseInt(color.replace("#", ""), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = ((num >> 8) & 0x00ff) + amt,
        G = (num & 0x0000ff) + amt;
      return (
        "#" +
        (
          0x1000000 +
          (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
          (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
          (G < 255 ? (G < 1 ? 0 : G) : 255)
        )
          .toString(16)
          .slice(1)
      );
    },

    fillData() {
      const allDays = Array.from(Array(this.getDaysInMonth() + 1).keys());
      allDays.splice(0, 1);
      (this.datacollection = {
        labels: allDays,
        datasets: [
          {
            label: "Customers - " + moment().format("MMMM, YYYY"),
            backgroundColor: this.$vuetify.theme.themes.light.primary,
            data: this.customersForChart,
          },
        ],
      }),
        (this.datacollection2 = {
          labels: allDays,
          datasets: [
            {
              label: "Orders - " + moment().format("MMMM, YYYY"),
              backgroundColor: this.lightenColor(
                this.$vuetify.theme.themes.light.primary
              ),
              data: this.orderssForChart,
            },
          ],
        });
    },

    amountFormatted(order) {
      let formattedAmount = order.amount;
      if (order.currencyCode === "INR")
        formattedAmount = this.currencyPipe(order.amount, "₹");
      else if (order.currencyCode && order.currencyCode !== "INR")
        formattedAmount = this.currencyPipe(order.amount, order.currencyCode);
      else if (!this.$store.state.country.currencyCode && !order.currencyCode)
        formattedAmount = this.currencyPipe(order.amount, "₹");
      else
        formattedAmount = this.currencyPipe(
          order.amount,
          this.$store.state.country.currencySymbol
        );

      return formattedAmount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    formateDate(date, mode) {
      if (mode === "day") return moment(date).format("ll");
      else if (mode === "month") return moment(date).format("MMM, YYYY");
    },

    fetchCardData() {
      this.cdprocessing = true;
      return Store.getCardData(this._id, this.calMode, this.calDate, false)
        .then((x) => {
          if (this.calMode == "day") this.numbers.todayEarning = x.data;
          else if (this.calMode == "month")
            this.numbers.thisMonthEarning = x.data;
          this.cdprocessing = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.cdprocessing = false;
        });
    },

    copyID(_id) {
      navigator.clipboard.writeText(_id);
      this.copyOIDText = "Copied";
      setTimeout(() => (this.copyOIDText = "Copy Order ID"), 1500);
    },

    getNumberWithOrdinal(n) {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },

    getCallingCode(item) {
      return item.callingCode || this.$store.state.country.callingCode || "91";
    },
  },

  watch: {
    calModal(n) {
      if (!n) {
        if (this.calMode == "day") this.calDayDate = this.calDate;
        else this.calMonthDate = this.calDate;

        this.fetchCardData();
      }
    },
  },

  data() {
    return {
      loading: true,
      store: [],
      _id: "",
      datacollection: null,
      datacollection2: null,
      processing: false,
      cloading: false,
      oloading: false,
      sfetching: false,
      nfetching: false,

      customers: [],
      orders: [],

      enums: {},
      numbers: {},

      customersForChart: [],
      orderssForChart: [],

      calMode: "day",
      calModal: false,
      calDate: new Date().toISOString().substr(0, 10),
      calDayDate: new Date().toISOString().substr(0, 10),
      calMonthDate: new Date().toISOString().substr(0, 10),
      calMaxDate: new Date().toISOString().substr(0, 10),
      cdprocessing: false,

      copyOIDText: "Copy Order ID",

      custHeaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", align: "start", value: "named" },
        { text: "Mobile", value: "mobile" },
        { text: "Email", value: "emaild" },
        { text: "Status", value: "status" },
        { text: "Orders", value: "orders" },
        { text: "Created Date", value: "joined" },
      ],

      odHeaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", align: "start", value: "address.name" },
        { text: "Mobile", value: "address.mobile" },
        { text: "Amount", value: "amount" },
        { text: "Payment Method", value: "paymentMethod" },
        { text: "Order Status", value: "sd" },
        { text: "Date & Time", value: "ago" },
      ],
    };
  },
};

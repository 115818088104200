import _ from 'underscore';

import Store from '../../services/store';
import Inventory from '../../services/inventory';

export default {
  name: 'BulkUpdateProducts',
  
  props: [
    '_id',
    'items',
    'bulkUpdated'
  ],

  mounted() {
    this.fetchCommonEnums();
  },

  watch: {
    '$store.state.bulkUpdateProductsModal'(n) {
      if (n) {
        this.bulkItems = this.items.map((item, index) => {
          item.i = index + 1 +'.';
          return item;
        });
      }
    }
  },

  methods: {
    save() {
      if (!this.$refs.form.validate()) return;

      const modifiedItems = [];
      this.bulkItems.forEach(item => {
        if (item.modified) {
          modifiedItems.push({
            _id: item._id,
            name: item.name,
            price: item.price,
            marketPrice: item.marketPrice,
            quantity: item.quantity,
            inStock: item.inStock,
            size: item.size,
            unit: item.unit,
            tags: item.tags,
            barcodeID: item.barcodeID,
            barcodeText: item.barcodeText,
            gst: item.gst,
            hsnSacCode: item.hsnSacCode
          })
        }
      });

      if (!modifiedItems.length) {
        this.$store.state.snackbar.text = 'No new changes to save';
        this.$store.state.snackbar.timeout = 2500;
        this.$store.state.snackbar.color = 'error';
        this.$store.state.snackbar.show = true;
        return;
      }

      Store.snackbarStart(); 
      this.processing = true;

      return Inventory
        .bulkUpdateProducts(this._id, modifiedItems, false)
        .then(() => {
          this.$store.state.bulkUpdateProductsModal = this.processing = false;
          this.$emit('update:bulkUpdated', true);
          Store.snackbarSuccess();
        })
        .catch(e => {
          console.log(e);
          this.$store.state.bulkUpdateProductsModal = this.processing = false;
          Store.snackbarError();
        });
    },

    handleTags(item) {
      const allTags = item.tags;
      const newArray = allTags.join(', ').split(',');
      item.tags = newArray && newArray[0] ? newArray : [];
    },

    verifyDuplicacy: _.debounce(function(item) {
      if (!item.barcodeID) return;
      return Store
        .isDuplicateProduct(this._id, { barcodeID: item.barcodeID })
        .then(x => {
          if (x.data) {
            item.barcodeID = '';
            this.$store.state.snackbar.text = 'Duplicate Barcode ID';
            this.$store.state.snackbar.show = true;
          }
        })
        .catch(e => console.log(e))
    }, 1000),

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return this.cenums = ce;

      this.processing = true;
      return Store.getCommonEnums()
        .then(x => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
          this.processing = false;
        })
        .catch(e => {
          console.log(e)
          this.processing = false;
        });
    }
  },

  data() {
    return {
      processing: false,
      cenums: {},
      bulkItems: [],

      expanded: [],
      search: '',
      headers: [
        { text: '#', value: 'i', align: 'start', sortable: false },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Size', value: 'size', sortable: false, width: '12%' },
        { text: 'Unit', value: 'unit', sortable: false, width: '12%' },
        //{ text: 'In Stock?', value: 'inStock', sortable: false, width: '12%' },
        { text: 'Quantity', value: 'quantity', sortable: false, width: '15%' },
        { text: 'MRP', value: 'marketPrice', sortable: false, width: '15%' },
        { text: 'Price', value: 'price', sortable: false, width: '15%' }
      ],

      headers2: [
        { text: 'Tags', value: 'tags', sortable: false, width: '100%' }
      ],

      validationRules: {
        basicRule: [
          v => !!v || 'It\'s required'
        ],
        statusRule: [
          v => !!v || v === false || 'It\'s is required'
        ],

        lengthRule: [
          v => !!v && !!v.length || 'It\'s required'
        ],
        quantityRule: [
          v => v >= 0 || 'Enter a valid number'
        ],

        unitRule: [
          v => v === '' || !!v || 'Select a unit'
        ],

        priceRule: [
          v => v >= 0 || 'Enter a valid price'
        ],

        numberRule: [
          v => !!v && v >= 0 || 'Enter a valid number'
        ],

        marketPriceRule: [
          v => !v || v >= 0 || 'Enter a valid number'
        ],

        barcodeIDRule: [
          v => !v.isDuplicate || 'Duplicate Barcode ID'
        ]
      }
    }
  }
}
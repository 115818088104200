import Vue from "vue";

export default {
  upload(storeId, fileType, file, format = "image") {
    const formData = new FormData();
    formData.append("file", file, file.name);

    return Vue.axios.post(
      `file/upload/${storeId}/${fileType}/${format}`,
      formData
    );
  },
};

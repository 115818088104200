<template>
  <v-dialog v-model="$store.state.pickupAddressPickerModal" max-width="1000" persistent>
    <v-progress-linear v-if="processing" indeterminate color="primary" class="mb-0"></v-progress-linear>
    <v-card>
      <div style="height: 10px"></div>
      <v-card-text>
        <v-row>
          <v-col cols="8" class="vc">
            <h5>Pick a pickup address to continue</h5>
            <div>Choose a pickup address from your available pickup addresses</div>
          </v-col>
        </v-row>

        <b-row>
          <b-col class="col-12" style="padding-top: 0">
            <v-text-field
              v-model="search"
              clearable
              label="Search"
              single-line
              hide-details></v-text-field>
          </b-col>
          <b-col class="col-12">
            <v-data-table
              :headers="headers"
              :items="pickupAddresses"
              :footer-props="{'items-per-page-options': rowItems}"
              :search="search"
              show-select
              :single-select="true"
              v-model="selected"
              :loading="processing"
              :disabled="processing"
              item-key="_id"
              loading-text="Loading...">
              
              <template v-slot:item.index="{ item }">
                <span>{{ item.index + 1 }}.</span>
              </template>

              <template v-slot:item.street="{ item }">
                <span>{{ item.street }} {{item.area ? ', '+item.area : ''}}{{item.landmark ? ', '+item.landmark : ''}}</span>
              </template>

            </v-data-table>
          </b-col>
        </b-row>

        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
         </v-row>

         <v-row>
            <v-col style="padding-top: 0; padding-bottom: 0;" cols="3" class="text-left vc">
              <v-btn color="error" :disabled="processing" outlined @click="$parent.cancelAtPickupAddress">Cancel</v-btn>
            </v-col>
            <v-col style="padding-top: 0; padding-bottom: 0;" class="text-right">
              <v-row>
                <v-col v-if="$parent.selectedPickupAddress.name" cols="8" class="vc">
                  <span>Your selected address is <b>{{ $parent.selectedPickupAddress.name }}, {{ $parent.selectedPickupAddress.mobile }}</b>.</span>
                </v-col>
                <v-col>
                  <v-btn :disabled="processing" text @click="$parent.previousFromPickupAddress">Previous</v-btn>
                  <v-btn outlined color="primary" :disabled="processing || !$parent.selectedPickupAddress.name" @click="$parent.continueFromPickupAddress">Continue</v-btn>
                </v-col>
              </v-row>
            </v-col>
         </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  import config from '../../config';

  export default {
    props: [
      'pickupAddresses',
      'processing',
      'selectedPickupAddress'
    ],

    watch: {
      selected() {
        this.$emit('update:selectedPickupAddress', this.selected[0] || {});
      }
    },

    data() {
      return {
        search: '',
        rowItems: config.rowItems,
        selected: [],
        headers: [
          { text: '#', align: 'start', value: 'index' },
          { text: 'Name', value: 'name' },
          { text: 'Mobile', value: 'mobile' },
          { text: 'Email', value: 'email' },
          { text: 'Address', value: 'street' },
          { text: 'Pincode', value: 'pincode' },
          { text: 'City', value: 'city' },
          { text: 'State', value: 'state' },
          { text: 'Country', value: 'country' },
        ]
      }
    }
  }
</script>
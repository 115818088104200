import Vue from "vue";
import VueHtmlToPaper from "vue-html-to-paper";
import moment from "moment";
import domtoimage from "dom-to-image";
import jsPDF from "jspdf";
import { Printd } from "printd";
import { getS3URL } from "../../helpers/misc";

const options = {
  name: "Invoice",
  specs: ["fullscreen=no", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    "https://growcify.s3-us-west-1.amazonaws.com/css/thermal-invoice.css",
  ],
};

Vue.use(VueHtmlToPaper, options);

export default {
  name: "ThermalInvoiceContent",

  props: ["_id", "order", "hideControls"],

  watch: {
    "$store.state.thermalInvoiceModal"(n) {
      this.isKOT = false;
      if (n) {
        this.isKOT = this.$store.state.isKOT;
        if (this.order.print) setTimeout(() => this.printInvoice(), 250);
      }
    },
  },

  methods: {
    printInvoice() {
      const cssText = `
        .spacercstm {
          margin-top: 15px;
          border-bottom: 1.5px dashed;
          margin-bottom: 15px;
          width: 100%;
        }
        .invoicelogo-img {
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
        .p0 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        .pr-0 {
          padding-right: 0 !important;
        }
        .mt--10 {
          margin-top: -25px;
        }
        #invoice {
          width: ${this.is2Inch ? "2in" : "3in"};
          background: white;
          font-size: 10px;
          padding-left: ${this.is2Inch ? 5 : 3}px;
          padding-right: ${this.is2Inch ? 5 : 3}px;
          font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        }
        h6, .h6 {
          font-size: 1rem;
        }
        .items {
          font-size: 10px
        }
        .text-center {
          text-align: center !important;
        }
        .text-right {
          text-align: right !important;
        }
        .container {
          width: 100%;
        }
        .row {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
        }
        .col {
          flex-basis: 0;
          flex-grow: 1;
          min-width: 0;
          max-width: 100%;
        }
        .p0 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        .pt-2 {
          padding-top: 8px !important;
        }
        .pt-1 {
          padding-top: 4px !important;
        }
        .col-1 {
          flex: 0 0 8.333333%;
          max-width: 8.333333%;
        }
        .col-2 {
          flex: 0 0 16.666667%;
          max-width: 16.666667%;
        }
        .col-5 {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
        .col-12 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .col-6 {
          flex: 0 0 50%;
          max-width: 50%;
        }
        .pr-0 {
          padding-right: 0px !important;
        }
        .v-data-table {
          max-width: 100%;
          border-radius: 4px;
        }
        .v-data-table__wrapper {
          overflow-x: auto;
          overflow-y: hidden;
        }
        .v-data-table table {
          width: 100%;
          border-spacing: 0;
        }
        table {
          border-collapse: collapse;
        }
        .v-data-table thead tr:last-child th {
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
        }
        .text-left {
          text-align: left !important;
        }
        .text-right {
          text-align: right !important;
        }
        .v-data-table td, .v-data-table th {
          padding: 0 10px;
        }
        .v-data-table th {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          height: 25px;
        }
        .text-black {
          color: black !important;
        }
        .v-data-table td {
          height: 25px;
          padding: 0 10px;
        }
        .pl-0 {
          padding-left: 0px !important;
        }
        .pr-0 {
          padding-right: 0px !important;
        }
        .pr-5px {
          padding-right: 3px !important;
        }
        .mdi-chevron-right {
          content: '>'
        }
        @media print {
          body {
            display: table;
            table-layout: fixed;
            margin-top: 0px;
            margin-left: 0px;
            margin-right: 0px;
            height: auto;
          }
        }
      `;
      const d = new Printd();
      d.print(document.getElementById("invoice"), [cssText]);
    },

    getPhotoURL(data) {
      if (data && data.split(".")[1] === "png") {
        return getS3URL(this._id, data);
      }
      return data;
    },

    getLogoURL() {
      return this.getPhotoURL(this.$store.state.activeStore.appLogo);
    },

    downloadAsPDF() {
      const order = this.order;
      const element = document.getElementById("invoice");
      element.style.paddingLeft = "5px";
      domtoimage.toPng(element).then(function(imgData) {
        if (!imgData) return;
        element.style.paddingLeft = "0px";
        const doc = new jsPDF();
        doc.addImage(imgData, "PNG", 0, 0);
        doc.save("Invoice-" + order._id + ".pdf");
      });
    },

    downloadAsImage() {
      const order = this.order;
      const element = document.getElementById("invoice");
      element.style.paddingLeft = "5px";
      element.style.paddingRight = "5px";
      domtoimage.toPng(element).then(function(imgData) {
        if (!imgData) return;
        element.style.paddingLeft = "0px";
        const a = document.createElement("a");
        a.href = imgData;
        a.setAttribute("download", "Invoice-" + order._id + ".png");
        a.click();
      });
    },

    hasColumn(name) {
      const tic = this.$store.state.activeStore.thermalInvoiceColumns;
      if (tic && tic.length && tic.includes(name)) return true;
      return false;
    },

    getSaving(type = "percent") {
      let a1 = 0;
      this.order.items.forEach((x) => (a1 = a1 + x.qty * x._id.marketPrice));

      const a2 = this.order.amount;
      if (type == "percent") return ((a1 * 100) / a2 - 100).toFixed(0);
      return a1 - a2;
    },

    getCallingCode(item) {
      let cc = this.$store.state.country.callingCode || "91";
      if (item.address && item.address.callingCode)
        cc = item.address.callingCode;
      else if (!item.address && item.user && item.user.callingCode)
        cc = item.user.callingCode;
      return cc;
    },
  },

  created() {
    this.store = this.$store.state.activeStore;
  },

  computed: {
    currency() {
      return !this.order.currencyCode || this.order.currencyCode == "INR"
        ? "₹"
        : this.order.currencyCode;
    },
    createdAt() {
      return moment(this.order.createdAt).format("lll");
    },

    totalQuantities() {
      return this.oitems.reduce((a, b) => a + b.qty, 0) || 0;
    },

    subtotal() {
      let subtotal = 0;
      if (this.order.items) {
        this.order.items.forEach((x) => {
          const itemTotal = x.qty * x.price;
          subtotal = subtotal + itemTotal;
        });
      }

      if (this.order.type == "pickup-drop") {
        subtotal =
          subtotal +
          (this.order.deliveryChargePerKM || 0) *
            (this.order.distance - this.order.baseDistance);
      }

      return subtotal;
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    getOrderAmount() {
      let amount = this.order.amount;
      if (
        this.isVendor &&
        this.$store.state.activeStore.vendorOwner.hideDeliveryChargeFromVendors
      )
        amount = amount - this.order.deliveryCharge || 0;
      return amount;
    },

    descriptionColumn() {
      let size = 5;
      const tic = this.$store.state.activeStore.thermalInvoiceColumns;
      if (tic && tic.length) {
        if (tic.includes("SKU")) size--;
        if (tic.includes("MRP")) size--;
      }
      return size;
    },

    is2Inch() {
      const store = this.$store.state.activeStore;
      if (
        store.thermalInvoicePrinter &&
        store.thermalInvoicePrinter === "2inch"
      )
        return true;
      return false;
    },

    allItemsHaveMRP() {
      return (
        this.order &&
        this.order.items &&
        this.order.items.every((x) => x._id.marketPrice)
      );
    },

    oitems() {
      if (!this.order || !this.order.items) return [];
      const items = [];
      this.order.items.forEach((item, index) => {
        const product = {
          index: index + 1,
          description: item.name || item._id.name,
          sku: item.sku || item._id.sku,
          qty: item.qty,
          size: item.size,
          unit: item.unit,
          price: item.price,
          marketPrice: item.marketPrice,
          color: item.color,
          gst: item.gst,
          total: item.price * item.qty,
          isCombo: item.isCombo,
          comboItems: item.comboItems,
        };

        items.push(product);
        if (item.isCombo) {
          item.comboItems.forEach((c) => {
            const cobject = {
              _id: c._id,
              qty: c.deductQty,
              description: c._id.name,
              size: c._id.size,
              unit: c._id.unit,
              color: c._id.color,
              sku: c._id.sku,
              ignore: true,
            };
            items.push(cobject);
          });
        }
      });

      return items;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    hcifv() {
      return (
        this.isVendor &&
        this.store &&
        this.store.vendorOwner.hideCustomerInfoFromVendors
      );
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    isCOD() {
      return ["COD"].includes(this.order.paymentMethod);
    },
  },

  data() {
    return {
      processing: false,
      store: {},
      isKOT: false,

      headers: [
        {
          text: "#",
          align: "start",
          value: "index",
          sortable: false,
          class: "text-black pl-0",
        },
        {
          text: "Item",
          value: "description",
          sortable: false,
          class: "text-black pl-0",
        },
        //{ text: 'SKU', value: 'sku', sortable: false,  class: 'text-black pl-0' },
        {
          text: "Qty",
          value: "qty",
          sortable: false,
          class: "text-black pl-0",
        },
        //{ text: 'MRP', value: 'marketPrice', sortable: false,  class: 'text-black pl-0' },
        {
          text: "Price",
          value: "price",
          sortable: false,
          class: "text-black pl-0",
        },
        {
          text: "GST",
          value: "gst",
          sortable: false,
          class: "text-black pl-0",
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          align: "right",
          class: "text-black pl-0 pr-0",
        },
      ],
    };
  },
};

import _ from "underscore";
import Store from "../../../services/store";
import router from "../../../router";
import config from "../../../config";

export default {
  name: "Communities",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Orders")) return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchCommunities();
    this.fetchCommunitiesEnums();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchCommunities() {
      this.loading = true;
      return Store.getCommunities(this._id, false)
        .then((res) => {
          this.communities = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            x.status = x.isActive ? "Active" : "Inactive";
            return x;
          });
          this.loading = false;
        })
        .catch((e) => console.log(e));
    },

    fetchCommunitiesEnums() {
      return Store.getCommunityEnums(this._id, false)
        .then((res) => (this.enums = res.data))
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.communities.indexOf(item);

      //new schedule thing / schedule
      if (item.schedule && item.schedule.length) this.schedule = item.schedule;

      this.selectedPlace = { placeId: item.placeId, description: item.address };
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteCommunity(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchCommunities();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      setTimeout(
        () => (this.editedItem = Object.assign({}, this.defaultItem)),
        100
      );
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    save(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      //new schedule thing / schedule
      if (this.schedule && this.schedule.length)
        this.editedItem.schedule = this.schedule;
      else if (!this.editedItem.schedule) this.editedItem.schedule = [];

      if (this.editedItem._id)
        request = Store.editCommunity(
          this.editedItem._id,
          this.editedItem,
          false
        );
      else request = Store.addCommunity(this._id, this.editedItem, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          this.fetchCommunities();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          this.loading = false;
          Store.snackbarError();
        });
    },

    addSchedule() {
      this.schedule.push(JSON.parse(JSON.stringify(this.scheduleItem)));
    },

    removeSchedule(index) {
      this.schedule.splice(index, 1);
    },

    selectTime(index, type) {
      const item = this.schedule[index];
      if (item && item[type]) this.selectedTime = item[type];

      this.timeDialogType = type;
      this.timeDialogIndex = index;
      this.timeDialog = true;
    },

    timeSelected() {
      const item = this.schedule[this.timeDialogIndex];
      item[this.timeDialogType] = this.selectedTime;
      this.timeDialog = false;
    },

    getSequenceItems() {
      const arr = [1];
      for (let i = 0; i < this.communities.length; i++) arr.push(i + 2);
      return arr;
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add new community" : "Edit community";
    },

    store() {
      return this.$store.state.activeStore;
    },

    dcPrefix() {
      return this.store.deliveryChargeBy == "amount"
        ? this.$store.state.country.currencySymbol
        : "";
    },

    dcSuffix() {
      return this.store.deliveryChargeBy == "percent" ? "%" : "";
    },
  },

  watch: {
    input: _.debounce(function(val) {
      if (
        val === this.lastInput ||
        !val ||
        val == this.selectedPlace.description
      )
        return;

      this.isLoading = true;
      return Store.searchPlaces(this._id, val)
        .then((res) => {
          this.places = res.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }, 500),

    selectedPlace(val) {
      if (!val || !val.placeId || !val.description) return;

      this.editedItem.placeId = val.placeId;
      this.editedItem.address = val.description;
    },

    "editedItem.dcScheduled"(n) {
      if (n)
        this.schedule = this.schedule.length
          ? this.schedule
          : [JSON.parse(JSON.stringify(this.scheduleItem))];
      else this.schedule = [];
    },
  },

  data: () => {
    return {
      _id: "",
      communities: [],
      enums: {},
      search: "",
      loading: true,
      valid: false,
      dialog: false,
      processing: false,

      error: "",

      menu: false,
      cenums: {},

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
        dcScheduled: false,
      },

      isLoading: false,
      places: [],
      input: null,
      lastInput: null,
      selectedPlace: {
        placeId: "",
        description: "",
      },

      confirmDialog: false,
      beingDeleted: {},

      rowItems: config.rowItems,
      scheduleItem: {
        day: "",
        startsAt: "",
        endsAt: "",
        deliveryCharge: "",
      },
      schedule: [],
      timeDialog: false,
      timeDialogType: "",
      selectedTime: "",
      timeDialogIndex: -1,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Address", value: "address" },
        { text: "Pin / Postal / Zip Code", value: "pincode" },
        { text: "Status", value: "status" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],

        placesRule: [
          (v) => !!Object.keys(v).length || "Google location is required",
        ],
      },
    };
  },
};

import config from "../../config";

import Store from "../../services/store";
import StoreList from "../../components/store-list/store-list.vue";
import { getS3URL } from "../../helpers/misc";

export default {
  name: "Signup",

  created() {
    this.setTitle();
    if (this.$route.name == "businesses") {
      this.isProcessCompleted = true;
      this.fetchStores();
      Store.setDefaultAppearance();
    } else {
      this.isProcessCompleted = false;
      this.otpSent = false;
    }
    this.fetchCountries();
    if (this.$route.query && this.$route.query.mobile)
      this.user.mobile = this.$route.query.mobile;
    if (this.$route.query && this.$route.query.cc)
      this.user.callingCode = this.$route.query.cc;

    if (!this.isGD) {
      this.user.otpMode = "sms";
      if (this.domainStore && this.domainStore._id)
        this.user.storeId = this.domainStore._id;
    }
  },

  components: {
    StoreList,
  },

  watch: {
    "$route.name"() {
      this.setTitle();
      if (this.$route.name != "businesses") this.isProcessCompleted = false;
    },
  },

  methods: {
    showModal() {
      this.loginModal = true;
    },
    hideModal() {
      this.loginModal = false;
    },

    setTitle() {
      let title = this.isGD
        ? "Growcify - "
        : this.domainStore && this.domainStore.name
        ? this.domainStore.name + " - "
        : "";
      if (["login", "signin"].includes(this.$route.name)) title += "Sign in";
      else if (this.$route.name == "signup") title += "Sign up";
      else if (this.$route.name == "businesses") title += "Pick a business";

      let array = title.split(" - ");
      if (!array[1]) title = title.replace(" -", "");
      document.title = title; //setting up the title
    },

    submit() {
      this.otpSent ? this.verifyOTP() : this.sendOTP();
    },

    processDefaultCountry() {
      if (this.countries && this.countries.length) {
        const defaultCountry = this.countries.find(
          (x) =>
            x.isDefault ||
            (this.user.callingCode && this.user.callingCode == x.callingCode)
        );
        if (defaultCountry) this.selectedCountry = defaultCountry;
      }
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) {
        this.countries = cs;
        this.processDefaultCountry();
        return;
      }

      this.progress = true;
      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;

          this.processDefaultCountry();
          this.progress = false;
        })
        .catch((e) => {
          console.log(e);
          this.progress = false;
        });
    },

    sendOTP(forceSend = false) {
      if (!this.$refs.form.validate() && !forceSend) return;

      if (this.selectedCountry._id) {
        this.user.callingCode = this.selectedCountry.callingCode;
        this.user.country = this.selectedCountry._id;
      }

      this.progress = true;
      this.startTimer();
      this.$store
        .dispatch("sendOTP", this.user)
        .then(() => {
          this.progress = false;
          this.otpSent = true;

          this.$store.state.snackbar.text =
            "We have sent you an OTP to verify.";
          this.$store.state.snackbar.timeout = 3000;
          this.$store.state.snackbar.color = "success";
          this.$store.state.snackbar.show = true;
        })
        .catch(() => {
          this.progress = false;
          setTimeout(() => {
            this.showError = false;
          }, 2500);

          this.$store.state.snackbar.text = "Something went wrong, try again";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "error";
          this.$store.state.snackbar.show = true;
        });
    },

    verifyOTP() {
      if (!this.$refs.form.validate()) return;

      this.progress = true;
      this.$store
        .dispatch("verifyOTP", this.user)
        .then(() => {
          this.progress = false;
          this.hideModal();
          this.otpSent = false;

          this.$store.state.snackbar.text = "OTP Verified Successfully";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "success";
          this.$store.state.snackbar.show = true;

          if (this.myTimer) window.clearInterval(this.myTimer);
          document.title = this.$store.state.isGD ? "Growcify" : "";

          this.isProcessCompleted = true;
          this.fetchStores();
          this.setTitle();
        })
        .catch((err) => {
          this.progress = false;
          if (err.response.status === 401)
            this.showError = err.response.data.message;

          this.$store.state.snackbar.text =
            this.showError || "Something went wrong, try again";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "error";
          this.$store.state.snackbar.show = true;
        });
    },

    fetchStores() {
      this.loading = true;
      return Store.getStores(this.$store.state.uuid, false)
        .then((x) => {
          this.stores = x.data.stores;
          this.accessStores = x.data.accessStores;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    text(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    onVerify() {
      this.reCAPTCHADone = true;
    },

    onExpired() {
      this.reCAPTCHADone = false;
    },

    onResize() {
      this.isMobile = window.innerWidth < 600;
    },

    startTimer() {
      this.resendOTPButton = false;
      this.seconds = 59;
      this.myTimer = setInterval(() => {
        this.seconds--;
        if (this.seconds === 0) {
          this.resendOTPButton = true;
          if (this.myTimer) window.clearInterval(this.myTimer);
        }
      }, 1000);
    },
  },

  computed: {
    numberRules() {
      if (this.selectedCountry) {
        const length = this.selectedCountry.minNumberLength || 10;
        return [
          (v) =>
            (!!v &&
              !isNaN(this.user.mobile) &&
              this.user.mobile.length >= length) ||
            "Mobile number is required",
        ];
      }
      return [];
    },

    isLocalhost() {
      return window.location.origin.includes("localhost");
    },

    getTimer() {
      return "00:" + (this.seconds < 10 ? "0" + this.seconds : this.seconds);
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isGD() {
      return this.$store.state.isGD;
    },

    domainStore() {
      return this.$store.state.domainStore;
    },

    ngdLogo() {
      if (!this.domainStore || !this.domainStore.appLogo) return false;
      return getS3URL(this.domainStore._id, this.domainStore.appLogo);
    },

    isSignup() {
      return this.$route.name === "signup";
    },
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      isMobile: false,

      showError: false,
      showSuccess: false,
      loginModal: false,
      valid: false,
      progress: false,
      dloading: false,
      otpSent: false,
      params: {},
      user: {
        name: "",
        mobile: "",
        otpMode: "sms",
        storeId: false,
      },
      countries: [],
      selectedCountry: {},

      reCAPTCHADone: false,
      reCAPTCHAKey: config.reCAPTCHAKey,

      resendOTPButton: false,
      myTimer: false,
      seconds: 59,

      loading: false,
      stores: [],
      accessStores: [],
      isProcessCompleted: false,

      validationRules: {
        mobileRules: [
          (v) => !!v || "Mobile number is required",
          (v) =>
            /^[0]?[6789]\d{9}$/.test(v) || "Please enter a valid mobile number",
        ],
        otpRules: [(v) => (v && this.otpSent) || "OTP is required"],
        basicRule: [(v) => !!v || "Name is required"],
        emailRule: [
          (v) => (v && emailregex.test(v)) || "Must be a valid email",
        ],
      },
      placeholder: config.meta.placeholder,
    };
  },
};

import Store from "../../../services/store";
import Subscription from "../../../services/subscription";
import router from "../../../router";

import moment from "moment";

import InfoCard from "../../../components/general/info-card.vue";
import AddressString from "../../../components/general/address-string.vue";
import AddressNavigation from "../../../components/general/address-navigation.vue";

export default {
  name: "Subscription",

  components: {
    InfoCard,
    AddressString,
    AddressNavigation,
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add more items to this order"
        : "Edit items of this order";
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    paymentMode() {
      if (this.loading || !this.subscription) return "";
      return this.subscription.isPrepayment
        ? "Prepayment"
        : this.subscription.isPostpaid
        ? "Postpaid"
        : "Prepaid";
    },

    country() {
      return this.$store.state.country;
    },
  },

  created() {
    this._id = this.$route.params._id;
    if (
      !this.amIAllowed("Subscriptions") ||
      !this.$store.state.activeStore.subscriptionEnabled ||
      this.$store.state.activeStore.isVendor
    )
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.subscriptionId = this.$route.params.subscriptionId;
    this.fetchSubscription();
    this.fetchCommonEnums();
    this.fetchDeliverymen();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    capitalize(value) {
      if (!value) return value;
      return value.charAt(0).toUpperCase() + value.slice(1);
    },

    processCEnums() {
      this.cenums.subscriptionStatuses = this.cenums.subscriptionStatuses.map(
        (x) => {
          if (typeof x == "object") return x;
          return {
            key: x,
            value: this.capitalize(x),
          };
        }
      );
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) {
        this.cenums = ce;
        this.processCEnums();
      }

      return Store.getCommonEnums(false)
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
          this.processCEnums();
        })
        .catch((e) => console.log(e));
    },

    fetchSubscription(fsonly = false) {
      if (fsonly) this.fsonly = true;
      else this.loading = true;
      return Subscription.getSubscription(this.subscriptionId, false)
        .then((res) => {
          this.subscription = res.data;
          this.subscription.schedule.forEach((x, i) => {
            x.index = i + 1 + ".";
            x.dated =
              moment(x.date).format("dddd") +
              ", " +
              moment(x.date).format("ll");
          });
          this.subscription.startDated = moment(
            this.subscription.startDate
          ).format("ll");
          this.subscription.endDated = moment(this.subscription.endDate).format(
            "ll"
          );
          this.subscription.createdAtD = moment(
            this.subscription.createdAt
          ).format("lll");
          this.subscription.preferredTimingd = moment(
            this.subscription.preferredTiming
          ).format("h:mm a");

          const items = this.subscription.items || [];
          if (!items.length) {
            const product = this.subscription.product;
            items.push({
              _id: product._id,
              name: product.name,
              qty: this.subscription.qty,
              size: this.subscription.size,
              unit: this.subscription.unit,
              price: this.subscription.price,
            });
          }
          this.subscription.items = items;

          if (!this.subscription.perDeliveryTotal) {
            const deliveryCharge = this.subscription.deliveryCharge || 0;
            const itemsTotal = items.reduce((a, b) => b.price * b.qty + a, 0);
            const perDeliveryTotal = itemsTotal + deliveryCharge;
            this.subscription.perDeliveryTotal = perDeliveryTotal;
          }

          this.os = JSON.parse(JSON.stringify(this.subscription));
          this.loading = this.fsonly = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = this.fsonly = false;
        });
    },

    formattedItems() {
      const items = this.subscription.items || [];
      return items.map((item, index) => {
        item.index = index + 1 + ".";
        return item;
      });
    },

    openMap() {
      const lat = this.subscription.address.lat;
      const long = this.subscription.address.long;
      const url = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      window.open(url, "_system");
    },

    scheduleConfirmation(item) {
      this.skippingScheduleItem = item;
      this.confirmSubscriptionDialog = true;
    },

    closeSkip() {
      this.skippingScheduleItem = {};
      this.confirmSubscriptionDialog = false;
    },

    skipSchedule(type) {
      Store.snackbarStart();
      this.processing = true;
      const object = { scheduleId: this.skippingScheduleItem._id, type: type };
      return Subscription.skipSubscription(this.subscriptionId, object, false)
        .then(() => {
          this.processing = false;
          this.confirmSubscriptionDialog = false;
          this.fetchSubscription(true);
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    scheduleUnduConfirmation(item) {
      this.skippingScheduleItem = item;
      this.confirmUndoDialog = true;
    },

    closeUndo() {
      this.skippingScheduleItem = {};
      this.confirmUndoDialog = false;
    },

    undoSchedule() {
      Store.snackbarStart();
      this.processing = true;
      const object = { scheduleId: this.skippingScheduleItem._id };
      return Subscription.undoSubscription(this.subscriptionId, object, false)
        .then(() => {
          this.processing = false;
          this.confirmUndoDialog = false;
          this.fetchSubscription(true);
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    scheduleAddConfirmation() {
      this.confirmAddDialog = true;
    },

    closeAdd() {
      this.confirmAddDialog = false;
    },

    addSchedule() {
      Store.snackbarStart();
      this.processing = true;
      return Subscription.addSubscription(this.subscriptionId, {}, false)
        .then(() => {
          this.processing = false;
          this.confirmAddDialog = false;
          this.fetchSubscription(true);
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    scheduleRemoveConfirmation(item) {
      this.confirmRemoveDialog = true;
      this.skippingScheduleItem = item;
    },

    closeRemove() {
      this.confirmRemoveDialog = false;
      setTimeout(() => (this.skippingScheduleItem = {}), 100);
    },

    removeSchedule() {
      Store.snackbarStart();
      this.processing = true;
      const object = { scheduleId: this.skippingScheduleItem._id };
      return Subscription.removeSubscription(this.subscriptionId, object, false)
        .then(() => {
          this.processing = false;
          this.confirmRemoveDialog = false;
          this.fetchSubscription(true);
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    getFormattedDate() {
      return (
        moment(this.skippingScheduleItem.date).format("dddd") +
        ", " +
        moment(this.skippingScheduleItem.date).format("ll")
      );
    },

    save(body) {
      let obj = !body ? this.subscription : body;
      this.processing = true;
      Store.snackbarStart();
      return Subscription.editSubscription(this.subscriptionId, obj, false)
        .then(() => {
          this.processing = false;
          if (obj.status) this.os.status = body.status;
          if (Object.prototype.hasOwnProperty.call(obj, "isPostpaid"))
            this.os.isPostpaid = body.isPostpaid;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    amountFormatted(amount) {
      let formatted = amount;
      const currencyCode = this.subscription.currencyCode;

      if (currencyCode === "INR") {
        formatted = this.currencyPipe(amount, "₹");
      } else if (currencyCode == this.country.currencyCode) {
        formatted = this.currencyPipe(amount, this.country.currencySymbol);
      } else if (currencyCode && currencyCode !== "INR") {
        formatted = this.currencyPipe(amount, currencyCode);
      } else if (!this.country.currencyCode && !currencyCode) {
        formatted = this.currencyPipe(amount, "₹");
      } else {
        formatted = this.currencyPipe(amount, this.country.currencySymbol);
      }

      return formatted;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    fetchDeliverymen() {
      this.dloading = true;
      return Store.getEmployeesByRole(this._id, "deliveryman")
        .then((res) => {
          this.deliverymen = res.data.filter((x) => x.isActive);
          this.dloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.dloading = false;
        });
    },

    generateInvoice() {
      return Subscription.generateInvoice(
        this.subscriptionId,
        this.$store.state.token
      );
    },

    getCallingCode(item) {
      return item.callingCode || this.country.callingCode || "91";
    },

    getMobile(item) {
      return "+" + this.getCallingCode(item) + " " + item.mobile;
    },

    getScheduleClass(status) {
      if (status == "delivered") return "success";
      if (status == "cancelled") return "error";
      if (status == "undelivered") return "warning";
      return "";
    },
  },

  watch: {
    "subscription.status"(n) {
      if (n === this.os.status) return;
      this.save({ status: n });
    },

    "subscription.isPostpaid"(n) {
      if (n === this.os.isPostpaid) return;
      this.save({ isPostpaid: n });
    },

    "subscription.deliveryman"(n) {
      if (n === this.os.deliveryman) return;
      this.save({ deliveryman: n });
    },
  },

  data() {
    return {
      _id: "",
      subscriptionId: "",

      dialog: false,
      loading: false,
      processing: false,
      fsonly: false,
      subscription: {
        address: {},
      },
      os: {},
      cenums: {},

      confirmSubscriptionDialog: false,
      confirmUndoDialog: false,
      confirmAddDialog: false,
      confirmRemoveDialog: false,
      skippingScheduleItem: {},

      editPaymentMode: false,
      deliverymen: [],
      dloading: false,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Date", value: "dated" },
        { text: "Status", value: "status" },
        { text: "Skip", value: "skip", align: "center" },
        { text: "Remove", value: "remove", align: "center" },
      ],

      iheaders: [
        { text: "#", align: "start", value: "index", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Size", value: "size", sortable: false },
        { text: "Qty", value: "qty", align: "right", sortable: false },
        { text: "Price", value: "price", align: "right", sortable: false },
        { text: "Total", value: "total", align: "right", sortable: false },
      ],
    };
  },
};

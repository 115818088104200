<template>
  <v-dialog v-model="$store.state.shipmentPackageModal" max-width="550px" persistent>
    <v-progress-linear
      v-if="processing || pprocessing"
      indeterminate
      color="primary"
      class="mb-0"
    ></v-progress-linear>
    <v-card>
      <v-card-text>
        <v-container>
          <v-form ref="form" valid="valid">
            <v-row>
              <v-col>
                <h5 style="color: black" v-if="!order.package.weightUnit">Enter the order's shipment package detail</h5>
                <h5 style="color: black" v-else>Review the order's shipment package detail</h5>
                <div>
                  <small class="text-error" style="font-size: 0.8rem;">The higher weight will be considered between package weight and volumetric weight. Air Shipping limit of <b>25kg</b>. Surface Shipping Limit of <b>125kg</b>.</small>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  suffix="kg"
                  label="Package weight"
                  :disabled="processing || pprocessing"
                  :rules="validationRules.minWeight"
                  v-model="shipmentPackage.weight"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  suffix="cm"
                  label="Package length"
                  :disabled="processing || pprocessing"
                  :rules="validationRules.minSize"
                  v-model="shipmentPackage.length"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  label="Package breadth"
                  :disabled="processing || pprocessing"
                  suffix="cm"
                  :rules="validationRules.minSize"
                  v-model="shipmentPackage.breadth"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  suffix="cm"
                  label="Package height"
                  :disabled="processing || pprocessing"
                  :rules="validationRules.minSize"
                  v-model="shipmentPackage.height"></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col cols="2">
                <v-btn color="error" outlined :disabled="processing || pprocessing" @click="$parent.cancelAtShipmentPackage">Cancel</v-btn>
              </v-col>
              <v-col class="text-right">
                <span>Volumetric Weight: <b>{{ calculateVW }}kg</b></span>
                <v-btn class="ml-2" color="primary" :disabled="processing || !calculateVW || pprocessing" outlined @click="updatePackageDetail">Continue</v-btn>
              </v-col>
            </v-row>

          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  import Order from '../../services/order';

  export default {
    props: [
      '_id',
      'order',
      'pprocessing'
    ],

    methods: {
      updatePackageDetail() {
        if (!this.$refs.form.validate()) return;

        this.shipmentPackage.vmWeight = this.calculateVW;
        this.processing = true;
        return Order
          .updateOrderPackageDetail(this.order._id, this.shipmentPackage, false)
          .then(() => {
            this.processing = false;
            this.$parent.continueFromShipmentPackage(this.shipmentPackage);
          })
          .catch(e => {
            console.log(e);
            this.processing = false;
          });
      }
    },

    computed: {
      calculateVW() {
        const p = this.shipmentPackage ? this.shipmentPackage : {};
        const vw = p.length && p.breadth && p.height ? p.length * p.breadth * p.height / 5000 : 0;
        const vmw = parseFloat(vw.toFixed(2));
        return vmw;
      }
    },

    watch: {
      '$store.state.shipmentPackageModal'(n) {
        if (n) this.shipmentPackage = this.order.package;
      }
    },

    data() {
      return {
        processing: false,
        valid: false,

        shipmentPackage: {},

        validationRules: {
          minWeight: [
            v => v >= 0.5 || 'Minimum weight 0.5kg'
          ],
          minSize: [
            v => v >= 0 || 'Minimum 0.5cm'
          ]
        }
      }
    }
  }
</script>
import Vue from 'vue';

export default {
  getLeads(object, pageNumber, pageSize, keyword, noProgress = true) {
    let endpoint = `crm/leads/list/${pageNumber}/${pageSize}`;
    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.post(endpoint, object, {noProgress: noProgress});
  },

  getSMSHistory(filter, noProgress = true) {
    return Vue.axios.post(`crm/sms/history`, filter, {noProgress: noProgress});
  },

  getCallsHistory(filter, noProgress = true) {
    return Vue.axios.post(`crm/call/history`, filter, {noProgress: noProgress});
  },

  getLeadsHistory(filter, noProgress = true) {
    return Vue.axios.post(`crm/lead/history`, filter, {noProgress: noProgress});
  },

  getMeetingsHistory(filter, noProgress = true) {
    return Vue.axios.post(`crm/meeting/history`, filter, {noProgress: noProgress});
  }
}
import Store from "../../services/store";
import FileUpload from "../../services/file-upload";
import { getS3URL } from "../../helpers/misc";
import getSymbolFromCurrency from "currency-symbol-map";

export default {
  name: "SupplierForm",

  props: ["_id", "loading", "editedItem", "supplierModified"],

  methods: {
    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
        })
        .catch((e) => console.log(e));
    },

    save(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedItem);

      if (this.editedItem._id) {
        request = Store.editSupplier(this.editedItem._id, object, false);
      } else {
        request = Store.addSupplier(this._id, object, false);
      }

      return request
        .then(() => {
          if (closeModal) this.close();
          this.processing = false;
          this.$emit("update:supplierModified", true);
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          Store.snackbarError();
          if (e.response && e.response.status === 400 && e.response.data) {
            if (e.response.data.error == "already-exists-supplier") {
              this.$store.state.snackbar.text = "This supplier already exists";
            }
          }
          this.processing = false;
        });
    },

    close() {
      this.$store.state.supplierModal = false;
    },

    getRefDoc() {
      return this.$refs.inputDocUpload;
    },

    pickFileDoc() {
      this.getRefDoc().click();
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    isValidDocument(file) {
      if (!file) return false;
      const type = file["type"].split("/")[0];
      const type2 = file["type"].split("/")[1];
      if (
        file &&
        (this.supportedFormats.includes(type) ||
          this.supportedFormats.includes(type2))
      ) {
        this.fileObject = {
          name: file["name"],
          size: file["size"],
          type: this.supportedFormats.includes(type) ? type : type2,
        };
        return true;
      }
      return false;
    },

    getURLDoc(data) {
      if (!data) return data;
      const ext = data.split(".")[1];
      if (data && (this.supportedFormats.includes(ext) || ext == "png")) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidDocError() {
      this.$store.state.snackbar.text = "Unsupported File Format";
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelectedDoc() {
      const file = this.getRefDoc().files[0];
      if (!this.isValidDocument(file)) return this.invalidDocError();

      this.fileObject._id = "";
      this.fileObject._id = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(
        this._id,
        "suppliers",
        file,
        this.fileObject.type
      )
        .then((x) => {
          if (!this.editedItem.files) this.editedItem.files = [];
          this.fileObject._id = x.data.file;
          this.editedItem.files.push(this.fileObject);
          this.uploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch(() => {
          this.uploading = false;
        });
    },

    openDocument(item) {
      window.open(this.getURLDoc(item._id), "_blank");
    },

    removeDocument(item) {
      const files = this.editedItem.files.filter((x) => x._id != item._id);
      this.editedItem.files = files;
      this.save(false, false);
    },

    fetchStatesByCountry(country = this.editedItem.country) {
      this.sfetching = true;
      return Store.getStatesByCountry(country, false)
        .then((x) => {
          this.states = x.data;
          this.sfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.sfetching = false;
        });
    },

    fetchCitiesByState(state = this.editedItem.state) {
      this.cfetching = true;
      return Store.getCitiesByState(state, false)
        .then((x) => {
          this.cities = x.data;
          this.cfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.cfetching = false;
        });
    },

    processAddress() {
      if (this.editedItem.isShippingSameAsBilling) {
        this.editedItem.isShippingSameAsBilling = false;
        this.editedItem.shippingAddress = "";
      } else {
        this.editedItem.isShippingSameAsBilling = true;
        if (this.editedItem.billingAddress) {
          this.editedItem.shippingAddress = this.editedItem.billingAddress;
        }
      }
    },

    getCurrencyCodeText(item) {
      return item.currencyCode + " (" + item.name + ")";
    },

    countryText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },
  },

  watch: {
    "$store.state.supplierModal"(n) {
      if (n) this.fetchCountries();
    },

    "editedItem.country"(n) {
      if (n) {
        this.fetchStatesByCountry(n);
      }
    },

    "editedItem.state"(n) {
      if (n) this.fetchCitiesByState();
    },

    "editedItem.billingAddress"(n) {
      if (n && this.editedItem.isShippingSameAsBilling) {
        this.editedItem.shippingAddress = n;
      }
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    store() {
      return this.$store.state.activeStore;
    },

    country() {
      return this.$store.state.country;
    },

    currencyCode() {
      return this.editedItem.currencyCode || this.country.currencyCode;
    },

    currencyPrefix() {
      return getSymbolFromCurrency(this.currencyCode);
    },

    formTitle() {
      return !this.editedItem._id ? "Add new supplier" : "Edit supplier";
    },
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      processing: false,
      uploading: false,

      supportedFormats: ["image", "pdf", "xls", "xsls", "doc", "docx"],
      balanceTypes: [
        { key: "collect", value: "To Collect" },
        { key: "pay", value: "To Pay" },
      ],

      error: "",
      sfetching: false,
      cfetching: false,
      countries: [],
      states: [],
      cities: [],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        mobileRule: [(v) => (!!v && !isNaN(v)) || "Must be a valid number"],
        emailRule: [(v) => !v || emailregex.test(v) || "Must be a valid email"],
        numberRule: [(v) => (!v && v != 0) || v >= 0 || "Must be valid amount"],
        addressRule: [(v) => (!!v && !!v.description) || "It is required"],
        countryRule: [(v) => !!v || "Country is required"],
      },
    };
  },
};

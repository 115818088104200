<template>
  <div>
    <small>{{ text }}</small>
  </div>
</template>
<script>
export default {
  name: "DeprecatedWarning",

  props: ["text", "color"],
};
</script>

<template>
  <div>
    <v-list-item-title v-if="title">
      <b>{{ title }}</b>
    </v-list-item-title>

    <v-row>
      <v-col>
        <v-list-item-subtitle class="mb-1">Name</v-list-item-subtitle>
        <b>{{ address.name }}</b>
      </v-col>
      <v-col>
        <v-list-item-subtitle class="mb-1">Mobile</v-list-item-subtitle>
        <b>{{ getMobile(address) }}</b>
      </v-col>
      <v-col></v-col>
    </v-row>

    <v-row v-if="hasInfo" class="mb-2">
      <v-col>
        <v-list-item-subtitle>Company Name</v-list-item-subtitle>
        <v-list-item-title>
          <b> {{ address.companyName || "--" }} </b>
        </v-list-item-title>
      </v-col>

      <v-col>
        <v-list-item-subtitle>Tax Code/GSTIN</v-list-item-subtitle>
        <v-list-item-title>
          <b> {{ address.taxCode || "--" }} </b>
        </v-list-item-title>
      </v-col>

      <v-col>
        <v-list-item-subtitle>PAN Number</v-list-item-subtitle>
        <v-list-item-title>
          <b> {{ address.pan || "-" }} </b>
        </v-list-item-title>
      </v-col>

      <v-col v-if="address.fssaiRegistrationNumber">
        <v-list-item-subtitle>FSSAI Registration Number</v-list-item-subtitle>
        <v-list-item-title>
          <b> {{ address.fssaiRegistrationNumber }} </b>
        </v-list-item-title>
      </v-col>
    </v-row>

    <v-list-item-subtitle class="mb-1">Street Address</v-list-item-subtitle>
    <b>{{ addressString }}</b>
  </div>
</template>
<script>
export default {
  props: ["title", "address"],

  computed: {
    addressString() {
      const array = [this.address.street];

      if (this.address.area) array.push(this.address.area);
      if (this.address.landmark) array.push(this.address.landmark);
      if (this.address.city) array.push(this.address.city);
      if (this.address.state) array.push(this.address.state);
      if (this.address.country) array.push(this.address.country);

      let str = array.join(", ");
      if (this.address.pincode) str += " - " + this.address.pincode;

      return str;
    },

    country() {
      return this.$store.state.country;
    },

    hasInfo() {
      return (
        this.address.companyName ||
        this.address.taxCode ||
        this.address.fssaiRegistrationNumber
      );
    },
  },

  methods: {
    getCallingCode(item) {
      return item.callingCode || this.country.callingCode || "91";
    },

    getMobile(item) {
      return "+" + this.getCallingCode(item) + " " + item.mobile;
    },
  },
};
</script>

import Store from '../../../services/store';
import router from '../../../router';

export default {
  name: 'Policies',

  created() {
    this._id = this.$route.params._id;
    if (this.$store.state.activeStore.isVendor) return router.push('/business/'+this._id);
    return this.fetchPolicies();
  },

  methods: {
    fetchPolicies() {
      this.loading = true;
      return Store
        .getPolicies(this._id, false)
        .then(res => {
          const data = res.data;
          if (data && data.length) {
            const refundPolicy = data.find(x => x.type === 'refund');
            const returnPolicy = data.find(x => x.type === 'return');
            const cancellationPolicy = data.find(x => x.type === 'cancellation');
            const shippingPolicy = data.find(x => x.type === 'shipping');
            const privacyPolicy = data.find(x => x.type === 'privacy-policy');
            const termsConditions = data.find(x => x.type === 'terms-and-conditions');

            if (refundPolicy) this.refundPolicy = refundPolicy;
            if (returnPolicy) this.returnPolicy = returnPolicy;
            if (cancellationPolicy) this.cancellationPolicy = cancellationPolicy;
            if (shippingPolicy) this.shippingPolicy = shippingPolicy;
            if (privacyPolicy) this.privacyPolicy = privacyPolicy;
            if (termsConditions) this.termsConditions = termsConditions;
          }
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    save() {
      if (this.policyTab == 0 && !this.refundPolicy.description) this.error = 'Empty refund policy can not be saved';
      if (this.policyTab == 1 && !this.returnPolicy.description) this.error = 'Empty return policy can not be saved';
      if (this.policyTab == 2 && !this.cancellationPolicy.description) this.error = 'Empty cancellation policy can not be saved';
      if (this.policyTab == 3 && !this.shippingPolicy.description) this.error = 'Empty shipping policy can not be saved';
      if (this.policyTab == 4 && !this.privacyPolicy.description) this.error = 'Empty privacy policy can not be saved';
      if (this.policyTab == 5 && !this.termsConditions.description) this.error = 'Empty terms and conditions can not be saved';

      if (this.error) return setTimeout(() => this.error = '', 3000);
      
      let editedItem = {};
      if (this.policyTab == 0) editedItem = this.refundPolicy;
      else if (this.policyTab == 1) editedItem = this.returnPolicy;
      else if (this.policyTab == 2) editedItem = this.cancellationPolicy;
      else if (this.policyTab == 3) editedItem = this.shippingPolicy;
      else if (this.policyTab == 4) editedItem = this.privacyPolicy;
      else if (this.policyTab == 5) editedItem = this.termsConditions;

      Store.snackbarStart();
      this.processing = true;
      const request = editedItem._id ? Store.editPolicy(editedItem._id, editedItem, false) : Store.addPolicy(this._id, editedItem, false);
      return request
        .then(res => {
          if (res.data._id) {
            if (this.policyTab == 0) this.refundPolicy._id = res.data._id;
            else if (this.policyTab == 1) this.returnPolicy._id = res.data._id;
            else if (this.policyTab == 2) this.cancellationPolicy._id = res.data._id;
            else if (this.policyTab == 3) this.shippingPolicy._id = res.data._id;
            else if (this.policyTab == 4) this.privacyPolicy._id = res.data._id;
            else if (this.policyTab == 5) this.termsConditions._id = res.data._id;
          } else {
            this.fetchPolicies();
          }

          this.success = 'Saved successfully';
          setTimeout(() => this.success = '', 2000);

          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        })
    }
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  data() {
    return {
      _id: '',
      policyTab: 0,
      policies: ['Refund Policy', 'Return Policy', 'Cancellation Policy', 'Shipping Policy', 'Privacy Policy', 'Terms and Conditions'],

      refundPolicy: {
        type: 'refund'
      },
      returnPolicy: {
        type: 'return'
      },
      cancellationPolicy: {
        type: 'cancellation'
      },
      shippingPolicy: {
        type: 'shipping'
      },
      privacyPolicy: {
        type: 'privacy-policy'
      },
      termsConditions: {
        type: 'terms-and-conditions'
      },

      error: '',
      success: '',

      processing: false,
      loading: false
    }
  }
}
import Vue from "vue";
import config from "../config";

export default {
  getOrdersOOU(storeId, noProgress = true) {
    return Vue.axios.get(`order/list/${storeId}`, { noProgress: noProgress });
  },

  getOrders(
    storeId,
    pageNumber,
    pageSize,
    keyword,
    isVendor,
    tabID,
    noProgress = true
  ) {
    let endpoint = `order/list/${
      isVendor ? "vendor" : "main"
    }/${tabID}/${storeId}/${pageNumber}/${pageSize}`;
    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.get(endpoint, { noProgress: noProgress });
  },

  getCounts(storeId, isVendor, noProgress = true) {
    return Vue.axios.get(
      `order/counts/${isVendor ? "vendor" : "main"}/${storeId}`,
      { noProgress: noProgress }
    );
  },

  getRecentOrders(storeId, noProgress = true, isVendor = false) {
    return Vue.axios.get(
      `order/list/${storeId}/${isVendor ? "vendor-recent" : "recent"}`,
      { noProgress: noProgress }
    );
  },

  getOrder(orderId, noProgress = true) {
    return Vue.axios.get(`order/${orderId}`, { noProgress: noProgress });
  },

  getOrderEnums(storeId) {
    return Vue.axios.get(`order/enums/${storeId}`, { noProgress: true });
  },

  changeStatus(orderId, status, notify, noProgress = true) {
    return Vue.axios.put(`order/${status}/${orderId}/${notify}`, {
      noProgress: noProgress,
    });
  },

  cancelAndProcessRefund(orderId, object, noProgress = true) {
    return Vue.axios.put(`order/cancelled/${orderId}/true`, object, {
      noProgress: noProgress,
    });
  },

  assignDeliveryman(_id, deliveryman, noProgress = true) {
    return Vue.axios.put(
      `order/${_id}/dm/${deliveryman}`,
      {},
      { noProgress: noProgress }
    );
  },

  assignVendor(_id, vendor, noProgress = true) {
    return Vue.axios.put(
      `order/${_id}/vendor/${vendor}`,
      {},
      { noProgress: noProgress }
    );
  },

  generateInvoice(_id, token, isKOT) {
    let endpoint = `${
      process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
    }order/${_id}/${!isKOT ? "invoice" : "invoice-kot"}`;
    return window.open(
      endpoint + "?x-request-user-agent=application/admin-app&token=" + token,
      "_blank"
    );
  },

  placeOrder(_id, order, noProgress = true) {
    return Vue.axios.post(`order/create/${_id}`, order, {
      noProgress: noProgress,
    });
  },

  editOrder(_id, order, noProgress = true) {
    return Vue.axios.put(`order/modify/${_id}`, order, {
      noProgress: noProgress,
    });
  },

  updatedAbandonedOrder(_id, order, noProgress = true) {
    return Vue.axios.put(`order/abandoned/${_id}`, order, {
      noProgress: noProgress,
    });
  },

  updateCourierInfo(_id, info, noProgress = true) {
    return Vue.axios.put(`order/courier/${_id}`, info, {
      noProgress: noProgress,
    });
  },

  downloadInvoices(_id, _ids, token) {
    let endpoint = `${
      process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
    }order/${_id}/invoices`;
    return window.open(
      endpoint +
        "?_ids=" +
        _ids +
        "&x-request-user-agent=application/admin-app&token=" +
        token,
      "_blank"
    );
  },

  updateOrderPackageDetail(_id, shipmentPackage, noProgress = false) {
    return Vue.axios.put(`order/package/${_id}`, shipmentPackage, {
      noProgress: noProgress,
    });
  },

  getCouriers(_id, orderId, addressId, noProgress = false) {
    return Vue.axios.get(
      `shipment/couriers/list/${_id}/${orderId}/${addressId}`,
      { noProgress: noProgress }
    );
  },

  createShipment(_id, orderId, shipmentObj, noProgress = false) {
    return Vue.axios.post(`shipment/create/${_id}/${orderId}`, shipmentObj, {
      noProgress: noProgress,
    });
  },

  generateLabel(_id, noProgress = false) {
    return Vue.axios.get(`shipment/generate/label/${_id}`, {
      noProgress: noProgress,
    });
  },

  generateManifest(_id, noProgress = false) {
    return Vue.axios.get(`shipment/generate/manifest/${_id}`, {
      noProgress: noProgress,
    });
  },

  getShipment(_id, orderId, noProgress = false) {
    return Vue.axios.get(`shipment/${_id}/${orderId}`, {
      noProgress: noProgress,
    });
  },

  cancelShipment(_id, noProgress = false) {
    return Vue.axios.post(
      `shipment/cancel/${_id}`,
      {},
      { noProgress: noProgress }
    );
  },

  trackShipment(_id, noProgress = false) {
    return Vue.axios.get(
      `shipment/track/${_id}`,
      {},
      { noProgress: noProgress }
    );
  },

  massUpdateDeliveryman(_id, deliveryman, object, noProgress = true) {
    return Vue.axios.post(
      `order/${_id}/mass/deliveryman/${deliveryman}`,
      object,
      { noProgress: noProgress }
    );
  },

  massUpdateVendor(_id, vendor, object, noProgress = true) {
    return Vue.axios.post(`order/${_id}/mass/vendor/${vendor}`, object, {
      noProgress: noProgress,
    });
  },

  massUpdateStatus(_id, status, object, noProgress = true) {
    return Vue.axios.post(`order/${_id}/mass/status/${status}`, object, {
      noProgress: noProgress,
    });
  },

  massUpdateCommunity(_id, community, object, noProgress = true) {
    return Vue.axios.post(`order/${_id}/mass/community/${community}`, object, {
      noProgress: noProgress,
    });
  },

  massUpdateSlot(_id, slot, object, noProgress = true) {
    return Vue.axios.post(`order/${_id}/mass/slot/${slot}`, object, {
      noProgress: noProgress,
    });
  },

  exportOrders(_id, isVendor, _ids = false, noProgress = true) {
    let endpoint = `order/export${isVendor ? "-vendor" : ""}/${_id}`;
    if (_ids) endpoint += "?_ids=" + _ids.join(",");
    return Vue.axios.get(endpoint, { noProgress: noProgress });
  },

  getSummary(_id, object, isVendor, noProgress = true) {
    return Vue.axios.post(
      `order/${isVendor ? "summary-vendor" : "summary"}/${_id}`,
      object,
      { noProgress: noProgress }
    );
  },

  //superadmin apis
  getSuperadminOrders(pageNumber, pageSize, keyword, noProgress = true) {
    let endpoint = `order/list/sa/${pageNumber}/${pageSize}`;
    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.get(endpoint, { noProgress: noProgress });
  },

  // dunzo apis
  getDunzoQuote(_id, orderId, object, noProgress = true) {
    return Vue.axios.post(`order/dunzo/get-quote/${_id}/${orderId}`, object, {
      noProgress: noProgress,
    });
  },

  createDunzoPickup(_id, orderId, object, noProgress = true) {
    return Vue.axios.post(`order/dunzo/pickup/${_id}/${orderId}`, object, {
      noProgress: noProgress,
    });
  },

  cancelDunzoPickup(_id, orderId, object, noProgress = true) {
    return Vue.axios.post(
      `order/dunzo/pickup/cancel/${_id}/${orderId}`,
      object,
      {
        noProgress: noProgress,
      }
    );
  },

  getReturns(
    storeId,
    pageNumber,
    pageSize,
    keyword,
    isVendor,
    noProgress = true
  ) {
    let endpoint = `order/return/list/${
      isVendor ? "vendor" : "main"
    }/${storeId}/${pageNumber}/${pageSize}`;

    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.get(endpoint, { noProgress: noProgress });
  },

  getReturnOrder(returnId, noProgress = true) {
    return Vue.axios.get(`order/return/${returnId}`, {
      noProgress: noProgress,
    });
  },

  getReturnOrderEnums(storeId) {
    return Vue.axios.get(`order/return/enums/${storeId}`, { noProgress: true });
  },

  updateReturnOrder(returnId, object, noProgress = true) {
    return Vue.axios.put(`order/return/${returnId}`, object, {
      noProgress: noProgress,
    });
  },
};

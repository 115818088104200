import Store from "../../../services/store";
import router from "../../../router";

import ConfirmDialog from "../../../components/confirm-dialog/confirm-dialog.vue";
import PurchaseReturns from "../../../components/purchase-returns/purchase-returns.vue";
import PurchaseInvoices from "../../../components/purchase-invoices/purchase-invoices.vue";

import config from "../../../config";

export default {
  name: "Purchases",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Purchases")) {
      return router.push("/business/" + this._id);
    }

    if (!this.doIHaveAccess("actions")) {
      this.headers = this.headers.filter((x) => x.text !== "Actions");
    }

    this.fetchPurchases();
    this.fetchSuppliers();
  },

  components: {
    ConfirmDialog,
    PurchaseInvoices,
    PurchaseReturns,
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchPurchases() {},

    fetchSuppliers() {
      this.fsuppliers = true;
      return Store.getSuppliers(this._id, false)
        .then((res) => {
          this.suppliers = res.data;
          this.fsuppliers = false;
        })
        .catch(() => {
          this.fsuppliers = false;
        });
    },
  },

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  watch: {
    supplierModified(n) {
      if (n) {
        this.fetchSuppliers();
        this.supplierModified = false;
      }
    },
  },

  data: () => {
    return {
      _id: "",
      suppliers: [],

      search: "",
      supplier: "",

      beingDeleted: {},
      confirmDialog: false,

      processing: false,
      fsuppliers: false,

      supplierModified: false,

      rowItems: config.rowItems,
      filterBySelected: "",
      filterBy: [
        { header: "Basic" },
        { value: "balanceType:collect", text: "To Collect" },
        { value: "balanceType:pay", text: "To Pay" },
      ],

      tab: 0,
      tabs: [
        { _id: "purchase-invoices", title: "Purchase Invoices" },
        { _id: "purchase-returns", title: "Purchase Returns" },
      ],
    };
  },
};

import config from "../config";
import store from "../store";

export default {
  getUserPhoto(data) {
    if ((Array.isArray(data) && !data.length) || !data)
      return config.placeholderImg;
    else if (Array.isArray(data) && data.length) data = data[0];

    if (/^\d+$/.test(data))
      return `https://graph.facebook.com/${data}/picture?height=450`;
    else if (
      new Blob([data]).size === 24 &&
      this.$store &&
      this.$store.getters.isTokenAvailable
    )
      return (
        config.apiProd +
        "file/" +
        data +
        "/?token=" +
        this.$store.getters.isTokenAvailable +
        "&x-request-user-agent=application/mobile-app"
      );
    else if (
      new Blob([data]).size === 24 &&
      (!this.$store || !this.$store.getters.isTokenAvailable)
    )
      return config.placeholderImg;
    else {
      //check if s3 image
      const arr = data.split(".");
      if (arr[0].length === 36 && arr[1] && arr[1] === "png")
        return config.usersS3PhotoURL + data;
      return data; //return as url
    }
  },

  getExpPhoto(data) {
    if (!data) return config.placeholderImg;
    if (data.includes("blob")) return data;
    return config.experiencesS3PhotoURL + data;
  },

  getItPhoto(data) {
    const placeholder = config.placeholderImg;
    if (!data) return placeholder;
    if (data.includes("blob")) return data;
    return config.itinerariesS3PhotoURL + data;
  },

  getLazy(text = "l") {
    return config.fImageURL + text;
  },

  getDarkLazy(text = "l") {
    return config.fDarkLURL + text;
  },

  getRazOptions(amount, description, currencyCode = "INR") {
    amount = parseFloat((amount * 100).toFixed(2));
    return {
      key: config.keys.razorpayKey,
      name: "Growcify",
      description: store.state.activeStore.name + " - " + description,
      image: "https://growcify.com/images/logo.png",
      amount: amount,
      currency: currencyCode,
      prefill: {
        name: "",
        email: "",
      },
      theme: {
        color: "#007bff",
      },
    };
  },

  getTaxAmount(amount) {
    return parseFloat((amount + (amount * config.gst) / 100).toFixed(2));
  },
};

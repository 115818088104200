import Store from '../../../services/store';
import router from '../../../router';

export default {
  name: 'Memberships',

  created() {
    this._id = this.$route.params._id;
    if (this.$store.state.activeStore.isVendor) return router.push('/business/'+this._id);
    this.fetchMemberships();
  },

  methods: {
    fetchMemberships() {
      this.loading = true;
      return Store.getMemberships(this._id, false)
        .then(res => {
          this.memberships = res.data.map((x, index) => {
            x.index = (index+1)+'.';
            return x;
          });
          this.loading = false;
        })
        .catch(e => { console.log(e); this.loading = false; });
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return this.cenums = ce;

      return Store.getCommonEnums()
        .then(x => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch(e => {
          console.log(e)
        });
    },

    editItem(item) {
      this.editedIndex = this.memberships.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteMembership(this.beingDeleted._id, false)
        .then(() => { this.confirmDialog = this.processing = false; this.fetchMemberships(); })
        .catch(e => { this.confirmDialog = this.processing = false; console.log(e)});
    },

    close () {
      this.dialog = this.processing = this.loading = false
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false);
    },

    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart(); 

      const object = Object.assign({}, this.editedItem);

      let request;
      if (this.editedItem._id) request = Store.editMembership(this.editedItem._id, object, false)
      else request = Store.addMembership(this._id, object, false);
          
      return request 
          .then(() => {
            if (closeModal) this.close();
            this.fetchMemberships();
            this.processing = this.editedItem.processing = false;
            Store.snackbarSuccess();
          })
          .catch(() => {
            this.processing = false;
            this.loading = false;
            Store.snackbarError();
          });
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add new membership' : 'Edit membership';
    },
  },
  data: () => {
    return {
      _id: '',

      memberships: [],
      search: '',

      loading: true,
      valid: false,
      dialog: false,
      processing: false,

      error: '',

      cenums: {},

      translationModal: false,
      translationField: '',

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true
      },

      confirmDialog: false,
      beingDeleted: {},

      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name',  align: 'start', value: 'name' },
        { text: 'Discount', value: 'discount', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false}
      ],

      validationRules: {
        basicRule: [
          v => !!v || 'It is required'
        ],
        statusRule: [
          v => v || v === false || 'It is required'
        ],
        callingCode: [
          v => v >= 0 || 'Enter digits only'
        ]
      }
    }
  }
}
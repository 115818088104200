import Vue from 'vue';

export default {
  addCategory(category, noProgress = true) {
    return Vue.axios.post(`mi/category`, category, {noProgress: noProgress});
  },

  editCategory(_id, category, noProgress = true) {
    return Vue.axios.put(`mi/category/${_id}`, category, {noProgress: noProgress});
  },

  getCategory(_id, noProgress = true) {
    return Vue.axios.get(`mi/category/${_id}`, {noProgress: noProgress});
  },

  deleteCategory(_id, noProgress = true) {
    return Vue.axios.delete(`mi/category/${_id}`, {noProgress: noProgress});
  },

  getCategories(noProgress = true) {
    return Vue.axios.get(`mi/category/list`, {noProgress: noProgress});
  },

  getActiveCategories(noProgress = true) {
    return Vue.axios.get(`mi/category/list/active`, {noProgress: noProgress});
  },

  searchCategories(storeCategory = '', keyword, noProgress = false) {
    return Vue.axios.get(`mi/category/list/active?category=${storeCategory}&keyword=${keyword}`, {noProgress: noProgress});
  },

  getActiveProducts(noProgress = true) {
    return Vue.axios.get(`mi/product/list/active`, {noProgress: noProgress});
  },

  addProduct(product, noProgress = true) {
    return Vue.axios.post(`mi/product`, product, {noProgress: noProgress});
  },

  editProduct(_id, product, noProgress = true) {
    return Vue.axios.put(`mi/product/${_id}`, product, {noProgress: noProgress});
  },

  getProduct(_id, noProgress = true) {
    return Vue.axios.get(`mi/product/${_id}`, {noProgress: noProgress});
  },

  deleteProduct(_id, noProgress = true) {
    return Vue.axios.delete(`mi/product/${_id}`, {noProgress: noProgress});
  },

  getProducts(pageNumber, pageSize, keyword, noProgress = true) {
    if (keyword) return Vue.axios.get(`mi/product/list/p/${pageNumber}/${pageSize}?keyword=${keyword}`, {noProgress: noProgress});
    return Vue.axios.get(`mi/product/list/p/${pageNumber}/${pageSize}`, {noProgress: noProgress});
  },

  getProductEnums() {
    return Vue.axios.get(`mi/product/enums`, {noProgress: true});
  },

  getCategoryEnums() {
    return Vue.axios.get(`mi/category/enums`, {noProgress: true});
  }
}

export default {
  name: 'Translator',

  props: [
    'dialog',
    'cenums',
    'processing',
    'editedItem',
    'field'
  ],

  methods: {
    save() {
      if (!this.$refs.trform.validate()) return;

      const translations = {};
      this.translations.forEach(x => translations[x.code] = x.value || this.editedItem.name);

      if (this.editedItem.name && this.field == 'name') translations['en'] = this.editedItem.name;
      if (this.editedItem.size && this.field == 'size') translations['en'] = this.editedItem.size;

      if (this.editedItem.translations) {
        this.editedItem.translations[this.field] = translations;
      } else {
        this.editedItem.translations = {}
        this.editedItem.translations[this.field] = translations;
      }

      this.$parent.saveTranslation();
    },

    addLanguage() {
      this.translations.push(this.litem())
    },

    litem() {
      return {
        code: '', 
        value: '',
        selectableItems: this.getFilteredItems()
      };
    },

    getFilteredItems(code = '') {
      return this.cenums.supportedLanguages ? this.cenums.supportedLanguages.filter(x => code == 'en' || !this.translations.find(i => i.code === x.code) || this.otrs.find(i => x.code === code && i.code === code)) : [];
    },

    languageChanged(item) {
      const index = this.translations.indexOf(item);
      const items = JSON.parse(JSON.stringify(this.translations.filter(i => i.code === item.code)));
      if (items.length > 1) {
        const withoutDuplicate = this.translations.filter(x => x.code !== item.code);
        this.translations = withoutDuplicate;

        this.translations.splice(index, 0, {
          code: item.code,
          value: '',
          selectableItems: this.getFilteredItems()
        });
      }
    },

    removeItem(item) {
      this.translations = this.translations.filter(x => x.code !== item.code);
    }
  },

  watch: {

    dialog(n) {
      if (n) {
        const translations = JSON.parse(JSON.stringify(this.editedItem.translations || {}));
        const translationsKeys = Object.keys(translations);
        const newtranslations = [];

        if (translationsKeys.length) {
          translationsKeys.forEach(x => {
            const narr = Object.keys(translations[x]).map(k => {
              return {
                code: k,
                value: translations[x][k]
              }
            });
            
            newtranslations.push({
              field: x,
              tr: narr
            });

          });
        }

        if (this.editedItem && (!translationsKeys.length || !translations[this.field])) {
          newtranslations.push({
            field: this.field,
            tr: [{
              code: 'en',
              value: this.editedItem[this.field],
            }]
          });
        }

        const trs = newtranslations.find(x => x.field === this.field).tr;
        this.otrs = JSON.parse(JSON.stringify(trs));

        const ntrs = trs.map(x => {
          x.selectableItems = this.getFilteredItems(x.code);
          return x;
        });

        this.translations = JSON.parse(JSON.stringify(ntrs));
      }
    }

  },

  computed: {
    fieldName() {
      if (this.field && typeof this.field === 'string') return this.field.charAt(0).toUpperCase() + this.field.slice(1)
    }
  },

  data() {
    return {
      valid: false,
      translations: [],

      otrs: [],

      validationRules: {
        basicRule: [
          v => !!v || 'It\'s required'
        ]
      }
    }
  }
}
<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <v-list-item-title>
          <b> Payment Details - Razorpay</b>
        </v-list-item-title>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-list-item-subtitle class="mb-1">
          Payment ID
        </v-list-item-subtitle>
        <b>{{ paymentInfo.id || "-" }}</b>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-list-item-subtitle class="mb-1">
          Payment Method
        </v-list-item-subtitle>
        <b>{{ transaction.paymentMethod || "-" }}</b>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-list-item-subtitle class="mb-1">
          Amount
        </v-list-item-subtitle>
        <b> {{ (paymentInfo.amount / 100) | fc(paymentInfo.currency) }}</b>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-list-item-subtitle class="mb-1">
          Status
        </v-list-item-subtitle>
        <b>{{ paymentInfo.status || "-" }}</b>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-list-item-subtitle class="mb-1">
          VPA
        </v-list-item-subtitle>
        <b>{{ paymentInfo.vpa || "-" }}</b>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-list-item-subtitle class="mb-1">
          RRN
        </v-list-item-subtitle>
        <b>
          {{
            paymentInfo.acquirer_data
              ? paymentInfo.acquirer_data.rrn || "-"
              : "-"
          }}
        </b>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-list-item-subtitle class="mb-1">
          Email
        </v-list-item-subtitle>
        <b>{{ paymentInfo.email }}</b>
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-list-item-subtitle class="mb-1">
          Contact
        </v-list-item-subtitle>
        <b>{{ paymentInfo.contact }}</b>
      </v-col>
    </v-row>
  </section>
</template>
<script>
export default {
  name: "PaymentDetails",
  props: ["order"],

  computed: {
    paymentInfo() {
      return JSON.parse(this.order?.transaction?.paymentObject || "{}");
    },

    transaction() {
      return this.order?.transaction || {};
    },
  },

  data() {
    return {};
  },
};
</script>

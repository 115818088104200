<template>
  <v-row :class="editable ? '' : 'mb-2'">
    <v-col :class="['vc', editable ? 'pb-0' : 'p-0 text-right']">
      <span class="vt-font-size">{{ title }}</span>
      <div v-if="subtitle">
        <small>{{ subtitle }}</small>
      </div>
    </v-col>
    <v-col :class="['text-right vc', editable ? 'pb-0' : 'pr-8 p-0']">
      <span class="vt-font-size">
        <b>{{ description }}</b>
      </span>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "PIInfoItem",

  props: ["title", "subtitle", "description", "editable"],
};
</script>

import Store from "../../services/store";
import router from "../../router";

import config from "../../config";

import DeprecatedWarning from "../../components/deprecated-warning/deprecated-warning.vue";

export default {
  name: "DeliveryTypes",

  components: {
    DeprecatedWarning,
  },

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Deliveries")) {
      return router.push("/business/" + this._id);
    }

    if (!this.doIHaveAccess("actions")) {
      this.headers = this.headers.filter((x) => x.text !== "Actions");
    }

    this.fetchDeliveryOptions();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchDeliveryOptions() {
      this.loading = true;
      return Store.getDeliveryOptions(this._id, false)
        .then((res) => {
          this.deliveryOptions = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            x.status = x.isActive ? "Active" : "Inactive";
            return x;
          });
          this.loading = false;
        })
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.deliveryOptions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteDeliverySlot(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchDeliveryOptions();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    save(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      if (this.editedItem._id)
        request = Store.editDeliveryOption(
          this.editedItem._id,
          this.editedItem,
          false
        );
      else request = Store.addDeliveryOption(this._id, this.editedItem, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          this.fetchDeliveryOptions();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          this.loading = false;
          Store.snackbarError();
        });
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false, false);
    },

    getSequenceItems() {
      const arr = [1];
      for (let i = 0; i < this.deliveryOptions.length; i++) arr.push(i + 2);
      return arr;
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Create new delivery option"
        : "Edit delivery option";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data: () => {
    return {
      _id: "",
      deliveryOptions: [],
      enums: {},
      search: "",
      loading: true,
      valid: false,
      dialog: false,
      processing: false,

      translationModal: false,
      translationField: "",

      error: "",

      menu: false,
      cenums: {},

      fenums: false,

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
        isDefault: false,
      },

      confirmDialog: false,
      beingDeleted: {},
      rowItems: config.rowItems,
      activeTimeModel: false,
      startsAtModel: false,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        // { text: "Description", value: "description" },
        { text: "Status", value: "status" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        deliveryCharge: [(v) => v >= 0 || "Must be valid amount"],
      },
    };
  },
};

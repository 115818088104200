import moment from "moment";

import config from "../../config";
import Store from "../../services/store";
import FileUpload from "../../services/file-upload";
import { getS3URL } from "../../helpers/misc";

export default {
  name: "OnboardingScreens",

  created() {
    this._id = this.$route.params._id;
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchScreens();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchScreens() {
      this.processing = this.loading = true;
      return Store.getOnboardingScreens(this._id, false)
        .then((x) => {
          this.screens = x.data;
          this.screens.forEach((x, index) => (x.index = index + 1 + "."));
          this.processing = this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.screens.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteOnboardingScreen(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchScreens();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    formatDateTime(value) {
      return moment(value).format("lll");
    },

    changeStatus(item) {
      this.editedItem = Object.assign({}, item);
      this.saveScreen(false);
    },

    getSequenceItems() {
      const arr = [1];
      for (let i = 0; i < this.screens.length; i++) arr.push(i + 2);
      return arr;
    },

    isVideo(item) {
      if (item) {
        const array = item.split(".");
        return array.includes("mp4");
      }
      return false;
    },

    saveScreen(close = true) {
      if (!this.editedItem.file) {
        return this.invalidImageError("Media Required");
      }
      if (this.$refs.form && !this.$refs.form.validate()) return;

      Store.snackbarStart();
      this.processing = this.editedItem.processing = true;

      let Request;
      const object = Object.assign({}, this.editedItem);
      if (object._id) {
        Request = Store.editOnboardingScreen(
          this.editedItem._id,
          object,
          false
        );
      } else {
        Request = Store.createOnboardingScreen(this._id, object, false);
      }

      return Request.then(() => {
        this.processing = this.editedItem.processing = false;
        Store.snackbarSuccess();
        if (close) {
          this.close();
          this.fetchScreens();
        }
      }).catch((e) => {
        console.log(e);
        this.processing = this.editedItem.processing = false;
        Store.snackbarError();
      });
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      if (
        file &&
        (file["type"].split("/")[0] === "image" ||
          file["type"].split("/")[0] === "video")
      ) {
        this.editedItem.type = file["type"].split("/")[0];
        return true;
      }
      return false;
    },

    getURL(data) {
      if (
        data &&
        (data.split(".")[1] === "png" || data.split(".")[1] === "mp4")
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError(msg = "Invalid file format") {
      this.showErr = msg;
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.uploading = true;
      return FileUpload.upload(
        this._id,
        "onboarding-screens",
        file,
        this.editedItem.type
      )
        .then((x) => {
          this.editedItem.file = x.data.file;
          this.uploading = false;
          if (this.editedItem._id) this.saveScreen(false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Create new onboarding screen"
        : "Edit onboarding screen";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    redirectionItems() {
      const items = [
        { key: "category", value: "Category" },
        { key: "url", value: "External URL" },
      ];

      if (!this.isVendor) items.push({ key: "vendor", value: "Vendor" });

      return items;
    },

    canCreate() {
      return this.doIHaveAccess("create");
    },
  },

  watch: {
    storyUpdated(n) {
      if (n) this.fetchStories();
      this.storyUpdated = false;
    },
  },

  data() {
    return {
      _id: "",
      screens: [],

      valid: false,
      processing: false,
      loading: false,
      uploading: false,
      showErr: false,
      dialog: false,
      storyUpdated: false,

      search: "",

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        file: "",
        isActive: true,
        sequenceNumber: 1,
      },

      confirmDialog: false,
      beingDeleted: {},
      rowItems: config.rowItems,

      categories: [],

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],
      },
    };
  },
};

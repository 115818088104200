<template>
  <v-dialog v-model="$store.state.idInfoModal" max-width="500">
    <v-card class="pl-4 pr-4 pt-2">
      <v-row>
        <v-col cols="5" class="vc">
          <h5 class="m-0">Preview Info</h5>
        </v-col>

        <v-col class="vc text-right">
          <v-btn outlined @click="initPrint">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div
        class="p-2 mt-5"
        style="width: max-content; margin: auto; padding-top: 4px !important"
        id="barcodes"
      >
        <section v-for="item in items" :key="item._id">
          <v-card
            outlined
            class="text-center px-4 py-2 mb-4"
            style="border-color: black; border-radius: 5px;"
          >
            <h4 class="mb-1" style="margin-top: 5px; font-size: 42px;">
              <b>{{ item.oid }}</b>
            </h4>
            <div style="max-width: 175px;">
              <span v-if="item.address && item.address.name">
                {{ item.address.name }}
              </span>
              <span v-else>
                {{ item.user.name }}
              </span>
            </div>
          </v-card>
        </section>
      </div>
    </v-card>
  </v-dialog>
</template>

<style>
.barcode-item {
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "monospace" !important;
}
.barcode-item small {
  font-size: 0.5rem;
}
.barcode-border {
  border: 1px solid rgba(0, 0, 0, 0.87);
}
.reverse {
  transform: rotate(-180deg);
  transform-origin: right, top;
  -ms-transform: rotate(-180deg);
  -ms-transform-origin: right, top;
  -webkit-transform: rotate(-180deg);
  -webkit-transform-origin: right, top;
}
</style>

<script type="text/javascript">
import { Printd } from "printd";

export default {
  methods: {
    initPrint() {
      const cssText = `
          .barcode-item {
            margin-top: 15px;
            margin-bottom: 15px;
            border-radius: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            font-family: 'monospace' !important;
          }
          .barcode-item small {
            font-size: 0.5rem;
          }
          .barcode-border {
            border: 1px solid rgba(0, 0, 0, 0.87);
          }
          .v-card.v-card--outlined {
            border: thin solid rgba(0, 0, 0, 0.12);
          }
          .mr-2 {
            margin-right: 8px !important;
          }
          .ml-2 {
            margin-left: 8px !important;
          }
          .text-center {
            text-align: center !important;
          }
          .reverse {
            transform: rotate(-180deg);
            transform-origin: right, top;
            -ms-transform: rotate(-180deg);
            -ms-transform-origin:right, top;
            -webkit-transform: rotate(-180deg);
            -webkit-transform-origin:right, top;
          }
          .item-text {
            font-size: 11px !important;
            font-family: 'monospace' !important;
          }
          .mb-1 {
            margin-bottom: 4px;
          }
          .mb-4 {
            margin-bottom: 16px;
          }
          .px-4 {
            padding-right: 16px;
            padding-left: 16px;
          }
          .py-2 {
            padding-top: 8px;
            padding-bottom: 8px;
          }
        `;
      const d = new Printd();
      d.print(document.getElementById("barcodes"), [cssText]);
    },
  },

  props: ["items"],

  data() {
    return {
      processing: false,
    };
  },
};
</script>

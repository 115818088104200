import Vue from 'vue';

export default {
  addLanguage(language, noProgress = true) {
    return Vue.axios.post(`i18n/language`, language, {noProgress: noProgress});
  },

  editLanguage(_id, language, noProgress = true) {
    return Vue.axios.put(`i18n/language/${_id}`, language, {noProgress: noProgress});
  },

  getLanguage(_id, noProgress = true) {
    return Vue.axios.get(`i18n/language/${_id}`, {noProgress: noProgress});
  },

  deleteLanguage(_id, noProgress = true) {
    return Vue.axios.delete(`i18n/language/${_id}`, {noProgress: noProgress});
  },

  getLanguages(noProgress = true) {
    return Vue.axios.get(`i18n/language/list`, {noProgress: noProgress});
  },

  getActiveLanguages(noProgress = true) {
    return Vue.axios.get(`i18n/language/list/active`, {noProgress: noProgress});
  },

  addTranslation(tr, noProgress = true) {
    return Vue.axios.post(`i18n/tr`, tr, {noProgress: noProgress});
  },

  editTranslation(_id, tr, noProgress = true) {
    return Vue.axios.put(`i18n/tr/${_id}`, tr, {noProgress: noProgress});
  },

  getTranslation(_id, noProgress = true) {
    return Vue.axios.get(`i18n/tr/${_id}`, {noProgress: noProgress});
  },

  deleteTranslation(_id, noProgress = true) {
    return Vue.axios.delete(`i18n/tr/${_id}`, {noProgress: noProgress});
  },

  getTranslations(pageNumber, pageSize, keyword, noProgress = true) {
    if (keyword) return Vue.axios.get(`i18n/tr/list/${pageNumber}/${pageSize}?keyword=${keyword}`, {noProgress: noProgress});
    return Vue.axios.get(`i18n/tr/list/${pageNumber}/${pageSize}`, {noProgress: noProgress});
  },

  processCSV(url, noProgress = true) {
    return Vue.axios.post(`csv/i18n`, {url: url}, {noProgress: noProgress});
  },

  sync(code) {
    return Vue.axios.post(`i18n/sync/${code}`, {}, {noProgress: true});
  }
}

import Store from "../../../services/store";
import router from "../../../router";
import config from "../../../config";

export default {
  name: "Locations",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Locations"))
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchLocations();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchLocations() {
      this.loading = true;
      return Store.getLocations(this._id, false)
        .then((res) => {
          this.locations = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            x.status = x.isActive ? "Active" : "Inactive";
            return x;
          });
          this.loading = false;
        })
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.locations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteLocation(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchLocations();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    save(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      if (this.editedItem._id)
        request = Store.editLocation(
          this.editedItem._id,
          this.editedItem,
          false
        );
      else request = Store.addLocation(this._id, this.editedItem, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          this.fetchLocations();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          if (e.response && e.response.status === 401) {
            this.error = "Pincode already exists";
            setTimeout(() => (this.error = ""), 2500);
          } else {
            Store.snackbarError();
          }

          this.processing = false;
          this.loading = false;
        });
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add new location" : "Edit location";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    store() {
      return this.$store.state.activeStore;
    },

    dcPrefix() {
      return this.store.deliveryChargeBy == "amount"
        ? this.$store.state.country.currencySymbol
        : "";
    },

    dcSuffix() {
      return this.store.deliveryChargeBy == "percent" ? "%" : "";
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  data: () => {
    return {
      _id: "",
      locations: [],
      enums: {},
      search: "",
      loading: true,
      valid: false,
      dialog: false,
      processing: false,

      error: "",

      menu: false,
      cenums: {},

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
      },

      confirmDialog: false,
      beingDeleted: {},

      rowItems: config.rowItems,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Area(s)", value: "name" },
        { text: "Pin / Postal / Zip Code", value: "pincode" },
        { text: "Delivery Charge", value: "deliveryCharge" },
        { text: "Status", value: "status" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
      },
    };
  },
};

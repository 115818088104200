<template>
  <h5>
    <span v-for="(item, index) in items" :key="index">
      <small v-if="item.isLast"> {{ item.title }} </small>
      <span v-else-if="!item.to"> {{ item.title }} </span>
      <router-link v-else :to="item.to"> {{ item.title }} </router-link>
      <i
        v-if="index < items.length - 1"
        class="fa fa-caret-right ml-2 mr-1"
        style="font-size: 0.8em;"
      ></i>

      <!-- customized for order info breadcrumb -->
      <v-chip
        class="pl-2 pr-2 vc"
        x-small
        v-if="orderCount > 0 && item.isLast"
        :color="orderCount === 1 ? 'success' : 'warning'"
      >
        {{ getNumberWithOrdinal(orderCount) }} order
      </v-chip>
    </span>
  </h5>
</template>
<script>
export default {
  name: "Breadcrumb",

  props: ["items", "orderCount"],

  methods: {
    getNumberWithOrdinal(n) {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
  },

  data() {
    return {};
  },
};
</script>

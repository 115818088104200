import Vue from "vue";
import moment from "moment";

import Inventory from "../../../services/inventory";
import FileUpload from "../../../services/file-upload";
import router from "../../../router";
import Store from "../../../services/store";
import config from "../../../config";

import MasterInventory from "../../../services/master-inventory";
import convert from "convert-units";
import * as JsBarcode from "jsbarcode";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "Product",

  created() {
    this._id = this.$route.params._id || config.growcifyMasterInventory;
    this.isMI = this._id === config.growcifyMasterInventory;

    if (!this.amIAllowed("Products"))
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.productId = this.$route.params.productId;
    // for (let p = 0; p < 6; p++) this.defaultItem.photos.push(this.photoItem);
    this.loading = true;

    const enumRequest = this.isMI
      ? MasterInventory.getProductEnums()
      : Inventory.getProductEnums(this._id);

    const p1 = this.getProduct();
    const p2 = enumRequest
      .then((res) => (this.enums = res.data))
      .catch((e) => console.log(e));

    const p3 = this.fetchCommonEnums();
    const p4 = this.fetchProductHistory();
    const p5 = this.fetchProductReviews();
    const p6 = this.fetchProductAddonGroups();

    return Promise.all([p1, p2, p3, p4, p5, p6])
      .then(() => (this.loading = false))
      .catch((e) => console.log(e));
  },

  watch: {
    "$route.path": "getProduct",

    "editedItem.dimensionUnit"(n, o) {
      if (n && o) {
        if (this.editedItem.width)
          this.editedItem.width = convert(this.editedItem.width)
            .from(o)
            .to(n);
        if (this.editedItem.length)
          this.editedItem.length = convert(this.editedItem.length)
            .from(o)
            .to(n);
        if (this.editedItem.height)
          this.editedItem.height = convert(this.editedItem.height)
            .from(o)
            .to(n);
      }
    },
  },

  methods: {
    resetPhotos() {
      this.defaultItem.photos = [];
    },

    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.cenums = ce);

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => console.log(e));
    },

    getProduct(pid = this.productId) {
      if (pid.length > 24) {
        const ar = pid.split("/");
        pid = ar[ar.length - 1];
      }
      this.ploading = true;
      const request = this.isMI
        ? MasterInventory.getProduct(pid, false)
        : Inventory.getProduct(pid, false);
      return request
        .then((x) => {
          this.product = x.data;
          this.product.photos = this.product.photos.filter((x) => x);

          this.product.children = this.product.children.map((x, index) => {
            x.categoryName = x.category?.name || "--";
            x.category = x.category?._id || undefined;
            x.index = index + 1 + ".";
            x.sizeName = x.size + x.unit;

            // x.photos = x.photos.map((x) => {
            //   return {
            //     _id: x || undefined,
            //     uploading: false,
            //     uploadProgress: 0,
            //     sheet: false,
            //   };
            // });

            // const totalEmpty = 6 - x.photos.length;
            // for (let p = 0; p < totalEmpty; p++) x.photos.push(this.photoItem);
            return x;
          });
          this.product.addons = this.product.addons.map((x, index) => {
            x.index = index + 1;
            return x;
          });

          this.ploading = false;
          setTimeout(() => this.generateBarcode(), 100);
        })
        .catch((e) => {
          console.log(e);
          this.ploading = false;
        });
    },

    getBarcodeText() {
      if (this.product.barcodeText) return this.product.barcodeText;
      return this.product.name + " - " + this.product.size + this.product.unit;
    },

    generateBarcode() {
      JsBarcode("#productBarcode", this.product.barcodeID || this.product._id, {
        text: this.getBarcodeText(),
      });
    },

    editItem(item) {
      if (item._id) {
        if (
          item.wholesalePrice ||
          item.weight ||
          item.length ||
          item.width ||
          item.height
        )
          item.expanded = true;
        else item.expanded = false;
      }

      this.editedIndex = this.product.children.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.category = this.product.category._id;
      if (!this.editedItem.tags) this.editedItem.tags = [];
      if (!this.editedItem._id) {
        this.editedItem.parent = this.productId;
        this.editedItem.name = this.product.name;
        this.editedItem.vegan = this.product.vegan;
        this.editedItem.brand = this.product.brand;
        this.editedItem.description = this.product.description;
      }

      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      const inventory = this.isMI ? MasterInventory : Inventory;
      return inventory
        .deleteProduct(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.getProduct();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    duplicateItem(item) {
      this.duplicateDialog = true;
      this.beingDuplicated = JSON.parse(
        JSON.stringify(Object.assign({}, item))
      );
    },

    closeDuplicate() {
      this.duplicateDialog = this.duplicatePromptDialog = false;
      this.beingDuplicated = {};
    },

    nextDuplicate() {
      this.duplicateDialog = false;

      delete this.beingDuplicated._id;
      delete this.beingDuplicated.__v;
      delete this.beingDuplicated.createdAt;
      delete this.beingDuplicated.updatedAt;
      this.beingDuplicated.name = this.beingDuplicated.name + " (Copy)";
      this.duplicatePromptDialog = true;
    },

    finalDuplicate() {
      if (!this.beingDuplicated.name) return;
      this.editedItem = this.beingDuplicated;
      this.save(true, true, true);
    },

    sampleVariant() {
      const sampleVariantItem = JSON.parse(
        JSON.stringify(Object.assign({}, this.product))
      );

      delete sampleVariantItem._id;
      delete sampleVariantItem.__v;
      delete sampleVariantItem.createdAt;
      delete sampleVariantItem.updatedAt;

      if (!sampleVariantItem.tags) sampleVariantItem.tags = [];

      sampleVariantItem.parent = this.product._id;
      // sampleVariantItem.photos = sampleVariantItem.photos.map((x) => {
      //   return {
      //     _id: x || undefined,
      //     uploading: false,
      //     uploadProgress: 0,
      //     sheet: false,
      //   };
      // });

      //if (!this.product.children.length) sampleVariantItem.name = sampleVariantItem.name + ' (Variant)'
      //else sampleVariantItem.name = sampleVariantItem.name + ' (Variant '+(this.product.children.length+1)+')';

      this.beingDuplicated = sampleVariantItem;
      //this.save(true, true, true);
      this.duplicatePromptDialog = true;
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true, true);
    },

    save(closeModal = true, fetchProduct = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      //this.processing = this.ec.saving = true;
      if (!this.ec._id) this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedItem);
      object.photos = object.photos.map((x) =>
        x._id ? x._id : typeof x === "string" ? x : ""
      );
      object.photos = object.photos.filter((x) => x);

      if (!object.parent || object.parent === "") delete object.parent;
      if (!object.category || object.category === "") delete object.category;

      //route-change
      if (this.isMI) {
        if (this.editedItem._id)
          request = MasterInventory.editProduct(
            this.editedItem._id,
            object,
            false
          );
        else request = MasterInventory.addProduct(object, false);
      } else {
        if (this.editedItem._id)
          request = Inventory.editProduct(this.editedItem._id, object, false);
        else request = Inventory.addProduct(this._id, object, false);
      }

      return request
        .then(() => {
          this.processing = this.ec.saving = this.editedItem.processing = false;

          if (closeModal) this.close();
          if (!this.ec._id && fetchProduct) this.getProduct();
          this.resetColumn();
          this.resetPhotos();
          this.closeDuplicate();
          this.closeConfirmPhoto();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = this.ec.saving = this.editedItem.processing = false;
          this.resetColumn();
          this.closeDuplicate();
          this.closeConfirmPhoto();
          Store.snackbarError();
        });
    },

    handleClick(index, photo) {
      if (!photo.id) this.pickImage(index);
      else photo.sheet = !photo.sheet;
    },

    getRef(index) {
      return this.$refs["inputUpload" + index][0];
    },

    getURL(data) {
      const array = data.split(".");
      if (
        data &&
        !array.includes(config.growcifyMasterInventory) &&
        (array[1] === "png" || array[1] === "mp4")
      ) {
        return getS3URL(this._id, data);
      }

      return data;
    },

    pushPhotoObject(index, _id, uploading, uploadProgress, sheet) {
      Vue.set(this.editedItem.photos, index, {
        uploading: uploading,
        _id: _id,
        uploadProgress: uploadProgress,
        sheet: sheet,
      });
    },

    pickImage(index) {
      this.getRef(index).click();
    },

    fileSelected(index) {
      const file = this.getRef(index).files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      //const localURL = URL.createObjectURL(file);
      this.pushPhotoObject(index, undefined, true, 0, false);
      this.processing = true;
      return FileUpload.upload(this._id, "products", file)
        .then((x) => {
          const _id = x.data.file;
          this.pushPhotoObject(index, _id, false, 0, false);
          if (this.editedItem._id) this.save(false, false); //only while editing
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    invalidImageError() {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 2000);
    },

    isFileImage(file) {
      return file && file["type"].split("/")[0] === "image";
    },

    editColumn(_id, value, index, type) {
      this.ec._id = _id;
      this.ec.value = value;
      this.ec.index = index;
      this.ec.type = type;
    },

    saveCloumn() {
      if (!this.ec.value) return;

      this.editedItem = this.product.children.find(
        (x) => x._id === this.ec._id
      );
      if (this.ec.type === "price") this.editedItem.price = this.ec.value;
      if (this.ec.type === "quantity") this.editedItem.quantity = this.ec.value;
      if (this.ec.type === "name") this.editedItem.name = this.ec.value;

      this.save();
    },

    resetColumn() {
      this.ec = {
        _id: "",
        value: "",
        index: 0,
        type: "",
        saving: false,
      };
    },

    closeConfirmPhoto() {
      this.confirmDialogPhoto = false;
      this.beingDeletedPhoto = {};
    },

    deletePhoto(index, photo) {
      this.confirmDialogPhoto = true;
      this.beingDeletedPhoto = photo;
    },

    finalDeletePhoto() {
      this.editedItem.photos = this.editedItem.photos.filter(
        (x) => !x._id || x._id !== this.beingDeletedPhoto._id
      );
      // this.editedItem.photos.push(this.photoItem);
      this.save(false, true, true);
    },

    editAddon(item) {
      this.isAddon = true;
      this.editedAddonIndex = this.product.addons.indexOf(item);
      this.editedAddon = Object.assign({}, item);
      this.addonDialog = true;
    },

    closeAddon() {
      this.addonDialog = false;
      this.editedAddon = {};
      this.isAddon = false;
    },

    // eslint-disable-next-line
    saveAddon(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form3 && !this.$refs.form3.validate())
        return;

      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.editedAddon._id)
        request = Inventory.editAddon(
          this.editedAddon._id,
          this.editedAddon,
          false
        );
      else
        request = Inventory.addAddon(this.productId, this.editedAddon, false);

      return request
        .then(() => {
          this.processing = false;
          this.addonDialog = false;
          this.isAddon = false;
          this.editedAddon = {};
          this.getProduct();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          this.isAddon = false;
          Store.snackbarError();
        });
    },

    changeStatusAddon(item) {
      this.editedAddon = item;
      this.saveAddon(true, true);
    },

    deleteAddon(item) {
      this.confirmDialogAddon = true;
      this.beingDeletedAddon = item;
    },

    finalDeleteAddon() {
      this.processing = true;
      return Inventory.deleteAddon(this.beingDeletedAddon._id, false)
        .then(() => {
          this.confirmDialogAddon = this.processing = false;
          this.getProduct();
        })
        .catch((e) => {
          this.confirmDialogAddon = this.processing = false;
          console.log(e);
        });
    },

    closeConfirmAddon() {
      this.confirmDialogAddon = false;
      this.beingDeletedAddon = {};
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.isAddon && this.editedAddon._id) this.saveAddon();
      else if (this.editedItem._id) this.save(false, false, false);
    },

    fetchProductHistory() {
      this.hloading = true;
      return Inventory.getProductHistory(
        this._id,
        this.productId,
        this.isVendor,
        false
      )
        .then((r) => {
          this.historyItems = r.data;
          this.hloading = false;
        })
        .catch((e) => {
          this.hloading = false;
          console.log(e);
        });
    },

    formatDate(date, format = "lll") {
      return moment(date).format(format);
    },

    fetchProductReviews() {
      this.rloading = true;
      return Inventory.getProductReviews(this._id, this.productId, false)
        .then((response) => {
          const data = response.data;
          data.forEach((item, index) => (item.index = index + 1 + "."));
          this.reviews = data;
          this.rloading = false;
        })
        .catch((e) => {
          this.rloading = false;
          console.log(e);
        });
    },

    closeConfirmReview() {
      this.confirmDialogReview = false;
      this.beingDeletedReview = {};
    },

    deleteReviewItem(item) {
      this.confirmDialogReview = true;
      this.beingDeletedReview = item;
    },

    finalDeleteReview() {
      this.processing = true;
      return Inventory.deleteProductReview(this.beingDeletedReview._id, false)
        .then(() => {
          this.closeConfirmReview();
          this.processing = false;
          this.fetchProductReviews();
        })
        .catch((e) => {
          this.processing = false;
          console.log(e);
        });
    },

    isVideo(item) {
      if (!item) return;
      const array = item.split(".");
      return array.includes("mp4");
    },

    fetchProductAddonGroups() {
      this.agloading = true;
      return Inventory.getProductAddonGroups(this.productId, false)
        .then((response) => {
          const data = response.data;
          data.forEach((item, index) => (item.index = index + 1 + "."));
          this.addonGroups = data;
          this.agloading = false;
        })
        .catch((e) => {
          this.agloading = false;
          console.log(e);
        });
    },

    editAddonGroup(item) {
      this.editedAddonGroupIndex = this.addonGroups.indexOf(item);
      this.editedAddonGroup = Object.assign({}, item);
      this.addonGroupDialog = true;
    },

    closeAddonGroup() {
      this.addonGroupDialog = false;
      this.editedAddonGroup = {};
    },

    saveAddonGroup(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form4 && !this.$refs.form4.validate()) {
        return;
      }

      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.editedAddonGroup._id) {
        request = Inventory.editAddonGroup(
          this.editedAddonGroup._id,
          this.editedAddonGroup,
          false
        );
      } else {
        request = Inventory.createAddonGroup(
          this.productId,
          this.editedAddonGroup,
          false
        );
      }

      return request
        .then(() => {
          this.processing = false;
          this.addonGroupDialog = false;
          this.editedAddonGroup = {};
          this.fetchProductAddonGroups();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    changeStatusAddonGroup(item) {
      this.editedAddonGroup = item;
      this.saveAddonGroup(true, true);
    },

    deleteAddonGroup(item) {
      this.confirmDialogAddonGroup = true;
      this.beingDeletedAddonGroup = item;
    },

    closeConfirmAddonGroup() {
      this.confirmDialogAddonGroup = false;
      this.beingDeletedAddonGroup = {};
    },

    finalDeleteAddonGroup() {
      this.processing = true;
      return Inventory.deleteAddonGroup(this.beingDeletedAddonGroup._id, false)
        .then(() => {
          this.confirmDialogAddonGroup = this.processing = false;
          this.fetchProductAddonGroups();
        })
        .catch((e) => {
          this.confirmDialogAddonGroup = this.processing = false;
          console.log(e);
        });
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Create new variant" : "Edit variant";
    },

    formAddonTitle() {
      return this.editedAddonIndex === -1 ? "Create new add-on" : "Edit add-on";
    },

    formAddonGroupTitle() {
      return this.editedAddonGroupIndex === -1
        ? "Create new add-on group"
        : "Edit add-on group";
    },

    pi() {
      return this.photoItem;
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },
  },

  data: () => {
    return {
      _id: "", //storeId
      isMI: false,

      productId: "",
      product: {
        category: {},
      },
      enums: {},
      loading: false,
      processing: false,
      ploading: false,

      avalid: false,

      search: "",
      dialog: false,
      varient: {},
      editedIndex: -1,
      editedItem: {
        expanded: false,
        tags: [],
      },
      defaultItem: {
        name: "",
        isActive: true,
        inStock: true,
        expanded: false,
        parent: "",
        photos: [],
        tags: [],
        dimensionUnit: "cm",
      },

      editedCloumn: {
        value: "",
      },

      ec: {
        _id: "",
        type: "",
        value: "",
        index: 0,
        saving: false,
      },

      photoItem: {
        uploading: false,
        _id: undefined,
        progress: 0,
        sheet: false,
      },

      confirmDialog: false,
      beingDeleted: {},

      duplicateDialog: false,
      beingDuplicated: {},
      duplicatePromptDialog: false,

      confirmDialogPhoto: false,
      beingDeletedPhoto: {},

      cenums: {},
      translationModal: false,
      translationField: "",

      confirmDialogReview: false,
      beingDeletedReview: {},
      rsearch: "",
      reviews: [],
      rloading: false,
      reviewEditedItem: {},
      rheaders: [
        {
          text: "#",
          align: "start",
          value: "index",
        },
        { text: "Customer", value: "user.name" },
        { text: "Rating", value: "rating" },
        { text: "Review", value: "review", width: "50%" },
        { text: "Date & Time", value: "createdAt", align: "right" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      headers: [
        {
          text: "#",
          align: "start",
          value: "index",
        },
        { text: "Name", value: "name" },
        { text: "In stock?", value: "inStock" },
        { text: "Quantity", value: "quantity" },
        { text: "Size", value: "sizeName", sortable: false },
        { text: "Price", value: "price" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      categories: [],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => !!v || v === false || "It is required"],
        unitRule: [(v) => !!v || v === "" || "It is required"],
        quantityRule: [(v) => v >= 0 || "Enter a valid number"],
        addonsLength: [
          (v) => !v || v.length > 0 || "Select at least one or more add-ons",
        ],
      },

      hsearch: "",
      hloading: true,
      historyItems: [],
      hheaders: [
        { text: "OID", value: "oid" },
        { text: "Ordered Qty", value: "qty" },
        { text: "Ordered At Price", value: "price" },
        { text: "Status", value: "status" },
        { text: "Date & Time", value: "createdAt", align: "right" },
      ],

      searcha: "",
      addonDialog: false,
      editedAddonIndex: -1,
      editedAddon: {},
      confirmDialogAddon: false,
      beingDeletedAddon: {},
      defaultAddon: {
        inStock: true,
        isActive: true,
      },
      isAddon: false,
      aheaders: [
        {
          text: "#",
          align: "start",
          value: "index",
        },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "In stock?", value: "inStock" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      agInput: "",
      searchag: "",
      agloading: false,
      agProcessing: false,
      addonGroups: [],
      editedAddonGroup: {
        addons: [],
      },
      addonGroupDialog: false,
      editedAddonGroupIndex: -1,
      confirmDialogAddonGroup: false,
      beingDeletedAddonGroup: {},
      defaultAddonGroup: {
        isMultiselect: false,
        isActive: true,
      },

      agHeaders: [
        {
          text: "#",
          align: "start",
          value: "index",
        },
        { text: "Title", value: "title" },
        { text: "Is Multiselect?", value: "isMultiselect" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      agaHeaders: [
        {
          text: "#",
          align: "start",
          value: "index",
        },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Status", value: "isActive" },
      ],
    };
  },
};

import Vue from 'vue';

export default {
  scheduleMeeting(object, noProgress = true) {
    return Vue.axios.post(`meeting/schedule`, object, {noProgress: noProgress});
  },

  getLeadMeetings(leadId, noProgress = true) {
    return Vue.axios.get(`meeting/history/lead/${leadId}`, {noProgress: noProgress});
  },

  getMeetingsByDate(timings, noProgress = true) {
    return Vue.axios.post(`meeting/list`, timings, {noProgress: noProgress});
  },

  getMeetingsHistory(filter, noProgress = true) {
    return Vue.axios.post(`meeting/history`, filter, {noProgress: noProgress});
  }
}
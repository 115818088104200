import Store from "../../services/store";
import Global from "../../services/global";
import config from "../../config";

export default {
  name: "GrowcifyAddons",

  props: ["_id"],

  computed: {
    store() {
      return this.$store.state.activeStore;
    },

    country() {
      return this.$store.state.country;
    },
  },

  methods: {
    getPrice(item) {
      let amount = item.price.INR;

      if (this.country && this.country.currencyCode != "INR") {
        amount = item.price.USD;
      }

      return amount;
    },

    formatAmount(amount) {
      let formattedAmount = amount;
      if (this.country.currencyCode === "INR") {
        formattedAmount = this.currencyPipe(amount, "₹");
      } else {
        formattedAmount = this.currencyPipe(amount, "$");
      }

      return formattedAmount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    buyNow(item) {
      this.purchasedModal = false;
      this.beingPurchased = item;

      const amount = Global.getTaxAmount(this.getPrice(item));
      const currencyCode = this.country.currencyCode;

      const options = Global.getRazOptions(
        amount,
        "Addon Purchase - " + item.title,
        currencyCode
      );

      const newthis = this;
      options.handler = function(response) {
        newthis.processPayment(response.razorpay_payment_id);
      };

      new window.Razorpay(options).open();
    },

    processPayment(paymentId) {
      if (!paymentId) {
        Store.snackbarError();
        return;
      }

      Store.snackbarStart();
      this.processing = true;

      const item = this.beingPurchased;
      const addon = item;
      addon.paymentId = paymentId;
      addon.paidAmount = Global.getTaxAmount(this.getPrice(item));
      addon.priceAmount = this.getPrice(item);
      addon.taxAmount = addon.paidAmount - addon.priceAmount;
      addon.currencyCode = this.country.currencyCode;

      return Store.processGrowcifyAddon(this._id, addon, true)
        .then(() => {
          this.purchasedModal = true;
          this.processing = false;
          Store.snackbarSuccess();

          // process the requests
          if (item.slug == "growcify-v2") {
            this.$store.state.growcifyV2AddonPurchased = true;
          } else if (item.slug == "app-analytics") {
            this.$store.state.analyticsAddonPurchased = true;
          }
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    isPurchased(item) {
      if (item.slug == "growcify-v2") {
        return this.store.growcifyV2AddonPurchased;
      }

      if (item.slug == "app-analytics") {
        return this.store.analyticsAddonPurchased;
      }

      return false;
    },
  },

  data() {
    return {
      processing: false,
      beingPurchased: {},
      purchasedModal: false,
      checkmarkAnimationJson: config.checkmarkAnimationJson,

      addons: [
        {
          _id: 1,
          slug: "growcify-v2",
          title: "Upgrade to Growcify V2",
          description:
            "Meet an advanced and feature-rich app and upgrade to the all-new Growcify V2",
          isPaid: true,
          price: {
            INR: 25000,
            USD: 1000,
          },
        },
        {
          _id: 2,
          slug: "app-analytics",
          title: "App Analytics (Firebase/Google)",
          description:
            "Get deeper insight of your app and users behaviour using Firebase Analytics",
          isPaid: true,
          isV2Only: true,
          price: {
            INR: 12000,
            USD: 500,
          },
        },
      ],
    };
  },
};

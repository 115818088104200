var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-container',{attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"margin-top":"-15px","margin-bottom":"15px"}},[_c('v-col',{staticClass:"nobp",attrs:{"cols":"8","sm":"6"}},[(!_vm.isMobile)?_c('h5',{class:_vm.isDT ? '' : 'text-primary',staticStyle:{"margin-bottom":"0"}},[_vm._v(" Hello, "+_vm._s(_vm.loggedInName)+"! ")]):_c('h6',{class:_vm.isDT ? '' : 'text-primary',staticStyle:{"margin-bottom":"0"}},[_vm._v(" Hello, "+_vm._s(_vm.loggedInName)+"! ")]),(!_vm.isMobile)?_c('small',[_vm._v(_vm._s(_vm.greetingMessage)+" Have a great time!")]):_c('small',[_vm._v("Have a great time ahead!")])]),_c('v-col',{staticClass:"nobp vc text-right",attrs:{"cols":"4","sm":"6"}},[(!_vm.isMobile)?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.toggleFullscreen}},[_c('v-icon',[_vm._v("mdi-fullscreen")])],1):_vm._e(),(_vm.posAccess)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":_vm.isMobile,"transition":"scale-transition","color":"primary","to":{ name: 'store-pos', params: { _id: _vm._id } }}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"color":"white"}},'span',attrs,false),on),[_c('b',[_vm._v("POS")])])]}}],null,false,1822849045)},[_c('span',[_vm._v("POS")])])],1):_vm._e(),(!_vm.isMobile)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","outlined":""},on:{"click":_vm.refreshDashboard}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"start":""}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}],null,false,2715343593)},[_c('span',[_vm._v("Refresh")])])],1):_vm._e()],1)],1),_c('section',[_c('v-dialog',{ref:"calDialog",attrs:{"persistent":"","width":"290px"},model:{value:(_vm.calModal),callback:function ($$v) {_vm.calModal=$$v},expression:"calModal"}},[_c('v-date-picker',{attrs:{"scrollable":"","elevation":"15","max":_vm.calMaxDate,"type":_vm.calMode === 'month' ? 'month' : 'date'},model:{value:(_vm.calDate),callback:function ($$v) {_vm.calDate=$$v},expression:"calDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.calModal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.calDialog.save(_vm.calDate)}}},[_vm._v("OK")])],1)],1),_c('b-row',[(!_vm.isVendor)?_c('b-col',{class:_vm.isMobile ? 'col-12' : 'col-4'},[_c('v-card',{staticStyle:{"border-radius":"5px"},attrs:{"outlined":"","to":("/business/" + _vm._id + "/customers")}},[(_vm.nfetching)?_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary","height":"2px"}}):_vm._e(),_c('div',{staticStyle:{"padding-left":"16px","padding-right":"16px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('span',[_c('b',[_vm._v(_vm._s((_vm.numbers.customers || 0).toLocaleString()))])]),_c('div',[_c('small',{staticStyle:{"font-size":"14px"}},[_vm._v("Customers")])])]),_c('v-col',{staticClass:"vc text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('section',_vm._g(_vm._b({},'section',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant")])],1)]}}],null,false,1907212894)},[_c('span',[_vm._v("Total Number of Registered Customers")])])],1)],1)],1)],1)],1):_vm._e(),(!_vm.isVendor)?_c('b-col',{class:_vm.isMobile ? 'col-12' : 'col-4'},[_c('v-card',{staticStyle:{"border-radius":"5px"},attrs:{"outlined":"","to":("/business/" + _vm._id + "/orders")}},[(_vm.nfetching)?_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary","height":"2px"}}):_vm._e(),_c('div',{staticStyle:{"padding-left":"16px","padding-right":"16px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('span',[_c('b',[_vm._v(_vm._s((_vm.numbers.orders || 0).toLocaleString()))])]),_c('div',[_c('small',{staticStyle:{"font-size":"14px"}},[_vm._v("Orders")])])]),_c('v-col',{staticClass:"vc text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('section',_vm._g(_vm._b({},'section',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant")])],1)]}}],null,false,1907212894)},[_c('span',[_vm._v("Total Number of Orders")])])],1)],1)],1)],1)],1):_vm._e(),(!_vm.isVendor)?_c('b-col',{class:_vm.isMobile ? 'col-12' : 'col-4'},[_c('v-card',{staticStyle:{"border-radius":"5px"},attrs:{"outlined":"","to":("/business/" + _vm._id + "/settings/sms?buy=true"),"color":_vm.store.transactionalCredits < 25
                ? 'error'
                : _vm.store.transactionalCredits < 100
                ? 'warning'
                : ''}},[(_vm.sfetching)?_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary","height":"2px"}}):_vm._e(),_c('div',{staticStyle:{"padding-left":"16px","padding-right":"16px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('span',[_c('b',[_vm._v(_vm._s((_vm.store.transactionalCredits || 0).toLocaleString()))])]),_c('div',[_c('small',{staticStyle:{"font-size":"14px"}},[_vm._v("SMS Credits")])])]),_c('v-col',{staticClass:"vc text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('section',_vm._g(_vm._b({},'section',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant")])],1)]}}],null,false,1907212894)},[_c('span',[_c('span',[_vm._v("Remaining SMS Credits")])])])],1)],1)],1)],1)],1):_vm._e(),_c('b-col',{class:_vm.isMobile ? 'col-12' : 'col-4'},[_c('v-card',{staticStyle:{"border-radius":"5px"},attrs:{"outlined":""}},[(_vm.nfetching || (_vm.cdprocessing && _vm.calMode == 'day'))?_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary","height":"2px"}}):_vm._e(),_c('div',{staticStyle:{"padding-left":"16px","padding-right":"16px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.isMobile ? 6 : 8}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm._f("fc")(_vm.numbers.todayEarning || 0)))])]),_c('div',[_c('small',{staticStyle:{"font-size":"14px"}},[_vm._v("Sales - Day")])])]),_c('v-col',{staticClass:"vc text-right",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.calMode = 'day';
                    _vm.calModal = true;}}},[_c('v-icon',[_vm._v("mdi-calendar")]),_c('div',[_c('small',[_vm._v(_vm._s(_vm.formateDate(_vm.calDayDate, "day")))])])],1)],1)],1)],1)],1),_c('b-col',{class:_vm.isMobile ? 'col-12' : 'col-4'},[_c('v-card',{staticStyle:{"border-radius":"5px"},attrs:{"outlined":""}},[(_vm.nfetching || (_vm.cdprocessing && _vm.calMode == 'month'))?_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary","height":"2px"}}):_vm._e(),_c('div',{staticStyle:{"padding-left":"16px","padding-right":"16px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.isMobile ? 6 : 8}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm._f("fc")(_vm.numbers.thisMonthEarning || 0)))])]),_c('div',[_c('small',{staticStyle:{"font-size":"14px"}},[_vm._v("Sales - Month")])])]),_c('v-col',{staticClass:"vc text-right",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.calMode = 'month';
                    _vm.calModal = true;}}},[_c('v-icon',[_vm._v("mdi-calendar")]),_c('div',[_c('small',[_vm._v(_vm._s(_vm.formateDate(_vm.calMonthDate, "month")))])])],1)],1)],1)],1)],1),_c('b-col',{class:_vm.isMobile ? 'col-12' : 'col-4'},[_c('v-card',{staticStyle:{"border-radius":"5px"},attrs:{"outlined":""}},[(_vm.nfetching)?_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary","height":"2px"}}):_vm._e(),_c('div',{staticStyle:{"padding-left":"16px","padding-right":"16px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm._f("fc")(_vm.numbers.totalEarning || 0)))])]),_c('div',[_c('small',{staticStyle:{"font-size":"14px"}},[_vm._v("Sales - Total")])])]),_c('v-col',{staticClass:"vc text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('section',_vm._g(_vm._b({},'section',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant")])],1)]}}])},[_c('span',[_vm._v("Total Sales")])])],1)],1)],1)],1)],1)],1),_c('div',{staticStyle:{"height":"25px"}}),_c('b-row',[(!_vm.isVendor)?_c('b-col',{attrs:{"cols":"12","sm":"6"}},[(!_vm.datacollection)?_c('v-progress-linear',{staticClass:"mb-0",staticStyle:{"border-radius":"5px"},attrs:{"indeterminate":"","color":_vm.lcolor,"height":"500px"}}):_c('line-chart',{attrs:{"chart-data":_vm.datacollection}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[(!_vm.datacollection2)?_c('v-progress-linear',{staticClass:"mb-0",staticStyle:{"border-radius":"5px"},attrs:{"indeterminate":"","color":_vm.lcolor,"height":"500px"}}):_c('line-chart',{attrs:{"chart-data":_vm.datacollection2}})],1)],1),_c('b-row',[_c('b-col',[(_vm.datacollection && _vm.datacollection2)?_c('hr'):_vm._e()])],1),_c('b-row',[_c('b-col',[_c('h5',[_vm._v("Recent Orders")]),_c('v-data-table',{attrs:{"headers":_vm.odHeaders,"items":_vm.orders,"loading":_vm.oloading,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"item.address.name",fn:function(ref){
                    var item = ref.item;
return [(_vm.hcifv)?_c('span',[_vm._v("-")]):_c('div',[_c('router-link',{attrs:{"to":{
                    name: 'store-order-info',
                    params: { _id: _vm._id, orderId: item._id },
                  }}},[_c('span',{staticStyle:{"color":"var(--primary)"}},[_c('TextWrapper',{staticStyle:{"display":"inline"},attrs:{"text":item.address
                          ? item.address.name
                          : item.user.name || item.user.mobile,"length":20}})],1)]),(item.orderCount && !_vm.$store.state.activeStore.isVendor)?_c('v-chip',{staticClass:"ml-1 pl-2 pr-2",attrs:{"x-small":"","color":item.orderCount === 1 ? 'success' : 'warning'}},[_vm._v(_vm._s(_vm.getNumberWithOrdinal(item.orderCount)))]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":""},on:{"click":function($event){return _vm.copyID(item._id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copyOIDText))])]),(item.notes)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[_c('span',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(item.notes))])]):_vm._e()],1)]}},{key:"item.address.mobile",fn:function(ref){
                          var item = ref.item;
return [(_vm.hcifv)?_c('span',[_vm._v("-")]):_c('span',[_vm._v("+"+_vm._s(item.address ? _vm.getCallingCode(item.address) + " " + item.address.mobile : _vm.getCallingCode(item.user) + " " + item.user.mobile))])]}},{key:"item.amount",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.amountFormatted(item))+" ")]}},{key:"item.sd",fn:function(props){return [(_vm.canEdit)?_c('v-edit-dialog',{attrs:{"large":"","return-value":props.item.status},on:{"save":function($event){_vm.canEdit ? _vm.changeStatus(props.item, props.item.status) : ''},"update:returnValue":function($event){return _vm.$set(props.item, "status", $event)},"update:return-value":function($event){return _vm.$set(props.item, "status", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4"},[_vm._v("Edit status")]),_c('v-select',{staticStyle:{"padding-top":"5px"},attrs:{"autofocus":"","hide-details":"","items":_vm.enums.status,"item-value":"key","item-text":"value"},model:{value:(props.item.status),callback:function ($$v) {_vm.$set(props.item, "status", $$v)},expression:"props.item.status"}})]},proxy:true}],null,true)},[_c('StatusChip',{attrs:{"status":props.item.status,"is-link":true}})],1):_c('StatusChip',{attrs:{"status":props.item.status}})]}},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [(_vm.doIHaveAccess('edit'))?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.processing ||
                        item.status === 'cancelled' ||
                        item.status === 'testing',"color":item.status === 'delivered' ? 'success' : ''},on:{"click":function($event){item.status !== 'delivered'
                        ? _vm.changeStatus(
                            item,
                            item.status === 'new'
                              ? 'accepted'
                              : item.status === 'accepted'
                              ? 'shipped'
                              : item.status === 'shipped'
                              ? 'delivered'
                              : ''
                          )
                        : ''}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.status === "delivered" ? "mdi-check-all" : "mdi-debug-step-over")+" ")]):_vm._e()]}}],null,true)},[(item.status !== 'delivered')?_c('span',[_vm._v(" The current order status is "),_c('b',[_vm._v(_vm._s(item.sd))]),_vm._v(". It'll change"),_c('br'),_vm._v("the status to "),_c('b',[_vm._v(_vm._s(item.status === "new" ? "Accepted" : item.status === "accepted" ? "Shipped" : item.status === "shipped" ? "Delivered" : ""))]),_vm._v(" and notify the customer. ")]):_vm._e(),(item.status === 'delivered')?_c('span',[_vm._v(" Delivered ")]):_vm._e()])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticStyle:{"height":"25px"}})])],1),(!_vm.isVendor)?_c('b-row',[_c('b-col',[_c('h5',[_vm._v("Recent Customers")]),_c('v-data-table',{attrs:{"headers":_vm.custHeaders,"items":_vm.customers,"loading":_vm.cloading,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"item.named",fn:function(ref){
                        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                  name: 'store-customer-info',
                  params: { _id: _vm._id, customerId: item._id },
                }}},[_c('span',{staticStyle:{"color":"var(--primary)"}},[_c('TextWrapper',{attrs:{"text":item.named,"length":20}})],1)])]}},{key:"item.mobile",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                  name: 'store-customer-info',
                  params: { _id: _vm._id, customerId: item._id },
                }}},[_c('span',{staticStyle:{"color":"var(--primary)"}},[_vm._v("+"+_vm._s(_vm.getCallingCode(item))+" "+_vm._s(item.mobile))])])]}}],null,false,4164444699)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
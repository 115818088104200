import Vue from "vue";

import router from "./router";
import Store from "./services/store";

import Banner from "./components/banner/banner.vue";
import Header from "./components/header/header.vue";
import Footer from "./components/footer/footer.vue";
import Inactive from "./components/inactive/inactive.vue";
import { getS3URL } from "./helpers/misc";

Vue.component(
  "ConfirmDialog",
  require("./components/confirm-dialog/confirm-dialog.vue").default
);
Vue.component(
  "ConfirmDialogPhoto",
  require("./components/confirm-dialog-photo/confirm-dialog-photo.vue").default
);
Vue.component(
  "CSVConfirmDialog",
  require("./components/csv-confirm-dialog/csv-confirm-dialog.vue").default
);
Vue.component(
  "Translator",
  require("./components/translator/translator.vue").default
);
Vue.component(
  "Notification",
  require("./components/notification/notification.vue").default
);
Vue.component("SMS", require("./components/sms/sms.vue").default);
Vue.component("Email", require("./components/email/email.vue").default);
Vue.component(
  "WhatsappMessage",
  require("./components/whatsapp-message/whatsapp-message.vue").default
);
Vue.component(
  "ShipmentWalletRecharge",
  require("./components/shipment-wallet-recharge/shipment-wallet-recharge.vue")
    .default
);
Vue.component(
  "TextWrapper",
  require("./components/text-wrapper/text-wrapper.vue").default
);
Vue.component(
  "PaymentLink",
  require("./components/payment-link/payment-link.vue").default
);

export default {
  name: "App",

  computed: {
    noHF() {
      return (
        this.$route &&
        (["verify", "businesses", "new-business"].includes(this.$route.name) ||
          !this.$store.getters.authStatus ||
          this.$store.state.superloading)
      );
    },

    isFullpage() {
      return ["store-pos", "store-pos-page"].includes(this.$route.name);
    },

    isLoginSignup() {
      return ["login", "signup", "signin", "businesses"].includes(
        this.$route.name
      );
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isGD() {
      return this.$store.state.isGD;
    },

    domainStore() {
      return this.$store.state.domainStore;
    },

    ngdLogo() {
      if (!this.domainStore || !this.domainStore.appLogo) return false;
      return getS3URL(this.domainStore._id, this.domainStore.appLogo);
    },
  },

  watch: {
    "$store.state.backgroundProcesses"() {
      this.isBPActive = this.$store.state.backgroundProcesses.length
        ? true
        : false;
    },

    "$vuetify.theme.dark"(n) {
      let color = "auto";
      let bgcolor = "white";
      if (n) {
        color = "#121212 #1e1e1e";
        bgcolor = "#1e1e1e";
      }
      document.getElementById("mhtml").style["scrollbar-color"] = color;
      document.getElementById("mhtml").style["background-color"] = bgcolor;
    },
  },

  components: {
    Banner,
    Header,
    Footer,
    Inactive,
  },

  methods: {
    viewOrder() {
      const info = this.$store.state.socketEventData;
      if (info && info.type === "order" && info._id && info.storeId) {
        this.$store.state.newOrderNotification = false;
        router
          .push("/business/" + info.storeId + "/orders/" + info._id)
          .then(() => {})
          .catch(() => {});
      }
    },

    setFavicon(url = false) {
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = url || "/cfavicon.png";
    },

    setTitle(title = false) {
      document.title = title || "Growcify";
    },

    fetchDashboardAccess() {
      this.dloading = true;
      return Store.getDashboardFromDomain(window.location.hostname)
        .then((x) => {
          if (x.data && x.data._id) {
            this.$store.state.domainStore = x.data;
            this.setTitle(this.domainStore.name);
            if (this.ngdLogo) this.setFavicon(this.ngdLogo);
            this.dsActive = true;
          }
          this.dloading = false;
        })
        .catch((e) => {
          this.dloading = false;
          console.log(e);
        });
    },

    closeWAesyapiModal() {
      localStorage.setItem("cwam", true);
      this.$store.state.waesyapiModal = false;
    },
  },

  mounted() {
    const theme = localStorage.getItem("dark");
    if (theme) {
      if (theme == "true") this.$vuetify.theme.dark = true;
      else this.$vuetify.theme.dark = false;
    }

    if (this.isGD) {
      this.setFavicon();
      this.setTitle();
    } else {
      this.fetchDashboardAccess();
    }
  },

  data() {
    return {
      isBPActive: false,
      dloading: false,
      dsActive: false,
    };
  },
};

<template>
  <section>
    <h6 class="mb-0">
      <b>{{ item.name }}</b>
    </h6>
    <small v-if="address">
      {{ address }}
    </small>

    <div class="mt-1"></div>

    <v-row>
      <v-col class="py-0" v-if="item.contactNumber">
        <small><b>Contact:</b> {{ item.contactNumber }}</small>
      </v-col>
      <v-col class="py-0" v-if="item.email">
        <small><b>Email:</b> {{ item.email }}</small>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0" v-if="item.taxCode">
        <small><b>GSTIN:</b> {{ item.taxCode }}</small>
      </v-col>
      <v-col class="py-0" v-if="item.pan">
        <small><b>PAN:</b> {{ item.pan }}</small>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="py-0" v-if="item.cin">
        <small><b>CIN:</b> {{ item.cin }}</small>
      </v-col>
      <v-col class="py-0" v-if="item.fssai">
        <small><b>FSSAI:</b> {{ item.fssai }}</small>
      </v-col>
    </v-row>

    <v-row v-if="item.stateName">
      <v-col class="py-0">
        <small><b>State:</b> {{ item.stateName }}</small>
      </v-col>
      <v-col class="py-0" v-if="item.stateGSTCode">
        <small><b>GST Code:</b> {{ item.stateGSTCode }}</small>
      </v-col>
    </v-row>
  </section>
</template>
<script>
export default {
  name: "PIParty",

  props: ["item", "address"],
};
</script>

import Store from "../../../services/store";
import FileUpload from "../../../services/file-upload";
import router from "../../../router";
import config from "../../../config";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "VendorLocations",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Vendors") || this.$store.state.activeStore.isVendor) {
      return router.push("/business/" + this._id);
    }

    if (!this.doIHaveAccess("actions")) {
      this.headers = this.headers.filter((x) => x.text !== "Actions");
    }

    this.fetchVendorLocations();
    this.fetchCountries();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchVendorLocations() {
      this.loading = true;
      return Store.getVendorLocations(this._id, false)
        .then((x) => {
          const data = x.data;
          this.locations = data.map((x, index) => {
            x.index = index + 1 + ".";
            return x;
          });
          this.allLocations = JSON.parse(JSON.stringify(this.locations));
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
        })
        .catch((e) => console.log(e));
    },

    fetchStatesByCountry(country = this.editedItem.country) {
      this.sfetching = true;
      return Store.getStatesByCountry(country, false)
        .then((x) => {
          this.states = x.data;
          this.sfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.sfetching = false;
        });
    },

    fetchCitiesByState(state = this.editedItem.state) {
      this.cfetching = true;
      return Store.getCitiesByState(state, false)
        .then((x) => {
          this.cities = x.data;
          this.cfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.cfetching = false;
        });
    },

    filterLocations() {
      this.locations = this.allLocations;
      if (this.country) {
        this.locations = this.allLocations.filter(
          (x) => x.country == this.country
        );
      }
      if (this.state) {
        this.locations = this.allLocations.filter((x) => x.state == this.state);
      }
      if (this.city) {
        this.locations = this.allLocations.filter((x) => x.city == this.city);
      }
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteVendorLocation(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchVendorLocations();
        })
        .catch(() => {
          this.confirmDialog = this.processing = false;
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    save(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();

      let request;
      const object = Object.assign({}, this.editedItem);
      if (object._id) {
        request = Store.editVendorLocation(object._id, object, false);
      } else {
        request = Store.createVendorLocation(this._id, object, false);
      }

      return request
        .then(() => {
          if (closeModal) this.close();
          this.processing = this.confirmDialogImage = this.editedItem.processing = false;
          this.fetchVendorLocations();
          Store.snackbarSuccess();
        })
        .catch(() => {
          Store.snackbarError();
          this.processing = this.editedItem.processing = false;
        });
    },

    getSequenceItems() {
      const arr = [1];
      for (let i = 0; i < this.locations.length; i++) arr.push(i + 2);
      return arr;
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      if (file && file["type"].split("/")[0] === "image") {
        this.editedItem.type = file["type"].split("/")[0];
        return true;
      }
      return false;
    },

    getURL(data) {
      if (data && data.split(".")[1] === "png") {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError() {
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.editedItem.image = "";
      this.editedItem.image = URL.createObjectURL(file);

      this.puploading = true;
      return FileUpload.upload(
        this._id,
        "vendor-locations",
        file,
        this.editedItem.type
      )
        .then((x) => {
          this.editedItem.image = x.data.file;
          this.puploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch(() => {
          this.puploading = false;
        });
    },

    deleteImage() {
      this.confirmDialogImage = true;
    },

    finalDeletePhoto() {
      this.editedItem.image = "";
      this.save(false, false);
    },

    closeConfirmPhoto() {
      this.confirmDialogImage = false;
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    store() {
      return this.$store.state.activeStore;
    },

    formTitle() {
      return this.editedItem._id ? "Edit location" : "Add new location";
    },
  },

  watch: {
    "editedItem.country"(n) {
      if (n) this.fetchStatesByCountry(n);
    },

    "editedItem.state"(n) {
      if (n) this.fetchCitiesByState(n);
    },

    country(n) {
      if (n) this.fetchStatesByCountry(n);
      this.filterLocations();
    },

    state(n) {
      if (n) this.fetchCitiesByState(n);
      this.filterLocations();
    },

    city() {
      this.filterLocations();
    },
  },

  data() {
    return {
      _id: "",

      search: "",
      loading: false,
      processing: false,
      locations: [],
      allLocations: [],

      cities: [],
      states: [],
      countries: [],

      city: "",
      state: "",
      country: "",

      sfetching: false,
      cfetching: false,
      puploading: false,

      dialog: false,
      defaultItem: {
        isActive: true,
        isPinned: false,
      },
      editedItem: {},

      beingDeleted: {},
      confirmDialog: false,

      confirmDialogImage: false,

      rowItems: config.rowItems,
      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        { text: "Description", value: "description" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        datesRule: [(v) => v.length === 2 || "Both dates are required"],
        discountRule: [(v) => v > 0 || "Enter valid discount"],
        mobileRule: [
          (v) => (!!v && v.length === 10) || "Must be a valid number",
        ],
        numberRule: [(v) => (!v && v != 0) || v >= 0 || "Must be valid number"],

        addressRule: [(v) => (!!v && !!v.description) || "It is required"],
        countryRule: [(v) => !!v || "Country is required"],
      },
    };
  },
};

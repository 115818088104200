import Vue from "vue";
import Vuex from "vuex";
import GlobalService from "./services/global";
import Pusher from "pusher-js";
import router from "./router";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: false,

  state: {
    authenticated: false,
    token: localStorage.getItem("token") || "",
    userInfo: {},
    uuid: "",
    isGD: ["dashboard.growcify.com", "localhost"].includes(
      window.location.hostname
    ),
    isMobile: window.innerWidth < 600,
    domainStore: false,

    showBanner: true,
    isLoggedOut: false,
    isLoggedIn: false,

    showSubscriptionModal: false,
    shipmentBalanceModal: false,
    pickupAddressModal: false,
    pickupAddressPickerModal: false,
    shipmentPackageModal: false,
    customerFormModal: false,
    produtQuickFormModal: false,
    shipmentSuccessModal: false,
    domainInstructionModal: false,
    thermalInvoiceModal: false,
    isKOT: false,
    massTranslateModal: false,
    bulkUpdateProductsModal: false,
    leadDetailModal: false,
    whatsappMessageModal: false,
    emailModal: false,
    callModal: false,
    meetingSchedulerModal: false,
    fullscreenMode: false,
    leadTaskHistoryModal: false,
    csvConfirmDialog: false,
    barcodesModal: false,
    posSubscriptionModal: false,
    posSubscriptionModalPersistent: false,
    posSubscriptionModalRenewal: false,
    paymentLinkModal: false,
    contractFormModal: false,
    refundConfirmationModal: false,
    bannerModel: false,
    groupModel: false,
    storyModel: false,
    currentStockModal: false,
    waesyapiModal: false,
    subscriptionModal: false,
    supplierModal: false,
    idInfoModal: false,
    dunzoModal: false,

    smsModal: false,
    noticeHidden: false,

    activeStore: {},
    planInfo: {},
    country: {
      name: "United States of America",
      countryCode: "USA",
      callingCode: "1",
      currencySymbol: "$",
      correncyCode: "USD",
    },
    cs: "",

    backgroundProcesses: [],

    newOrderNotification: false,
    socketEventData: {},

    notification: {
      duration: 3000,
      show: false,
      text: "",
    },

    snackbar: {
      timeout: 2500,
      show: false,
      text: "",
      color: "success",
      btnColor: "white",
      closeBtnText: "Close",

      submittingText: "Submitting...",
      submittedText: "Your changes have been saved.",
      errorText: "Your changes could not be saved.",
    },

    counts: {},
    growcifyCounts: {},

    commonEnums: undefined,
    pricingEnums: {},
    countries: [],

    logingOut: false,
    superloading: false,

    pusher: new Pusher("bab2a2451db32495c514", { cluster: "ap2" }),
    pusherBusinessEvents: [],

    mtiOrders: [],
    multipleThermalInvoiceModal: false,
  },

  mutations: {
    authSuccess(state, infoObject) {
      const token = infoObject.token;
      delete infoObject.token;

      state.authenticated = true;
      state.token = token;
      state.userInfo = infoObject;
      state.uuid = infoObject._id;

      //store token in the localStorage and headers
      localStorage.setItem("token", token);
      Vue.axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      if (
        state.isGD &&
        (state.userInfo.roles.includes("superadmin") ||
          state.userInfo.roles.includes("growcify-employee"))
      )
        router.push("/");
      else router.push("/businesses");
    },
    authError(state) {
      state.authenticated = false;
      state.token = state.uuid = "";
      state.userInfo = {};
    },
    verifySuccess(state, infoObject) {
      state.authenticated = true;
      state.userInfo = infoObject;
      state.uuid = infoObject._id;
    },

    modifyPhotos(state, photos) {
      state.userInfo.photos = photos;
    },

    modifyBasicInfo(state, info) {
      if (info.name) state.userInfo.name = info.name;
      if (info.email) state.userInfo.email = info.email;
      if (info.mobile) state.userInfo.mobile = info.mobile;
    },

    logout(state) {
      state.logingOut = true;
      return new Promise((resolve, reject) => {
        return Vue.axios
          .post("a/logout/" + state.uuid + "/web")
          .then(() => {
            state.token = "";
            state.authenticated = false;
            localStorage.removeItem("token");
            state.isLoggedOut = true;
            router.push("/signin");
            resolve();
            setTimeout(() => {
              state.isLoggedOut = false;
            }, 1000);

            //unsubscribe to channels; events
            if (state.activeStore._id)
              state.pusher.unsubscribe(state.activeStore._id);
            state.pusher.unsubscribe("growcify-team-dashboard");
            state.pusher.unsubscribe("growcify-new-lead");
            state.logingOut = false;
          })
          .catch((err) => reject(err));
      });
    },

    unset(state) {
      state.isLoggedOut = state.isLoggedIn = false;
    },

    loggedIn(state) {
      state.isLoggedIn = true;
      setTimeout(() => {
        state.isLoggedIn = false;
      }, 2500);
    },

    hideSubscriptionModal(state) {
      state.showSubscriptionModal = false;
    },

    showSubscriptionModal(state) {
      state.showSubscriptionModal = true;
    },
  },

  actions: {
    // eslint-disable-next-line
    sendOTP({ commit }, uobj) {
      return new Promise((resolve, reject) => {
        const user = Object.assign({}, uobj);
        let endpoint = "a/send-otp";
        if (user.storeId) {
          endpoint += "/" + user.storeId;
          delete user.storeId;
        }

        Vue.axios
          .post(endpoint, uobj)
          .then((resp) => resolve(resp))
          .catch((err) => reject(err));
      });
    },

    verifyOTP({ commit }, uobj) {
      return new Promise((resolve, reject) => {
        const user = Object.assign({}, uobj);
        let endpoint = "a/verify-otp";
        if (user.storeId) {
          endpoint += "/" + user.storeId;
          delete user.storeId;
        }

        Vue.axios
          .post(endpoint, user)
          .then((resp) => {
            commit("authSuccess", resp.data);
            commit("loggedIn");
            resolve(resp);
          })
          .catch((err) => {
            localStorage.removeItem("token");
            commit("authError");
            reject(err);
          });
      });
    },

    verify({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("a/v")
          .then((resp) => {
            commit("verifySuccess", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            localStorage.removeItem("token");
            commit("authError");
            reject(err);
          });
      });
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.authenticated,
    userInfo: (state) => state.userInfo,
    isTokenAvailable: () => localStorage.getItem("token"),
    getUserPhoto: (state) => {
      let data = state.userInfo.photos?.[0] || [];
      return GlobalService.getUserPhoto(data);
    },
    isMobile: () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
  },
});

export default store;

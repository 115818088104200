import Store from '../../services/store';
import router from '../../router'

export default {
  name: 'Stores',

  mounted() {
    if (!this.canProceed) return Store.redirectToHome();

    document.title = this.canRegister ? 'Growcify' : '';
    this.$store.state.activeStore = {};
    this.$store.state.cs = '';
    this.loading = true;
    return Store.getStores(this.$store.state.uuid, false)
      .then(x => {
        this.stores = x.data.stores;
        this.accessStores = x.data.accessStores;
        if (this.accessStores.length) {
          this.items.push('Businesses I Manage');
          if (!this.stores.length) this.tab = 'Businesses I Manage';
        }
        //auto-redirection is pending
        this.loading = false
      })
      .catch(e => {
        console.log(e);
        this.loading = false;
      });
  },

  methods: {
    isSuperadmin() {
      return this.$store.state.userInfo.roles.includes('superadmin');
    },

    amIGrowcifyEmployee() {
      return this.$store.state.userInfo.roles.includes('growcify-employee');
    },

    openDashboard(_id) {
      router.push({ name: 'store-dashboard', params: { _id: _id }});
    },

    newBusiness() {
      router.push({ name: 'new-business' });
    },

    manageRedirection() {
      if (this.stores.length === 1 && !this.accessStores.length) {
        const firstStore = this.stores[0];
        router.push({ name: 'store-dashboard', params: { _id: firstStore._id }});
      } else if (!this.stores.length && this.accessStores.length === 1) {
        const firstStore = this.accessStores[0];
        router.push({ name: 'store-dashboard', params: { _id: firstStore._id }});
      }
    }
  },

  computed: {
    canRegister() {
      return this.$store.state.isGD;
    },

    amIRegularUser() {
      return !this.isSuperadmin() || !this.amIGrowcifyEmployee() || !this.$store.state.isGD
    },

    userInfo() { return this.$store.getters.userInfo },

    canProceed() {
      return (Store.amISuperadmin(this.$store) || Store.amIGrowcifyEmployee(this.$store)) && this.$store.state.isGD;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  data() {
    return {
      stores: [],
      accessStores: [],
      loading: false,

      tab: null,
      items: [
        'My Businesses'
      ]
    }
  }
}
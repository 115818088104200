<template>
  <v-dialog v-model="confirmDialogPhoto" max-width="400" persistent>
    <v-progress-linear v-if="processing" indeterminate color="primary" class="mb-0"></v-progress-linear>
    <v-card>
      <v-card-title>
        <span>Are you sure?</span>
      </v-card-title>

      <v-card-text>
        Are you sure you want to delete this photo?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="error darken-1" :disabled="processing" text @click="$parent.closeConfirmPhoto">No</v-btn>
        <v-btn color="green darken-1" :disabled="processing" text @click="$parent.finalDeletePhoto">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  export default {
    props: [
      'confirmDialogPhoto',
      'processing'
    ]
  }
</script>
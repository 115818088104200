<template>
  <section class="home" >
    <v-row>
      <v-col class="vc">
        <section class="p-4">
          <v-row>
            <v-col>
              <h6 style="margin-bottom: 3px; font-size: 1.1rem;"><b>{{ userInfo.name || 'Hi, there!' }}</b></h6>
              <span>+{{ userInfo.callingCode || '91' }} {{ userInfo.mobile }}</span>
            </v-col>
            <v-col cols="2" class="vc text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" :disabled="$store.state.logingOut" @click="logout" color="primary">mdi-logout</v-icon>
                </template>
                <span>Sign out</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <section v-if="!loading">
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col>
                <h5 style="margin-bottom: 3px; font-size: 1.20rem;"><b>Businesses</b></h5>
                <small v-if="hasStores">Pick a business to continue</small>
                <small v-else-if="isGD">Register a new business</small>
                <small v-else>No access. Please contact the admin.</small>
              </v-col>

              <v-col v-if="isGD" cols="12" sm="7" class="vc text-right">
                <v-btn :disabled="$store.state.logingOut" small block outlined large color="primary" @click="newBusiness">
                  Register new business
                </v-btn>
              </v-col>
              <v-col cols="12" class="pb-0" v-if="hasStores"></v-col>
            </v-row>

            <div v-if="hasStores">
              <div style="height: 10px"></div>
              <section v-for="s in stores" :key="s._id">
                <v-card link outlined class="pt-2 pb-2 pl-3 pr-3 mb-5" style="border-radius: 10px;">
                  <v-row @click="openDashboard(s._id)">
                    <v-col>
                      <h6 style="margin-bottom: 3px;">
                        <b>
                          <TextWrapper :text="s.name" :length="50" :copyDisabled="true"></TextWrapper>
                        </b>
                      </h6>
                      <small>{{ s.address }}</small>
                    </v-col>
                    <v-col cols="2" class="vc text-right">
                      <v-icon color="primary">mdi-chevron-double-right</v-icon>
                    </v-col>
                  </v-row>
                </v-card>
              </section>

              <section v-for="s in accessStores" :key="s._id">
                <v-card link ripple outlined class="pt-2 pb-2 pl-3 pr-3 mb-5" style="border-radius: 10px;">
                  <v-row @click="openDashboard(s._id)">
                    <v-col>
                      <h6 style="margin-bottom: 3px; color: black;">
                        <b>
                          <TextWrapper :text="s.name" :length="50" :copyDisabled="true"></TextWrapper>
                        </b>
                      </h6>
                      <small> {{ s.address }}</small>
                    </v-col>
                    <v-col cols="2" class="vc text-right">
                      <v-icon color="primary">mdi-chevron-double-right</v-icon>
                    </v-col>
                  </v-row>
                </v-card>
              </section>
            </div>
          </section>
        </section>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import router from '../../router';

export default {
  name: 'StoreList',

  created() { },

  props: [
    'stores',
    'loading',
    'accessStores'
  ],

  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },

    isGD() {
      return this.$store.state.isGD;
    },

    hasStores() {
      return this.stores.length || this.accessStores.length;
    }
  },

  methods: {
    newBusiness() {
      router.push({ name: 'new-business' });
    },

    openDashboard(_id) {
      router.push({ name: 'store-dashboard', params: { _id: _id }});
    },

    logout() {
      this.$store.commit('logout');
    }
  },

  data() {
    return {
    }
  }
}
</script>

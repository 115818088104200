<template>
  <section>
    <v-chip small v-if="mode == 'Prepaid'" color="primary">
      {{ capitalize }}
    </v-chip>
    <v-chip small v-else-if="mode === 'Postpaid'" color="warning">
      {{ capitalize }}
    </v-chip>
    <v-chip small v-else-if="mode === 'Prepayment'" color="success">
      {{ capitalize }}
    </v-chip>
    <v-chip small v-else>{{ capitalize }}</v-chip>
  </section>
</template>

<script>
export default {
  name: "SubscriptionPaymentMode",

  props: ["mode"],

  computed: {
    capitalize() {
      return this.mode.charAt(0).toUpperCase() + this.mode.slice(1);
    },
  },
};
</script>

import moment from "moment";

import config from "../../config";
import Store from "../../services/store";
import Order from "../../services/order";

export default {
  name: "Dunzo",

  props: ["_id", "editedItem"],

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    store() {
      return this.$store.state.activeStore;
    },

    user() {
      return this.$store.state.userInfo;
    },

    country() {
      return this.$store.state.activeStore.country;
    },

    drop() {
      return this.editedItem.address || {};
    },

    collectableAmount() {
      if (this.editedItem.paymentMethod != "COD") return 0;
      else if (!this.editedItem.outstandingAmount) {
        return this.editedItem.amount - this.editedItem.amountFromWallet;
      }

      return this.editedItem.amount;
    },

    pickupTime() {
      return moment()
        .add(this.quote.eta.pickup, "minutes")
        .format("h:mm a");
    },

    dropTime() {
      return moment()
        .add(this.quote.eta.dropoff, "minutes")
        .format("h:mm a");
    },
  },

  methods: {
    getCallingCode(item) {
      return item.callingCode || this.country.callingCode || "91";
    },

    getMobile(item) {
      return "+" + this.getCallingCode(item) + " " + item.mobile;
    },

    getQuote() {
      this.processing = true;
      Store.snackbarStart();

      const object = {
        pickup: {
          lat: this.store.lat,
          long: this.store.long,
          _id: this.store._id,
        },
        drop: {
          _id: this.drop._id, // address._id
          lat: this.drop.lat,
          long: this.drop.long,
        },
      };

      return Order.getDunzoQuote(this._id, this.editedItem._id, object)
        .then((res) => {
          const data = res.data;
          if (data && !data.success) {
            this.$store.state.snackbar.errorText = data.message;
            Store.snackbarError();

            if (data.dunzoTaskID) {
              this.shipmentInfo = {
                shipmentID: data.shipmentID,
                dunzoTaskID: data.dunzoTaskID,
              };
              setTimeout(() => (this.cancelDialog = true), 1000);
            }
          } else {
            const info = data.details;
            if (info && info.dunzoOID) {
              this.step = 2;
              this.quote = info;
              Store.snackbarSuccess();
            } else {
              Store.snackbarError();
            }
          }
          this.processing = false;
        })
        .catch(() => {
          Store.snackbarError();
          this.processing = false;
        });
    },

    createDunzoPickup() {
      this.processing = true;
      Store.snackbarStart();

      const object = {
        pickup: {
          _id: this.store._id,
          lat: this.store.lat,
          long: this.store.long,
          address: this.store.address,
          name: this.user.name,
          callingCode: this.user.callingCode,
          mobile: this.user.mobile,
        },
        drop: this.drop,
        paymentMethod: this.editedItem.paymentMethod,
        amount: this.collectableAmount,
        oid: this.editedItem.oid,
      };

      return Order.createDunzoPickup(this._id, this.editedItem._id, object)
        .then((res) => {
          const data = res.data;
          if (data && !data.success) {
            this.$store.state.snackbar.errorText = data.message;
            Store.snackbarError();
          } else {
            const info = data.details;
            if (info && info.dunzoOID) {
              this.step = 3;
              this.dunzoOrder = info;
              this.shipmentInfo = {
                shipmentID: info.shipmentID,
                dunzoTaskID: info.dunzoTaskID,
              };
              Store.snackbarSuccess();
            } else {
              Store.snackbarError();
            }
          }
          this.processing = false;
        })
        .catch(() => {
          Store.snackbarError();
          this.processing = false;
        });
    },

    finalCancel() {
      this.processing = true;
      Store.snackbarStart();

      return Order.cancelDunzoPickup(
        this._id,
        this.editedItem._id,
        this.shipmentInfo
      )
        .then((res) => {
          const data = res.data;
          if (data && !data.success) {
            this.$store.state.snackbar.errorText = data.message;
            Store.snackbarError();
          } else {
            this.$store.state.snackbar.submittedText =
              "Your pickup has been cancelled!";

            Store.snackbarSuccess();
            this.step = 1;
            this.cancelDialog = false;
            this.$store.state.dunzoModal = false;
          }
          this.processing = false;
        })
        .catch(() => {
          Store.snackbarError();
          this.processing = false;
          this.cancelDialog = false;
          this.step = 1;
        });
    },
  },

  data() {
    return {
      processing: false,

      step: 1,
      quote: {
        eta: {},
      },
      dunzoOrder: {},

      shipmentInfo: {},

      cancelDialog: false,

      checkmarkAnimationJson: config.checkmarkAnimationJson,
    };
  },
};

<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <img :src="img" height="200px">

        <div class="mt-8"></div>
        <h4><b class="text-primary">{{ hostname }}</b> is currently unavailable.</h4>
        <div class="mt-8"></div>

        <div class="mt-10">
          <span class="mb-1"><b>If you're the owner of this website,</b></span>
          <div class="mt-2">please <a href="https://dashboard.growcify.com/signin" target="_blank">signin</a> to resolve the issue, or <a href="https://growcify.com" target="_blank">contact support</a></div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'StoreInactive',
  
  data() {
    return {
      href: window.location.href,
      hostname: window.location.hostname,
      img: require("@/assets/imgs/not-found.gif")
    }
  }
}
</script>
import Store from '../../services/store';
import Util from '../../services/util';

const defaultSubject = 'Growcify: Your own end-to-end integrated eCommerce app';
const defaultMessage = 'Hello {{name}}, \r\n\nHope you are doing well.\r\n\nIt was nice talking to you. We would be happy to onboard you and help you set up your business with Growcify.\r\n\nPlease find details about our company, the sign-up process, and the pricing. We also suggest you kindly visit our website (https://growcify.com) or give us a call if you need further assistance on the number below for more details.\r\n\n- Steps to get started: https://youtu.be/8k1EuKUCz_M\r\n- Product Demo Video: https://youtu.be/n8RcM1eUNP8  \r\n- Demo Android App: https://play.google.com/store/apps/details?id=co.grocify\r\n- Demo iOS App: https://apps.apple.com/app/id1534266201\r\n- Dashboard (Admin): https://dashboard.growcify.com\r\n- Website: https://growcify.com\r\n\nAnd for pricing and features, please download Growcify Brochure: https://bit.ly/growcify-brochure\r\n\nLet us know if you have any questions.\r\n\r\nRegards,\r\n​\nThe​ ​Growcify​ ​Team​\r\nWebsite: https://growcify.com\r\nEmail: team@growcify.com\r\nCall/WhatsApp: +918005771838';

export default {
  name: 'Email',

  props: [
    'to',
    'type',
    'editedItem'
  ],

  methods: {
    send() {
      if (!this.$refs.form.validate()) return;

      this.processing = true;
      Store.snackbarStart();
      if (this.type === 'monthly-update') this.emailItem.monthlyUpdate = true;
      return Util.sendEmail(this.emailItem, false)
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    close() {
      this.$store.state.emailModal = false;
    },

    remove(item) {
      this.emailItem.cc.splice(this.emailItem.cc.indexOf(item), 1)
      this.emailItem.cc = [...this.emailItem.cc]
    },

    removeReplyTo(item) {
      this.emailItem.replyTo.splice(this.emailItem.replyTo.indexOf(item), 1)
      this.emailItem.replyTo = [...this.emailItem.replyTo]
    }
  },

  computed: {
    formTitle() {
      return 'Send Email';
    }
  },

  watch: {
    '$store.state.emailModal'(n) {
      if (n) {
        if (this.to) this.emailItem.to = this.to;
        if (this.type == 'lead') {
          this.emailItem.subject = defaultSubject;
          this.emailItem.message = defaultMessage;
          this.emailItem.replyTo = ['team@growcify.com'];
          if (this.editedItem.name) this.emailItem.message = this.emailItem.message.replace('{{name}}', this.editedItem.name);
        }
      }
    }
  },

  data() {

    return {
      valid: false, 
      processing: false,

      error: false,
      success: false,
      emailItem: {
        cc: [],
        replyTo: []
      },

      validationRules: {
        basicRule: [
          v => !!v || 'It\'s required'
        ]
      }
    }
  }
}
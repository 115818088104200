<template>
  <v-dialog v-model="$store.state.currentStockModal" max-width="425px">
    <v-progress-linear v-if="processing" indeterminate color="primary" class="mb-0"></v-progress-linear>
    <v-card class="p-4" >
      <v-row class="mb-2">
        <v-col cols="8" class="vc">
          <h5 class="m-0">Current Stock</h5>
          <small>Current availablity of products</small>
        </v-col>

        <v-col class="vc text-right">
          <v-btn :disabled="processing" outlined @click="printStock">
            <v-icon left>mdi-printer</v-icon> Print
          </v-btn>
        </v-col>
      </v-row>

      <v-row id="invoice">
        <v-col class="text-center" cols="12">
          <div class="text-center mt--10">
            <h6 style="margin-bottom: 12px"><b>{{ store.name || store.appName }}</b></h6>
            <h6 style="margin-bottom: 12px; margin-top: 0px">Current Stock Availability</h6>
            <p style="margin-bottom: 12px">{{ formatDate() }}</p>
          </div>
        </v-col>

        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th :class="['text-left pl-0 items', tc]">#</th>
                  <th :class="['text-left pl-0 items', tc]">Name</th>
                  <!-- <th :class="['text-left pl-0 items', tc]">SKU</th> -->
                  <th :class="['text-left pl-0 items', tc]">MRP</th>
                  <th :class="['text-left pl-0 items', tc]">Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td class="pl-0 items">{{ item.index }}</td>
                  <td class="pl-0 items" style="max-width: 75px">{{ item.name }} ({{item.size}}{{item.unit}})</td>
                  <!-- <td class="pl-0 items">{{ item.sku || '-' }}</td> -->
                  <td class="pl-0 items">
                    <span v-if="item.marketPrice">{{ item.marketPrice | fc }}</span>
                    <span v-else>-</span>
                  </td>
                  <td class="pl-0 items text-left">{{ item.quantity }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="vc text-right">
          <v-btn class="ml-3" color="error" outlined @click="$store.state.currentStockModal = false">
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { Printd } from 'printd';
import moment from 'moment';

export default {

  props: [
    'items'
  ],
  
  methods: {
    printStock() {
      const cssText = `
        .spacercstm {
          margin-top: 15px;
          border-bottom: 1.5px dashed;
          margin-bottom: 15px;
          width: 100%;
        }
        .invoicelogo-img {
          -webkit-filter: grayscale(100%); 
          filter: grayscale(100%);
        }
        .p0 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        .pr-0 {
          padding-right: 0 !important;
        }
        .mt--10 {
          margin-top: -25px;
        }
        #invoice {
          width: 3in;
          background: white;
          font-size: 10px;
          padding-left: 3px;
          padding-right: 3px;
          font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        }
        h6, .h6 {
          font-size: 1rem;
        }
        .items {
          font-size: 10px
        }
        .text-center {
          text-align: center !important;
        }
        .text-right {
          text-align: right !important;
        }
        .container {
          width: 100%;
        }
        .row {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
        }
        .col {
          flex-basis: 0;
          flex-grow: 1;
          min-width: 0;
          max-width: 100%;
        }
        .p0 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        .pt-2 {
          padding-top: 8px !important;
        }
        .pt-1 {
          padding-top: 4px !important;
        }
        .col-1 {
          flex: 0 0 8.333333%;
          max-width: 8.333333%;
        }
        .col-2 {
          flex: 0 0 16.666667%;
          max-width: 16.666667%;
        }
        .col-5 {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
        .col-12 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .col-6 {
          flex: 0 0 50%;
          max-width: 50%;
        }
        .pr-0 {
          padding-right: 0px !important;
        }
        .v-data-table {
          max-width: 100%;
          border-radius: 4px;
        }
        .v-data-table__wrapper {
          overflow-x: auto;
          overflow-y: hidden;
        }
        .v-data-table table {
          width: 100%;
          border-spacing: 0;
        }
        table {
          border-collapse: collapse;
        }
        .v-data-table thead tr:last-child th {
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
        }
        .text-left {
          text-align: left !important;
        }
        .text-right {
          text-align: right !important;
        }
        .v-data-table td, .v-data-table th {
          padding: 0 10px;
        }
        .v-data-table th {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          height: 25px;
        }
        .text-black {
          color: black !important;
        }
        .v-data-table td {
          height: 25px;
          padding: 0 10px;
        }
        .pl-0 {
          padding-left: 0px !important;
        }
        .pr-0 {
          padding-right: 0px !important;
        }
        .pr-5px {
          padding-right: 3px !important;
        }
        @media print {
          body {
            display: table;
            table-layout: fixed;
            margin-top: 0px;
            margin-left: 0px;
            margin-right: 0px;
            height: auto;
          }
        }
      `
      const d = new Printd()
      d.print( document.getElementById('invoice'), [ cssText ] )
    },

    formatDate() {
      return moment().format('lll');
    }
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? 'text-white' : 'text-black';
    },

    store() {
      return this.$store.state.activeStore;
    }
  },

  watch: {
    '$store.state.currentStockModal'(n) {
      if (n) {
        console.log(this.items);
      }
    }
  },

  data() {
    return {
      processing: false,

      headers: [
        { text: '#', align: 'start', value: 'index', sortable: false },
        { text: 'Name', value: 'name' },
        { text: 'Category', value: 'category' },
        { text: 'Quantity', value: 'qty' },
      ]
    }
  }
  
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"col-8 nobp bv"},[_c('h5',{class:_vm.isDT ? '' : 'text-primary'},[_vm._v("Notifications")])]),(_vm.canEdit)?_c('b-col',{staticClass:"col-4 nobp vc text-right"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.editItem(_vm.defaultItem)}}},[_vm._v("Create new notification")])],1):_vm._e()],1),_c('div',{staticStyle:{"height":"15px"}}),_c('Notification',{attrs:{"_id":_vm._id,"dialog":_vm.notificationModal,"users":[],"edited-item":_vm.editedItem},on:{"update:dialog":function($event){_vm.notificationModal=$event}}}),_c('b-row',[_c('b-col',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"clearable":"","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-col',{staticClass:"col-12"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.notifications,"footer-props":{ 'items-per-page-options': _vm.rowItems },"search":_vm.search,"loading":_vm.loading || _vm.processing,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"item.notify",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.notify ? "Yes" : "No")+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('TextWrapper',{attrs:{"text":item.title,"length":35}})]}},{key:"item.toAll",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.toAll ? "Yes" : "No")+" ")]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('TextWrapper',{attrs:{"text":item.message,"length":35}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.doIHaveAccess('edit'))?_c('v-icon',{attrs:{"small":"","title":"Resend"},on:{"click":function($event){return _vm.processItem(item)}}},[_vm._v(" mdi-remote ")]):_vm._e(),(_vm.doIHaveAccess('edit'))?_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.doIHaveAccess('delete'))?_c('v-icon',{staticClass:"ml-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)],1)],1),_c('ConfirmDialog',{attrs:{"confirm-dialog":_vm.confirmDialog,"being-deleted":_vm.beingDeleted,"processing":_vm.processing}}),_c('v-dialog',{attrs:{"max-width":"350","persistent":""},model:{value:(_vm.confirmResendDialog),callback:function ($$v) {_vm.confirmResendDialog=$$v},expression:"confirmResendDialog"}},[(_vm.processing)?_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Are you sure?")])]),_c('v-card-text',[_vm._v(" Are you sure you want to resend this ("),_c('b',[_vm._v(_vm._s(_vm.resendItem.title))]),_vm._v(") notification? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","disabled":_vm.processing,"text":""},on:{"click":function($event){_vm.confirmResendDialog = false}}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"green darken-1","disabled":_vm.processing,"text":""},on:{"click":_vm.resendNotification}},[_vm._v("Yes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import moment from "moment";
import _ from "underscore";

import User from "../../../services/user";
import Store from "../../../services/store";
import Inventory from "../../../services/inventory";
import Subscription from "../../../services/subscription";

import config from "../../../config";
import router from "../../../router";

import Link from "../../../components/general/link.vue";
import SubscriptionStatus from "../../../components/general/subscription-status.vue";
import SubscriptionPaymentMode from "../../../components/general/subscription-payment-mode.vue";
import SubscriptionForm from "../../../components/subscription-form/subscription-form.vue";
import { generateSchedule } from "../../../helpers/util";

const todayDate = moment().format("YYYY-MM-DD");
const yesterdayDate = moment()
  .subtract(1, "d")
  .format("YYYY-MM-DD");
const tomorrowDate = moment()
  .add(1, "d")
  .format("YYYY-MM-DD");

export default {
  created() {
    this._id = this.$route.params._id;
    if (
      !this.amIAllowed("Subscriptions") ||
      !this.store.subscriptionEnabled ||
      this.store.isVendor
    ) {
      return router.push("/business/" + this._id);
    }

    //set default columns
    const savedColumns = localStorage["subscriptionsV2Columns"];
    if (savedColumns) this.headers = JSON.parse(savedColumns);

    // re-check access for actions column
    this.headers = this.headers.filter((x) => x.text !== "Actions");
    if (this.doIHaveAccess("edit")) {
      const actionsItem = {
        text: "Actions",
        align: "right",
        value: "actions",
        sortable: false,
        disabled: true,
      };
      this.headers.push(actionsItem);
      this.defaultHeaders.push(actionsItem);
      this.availableHeaders.push(actionsItem);
    }

    this.fetchSubscribableProducts();
    this.fetchSubscriptionsCounts();
    this.fetchSubscriptions();
    this.fetchCommonEnums();
    this.fetchCustomers();
    this.defaultItem.currencyCode = this.country.currencyCode;
  },

  components: {
    Link,
    SubscriptionForm,
    SubscriptionStatus,
    SubscriptionPaymentMode,
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    getTabID(index) {
      return this.tabs[index]._id;
    },

    resetResults() {
      this.fetchSubscriptions(1, this.options.itemsPerPage, this.search);
    },

    initPusher() {
      const channelName = this._id;
      const eventName = "backgroundProcessCompleted";

      const pusher = this.$store.state.pusher;
      let channel = pusher.channel(channelName);
      if (!channel) channel = pusher.subscribe(channelName);

      const events = this.$store.state.pusherBusinessEvents;
      if (events.includes("dashboard:" + eventName)) return;
      events.push("dashboard:" + eventName);
      this.$store.state.pusherBusinessEvents = events;

      channel.bind(eventName, () => {
        this.$store.state.backgroundProcesses.pop();
        this.notifyOrderGeneration();
      });
    },

    notifyOrderGeneration() {
      this.$store.state.snackbar.text =
        "All eligible subscriptions have been processed successfully!";
      this.$store.state.snackbar.timeout = 5000;
      this.$store.state.snackbar.color = "success";
      this.$store.state.snackbar.show = true;
    },

    fetchSubscriptionsCounts() {
      return Subscription.getCounts(this._id, false)
        .then((response) => {
          const data = response.data;
          if (data) this.counts = data;
        })
        .catch((e) => console.log(e));
    },

    fetchSubscribableProducts() {
      return Inventory.getSubscribableProducts(this._id, false)
        .then((response) => {
          const items = response.data;
          if (items && items.length) {
            this.filterBy.push({ header: "Subscribable Products" });
            items.forEach((item) => {
              this.filterBy.push({
                value: "product:" + item._id,
                text: item.name + " " + item.size + item.unit,
              });
            });
          }
        })
        .catch((e) => console.log(e));
    },

    fetchSubscriptions(
      pageNumber = this.subscriptionsObject.pageNumber,
      itemsPerPage = this.subscriptionsObject.itemsPerPage,
      keyword = ""
    ) {
      this.loading = true;
      const tabID = this.getTabID(this.tab);

      const filters = this.filters;
      return Subscription.getSubscriptionsV2(
        this._id,
        tabID,
        pageNumber,
        itemsPerPage,
        keyword,
        filters,
        false
      )
        .then((response) => {
          const data = response.data;
          this.subscriptionsObject = data;
          this.subscriptions = this.formatItems(data.items);

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) {
        this.cenums = ce;
        this.processCEnums();
        return;
      }

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
          this.processCEnums();
        })
        .catch((e) => console.log(e));
    },

    fetchCustomers() {
      this.fcustomers = true;
      return User.getSelectableUsers(this._id, false)
        .then((res) => {
          this.customers = res.data;
          this.fcustomers = false;
        })
        .catch((e) => {
          console.log(e);
          this.fcustomers = false;
        });
    },

    formatItems(array) {
      return array.map((item, index) => {
        item.index = index + 1 + ".";
        item.name = item.user.name || this.getMobile(item.user);
        item.mobile = this.getMobile(item.user);
        item.paymentMode = "Prepaid";

        if (item.isPrepayment) item.paymentMode = "Prepayment";
        else if (item.isPostpaid) item.paymentMode = "Postpaid";

        this.processEndingIn(item);
        return item;
      });
    },

    processEndingIn(item) {
      const today = moment().startOf("day");
      const en = moment(item.endDate)
        .startOf("day")
        .diff(today, "days");
      if (en <= 5) {
        if (!en) item.enText = "Ending Today";
        else if (en === 1) item.enText = "Ending Tomorrow";
        else if (en === -1) item.enText = "Ended Yesterday";
        else if (en > 0 && en <= 5) {
          item.enText = "Ending in " + en + " day" + (en !== 1 ? "s" : "");
        } else if (en < 0) {
          item.enText = "Ended on " + moment(item.endDate).format("ll");
        }
      }
    },

    processCEnums() {
      this.cenums.filteredDeliveryModes = this.cenums.deliveryModes.filter(
        (x) => this.store.deliveryModes.includes(x._id)
      );
      this.cenums.formattedSubscriptionStatuses = this.cenums.subscriptionStatuses.map(
        (x) => {
          if (typeof x == "object") return x;
          return {
            key: x,
            value: this.$options.filters.firstLetterUppercase(x),
          };
        }
      );
    },

    customerText(item) {
      let text = "";
      if (item.name) text += item.name;
      const mobile = this.getMobile(item);

      if (text) text += " (" + mobile + ")";
      else text = mobile;

      return text;
    },

    getCallingCode(item) {
      return item.callingCode || this.country.callingCode || "91";
    },

    getMobile(item) {
      return "+" + this.getCallingCode(item) + " " + item.mobile;
    },

    formatDate(input, format = "ll") {
      return moment(input).format(format);
    },

    amountFormatted(obj) {
      let amount = obj.amount;
      if (obj.currencyCode === "INR") {
        amount = this.currencyPipe(obj.amount, "₹");
      } else if (obj.currencyCode == this.country.currencyCode) {
        amount = this.currencyPipe(obj.amount, this.country.currencySymbol);
      } else if (obj.currencyCode && obj.currencyCode !== "INR") {
        amount = this.currencyPipe(obj.amount, obj.currencyCode);
      } else if (!this.country.currencyCode && !obj.currencyCode) {
        amount = this.currencyPipe(obj.amount, "₹");
      } else {
        amount = this.currencyPipe(obj.amount, this.country.currencySymbol);
      }

      return amount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    saveColumns() {
      localStorage["subscriptionsV2Columns"] = JSON.stringify(this.headers);
      this.saveColumnsText = "Saved";
      setTimeout(() => {
        this.saveColumnsText = "Save";
        this.toggleColumnSelect = !this.toggleColumnSelect;
      }, 500);
    },

    resetColumns() {
      this.headers = this.defaultHeaders;
    },

    columnsChanged() {
      const nh = [];
      this.availableHeaders.forEach((x) => {
        if (
          this.headers.find((i) => i.value == x.value) &&
          !nh.find((j) => j.value == x.value)
        )
          nh.push(x);
      });
      this.headers = nh;
    },

    sendSubReminders() {
      this.sendingReminders = true;
      this.ordersDialog = true;
    },

    generateSubOrders() {
      this.ordersDialog = true;
      this.sendingReminders = false;
    },

    sendReminders() {
      if (!this.$refs.form3.validate()) return;

      Store.snackbarStart();
      this.processing = true;

      const object = Object.assign({}, this.ordersObject);
      return Subscription.sendReminders(this._id, object, false)
        .then(() => {
          Store.snackbarSuccess();
          this.processing = this.sendingReminders = this.ordersDialog = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    generateOrders() {
      if (!this.$refs.form3.validate()) return;

      Store.snackbarStart();
      this.processing = true;

      const object = Object.assign({}, this.ordersObject);
      return Subscription.generateOrders(this._id, object, false)
        .then((res) => {
          Store.snackbarSuccess();
          this.processing = this.ordersDialog = false;
          if (res.data && res.data.subscriptions) this.initPusher();
          this.$store.state.backgroundProcesses.push("subscription-orders");
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    processMassUpdate() {
      if (!this.$refs.form5.validate()) return;

      Store.snackbarStart();
      this.processing = true;

      const subscriptionsIds = this.selectedSubscriptions.map((x) => x._id);
      const object = {
        updates: this.massItem,
        subscriptionsIds: subscriptionsIds,
      };

      return Subscription.massUpdate(this._id, object, false)
        .then(() => {
          this.processing = this.massDialog = false;
          this.massItem = {};
          this.selectedSubscriptions = [];
          this.fetchSubscriptions();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    processRenewals() {
      if (!this.$refs.form4.validate()) return;

      Store.snackbarStart();
      this.processing = true;

      const subscriptions = this.selectedSubscriptions.map((x) => {
        const newObject = {
          _id: x._id,
          cycle: x.cycle,
          period: x.period,
          schedule: this.processSchedule(x.cycle),
          startDate: this.renewObject.startDate,
          endDate: this.renewObject.endDate,
        };

        return newObject;
      });

      const object = { subscriptions: subscriptions };
      return Subscription.renewSubscriptions(this._id, object, false)
        .then(() => {
          this.processing = this.renewDialog = false;
          this.renewObject = {};
          this.selectedSubscriptions = [];
          this.fetchSubscriptions();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    removeSelected(item) {
      this.selectedSubscriptions = this.selectedSubscriptions.filter(
        (x) => x._id != item._id
      );
    },

    massDeleteInit() {
      this.confirmMassDelete = true;
    },

    massDeleteProcess() {
      const subscriptionsIds = this.selectedSubscriptions.map((x) => x._id);
      const object = {
        updates: { deleted: true },
        subscriptionsIds: subscriptionsIds,
      };

      Store.snackbarStart();
      this.processing = true;
      return Subscription.massUpdate(this._id, object, true)
        .then(() => {
          this.selectedSubscriptions = [];
          this.processing = this.confirmMassDelete = false;
          this.fetchSubscriptions();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    downloadInvoices() {
      const _ids = this.selectedSubscriptions.map((x) => x._id).join(",");
      this.selectedSubscriptions = [];
      return Subscription.downloadInvoices(
        this._id,
        _ids,
        this.$store.state.token
      );
    },

    processSchedule(cycle) {
      const endDate = this.renewObject.endDate;
      const startDate = this.renewObject.startDate;
      if (!startDate || !endDate) return [];

      const schedule = generateSchedule(startDate, endDate, cycle);
      return schedule;
    },

    getDeliveryTotal(item) {
      if (item.perDeliveryTotal) return item.perDeliveryTotal;

      const items = item.items;
      const dc = item.deliveryCharge;

      if (items && items.length) {
        const total = items.reduce((a, b) => (a += b.price * b.qty), 0);
        return total + dc;
      }

      if (item.price) return item.price * item.qty + (dc || 0);
      return 0;
    },

    downloadInvoice(id) {
      return Subscription.generateInvoice(id, this.$store.state.token);
    },

    editItem(item) {
      this.editedItem = item;
      this.editedItem = Object.assign({}, item);
      this.$store.state.subscriptionModal = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Subscription.deleteSubscription(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.resetResults();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },
  },

  watch: {
    tab() {
      this.subscriptions = [];
      this.selectedSubscriptions = [];
      this.fetchSubscriptions(
        1,
        this.subscriptionsObject.itemsPerPage,
        this.search
      );
    },

    options: {
      handler(options) {
        if (!this.loading)
          this.fetchSubscriptions(
            options.page,
            options.itemsPerPage,
            this.search
          );
      },
      deep: true,
    },

    search: _.debounce(function() {
      this.resetResults();
    }, 500),

    "filters.user"() {
      this.resetResults();
    },

    "filters.status"() {
      this.resetResults();
    },

    filterBySelected(n) {
      delete this.filters.endDate;
      delete this.filters.startDate;
      delete this.filters.product;

      if (n) {
        const data = n.split(":")[1];
        if (n.includes("endDate")) this.filters.endDate = data;
        if (n.includes("startDate")) this.filters.startDate = data;
        if (n.includes("product")) this.filters.product = data;
      }

      this.resetResults();
    },

    itemUpdated(n) {
      if (n) {
        this.resetResults();
        this.fetchSubscriptionsCounts();
      }
      this.itemUpdated = false;
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    hasSubscriptions() {
      return this.subscriptionsObject.totalItems;
    },

    store() {
      return this.$store.state.activeStore;
    },

    country() {
      return this.$store.state.country;
    },

    searchDisabled() {
      if (this.search) {
        if (this.search.includes("status:")) return true;
        if (this.search.includes("user:")) return true;
      }
      return false;
    },

    computedStartDate2() {
      return this.renewObject.startDate
        ? moment(this.renewObject.startDate).format("dddd, ll")
        : "";
    },

    computedEndDate2() {
      return this.renewObject.endDate
        ? moment(this.renewObject.endDate).format("dddd, ll")
        : "";
    },
  },

  data: () => {
    return {
      _id: "",
      subscriptions: [],
      allSubscriptions: [],
      rowItems: config.rowItems,

      options: {},
      subscriptionsObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      counts: {},
      cenums: {},
      contractItems: [],
      customers: [],

      fcustomers: false,
      cloading: false,
      success: false,
      valid: false,
      dialog: false,
      editedItem: {},
      editedIndex: -1,
      defaultItem: {
        items: [],
        schedule: [],
        status: "new",
        cycle: "daily",
        period: "morning",
        deliveryCharge: 0,
        isPostpaid: false,
        deliveryMode: "home-delivery",
      },
      itemUpdated: false,

      tab: 0,
      tabs: [
        { _id: "all", title: "All" },
        { _id: "new", title: "New" },
        { _id: "active", title: "Active" },
        { _id: "paused", title: "Paused" },
        { _id: "completed", title: "Completed" },
        { _id: "cancelled", title: "Cancelled" },
        { _id: "testing", title: "Testing" },
      ],

      filters: {},
      filterBySelected: "",
      filterBy: [
        { header: "Ending" },
        { value: "endDate:" + todayDate, text: "Ending Today" },
        { value: "endDate:" + tomorrowDate, text: "Ending Tomorrow" },
        { value: "endDate:" + yesterdayDate, text: "Ended Yesterday" },

        { header: "Starting" },
        { value: "startDate:" + todayDate, text: "Starting Today" },
        { value: "startDate:" + tomorrowDate, text: "Starting Tomorrow" },
        { value: "startDate:" + yesterdayDate, text: "Started Yesterday" },
      ],

      menu: false,
      menu1: false,
      menu3: false,

      date: "",
      todayDate: todayDate,

      sendingReminders: false,
      ordersDialog: false,
      ordersObject: {
        period: "morning",
      },

      renewObject: {},
      showRNSubs: false,
      renewDialog: false,
      selectedSubscriptions: [],

      massItem: {},
      massDialog: false,

      confirmMassDelete: false,
      confirmDialog: false,
      beingDeleted: {},

      search: "",
      loading: true,
      processing: false,
      headers: [
        { text: "#", value: "index", disabled: true },
        { text: "SID", value: "sid", sortable: false, disabled: true },
        { text: "Name", value: "name", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        { text: "Payment", value: "paymentMode", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Date & Time", value: "createdAt", sortable: false },
      ],

      saveColumnsText: "Save",
      toggleColumnSelect: false,
      defaultHeaders: [
        { text: "#", value: "index", disabled: true },
        { text: "SID", value: "sid", sortable: false, disabled: true },
        { text: "Name", value: "name", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        { text: "Payment", value: "paymentMode", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Date & Time", value: "createdAt", sortable: false },
      ],

      availableHeaders: [
        { text: "#", value: "index", sortable: false, disabled: true },
        { text: "SID", value: "sid", sortable: false, disabled: true },
        { text: "Name", value: "name", sortable: false },
        { text: "Mobile", value: "mobile", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        { text: "Delivery Total", value: "perDeliveryTotal", sortable: false },
        { text: "Cycle", value: "cycle", sortable: false },
        { text: "Period", value: "period", sortable: false },
        { text: "Start Date", value: "startDate", sortable: false },
        { text: "End Date", value: "endDate", sortable: false },
        { text: "Payment", value: "paymentMode", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Date & Time", value: "createdAt", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v === false || v == true || "It is required"],

        amountRule: [(v) => v >= 0 || "Enter a valid amount"],

        selectedProduct: [(v) => (v && !!v._id) || "Please select a product"],
      },
    };
  },
};

import Global from '../../../services/global';
import Store from '../../../services/store';

import config from '../../../config';
import moment from 'moment';

export default {
  name: 'Billing',

  created() {
    if (!this.amIAllowedAdmin() || this.isVendor) return Store.redirectToHome();
 
    this._id = this.$route.params._id;
    this.fetchInvoices();

    if (this.store.plan == 'growcify-pay-as-you-grow') {
      this.fetchBilling();
      this.tabs.push({ _id: 'billing', title: 'Running Billing' });
    }
  },

  methods: {
    amIAllowedAdmin() {
      return Store.doIHaveAdminAccess(this.$store);
    },

    fetchInvoices() {
      this.processing = true;
      return Store.getInvoices(this._id)
        .then(x => {
          this.invoices = x.data.map((x, index) => {
            x.index = (index+1)+'.';
            x.createdByName = x.createdBy && x.createdBy.name ? x.createdBy.name : '-';
            return x;
          });
          this.processing = false;
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
        });
    },

    getURL(data) {
      if (!data) return data;
      const ext = data.split('.')[1];
      if (data && (this.supportedFormats.includes(ext) || ext == 'png')) return `https://${this.bucketID}.s3.amazonaws.com/${data}`;
      return data;
    },

    openDocument(item) {
      if (!item.isAutomated) return window.open(this.getURL(item.file._id), '_blank');

      const token = this.$store.state.token;
      const baseURL = process.env.NODE_ENV !== 'production' ? config.apiDev : config.apiProd;
      const endpoint = `${baseURL}invoice/${item._id}/download`;

      window.open(endpoint + '?x-request-user-agent=application/admin-app&token=' + token, '_blank');
    },

    formatDate(date, format = 'lll') {
      return moment(date).format(format);
    },

    processBillingPayment() {
      if (this.$route.query.pay) {
        this.tab = 1; 
        delete this.$route.query.pay;
        setTimeout(() => this.pay(), 500);
      }
    },

    fetchBilling() {
      this.bfetching = true;
      return Store
        .getBilling(this.store._id)
        .then(x => {
          if (x.data) { 
            this.billing = x.data;
            this.processBillingPayment();
          }
          this.bfetching = false;
        })
        .catch(e => {
          console.log(e);
          this.bfetching = false;
        })
    },

    razOptions() {
      return {
        amount: this.billing.amount,
        currencyCode: this.billing.currencyCode || 'INR'
      }
    },

    pay() {
      const amount = this.razOptions().amount;
      const currencyCode = this.razOptions().currencyCode;

      const options = Global.getRazOptions(amount, 'Growcify Platform Fee', currencyCode);
      options.handler = (response) => { this.processPayment(response.razorpay_payment_id) };
      new window.Razorpay(options).open();
    },

    processPayment(paymentId) {
      const billingObject = {
        paymentId: paymentId,
        amount: this.razOptions().amount,
        currencyCode: this.razOptions().currencyCode,
      }

      this.processing = true;
      return Store
        .processBillingPayment(this.billing._id, this.store._id, billingObject)
        .then(() => {
          this.processing = false;
          this.fetchBilling();
          this.fetchStoreStatus();
          
          this.bsuccess = true;
          setTimeout(() => this.bsuccess = false, 10000);
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
        });
    },

    fetchStoreStatus() {
      return Store.getRetnalStatus(this._id, true)
        .then(x => this.$store.state.planInfo = x.data)
        .catch(e => console.log(e));
    },
  },

  computed: {
    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    store() {
      return this.$store.state.activeStore;
    },

    tax() {
      return config.gst;
    },
    
    planInfo() {
      return this.$store.state.planInfo;
    },
  },

  data() {
    return {
      _id: '',
      loading: false,
      processing: false,
      search: '',

      bsuccess: false,

      invoices: [],
      bucketID: 'growcify',
      rowItems: config.rowItems,
      supportedFormats: ['image', 'pdf', 'xls', 'xsls', 'doc', 'docx'],

      tab: 0,
      tabs: [
        { _id: 'invoices', title: 'Invoices' }
      ],

      billing: false,
      bitems: [
        { index: '1.', _id: 'pos', description: 'POS Orders', units: 255, amount: 249.22 },
        { index: '2.', _id: 'website', description: 'Website Orders', units: 25, amount: 529.65 },
        { index: '3.', _id: 'regular', description: 'App & Dashboard Orders', units: 55, amount: 29229 },
      ],

      bheaders: [
        { text: '#', align: 'start', value: 'index', sortable: false },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Units', value: 'units', align: 'right', sortable: false },
        { text: 'Amount', value: 'amount', align: 'right', sortable: false },
      ],

      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Title', value: 'title' },
        { text: 'Invoice ID', value: 'invoiceID' },
        { text: 'Invoice Date', value: 'invoiceDate' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Date & Time', value: 'createdAt' },
        { text: 'Download', value: 'actions', sortable: false, align: 'center' },
      ]
    }
  }
}
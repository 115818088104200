import Store from "../../../services/store";
import Inventory from "../../../services/inventory";
import FileUpload from "../../../services/file-upload";
import router from "../../../router";

import config from "../../../config";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "Brands",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Brands")) return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchBrands();
    this.fetchCommonEnums();

    //filter columns
    if (this.$store.state.activeStore.isVendor)
      this.headers = this.headers.filter((x) => x.value != "actions");
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.cenums = ce);

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    fetchBrands() {
      this.processing = this.loading = true;

      let brandRequest;
      if (this.$store.state.activeStore.isVendor) {
        brandRequest = Inventory.getVendorBrands(this._id, false);
      } else {
        brandRequest = Inventory.getBrands(this._id, false);
      }

      return brandRequest
        .then((x) => {
          this.brands = x.data;
          this.brands.forEach((x, index) => (x.index = index + 1 + "."));
          this.processing = this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.brands.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.editedItem.order = this.editedIndex >= 0 ? this.editedIndex + 1 : 1;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Inventory.deleteBrand(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchBrands();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true, true);
    },

    save(closeModal = true, fetchBrands = true, forceSave) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) {
        this.$refs.form.validate();
        return;
      }

      Store.snackbarStart();
      this.processing = this.editedItem.processing = true;
      let request;

      const object = Object.assign({}, this.editedItem);
      if (this.editedItem._id)
        request = Inventory.editBrand(this.editedItem._id, object, false);
      else request = Inventory.addBrand(this._id, object, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          if (fetchBrands) this.fetchBrands();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = this.editedItem.processing = false;
          Store.snackbarError();
        });
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      if (file && file["type"].split("/")[0] === "image") {
        this.editedItem.type = file["type"].split("/")[0];
        return true;
      }
      return false;
    },

    getURL(data) {
      if (
        data &&
        (data.split(".")[1] === "png" || data.split(".")[1] === "mp4")
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError() {
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.editedItem.file = "";
      this.editedItem.file = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(this._id, "brands", file)
        .then((x) => {
          this.editedItem.file = x.data.file;
          this.uploading = false;
          if (this.editedItem._id) this.save(false, false, true);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false);
    },

    copyID(_id) {
      navigator.clipboard.writeText(_id);
      this.copyBIDText = "Copied";
      setTimeout(() => (this.copyBIDText = "Copy Brand ID"), 1500);
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add new brand" : "Edit brand";
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },
  },

  data() {
    return {
      _id: "",
      brands: [],

      valid: false,
      processing: false,
      loading: false,
      uploading: false,
      showErr: false,
      dialog: false,

      search: "",
      cenums: {},

      translationField: "",
      translationModal: false,

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
        type: "image",
        file: undefined,
      },

      confirmDialog: false,
      beingDeleted: {},
      rowItems: config.rowItems,

      copyBIDText: "Copy Brand ID",

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],
      },
    };
  },
};

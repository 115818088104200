<template>
  <v-row>
    <v-col>
      <v-card outlined class="text-center pt-3 pb-3">
        <v-list-item-subtitle style="font-size: 0.85rem;">
          {{ title }}
        </v-list-item-subtitle>
        <v-list-item-title>
          <h5 style="margin: 0">{{ description }}</h5>
        </v-list-item-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "InfoCard",

  props: ["title", "description"],
};
</script>

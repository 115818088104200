import moment from "moment";
import _ from "underscore";

import config from "../../../config";
import User from "../../../services/user";
import Store from "../../../services/store";
import router from "../../../router";

import CustomerForm from "../../../components/customer-form/customer-form.vue";

export default {
  name: "Customers",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Customers"))
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions")) {
      this.headers = this.headers.filter((x) => x.text !== "Actions");
      this.availableHeaders = this.availableHeaders.filter(
        (x) => x.text !== "Actions"
      );
    }

    this.fetchCustomers(1);
    this.fetchMemberships();

    //set default columns
    const savedColumns = localStorage["customersColumns"];
    if (savedColumns) this.headers = JSON.parse(savedColumns);
  },

  components: {
    CustomerForm,
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    doIHaveAdminAccess() {
      return Store.doIHaveAdminAccess(this.$store);
    },

    getTabID(index) {
      return this.tabs[index]._id;
    },

    fetchCustomers(
      pageNumber = 1,
      itemsPerPage = this.customersObject.itemsPerPage,
      keyword = ""
    ) {
      this.loading = true;

      const tabID = this.getTabID(this.tab);
      return User.getUsers(
        this._id,
        pageNumber,
        itemsPerPage,
        keyword,
        tabID,
        false
      )
        .then((res) => {
          this.customersObject = res.data;
          this.customers = this.customersObject.items.map((x, index) => {
            x.index = index + 1 + ".";
            x.createdAtD = moment(x.createdAt).format("lll");
            x.named = x.name || "-";
            x.emaild = x.email || "-";
            x.pincoded = x.pincode || "-";
            x.orders = x.orders || 0;
            x.sd = !x.deactivated ? "Active" : "Inactive";
            return x;
          });

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    fetchMemberships() {
      return Store.getMemberships(this._id)
        .then((x) => (this.memberships = x.data))
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$store.state.customerFormModal = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    canDelete(_id) {
      return this.$store.state.activeStore.owner !== _id;
    },

    finalDelete() {
      this.processing = true;
      Store.snackbarStart();
      return User.deleteUser(this._id, this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchCustomers();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          Store.snackbarError();

          if (e.response.status === 403 && e.response.data) {
            if (e.response.data.error == "no-access")
              this.$store.state.snackbar.text =
                "You do not have access to perform this action";
            if (e.response.data.error == "already-exists-in-vendor")
              this.$store.state.snackbar.text =
                "This number already exists in vendors. To delete a vendor, first remove them from Vendors.";
            if (e.response.data.error == "already-exists-in-employee")
              this.$store.state.snackbar.text =
                "This number already exists in employees. To delete an employee, first remove them from Employees.";
          }
        });
    },

    close() {
      this.dialog = this.$store.state.customerFormModal = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.editedItem._id)
        request = User.editUser(
          this._id,
          this.editedItem._id,
          this.editedItem,
          false
        );
      else {
        request = User.addUser(this._id, this.editedItem, false);
      }

      return request
        .then(() => {
          if (closeModal) this.close();
          this.fetchCustomers();
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          if (e.response && e.response.status === 401) {
            this.error = "Email or mobile number already exists.";
            setTimeout(() => (this.error = ""), 2500);
          } else {
            Store.snackbarError();
          }
          this.processing = false;
          this.loading = false;
        });
    },

    exportCustomers() {
      this.exporting = true;
      return User.exportCustomers(this._id)
        .then(() => {
          this.exporting = false;
          this.$store.state.snackbar.text =
            "Your request has been submit. Once the customer report is ready, you'll receive it via email. Thanks.";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "success";
          this.$store.state.snackbar.show = true;
        })
        .catch(() => {
          this.exporting = false;
        });
    },

    downloadSampleCSV() {
      window.open(
        config.grocify.website + config.grocify.sampleCustomersCSV,
        "_system"
      );
    },

    processImportCSV() {
      this.$store.state.csvConfirmDialog = false;
      return router.push("/business/" + this._id + "/customers/import");
    },

    // eslint-disable-next-line
    columnsChanged(item) {
      const nh = [];
      this.availableHeaders.forEach((x) => {
        if (this.headers.find((i) => i.value == x.value)) nh.push(x);
      });
      this.headers = nh;
    },

    saveColumns() {
      localStorage["customersColumns"] = JSON.stringify(this.headers);
      this.saveColumnsText = "Saved";
      setTimeout(() => {
        this.saveColumnsText = "Save";
        this.toggleColumnSelect = !this.toggleColumnSelect;
      }, 250);
    },

    resetColumns() {
      this.headers = this.defaultHeaders;
      localStorage.removeItem("customersColumns");
    },

    openWhatsApp(info) {
      const callingCode =
        info.callingCode || this.$store.state.country.callingCode || "91";
      window.open(
        "https://api.whatsapp.com/send?phone=" + callingCode + info.mobile,
        "_system"
      );
    },

    getCallingCode(item) {
      return item.callingCode || this.$store.state.country.callingCode || "91";
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add new customer" : "Edit customer";
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    searchDisabled() {
      if (this.search && this.search.includes("membership")) return true;
      return false;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    isOwner() {
      return this.$store.state.activeStore.whoAmI == "owner";
    },

    isWhitelabeled() {
      return !this.isOwner && this.$store.state.activeStore.isWhitelabeled;
    },
  },

  watch: {
    userModified(n) {
      if (n) {
        this.$store.state.customerFormModal = this.userModified = false;
        this.fetchCustomers();
      }
    },

    options: {
      handler(options) {
        if (!this.loading)
          this.fetchCustomers(options.page, options.itemsPerPage, this.search);
      },
      deep: true,
    },

    search: _.debounce(function(nv) {
      this.fetchCustomers(1, this.options.itemsPerPage, nv);
    }, 500),

    membershipFilter(n) {
      if (n) this.search = "membership:" + n;
      else this.search = "";
    },

    tab() {
      this.customers = [];
      this.selectedCustomers = [];
      this.fetchCustomers(
        this.options.page,
        this.options.itemsPerPage,
        this.search
      );
    },
  },

  data: () => {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      _id: "",
      customers: [],
      rowItems: config.rowItems,

      search: "",
      loading: true,

      processing: false,
      exporting: false,

      valid: false,
      dialog: false,
      error: "",
      editedIndex: -1,
      editedItem: {
        deactivated: false,
      },
      defaultItem: {
        deactivated: false,
      },

      confirmDialog: false,
      beingDeleted: {},

      selectedCustomers: [],
      notificationModal: false,
      editedNotificationItem: {
        notify: true,
        sendSMSIfNotificationFails: false,
        toAll: false,
        data: {
          entity: "regular",
        },
      },

      userModified: false,

      options: {},
      customersObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      memberships: [],
      membershipFilter: "",

      headers: [
        { text: "#", align: "start", value: "index", disabled: true },
        { text: "Name", align: "start", value: "named", disabled: true },
        { text: "Mobile", value: "mobile" },
        { text: "Email", value: "emaild" },
        { text: "Created Date", value: "createdAtD", sortable: false },
        {
          text: "Action",
          align: "center",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      defaultHeaders: [
        { text: "#", align: "start", value: "index", disabled: true },
        { text: "Name", align: "start", value: "named", disabled: true },
        { text: "Mobile", value: "mobile" },
        { text: "Email", value: "emaild" },
        { text: "Created Date", value: "createdAtD", sortable: false },
        {
          text: "Action",
          align: "center",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      toggleColumnSelect: false,
      saveColumnsText: "Save",
      availableHeaders: [
        { text: "#", align: "start", value: "index", disabled: true },
        { text: "Name", align: "start", value: "named", disabled: true },
        { text: "Mobile", value: "mobile" },
        { text: "Email", value: "emaild" },
        { text: "Pincode", value: "pincoded" },
        { text: "Orders", value: "orders" },
        { text: "Status", value: "sd" },
        { text: "Referrals", value: "referrals" },
        { text: "Created Date", value: "createdAtD", sortable: false },
        {
          text: "Action",
          align: "center",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      counts: {},
      tab: 0,
      tabs: [
        { _id: "all", title: "All Customers" },
        {
          _id: "frequent",
          title: "Frequent Customers",
          description:
            "Customers who have ordered twice or more in the last 1 week",
        },
        {
          _id: "one-order",
          title: "One Order Customers",
          description:
            "Customers who have ordered only once in the last 2 months",
        },
        {
          _id: "no-order",
          title: "No Order Customers",
          description:
            "Customers who have not ordered even once in the last 2 months",
        },
        {
          _id: "discount-seekers",
          title: "Discount Seekers",
          description:
            "Customers who have ordered using a discount coupon in the last 1 month",
        },
      ],

      validationRules: {
        codeRule: [(v) => (v && v.length > 3) || "Code must be unique"],
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        emailRule: [(v) => !v || emailregex.test(v) || "Must be a valid email"],
      },
    };
  },
};

<template>
  <section>
    <section v-if="tasks.includes('calls')">
      <v-row>
        <v-col cols="9">
          <v-list-item-subtitle>
            <b :class="isDT ? 'text-white' : 'text-black'">Calls</b>
          </v-list-item-subtitle>
        </v-col>
      </v-row>

      <div style="height: 15px;"></div>

      <v-data-table
        :headers="callColumns"
        :items="callItems"
        :loading="cloading"
        loading-text="Loading...">

        <template v-slot:item.lead.name="{ item }">
          {{ item.lead.name || '-' }}
        </template>

        <template v-slot:item.remarks="{ item }">
          <TextWrapper :text="item.remarks" :length="60"></TextWrapper>
        </template>

        <template v-slot:item.calledFrom="{ item }">
          {{ item.from || '-' }}
        </template>

        <template v-slot:item.calledTo="{ item }">
          +{{ item.callingCode }} {{ item.to || '-' }}
        </template>

        <template v-slot:item.duration="{ item }">
          {{ item.duration || '-' }}
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>

        <template v-slot:item.createdByName="{ item }">
          {{ item.createdBy && item.createdBy.name ? item.createdBy.name : '-' }}
        </template>
      </v-data-table>
    </section>

    <section v-if="tasks.includes('meetings')">
      <div style="height: 50px;"></div>
      <v-row>
        <v-col cols="9">
          <v-list-item-subtitle>
            <b :class="isDT ? 'text-white' : 'text-black'">Meetings</b>
          </v-list-item-subtitle>
        </v-col>
      </v-row>

      <div style="height: 15px;"></div>

      <v-data-table
        :headers="meetingColoumns"
        :items="meetingsItems"
        :loading="mloading"
        loading-text="Loading...">

        <template v-slot:item.lead.name="{ item }">
          {{ item.lead ? item.lead.name : '-' }}
        </template>

        <template v-slot:item.summary="{ item }">
          <TextWrapper :text="item.summary" :length="50"></TextWrapper>
        </template>

        <template v-slot:item.attendees="{ item }">
          <TextWrapper :text="item.attendees.join(', ')" :length="50"></TextWrapper>
        </template>

        <template v-slot:item.start="{ item }">
          {{ formatDate(item.start) }}
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>

        <template v-slot:item.scheduledByName="{ item }">
          {{ item.scheduledBy && item.scheduledBy.name ? item.scheduledBy.name : '-' }}
        </template>
      </v-data-table>
    </section>

    <section v-if="tasks.includes('sms')">
      <div style="height: 50px;"></div>
      <v-row>
        <v-col cols="9">
          <v-list-item-subtitle>
            <b :class="isDT ? 'text-white' : 'text-black'">Leads Assigned</b>
          </v-list-item-subtitle>
        </v-col>
      </v-row>

      <div style="height: 15px;"></div>

      <v-data-table
        :headers="leadColumns"
        :items="leads"
        :loading="lloading"
        loading-text="Loading...">

        <template v-slot:item.name="{ item }">
          {{ item.name || '-' }}
        </template>

        <template v-slot:item.city="{ item }">
          {{ item.city || '-' }}
        </template>

        <template v-slot:item.contactNumber="{ item }">
          {{ item.contactNumber }}
        </template>

        <template v-slot:item.remarks="{ item }">
          <TextWrapper :text="item.remarks || '-'" :length="75"></TextWrapper>
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>

        <template v-slot:item.convertedAt=" { item } ">
          <span v-if="item.convertedAt">{{ formatDate(item.convertedAt, 'll') }} ({{ getDays(item.convertedAt, item.createdAt) }} days)</span>
          <span v-else>-</span>
        </template>

        <template v-slot:item.assignedTo="{ item }">
          {{ item.assignedTo && item.assignedTo.name ? item.assignedTo.name : '-' }}
        </template>
      </v-data-table>
    </section>

    <section v-if="tasks.includes('sms')">
      <div style="height: 50px;"></div>
      <v-row>
        <v-col cols="9">
          <v-list-item-subtitle>
            <b :class="isDT ? 'text-white' : 'text-black'">Messages</b>
          </v-list-item-subtitle>
        </v-col>
      </v-row>

      <div style="height: 15px;"></div>

      <v-data-table
        :headers="smsColumns"
        :items="smsItems"
        :loading="sloading"
        loading-text="Loading...">

        <template v-slot:item.lead.name="{ item }">
          {{ item.lead.name || '-' }}
        </template>

        <template v-slot:item.sentTo="{ item }">
          +{{ item.callingCode || '91' }} {{ item.mobile }}
        </template>

        <template v-slot:item.sentFrom="{ item }">
          {{ item.type == 'SMS' ? 'GROCFY' : '+91 8005771838' }}
        </template>

        <template v-slot:item.message="{ item }">
          <TextWrapper :text="item.message" :length="75"></TextWrapper>
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>

        <template v-slot:item.sentByName="{ item }">
          {{ item.sentBy && item.sentBy.name ? item.sentBy.name : '-' }}
        </template>
      </v-data-table>
    </section>
  </section>
</template>

<script>
import moment from 'moment';

export default {
  props: [
    'tasks',
    'cloading',
    'calls',
    'leads',
    'mloading',
    'meetings',
    'sloading',
    'lloading',
    'smsHistory'
  ],

  methods: {
    formatDate(date, format = 'lll') {
      return moment(date).format(format);
    },

    getDays(a, b) {
      return moment(a).diff(b, 'days');
    }
  },

  computed: {
    callItems() {
      return this.calls.map(x => { x.createdByName = x.createdBy ? x.createdBy.name : ''; return x; })
    },

    meetingsItems() {
      return this.meetings.map(x => { x.scheduledByName = x.scheduledBy ? x.scheduledBy.name : ''; return x; })
    },

    smsItems() {
      return this.smsHistory.map(x => { x.sentByName = x.sentBy ? x.sentBy.name : ''; return x; })
    },

    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  data() {
    return {
      meetingColoumns: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', align: 'start', value: 'lead.name' },
        { text: 'Title',  align: 'start', value: 'summary' },
        { text: 'Attendees', value: 'attendees'},
        { text: 'Scheduled At', value: 'start' },
        { text: 'Scheduled By', value: 'scheduledByName' },
        { text: 'Created At', value: 'createdAt' },
      ],

      smsColumns: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', align: 'start', value: 'lead.name' },
        { text: 'Type', value: 'type' },
        { text: 'From',  align: 'start', value: 'sentFrom' },
        { text: 'To',  align: 'start', value: 'sentTo' },
        { text: 'Message', value: 'message' },
        { text: 'Sent By', value: 'sentByName'},
        { text: 'Sent At', value: 'createdAt' },
      ],

      callColumns: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', align: 'start', value: 'lead.name' },
        { text: 'Type', value: 'type' },
        { text: 'From',  align: 'start', value: 'calledFrom' },
        { text: 'To',  align: 'start', value: 'calledTo' },
        { text: 'Duration',  align: 'start', value: 'duration' },
        { text: 'Remarks', value: 'remarks' },
        { text: 'Called By', value: 'createdByName'},
        { text: 'Called At', value: 'createdAt' },
      ],

      leadColumns: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', value: 'name' },
        { text: 'Contact Number', value: 'contactNumber' },
        { text: 'City', value: 'city' },
        { text: 'Remarks', value: 'remarks' },
        { text: 'Created At', value: 'createdAt' },
        { text: 'Converted Date', value: 'convertedAt'},
        { text: 'Assgined To', value: 'assignedTo' },
      ]
    }
  }
}
</script>

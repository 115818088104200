import Store from "../../services/store";
import FileUpload from "../../services/file-upload";
import config from "../../config";
import moment from "moment";

export default {
  name: "Support",

  created() {
    if (!this.canProceed) return Store.redirectToHome();
    this.fetchTickets();
    this.fetchTicketsEnums();

    //subscribe to new lead event
    this.initPusher();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    initPusher() {
      const channelName = "growcify-support";
      const eventName = "newCase";

      const pusher = this.$store.state.pusher;
      pusher.unsubscribe(channelName);
      const channel = pusher.subscribe(channelName);
      channel.unbind(eventName);
      channel.bind(eventName, () => {
        this.notify();
        if (this.$route.name === "support") this.fetchTickets();
      });
    },

    notify(title, body = {}) {
      this.$notification.show(
        title || "New Growcify Support Case! ✌️",
        body,
        {}
      );
    },

    fetchTickets() {
      this.processing = true;
      return Store.getGrowcifyTickets()
        .then((x) => {
          this.tickets = x.data.map((x, index) => {
            x.index = index + 1 + ".";
            x.createdByName =
              x.createdBy && x.createdBy.name ? x.createdBy.name : "-";
            x.businessName = x.store && x.store.name ? x.store.name : "-";
            return x;
          });
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    fetchTicketsEnums() {
      return Store.getTicketsEnums()
        .then((x) => (this.enums = x.data))
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.ticketModal = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteTicket(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.getGrowcifyTickets();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.ticketModal = false;
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    isValidDocument(file) {
      if (!file) return false;
      const type = file["type"].split("/")[0];
      const type2 = file["type"].split("/")[1];
      if (
        file &&
        (this.supportedFormats.includes(type) ||
          this.supportedFormats.includes(type2))
      ) {
        this.fileObject = {
          name: file["name"],
          size: file["size"],
          type: this.supportedFormats.includes(type) ? type : type2,
        };
        return true;
      }
      return false;
    },

    getURL(data) {
      if (!data) return data;
      const ext = data.split(".")[1];
      if (data && (this.supportedFormats.includes(ext) || ext == "png")) {
        return `https://${this._id}.s3.amazonaws.com/${data}`;
      }
      return data;
    },

    invalidDocError() {
      this.$store.state.snackbar.text = "Unsupported File Format";
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isValidDocument(file)) return this.invalidDocError();

      this.fileObject._id = "";
      this.fileObject._id = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(this._id, "support", file, this.fileObject.type)
        .then((x) => {
          if (!this.editedItem.files) this.editedItem.files = [];
          this.fileObject._id = x.data.file;
          this.editedItem.files.push(this.fileObject);
          this.uploading = false;
          if (this.editedItem._id) this.save(false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    openDocument(item) {
      window.open(this.getURL(item._id), "_blank");
    },

    removeDocument(item) {
      const files = this.editedItem.files.filter((x) => x._id != item._id);
      this.editedItem.files = files;
      this.save(false);
    },

    changeStatus(item, status) {
      item.status = status;
      this.editedItem = item;
      this.save(true);
    },

    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.editedItem._id)
        request = Store.editTicket(this.editedItem._id, this.editedItem, false);
      else request = Store.createTicket(this._id, this.editedItem, false);

      return request
        .then(() => {
          this.processing = false;
          if (closeModal) this.close();
          Store.snackbarSuccess();
          this.fetchTickets();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    formatDate(date, format = "lll") {
      return moment(date).format(format);
    },
  },

  computed: {
    formTitle() {
      return !this.editedItem._id ? "Create new case" : "Edit case";
    },

    canProceed() {
      return (
        (Store.amISuperadmin(this.$store) ||
          Store.amIGrowcifyEmployee(this.$store)) &&
        this.$store.state.isGD
      );
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data() {
    return {
      _id: "",

      valid: false,
      ticketModal: false,
      loading: false,
      processing: false,

      search: "",
      tickets: [],
      stores: [],
      enums: {},

      rowItems: config.rowItems,

      editedItem: {},
      defaultItem: {
        status: "Open",
      },

      confirmDialog: false,
      beingDeleted: {},

      confirmResendDialog: false,
      resendItem: {},

      supportedFormats: ["image", "pdf", "xls", "xsls", "doc", "docx"],
      fileObject: {},
      uploading: false,
      ploading: false,

      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Case ID", value: "tid" },
        { text: "Subject", value: "subject" },
        { text: "Business", value: "businessName" },
        { text: "Status", value: "status" },
        { text: "Created By", value: "createdByName" },
        { text: "Date & Time", value: "createdAt" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        numberRule: [(v) => (!isNaN(v) && v >= 0) || "Enter a valid number"],
      },
    };
  },
};

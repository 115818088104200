import Vue from "vue";

import config from "../../config";
import FileUpload from "../../services/file-upload";
import Store from "../../services/store";
import MasterInventory from "../../services/master-inventory";
import _ from "underscore";
import { getS3URL } from "../../helpers/misc";

export default {
  props: [
    "_id",
    "dialog",
    "loading",
    "processing",
    "editedItem",
    "categories",
    "isSubcategory",
  ],

  computed: {
    formTitle() {
      let text = "";
      if (!this.isSubcategory && !this.editedItem.parent)
        text = this.editedItem._id ? "Edit category" : "Create new category";
      else
        text = this.editedItem._id
          ? "Edit sub-category"
          : "Create new sub-category";
      return text;
    },

    filteredCategories() {
      return this.categories
        ? this.categories.filter((x) => x._id != this.editedItem._id)
        : [];
    },

    canSetDeliveryCharge() {
      return (
        this.$store.state.activeStore.categoryWiseDeliveryCharge &&
        ((this.$store.state.activeStore.mainCategoryWiseDeliveryCharge &&
          !this.editedItem.parent) ||
          !this.$store.state.activeStore.mainCategoryWiseDeliveryCharge)
      );
    },

    store() {
      return this.$store.state.activeStore;
    },

    dcPrefix() {
      return this.store.deliveryChargeBy == "amount"
        ? this.$store.state.country.currencySymbol
        : "";
    },

    dcSuffix() {
      return this.store.deliveryChargeBy == "percent" ? "%" : "";
    },
  },

  methods: {
    handleClick(index, photo) {
      if (!photo.id) this.pickImage(index);
      else photo.sheet = !photo.sheet;
    },

    getRef(index) {
      return this.$refs["inputUpload" + index][0];
    },

    getPhotoURL(data) {
      const array = data.split(".");
      if (
        data &&
        !array.includes(config.growcifyMasterInventory) &&
        array[1] === "png"
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    pushPhotoObject(index, _id, uploading, uploadProgress, sheet) {
      Vue.set(this.editedItem.photos, index, {
        uploading: uploading,
        _id: _id,
        uploadProgress: uploadProgress,
        sheet: sheet,
      });
    },

    pickImage(index) {
      this.getRef(index).click();
    },

    fileSelected(index) {
      const file = this.getRef(index).files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      //const localURL = URL.createObjectURL(file);
      this.pushPhotoObject(index, undefined, true, 0, false);
      this.uploading = true;
      return FileUpload.upload(this._id, "categories", file)
        .then((x) => {
          const _id = x.data.file;
          this.pushPhotoObject(index, _id, false, 0, false);
          if (this.editedItem._id) this.$parent.save(false, false); //only while editing
          this.uploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    invalidImageError() {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 2000);
    },

    isFileImage(file) {
      return file && file["type"].split("/")[0] === "image";
    },

    closeConfirmPhoto() {
      this.confirmDialogPhoto = false;
      this.beingDeletedPhoto = {};
    },

    deletePhoto(index, photo) {
      this.confirmDialogPhoto = true;
      this.beingDeletedPhoto = photo;
    },

    finalDeletePhoto() {
      this.editedItem.photos = this.editedItem.photos.filter(
        (x) => !x._id || x._id !== this.beingDeletedPhoto._id
      );
      this.editedItem.photos.push(this.photoItem);
      this.save(false, true, true);
    },

    save(closeModal = true, fetch = true, forceSave = false) {
      if (!this.$refs.form.validate()) return;
      this.$parent
        .save(closeModal, fetch, forceSave)
        .then(() => this.closeConfirmPhoto());
    },

    querySelections: _.debounce(function() {
      this.lastSearched = this.searchInput;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;

      return MasterInventory.searchCategories(
        this.$store.state.activeStore.category,
        this.searchInput,
        false
      )
        .then((x) => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.masterCategories = x.data;
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    }, 500),

    categorySelected() {
      this.editedItem.name = this.selectedCategory.name;
      this.editedItem.parent = this.selectedCategory.parent;
      this.editedItem.translations = this.selectedCategory.translations || {};
      this.editedItem.photos = this.selectedCategory.photos.map((x) => {
        if (x)
          x = `https://${config.growcifyMasterInventory}.s3.amazonaws.com/${x}`;
        return {
          uploading: false,
          _id: x,
          progress: 0,
          sheet: false,
        };
      });
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false, false);
    },

    getSequenceItems() {
      const arr = [1];
      for (let i = 0; i < this.categories.length; i++) arr.push(i + 2);
      return arr;
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.cenums = ce);

      this.fenums = true;
      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
          this.fenums = false;
        })
        .catch((e) => {
          console.log(e);
          this.fenums = false;
        });
    },
  },

  watch: {
    searchInput(newVal, oldVal) {
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        newVal != this.selectedCategory.name
      )
        this.querySelections(newVal);
    },

    dialog(n) {
      if (n) this.fetchCommonEnums();
    },

    "editedItem.name"(n) {
      if (n) {
        if (this.editedItem.translations && this.editedItem.translations.name) {
          this.editedItem.translations.name.en = n;
        }
      }
    },
  },

  data() {
    return {
      valid: false,
      uploading: false,

      fenums: false,

      translationModal: false,
      translationField: "",

      confirmDialogPhoto: false,
      beingDeletedPhoto: {},

      photoItem: {
        uploading: false,
        _id: undefined,
        progress: 0,
        sheet: false,
      },

      searchInput: "",
      masterCategories: [],

      lastSearched: "",
      isSearching: false,
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,

      selectedCategory: {},

      cenums: {},
      growcifyMasterInventory: config.growcifyMasterInventory,

      validationRules: {
        basicRule: [(v) => !!v || "It's required"],
        statusRule: [(v) => v || v === false || "Status is required"],

        lengthRule: [(v) => (!!v && !!v.length) || "It's required"],
      },
    };
  },
};

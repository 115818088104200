import _ from "underscore";

import Inventory from "../../services/inventory";
import Store from "../../services/store";
import FileUpload from "../../services/file-upload";
import { getS3URL } from "../../helpers/misc";

export default {
  name: "Banners",

  props: ["_id", "loading", "editedItem", "bannerUpdated"],

  methods: {
    fetchCategories() {
      let catRequest = Inventory.getCategories(this._id, false);
      if (this.isVendor)
        catRequest = Inventory.getVendorSubscribedCategories(this._id, false);

      this.cloading = true;
      return catRequest
        .then((res) => {
          this.categories = res.data;
          this.cloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.cloading = false;
        });
    },

    fetchVendors() {
      if (this.isVendor) return;
      this.vloading = true;
      return Store.getVendors(this._id, false)
        .then((res) => {
          this.vendors = res.data;
          this.vloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.vloading = false;
        });
    },

    fetchGroups() {
      this.gloading = true;
      return Store.getGroups(this._id, false)
        .then((x) => {
          this.groups = x.data;
          this.gloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.gloading = false;
        });
    },

    close() {
      this.$store.state.bannerModel = this.processing = this.loading = false;
    },

    save(closeModal = true, fetchBanners = true, forceSave) {
      if (
        !forceSave &&
        (!this.editedItem.file ||
          (this.$refs.form && !this.$refs.form.validate()))
      ) {
        if (!this.editedItem.file) {
          this.showErr = "Video or image is required";
          setTimeout(() => {
            this.showErr = false;
          }, 2500);
        }

        this.$refs.form.validate();
        return;
      }

      Store.snackbarStart();
      this.processing = this.editedItem.processing = true;
      let request;

      const object = Object.assign({}, this.editedItem);
      if (!object.redirectToCategory || object.redirectToCategory === "")
        delete object.redirectToCategory;
      if (!object.redirectToProduct || object.redirectToProduct === "")
        delete object.redirectToProduct;
      if (this.editedItem._id)
        request = Store.editBanner(this.editedItem._id, object, false);
      else request = Store.createBanner(this._id, object, false);

      return request
        .then(() => {
          Store.snackbarSuccess();
          if (closeModal) this.close();
          this.processing = this.editedItem.processing = false;
          if (fetchBanners) this.$emit("update:bannerUpdated", true);
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = this.editedItem.processing = false;
          Store.snackbarError();
        });
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      if (
        file &&
        (file["type"].split("/")[0] === "image" ||
          file["type"].split("/")[0] === "video")
      ) {
        this.editedItem.type = file["type"].split("/")[0];
        return true;
      }
      return false;
    },

    getURL(data) {
      if (
        data &&
        (data.split(".")[1] === "png" || data.split(".")[1] === "mp4")
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError() {
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.editedItem.file = "";
      this.editedItem.file = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(this._id, "banners", file, this.editedItem.type)
        .then((x) => {
          this.editedItem.file = x.data.file;
          this.uploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    productText(item) {
      return item.name + " (" + item.size + item.unit + ")";
    },

    querySelections(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;
      return Store.searchProducts(this._id, keyword, false)
        .then((x) => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.products = this.parseJSON(x.data);
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    },

    parseJSON(array) {
      return JSON.parse(JSON.stringify(array));
    },
  },

  computed: {
    isNonRegular() {
      return ["main", "mini"].includes(this.editedItem.bannerType);
    },

    formTitle() {
      if (this.isNonRegular)
        return !this.editedItem._id
          ? `Create new ${this.editedItem.bannerType} banner`
          : `Edit ${this.editedItem.bannerType} banner`;
      return !this.editedItem._id ? `Create new banner` : `Edit banner`;
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    redirectionItems() {
      const items = [
        { key: "category", value: "Category" },
        { key: "product", value: "Product" },
        { key: "url", value: "External URL" },
      ];

      if (!this.isVendor) items.push({ key: "vendor", value: "Vendor" });
      if (this.isNonRegular) items.push({ key: "group", value: "Group" });

      return items;
    },

    positions() {
      const items = [
        { key: 1, value: "Home Page: Top" },
        { key: 2, value: "Home Page: After Categories" },
        { key: 3, value: "Home Page: After New Arrivals" },
        { key: 4, value: "Home Page: After Trending" },
        { key: 5, value: "Home Page: After Coming Soon" },
      ];

      if (this.isNonRegular) {
        items.push({ key: 7, value: "Home Page: After Recommended" });
        items.push({ key: 8, value: "Home Page: After Featured" });
        items.push({ key: 6, value: "Home Page: After Group" });
      }
      return items;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  watch: {
    searchInput: _.debounce(function(newVal, oldVal) {
      const array = newVal ? newVal.split("(") : [];
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        array.length === 1
      )
        this.querySelections(newVal);
    }, 500),

    "$store.state.bannerModel"(n) {
      if (n) {
        if (this.editedItem.redirectToProduct)
          this.querySelections(this.editedItem.redirectToProduct);
      }
    },

    "editedItem.redirectTo"(n) {
      if (n == "category" && !this.categories.length) this.fetchCategories();
      else if (n == "vendor" && !this.vendors.length) this.fetchVendors();
      else if (n == "group" && !this.groups.length) this.fetchGroups();
    },

    "editedItem.position"(n) {
      if (n == 6 && !this.groups.length) this.fetchGroups();
    },
  },

  data() {
    return {
      valid: false,
      processing: false,
      uploading: false,
      showErr: false,

      cloading: false,
      vloading: false,
      gloading: false,

      categories: [],
      products: [],
      vendors: [],
      groups: [],

      searchInput: "",
      lastSearched: "",
      isSearching: false,
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],

        statusRule: [(v) => v || v === false || "It is required"],
      },
    };
  },
};

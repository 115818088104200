import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";

//import Home from '../views/home/home.vue'
import Signup from "../views/signup/signup.vue";
import Dashboard from "../views/dashboard/dashboard.vue";
import DashboardDefault from "../views/dashboard/default/default.vue";
import Stores from "../views/stores/stores.vue";
import NewStore from "../views/stores/new/new.vue";
import NewBusiness from "../views/stores/new-business/new-business.vue";

import Store from "../views/store/store.vue";
import StoreDashboard from "../views/store/dashboard/dashboard.vue";
import StoreNotifications from "../views/store/notifications/notifications.vue";
import StoreCustomersMemberships from "../views/store/customers-memberships/customers-memberships.vue";
import StoreCustomers from "../views/store/customers/customers.vue";
import StoreCustomersImport from "../views/store/customers-import/customers-import.vue";
import StoreCustomerInfo from "../views/store/customer/customer.vue";
import StoreOrders from "../views/store/orders/orders.vue";
import StorePickupAddresses from "../views/store/pickup-addresses/pickup-addresses.vue";
import StoreOrderForm from "../views/store/order-form/order-form.vue";
import StoreOrderInfo from "../views/store/order/order.vue";
import StoreSubscriptions from "../views/store/subscriptions/subscriptions.vue";
import StoreSubscriptionInfo from "../views/store/subscription/subscription.vue";
import StoreCoupons from "../views/store/coupons/coupons.vue";
import StoreBanners from "../views/store/banners/banners.vue";
import StoreCategories from "../views/store/categories/categories.vue";
import StoreCategoryInfo from "../views/store/category/category.vue";
import StoreProducts from "../views/store/products/products.vue";
import StoreProductsImport from "../views/store/products-import/products-import.vue";
import StoreProductInfo from "../views/store/product/product.vue";
import StoreEmployees from "../views/store/employees/employees.vue";
import StoreTransactions from "../views/store/transactions/transactions.vue";
import StoreDeliveries from "../views/store/deliveries/deliveries.vue";
import StoreLocations from "../views/store/locations/locations.vue";
import StoreOutlets from "../views/store/outlets/outlets.vue";
import StoreReports from "../views/store/reports/reports.vue";
import StoreSettings from "../views/store/settings/settings.vue";
import StoreIntegrations from "../views/store/integrations/integrations.vue";
import StoreDeals from "../views/store/deals/deals.vue";
import StoreReleases from "../views/store/releases/releases.vue";
import StorePopups from "../views/store/pop-ups/pop-ups.vue";
import StorePolicies from "../views/store/policies/policies.vue";
import StoreAboutUs from "../views/store/about-us/about-us.vue";
import StoreContactUs from "../views/store/contact-us/contact-us.vue";
import StoreVendors from "../views/store/vendors/vendors.vue";
import StoreVendorInfo from "../views/store/vendor/vendor.vue";
import StoreBrands from "../views/store/brands/brands.vue";
import StoreRequests from "../views/store/requests/requests.vue";
import StoreCommunities from "../views/store/communities/communities.vue";
import StoreHelpdesk from "../views/store/helpdesk/helpdesk.vue";
import StorePOS from "../views/store/pos/pos.vue";
import StoreProductStockEntries from "../views/store/product-stock-entries/product-stock-entries.vue";
import StoreSupportTickets from "../views/store/support-tickets/support-tickets.vue";
import StoreSupportTicket from "../views/store/support-ticket/support-ticket.vue";
import StoreBilling from "../views/store/billing/billing.vue";
import StorePaymentLinks from "../views/store/payment-links/payment-links.vue";
import DesignStudio from "../views/store/design-studio/design-studio.vue";
import StoreSuppliers from "../views/store/suppliers/suppliers.vue";
import StorePurchases from "../views/store/purchases/purchases.vue";
import StoreVendorLocations from "../views/store/vendor-locations/vendor-locations.vue";
import StoreReturnInfo from "../views/store/return/return.vue";

import MasterInventory from "../views/master-inventory/master-inventory.vue";
import i18n from "../views/i18n/i18n.vue";
import Countries from "../views/countries/countries.vue";
import Leads from "../views/leads/leads.vue";
import SMS from "../views/sms/sms.vue";
import Support from "../views/helpdesk/helpdesk.vue";
import Tickets from "../views/tickets/tickets.vue";
import Ticket from "../views/ticket/ticket.vue";
import Invoices from "../views/invoices/invoices.vue";
import KYC from "../views/kyc/kyc.vue";

import Verify from "../views/verify/verify";

import store from "../store";
import config from "../config";

import StoreService from "../services/store";

NProgress.configure({ showSpinner: false });

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Signup,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: Signup,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: Signup,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/businesses",
    name: "businesses",
    component: Signup,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: DashboardDefault,
      },
      {
        path: "default",
        redirect: "/dashboard",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "my-businesses",
        name: "my-businesses",
        component: Stores,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "new",
        name: "new-store",
        component: NewStore,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "new-business",
        name: "new-business",
        component: NewBusiness,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "mi",
        name: "master-inventory",
        component: MasterInventory,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "mi/p/:productId",
        name: "mi-product-info",
        component: StoreProductInfo,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "i18n",
        name: "i18n",
        component: i18n,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "kyc",
        name: "kyc",
        component: KYC,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "support",
        name: "support",
        component: Tickets,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "support/:ticketId",
        name: "support-info",
        component: Ticket,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "leads",
        name: "leads",
        component: Leads,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "crm",
        name: "crm",
        component: Leads,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "invoices",
        name: "invoices",
        component: Invoices,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "countries",
        name: "countries",
        component: Countries,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "sms",
        name: "sms",
        component: SMS,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "helpdesk",
        name: "helpdesk",
        component: Support,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/store/:_id([a-f\\d]{24})",
    redirect: "/business/:_id([a-f\\d]{24})",
  },
  {
    path: "/business/:_id([a-f\\d]{24})",
    name: "store",
    component: Store,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "store-dashboard",
        component: StoreDashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "default",
        name: "store-default",
        redirect: "/store/:_id",
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "customers",
        name: "store-customers",
        component: StoreCustomers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "customers/memberships",
        name: "store-customers-memberships",
        component: StoreCustomersMemberships,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "customers/:customerId([a-f\\d]{24})",
        name: "store-customer-info",
        component: StoreCustomerInfo,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "customers/import",
        name: "store-customers-import",
        component: StoreCustomersImport,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "notifications",
        name: "store-notifications",
        component: StoreNotifications,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "orders",
        name: "store-orders",
        component: StoreOrders,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "pickup-addresses",
        name: "store-pickup-addresses",
        component: StorePickupAddresses,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "communities",
        name: "store-communities",
        component: StoreCommunities,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "payment-links",
        name: "store-payment-links",
        component: StorePaymentLinks,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "orders/new",
        name: "store-order-new",
        component: StoreOrderForm,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "orders/:orderId([a-f\\d]{24})",
        name: "store-order-info",
        component: StoreOrderInfo,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "orders/:orderId([a-f\\d]{24})/edit",
        name: "store-order-edit",
        component: StoreOrderForm,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "returns/:returnId([a-f\\d]{24})",
        name: "store-return-info",
        component: StoreReturnInfo,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "subscriptions",
        name: "store-subscriptions",
        component: StoreSubscriptions,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "subscriptions/:subscriptionId([a-f\\d]{24})",
        name: "store-subscription-info",
        component: StoreSubscriptionInfo,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "coupons",
        name: "store-coupons",
        component: StoreCoupons,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "banners",
        name: "store-banners",
        component: StoreBanners,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "pop-ups",
        name: "store-popups",
        component: StorePopups,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "deals",
        name: "store-deals",
        component: StoreDeals,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "categories",
        name: "store-categories",
        component: StoreCategories,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "categories/:categoryId([a-f\\d]{24})",
        name: "store-category-info",
        component: StoreCategoryInfo,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "products",
        name: "store-products",
        component: StoreProducts,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "products/:productId([a-f\\d]{24})",
        name: "store-product-info",
        component: StoreProductInfo,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "products/import",
        name: "store-products-import",
        component: StoreProductsImport,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "products/stock-entries",
        name: "store-product-stock-entries",
        component: StoreProductStockEntries,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "suppliers",
        name: "store-suppliers",
        component: StoreSuppliers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "purchases",
        name: "store-purchases",
        component: StorePurchases,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "brands",
        name: "store-brands",
        component: StoreBrands,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "employees",
        name: "store-employees",
        component: StoreEmployees,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "transactions",
        name: "store-transactions",
        component: StoreTransactions,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "deliveries",
        name: "store-deliveries",
        component: StoreDeliveries,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "locations",
        name: "store-locations",
        component: StoreLocations,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "outlets",
        name: "store-outlets",
        component: StoreOutlets,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "vendors",
        name: "store-vendors",
        component: StoreVendors,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "vendors/:vendorId([a-f\\d]{24})",
        name: "store-vendor-info",
        component: StoreVendorInfo,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "vendor-locations",
        name: "store-vendor-locations",
        component: StoreVendorLocations,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "partners",
        name: "store-partners",
        component: StoreVendors,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "requests",
        name: "store-requests",
        component: StoreRequests,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "reports",
        name: "store-reports",
        component: StoreReports,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "integrations",
        name: "store-integrations",
        component: StoreIntegrations,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "settings",
        name: "store-settings",
        component: StoreSettings,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "settings/:slug(" + config.settingsSlug.join("|") + ")",
        name: "store-settings-slug",
        component: StoreSettings,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "releases",
        name: "store-releases",
        component: StoreReleases,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "policies",
        name: "store-policies",
        component: StorePolicies,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "about-us",
        name: "store-about-us",
        component: StoreAboutUs,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "contact-us",
        name: "store-contact-us",
        component: StoreContactUs,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "helpdesk",
        name: "store-helpdesk",
        component: StoreHelpdesk,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "support",
        name: "store-support-tickets",
        component: StoreSupportTickets,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "support/:ticketId([a-f\\d]{24})",
        name: "store-support-ticket",
        component: StoreSupportTicket,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "billing",
        name: "store-billing",
        component: StoreBilling,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "design-studio",
        name: "store-design-studio",
        component: DesignStudio,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "pos",
        name: "store-pos",
        component: StorePOS,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "pos/:page",
        name: "store-pos-page",
        component: StorePOS,
        meta: {
          requiresAuth: true,
          requiresActivation: true,
        },
      },
      {
        path: "*",
        redirect: "/store/:_id",
      },
    ],
  },
  {
    path: "/verify/:token",
    name: "verify",
    component: Verify,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  scrollBehavior(to, from) {
    if (from.name == "experience-detail") return;
    if (from.name == "member-detail") return;
    else return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

//auth guard
router.beforeEach((to, from, next) => {
  //for real-time if user deletes token from storage
  if (!localStorage.getItem("token")) store.commit("authError");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      //EventBus.$emit('prompt-login', { nextUrl: to.fullPath });
      if (store.state.isGD) router.push("/signup");
      else router.push("/signin");

      next(false);
    } else {
      if (!store.state.userInfo._id) {
        const token = store.state.token;
        Vue.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        store
          .dispatch("verify")
          .then(() => next())
          .catch(() => router.push("/signin"));
      } else {
        if (
          to.meta.requiresActivation &&
          !store.state.activeStore.isVendor &&
          !store.state.activeStore.paid &&
          (!store.state.activeStore.bypassDashboardAccess ||
            (to.name == "store-settings-slug" &&
              !["general", "app-information", "add-ons"].includes(
                to.params.slug
              ))) &&
          !StoreService.amISuperadmin(store) &&
          !StoreService.amIGrowcifyEmployee(store)
        )
          StoreService.snackbarAccessDenied();
        else next();
      }
    }
  } else {
    //if user forceRefresh the page
    if (store.getters.isAuthenticated && !store.state.userInfo._id) {
      const token = store.state.token;
      Vue.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      store
        .dispatch("verify")
        .then(() => {
          if (to.name === "signin" || to.name === "signup")
            router.push("/businesses");
          else next();
        })
        .catch(() => router.push("/signin"));
    } else {
      if (
        store.getters.isAuthenticated &&
        (to.name === "signin" || to.name === "signup")
      )
        return next(false);
      else next();
    }
  }
});

/*as we've added loading for http now*/
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;

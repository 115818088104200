import CategoryForm from "../../components/category-form/category-form.vue";
import ProductForm from "../../components/product-form/product-form.vue";

import MasterInventory from "../../services/master-inventory";
import Store from "../../services/store";
import config from "../../config";

import _ from "underscore";

export default {
  name: "MasterInventory",

  components: {
    CategoryForm,
    ProductForm,
  },

  created() {
    //if not superadmin, redirect to home
    if (
      !Store.amISuperadmin(this.$store) &&
      !Store.amIGrowcifyEmployee(this.$store)
    )
      return Store.redirectToHome();

    this._id = config.growcifyMasterInventory;

    for (let p = 0; p < 4; p++) {
      this.defaultCategoryItem.photos.push(this.photoItem);
      this.defaultProductItem.photos.push(this.photoItem);
    }
    this.defaultProductItem.photos.push(this.photoItem);
    this.defaultProductItem.photos.push(this.photoItem);

    this.loading = true;

    const p1 = this.fetchMasterCategories();
    const p2 = this.fetchMasterProducts(1);
    const p3 = this.fetchMasterProductEnums();

    return Promise.all([p1, p2, p3])
      .then(() => (this.loading = false))
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });
  },

  methods: {
    resetCategoryPhotos() {
      this.defaultCategoryItem.photos.forEach((x) => (x._id = undefined));
    },

    resetProductPhotos() {
      this.defaultProductItem.photos.forEach((x) => (x._id = undefined));
    },

    editCategoryItem(item) {
      this.editedCategoryItem = JSON.parse(JSON.stringify(item));
      this.categoryDialog = true;
    },

    editProductItem(item) {
      this.editedProductItem = JSON.parse(JSON.stringify(item));
      this.productDialog = true;
    },

    fetchMasterProductEnums() {
      return MasterInventory.getProductEnums()
        .then((res) => (this.enums = res.data))
        .catch((e) => console.log(e));
    },

    fetchMasterCategories() {
      this.cloading = true;
      return MasterInventory.getCategories(false)
        .then((res) => {
          this.categories = res.data.map((x, index) => {
            x.parentName = x.parent?.name || "--";
            x.parent = x.parent?._id || undefined;
            x.index = index + 1 + ".";

            // x.photos = x.photos.map(x => {
            //   return {
            //     _id: x || undefined,
            //     uploading: false,
            //     uploadProgress: 0,
            //     sheet: false
            //   }
            // });
            // const totalEmpty = 4-x.photos.length;
            // for (let p = 0; p < totalEmpty; p++) x.photos.push(this.photoItem);
            return x;
          });
          this.cloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.cloading = false;
        });
    },

    fetchMasterProducts(
      pageNumber,
      itemsPerPage = this.productsObject.itemsPerPage,
      keyword = ""
    ) {
      this.ploading = true;
      return MasterInventory.getProducts(
        pageNumber,
        itemsPerPage,
        keyword,
        false
      )
        .then((res) => {
          this.productsObject = res.data;
          this.products = this.productsObject.items.map((x, index) => {
            x.categoryName = x.category?.name || "--";
            x.category = x.category?._id || undefined;
            x.index =
              (pageNumber === 1
                ? index + 1
                : itemsPerPage * (pageNumber - 1) + index + 1) + ".";
            x.sizeName = x.size + x.unit;

            // x.photos = x.photos.map((x) => {
            //   return {
            //     _id: x || undefined,
            //     uploading: false,
            //     uploadProgress: 0,
            //     sheet: false,
            //   };
            // });

            // const totalEmpty = 6 - x.photos.length;
            // for (let p = 0; p < totalEmpty; p++) x.photos.push(this.photoItem);
            return x;
          });

          const { sortBy, sortDesc } = this.options;
          if (sortBy.length === 1 && sortDesc.length === 1) {
            this.products = this.products.sort((a, b) => {
              const sortA = a[sortBy[0]];
              const sortB = b[sortBy[0]];

              if (sortDesc[0]) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            });
          }

          this.ploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.ploading = false;
        });
    },

    deleteCategoryItem(item) {
      this.beingDeleted = item;
      this.confirmDialog = true;
    },

    deleteProductItem(item) {
      this.beingDeleted = item;
      this.confirmDialog = true;
    },

    closeConfirm() {
      this.confirmDialog = false;
      setTimeout(() => (this.beingDeleted = {}), 100);
    },

    finalDelete() {
      if (this.beingDeleted._id && !this.beingDeleted.category)
        this.finalDeleteCategory();
      else this.finalDeleteProduct();
    },

    finalDeleteCategory() {
      this.cprocessing = true;
      return MasterInventory.deleteCategory(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.cprocessing = false;
          this.fetchMasterCategories();
        })
        .catch((e) => {
          this.confirmDialog = this.cprocessing = false;
          console.log(e);
        });
    },

    finalDeleteProduct() {
      this.pprocessing = true;
      return MasterInventory.deleteProduct(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.pprocessing = false;
          this.fetchMasterProducts();
        })
        .catch((e) => {
          this.confirmDialog = this.pprocessing = false;
          console.log(e);
        });
    },

    close() {
      this.categoryDialog = this.productDialog = false;
      this.editedCategoryItem = this.editedProductItem = {};
    },

    save(closeModal = false, fetch = true, forceSave = false) {
      if (Object.keys(this.editedCategoryItem).length)
        return this.saveCategory(closeModal, fetch, forceSave);
      else if (Object.keys(this.editedProductItem).length)
        return this.saveProduct(closeModal, fetch, forceSave);
    },

    changeCategoryStatus(item) {
      this.editedCategoryItem = item;
      this.saveCategory(false, true, true);
    },

    changeProductStatus(item) {
      this.editedProductItem = item;
      this.saveProduct(false, true, true);
    },

    // eslint-disable-next-line
    saveCategory(
      closeModal = false,
      fetchCategories = true,
      forceSave = false
    ) {
      this.cprocessing = this.editedCategoryItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedCategoryItem);
      object.photos = object.photos ? object.photos.map((x) => x._id) : [];
      object.photos = object.photos.filter((x) => x);

      if (!object.parent || object.parent === "") delete object.parent;

      if (this.editedCategoryItem._id)
        request = MasterInventory.editCategory(
          this.editedCategoryItem._id,
          object,
          false
        );
      else request = MasterInventory.addCategory(object, false);

      return request
        .then(() => {
          this.cprocessing = this.loading = this.editedCategoryItem.processing = false;
          Store.snackbarSuccess();

          if (closeModal) this.close();
          if (fetchCategories) this.fetchMasterCategories();

          this.resetCategoryPhotos();
        })
        .catch((e) => {
          console.log(e);
          this.cprocessing = this.loading = this.editedCategoryItem.processing = false;
          Store.snackbarError();

          this.resetCategoryPhotos();
        });
    },

    // eslint-disable-next-line
    saveProduct(closeModal = false, fetchProducts = true, forceSave = false) {
      this.pprocessing = this.editedProductItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedProductItem);
      object.photos = object.photos.map((x) => x._id);
      object.photos = object.photos.filter((x) => x);

      if (!object.parent || object.parent === "") delete object.parent;
      if (!object.category || object.category === "") delete object.category;

      if (this.editedProductItem._id)
        request = MasterInventory.editProduct(
          this.editedProductItem._id,
          object,
          false
        );
      else request = MasterInventory.addProduct(object, false);

      return request
        .then(() => {
          this.pprocessing = this.loading = this.editedProductItem.processing = false;
          Store.snackbarSuccess();

          if (closeModal) this.close();
          if (fetchProducts)
            this.fetchMasterProducts(this.productsObject.pageNumber);

          this.resetColumn();
          this.closeDuplicate();
          this.resetProductPhotos();
        })
        .catch((e) => {
          console.log(e);
          this.pprocessing = this.loading = this.editedProductItem.processing = false;
          Store.snackbarError();

          this.resetColumn();
          this.closeDuplicate();
          this.resetProductPhotos();
        });
    },

    duplicateItem(item) {
      this.duplicateDialog = true;
      this.beingDuplicated = JSON.parse(
        JSON.stringify(Object.assign({}, item))
      );
    },

    closeDuplicate() {
      this.duplicateDialog = this.duplicatePromptDialog = this.creatingVariant = false;
      this.beingDuplicated = {};
    },

    nextDuplicate() {
      this.duplicateDialog = false;

      delete this.beingDuplicated._id;
      delete this.beingDuplicated.__v;
      delete this.beingDuplicated.createdAt;
      delete this.beingDuplicated.updatedAt;
      this.beingDuplicated.name = this.beingDuplicated.name + " (Copy)";
      this.duplicatePromptDialog = true;
    },

    finalDuplicate() {
      if (!this.beingDuplicated.name) return;
      this.editedProductItem = this.beingDuplicated;
      this.saveProduct(true, true, true);
    },

    createVariant(item) {
      this.duplicateDialog = this.creatingVariant = true;
      this.beingDuplicated = JSON.parse(
        JSON.stringify(Object.assign({}, item))
      );
    },

    nextCreateVariant() {
      this.duplicateDialog = false;

      this.beingDuplicated.parent = this.beingDuplicated._id;
      delete this.beingDuplicated._id;
      delete this.beingDuplicated.__v;
      delete this.beingDuplicated.createdAt;
      delete this.beingDuplicated.updatedAt;
      this.duplicatePromptDialog = true;
    },

    editColumn(_id, value, index, type) {
      this.ec._id = _id;
      this.ec.value = value;
      this.ec.index = index;
      this.ec.type = type;
    },

    saveCloumn() {
      if (!this.ec.value) return;

      this.editedProductItem = this.products.find((x) => x._id === this.ec._id);
      if (this.ec.type === "price")
        this.editedProductItem.price = this.ec.value;
      if (this.ec.type === "quantity")
        this.editedProductItem.quantity = this.ec.value;
      if (this.ec.type === "name") this.editedProductItem.name = this.ec.value;

      this.save();
    },

    resetColumn() {
      this.ec = {
        _id: "",
        value: "",
        index: 0,
        type: "",
        saving: false,
      };
    },

    removeSelected(item) {
      this.selected = this.selected.filter((x) => x._id != item._id);
    },

    massUpdate() {
      /*const massUpdates = {};
      Object.keys(this.massItem).forEach(x => {
        if (x) {
          if (x === 'size') massUpdates.size = this.massItem.size;
          else if (x === 'unit') massUpdates.unit = this.massItem.unit;
          else if (x === 'price' && this.massItem.price >= 0) massUpdates.price = parseInt(this.massItem.price);
          else if (x === 'quantity' && this.massItem.quantity >= 0) massUpdates.quantity = parseInt(this.massItem.quantity);
          else if (x === 'orderCap' && this.massItem.orderCap) massUpdates.orderCap = parseInt(this.massItem.orderCap);
          else if (x === 'isActive') massUpdates.isActive = this.massItem.isActive;
          else if (x === 'description' && this.massItem.description) massUpdates.description = this.massItem.description;
        }
      });

      if (!Object.keys(massUpdates).length) return;

      const massIds = this.selected.map(x => x._id);
      this.massprocessing = true;
      return MasterInventory
        .massUpdate({massIds: massIds, massUpdates: massUpdates})
        .then(() => {
          this.massItem = {};
          this.massprocessing = false;
          this.massdialog = false;
          this.fetchProductsOnly(this.productsObject.pageNumber);
        })
        .catch(e => {
          console.log(e)
          this.massprocessing = false;
          this.resetColumn();
        });*/
    },
  },

  watch: {
    "selected.length"(n) {
      if (!n) this.massdialog = false;
    },

    options: {
      handler(options) {
        if (this.products.length)
          this.fetchMasterProducts(
            options.page,
            options.itemsPerPage,
            this.search
          );
      },
      deep: true,
    },

    psearch: _.debounce(function(nv, ov) {
      if (this.products.length || nv == "" || nv != ov)
        this.fetchMasterProducts(1, this.options.itemsPerPage, nv);
    }, 500),
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data() {
    return {
      _id: "",

      loading: false,
      cloading: true,
      ploading: true,

      cprocessing: false,
      pprocessing: false,

      csearch: "",
      psearch: "",

      defaultCategoryItem: {
        name: "",
        isActive: true,
        parent: "",
        photos: [],
      },

      categoryDialog: false,
      productDialog: false,

      editedCategoryItem: {},
      editedProductItem: {},

      beingDeleted: {},
      confirmDialog: false,

      duplicateDialog: false,
      beingDuplicated: {},
      duplicatePromptDialog: false,

      photoItem: {
        uploading: false,
        _id: undefined,
        progress: 0,
        sheet: false,
      },

      cheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        //{ text: 'Parent category', value: 'parentName' },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      categories: [],

      defaultProductItem: {
        name: "",
        isActive: true,
        isComingSoon: false,
        inStock: true,
        expanded: false,
        parent: "",
        photos: [],
        tags: [],
        dimensionUnit: "cm",
      },

      pheaders: [
        {
          text: "#",
          align: "start",
          value: "index",
        },
        { text: "Name", value: "name" },
        { text: "Category", value: "categoryName" },
        //{ text: 'In stock?', value: 'inStock' },
        { text: "Quantity", value: "quantity" },
        { text: "Size", value: "sizeName", sortable: false },
        { text: "Price", value: "price" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "right", value: "actions", sortable: false },
      ],
      products: [],

      enums: {},
      selected: [],
      creatingVariant: false,

      massdialog: false,
      massprocessing: false,
      massItem: {},

      ec: {
        _id: "",
        type: "",
        value: "",
        index: 0,
        saving: false,
      },
      singleSelect: false,

      options: {},
      productsObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      growcifyMasterInventory: config.growcifyMasterInventory,

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => !!v || v === false || "It is required"],
        inlineRule: [(v) => !!v || ""],
        unitRule: [(v) => !!v || v === "" || "It is required"],
        numberRule: [(v) => !v || v >= 0 || "Must be a valid number"],
      },
    };
  },
};

import Vue from 'vue';
import store from '../store';

export default {
  posAccess() {
    return store.state.activeStore.posAccess || 
      ['buy-growcify-enterprise', 'pay-as-you-grow'].includes(store.state.activeStore.plan) || 
      (
        store.state.activeStore.isVendor && 
        store.state.activeStore.vendorModules.includes('POS') && 
        store.state.activeStore.vendorOwner.posAccess && 
        store.state.activeStore.vendorOwner.posVendors && 
        store.state.activeStore.vendorOwner.posVendors.includes(store.state.activeStore._id)
      );
  },

  getCategories(storeId, noProgress = true) {
    const isVendor = store.state.activeStore.isVendor;
    return Vue.axios.get(`category/list/${storeId}/pos${isVendor ? '-v' : ''}`, {noProgress: noProgress});
  },

  getProducts(storeId, noProgress = true) {
    const isVendor = store.state.activeStore.isVendor;
    return Vue.axios.get(`product/list/${storeId}/pos${isVendor ? '-v' : ''}`, {noProgress: noProgress});
  },

  placeOrder(_id, order, noProgress = true) {
    const isVendor = store.state.activeStore.isVendor;
    return Vue.axios.post(`order/pos${isVendor ? '-v' : ''}/${_id}`, order, {noProgress: noProgress});
  },

  editOrder(_id, order, noProgress = true) {
    const isVendor = store.state.activeStore.isVendor;
    return Vue.axios.put(`order/pos${isVendor ? '-v' : ''}/${_id}`, order, {noProgress: noProgress});
  },

  modifyOrder(_id, action, noProgress = true) {
    const isVendor = store.state.activeStore.isVendor;
    return Vue.axios.put(`order/pos${isVendor ? '-v' : ''}/${action}/${_id}`, {}, {noProgress: noProgress});
  },

  getPOSOrders(storeId, pageNumber, pageSize, keyword, filters, isVendor, noProgress = false) {
    let endpoint = `order/list/${isVendor ? 'pos-v' : 'pos'}/${storeId}/${pageNumber}/${pageSize}`;
    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.post(endpoint, filters, {noProgress: noProgress});
  },

  processPayment(_id, object, noProgress = true) {
    return Vue.axios.post(`store/pos/${_id}/process-payment`, object, {noProgress: noProgress});
  },

  getSettings(_id, noProgress = false) {
    return Vue.axios.get(`store/pos/${_id}/settings`, {noProgress: noProgress});
  },

  saveSettings(_id, object, noProgress = false) {
    return Vue.axios.put(`store/pos/${_id}/settings`, object, {noProgress: noProgress});
  }
}
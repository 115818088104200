import moment from 'moment';

import router from '../../../router';
import Super from '../../../services/super';

export default {
  name: 'Help',

  created() {
    this._id = this.$route.params._id;
    if (this.$store.state.activeStore.isVendor) return router.push('/business/'+this._id);

    this.fetchHelpdeskItems();
    this.fetchHelpdeskEnums();
  },

  methods: {
    fetchHelpdeskItems() {
      this.loading = true;
      return Super.getHelpdeskItemsActive('all', false)
        .then(res => {
          this.helpdeskItems = res.data.map((x, index) => {
            x.index = index + 1 + '.';
            x.show = false;
            x.publishedAt = moment(x.createdAt).format('lll');
            return x;
          });

          this.helpdeskItems.sort((a, b) => b.isPinned - a.isPinned);
          this.videos = this.helpdeskItems.filter(x => x.type === 'video');
          this.faqs = this.helpdeskItems.filter(x => x.type === 'faq');          
          this.activeFAQItems = JSON.parse(JSON.stringify(this.faqs));
          this.resetVideos();

          this.loading = this.firstTime = false;
        })
        .catch(e => { console.log(e); this.loading = false; });
    },

    resetVideos() {
      this.totalPages = Math.floor(this.videos.length / this.pageSize) + ((this.videos.length % this.pageSize > 0) ? 1 : 0);
      this.activeVideoItems = this.videos.slice(0, this.pageSize);
    },

    fetchHelpdeskEnums() {
      return Super.getHelpdeskEnums()
        .then(res => {
          this.enums = res.data;
          this.enums.modules = this.enums.modules.sort();
        })
        .catch(e => console.log(e));
    },
  },

  watch: {
    page(n) {
      if (this.firstTime) return;
      this.activeVideoItems = (this.modules.length ? this.filteredVideoItems : this.videos).slice((this.pageSize * (n-1)), this.pageSize * n);
    },

    modules(n) {
      if (this.type == 'video') {
        if (!n.length) return this.resetVideos();
        const filteredItems = [];
        this.videos.filter(x => {
          n.forEach(j => {
            if (x.modules.includes(j)) filteredItems.push(x);
          })
        });
        this.filteredVideoItems = filteredItems;
        this.totalPages = Math.floor(filteredItems.length / this.pageSize) + ((filteredItems.length % this.pageSize > 0) ? 1 : 0);
        this.activeVideoItems = filteredItems.slice(0, this.pageSize);
      } else {
        if (!n.length) return this.activeFAQItems = this.faqs;
        const filteredItems = [];
        this.faqs.filter(x => {
          n.forEach(j => {
            if (x.modules.includes(j)) filteredItems.push(x);
          })
        });
        this.activeFAQItems = filteredItems;
      }
    },

    search(n) {
      if (this.type == 'video') {
        if (!n) return this.resetVideos();

        const filteredItems = this.videos.filter(x => (x.title.toLowerCase().indexOf(n.toLowerCase()) > -1) || x.description.toLowerCase().indexOf(n.toLowerCase()) > -1);
        this.filteredVideoItems = filteredItems;
        this.totalPages = Math.floor(filteredItems.length / this.pageSize) + ((filteredItems.length % this.pageSize > 0) ? 1 : 0);
        this.activeVideoItems = filteredItems.slice(0, this.pageSize);
      } else {
        if (!n) return this.activeFAQItems = this.faqs;
        const filteredItems = this.faqs.filter(x => (x.title.toLowerCase().indexOf(n.toLowerCase()) > -1) || x.description.toLowerCase().indexOf(n.toLowerCase()) > -1);
        this.activeFAQItems = filteredItems;
      }
    }
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isGD() {
      return this.$store.state.isGD
    },
  },

  data() {
    return {
      input: '',
      search: '',
      type: 'video',
      loading: false,

      enums: {},
      suggestions: [],

      helpdeskItems: [],
      videos: [],
      faqs: [],

      activeVideoItems: [],
      activeFAQItems: [],
      filteredVideoItems: [],

      pageSize: 9,
      totalPages: 3,
      page: 1,

      firstTime: true,

      modules: []
    }
  }
}
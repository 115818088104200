export default {
  name: 'Footer',

  computed: {
    isDark() { 
      return this.$route.name === 'member-detail' || this.$route.name === 'careers';
    },

    hideMainFooter() {
      return this.$store.getters.authStatus;
    },

    getCurrentYear() {
      return new Date().getFullYear();
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },
}
<template>
  <v-dialog v-model="$store.state.pickupAddressModal" max-width="800px" persistent>
    <v-progress-linear
      v-if="processing"
      indeterminate
      color="primary"
      class="mb-0"
    ></v-progress-linear>
    <v-card>
      <v-card-title class="pl-10 pt-5">
        <span>{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" valid="valid">
            <b-row>
              <b-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  v-model="editedItem.name"
                  label="Name"
                  :disabled="processing"
                  :rules="validationRules.basicRule"
                ></v-text-field>
              </b-col>

              <b-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  required
                  v-model="editedItem.mobile"
                  label="Contact number"
                  :disabled="processing"
                  :rules="validationRules.basicRule"
                ></v-text-field>
              </b-col>

              <b-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  required
                  label="Email"
                  :disabled="processing"
                  v-model="editedItem.email"
                  :rules="validationRules.basicRule"></v-text-field>
              </b-col>
              
              <b-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  required
                  label="Street address line 1"
                  :disabled="processing"
                  v-model="editedItem.street"
                  :rules="validationRules.basicRule"></v-text-field>
              </b-col>

              <b-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  label="Street address line 2"
                  required
                  :disabled="processing"
                  v-model="editedItem.area"
                  :rules="validationRules.basicRule"></v-text-field>
              </b-col>
              
              <b-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  required
                  label="Nearby landmark"
                  :disabled="processing"
                  v-model="editedItem.landmark"
                  :rules="validationRules.basicRule"></v-text-field>
              </b-col>

              <b-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  required
                  label="Pincode"
                  :disabled="processing"
                  v-model="editedItem.pincode"
                  :rules="validationRules.basicRule"></v-text-field>
              </b-col>

              <b-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  required
                  label="City"
                  :disabled="processing"
                  v-model="editedItem.city"
                  :rules="validationRules.basicRule"></v-text-field>
              </b-col>

              <b-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  required
                  label="State"
                  :disabled="processing"
                  v-model="editedItem.state"
                  :rules="validationRules.basicRule"></v-text-field>
              </b-col>

              <b-col cols="12" sm="6" md="6">
                <v-text-field 
                  outlined 
                  required
                  label="Country"
                  :disabled="processing"
                  v-model="editedItem.country"
                  :rules="validationRules.basicRule"></v-text-field>
              </b-col>

              <!-- <v-col cols="12" sm="6" md="6">
                <v-select
                  required
                  outlined
                  v-model="editedItem.isPrimary"
                  :items="[{key: true, value: 'Yes'}, {key: false, value: 'No'}]"
                  label="Is primary?"
                  item-value="key"
                  item-text="value"
                  :disabled="processing"
                  :rules="validationRules.statusRule"
                ></v-select>
              </v-col> -->
              
              <v-col cols="12">
                <v-select
                  required
                  outlined
                  v-model="editedItem.isActive"
                  :items="[{key: true, value: 'Active'}, {key: false, value: 'Inactive'}]"
                  label="Status"
                  item-value="key"
                  item-text="value"
                  :disabled="processing"
                  :rules="validationRules.statusRule"
                ></v-select>
              </v-col>
            </b-row>

            <b-row v-if="error">
              <b-col cols="12" class="text-danger text-right">
                {{ error }}
              </b-col>
            </b-row>

            <v-row>
              <v-col class="text-right">
                <v-btn color="error" :disabled="processing" text @click="$store.state.pickupAddressModal = false">Cancel</v-btn>
                 <v-btn color="success" class="ml-2" :disabled="processing" outlined @click="save">Save</v-btn>
              </v-col>
            </v-row>

          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Store from '../../services/store';

export default {
  name: 'PickupAddress',

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add new pickup address' : 'Edit pick address';
    }
  },

  props: [
    '_id',
    'addressModified',
    'editedItem',
    'editedIndex'
  ],

  methods: {
    save(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      if (this.editedItem._id) request = Store.editPickupAddress(this.editedItem._id, this.editedItem, false)
      else request = Store.addPickupAddress(this._id, this.editedItem, false);
          
      return request 
        .then(response => {
          if (response.data && !response.data.ok) {
            const error = response.data.errors[Object.keys(response.data.errors)[0]][0];
            this.error = error || 'Something went wrong, please re-check details';
            Store.snackbarError();
            setTimeout(() => this.error = '', 2500);
          } else {
            if (closeModal) this.close();
            this.$emit('update:addressModified', true);
            Store.snackbarSuccess();
          }
          this.processing = this.editedItem.processing = false;
        })
        .catch(() => {
          this.error = 'Something went wrong, please re-check details';
          Store.snackbarError();
          setTimeout(() => this.error = '', 2500);
          this.processing = false;
          this.loading = false;
        });
    },

    close() {
      this.$store.state.pickupAddressModal = false;
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false, false);
    }
  },

  data() {
    return {
      processing: false,
      translationModal: false,
      translationField: '',
      error: '',

      validationRules: {
        basicRule: [
          v => !!v || 'It can not be empty'
        ],
        statusRule: [
          v => v || v === false || 'Status is required'
        ]
      }
    }
  }
}
</script>
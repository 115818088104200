import Vue from "vue";

export default {
  getTransaction(storeId, noProgress = true) {
    return Vue.axios.get(`transaction/list/${storeId}`, {
      noProgress: noProgress,
    });
  },

  getPaginatedTransactions(
    storeId,
    pageNumber,
    pageSize,
    keyword,
    tabID,
    noProgress = true
  ) {
    let endpoint = `transaction/list/${storeId}/${tabID}/${pageNumber}/${pageSize}`;
    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.get(endpoint, { noProgress: noProgress });
  },

  createTransaction(_id, transaction, noProgress = true) {
    return Vue.axios.post(`transaction/${_id}`, transaction, {
      noProgress: noProgress,
    });
  },

  editTransaction(_id, transaction, noProgress = true) {
    return Vue.axios.put(`transaction/${_id}`, transaction, {
      noProgress: noProgress,
    });
  },

  deleteTransaction(_id, noProgress = true) {
    return Vue.axios.delete(`transaction/${_id}`, { noProgress: noProgress });
  },

  getEnums(_id, noProgress = false) {
    return Vue.axios.get(`transaction/enums/${_id}`, {
      noProgress: noProgress,
    });
  },
};

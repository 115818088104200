import Subscription from "../../services/subscription";
import Store from "../../services/store";
import User from "../../services/user";
import moment from "moment";
import { generateSchedule } from "../../helpers/util";
import Inventory from "../../services/inventory";

export default {
  name: "SubscriptionForm",

  props: ["_id", "cenums", "customers", "editedItem", "itemUpdated"],

  methods: {
    close() {
      this.rendered = false;
      this.$store.state.subscriptionModal = false;
    },

    fetchAddresses() {
      this.addressLoading = true;
      return User.getAddresses(
        this._id,
        this.editedItem.user._id || this.editedItem.user,
        false
      )
        .then((res) => {
          this.addresses = res.data;
          this.addressLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.addressLoading = false;
        });
    },

    addressText(item) {
      let text = "";
      if (!item) return text;
      if (item.name) text += item.name;
      if (item.mobile) text += " (" + this.$parent.getMobile(item) + ")";
      if (item.street) text += ", " + item.street;
      if (item.area) text += ", " + item.area;
      if (item.landmark) text += ", " + item.landmark;
      if (item.city) text += ", " + item.city;
      if (item.state) text += ", " + item.state;
      if (item.pincode) text += " - " + item.pincode;

      return text;
    },

    productText(item) {
      if (!item) return "";

      return (
        item.name +
        " : " +
        item.size +
        item.unit +
        " : " +
        this.$options.filters.fc(item.price)
      );
    },

    amountFormatted(amount) {
      let formatted = amount;
      const currencyCode = this.currencyCode;

      if (currencyCode === "INR") {
        formatted = this.currencyPipe(amount, "₹");
      } else if (currencyCode == this.country.currencyCode) {
        formatted = this.currencyPipe(amount, this.country.currencySymbol);
      } else if (currencyCode && currencyCode !== "INR") {
        formatted = this.currencyPipe(amount, currencyCode);
      } else if (!this.country.currencyCode && !currencyCode) {
        formatted = this.currencyPipe(amount, "₹");
      } else {
        formatted = this.currencyPipe(amount, this.country.currencySymbol);
      }

      return formatted;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    getUserContracts() {
      if (!this.editedItem.user || !this.isV2) return;

      this.cloading = true;
      const userId = this.editedItem.user._id || this.editedItem.user;
      return Store.getUserContractsItems(this._id, userId, false)
        .then((x) => {
          this.cloading = false;
          if (x && x.data) this.contractItems = x.data;
        })
        .catch((e) => {
          console.log(e);
          this.cloading = false;
        });
    },

    fetchSubscribableProducts() {
      this.isSearching = true;
      return Inventory.getSubscribableProducts(this._id, false)
        .then((x) => {
          this.products = x.data;
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    },

    productSelected() {
      if (!this.selectedProduct) return;
      const item = this.selectedProduct;
      const items = this.editedItem.items || [];

      const added = items.find((x) => x._id == item._id);
      if (added) return;

      const object = {
        _id: item._id,
        name: item.name,
        size: item.size,
        unit: item.unit,
        price: item.price,
        qty: 1,
      };
      if (item.gst) object.gst = item.gst;

      const citems = this.contractItems;
      if (citems && citems.length) {
        const conItem = citems.find((x) => x._id == item._id);
        if (conItem) object.price = conItem.price;
      }

      items.push(object);
      this.editedItem.items = items;
      this.selectedProduct = {};
    },

    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    formatDate(date) {
      return moment(date).format("ddd, ll");
    },

    save() {
      if (!this.$refs.form.validate()) return;

      const object = Object.assign({}, this.editedItem);
      object.user = object.user._id || object.user;

      if (this.isHD || object.address) {
        object.address = object.address._id || object.address;
      }

      // for old subscriptions
      if (this.isV2) {
        object.total = this.totalAmount;
        object.perDeliveryTotal = this.perDeliveryTotal;
      } else {
        object.total = this.totalAmountOld;
        object.perDeliveryTotal = this.perDeliveryTotalOld;
        object.product = object.product._id || object.product;
      }

      Store.snackbarStart();
      this.processing = true;
      let request;

      if (!this.isNew) {
        request = Subscription.editSubscription(
          this.editedItem._id,
          object,
          false
        );
      } else {
        request = Subscription.createSubscription(this._id, object, false);
      }

      return request
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();
          this.close();
          this.$emit("update:itemUpdated", true);
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    processSchedule() {
      if (!this.rendered) return;

      const cycle = this.editedItem.cycle;
      const period = this.editedItem.period;
      const endDate = this.editedItem.endDate;
      const startDate = this.editedItem.startDate;
      if (!startDate || !endDate || !cycle || !period) return;

      const oldSchedule = this.isNew ? [] : this.editedItem.schedule;
      const schedule = generateSchedule(startDate, endDate, cycle, oldSchedule);
      this.editedItem.schedule = schedule;
    },

    removeItemsItem(item) {
      const items = this.editedItem.items;
      const filtered = items.filter((x) => x._id !== item._id);
      this.editedItem.items = filtered;
    },
  },

  computed: {
    formTitle() {
      return this.editedItem._id ? "Edit Subscription" : "Create Subscription";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    isHD() {
      return this.editedItem.deliveryMode === "home-delivery";
    },

    isNew() {
      return !this.editedItem._id;
    },

    store() {
      return this.$store.state.activeStore;
    },

    country() {
      return this.$store.state.country;
    },

    currencyCode() {
      return this.editedItem.currencyCode || this.country.currencyCode;
    },

    currencyPrefix() {
      return this.currencyCode === "INR"
        ? "₹"
        : this.currencyCode === this.country.currencyCode
        ? this.country.currencySymbol
        : this.currencyCode;
    },

    computedStartDate() {
      return this.editedItem.startDate
        ? moment(this.editedItem.startDate).format("dddd, ll")
        : "";
    },

    computedEndDate() {
      return this.editedItem.endDate
        ? moment(this.editedItem.endDate).format("dddd, ll")
        : "";
    },

    hasItems() {
      return !!(this.editedItem.items && this.editedItem.items.length);
    },

    isV2() {
      return this.isNew || this.hasItems;
    },

    deliveryCharge() {
      return this.editedItem.deliveryCharge;
    },

    perDeliveryTotalOld() {
      return (
        this.editedItem.qty * this.editedItem.price +
        +(this.deliveryCharge || 0)
      );
    },

    totalAmountOld() {
      return this.perDeliveryTotalOld * this.deliveries;
    },

    itemsTotal() {
      const items = this.editedItem.items || [];
      return items.reduce((a, b) => (a += b.price * b.qty), 0);
    },

    perDeliveryTotal() {
      return this.itemsTotal + +(this.deliveryCharge || 0);
    },

    totalAmount() {
      return this.perDeliveryTotal * this.deliveries;
    },

    deliveries() {
      return this.editedItem.schedule ? this.editedItem.schedule.length : 0;
    },

    formattedSchedule() {
      if (!this.editedItem || !this.editedItem.schedule) return [];
      return this.editedItem.schedule.map((item, index) => {
        item.index = index + 1 + ".";
        return item;
      });
    },
  },

  watch: {
    "$store.state.subscriptionModal"(n) {
      if (n) {
        if (this.editedItem._id) {
          this.fetchAddresses();

          this.selectedProduct = this.editedItem.product;
          this.editedItem.startDate = this.editedItem.startDate.split("T")[0];
          this.editedItem.endDate = this.editedItem.endDate.split("T")[0];
        } else {
          const subDC = this.store.subscriptionDeliveryCharge;
          if (subDC > 0) this.editedItem.deliveryCharge = subDC;
        }
      }

      setTimeout(() => {
        this.rendered = true;
      }, 2000);
    },

    searchInput(n) {
      if (this.products.length) return;
      if (n) this.fetchSubscribableProducts();
    },

    "editedItem.user"(n) {
      if (n) this.getUserContracts();
    },

    "editedItem.startDate"() {
      this.processSchedule();
    },

    "editedItem.endDate"() {
      this.processSchedule();
    },

    "editedItem.cycle"() {
      this.processSchedule();
    },

    "editedItem.period"() {
      this.processSchedule();
    },
  },

  data() {
    return {
      cloading: false,
      processing: false,
      addressLoading: false,
      addresses: [],
      contractItems: [],

      menu: false,
      menu1: false,
      success: false,
      date: "",
      selectedProduct: {},
      searchInput: "",
      isSearching: false,
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,
      lastSearched: "",
      products: [],
      rendered: false,

      dsheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Day & Date", value: "dayDate" },
        { text: "Status", value: "status" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v === false || v == true || "It is required"],

        amountRule: [(v) => v >= 0 || "Enter a valid amount"],

        selectedProduct: [(v) => (v && !!v._id) || "Please select a product"],
      },
    };
  },
};

import moment from 'moment'; 
import _ from 'underscore';

import Store from '../../services/store';
import Super from '../../services/super';
import Meeting from '../../services/meeting';

import LeadTaskHistoryItems from '../../components/lead-task-history-items/lead-task-history-items.vue';

export default {
  name: 'LeadDetail',

  props: [
    'fl',
    'lead',
    'users',
    'enums',
    'sendMessage',
    'leadModified',
    'messageSent',
    'callCreated',
    'meetingScheduled'
  ],

  components: {
    LeadTaskHistoryItems
  },

  watch: {
    '$store.state.leadDetailModal'(n) {
      if (n) {
        //find call logs; meetings etc
        this.calls = [];
        this.meetings = [];
        this.smsHistory = [];

        if (this.lead.callback) this.lead.callback = new Date(this.lead.callback);
        if (this.lead.demoAt) this.lead.demoAt = new Date(this.lead.demoAt);

        //now fetch latest
        this.fetchMeetingsHistory();
        this.fetchSMSHistory();
        this.fetchCallsHistory();

        setTimeout(() => this.lfl = true, 3000);
      } else {
        this.lfl = false;
      }
    },

    'lead.status'(n) {
      if (this.fl && n && this.lfl) this.save(); 
    },

    'lead.assignedTo'(n) {
      if (this.fl && n && this.lfl) this.save(); 
    },

    'lead.callback':  _.debounce(function(n) {
      if (this.fl && n && this.lfl) this.save(); 
    }, 500),
    
    'lead.demoAt':  _.debounce(function(n) {
      if (this.fl && n && this.lfl) this.save(); 
    }, 500),

    'lead.chances':  _.debounce(function(n) {
      if (this.fl && n && this.lfl) this.save(); 
    }, 800),

    'lead.remarks':  _.debounce(function(n) {
      if (this.fl && n && this.lfl) this.save(); 
    }, 2000),

    'lead.city':  _.debounce(function(n) {
      if (this.fl && n && this.lfl) this.save(); 
    }, 1000),

    meetingScheduled(n) {
      if (n && this.$store.state.leadDetailModal) {
        this.fetchMeetingsHistory();
        this.fetchSMSHistory();
      }
    },

    messageSent(n) {
      if (n && this.$store.state.leadDetailModal) this.fetchSMSHistory();
    },

    callCreated(n) {
      if (n && this.$store.state.leadDetailModal) this.fetchCallsHistory();
    }
  },

  methods: {
    save() {
      this.processing = true;
      Store.snackbarStart();

      const object = Object.assign({}, this.lead);
      return Super.editLead(this.lead._id, object, false) 
        .then(() => {
          this.processing = false;
          this.$emit('update:leadModified', true);
          Store.snackbarSuccess();
        })
        .catch(() => {
          Store.snackbarError();
          this.processing = false;
        });
    },

    fetchMeetingsHistory() {
      this.mloading = true;
      return Meeting
        .getLeadMeetings(this.lead._id, false)
        .then(x => {
          this.meetings = x.data.map((x, index) => { x.index = index + 1 + '.'; return x; });
          this.mloading = false;
        })
        .catch(e => {
          console.log(e);
          this.mloading = false;
        });
    },

    fetchSMSHistory() {
      this.sloading = true;
      return Super
        .getLeadSMSHistory(this.lead._id, false)
        .then(x => {
          this.smsHistory = x.data.map((x, index) => { x.index = index + 1 + '.'; return x; });
          this.sloading = false;
        })
        .catch(e => {
          console.log(e);
          this.sloading = false;
        });
    },

    fetchCallsHistory() {
      this.cloading = true;
      return Super
        .getLeadCalls(this.lead._id, false)
        .then(x => {
          this.calls = x.data.map((x, index) => { x.index = index + 1 + '.'; return x; });
          this.cloading = false;
        })
        .catch(e => {
          console.log(e);
          this.cloading = false;
        });
    },

    formatDate(date, format = 'lll') {
      return moment(date).format(format);
    }
  },

  computed: {
    callItems() {
      return this.calls.map(x => { x.calledByName = x.calledBy ? x.calledBy.name : ''; return x; })
    },

    amIGrowcifyEmployee() {
      return Store.amIGrowcifyEmployee(this.$store)
    },

    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  data() {
    return {
      processing: false,
      mloading: false,
      sloading: false,
      cloading: false,
      lfl: false,

      calls: [],
      meetings: [],
      smsHistory: [],

      meetingColoumns: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', align: 'start', value: 'lead.name' },
        { text: 'Title',  align: 'start', value: 'summary' },
        { text: 'Attendees', value: 'attendees'},
        { text: 'Scheduled At', value: 'start' },
        { text: 'Scheduled By', value: 'scheduledByName'},
        { text: 'Created At', value: 'createdAt' },
        //{ text: 'Actions', align: 'center', value: 'actions', sortable: false, width: '12%'}
      ],

      smsColumns: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', align: 'start', value: 'lead.name' },
        { text: 'Type', value: 'type' },
        { text: 'From',  align: 'start', value: 'sentFrom' },
        { text: 'To',  align: 'start', value: 'sentTo' },
        { text: 'Message', value: 'message' },
        { text: 'Sent By', value: 'sentByName'},
        { text: 'Sent At', value: 'createdAt' },
      ],

      callColumns: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', align: 'start', value: 'lead.name' },
        { text: 'Type', value: 'type' },
        { text: 'From',  align: 'start', value: 'calledFrom' },
        { text: 'To',  align: 'start', value: 'calledTo' },
        { text: 'Duration',  align: 'start', value: 'duration' },
        { text: 'Remarks', value: 'remarks' },
        { text: 'Called By', value: 'calledByName'},
        { text: 'Called At', value: 'createdAt' },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ]
    }
  }
}
<template>
  <v-dialog
    fullscreen
    scrollable
    v-model="couriersDialog"
    max-width="1400"
    persistent
  >
    <v-progress-linear
      v-if="processing"
      indeterminate
      color="primary"
      class="mb-0"
    ></v-progress-linear>
    <v-card>
      <v-card-text style="padding-top: 15px;">
        <v-row style="color: black;">
          <v-col cols="2" class="vc">
            <h5><b>Pick a courier parnter</b></h5>
            <div>Choose from available couriers</div>
          </v-col>

          <v-col class="text-center vc">
            <div class="citem mt-0" style="cursor: auto">
              <v-row>
                <v-col cols="5" class="vc" style="padding: 0">
                  <h6 style="margin-top: 0">
                    <b
                      >{{ $parent.selectedPickupAddress.pincode }},
                      {{ $parent.selectedPickupAddress.state }}</b
                    >
                  </h6>
                  <p class="pi-title" style="margin-bottom: 0">
                    Pickup Pincode
                  </p>
                </v-col>

                <v-col cols="2" class="vc" style="padding: 0">
                  <v-icon
                    style="color: black;"
                    class="sm-icon"
                    v-if="$parent.selectedCourier.is_surface"
                    >mdi-bus</v-icon
                  >
                  <v-icon style="color: black;" class="sm-icon" v-else
                    >mdi-airplane-takeoff</v-icon
                  >
                </v-col>

                <v-col
                  cols="5"
                  v-if="$parent.order.address"
                  class="vc"
                  style="padding: 0"
                >
                  <h6 style="margin-top: 0">
                    <b
                      >{{ $parent.order.address.pincode }},
                      {{ $parent.order.address.state }}</b
                    >
                  </h6>
                  <p class="pi-title" style="margin-bottom: 0">
                    Delivery Pincode
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col cols="2" class="text-center vc">
            <div class="citem mt-0" style="cursor: auto">
              <h6 style="margin-top: 0">
                <b
                  >{{
                    $parent.order.package.weight >
                    $parent.order.package.vmWeight
                      ? $parent.order.package.weight
                      : $parent.order.package.vmWeight
                  }}kg</b
                >
              </h6>
              <p class="pi-title" style="margin-bottom: 0">Applied Weight</p>
            </div>
          </v-col>

          <v-col
            v-if="$parent.order.transaction"
            cols="2"
            class="text-center vc"
          >
            <div class="citem mt-0" style="cursor: auto">
              <h6 style="margin-top: 0">
                <b>{{ $parent.order.amount | currency("₹") }}</b>
              </h6>
              <p class="pi-title" style="margin-bottom: 0">
                Order Amount ({{
                  $parent.order.transaction.paymentStatus === "completed"
                    ? "Prepaid"
                    : "COD"
                }})
              </p>
            </div>
          </v-col>

          <v-col
            v-if="shipmentWallet && shipmentWallet.balance"
            cols="2"
            class="text-right vc"
          >
            <div class="citem mt-0" style="cursor: auto">
              <h6 style="margin-top: 0">
                <b>{{ shipmentWallet.balance | currency("₹") }}</b>
              </h6>
              <p class="pi-title" style="margin-bottom: 0">
                Shipment Wallet Balance
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="!allCouriers.length">
          <v-col cols="12">
            <div class="no-couriers">
              <span class="text-danger"
                >We're sorry! We could not find any courier parnter available
                for the delivery address pincode.</span
              >
            </div>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="12">
            <div class="filters-sorting">
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-model="search"
                    clearable
                    label="Search"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-select
                    outlined
                    v-model="mode"
                    :items="['All', 'Air', 'Surface']"
                    label="Shipment mode"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    outlined
                    v-model="sortBy"
                    :items="['Name', 'Cheapest', 'Expensive', 'Best Rated']"
                    label="Sort by"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    style="min-height: 56px;"
                    block
                    @click="resetFilters"
                    x-large
                    outlined
                    color="error"
                    >RESET</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col
            v-for="courier of couriers"
            :key="courier.courier_company_id"
            cols="12"
          >
            <div
              @click="selectCourier(courier)"
              :class="[
                'courier-item',
                isSelected(courier) ? 'courier-item-selected' : 'hoverable',
              ]"
            >
              <v-row>
                <v-col cols="3" class="vc">
                  <img
                    :src="growcifyS3 + 'couriers/' + courier.logo"
                    height="85"
                    style="margin-bottom: 20px;"
                  />
                  <h6 class="courier-heading">{{ courier.courier_name }}</h6>
                  <span
                    >Minimum weight: <b>{{ courier.min_weight }}kg</b></span
                  >
                  <div>
                    <span
                      >Return to origin charges:
                      <b>{{ courier.rto_charges | currency("₹") }}</b></span
                    >
                  </div>
                </v-col>

                <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                  <v-row>
                    <v-col cols="12" style="padding-top: 0; padding-bottom: 0">
                      <v-row style="border-bottom: 1px solid;">
                        <v-col cols="2" class="text-center vc">
                          <v-icon
                            class="sm-icon"
                            :color="isSelected(courier) ? 'black' : ''"
                            v-if="courier.is_surface"
                            >mdi-bus</v-icon
                          >
                          <v-icon
                            class="sm-icon"
                            :color="isSelected(courier) ? 'black' : ''"
                            v-else
                            >mdi-airplane-takeoff</v-icon
                          >
                          <div class="mt-2">
                            <small
                              ><b>Shipment<br />Mode</b></small
                            >
                          </div>
                        </v-col>

                        <v-col cols="2" class="text-center vc">
                          <v-progress-circular
                            :size="50"
                            v-if="courier.pickup_performance"
                            :value="getValue(courier.pickup_performance)"
                            :color="
                              getProgressBarClass(courier.pickup_performance)
                            "
                          >
                            {{ courier.pickup_performance.toFixed(1) }}
                          </v-progress-circular>
                          <v-progress-circular
                            v-else
                            :size="50"
                            :value="0"
                            color="light"
                            >NA</v-progress-circular
                          >
                          <div class="mt-2">
                            <small
                              ><b>Pickup<br />Performance</b></small
                            >
                          </div>
                        </v-col>

                        <v-col cols="2" class="text-center vc">
                          <v-progress-circular
                            :size="50"
                            v-if="courier.delivery_performance"
                            :value="getValue(courier.delivery_performance)"
                            :color="
                              getProgressBarClass(courier.delivery_performance)
                            "
                          >
                            {{ courier.delivery_performance.toFixed(1) }}
                          </v-progress-circular>
                          <v-progress-circular
                            v-else
                            :size="50"
                            :value="0"
                            color="light"
                            >NA</v-progress-circular
                          >
                          <div class="mt-2">
                            <small
                              ><b>Delivery<br />Performance</b></small
                            >
                          </div>
                        </v-col>

                        <v-col cols="2" class="text-center vc">
                          <v-progress-circular
                            v-if="courier.rto_performance"
                            :size="50"
                            :value="getValue(courier.rto_performance)"
                            :color="
                              getProgressBarClass(courier.rto_performance)
                            "
                          >
                            {{ courier.rto_performance.toFixed(1) }}
                          </v-progress-circular>

                          <v-progress-circular
                            v-else
                            :size="50"
                            :value="0"
                            color="light"
                            >NA</v-progress-circular
                          >
                          <div class="mt-2">
                            <small
                              ><b>RTO<br />Performance</b></small
                            >
                          </div>
                        </v-col>

                        <v-col cols="2" class="text-center vc">
                          <v-progress-circular
                            :size="50"
                            v-if="courier.weight_cases"
                            :value="getValue(courier.weight_cases)"
                            :color="getProgressBarClass(courier.weight_cases)"
                          >
                            {{ courier.weight_cases.toFixed(1) }}
                          </v-progress-circular>
                          <v-progress-circular
                            v-else
                            :size="50"
                            :value="0"
                            color="light"
                            >NA</v-progress-circular
                          >
                          <div class="mt-2">
                            <small
                              ><b>Weight<br />Cases</b></small
                            >
                          </div>
                        </v-col>

                        <v-col cols="2" class="text-center vc">
                          <v-progress-circular
                            :size="50"
                            width="7"
                            v-if="courier.rating"
                            :value="getValue(courier.rating)"
                            :color="getProgressBarClass(courier.rating)"
                          >
                            {{ courier.rating.toFixed(1) }}
                          </v-progress-circular>
                          <v-progress-circular
                            v-else
                            :size="50"
                            :value="0"
                            color="light"
                            >NA</v-progress-circular
                          >
                          <div class="mt-2">
                            <small
                              ><b>Overall<br />Rating</b></small
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12">
                      <v-row>
                        <v-col style="padding-top: 0; padding-bottom: 0">
                          <v-row>
                            <v-col
                              style="padding: 0"
                              cols="4"
                              class="text-center"
                            >
                              <span
                                ><b>{{ courier.call_before_delivery }}</b></span
                              >
                              <div><small>Call Before Delivery</small></div>
                            </v-col>
                            <v-col
                              style="padding: 0"
                              cols="4"
                              class="text-center"
                            >
                              <span
                                ><b>{{ courier.delivery_boy_contact }}</b></span
                              >
                              <div><small>Delivery Boy Number</small></div>
                            </v-col>
                            <v-col
                              style="padding: 0"
                              cols="4"
                              class="text-center"
                            >
                              <span
                                ><b>{{ courier.realtime_tracking }}</b></span
                              >
                              <div><small>Tracking Service</small></div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="3" class="vc">
                  <div
                    :class="[
                      isSelected(courier)
                        ? 'courier-item-right-selected'
                        : 'courier-item-right',
                    ]"
                  >
                    <v-row>
                      <v-col>
                        <h4 style="margin-bottom: 5px;">
                          <b>{{ courier.rate | currency("₹") }}</b>
                        </h4>
                        <div>
                          Frieght charges:
                          <b>{{ courier.freight_charge | currency("₹") }}</b>
                        </div>

                        <div>
                          Expected pickup:
                          <b
                            >{{
                              courier.pickup_availability == 2
                                ? "Monday"
                                : courier.pickup_availability == 1
                                ? "Today"
                                : "Tomorrow"
                            }}
                          </b>
                        </div>

                        <div>
                          Estimated delivery: <b>{{ courier.etd }}</b>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <div v-if="isSelected(courier)" class="selected-badge"></div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        :class="['courier-actions', processing ? 'loading-processing' : '']"
      >
        <v-row style="margin: 0 !important">
          <v-col v-if="processing" cols="12" style="padding: 0;">
            <v-progress-linear
              height="2"
              indeterminate
              color="primary"
              class="mb-0"
            ></v-progress-linear>
          </v-col>

          <v-col
            style="padding-top: 0;"
            cols="1"
            :class="['text-left', 'vc', processing ? 'mt-13 marleft-8' : '']"
          >
            <v-btn
              :disabled="processing"
              color="error"
              outlined
              @click="$parent.cancelAtCouriersDialog"
              >Cancel</v-btn
            >
          </v-col>
          <v-col
            style="padding-top: 0; padding-bottom: 0;"
            :class="['text-right', processing ? 'mt-13 marright-8' : '']"
          >
            <v-row>
              <v-col style="padding-top: 0" cols="9" class="vc">
                <div v-if="error">
                  <small class="text-danger" v-html="error"></small>
                </div>
                <div
                  v-else-if="
                    $parent.selectedCourier.courier_name &&
                      shipmentWallet &&
                      shipmentWallet.balance
                  "
                >
                  <small
                    v-if="
                      $parent.selectedCourier.rate <= shipmentWallet.balance
                    "
                    class="text-success"
                    >You've selected
                    <b>{{ $parent.selectedCourier.courier_name }}</b> courier
                    and its shipping rate is
                    <b>{{ $parent.selectedCourier.rate | currency("₹") }}</b
                    >.</small
                  >
                  <small v-else class="text-danger">
                    Your current shipment wallet balance is
                    <b>{{ shipmentWallet.balance | currency("₹") }}</b> but
                    <b>{{ $parent.selectedCourier.courier_name }}</b
                    >'s rate is
                    <b>{{ $parent.selectedCourier.rate | currency("₹") }}</b
                    >.
                  </small>
                </div>
              </v-col>
              <v-col style="padding-top: 0" class="vc">
                <v-btn
                  :disabled="processing"
                  text
                  @click="$parent.previousFromCouriersModal"
                  >Previous</v-btn
                >
                <v-btn
                  class="ml-2"
                  style="margin-right: 15px;"
                  outlined
                  color="primary"
                  :disabled="
                    processing ||
                      !$parent.selectedCourier.courier_name ||
                      $parent.selectedCourier.rate > shipmentWallet.balance
                  "
                  @click="$parent.continueFromCouriersModal"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.courier-item {
  background: var(--tertiary);
  padding: 5px 15px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid var(--tertiary);
}
.courier-item-selected {
  background: rgba(0, 0, 0, 0.18);
  border: 1px solid var(--primary);
  color: black;
}
.hoverable:hover {
  background: rgba(0, 0, 0, 0.18);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border: 1px solid rgba(0, 0, 0, 0.18);
}
.courier-heading {
  font-weight: bold;
  margin-bottom: 5px;
}
.sm-icon {
  font-size: 30px !important;
}
.courier-item-right {
  background: rgba(0, 0, 0, 0.18);
  min-height: 208px;
  margin-top: -22px;
  margin-bottom: -22px;
  margin-right: -15px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 15px;
  color: black;
  padding-top: 14.9%;
  border-left: 1px solid var(--tertiary);
}
.courier-item-right-selected {
  min-height: 208px;
  margin-top: -22px;
  margin-bottom: -22px;
  margin-right: -15px;
  padding-left: 15px;
  padding-top: 14.9%;
  border-left: 1px solid white;
}
.selected-badge {
  position: absolute;
  top: 12px;
  background: var(--primary);
  left: 15px;
  border-top-left-radius: 12px;
  padding: 15px;
  border-bottom-right-radius: 50px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
.no-couriers {
  min-height: 100px;
  padding-top: 7%;
  text-align: center;
}
.courier-actions {
  padding-top: 15px;
  padding-bottom: 5px;
  border-top: 1.5px solid rgba(0, 0, 0, 0.18);
}
.loading-processing {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.mt-13 {
  margin-top: 15px;
}
.marleft-8 {
  margin-left: 8px;
}
.marright-8 {
  margin-right: 8px;
}
</style>

<script type="text/javascript">
import config from "../../config";

export default {
  props: [
    "couriersDialog",
    "couriers",
    "allCouriers",
    "processing",
    "selectedCourier",
    "shipmentWallet",
    "error",
  ],

  watch: {
    selected() {
      this.$emit("update:selectedCourier", this.selected[0] || {});
    },

    search(value) {
      if (value) {
        const filteredCouriers = this.couriers.filter((x) => {
          return x.courier_name.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
        this.$emit("update:couriers", filteredCouriers);
      } else {
        this.$emit("update:couriers", this.allCouriers);
      }
      this.sortCouriers(this.sortBy);
    },

    mode(value) {
      if (value && value !== "All") {
        const array = this.search ? this.couriers : this.allCouriers;
        const filteredCouriers = array.filter((x) => {
          return value === "Surface" ? x.is_surface : !x.is_surface;
        });
        this.$emit("update:couriers", filteredCouriers);
      } else {
        this.$emit("update:couriers", this.allCouriers);
      }
      this.sortCouriers(this.sortBy);
    },

    sortBy(value) {
      this.sortCouriers(value);
    },

    couriersDialog(n) {
      if (n) this.sortBy = "Best Rated";
    },
  },

  methods: {
    sortCouriers(value) {
      if (value === "Best Rated")
        this.couriers.sort((a, b) => a.rating < b.rating);
      else if (value === "Cheapest")
        this.couriers.sort((a, b) => a.rate > b.rate);
      else if (value === "Expensive")
        this.couriers.sort((a, b) => a.rate < b.rate);
      else if (value === "Name")
        this.couriers.sort((a, b) => a.courier_name > b.courier_name);
      else this.couriers.sort((a, b) => a.courier_name > b.courier_name);
    },

    selectCourier(item) {
      if (
        this.$parent.selectedCourier.courier_company_id ===
        item.courier_company_id
      )
        this.selected = [];
      else this.selected = [item];
    },

    getValue(value) {
      return (value * 100) / 5;
    },

    isSelected(courier) {
      return (
        courier.courier_company_id ===
        this.$parent.selectedCourier.courier_company_id
      );
    },

    getProgressBarClass(value) {
      let className = "teal";
      if (value >= 4) className = "success";
      else if (value >= 2) className = "warning";
      else className = "error";
      return className;
    },

    resetFilters() {
      this.mode = "All";
      this.search = "";
      this.sortBy = "Best Rated";
    },
  },

  data() {
    return {
      search: "",
      sortBy: "Best Rated",
      mode: "All",
      rowItems: config.rowItems,
      selected: [],

      growcifyS3: config.growcifyS3,
    };
  },
};
</script>

export default {
  name: 'DynamicPriceDialog',

  props: [
    'dialog',
    'cenums',
    'processing',
    'editedItem'
  ],

  methods: {
    save() {
      this.editedItem.pricing = this.pricing;
      this.$parent.toggleDynamicPrice();
    },

    pushRule() {
      this.pricing.push(JSON.parse(JSON.stringify(this.dpObject)));
    },

    removeItem() {
      this.pricing.pop();
    }
  },

  watch: {
    dialog(n) {
      if (n) {
        this.pricing = JSON.parse(JSON.stringify([]));

        if (this.editedItem.pricing) this.pricing = this.editedItem.pricing;
        this.dpObject.price = this.editedItem.price || 0;
      }
    }
  },

  data() {
    return {
      pricing: [],
      dpObject: { gqty: 0 },

      validationRules: {
        basicRule: [
          v => v >= 0 || 'Enter valid number'
        ]
      }
    }
  }
}
import _ from 'underscore';
import Store from '../../../services/store';
import Global from '../../../services/global';
import AppColorPicker from '../../../components/app-color-picker/app-color-picker.vue';

export default {

  components: {
    AppColorPicker
  },

  created() {
    if (!this.$store.state.isGD) return Store.redirectToHome();
  },

  watch: {
    group () {
      this.drawer = false
    },

    'store.plan' (n) {
      if (n === 'rent-android-admin') {
        this.store.ownership = 'rent';
        this.store.apps = ['Android'];
      } else if (n === 'rent-android-ios-admin' || n === 'rent-everything' || n === 'rent-growcify-premium') {
        this.store.ownership = 'rent';
        this.store.apps = ['Android', 'iOS']
      } else if (n === 'buy-android-admin') {
        this.store.ownership = 'buy';
        this.store.apps = ['Android'];
      } else if ([
        'buy-android-ios-admin', 
        'buy-everything', 
        'buy-growcify-premium', 
        'buy-growcify-premium-singlevendor', 
        'buy-growcify-enterprise',
        'pay-as-you-grow'].includes(n)) {
        this.store.ownership = 'buy';
        this.store.apps = ['Android', 'iOS']
      }

      this.setBusinessPlan(n);
    },

    'store.country'(n) {
      if (n) {
        this.selectedCountry = this.countries.find(x => x._id == n);
        if (this.store.plan) this.setBusinessPlan(this.store.plan)
      }
    },

    'store.gstn': _.debounce(function(n) {
      if (n) return this.verifyGSTN();
    }, 750),
  },

  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;
      this.serverSubmission();
    },

    calculatePrice() {
      if (!this.$refs.form.validate()) return;
      this.showPrice = true;
    },

    getAmount(amount) {
      this.store.amount = amount;
      return amount;
    },

    setBusinessPlan(planId) {
      const plan = this.enums.plans.find(x => x._id === planId);
      if (this.selectedCountry.currencyCode == 'INR') {
        this.store.amount = plan[this.selectedCountry.currencyCode] ||  plan.price;
        this.store.currencyCode = this.selectedCountry.currencyCode;

        const taxAmount = Global.getTaxAmount(this.store.amount);
        this.store.amount = taxAmount;
      } else {
        this.store.amount = plan['USD'];
        this.store.currencyCode = 'USD';
      }

      this.store.razorpayPlanId = plan.razorpayPlanId;
    },

    serverSubmission() {
      this.progress = true;
      return Store.createNewStore(this.store)
        .then(x => {
          this.progress = false;
          this.$router.push(`/business/${x.data._id}/`);
        })
        .catch(e => {
          console.log(e);
          this.progress = false;
        })
    },

    text(item) {
      if (['rent-android-admin', 'rent-android-ios-admin', 'rent-everything', 'rent-growcify-premium'].includes(item._id))
        return item.name;
      else return item.name;
    },

    getPrice(item) {
      let obj = { price: 0, symbol: '' };
      if (this.selectedCountry.currencyCode == 'INR') { 
        obj.price = item[this.selectedCountry.currencyCode];
        obj.symbol = this.selectedCountry.currencySymbol;
      } else {
        obj.price = item['USD'];
        obj.symbol = '$';
      }

      return obj
    },

    priceText(item) {
      if (['rent-android-admin', 'rent-android-ios-admin', 'rent-everything', 'rent-growcify-premium'].includes(item._id))
        return this.$options.filters.currency(this.getPrice(item).price, this.getPrice(item).symbol)+'/monthly';
      else return this.$options.filters.currency(this.getPrice(item).price, this.getPrice(item).symbol)+'/one-time';
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return this.countries = cs;

      return Store.getActiveCountries(false)
        .then(res => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
        })
        .catch(e => console.log(e));
    },

    processEnums() {
      this.enums.plans = this.enums.plans.filter(x => !['rent-android-admin', 'rent-android-ios-admin', 'rent-growcify-premium'].includes(x._id));
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) {
        this.enums = ce;
        this.processEnums();
        return;
      }

      this.progress = true;
      return Store.getCommonEnums()
        .then(x => {
          this.enums = x.data;
          this.$store.state.commonEnums = x.data;
          this.processEnums();
          this.progress = false;
        })
        .catch(e => {
          console.log(e)
          this.progress = false;
        });
    },

    verifyGSTN() {
      this.gstVerifing = true;
      return Store
        .verifyGSTN('growcify', this.store.gstn, false)
        .then(x => {
          if (x && x.data && x.data.success) {
            this.store.name = x.data.taxpayerInfo.lgnm;
          } else { 
            this.snackbarText = 'Invalid GST Number'; 
            this.esnackbar = true;
          }
          this.gstVerifing = false;
        })
        .catch(() => {
          this.gstVerifing = false;
        });
    },
  },

  mounted() {
    this.fetchCountries();
    this.fetchCommonEnums();
  },

  data() {
    return {
      enums: {},
      countries: [],
      selectedCountry: { currencyCode: 'USD', currencySymbol: '$' },

      dsid: '5f43efa177d8450017693efc',

      gstVerifing: false,
      esnackbar: false,
      snackbarText: '',

      step1: true,
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false,
      valid: false,
      showPrice: false,

      progress: false,

      store: {
        name: '',
        address: '',
        ownership: '',
        googlePlaceId: '',
        appName: '',
        appPackage: '',
        appColor: '',
        apps: [],
        amount: 0,
        currencyCode: '',
        transactionId: '',
        paymentStatus: 'pending'
      },

      validationRules: {
        appName: [
          v => !!v || 'App name is required'
        ],
        appPackage: [
          v => !!v || 'App package is required'
        ],
        storeName: [
          v => !!v || 'Name is required'
        ],
        address: [
          v => !!v || 'Address is required'
        ],
        ownership: [
          v => !!v || 'Ownership is required'
        ],
        apps: [
          v => !!v.length || 'One of apps is required'
        ],
        planRules: [
          v => !!v || 'Plan is required'
        ],

        countryRule: [
          v => !!v || 'Country is required'
        ],
        categoryRule: [
          v => !!v || 'Category is required'
        ],
        cityRule: [
          v => !!v || 'City is required'
        ]
      }
    }
  }
}
import FileUpload from "../../../services/file-upload";
import config from "../../../config";

import User from "../../../services/user";
import Store from "../../../services/store";
import router from "../../../router";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "CustomersImport",

  created() {
    this._id = this.$route.params._id;
    if (
      !this.amIAllowed("Customers") ||
      !this.doIHaveAccess("create") ||
      this.isVendor
    )
      return router.push("/business/" + this._id);
  },

  computed: {
    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    nextStep() {
      if (this.cstep === 1) {
        this.uploading = true;
        return FileUpload.upload(this._id, "files", this.file, "csv")
          .then((x) => {
            this.uploading = false;
            this.csvurl = this.getURL(x.data.file);
            this.processCSV();
          })
          .catch((e) => {
            console.log(e);
            this.uploading = false;
          });
      } else if (this.cstep == 2) {
        let isInvalid = false;
        this.processedItems.forEach((x) => {
          if (!this.$refs["form_" + x.name][0].validate())
            x.expanded = isInvalid = true;
        });

        if (!isInvalid) this.processUsers();
      }
    },

    processCSV() {
      this.processingCSV = true;
      return User.processCSV(this._id, this.csvurl)
        .then((x) => {
          this.processedItems = x.data;
          this.processingCSV = false;
          this.cstep++;
        })
        .catch((e) => {
          console.log(e);
          this.processingCSV = false;
        });
    },

    processUsers() {
      this.processing = true;
      return User.processUsers(this._id, this.processedItems)
        .then(() => {
          this.processing = false;
          this.csvurl = this.file = "";
          this.processedItems = [];
          this.cstep++;
          setTimeout(() => this.$router.back(), 2500);
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          this.insErr =
            "Insertion operation could not complete. Please check if data is correct and try again.";
          setTimeout(() => (this.insErr = ""), 2500);
        });
    },

    getRef() {
      return this.$refs.inputUpload[0]
        ? this.$refs.inputUpload[0]
        : this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileCSV(file) {
      if (
        file &&
        (file["type"].split("/")[1] === "csv" ||
          file["type"] === "application/vnd.ms-excel")
      )
        return true;
      return false;
    },

    getURL(data) {
      const array = data.split(".");
      if (
        data &&
        !array.includes(config.growcifyMasterInventory) &&
        (array[1] === "png" || array[1] === "csv")
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidFileError() {
      this.file = "";
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileCSV(file)) return this.invalidFileError();
      this.file = file;

      this.nextStep(); //auto process
    },

    removeItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      const index = this.processedItems.indexOf(this.beingDeleted);
      this.processedItems.splice(index, 1);
      setTimeout(() => {
        this.confirmDialog = this.processing = false;
      }, 500);
    },

    text(item) {
      return item.name + " (" + item.size + item.unit + ")";
    },
  },

  data() {
    return {
      _id: "",
      cstep: 1,
      valid: false,

      file: "",
      csvurl: "",

      uploading: false,
      showErr: false,

      processingCSV: false,
      processedItems: [],

      insErr: "",
      enums: {},
      categories: [],

      confirmDialog: false,
      beingDeleted: {},

      processing: false,

      validationRules: {
        quantityRule: [(v) => v >= 0 || "Enter a valid number"],
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => !!v || v === false || "It is required"],
        inlineRule: [(v) => !!v || ""],
        sizeRule: [(v) => !!v || "Size is required"],
        unitRule: [(v) => !!v || v === "" || "Unit is required"],
        categoryRule: [
          (v) =>
            (!!v && this.categories.findIndex((c) => v === c._id) >= 0) ||
            "Category is required",
        ],
      },
    };
  },
};

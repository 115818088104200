import moment from "moment";

const formatDate = (date, format = "YYYY-MM-DD") => {
  return moment(date).format(format);
};

const generateSchedule = (startDate, endDate, cycle, oldSchedule = []) => {
  const schedule = [];

  if (startDate && endDate) {
    let duration = "days";
    if (cycle === "weekly") duration = "weeks";
    else if (cycle === "monthly") duration = "months";
    else if (cycle === "yearly") duration = "years";

    if (duration) {
      const hasOS = oldSchedule.length;
      const isAlternate = cycle === "alternate-days";

      while (moment(startDate).isSameOrBefore(moment(endDate))) {
        let object = {
          date: formatDate(startDate),
          status: "pending",
        };

        if (hasOS) {
          const oldItem = oldSchedule.find(
            (x) => formatDate(x.date) == object.date
          );
          if (oldItem) object = oldItem;
        }

        schedule.push(object);
        startDate = moment(startDate).add(isAlternate ? 2 : 1, duration);
      }
    }
  }
  return schedule;
};

export { generateSchedule };

import moment from "moment";

import Store from "../../../services/store";
import router from "../../../router";
import config from "../../../config";

export default {
  name: "Notifications",

  created() {
    this._id = this.$route.params._id;
    if (
      !this.amIAllowed("Notifications") ||
      this.$store.state.activeStore.isVendor
    ) {
      return router.push("/business/" + this._id);
    }

    if (!this.doIHaveAccess("actions")) {
      this.headers = this.headers.filter((x) => x.text !== "Actions");
    }

    this.loading = true;
    const p1 = this.fetchNotifications();
    return Promise.all([p1]).then(() => (this.loading = false));
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchNotifications() {
      this.processing = true;
      return Store.getNotifications(this._id, false)
        .then((x) => {
          this.notifications = x.data.map((x, index) => {
            x.index = index + 1 + ".";
            return x;
          });
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    processItem(item) {
      this.resendItem = item;
      this.confirmResendDialog = true;
    },

    resendNotification() {
      this.confirmResendDialog = false;
      this.$store.state.backgroundProcesses.push("notification");
      return Store.processNotification(this.resendItem._id, false)
        .then(() => this.$store.state.backgroundProcesses.pop())
        .catch((e) => {
          console.log(e);
          this.$store.state.backgroundProcesses.pop();
        });
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.notificationModal = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteNotification(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchNotifications();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    formatDate(date, format = "lll") {
      return moment(date).format(format);
    },
  },

  watch: {
    notificationModal(n) {
      if (!n) this.fetchNotifications();
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  data() {
    return {
      _id: "",

      notificationModal: false,
      loading: false,
      processing: false,

      search: "",
      notifications: [],

      rowItems: config.rowItems,

      editedItem: {},
      defaultItem: {
        notify: true,
        toAll: true,
        sendSMSIfNotificationFails: false,
        data: {
          entity: "regular",
        },
      },

      confirmDialog: false,
      beingDeleted: {},

      confirmResendDialog: false,
      resendItem: {},

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        { text: "Message", value: "message" },
        { text: "To All?", value: "toAll" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", align: "right", value: "actions", sortable: false },
      ],
    };
  },
};

import Vue from "vue";
import _ from "underscore";
import convert from "convert-units";

import config from "../../config";
import FileUpload from "../../services/file-upload";
import Store from "../../services/store";
import MasterInventory from "../../services/master-inventory";
import Inventory from "../../services/inventory";

import DynamicPriceDialog from "../dynamic-price-dialog/dynamic-price-dialog.vue";
import { getS3URL } from "../../helpers/misc";

export default {
  props: [
    "_id",
    "dialog",
    "loading",
    "processing",
    "editedItem",
    "categories",
    "brands",
    "productsObject",
  ],

  components: {
    DynamicPriceDialog,
  },

  computed: {
    formTitle() {
      return this.editedItem._id
        ? "Edit product" +
            (this.editedItem.name ? " - " + this.editedItem.name : "")
        : "Add new product";
    },

    filteredCategories() {
      return this.categories
        ? this.categories.filter((x) => x._id != this.editedItem._id)
        : [];
    },

    filteredBrands() {
      return this.brands
        ? this.brands.filter((x) => x._id != this.editedItem._id)
        : [];
    },

    filteredOtherCategories() {
      return this.categories
        ? this.categories.filter((x) => x._id != this.editedItem.category)
        : [];
    },

    getFormattedComboItems() {
      return this.editedItem.comboItems
        ? this.editedItem.comboItems.map((x, i) => {
            if (x._id._id) {
              const obj = x._id;
              obj.deductQty = x.deductQty;
              x = obj;
            }
            x.index = i + 1 + ".";
            return x;
          })
        : [];
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    sortedAvailableFields() {
      const availableFields = this.availableFields;
      availableFields.sort((a, b) => a.text > b.text);
      return availableFields;
    },

    isHardDuplicate() {
      return (
        this.duplicateItem.barcodeIDDuplicacy || this.duplicateItem.skuDuplicacy
      );
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },
  },

  methods: {
    handleClick(index, photo) {
      if (!photo.id) this.pickImage(index);
      else photo.sheet = !photo.sheet;
    },

    // getRef(index) {
    //   return this.$refs["productImageInputUpload" + index][0];
    // },

    // getPhotoURL(data, isMain = false) {
    //   const array = data.split(".");
    //   if (
    //     data &&
    //     !array.includes(config.growcifyMasterInventory) &&
    //     array[1] === "png"
    //   ) {
    //     if (!isMain) return `https://${this._id}.s3.amazonaws.com/${data}`;
    //     return `https://${this.$store.state.activeStore.vendorOwner._id}.s3.amazonaws.com/${data}`;
    //   }
    //   return data;
    // },

    // pushPhotoObject(index, _id, uploading, uploadProgress, sheet) {
    //   Vue.set(this.editedItem.photos, index, {
    //     uploading: uploading,
    //     _id: _id,
    //     uploadProgress: uploadProgress,
    //     sheet: sheet,
    //   });
    // },

    getRef() {
      return this.$refs.inputUpload;
    },

    isVideo(item) {
      if (!item) return;
      const array = item.split(".");
      return array.includes("mp4");
    },

    getURL(data) {
      if (
        data &&
        (data.split(".")[1] === "png" || data.split(".")[1] === "mp4")
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    deleteMedia(item) {
      const filteredFiles = this.editedItem.photos.filter((x) => x != item);
      this.editedItem.photos = filteredFiles;
      if (this.editedItem._id) this.$parent.save(false, false);
    },

    addMedia(index = "") {
      if (index >= 0 && index !== "") {
        this.replaceAtIndex = index;
        this.pickFile();
      } else {
        this.pickFile();
      }
    },

    pickFile() {
      this.getRef().click();
    },

    isValidFile(file) {
      if (
        file &&
        (file["type"].split("/")[0] === "image" ||
          file["type"].split("/")[0] === "video")
      ) {
        this.editedItem.tempType = file["type"].split("/")[0];
        return true;
      }
      return false;
    },

    invalidError() {
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isValidFile(file)) return this.invalidError();

      if (!this.editedItem.photos) this.editedItem.photos = [];
      this.editedItem.file = "";

      this.uploading = true;
      return FileUpload.upload(
        this._id,
        "products",
        file,
        this.editedItem.tempType
      )
        .then((x) => {
          const fileKey = x.data.file;
          if (this.replaceAtIndex !== null && this.replaceAtIndex >= 0) {
            this.editedItem.photos[this.replaceAtIndex] = fileKey;
            this.replaceAtIndex = null;
          } else {
            this.editedItem.photos.push(fileKey);
          }

          this.uploading = false;
          if (this.editedItem._id) this.$parent.save(false, false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    // pickImage(index) {
    //   this.getRef(index).click();
    // },

    // fileSelected(index) {
    //   const file = this.getRef(index).files[0];
    //   if (!this.isFileImage(file)) return this.invalidImageError();

    //   //const localURL = URL.createObjectURL(file);
    //   this.pushPhotoObject(index, undefined, true, 0, false);
    //   this.uploading = true;
    //   return FileUpload.upload(this._id, "products", file, this.editedItem)
    //     .then((x) => {
    //       const _id = x.data.file;
    //       this.pushPhotoObject(index, _id, false, 0, false);
    //       if (this.editedItem._id) this.$parent.save(false, false); //only while editing
    //       this.uploading = false;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       this.uploading = false;
    //     });
    // },

    invalidImageError() {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 2000);
    },

    isFileImage(file) {
      return file && file["type"] && file["type"].split("/")[0] === "image";
    },

    closeConfirmPhoto() {
      this.confirmDialogPhoto = false;
      this.beingDeletedPhoto = {};
    },

    deletePhoto(index, photo) {
      this.confirmDialogPhoto = true;
      this.beingDeletedPhoto = photo;
    },

    finalDeletePhoto() {
      this.editedItem.photos = this.editedItem.photos.filter(
        (x) => !x._id || x._id !== this.beingDeletedPhoto._id
      );
      this.editedItem.photos.push(this.photoItem);
      this.save(false, true, true);
    },

    save(closeModal = true, fetch = true, forceSave = false) {
      if (!this.$refs.form.validate()) return;

      const itags = this.editedItem.tags;
      if (itags && itags.length) {
        const tags = itags
          .filter((x) => typeof x == "string")
          .map((x) => x.trim());
        this.editedItem.tags = tags;
      }

      const ipincodes = this.editedItem.pincodes;
      if (ipincodes && ipincodes.length) {
        const pincodes = ipincodes.map((x) => x.trim());
        this.editedItem.pincodes = pincodes;
      }

      //new schedule thing / schedule
      if (this.schedule && this.schedule.length) {
        this.editedItem.schedule = this.schedule;
      } else if (!this.editedItem.schedule) {
        this.editedItem.schedule = [];
      }

      this.$parent
        .save(closeModal, fetch, forceSave)
        .then(() => this.closeConfirmPhoto());
    },

    querySelections: _.debounce(function() {
      this.lastSearched = this.searchInput;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;

      return MasterInventory.getProducts(1, 10, this.searchInput, false)
        .then((x) => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.masterProducts = x.data ? x.data.items : [];
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    }, 500),

    mainQuerySelections: _.debounce(function(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;

      return Store.searchProducts(
        this.$store.state.activeStore.vendorOwner._id,
        keyword,
        false
      )
        .then((x) => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.mainProducts = x.data ? x.data : [];
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    }, 1000),

    productSelected(isMain = false) {
      const copiedItem = JSON.parse(JSON.stringify(this.selectedProduct));
      const category =
        copiedItem.category && copiedItem.category.name
          ? this.categories.find((x) => x.name === copiedItem.category.name)
          : undefined;

      if (category) this.editedItem.category = category._id;

      this.editedItem.name = copiedItem.name;
      this.editedItem.size = copiedItem.size;
      this.editedItem.unit = copiedItem.unit;
      this.editedItem.quantity = copiedItem.quantity;
      this.editedItem.marketPrice = copiedItem.marketPrice;
      this.editedItem.price = copiedItem.price;
      this.editedItem.orderCap = copiedItem.orderCap;
      this.editedItem.isInStock = copiedItem.isInStock;
      this.editedItem.brand = copiedItem.brand;
      this.editedItem.sku = copiedItem.sku;
      this.editedItem.gstn = copiedItem.gstn;
      this.editedItem.hsnSacCode = copiedItem.hsnSacCode;
      this.editedItem.gst = copiedItem.gst;
      this.editedItem.vegan = copiedItem.vegan;
      this.editedItem.isComingSoon = copiedItem.isComingSoon;
      this.editedItem.isActive = copiedItem.isActive;
      this.editedItem.description = copiedItem.description;
      this.editedItem.barcodeID = copiedItem.barcodeID;
      this.editedItem.barcodeText = copiedItem.barcodeText;
      this.editedItem.purchasePrice = copiedItem.purchasePrice;
      this.editedItem.incrementor = copiedItem.incrementor;
      this.editedItem.tags = copiedItem.tags;
      this.editedItem.minOrderCap = copiedItem.minOrderCap;
      this.editedItem.expiryDate = copiedItem.expiryDate;

      this.editedItem.wholesaleSize = copiedItem.wholesaleSize;
      this.editedItem.wholesaleUnit = copiedItem.wholesaleUnit;
      this.editedItem.wholesaleQuantity = copiedItem.wholesaleQuantity;
      this.editedItem.wholesalePrice = copiedItem.wholesalePrice;
      this.editedItem.weight = copiedItem.weight;
      this.editedItem.dimensionUnit = copiedItem.dimensionUnit;
      this.editedItem.length = copiedItem.length;
      this.editedItem.width = copiedItem.width;
      this.editedItem.height = copiedItem.height;
      this.editedItem.translations = copiedItem.translations || {};

      if (
        this.editedItem.wholesalePrice ||
        this.editedItem.weight ||
        this.editedItem.length ||
        this.editedItem.width ||
        this.editedItem.height
      )
        this.editedItem.expanded = true;
      else this.editedItem.expanded = false;

      // if (isMain) {
      //   this.editedItem.copyPhotos = true; //to-be-used-server-side
      //   this.editedItem.parentStore = this.$store.state.activeStore.vendorOwner._id;
      //   this.editedItem.photos = JSON.parse(JSON.stringify(copiedItem.photos));
      // }
      // else {
      //   this.editedItem.photos = copiedItem.photos
      // }
    },

    mainItemText(item) {
      return item.name + " (" + item.size + item.unit + ")";
    },

    getSequenceItems() {
      const arr = [1];
      for (let i = 0; i < this.productsObject.totalItems; i++) arr.push(i + 2);
      return arr;
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false, false);
    },

    remove(item) {
      this.editedItem.tags.splice(this.editedItem.tags.indexOf(item), 1);
      this.editedItem.tags = [...this.editedItem.tags];
    },

    querySelectionsProducts: _.debounce(function(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;

      let Request;
      const isCWMV = Store.isMultivendorUI(["categories"]);
      if (!isCWMV) {
        Request = Store.searchProducts(this._id, keyword, false);
      } else {
        Request = Store.searchMVProducts(this._id, keyword, false);
      }

      return Request.then((x) => {
        this.outstandingRequests--;
        if (thisRequest < this.lastResponse) return;
        this.lastResponse = thisRequest;

        this.items = x.data;
        this.isSearching = false;
      }).catch(() => {
        this.outstandingRequests--;
        this.isSearching = false;
      });
    }, 500),

    comboItemSelected() {
      this.lastSearched = this.selectedItem.name;
      if (
        this.editedItem.comboItems.find((x) => x._id === this.selectedItem._id)
      )
        return;

      this.selectedItem.index = this.editedItem.comboItems.length + 1;
      this.selectedItem.deductQty = 1;
      this.editedItem.comboItems.push(this.selectedItem);
      this.searchInputCombo = "";
      this.selectedItem = {};
    },

    text(item) {
      return `${item.name} (${item.size}${item.unit})`;
    },

    removeItem(item) {
      const filteredItems = this.editedItem.comboItems.filter(
        (x) => x._id != item._id
      );
      this.editedItem.comboItems = filteredItems;
    },

    increaseQty(item) {
      const indexOfItem = this.editedItem.comboItems.indexOf(item);
      item.deductQty++;
      Vue.set(this.editedItem.comboItems, indexOfItem, item);
    },

    decreaseQty(item) {
      if (item.deductQty > 1) {
        const indexOfItem = this.editedItem.comboItems.indexOf(item);
        item.deductQty--;
        Vue.set(this.editedItem.comboItems, indexOfItem, item);
      }
    },

    verifyDuplicacy: _.debounce(function() {
      this.isDuplicate = false;
      this.isVerifingDuplicacy = true;
      return Store.isDuplicateProduct(this._id, this.editedItem)
        .then((x) => {
          if (x.data) {
            this.duplicateItem = x.data;
            this.isDuplicate = true;

            if (
              this.duplicateItem.barcodeIDDuplicacy ||
              this.duplicateItem.skuDuplicacy
            ) {
              if (this.duplicateItem.skuDuplicacy)
                this.$store.state.snackbar.text = "Duplicate SKU";
              else this.$store.state.snackbar.text = "Duplicate Barcode ID";

              this.$store.state.snackbar.color = "error";
              this.$store.state.snackbar.show = true;

              setTimeout(() => {
                this.duplicateItem.barcodeIDDuplicacy = this.duplicateItem.skuDuplicacy = false;
              }, 2500);
            }
          }
          this.isVerifingDuplicacy = false;
        })
        .catch(() => {
          this.isVerifingDuplicacy = false;
        });
    }, 500),

    resetEditedItem() {
      // this.duplicateItem.photos = this.duplicateItem.photos.map((x) => {
      //   return {
      //     uploading: false,
      //     _id: x,
      //     progress: 0,
      //     sheet: false,
      //   };
      // });

      // const pl = this.duplicateItem.photos.length;
      // if (pl < 6)
      //   for (let p = 0; p < 6 - pl; p++)
      //     this.duplicateItem.photos.push(this.photoItem);

      if (!this.duplicateItem.comboItems)
        this.duplicateItem.comboItems = JSON.parse(JSON.stringify([]));
      else {
        this.duplicateItem.comboItems = this.duplicateItem.comboItems.map(
          (x, i) => {
            if (x._id._id) {
              const obj = x._id;
              obj.deductQty = x.deductQty;
              x = obj;
            }
            x.index = i + 1 + ".";
            return x;
          }
        );
      }

      const oldItem = JSON.parse(
        JSON.stringify(Object.assign({}, this.duplicateItem))
      );
      this.$emit("update:editedItem", oldItem);
      this.duplicateItem = {};
      this.isDuplicate = false;
    },

    fetchVendors() {
      if (this.isVendor) return;
      return Store.getVendors(this._id, false)
        .then((res) => (this.vendors = res.data))
        .catch((e) => console.log(e));
    },

    updateDiscountOffer() {
      if (!this.editedItem.discountBy || !this.editedItem.discount) return;
      if (this.editedItem.discountBy === "amount")
        this.editedItem.discountedPrice =
          this.editedItem.price - this.editedItem.discount;
      else
        this.editedItem.discountedPrice = parseFloat(
          (
            this.editedItem.price -
            (this.editedItem.price * this.editedItem.discount) / 100
          ).toFixed(2)
        );
    },

    saveFields() {
      localStorage["productFormFields"] = JSON.stringify(this.activeFields);
      this.saveFieldsText = "Saved";
      setTimeout(() => {
        this.saveFieldsText = "Save fields";
        this.fieldsExpanded = !this.fieldsExpanded;
      }, 500);
    },

    resetFields() {
      this.activeFields = this.defaultFields;
      localStorage.removeItem("productFormFields");
    },

    isActive(value) {
      return !!this.activeFields.find((x) => x.value == value);
    },

    allAction() {
      if (this.activeFields.toString() == this.availableFields.toString())
        return (this.activeFields = this.defaultFields);
      this.activeFields = this.availableFields;
    },

    handleTags() {
      const allTags = this.editedItem.tags;
      const newArray = allTags
        .join(", ")
        .split(",")
        .filter((x) => x);
      this.editedItem.tags = newArray && newArray[0] ? newArray : [];
    },

    handlePincodes() {
      const allPincodes = this.editedItem.pincodes;
      const newArray = allPincodes
        .join(", ")
        .split(",")
        .filter((x) => x);
      this.editedItem.pincodes = newArray && newArray[0] ? newArray : [];
    },

    selectColor() {
      if (typeof this.selectedColor === "string") {
        this.editedItem.color = this.selectedColor;
      } else {
        this.editedItem.color = this.selectedColor.hex;
      }
      this.colorDialog = false;
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.cenums = ce);

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => console.log(e));
    },

    toggleDynamicPrice() {
      this.dynamicPriceDialog = !this.dynamicPriceDialog;
    },

    addSchedule() {
      this.schedule.push(JSON.parse(JSON.stringify(this.scheduleItem)));
    },

    removeSchedule(index) {
      this.schedule.splice(index, 1);
    },

    selectTime(index, type) {
      const item = this.schedule[index];
      if (item && item[type]) this.selectedTime = item[type];

      this.timeDialogType = type;
      this.timeDialogIndex = index;
      this.timeDialog = true;
    },

    timeSelected() {
      const item = this.schedule[this.timeDialogIndex];
      item[this.timeDialogType] = this.selectedTime;
      this.timeDialog = false;
    },
  },

  watch: {
    searchInput(newVal, oldVal) {
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        newVal != this.selectedProduct.name
      )
        this.querySelections(newVal);
    },

    mainSearchInput(newVal, oldVal) {
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        newVal != this.selectedProduct.name &&
        newVal !=
          this.selectedProduct.name +
            " (" +
            this.selectedProduct.size +
            this.selectedProduct.unit +
            ")"
      ) {
        this.mainQuerySelections(newVal);
      }
    },

    searchInputCombo(newVal, oldVal) {
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        newVal != this.selectedItem.name
      ) {
        this.querySelectionsProducts(newVal);
      }
    },

    "editedItem.dimensionUnit"(n, o) {
      if (n && o) {
        if (this.editedItem.width)
          this.editedItem.width = convert(this.editedItem.width)
            .from(o)
            .to(n);
        if (this.editedItem.length)
          this.editedItem.length = convert(this.editedItem.length)
            .from(o)
            .to(n);
        if (this.editedItem.height)
          this.editedItem.height = convert(this.editedItem.height)
            .from(o)
            .to(n);
      }
    },

    dialog(n) {
      if (n) {
        this.enableRefs = true;
        this.isDuplicate = false;
        this.duplicateItem = {};
        this.fenums = true;

        if (this.editedItem.schedule && this.editedItem.schedule.length) {
          this.schedule = this.editedItem.schedule;
        }

        const p1 = this.fetchCommonEnums();
        let p2;

        if (this._id === this.growcifyMasterInventory) {
          p2 = MasterInventory.getProductEnums()
            .then((x) => (this.enums = x.data))
            .catch((e) => console.log(e));
        } else {
          p2 = Inventory.getProductEnums(this._id)
            .then((x) => (this.enums = x.data))
            .catch((e) => console.log(e));
        }

        const p3 = this.fetchVendors();

        const savedFields = localStorage["productFormFields"];
        if (savedFields) this.activeFields = JSON.parse(savedFields);

        return Promise.all([p1, p2, p3])
          .then(() => (this.fenums = false))
          .catch(() => (this.fenums = false));
      } else {
        this.enableRefs = false;
      }
    },

    // 'editedItem.margin'(n) {
    //   if (n) {
    //     const namount = this.editedItem.marketPrice*n/100;
    //     this.editedItem.price = this.editedItem.marketPrice - namount;
    //   }
    // },

    "editedItem.discount"(n) {
      if (n) this.updateDiscountOffer();
    },

    "editedItem.discountBy"(n) {
      if (n) this.updateDiscountOffer();
      // if (n && this.editedItem.discount) {
      //   if (this.editedItem.discountBy === 'amount') this.editedItem.discountedPrice = this.editedItem.price - this.editedItem.discount;
      //   else this.editedItem.discountedPrice = parseFloat((this.editedItem.price - ((this.editedItem.price * this.editedItem.discount)/100)).toFixed(2));
      // } else if (this.editedItem.discountBy && this.editedItem.discount) this.editedItem.discountedPrice = this.editedItem.price;
    },

    "editedItem.name"(n) {
      if (n && !this.editedItem._id) this.verifyDuplicacy();

      if (n) {
        if (this.editedItem.translations && this.editedItem.translations.name) {
          this.editedItem.translations.name.en = n;
        }
      }
    },
    "editedItem.size"(n) {
      if (n && !this.editedItem._id) this.verifyDuplicacy();

      if (n) {
        if (this.editedItem.translations && this.editedItem.translations.size) {
          this.editedItem.translations.size.en = n;
        }
      }
    },
    "editedItem.unit"(n) {
      if (n && !this.editedItem._id) this.verifyDuplicacy();
    },
    "editedItem.price"(n) {
      if (n) this.updateDiscountOffer();
    },
    "editedItem.sku"(n) {
      if (n) this.verifyDuplicacy();
    },

    "editedItem.barcodeID"(n) {
      if (n) this.verifyDuplicacy();
    },

    "editedItem.isScheduled"(n) {
      if (n)
        this.schedule = this.schedule.length
          ? this.schedule
          : [JSON.parse(JSON.stringify(this.scheduleItem))];
      else this.schedule = [];
    },
  },

  data() {
    return {
      valid: false,
      uploading: false,
      enableRefs: false,
      showError: false,

      fenums: false,

      translationModal: false,
      translationField: "",

      confirmDialogPhoto: false,
      beingDeletedPhoto: {},

      replaceAtIndex: null,
      photoItem: {
        uploading: false,
        _id: undefined,
        progress: 0,
        sheet: false,
      },

      searchInput: "",
      masterProducts: [],

      mainSearchInput: "",
      mainProducts: [],

      lastSearched: "",
      isSearching: false,
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,

      selectedProduct: {},

      cenums: {},
      enums: {},
      growcifyMasterInventory: config.growcifyMasterInventory,

      chips: [],
      schedule: [],
      scheduleItem: {
        day: "",
        startsAt: "",
        endsAt: "",
      },
      timeDialog: false,
      timeDialogType: "",
      selectedTime: "",
      timeDialogIndex: -1,

      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "undo",
        "redo",
        "indent",
        "|",
        "bulletedList",
        "numberedList",
        "blockQuote",
      ],

      ploading: false,
      searchInputCombo: "",
      items: [],
      selectedItem: {},
      pheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Category", value: "category.name" },
        { text: "Size", value: "size" },
        { text: "Price", value: "price" },
        { text: "Available Quantity", value: "quantity" },
        {
          text: "Deduct Quantities",
          value: "deductQty",
          sortable: false,
          align: "center",
        },
        { text: "Remove", value: "actions", sortable: false, align: "center" },
      ],

      fieldsExpanded: false,
      toggleFieldSelect: false,
      saveFieldsText: "Save fields",
      activeFields: [
        { value: "name", text: "Name", disabled: true },
        { value: "category", text: "Category", disabled: true },
        {
          value: "other-categories",
          text: "Other categories (optional)",
          disabled: true,
        },
        { value: "status", text: "Status", disabled: true },
        { value: "size", text: "Weight/Size/Qty", disabled: true },
        { value: "unit", text: "Unit", disabled: true },
        { value: "quantity", text: "Available quantity", disabled: true },
        { value: "inStock", text: "Is in stock?", disabled: true },
        { value: "marketPrice", text: "MRP", disabled: true },
        { value: "price", text: "Selling price", disabled: true },
        { value: "isSubscribable", text: "Is subscribable?" },
        { value: "sequenceNumber", text: "Sequence number" },
        { value: "description", text: "Description" },
        { value: "tags", text: "Tags" },
      ],
      defaultFields: [
        { value: "name", text: "Name", disabled: true },
        { value: "category", text: "Category", disabled: true },
        {
          value: "other-categories",
          text: "Other categories (optional)",
          disabled: true,
        },
        { value: "status", text: "Status", disabled: true },
        { value: "size", text: "Weight/Size/Qty", disabled: true },
        { value: "unit", text: "Unit", disabled: true },
        { value: "quantity", text: "Available quantity", disabled: true },
        { value: "inStock", text: "Is in stock?", disabled: true },
        { value: "marketPrice", text: "MRP", disabled: true },
        { value: "price", text: "Selling price", disabled: true },
        { value: "isSubscribable", text: "Is subscribable?" },
        { value: "sequenceNumber", text: "Sequence number" },
        { value: "description", text: "Description" },
        { value: "tags", text: "Tags" },
      ],
      availableFields: [
        { value: "name", text: "Name", disabled: true },
        { value: "category", text: "Category", disabled: true },
        {
          value: "other-categories",
          text: "Other categories (optional)",
          disabled: true,
        },
        { value: "status", text: "Status", disabled: true },
        { value: "size", text: "Weight/Size/Qty", disabled: true },
        { value: "unit", text: "Unit", disabled: true },
        { value: "quantity", text: "Available quantity", disabled: true },
        { value: "inStock", text: "Is in stock?", disabled: true },
        { value: "marketPrice", text: "MRP", disabled: true },
        { value: "price", text: "Selling price", disabled: true },
        { value: "purchasePrice", text: "Purchase price" },
        { value: "incrementor", text: "Incrementor" },
        { value: "minOrderCap", text: "Minimum (Orderable Quantity)" },
        { value: "orderCap", text: "Maximum (Orderable Quantity)" },
        { value: "productBrand", text: "Brand" },
        { value: "sku", text: "SKU" },
        { value: "gstn", text: "GSTN or Tax Code" },
        { value: "hsnSacCode", text: "HSN/SAC Code" },
        { value: "gst", text: "GST %" },
        { value: "vegan", text: "Vegan status" },
        { value: "isSubscribable", text: "Is subscribable?" },
        {
          value: "isPrementSubscriptionOnly",
          text: "Pre-payment subscription only?",
        },
        { value: "hideAddToCart", text: "Hide add to cart button?" },
        { value: "isPreorderable", text: "Is available for pre-orders?" },
        { value: "isPOSOnly", text: "Is POS Only product?" },
        { value: "isComingSoon", text: "Is coming soon?" },
        { value: "isCombo", text: "Is it a combo product?" },
        { value: "expiryDate", text: "Expiry date" },
        { value: "batch", text: "Batch" },
        { value: "manufacturer", text: "Manufacturer" },
        { value: "manufactureDate", text: "Manufacture Date" },
        {
          value: "isAttachmentRequired",
          text: "Is attachment required?",
        },
        { value: "sequenceNumber", text: "Sequence number", disabled: true },
        { value: "color", text: "Color" },
        {
          value: "isScheduled",
          text: "Do you want to schedule product availability?",
        },

        { value: "serialNumber", text: "Serial Number" },
        { value: "imei", text: "IMEI" },

        { value: "countryOrigin", text: "Country of origin" },
        { value: "isbn", text: "ISBN" },
        { value: "bookFormat", text: "Book format" },
        { value: "languagePublished", text: "Language published" },
        { value: "publisher", text: "Publisher" },
        { value: "author", text: "Author" },
        { value: "editor", text: "Editor" },
        { value: "preface", text: "Preface" },
        { value: "translator", text: "Translator" },
        { value: "publicationYear", text: "Publication year" },
        { value: "totalPages", text: "Number of pages" },
        { value: "edition", text: "Edition" },

        { value: "externalID", text: "External ID" },

        { value: "barcodeID", text: "Barcode ID" },
        { value: "barcodeText", text: "Barcode Text" },

        { value: "description", text: "Description", disabled: true },
        {
          value: "deliveryInstructions",
          text: "Internal delivery instructions",
        },
        { value: "safetyInstructions", text: "Customer safety instructions" },
        { value: "remarks", text: "Remarks" },
        { value: "tags", text: "Tags" },
        { value: "pincodes", text: "Pincodes" },
        { value: "vendors", text: "Vendors" },
      ],

      yesNoArray: [
        { key: true, value: "Yes" },
        { key: false, value: "No" },
      ],

      isDuplicate: false,
      duplicateItem: {},
      menu: false,
      menu1: false,
      isVerifingDuplicacy: false,
      colorDialog: false,
      selectedColor: "",

      vendors: [],
      dynamicPriceDialog: false,

      validationRules: {
        basicRule: [(v) => !!v || "It's required"],
        statusRule: [(v) => !!v || v === false || "It's is required"],

        lengthRule: [(v) => (!!v && !!v.length) || "It's required"],
        quantityRule: [(v) => v >= 0 || "Enter a valid number"],

        unitRule: [(v) => v === "" || !!v || "Select a unit"],

        priceRule: [(v) => (!isNaN(v) && v >= 0) || "Enter a valid price"],

        sellingPriceRule: [
          (v) => v >= 0 || "Enter a valid price",
          (v) =>
            !this.editedItem.marketPrice ||
            v <= parseFloat(this.editedItem.marketPrice) ||
            "Selling Price can not be greater than M.R.P.",
        ],

        numberRule: [(v) => v >= 0 || "Enter a valid number"],
        minOrderCapRule: [
          (v) =>
            !v || v >= 0 || "Enter a number less than equal to max quantity",
        ],
        maxOrderCapRule: [
          (v) =>
            !v || v >= 0 || "Enter a number greater than equal to min quantity",
        ],
        itemsRule: [
          (v) =>
            !!v.length ||
            "Please select at least one product in case of a combo product",
        ],
        aboveZeroRule: [(v) => !v || v >= 1 || "Enter a valid number (>=1)"],
      },
    };
  },
};

<template>
  <section>
    <section v-for="(item, index) of taxSlabs" :key="'ts_' + index">
      <section v-if="isIGST">
        <PIInfoItem
          :title="'IGST (' + item.igst + '%)'"
          :description="
            amountFormatted(item.igstAmount, currentItem.currencyCode)
          "
          :editable="editable"
        />
      </section>

      <section v-else-if="isIndia">
        <PIInfoItem
          :title="'CGST (' + item.cgst + '%)'"
          :description="
            amountFormatted(item.cgstAmount, currentItem.currencyCode)
          "
          :editable="editable"
        />
        <PIInfoItem
          :title="'SGST (' + item.sgst + '%)'"
          :description="
            amountFormatted(item.sgstAmount, currentItem.currencyCode)
          "
          :editable="editable"
        />
      </section>
      <section v-else>
        <PIInfoItem
          :title="'Tax (' + item.tax + '%)'"
          :description="
            amountFormatted(item.taxAmount, currentItem.currencyCode)
          "
          :editable="editable"
        />
      </section>
    </section>
  </section>
</template>
<script>
import getSymbolFromCurrency from "currency-symbol-map";
import PIInfoItem from "../../components/pi-info-item/pi-info-item.vue";

export default {
  name: "PITaxSlabs",

  props: ["taxSlabs", "isIGST", "currentItem", "editable"],

  components: {
    PIInfoItem,
  },

  methods: {
    amountFormatted(amount, currencyCode) {
      return this.currencyPipe(
        amount,
        getSymbolFromCurrency(currencyCode || "INR")
      );
    },

    isIndia() {
      return this.currentItem.buyer.countryName == "India";
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },
  },
};
</script>

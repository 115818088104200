import Store from "../../../services/store";
import router from "../../../router";

import moment from "moment";
import _ from "underscore";

export default {
  name: "Integrations",

  created() {
    this._id = this.$route.params._id;
    if (!Store.doIHaveAdminAccess(this.$store)) {
      return router.push("/business/" + this._id);
    }

    if (this.isVendor) {
      this.tabs = this.tabs.filter((x) => x._id == "bank-account");
    }

    const p1 = !this.isVendor ? this.fetchIntegrations() : Promise.resolve();
    const p2 = this.fetchBankAccount();
    const p3 = this.fetchBankEnums();
    const p4 = this.fetchWebhookEnums();
    const p5 = this.fetchWebhooks();

    return Promise.all([p1, p2, p3, p4, p5])
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false));
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    fetchBankAccount() {
      this.bloading = true;
      return Store.getBankAccount(this._id, false)
        .then((res) => {
          this.bankAccount = res.data;
          this.bloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.bloading = false;
        });
    },

    fetchIntegrations() {
      this.iloading = true;
      this.gateway = Object.assign({}, this.defaultGateway);
      this.shipment = Object.assign({}, this.defaultShipment);
      this.dunzoShipment = Object.assign({}, this.defaultDunzoShipment);

      return Store.getIntegrations(this._id, false)
        .then((res) => {
          const data = res.data;
          if (data && data.length) {
            const gateway = data.find(
              (x) => x.type === "payment" && x.provider == "Razorpay"
            );
            const shipment = data.find(
              (x) => x.type === "shipment" && x.provider == "Shiprocket"
            );
            const dunzoShipment = data.find(
              (x) => x.type === "shipment" && x.provider == "Dunzo"
            );

            if (gateway) this.gateway = gateway;
            if (shipment) this.shipment = shipment;
            if (dunzoShipment) this.dunzoShipment = dunzoShipment;
          }
          this.iloading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    fetchWebhooks() {
      this.wfetching = true;
      return Store.getWebhooks(this._id, false)
        .then((res) => {
          this.webhooks = res.data;
          this.webhooks.forEach(
            (item, index) => (item.index = index + 1 + ".")
          );
          this.wfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.wfetching = false;
        });
    },

    fetchBankEnums() {
      return Store.getBankAccountEnums(this._id, false)
        .then((res) => (this.enums = res.data))
        .catch((e) => console.log(e));
    },

    fetchWebhookEnums() {
      return Store.getWebhookEnums(this._id, false)
        .then((res) => (this.wenums = res.data))
        .catch((e) => console.log(e));
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    savePaymentGateway() {
      if (this.$refs.form1 && !this.$refs.form1[0].validate()) return;
      Store.snackbarStart();
      this.saving = true;
      let request;

      if (this.gateway._id)
        request = Store.editIntegration(this.gateway._id, this.gateway, false);
      else request = Store.createIntegration(this._id, this.gateway, false);

      return request
        .then((x) => {
          this.paymentSaved = true;
          setTimeout(() => (this.paymentSaved = false), 1500);
          this.saving = false;
          if (!this.gateway._id) this.gateway._id = x.data._id;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.saving = false;
          Store.snackbarError();
        });
    },

    saveShipment() {
      if (this.$refs.form3 && !this.$refs.form3[0].validate()) return;
      Store.snackbarStart();
      this.saving = true;
      let request;

      if (this.shipment._id)
        request = Store.editIntegration(
          this.shipment._id,
          this.shipment,
          false
        );
      else request = Store.createIntegration(this._id, this.shipment, false);

      return request
        .then((x) => {
          this.shipmentSaved = true;
          setTimeout(() => (this.shipmentSaved = false), 1500);
          this.saving = false;
          if (!this.shipment._id) this.shipment._id = x.data._id;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.saving = false;
          Store.snackbarError();
        });
    },

    saveDunzoShipment() {
      if (this.$refs.form5 && !this.$refs.form5[0].validate()) return;
      Store.snackbarStart();
      this.saving = true;
      let request;

      if (this.dunzoShipment._id) {
        request = Store.editIntegration(
          this.dunzoShipment._id,
          this.dunzoShipment,
          false
        );
      } else {
        request = Store.createIntegration(this._id, this.dunzoShipment, false);
      }

      return request
        .then((x) => {
          this.dunzoShipmentSaved = true;
          setTimeout(() => (this.dunzoShipmentSaved = false), 1500);
          this.saving = false;
          if (!this.dunzoShipment._id) this.dunzoShipment._id = x.data._id;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.saving = false;
          Store.snackbarError();
        });
    },

    saveBankAccount() {
      if (this.$refs.form2 && !this.$refs.form2[0].validate()) return;
      this.saving = true;
      Store.snackbarStart();
      let request;

      if (this.bankAccount._id)
        request = Store.editBankAccount(
          this.bankAccount._id,
          this.bankAccount,
          false
        );
      else request = Store.addBankAccount(this._id, this.bankAccount, false);

      return request
        .then((x) => {
          this.bankSaved = true;
          setTimeout(() => (this.bankSaved = false), 1500);
          this.saving = false;
          if (!this.bankAccount._id) this.bankAccount._id = x.data._id;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.saving = false;
          Store.snackbarError();
        });
    },

    toggleEye(type) {
      if (type === "key") this.showPGKey = !this.showPGKey;
      else if (type === "secret") this.showPGSecret = !this.showPGSecret;
      else if (type === "merchant-id") this.showPGMID = !this.showPGMID;
      else if (type === "rz-account-id") this.showBAAID = !this.showBAAID;
      else if (type === "account-number") this.showBAAC = !this.showBAAC;
      else if (type === "ifsc") this.showBAIFSC = !this.showBAIFSC;
      else if (type === "upi") this.showBAUPI = !this.showBAUPI;
      else if (type === "username") this.showUsername = !this.showUsername;
      else if (type === "password") this.showPassword = !this.showPassword;
      else if (type === "password") this.showPassword = !this.showPassword;
      else if (type === "dz-key") this.showDK = !this.showDK;
      else if (type === "dz-secret") this.showDS = !this.showDS;
    },

    editWebhookItem(item) {
      this.editedWebhookIndex = this.webhooks.indexOf(item);
      this.editedWebhookItem = Object.assign({}, item);
      this.webhookModal = true;
    },

    deleteWebhookItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDeleteWebhook() {
      this.processing = true;
      return Store.deleteWebhook(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchWebhooks();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    saveWebhook() {
      if (this.$refs.form4 && !this.$refs.form4.validate()) return;
      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.editedWebhookItem._id)
        request = Store.editWebhook(
          this.editedWebhookItem._id,
          this.editedWebhookItem,
          false
        );
      else request = Store.addWebhook(this._id, this.editedWebhookItem, false);

      return request
        .then(() => {
          this.processing = false;
          this.webhookModal = false;
          this.fetchWebhooks();
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },
  },

  watch: {
    "editedWebhookItem.url": _.debounce(function(nv) {
      if (nv) this.editedWebhookItem.url = nv.toLowerCase().trim();
    }, 500),
  },

  data() {
    return {
      _id: "",

      valid1: false,
      valid2: false,
      valid3: false,
      valid4: false,
      valid5: false,

      enums: {},

      sms: {},
      gateway: {},
      shipment: {},
      dunzoShipment: {},

      defaultGateway: { type: "payment", provider: "Razorpay", isActive: true },
      defaultShipment: {
        type: "shipment",
        provider: "Shiprocket",
        isActive: true,
      },
      defaultDunzoShipment: {
        type: "shipment",
        provider: "Dunzo",
        isActive: true,
      },

      dunzoShipmentSaved: false,

      bankSaved: false,
      bankAccount: {},

      bloading: false,
      iloading: false,
      loading: true,
      saving: false,
      paymentSaved: false,
      shipmentSaved: false,
      processing: false,

      showPGMID: false,
      showPGKey: false,
      showPGSecret: false,

      showBAAID: false,
      showBAAC: false,
      showBAIFSC: false,
      showBAUPI: false,

      showUsername: false,
      showPassword: false,

      showDK: false,
      showDS: false,

      integrationTab: "payment",
      tabs: [
        { _id: "payment", title: "Payment Gateway" },
        { _id: "bank-account", title: "Bank Account" },
        { _id: "shipment", title: "Shipment" },
        { _id: "webhooks", title: "Webhooks" },
      ],

      wenums: {},
      webhookModal: false,
      editedWebhookItem: {},
      editedWebhookIndex: -1,
      webhookDefaultItem: {
        isActive: true,
      },
      beingDeleted: {},
      confirmDialog: false,
      payloadModal: false,

      wfetching: false,
      webhooks: [],
      wheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "URL", value: "url" },
        { text: "Events", value: "events" },
        { text: "Status", value: "isActive" },
        { text: "Last Updated", value: "updatedAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],
        urlRule: [
          (v) =>
            (!!v &&
              v.includes("https") &&
              !v.includes("localhost") &&
              !v.includes("127.0.0.1")) ||
            "Enter a valid HTTPS URL",
        ],
        eventsRule: [(v) => (v && !!v.length) || "It can not be empty"],
        statusRule: [(v) => v || v === false || "It is required"],
      },
    };
  },
};

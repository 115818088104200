import moment from "moment";

import Inventory from "../../../services/inventory";
import Store from "../../../services/store";
import router from "../../../router";
import config from "../../../config";

import CategoryForm from "../../../components/category-form/category-form.vue";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "Category",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Categories"))
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.categoryId = this.$route.params.categoryId;
    this.fetchCategory();
    this.fetchSubcategories();
    for (let p = 0; p < 4; p++) this.defaultItem.photos.push(this.photoItem);

    //filter columns
    if (this.isVendor)
      this.headers = this.headers.filter((x) => x.value != "actions");
  },

  components: {
    CategoryForm,
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      if (["create", "edit", "delete"].includes(access) && this.isVendor)
        return false;
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchCat(catId) {
      this.categoryId = catId;
      this.fetchCategory();
      this.fetchSubcategories();
    },

    fetchCategory() {
      this.loading = true;
      return Inventory.getCategory(this.categoryId, false)
        .then((res) => {
          this.category = res.data;
          this.category.createdAtD = moment(this.category.createdAt).format(
            "ll"
          );
          this.category.createdByName =
            this.category.createdBy && this.category.createdBy.name
              ? this.category.createdBy.name
              : "-";

          if (!this.category.photos) this.category.photos = [];

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchSubcategories() {
      this.sloading = true;
      return Inventory.getSubCategories(this._id, this.categoryId, false)
        .then((x) => {
          this.subcategories = x.data;
          this.subcategories.forEach((x, index) => {
            x.index = index + 1 + ".";

            x.photos = x.photos.map((x) => {
              return {
                _id: x || undefined,
                uploading: false,
                uploadProgress: 0,
                sheet: false,
              };
            });

            const totalEmpty = 4 - x.photos.length;
            for (let p = 0; p < totalEmpty; p++) x.photos.push(this.photoItem);
          });
          this.sloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.sloading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.subcategories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (!this.editedItem.parent) this.editedItem.parent = this.categoryId;
      this.dialog = true;
    },

    deleteItem(item) {
      this.beingDeleted = item;
      this.confirmDialog = true;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Inventory.deleteCategory(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchSubcategories();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign(
        {},
        JSON.parse(JSON.stringify(this.defaultItem))
      );
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true, true);
    },

    save(closeModal = true, fetchCategories = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedItem);
      object.photos = object.photos.map((x) => x._id);
      object.photos = object.photos.filter((x) => x);

      if (!object.parent || object.parent === "") delete object.parent;

      if (this.editedItem._id)
        request = Inventory.editCategory(this.editedItem._id, object, false);
      else request = Inventory.addCategory(this._id, object, false);

      return request
        .then(() => {
          this.processing = this.loading = this.editedItem.processing = false;
          if (closeModal) this.close();
          if (fetchCategories) this.fetchSubcategories();
          this.resetPhotos();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = this.editedItem.processing = false;
          this.resetPhotos();
          Store.snackbarError();
        });
    },

    resetPhotos() {
      this.defaultItem.photos.forEach((x) => (x._id = undefined));
    },

    getPhotoURL(data) {
      const array = data.split(".");
      if (
        data &&
        !array.includes(config.growcifyMasterInventory) &&
        array[1] === "png"
      ) {
        let _id = this._id;
        if (this.isVendor) _id = this.$store.state.activeStore.vendorOwner._id;

        return getS3URL(_id, data);
      }
      return data;
    },
  },

  computed: {
    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },
  },

  data() {
    return {
      _id: "",
      userId: "",

      dialog: false,
      loading: false,
      processing: false,
      uloading: false,
      sloading: false,
      category: {},
      subcategories: [],

      confirmDialog: false,
      beingDeleted: {},

      editedItem: {},
      editedIndex: -1,
      defaultItem: {
        isActive: true,
        photos: [],
      },

      photoItem: {
        uploading: false,
        _id: undefined,
        progress: 0,
        sheet: false,
      },

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        amountRule: [(v) => (!!v && v > 0) || "Enter a valid amount"],
      },
    };
  },
};

import moment from "moment";

import config from "../../config";
import { getS3URL } from "../../helpers/misc";
import Store from "../../services/store";
import StoryForm from "../story-form/story-form.vue";

export default {
  name: "Stories",

  created() {
    this._id = this.$route.params._id;
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchStories();
  },

  components: {
    StoryForm,
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchStories() {
      this.processing = this.loading = true;
      return Store.getStories(this._id, false)
        .then((x) => {
          this.stories = x.data;
          this.stories.forEach((x, index) => (x.index = index + 1 + "."));
          this.processing = this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = false;
        });
    },

    getURL(data) {
      if (data) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    formatTime(time) {
      return moment(time).fromNow();
    },

    editItem(item) {
      this.editedIndex = this.stories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (!this.editedItem._id) {
        this.editedItem.files = [];
        this.editedItem.products = [];
      }
      this.$store.state.storyModel = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteStory(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchStories();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    formatDateTime(value) {
      return moment(value).format("lll");
    },

    changeStatus(item) {
      this.editedItem = Object.assign({}, item);
      this.saveStory();
    },

    saveStory() {
      Store.snackbarStart();
      this.processing = this.editedItem.processing = true;

      const object = Object.assign({}, this.editedItem);
      return Store.editStory(this.editedItem._id, object, false)
        .then(() => {
          this.storyUpdated = true;
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.editedItem.processing = false;
          Store.snackbarError();
        });
    },

    storyViews(storyId) {
      this.viewsModal = true;
      this.processing = true;
      return Store.getStoryViews(storyId)
        .then((response) => {
          const data = response.data;
          this.views = data || [];
          this.processing = false;
        })
        .catch((error) => {
          console.log(error);
          this.processing = false;
        });
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Create new pop-up" : "Edit pop-up";
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    redirectionItems() {
      const items = [
        { key: "category", value: "Category" },
        { key: "url", value: "External URL" },
      ];

      if (!this.isVendor) items.push({ key: "vendor", value: "Vendor" });

      return items;
    },

    canCreate() {
      return this.doIHaveAccess("create");
    },
  },

  watch: {
    storyUpdated(n) {
      if (n) this.fetchStories();
      this.storyUpdated = false;
    },
  },

  data() {
    return {
      _id: "",
      stories: [],

      views: [],
      viewsModal: false,
      vsearch: "",

      valid: false,
      processing: false,
      loading: false,
      uploading: false,
      showErr: false,
      dialog: false,
      storyUpdated: false,

      search: "",

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
        files: [],
        products: [],
      },

      confirmDialog: false,
      beingDeleted: {},
      rowItems: config.rowItems,

      categories: [],

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        //{ text: "Expires At", value: "expiresAt" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "right", value: "actions", sortable: false },
      ],

      vheaders: [
        { text: "#", align: "start", value: "index", sortable: false },
        { text: "Name", value: "user.name" },
        { text: "Mobile", value: "user.mobile", sortable: false },
        { text: "Date & Time", value: "createdAt", align: "right" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],
      },
    };
  },
};

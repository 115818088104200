<template>
  <v-dialog
    class="thermal-invoice-dialog"
    v-model="$store.state.multipleThermalInvoiceModal"
    max-width="425px"
  >
    <v-card
      style="width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto;"
    >
      <div class="pt-5"></div>

      <v-row>
        <v-col cols="5" class="p0">
          <v-btn small block outlined color="primary" @click="printInvoices">
            <v-icon left small>mdi-printer</v-icon> PRINT
          </v-btn>
        </v-col>
        <v-col class="p0 text-right">
          <v-btn
            outlined
            small
            color="primary"
            class="mr-2"
            title="Download as Image"
            @click="downloadAsImage"
          >
            <v-icon small>mdi-image</v-icon>
          </v-btn>

          <v-btn
            small
            color="error"
            outlined
            @click="$store.state.multipleThermalInvoiceModal = false"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <section id="invoices">
        <section
          v-for="(order, index) in orders"
          :key="index"
          style="page-break-inside:avoid; page-break-after:always"
        >
          <ThermalInvoiceContent
            :_id="_id"
            :order="order"
            :hide-controls="true"
          ></ThermalInvoiceContent>
          <div v-if="index + 1 < orders.length" class="mb-5"></div>
        </section>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import domtoimage from "dom-to-image";
import { Printd } from "printd";

import ThermalInvoiceContent from "../thermal-invoice-content/thermal-invoice-content.vue";

export default {
  name: "ThermalInvoices",

  components: {
    ThermalInvoiceContent,
  },

  props: ["_id"],

  computed: {
    orders() {
      return this.$store.state.mtiOrders;
    },
  },

  methods: {
    printInvoices() {
      const cssText = `
        .spacercstm {
          margin-top: 15px;
          border-bottom: 1.5px dashed;
          margin-bottom: 15px;
          width: 100%;
        }
        .invoicelogo-img {
          -webkit-filter: grayscale(100%); 
          filter: grayscale(100%);
        }
        .p0 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        .pr-0 {
          padding-right: 0 !important;
        }
        .mt--10 {
          margin-top: -25px;
        }
        #invoice {
          width: ${this.is2Inch ? "2in" : "3in"};
          background: white;
          font-size: 10px;
          padding-left: ${this.is2Inch ? 5 : 3}px;
          padding-right: ${this.is2Inch ? 5 : 3}px;
          font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        }
        h6, .h6 {
          font-size: 1rem;
        }
        .items {
          font-size: 10px
        }
        .text-center {
          text-align: center !important;
        }
        .text-right {
          text-align: right !important;
        }
        .container {
          width: 100%;
        }
        .row {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
        }
        .col {
          flex-basis: 0;
          flex-grow: 1;
          min-width: 0;
          max-width: 100%;
        }
        .p0 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        .pt-2 {
          padding-top: 8px !important;
        }
        .pt-1 {
          padding-top: 4px !important;
        }
        .col-1 {
          flex: 0 0 8.333333%;
          max-width: 8.333333%;
        }
        .col-2 {
          flex: 0 0 16.666667%;
          max-width: 16.666667%;
        }
        .col-5 {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
        .col-12 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .col-6 {
          flex: 0 0 50%;
          max-width: 50%;
        }
        .pr-0 {
          padding-right: 0px !important;
        }
        .v-data-table {
          max-width: 100%;
          border-radius: 4px;
        }
        .v-data-table__wrapper {
          overflow-x: auto;
          overflow-y: hidden;
        }
        .v-data-table table {
          width: 100%;
          border-spacing: 0;
        }
        table {
          border-collapse: collapse;
        }
        .v-data-table thead tr:last-child th {
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
        }
        .text-left {
          text-align: left !important;
        }
        .text-right {
          text-align: right !important;
        }
        .v-data-table td, .v-data-table th {
          padding: 0 10px;
        }
        .v-data-table th {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          height: 25px;
        }
        .text-black {
          color: black !important;
        }
        .v-data-table td {
          height: 25px;
          padding: 0 10px;
        }
        .pl-0 {
          padding-left: 0px !important;
        }
        .pr-0 {
          padding-right: 0px !important;
        }
        .pr-5px {
          padding-right: 3px !important;
        }
        .mt-2 {
          margin-bottom: 8px !important;
        }
        .mt-7 {
          margin-top: 28px !important;
        }
        @media print {
          body {
            display: table;
            table-layout: fixed;
            margin-top: 0px;
            margin-left: 0px;
            margin-right: 0px;
            height: auto;
          }
          .pagebreak { page-break-before: always; }
        }
      `;
      const d = new Printd();
      d.print(document.getElementById("invoices"), [cssText]);
    },

    downloadAsImage() {
      const element = document.getElementById("invoices");
      element.style.paddingLeft = "5px";
      element.style.paddingRight = "5px";
      domtoimage.toPng(element).then(function(imgData) {
        if (!imgData) return;
        element.style.paddingLeft = "0px";
        const a = document.createElement("a");
        a.href = imgData;
        a.setAttribute("download", "Invoices.png");
        a.click();
      });
    },
  },

  data() {
    return {
      processing: false,
    };
  },
};
</script>

import moment from "moment";
import _ from "underscore";
import { Printd } from "printd";
import onScan from "onscan.js";
import * as Mousetrap from "mousetrap";
import getSymbolFromCurrency from "currency-symbol-map";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import Store from "../../../services/store";
import User from "../../../services/user";
import Order from "../../../services/order";
import POS from "../../../services/pos";

import router from "../../../router";
import config from "../../../config";

import Barcodes from "../../../components/barcodes/barcodes.vue";
import ThermalInvocie from "../../../components/thermal-invoice/thermal-invoice.vue";
import POSSubscription from "../../../components/pos-subscription/pos-subscription.vue";
import ProdutQuickForm from "../../../components/product-quick-form/product-quick-form.vue";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "POS",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("POS") || !this.posAccess)
      return router.push("/business/" + this._id);
    this.resetDocumentTitle();

    if (this.$route.params.page) {
      this.activeTab = this.$route.params.page;
      if (this.activeTab == "history") this.reloadHistory();
      else if (this.activeTab == "settings") this.fetchPOSSettings();
      else if (this.activeTab == "links") this.fetchPaymentLinks();
    }

    this.$store.state.posSubscriptionModal = false;

    this.initPusher();

    //fetch POS data
    this.fetchCategories();
    this.fetchProducts();
    this.fetchCustomers();
    this.fetchPOSEmployees();
    this.fetchPOSSettings();
    //this.fetchCoupons();

    //reattach every time
    if (onScan.isAttachedTo(document)) onScan.detachFrom(document);
    if (onScan.isAttachedTo(document) == false) {
      const newthis = this;
      onScan.attachTo(document, {
        onScan: (sCode) => {
          newthis.isBarcodeScanned = false;
          if (sCode) {
            if (newthis.editMode) newthis.onBarcodeScanned(sCode);
            else {
              newthis.snackbarText = "Order is in view mode";
              newthis.esnackbar = true;
            }
          } else {
            newthis.snackbarText = "Barcode coundn't detect, try again please";
            newthis.esnackbar = true;
          }
          setTimeout(() => (newthis.search = ""), 250);
        },
      });
    }

    Mousetrap.bind("enter", () => {
      this.processEnterClick();
      return false;
    });
    Mousetrap.bind(["command+g", "ctrl+g"], () => {
      this.processOrder(true);
      return false;
    });
    Mousetrap.bind("esc", () => {
      this.orderSuccessModal = false;
      this.ocModal = false;
      this.$store.state.thermalInvoiceModal = false;
      this.$store.state.paymentLinkModal = false;
    });

    setTimeout(() => this.draggableDiv(), 250);

    //setup country code
    this.order.currencyCode = this.$store.state.country.currencyCode || "INR";
    this.defaultOrderObject.currencyCode = this.order.currencyCode;
  },

  components: {
    ThermalInvocie,
    POSSubscription,
    ProdutQuickForm,
    Loading,
    Barcodes,
  },

  methods: {
    initPusher() {
      const channelName = this._id;
      const eventName = "refreshInventory";

      const pusher = this.$store.state.pusher;
      let channel = pusher.channel(channelName);
      if (!channel) channel = pusher.subscribe(channelName);

      if (this.$store.state.pusherBusinessEvents.includes("pos:" + eventName))
        return;
      this.$store.state.pusherBusinessEvents.push("pos:" + eventName);

      channel.bind(eventName, () => {
        if (this.$route.name == "store-pos") this.refresh();
      });
    },

    onBarcodeScanned(barcode) {
      this.esnackbar = false;
      if (barcode) {
        let item =
          this.order.items.find(
            (x) =>
              x.barcodeID == barcode || x.uuid == barcode || x._id == barcode
          ) ||
          this.ooAllProducts.find(
            (x) =>
              x.barcodeID == barcode ||
              x.uuid == barcode ||
              x._id == barcode ||
              (x.children &&
                x.children.length &&
                x.children.find(
                  (c) =>
                    c.barcodeID == barcode ||
                    c.uuid == barcode ||
                    c._id == barcode
                ))
          );
        if (item) {
          if (
            item.barcodeID != barcode &&
            item.uuid != barcode &&
            item._id != barcode
          )
            item = item.children.find(
              (x) =>
                x.barcodeID == barcode || x.uuid == barcode || x._id == barcode
            );
          this.add(item);
        } else {
          this.snackbarText = "Invalid Barcode Detected";
          this.esnackbar = true;

          //want to add this new product modal
          this.editedProductItem.barcodeID = barcode;
          this.$store.state.produtQuickFormModal = true;
        }
      } else {
        this.snackbarText = "Try again please";
        this.esnackbar = true;
      }

      setTimeout(() => {
        this.isBarcodeScanned = true;
        this.search = "";
      }, 250);
    },

    draggableDiv() {
      dragElement(document.getElementById("mydiv"));

      function dragElement(elmnt) {
        var pos1 = 0,
          pos2 = 0,
          pos3 = 0,
          pos4 = 0;
        if (document.getElementById(elmnt.id + "header")) {
          // if present, the header is where you move the DIV from:
          document.getElementById(
            elmnt.id + "header"
          ).onmousedown = dragMouseDown;
        } else {
          // otherwise, move the DIV from anywhere inside the DIV:
          elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = elmnt.offsetTop - pos2 + "px";
          elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        }

        function closeDragElement() {
          // stop moving when mouse button is released:
          document.onmouseup = null;
          document.onmousemove = null;
        }
      }
    },

    changeDetected() {
      if (this.isBarcodeScanned) this.search = "";
    },

    resetDocumentTitle() {
      document.title = this.$store.state.activeStore.name + " POS";
    },

    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    doIHaveAdminAccess() {
      return Store.doIHaveAdminAccess(this.$store);
    },

    activateSession() {
      this.startAt = moment();
      this.clock();
      this.startSession();
    },

    clock() {
      setInterval(
        () =>
          (this.currentTime = moment().format("dddd, MMMM Do YYYY, h:mm A")),
        1000
      );
    },

    startSession() {
      this.sessionTime = moment(this.startAt).fromNow();
      setInterval(() => (this.sessionTime = this.formatHours()), 60000);
    },

    formatDate(date = "", format = "ll") {
      return (date ? moment(date) : moment()).format(format);
    },

    formatHours() {
      const totalSeconds = moment().diff(this.startAt, "seconds");
      let dateObj = new Date(totalSeconds * 1000);
      let hours = dateObj.getUTCHours();
      let minutes = dateObj.getUTCMinutes();
      let seconds = dateObj.getSeconds();

      let timeString =
        (hours >= 0 ? hours.toString().padStart(1, "0") + "h, " : "") +
        (minutes >= 0 ? minutes.toString().padStart(1, "0") + "m, " : "") +
        (seconds >= 0 ? seconds.toString().padStart(1, "0") + "s" : "");

      return timeString;
    },

    refresh() {
      if (this.activeTab === "home") {
        this.fetchCategories();
        this.fetchProducts(true);
        this.fetchCustomers();
        //this.fetchCoupons();
      } else if (this.activeTab === "history") {
        this.historyObject.pageNumber = 1;
        this.reloadHistory();
      } else if (this.activeTab === "settings") {
        this.fetchPOSSettings();
      } else if (this.activeTab === "links") {
        this.paymentLinksObject.pageNumber = 1;
        this.fetchPaymentLinks();
      }
    },

    fetchPOSOrders(
      pageNumber = 1,
      itemsPerPage = this.historyObject.itemsPerPage,
      keyword = "",
      filters = {}
    ) {
      this.ofetching = true;
      return POS.getPOSOrders(
        this._id,
        pageNumber,
        itemsPerPage,
        keyword,
        filters,
        this.store.isVendor,
        false
      )
        .then((x) => {
          this.historyObject = x.data;
          this.historyOrders = this.historyObject.items;

          if (
            (this.order._id || this.preOpenOrder) &&
            this.activeTab == "history"
          ) {
            const findIndex = this.historyOrders.findIndex(
              (x) => x._id == this.order._id || x._id == this.preOpenOrder
            );
            if (findIndex > -1) {
              const orderItem = this.historyOrders[findIndex];
              this.openInfo(orderItem, findIndex);
            } else {
              this.resetOrder();
              this.editMode = true;
            }
          }
          this.ofetching = false;
          this.floading = true;
        })
        .catch((e) => {
          console.log(e);
          this.ofetching = false;
        });
    },

    processCategories(data) {
      data.forEach((item, index) => (item.index = index));
      this.allCategories = data;
      if (this.allCategories.length) {
        this.firstCategory = this.allCategories[0];
        this.first6Categories = this.allCategories.slice(1, 7);
      }
    },

    fetchCategories() {
      return POS.getCategories(this._id)
        .then((x) => this.processCategories(x.data))
        .catch((e) => console.log(e));
    },

    fetchProducts(refreshing = false) {
      this.loading = true;
      this.refreshing = refreshing;
      return POS.getProducts(this._id)
        .then((x) => {
          this.allProducts = JSON.parse(JSON.stringify(x.data));
          this.ooAllProducts = JSON.parse(JSON.stringify(x.data));
          this.paginateProducts();
          this.activateSession();
          this.loading = this.refreshing = false;

          //not-required-for new plans
          // if (!this.firstLoaded) {
          //   this.firstLoaded = true;
          //   setTimeout(() => this.validatePOSPlan(), 2500);
          // }
        })
        .catch((e) => {
          console.log(e);
          this.loading = this.refreshing = false;
        });
    },

    fetchCoupons() {
      return Store.getCoupons(this._id, false)
        .then((res) => (this.coupons = res.data.filter((x) => x.isActive)))
        .catch((e) => console.log(e));
    },

    fetchCustomers() {
      return User.getSelectableUsers(this._id, false)
        .then((res) => {
          this.customers = res.data;
          this.customers.sort((a, b) => a.name && a.name.localeCompare(b.name));
          this.setCashUser();
        })
        .catch((e) => console.log(e));
    },

    fetchPOSEmployees() {
      return Store.getEmployees(this._id, false)
        .then((res) => {
          if (res && res.data && res.data.length)
            this.employees = res.data
              .filter((x) => x.modules && x.modules.includes("POS"))
              .map((x) => {
                return { name: x.name, mobile: x.mobile, user: x.user._id };
              });
          const currentUser = this.$store.getters.userInfo;
          const alreadyExists = this.employees.find(
            (x) => x.user === currentUser._id
          );
          if (!alreadyExists)
            this.employees.push({
              name: currentUser.name,
              mobile: currentUser.mobile,
              user: currentUser._id,
            });
        })
        .catch((e) => console.log(e));
    },

    paginateProducts() {
      let products = JSON.parse(JSON.stringify(this.ooAllProducts));
      if (this.search) {
        const ls = this.search.toLowerCase();
        products = products.filter(
          (x) =>
            x.name.toLowerCase().indexOf(ls) > -1 ||
            x._id == this.search ||
            x.barcodeID == this.search ||
            (x.children &&
              x.children.length &&
              x.children.find(
                (c) => c._id == this.search || c.barcodeID == this.search
              ))
        );
      }

      if (this.hideOutofStock)
        products = products.filter((x) => x.quantity && x.inStock);
      if (this.activeCategoryId != "all")
        products = products.filter(
          (x) => x.category._id === this.activeCategoryId
        );
      this.totalPages =
        Math.floor(products.length / this.pageSize) +
        (products.length % this.pageSize > 0 ? 1 : 0);
      this.totalItems = products.length;

      products = products.slice(
        this.pageSize * (this.pageNumber - 1),
        this.pageSize * this.pageNumber
      );
      this.products = JSON.parse(JSON.stringify(products));
      this.products.sort((a, b) => b.name < a.name);
    },

    getPhotoURL(data) {
      const as = this.$store.state.activeStore;
      let _id = this._id;

      let imgData = data;
      if (!imgData)
        imgData =
          as.appLogo ||
          (as.vendorOwner ? as.vendorOwner.appLogo : config.growcifyLogo);
      if (as.vendorOwner && as.vendorOwner._id && !data)
        _id = as.vendorOwner._id;

      if (!imgData) return "";
      const array = imgData.split(".");
      if (
        imgData &&
        !array.includes(config.growcifyMasterInventory) &&
        array[1] === "png"
      ) {
        return getS3URL(_id, imgData);
      }

      return imgData;
    },

    selectCategory(cat) {
      this.activeCategoryId = cat._id;
      this.activeCategoryName = cat.name;
    },

    filterByCategory() {
      this.products = this.allProducts.filter(
        (x) => x.category._id === this.activeCategoryId
      );
    },

    cardColor(item, type = "card") {
      return (this.activeCategoryId === item && type == "card") ||
        (this.order.paymentMethod == item && type == "payment") ||
        (this.order.deliveryMode == item && type == "deliveryMode") ||
        (this.order.status == item && type == "status")
        ? "primary"
        : this.darkDullColor;
    },

    cardTextColorClass(item, type = "card") {
      return (this.activeCategoryId === item && type == "card") ||
        (this.order.paymentMethod == item && type == "payment") ||
        (this.order.deliveryMode == item && type == "deliveryMode") ||
        (this.order.status == item && type == "status") ||
        (this.order.paymentStatus == item && type == "paymentStatus")
        ? "activeColor"
        : "";
    },

    processItem(item) {
      if (item.children && item.children.length) {
        this.activeProduct = item;
        this.showVariants = true;
      } else {
        this.add(item);
      }
    },

    getItemPrice(item, skipOnnMRP = false) {
      if (!item.basePrice) item.basePrice = item.price;
      if (this.onMRP && !skipOnnMRP && !this.order._id)
        return item.marketPrice || item.price;
      if (this.isProductTax(item)) return item.price + this.productTax(item);
      return parseFloat(item.price.toFixed(2));
    },

    getCount(product) {
      return this.findProduct(product)?.qty || 0;
    },

    findProduct(product) {
      return this.order.items.find((x) => x._id === product._id);
    },

    updateDynamicPricing(product) {
      if (!product.pricing || !product.pricing.length) return;
      const cartProduct = this.findProduct(product);
      if (cartProduct) {
        cartProduct.price = this.calculateDynamicPricing(
          product,
          product.basePrice
        );
        if (this.isProductTax(product))
          cartProduct.price = cartProduct.price + this.productTax(product);
      }
    },

    calculateDynamicPricing(product, price) {
      if (!product.pricing || !product.pricing.length) return price;
      const count = this.getCount(product);
      const pricing = product.pricing;

      pricing.forEach((item) => {
        if (count >= item.gqty) price = item.price;
      });

      return price;
    },

    add(itemObject, shift = true) {
      const item = Object.assign({}, itemObject);
      const oldItem = this.order.items.find((x) => x._id === item._id);
      if (oldItem) {
        oldItem.qty++;
        if (shift && this.posSettings.cartSorting == "top-to-bottom") {
          const index = this.order.items.findIndex(
            (x) => x._id === oldItem._id
          );
          this.order.items.splice(index, 1);
          this.order.items.unshift(oldItem);
        }
        this.updateDynamicPricing(oldItem);
      } else {
        const cartItem = {
          _id: item._id,
          name: item.name,
          category: item.category,
          qty: 1,
          price: this.getItemPrice(item),
          nonMRPPrice: this.getItemPrice(item, true),
          marketPrice: item.marketPrice,
          purchasePrice: item.purchasePrice,
          expiryDate: item.expiryDate,
          size: item.size,
          unit: item.unit,
          photos: item.photos,
          gst: item.gst,
          hsnSacCode: item.hsnSacCode,
          barcodeID: item.barcodeID,
          barcodeText: item.barcodeText,
          basePrice: item.basePrice,
          pricing: item.pricing,
        };
        if (item.color) cartItem.color = item.color;
        if (this.posSettings.cartSorting == "top-to-bottom")
          this.order.items.unshift(cartItem);
        else this.order.items.push(cartItem);

        this.updateDynamicPricing(cartItem);
        this.snackbarText = item.name + " added to the cart!";
        this.ssnackbar = true;
      }

      if (shift) this.processCartSorting();
      this.showVariants = false;
    },

    remove(item, shift = true) {
      const oldItem = this.order.items.find((x) => x._id === item._id);
      if (oldItem) oldItem.qty--;
      if (!oldItem.qty) {
        this.order.items = this.order.items.filter((x) => x._id != item._id);
        this.snackbarText = item.name + " removed to the cart";
        this.esnackbar = true;
      }

      this.updateDynamicPricing(oldItem);
      if (shift) this.processCartSorting();
    },

    removeItem(item) {
      this.order.items = this.order.items.filter((x) => x._id != item._id);
      this.snackbarText = item.name + " removed to the cart!";
      this.esnackbar = true;
      this.processCartSorting();
    },

    processCartSorting() {
      const sortBy = this.posSettings.cartSorting;
      if (sortBy == "alphabetically")
        this.order.items.sort((a, b) => a.name > b.name);
      else if (sortBy == "quantity-asc")
        this.order.items.sort((a, b) => a.qty - b.qty);
      else if (sortBy == "quantity-desc")
        this.order.items.sort((a, b) => b.qty - a.qty);
    },

    isInCart(item, isActiveProduct = false) {
      if (!this.order || !this.order.items) return false;
      const foundItem = this.order.items.find((x) => x._id === item._id);
      if (foundItem) return true;
      if (
        !item.parent &&
        item.children &&
        item.children.length &&
        !isActiveProduct
      ) {
        //check if any of its children is in cart
        const cartIds = this.order.items.map((x) => x._id);
        const isChildrenAdded = item.children.find((x) =>
          cartIds.includes(x._id)
        );
        return isChildrenAdded;
      }
      return false;
    },

    resetOrder() {
      // if (this.order.createdAt) delete this.order.createdAt;
      // if (this.order.createdAt) delete this.order.createdAt;
      // if (this.order.__v) delete this.order.__v;
      // if (this.order._id) delete this.order._id;
      // if (this.order.oid) delete this.order.oid;
      // if (this.order.isNew) delete this.order.isNew;

      const newOrderObj = {
        items: [],
        discount: this.order.discount,
        deliveryCharge: this.order.deliveryCharge,
        paymentMethod: this.order.paymentMethod,
        paymentStatus: this.order.paymentStatus,
        deliveryMode: this.order.deliveryMode,
        status: this.order.status,
        type: "pos",
        taxCode: "",
        taxInAmount: 0,
        user: {},
        sendPaymentLink: false,
      };

      if (this.posSettings.resetDeliveryCharge) newOrderObj.deliveryCharge = 0;
      if (this.posSettings.resetDiscount) newOrderObj.discount = 0;
      if (this.posSettings.resetCustomer) this.selectedUser = "";
      this.order = JSON.parse(JSON.stringify(newOrderObj));
      this.isCash = false;
    },

    customerText(item) {
      if (!item.mobile) return item;
      return item.name ? item.name + " (" + item.mobile + ")" : item.mobile;
    },

    customerMobileNumber(item) {
      if (!item.mobile) return item;
      return item.mobile;
    },

    customerUserText(item) {
      let customer = item.name;
      if (customer) customer = customer + " (" + item.mobile + ")";
      else customer = item.mobile;
      return customer;
    },

    customerValue(item) {
      if (!item.mobile) return item;
      return item.mobile;
    },

    calculateDiscount() {
      //this.couponMessage = '';
      const coupon = this.coupons.find((x) => x._id === this.order.coupon);
      if (coupon && this.order.coupon && this.getOrderTotal()) {
        const body = {
          //paymentMethod: this.order.paymentMethod,
          amount: this.cartSubtotal,
          code: coupon.code,
        };
        this.validating = true;
        return Store.validateCouponCode(this._id, body, false)
          .then((x) => {
            if (x.data.status && x.data.status === 403) {
              this.couponMessage = x.data.message;
              this.order.discount = 0;
              this.order.coupon = undefined;
            } else {
              this.couponMessage = "Code applied";
              this.order.discount = x.data.discount;
            }

            this.validating = false;
          })
          .catch((e) => {
            console.log(e);
            this.validating = false;
          });
      }
    },

    productTax(product) {
      const tax = (product.price * product.gst) / 100;
      return parseFloat(tax.toFixed(2));
    },

    isProductTax(product) {
      if (
        this.$store.state.activeStore.applyTax &&
        this.$store.state.activeStore.taxType === "product" &&
        product.gst
      )
        return true;
      return false;
    },

    closePOS() {
      document.title = this.$store.state.activeStore.name;
      router.push("/business/" + this._id);
    },

    processEnterClick() {
      if (this.posSettings.enterKeyToPlaceOrder) {
        this.processOrder(true);
      }
    },

    processOrder(bypassPreview = false, buttonClicked = false) {
      if (!this.order.items || !this.order.items.length) return; //if no items

      let isCash = false;
      if (
        this.order.paymentMethod == "CASH" &&
        this.posSettings.cashUser &&
        !this.order.user.mobile
      ) {
        this.order.user = this.cashUser;
        bypassPreview = isCash = true;
      }

      const numberLength = this.country.minNumberLength || 10;
      const ou = this.order.user;
      if (
        !isCash &&
        (!this.$refs.userForm ||
          (this.$refs.userForm && !this.$refs.userForm.validate()))
      ) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (!ou || !ou.mobile) {
          this.snackbarText = "Mobile Number is required";
          return (this.esnackbar = true);
        } else if (ou.mobile && ou.mobile.length !== numberLength) {
          this.snackbarText = "Invalid Mobile Number";
          return (this.esnackbar = true);
        } else if (!ou.name) {
          this.snackbarText = "Name is required";
          return (this.esnackbar = true);
        }

        return;
      }

      if (!isCash && ou.mobile && ou.mobile.length !== numberLength) {
        this.snackbarText = "Invalid Mobile Number";
        window.scrollTo({ top: 0, behavior: "smooth" });
        return (this.esnackbar = true);
      }

      if ((this.order._id || bypassPreview == true) && !buttonClicked)
        this.completeOrder(isCash);
      else this.ocModal = true;
    },

    completeOrder(isCash = false) {
      const orderObject = this.order;
      orderObject.amount = this.cartTotal;
      orderObject.taxInAmount = this.cartTax;

      if (this.cartTax > 0) orderObject.tax = this.store.tax;
      if (!this.isValidGST) delete orderObject.taxCode;
      if (!orderObject.address) delete orderObject.address;
      if (!orderObject.deliveryman) delete orderObject.deliveryman;

      Store.snackbarStart();
      this.processing = this.oprocessing = true;

      let request;
      if (this.order._id)
        request = POS.editOrder(this.order._id, orderObject, false);
      else request = POS.placeOrder(this._id, orderObject, false);

      return request
        .then((x) => {
          if (x && x.data && x.data._id) {
            if (this.activeTab === "history") {
              //this.reloadHistory();
              this.editMode = this.processing = this.oprocessing = false;
            } else if (this.order._id) {
              this.preOpenOrder = this.order._id;
              this.editMode = this.processing = this.oprocessing = false;
              this.resetOrder();
              this.$router.back();
            } else {
              // automatically send payment link
              if (this.order.sendPaymentLink) {
                this.processAutoPaymentLink(x.data);
              }

              this.lastOrder = {
                oid: x.data.oid,
                amount: x.data.amount,
                currencyCode: x.data.currencyCode || "INR",
                customer: x.data.user,
                totalItems: x.data.items.length,
                totalItemsQuantities: x.data.items.reduce(
                  (a, b) => a + b.qty,
                  0
                ),
              };

              this.isCash = isCash;
              this.orderInfo = x.data;
              this.orderInfo.print = true;

              this.resetOrder();
              this.processing = this.ocModal = this.oprocessing = false;

              this.orderSuccessModal = true;
              if (this.posSettings.autoPrint)
                setTimeout(
                  () => (this.$store.state.thermalInvoiceModal = true),
                  100
                );
              window.scrollTo({ top: 0, behavior: "smooth" });

              this.deductQuantities(x.data.items);
            }

            //this.refresh();
            Store.snackbarSuccess();
            this.onMRP = false;
          } else {
            this.snackbarText = "Something went wrong, try again";
            this.esnackbar = true;
            this.processing = false;
          }
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    processAutoPaymentLink(order) {
      if (!this.canSendAutoPaymentLink) return;

      let amount = order.amount;
      if (amount && order.amountFromWallet)
        amount = order.amount - order.amountFromWallet;

      const linkObject = {
        type: "order",
        order: order._id,
        oid: order.oid,
        amount: amount,
        name: order.user.name,
        email: order.user.email,
        mobile: order.user.mobile,
        callingCode: order.user.callingCode,
        currencyCode: order.currencyCode || "INR",
        description:
          this.$store.state.activeStore.appName + " Order: " + order.oid,
      };

      return Store.createPaymentLink(this._id, linkObject)
        .then(() => {})
        .catch(() => {});
    },

    deductQuantities(items) {
      this.ooAllProducts.forEach((x) => {
        const findItem = items.find(
          (y) => y._id == x._id || y._id._id == x._id
        );
        if (findItem) x.quantity = x.quantity - findItem.qty;
      });
      this.paginateProducts();
    },

    reloadHistory() {
      this.fetchPOSOrders(
        this.historyObject.pageNumber,
        this.historyObject.itemsPerPage,
        this.searchHistory,
        this.historyFilters
      );
    },

    fetchOrder(orderId) {
      this.processing = true;
      return Order.getOrder(orderId, false)
        .then((res) => {
          if (res && res.data) {
            this.orderInfo = res.data;
            this.orderInfo.print = true;
            this.resetOrder();
            this.refresh();
            this.processing = this.ocModal = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
            setTimeout(() => {
              this.orderSuccessModal = true;
              if (this.posSettings.autoPrint)
                setTimeout(
                  () => (this.$store.state.thermalInvoiceModal = true),
                  100
                );
            }, 100);
          }
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    closeOrderSuccess() {
      this.orderSuccessModal = false;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    verifyGSTN() {
      this.order.legalName = "";
      this.gstVerifing = true;
      this.isValidGST = false;
      return Store.verifyGSTN(this._id, this.order.taxCode, false)
        .then((x) => {
          if (x && x.data && x.data.success) {
            this.order.legalName = x.data.taxpayerInfo.lgnm;
            this.isValidGST = true;
          } else {
            this.snackbarText = "Invalid GST Number";
            this.esnackbar = true;
          }
          this.gstVerifing = false;
        })
        .catch(() => {
          this.gstVerifing = false;
        });
    },

    copyID(_id) {
      navigator.clipboard.writeText(_id);
      this.copyText = "Copied";
      setTimeout(() => (this.copyText = "Copy OID"), 1000);
    },

    downloadInvoice(_id) {
      return Order.generateInvoice(_id, this.$store.state.token);
    },

    printA4Invoice() {
      this.a4Invoice = true;
      this.ocModal = true;
    },

    printA4() {
      const cssText = `
        .invoicelogo-img {
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
        }
        .p0 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        .pr-0 {
          padding-right: 0 !important;
        }
        .mt--10 {
          margin-top: -25px;
        }
        #a4Invoice {
          width: 800px;
          background: white;
          font-size: 10px;
          padding-left: 3px;
          padding-right: 3px;
          font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        }
        h6, .h6 {
          font-size: 1rem;
        }
        .items {
          font-size: 10px
        }
        .text-center {
          text-align: center !important;
        }
        .text-right {
          text-align: right !important;
        }
        .container {
          width: 100%;
        }
        .row {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
        }
        .col {
          flex-basis: 0;
          flex-grow: 1;
          min-width: 0;
          max-width: 100%;
        }
        .p0 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        .pt-2 {
          padding-top: 8px !important;
        }
        .pt-1 {
          padding-top: 4px !important;
        }
        .col-1 {
          flex: 0 0 8.333333%;
          max-width: 8.333333%;
        }
        .col-2 {
          flex: 0 0 16.666667%;
          max-width: 16.666667%;
        }
        .col-5 {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
        }
        .col-12 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .pr-0 {
          padding-right: 0px !important;
        }
        .v-data-table {
          max-width: 100%;
          border-radius: 4px;
        }
        .v-data-table table {
          width: 100%;
          border-spacing: 0;
        }
        .ctitle {
          font-size: 12px;
          margin-bottom: 7px;
        }
        .cstitle {
          font-size: 10px;
        }
        table {
          border-collapse: collapse;
        }
        .theme--light.v-data-table.v-data-table--fixed-header thead th {
          background: #FFFFFF;
          box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
        }
        .v-data-table th {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          font-size: 0.75rem;
          height: 48px;
        }
        .theme--light.v-data-table thead tr th {
          color: rgba(0, 0, 0, 0.6);
        }
        .text-left {
          text-align: left !important;
        }
        .v-data-table--fixed-header thead th {
          border-bottom: 0px !important;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          z-index: 2;
        }
        .theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row), .theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
          border-bottom: thin solid rgba(0, 0, 0, 0.12);
        }
        .v-data-table td {
          font-size: 0.875rem;
          height: 48px;
        }
        .v-data-table td, .v-data-table th {
          padding: 0 16px;
        }
        .nobb {
          border-bottom: none !important;
        }
        @media print {
          body {
            display: table;
            table-layout: fixed;
            margin-top: 0px;
            margin-left: 0px;
            margin-right: 0px;
            height: auto;
          }
        }
      `;
      const d = new Printd();
      d.print(document.getElementById("a4Invoice"), [cssText]);
    },

    amountFormatted(order) {
      let formattedAmount = order.amount;
      if (order.currencyCode === "INR")
        formattedAmount = this.currencyPipe(order.amount, "₹");
      else if (order.currencyCode && order.currencyCode !== "INR")
        formattedAmount = this.currencyPipe(order.amount, order.currencyCode);
      else if (!this.$store.state.country.currencyCode && !order.currencyCode)
        formattedAmount = this.currencyPipe(order.amount, "₹");
      else
        formattedAmount = this.currencyPipe(
          order.amount,
          this.$store.state.country.currencySymbol
        );

      return formattedAmount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    printReceipt(order, print = true, isKOT = false) {
      order.print = print;
      this.orderInfo = order;
      this.$store.state.isKOT = isKOT;
      this.$store.state.thermalInvoiceModal = true;
    },

    getCardClass(order) {
      if (order._id == this.order._id && this.activeTab == "history")
        return "warning";
      if (order.status == "cancelled") return "error";
      if (order.paymentMethod == "CASH") return "success";
      if (order.paymentMethod == "COD") return "success";
      if (order.paymentMethod == "DOC") return "lime darken-4";
      if (order.paymentMethod == "UPI") return "indigo";
      if (order.paymentMethod == "CARD") return "light-blue darken-4";
    },

    getNumberWithOrdinal(n) {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },

    openInfo(order, index) {
      this.editMode = false;
      const newOrderObj = JSON.parse(JSON.stringify(order));
      newOrderObj.items.forEach((x) => {
        x.name = x._id.name;
        x.marketPrice = x._id.marketPrice;
        x.photos = x._id.photos;
        x._id = x._id._id;
      });

      if (newOrderObj.address && newOrderObj.address._id)
        newOrderObj.address = newOrderObj.address._id;
      this.selectedUser = newOrderObj.user;
      this.order = newOrderObj;

      if (this.historyOrders.length > 1) {
        const element = this.historyOrders[index];
        this.historyOrders.splice(index, 1);
        this.historyOrders.splice(1, 0, element);
      }

      this.preOpenOrder = "";
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    editOrder() {
      this.beingEditedItem = JSON.parse(JSON.stringify(this.order));
      this.editMode = true;
    },

    discardEditing() {
      this.avoidReloading = true;
      this.order = this.beingEditedItem;
      this.selectedUser = this.beingEditedItem.user;
      this.editMode = false;
      setTimeout(() => (this.avoidReloading = false), 500);
    },

    modifyOrder(action = "cancel") {
      this.processing = true;
      return POS.modifyOrder(this.order._id, action, false)
        .then(() => {
          if (action == "cancel") this.order.status = "cancelled";
          else if (action == "undo") this.order.status = "delivered";
          this.reloadHistory();
          this.processing = this.cancelConfirmDialog = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    validatePOSPlan() {
      if (this.store.isVendor) return;

      if (this.planInfo.paid) {
        const plan = this.store.plan;
        if (
          [
            "buy-growcify-enterprise",
            "growcify-pay-as-you-grow",
            "growcify-basic",
            "growcify-premium",
            "growcify-enterprise",
          ].includes(plan)
        )
          return;
      }

      if (this.planInfo.posPaid && this.store.posPlan == "pos-onetime") return;

      if (!this.planInfo.posPaid) {
        this.$store.state.posSubscriptionModal = true;
        const lspc = localStorage[this._id + "_pc"];
        if (parseInt(lspc) > this.store.posFreeAttempts) {
          if (!this.amISuperadmin && !this.amIGrowcifyEmployee) {
            this.$store.state.posSubscriptionModalPersistent = true;
          }
        } else
          localStorage.setItem(this._id + "_pc", lspc ? parseInt(lspc) + 1 : 1);
      } else {
        if (this.planInfo.posIsTenDaysToGoOrPassed)
          this.$store.state.posSubscriptionModal = true;
        else if (this.planInfo.posInPast) {
          this.$store.state.posSubscriptionModal = true;
          if (!this.amISuperadmin && !this.amIGrowcifyEmployee)
            this.$store.state.posSubscriptionModalPersistent = true;
        }
        if (this.planInfo.posPaid)
          this.$store.state.posSubscriptionModalRenewal = true;
      }
    },

    close() {
      this.$store.state.produtQuickFormModal = false;
    },

    fetchAddresses() {
      if (this.addressLoading || this.avoidReloading) return;
      if (!this.order._id) delete this.order.address;
      this.addresses = [];

      if (!this.order.user || !this.order.user._id) return;
      this.addressLoading = true;
      return User.getAddresses(
        this._id,
        this.order.user._id || this.order.user,
        false
      )
        .then((res) => {
          this.addresses = res.data;
          if (!this.order.address && this.addresses && this.addresses.length)
            this.order.address = this.addresses[0]._id;
          this.addressLoading = false;
        })
        .catch(() => (this.addressLoading = false));
    },

    addressText(item) {
      let text = "";
      if (item.name) text += item.name;
      if (item.mobile) text += " (" + item.mobile + ")";
      if (item.street) text += ", " + item.street;
      if (item.area) text += ", " + item.area;
      if (item.landmark) text += ", " + item.landmark;
      if (item.city) text += ", " + item.city;
      if (item.state) text += ", " + item.state;
      if (item.country) text += ", " + item.country;
      if (item.pincode) text += " - " + item.pincode;

      return text;
    },

    addAddress() {
      if (!this.order.user || !this.order.user._id) {
        this.snackbarText = "Customer does not exist";
        this.esnackbar = true;
        return;
      }
      const url =
        window.location.origin +
        "/business/" +
        this._id +
        "/customers/" +
        this.order.user._id +
        "?addAddress=true";
      window.open(url, "_blank");
    },

    addDeliveryman() {
      const url =
        window.location.origin +
        "/business/" +
        this._id +
        "/employees?addDeliveryman=true";
      window.open(url, "_blank");
    },

    fetchDeliverymen() {
      this.dloading = true;
      return Store.getEmployeesByRole(this._id, "deliveryman")
        .then((res) => {
          this.deliverymen = res.data.filter((x) => x.isActive);
          this.dloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.dloading = false;
        });
    },

    fetchPOSSettings() {
      return POS.getSettings(this._id, false)
        .then((x) => {
          if (x.data) {
            this.posSettings = x.data;
            this.setCashUser();
          }
        })
        .catch((e) => console.log(e));
    },

    setCashUser() {
      if (this.posSettings.cashUser) {
        const cashUser = this.customers.find(
          (x) => x._id == this.posSettings.cashUser
        );
        if (cashUser) this.cashUser = cashUser;
      }
    },

    saveSettings() {
      Store.snackbarStart();
      this.processing = true;
      return POS.saveSettings(this._id, this.posSettings, false)
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    editProductItem(item) {
      if (!item._id && this.activeCategoryId != "all")
        item.category = this.activeCategoryId;
      this.editedProductItem = item;
      this.$store.state.produtQuickFormModal = true;
    },

    sendPaymentLink(order, type = "order") {
      let amount = order.amount;
      if (amount && order.amountFromWallet)
        amount = order.amount - order.amountFromWallet;

      this.paymentLinkObject = {
        type: type,
        order: order._id,
        oid: order.oid,
        amount: amount,
        customer: {
          name: order.user.name,
          email: order.user.email,
          mobile: order.user.mobile,
          callingCode: order.user.callingCode,
          currencyCode: order.currencyCode || "INR",
        },
      };
      if (order.oid)
        this.paymentLinkObject.description =
          this.$store.state.activeStore.appName + " Order: " + order.oid;
      this.$store.state.paymentLinkModal = true;
    },

    copyPaymentLink(link) {
      navigator.clipboard.writeText(link);
      this.copyPaymentLinkText = "Copied";
      setTimeout(() => (this.copyPaymentLinkText = "Copy Payment Link"), 2000);
    },

    fetchPaymentLinks(
      pageNumber = this.paymentLinksObject.pageNumber,
      itemsPerPage = this.paymentLinksObject.itemsPerPage,
      keyword = ""
    ) {
      this.plfetching = true;
      return Store.getPaymentLinks(
        this._id,
        pageNumber,
        itemsPerPage,
        keyword,
        false
      )
        .then((x) => {
          if (x.data && x.data.items)
            x.data.items.forEach((x) => (x.expanded = false));
          this.paymentLinksObject = x.data;
          this.paymentLinks = this.paymentLinksObject.items;
          this.plfetching = false;
          this.fplloading = true;
        })
        .catch((e) => {
          console.log(e);
          this.plfetching = false;
        });
    },

    processModifyPaymentLink(item, action) {
      this.beingModifiedPLItem = item;
      this.beingModifiedPLItem.action = action;
      this.modifyPaymentLinkDialog = true;
    },

    getCallingCode(item) {
      return item.callingCode || this.country.callingCode || "91";
    },

    modifyPaymentLink(_id, action) {
      this.processing = true;
      Store.snackbarStart();

      let request;
      if (action == "cancel") request = Store.cancelPaymentLink(_id, false);
      else if (action == "delete")
        request = Store.deletePaymentLink(_id, false);
      else if (action == "notify")
        request = Store.notifyPaymentLink(_id, false);
      else if (action == "paid") request = Store.paymentLinkPaid(_id, false);

      return request
        .then(() => {
          this.processing = this.modifyPaymentLinkDialog = false;
          Store.snackbarSuccess();
          this.fetchPaymentLinks();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    processLinksView() {
      this.paymentLinks.forEach((x) => (x.expanded = !x.expanded));
    },

    printBarcodes() {
      this.selectedItems = this.order.items;
      this.$store.state.barcodesModal = true;
    },

    fetchWallet(userId) {
      this.wfetching = true;
      return User.getWallet(this._id, userId)
        .then((x) => {
          if (x && x.data) this.wallet = x.data;
          this.wfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.wfetching = false;
        });
    },

    totalPayable() {
      const oitem = this.a4Invoice ? this.orderInfo : this.order;
      const totalPayable =
        this.cartSubtotal + this.cartTax + this.deliveryCharge - oitem.discount;
      return parseFloat(totalPayable.toFixed(2));
    },

    processPercentDiscount() {
      if (this.percentDiscount && !isNaN(this.percentDiscount)) {
        const cartTotal = this.cartSubtotal;
        const amt = (cartTotal * this.percentDiscount) / 100;
        this.order.discount = parseFloat(amt.toFixed(2));
      }
    },
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },

    firstName() {
      return this.userInfo.name
        ? this.userInfo.name.split(" ").length === 1
          ? this.userInfo.name
          : this.userInfo.name
              .split(" ")
              .slice(0, -1)
              .join(" ")
        : this.userInfo.mobile;
    },

    hasItems() {
      return this.order.items.length;
    },

    isVendor() {
      return this.store.isVendor;
    },

    cartSubtotal() {
      let itemsTotal = (this.a4Invoice
        ? this.orderInfo.items
        : this.order.items
      ).reduce((a, b) => a + b.price * b.qty, 0);
      //calculations of add-ons
      (this.a4Invoice ? this.orderInfo.items : this.order.items).forEach(
        (x) => {
          if (x.selectedAddons) {
            x.selectedAddons.forEach((i) => {
              if (i)
                itemsTotal = itemsTotal + i.reduce((a, b) => a + b.price, 0);
            });
          }
        }
      );

      return itemsTotal;
    },

    cartTax() {
      if (!this.store.applyTax || this.store.taxType === "product") return 0;
      const tax = this.store.tax;
      const amount = this.cartSubtotal;
      return parseFloat(((amount * tax) / 100).toFixed(2));
    },

    deliveryCharge() {
      const oitem = this.a4Invoice ? this.orderInfo : this.order;
      const deliveryCharge = parseFloat(
        parseFloat(oitem.deliveryCharge).toFixed(2)
      );
      return deliveryCharge;
    },

    cartTotal() {
      let totalPayable = this.totalPayable();
      return parseFloat(totalPayable.toFixed(2));
    },

    cartTotalDisplay() {
      let totalPayable = this.totalPayable();

      if (this.wallet && this.wallet.balance > 0 && this.useWalletAmount) {
        if (totalPayable >= this.wallet.balance)
          totalPayable = totalPayable - this.wallet.balance;
        else totalPayable = 0;
      }

      return parseFloat(totalPayable.toFixed(2));
    },

    businessLogo() {
      return this.store.appLogo
        ? getS3URL(this._id, this.store.appLogo)
        : false;
    },

    store() {
      return this.$store.state.activeStore;
    },

    country() {
      return this.store.country;
    },

    indexedOrderItems() {
      return this.order.items.map((x, index) => {
        x.index = index;
        return x;
      });
    },

    posAccess() {
      return POS.posAccess();
    },

    planInfo() {
      return this.$store.state.planInfo;
    },

    amISuperadmin() {
      return Store.amISuperadmin(this.$store);
    },

    amIGrowcifyEmployee() {
      return Store.amIGrowcifyEmployee(this.$store);
    },

    getTotalItems() {
      return this.totalItems;
    },

    getSortedCartItems() {
      this.processCartSorting();
      return this.order.items;
    },

    isAllExpanded() {
      return this.paymentLinks.every((x) => x.expanded);
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    darkDullColor() {
      return this.isDT ? "grey darken-3" : "grey lighten-3";
    },

    walletAmountWithoutMinus() {
      let displayableAmount = 0;
      if (!this.wallet || !this.useWalletAmount) {
        this.order.amountFromWallet = 0;
        return displayableAmount;
      }

      const totalPayable = this.totalPayable();
      if (this.wallet.balance === 0) displayableAmount = 0;
      else if (this.wallet.balance < 0) {
        let balance = this.wallet.balance + "";
        balance = balance.replace(/-/g, "");
        displayableAmount = parseFloat(balance);
      } else if (totalPayable >= this.wallet.balance) {
        displayableAmount = this.wallet.balance;
        this.order.amountFromWallet = displayableAmount;
      } else {
        this.order.amountFromWallet = totalPayable;
        displayableAmount = totalPayable;
      }

      return displayableAmount;
    },

    currencySymbol() {
      const code =
        this.order.currencyCode || this.$store.state.country.currencyCode;
      return getSymbolFromCurrency(code);
    },

    canSendAutoPaymentLink() {
      return (
        !this.order._id && ["COD", "DOC"].includes(this.order.paymentMethod)
      );
    },
  },

  watch: {
    showAllCategoies(n) {
      if (n) this.otherCategories = this.allCategories.slice(7);
      else this.otherCategories = [];
    },

    activeCategoryId(n) {
      this.pageNumber = 1;
      if (n && n == "all") {
        this.paginateProducts();
        this.showAllCategoies = !this.showAllCategoies;
      } else this.paginateProducts();
    },

    search() {
      if (this.pageNumber != 1) this.pageNumber = 1;
      else this.paginateProducts();
    },

    pageNumber() {
      this.paginateProducts();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    selectedUser(n) {
      if (n && n.name) this.order.user = n;
      else if (n && n.mobile) this.order.user = n;
      else {
        this.order.user = {
          mobile: n,
          callingCode: this.country.callingCode,
        };
      }
      if (this.order.deliveryMode == "home-delivery") this.fetchAddresses();
      if (this.$refs.userForm) this.$refs.userForm.validate();

      if (n && n._id && n._id != this.posSettings.cashUser)
        this.fetchWallet(n._id);
      else this.wallet = this.useWalletAmount = false;
    },

    useWalletAmount(n) {
      if (!n) this.order.amountFromWallet = 0;
    },

    "order.taxCode": _.debounce(function(n) {
      if (n) return this.verifyGSTN();
      this.order.legalName = this.order.taxCode = "";
    }, 500),

    cartSearch(n) {
      if (n) {
        const sortedProducts = [];
        const matchingItems = this.order.items.filter(
          (x) => x.name.toLowerCase().indexOf(n.toLowerCase()) > -1
        );
        const nonMatchingItems = this.order.items.filter(
          (x) => x.name.toLowerCase().indexOf(n.toLowerCase()) < 0
        );
        matchingItems.forEach((x) => sortedProducts.push(x));
        nonMatchingItems.forEach((x) => sortedProducts.push(x));
        if (sortedProducts.length) this.order.items = sortedProducts;
      }
    },

    "$route.params"() {
      if (this.$route.params.page) {
        this.activeTab = this.$route.params.page;
        if (this.activeTab == "history") {
          this.reloadHistory();
          this.homeOrder = JSON.parse(JSON.stringify(this.order));
          delete this.homeOrder._id;
        } else if (this.activeTab == "settings") {
          this.homeOrder = JSON.parse(JSON.stringify(this.order));
        } else if (this.activeTab == "links") {
          this.homeOrder = JSON.parse(JSON.stringify(this.order));
          this.fetchPaymentLinks();
        }
      } else {
        this.activeTab = "home";
        if (this.order._id && this.editMode) return; //allow adding more products;
        this.resetOrder();

        if (this.homeOrder.items) {
          this.order = this.homeOrder;
          this.editMode = true;
          if (this.homeOrder.user && this.homeOrder.user.mobile)
            this.selectedUser = this.homeOrder.user;
        }
      }
    },

    searchHistory: _.debounce(function(nv) {
      this.fetchPOSOrders(
        1,
        this.historyObject.itemsPerPage,
        nv,
        this.historyFilters
      );
    }, 500),

    historyFilters: {
      handler: function(val) {
        this.fetchPOSOrders(
          1,
          this.historyObject.itemsPerPage,
          this.searchHistory,
          val
        );
      },
      deep: true,
    },

    "historyObject.pageNumber"(n) {
      if (this.floading)
        this.fetchPOSOrders(
          n,
          this.historyObject.itemsPerPage,
          this.searchHistory,
          this.historyFilters
        );
    },

    plsearch: _.debounce(function(n) {
      this.fetchPaymentLinks(1, this.paymentLinksObject.itemsPerPage, n);
    }, 500),

    "paymentLinksObject.pageNumber"(n) {
      if (this.fplloading)
        this.fetchPaymentLinks(
          n,
          this.paymentLinksObject.itemsPerPage,
          this.plsearch
        );
    },

    hideOutofStock() {
      this.paginateProducts();
    },

    calModal(n) {
      if (!n) this.historyFilters.dates = this.calDates;
    },

    productModified(newItem) {
      if (newItem && newItem.isAdded) {
        this.allProducts.push(newItem);
        this.ooAllProducts.push(newItem);
        this.add(newItem);
        this.paginateProducts();
      }
      if (newItem)
        this.editedProductItem = JSON.parse(
          JSON.stringify(this.defaultProductItem)
        );
    },

    paymentLinkModified() {
      this.fetchPaymentLinks();
    },

    "order.deliveryMode"(n) {
      if (!this.order._id) delete this.order.address;
      if (n && n == "home-delivery") {
        this.fetchAddresses();
        if (!this.deliverymen || !this.deliverymen.length)
          this.fetchDeliverymen();
      }
    },

    onMRP(n) {
      if (!this.order._id) {
        if (this.order.items) {
          this.order.items.forEach((x) => {
            if (n) x.price = x.marketPrice || x.price;
            else x.price = x.nonMRPPrice;
          });
        }
      }
    },

    percentDiscount(n) {
      if (n) this.processPercentDiscount();
      else {
        this.percentDiscount = 0;
        this.order.discount = 0;
      }
    },

    isPercentDiscount(n) {
      if (n) this.processPercentDiscount();
      else {
        this.percentDiscount = 0;
        this.order.discount = 0;
      }
    },
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;
    const todayDate = moment().format("YYYY-MM-DD");
    const oneWeekBefore = moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD");

    return {
      loading: false,
      processing: false,
      oprocessing: false,
      currentTime: false,
      startAt: false,
      sessionTime: false,
      floading: false,
      fplloading: false,
      firstLoaded: false,
      refreshing: false,

      gstVerifing: false,
      isValidGST: false,

      showAllCategoies: false,
      onMRP: false,
      isPercentDiscount: false,
      percentDiscount: 0,

      allCategories: [],
      firstCategory: {},
      first6Categories: [],
      otherCategories: [],

      ooAllProducts: [],
      allProducts: [],
      products: [],
      customers: [],

      isBarcodeScanned: false,
      activeCategoryId: "all",
      activeCategoryName: "All",

      activeTab: "home",

      pageSize: 18,
      pageNumber: 1,
      totalPages: 1,
      totalItems: 0,
      lastOrder: {
        amount: 0,
        currencyCode: "INR",
      },

      search: "",
      cartSearch: "",
      preOpenOrder: "",

      coupons: [],
      validating: false,

      activeProduct: { photos: [] },
      showVariants: false,
      selectedUser: "",

      snackbarText: "",
      esnackbar: false,
      ssnackbar: false,

      ocModal: false,
      orderSuccessModal: false,
      checkmarkAnimationJson: config.checkmarkAnimationJson,
      copyText: "Copy",
      a4Invoice: false,

      searchHistory: "",
      ofetching: false,
      historyFilters: {
        dates: [oneWeekBefore, todayDate],
      },
      employees: [],

      homeOrder: {},
      orderInfo: {},
      editMode: true,
      beingEditedItem: {},
      cancelConfirmDialog: false,
      order: {
        items: [],
        discount: 0,
        deliveryCharge: 0,
        paymentMethod: "CASH",
        paymentStatus: "completed",
        deliveryMode: "pick-up",
        status: "delivered",
        type: "pos",
        taxCode: "",
        taxInAmount: 0,
        amountFromWallet: 0,
        user: {},
        sendPaymentLink: false,
      },
      defaultOrderObject: {
        items: [],
        discount: 0,
        deliveryCharge: 0,
        paymentMethod: "CASH",
        paymentStatus: "completed",
        deliveryMode: "pick-up",
        status: "delivered",
        type: "pos",
        taxCode: "",
        taxInAmount: 0,
        amountFromWallet: 0,
        user: {},
        sendPaymentLink: false,
      },
      hideOutofStock: true,
      outOfStockItems: 0,

      wallet: false,
      wfetching: false,
      useWalletAmount: false,

      cancelledImage: require("@/assets/imgs/cancelled.png"),
      historyItemDetailView: false,
      historyOptions: {
        pageNumber: 1,
      },
      historyOrders: [],
      historyObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      posSettings: {
        resetCustomer: true,
        enableProductEditing: false,
        cartSorting: "top-to-bottom",
      },
      posModalPersistent: false,
      calModal: false,
      calMaxDate: new Date().toISOString().substr(0, 10),
      calDates: [oneWeekBefore, todayDate],
      cdProcessing: false,

      editedProductItem: {
        inStock: true,
        isActive: true,
        barcodeID: "",
        photos: [],
      },
      defaultProductItem: {
        inStock: true,
        isActive: true,
        barcodeID: "",
        photos: [],
      },
      productModified: false,

      addresses: [],
      addressLoading: false,
      dloading: false,
      deliverymen: [],
      avoidReloading: false,
      pressedEnterViaScanner: false,

      isCash: false,
      cashUser: {},
      paymentLinkObject: {},
      plsearch: "",
      copyPaymentLinkText: "Copy Payment Link",
      paymentLinks: [],
      paymentLinksObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 12,
      },
      plfetching: false,
      paymentLinkModified: false,
      modifyPaymentLinkDialog: false,
      beingModifiedPLItem: {},

      selectedItems: [],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        amountRule: [(v) => v > 0 || "Enter a valid amount"],
        deliveryChargeRule: [(v) => v >= 0 || "Enter a valid amount"],
        numberRule: [(v) => !isNaN(v) || "Enter a valid number"],
        emailRule: [(v) => !v || emailregex.test(v) || "Must be a valid email"],

        mobileNumberRule: [
          (v) => (!isNaN(v) && v.length === 10) || "Enter a valid number",
        ],
        statusRule: [(v) => v === false || v === true || "It can not be empty"],
      },
    };
  },
};

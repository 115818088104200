<template>
  <v-row>
    <v-col class="text-right">
      <div class="mb-4 vt-font-size">
        Authorized signatory for <b>{{ store.name }}</b>
      </div>
      <SignatureBox v-if="ps.showEmptySignatureBox || !ps.signature" />
      <div v-else style="width: 100%;" class="text-right">
        <img :src="getPhotoURL(ps.signature)" height="125" />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { getS3URL } from "../../helpers/misc";

export default {
  name: "PISignature",

  props: ["_id"],

  methods: {
    getPhotoURL(data) {
      if (data && data.split(".")[1] === "png") {
        return getS3URL(this._id, data);
      }

      return data;
    },
  },

  computed: {
    store() {
      return this.$store.state.activeStore;
    },

    ps() {
      return this.store.purchaseSettings || {};
    },
  },
};
</script>

<template>
  <v-dialog
    :persistent="!linkProcessed"
    v-model="$store.state.paymentLinkModal"
    max-width="600"
  >
    <v-progress-linear
      v-if="processing || cfetching"
      indeterminate
      color="primary"
      class="mb-0"
    ></v-progress-linear>
    <v-card
      class="pl-10 pr-10"
      style="padding-top: 15px; padding-bottom: 15px;"
    >
      <div style="height: 10px;"></div>
      <v-row>
        <v-col v-if="!linkProcessed" class="vc">
          <h5 class="m-0">Send Payment Link</h5>
          <small>Create and send a payment link</small>
        </v-col>

        <v-col v-if="linkProcessed" class="p0 text-right vc">
          <v-btn text @click="$store.state.paymentLinkModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12"></v-col>
      </v-row>

      <section v-if="!linkProcessed">
        <v-form ref="linkForm">
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                required
                outlined
                hide-details
                :items="countries"
                item-value="currencyCode"
                item-text="currencyCode"
                v-model="link.currencyCode"
                label="Currency"
                :disabled="processing || linkProcessed"
                :rules="validationRules.basicRule"
              ></v-autocomplete>
            </v-col>

            <v-col>
              <v-text-field
                required
                outlined
                hide-details
                v-model="link.amount"
                label="Amount"
                :disabled="processing || linkProcessed"
                :rules="validationRules.amountRule"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <v-autocomplete
                required
                outlined
                hide-details
                v-model="link.callingCode"
                :items="countries"
                :item-text="countryText"
                item-value="callingCode"
                label="Code"
                :disabled="processing || linkProcessed"
                :rules="validationRules.basicRule"
              ></v-autocomplete>
            </v-col>

            <v-col>
              <v-text-field
                required
                outlined
                hide-details
                v-model="link.mobile"
                label="Mobile Number"
                :disabled="processing || linkProcessed"
                :rules="validationRules.basicRule"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                required
                outlined
                hide-details
                label="Name"
                v-model="link.name"
                :disabled="processing || linkProcessed"
                :rules="validationRules.basicRule"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                required
                outlined
                hide-details
                v-model="link.email"
                label="Email"
                :disabled="processing || linkProcessed"
                :rules="validationRules.emailRequiredRule"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                required
                outlined
                maxlength="2048"
                counter
                label="Description"
                v-model="link.description"
                :disabled="processing || linkProcessed"
                hint="Eg: Payment for Premium Plan"
                :rules="validationRules.basicRule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </section>

      <section v-if="linkProcessed" class="animate__animated animate__fadeIn">
        <v-row>
          <v-col class="text-center">
            <lottie-player
              :src="checkmarkAnimationJson"
              background="transparent"
              speed="0.5"
              style="width: 200px; height: 200px; margin: auto; margin-top: -50px"
              autoplay
            ></lottie-player>
            <h5 style="margin-bottom: 3px;"><b>Payment Link Sent!</b></h5>
            <p style="padding: 10px 15px; font-size: 0.9rem; padding-bottom: 0">
              New payment link has been created and sent to the customer.
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="awb-card pos-name-card" outlined>
              <v-row>
                <v-col class="pt-0 pb-0">
                  <small><b>Payment Link</b></small>
                  <h6>
                    <a target="_blank" :href="link.link"
                      ><b>{{ link.link }}</b></a
                    >
                  </h6>
                </v-col>
                <v-col cols="3" class="vc pt-0 pb-0 p-4 text-right">
                  <v-tooltip bottom slot="append">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        @click="copyLink(link.link)"
                        >mdi-content-copy</v-icon
                      >
                    </template>
                    <span>{{ copyText || "Copy" }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <div style="height: 25px;"></div>
      </section>

      <v-row v-if="!linkProcessed">
        <v-col cols="12"></v-col>
        <v-col class="text-right" cols="12">
          <v-btn
            color="error"
            large
            outlined
            :disabled="processing"
            @click="$store.state.paymentLinkModal = false"
            >Cancel</v-btn
          >
          <v-btn
            color="success"
            large
            outlined
            class="ml-4"
            :disabled="processing"
            @click="createPaymentLink"
            >Create & Send Payment Link</v-btn
          >
        </v-col>
        <v-col cols="12"></v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import config from "../../config";
import Store from "../../services/store";

export default {
  props: ["_id", "info", "paymentLinkModified"],

  watch: {
    "$store.state.paymentLinkModal"(n) {
      if (n) {
        delete this.link.amount;
        delete this.link.order;
        delete this.link.oid;
        delete this.link.description;
        this.link.type = "growcify";
        this.linkProcessed = false;

        this.fetchCountries();

        //set data
        if (this.info.type) this.link.type = this.info.type;
        if (this.info.amount) this.link.amount = this.info.amount;
        if (this.info.order) this.link.order = this.info.order;
        if (this.info.oid) this.link.oid = this.info.oid;
        if (this.info.description)
          this.link.description = this.info.description;

        if (this.info.customer) {
          this.link.name = this.info.customer.name || "";
          this.link.mobile = this.info.customer.mobile || "";
          this.link.email = this.info.customer.email || "";
          this.link.callingCode = this.info.customer.callingCode || "91";
          this.link.currencyCode = this.info.customer.currencyCode || "INR";
        } else {
          delete this.link.name;
          delete this.link.mobile;
          delete this.link.email;
        }
      }
    },
  },

  methods: {
    countryText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    copyLink(link) {
      navigator.clipboard.writeText(link);
      this.copyText = "Copied";
      setTimeout(() => (this.copyText = "Copy"), 2000);
    },

    createPaymentLink() {
      if (!this.$refs.linkForm.validate()) return;

      Store.snackbarStart();
      this.processing = true;
      return Store.createPaymentLink(this._id, this.link, false)
        .then((x) => {
          this.link.link = x.data.link;
          this.linkProcessed = true;
          this.processing = false;
          Store.snackbarSuccess();
          this.$emit("update:paymentLinkModified", true);
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      this.cfetching = true;
      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
          this.cfetching = false;
        })
        .catch((e) => console.log(e));
    },
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      cfetching: false,
      processing: false,
      linkProcessed: false,
      countries: [],
      copyText: "Copy Payment Link",

      link: {
        currencyCode: "INR",
        callingCode: "91",
        type: "growcify",
      },

      checkmarkAnimationJson: config.checkmarkAnimationJson,
      validationRules: {
        basicRule: [(v) => !!v || "It's required"],
        amountRule: [(v) => v >= 0 || "Enter valid amount"],
        emailRequiredRule: [
          (v) => (v && emailregex.test(v)) || "Must be a valid email",
        ],
      },
    };
  },
};
</script>

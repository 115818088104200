<template>
  <v-btn
    v-if="address.lat && address.long"
    block
    outlined
    color="primary"
    large
    @click="openMap(address.lat, address.long)"
  >
    <v-icon left>mdi-map</v-icon> Navigate
  </v-btn>
</template>
<script>
export default {
  name: "AddressNavigation",

  props: ["address"],

  methods: {
    openMap() {
      const lat = this.address.lat;
      const long = this.address.long;
      const url = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      window.open(url, "_system");
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"nobp"},[_c('h5',{class:_vm.isDT ? '' : 'text-primary'},[_vm._v("Categories")]),(_vm.isVendor)?_c('small',[_vm._v("As you're a vendor, you can not create a new category. You can raise a request to create a new category from "),_c('b',[_vm._v("Requests")]),_vm._v(".")]):_vm._e()]),(_vm.doIHaveAccess('create'))?_c('b-col',{staticClass:"nobp vc text-right"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.editItem(_vm.defaultItem)}}},[_vm._v("Create new category")])],1):_vm._e()],1),_c('div',{staticStyle:{"height":"15px"}}),_c('CategoryForm',{attrs:{"edited-item":_vm.editedItem,"dialog":_vm.dialog,"categories":_vm.categories,"processing":_vm.loading || _vm.processing,"_id":_vm._id}}),_c('b-row',[_c('b-col',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"clearable":"","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-col',{staticClass:"col-12"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.categories,"footer-props":{ 'items-per-page-options': _vm.rowItems },"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading...","show-select":_vm.doIHaveAccess('edit'),"item-key":"_id"},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index)+" "),(_vm.isVendor)?_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.vendors || !item.vendors.includes(_vm._id))?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",staticStyle:{"margin-right":"5px","margin-top":"-3px"},attrs:{"disabled":_vm.processing,"small":""},on:{"click":function($event){return _vm.vendorCategoryAction(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-box ")]):_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",staticStyle:{"margin-right":"5px","margin-top":"-3px"},attrs:{"disabled":_vm.processing,"small":"","color":"primary"},on:{"click":function($event){return _vm.vendorCategoryAction(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-minus-box ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(!item.vendors || !item.vendors.includes(_vm._id) ? "Add to my catalogue" : "Remove from my catalogue")+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"margin-top":"-3px"},attrs:{"x-small":""},on:{"click":function($event){return _vm.copyID(item._id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copyCIDText))])])]}},(_vm.selectedItems.length)?{key:"footer",fn:function(){return [_c('div',{staticStyle:{"padding":"10px 0"}},[_c('v-btn',{attrs:{"small":"","color":"primary","disabled":_vm.processing},on:{"click":function($event){_vm.$store.state.massTranslateModal = true}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-translate")]),_vm._v(" Translate ")],1)],1)]},proxy:true}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                name: 'store-category-info',
                params: { _id: _vm._id, categoryId: item._id },
              }}},[_c('span',{staticStyle:{"color":"var(--primary)"}},[_vm._v(_vm._s(item.name))])])]}},{key:"item.parentName",fn:function(ref){
              var item = ref.item;
return [(item.parent)?_c('router-link',{attrs:{"to":{
                name: 'store-category-info',
                params: { _id: _vm._id, categoryId: item.parent._id },
              }}},[_c('span',{staticStyle:{"color":"var(--primary)"}},[_vm._v(_vm._s(item.parentName))])]):_c('span',[_vm._v(_vm._s(item.parentName))])]}},{key:"item.isActive",fn:function(ref){
              var item = ref.item;
return [(_vm.doIHaveAccess('edit'))?_c('v-switch',{staticClass:"mt-0",attrs:{"loading":item.processing && item._id === _vm.editedItem._id,"hide-details":"","success":item.isActive,"error":!item.isActive,"flat":""},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}}):_c('span',[_vm._v(_vm._s(item.isActive ? "Active" : "Inactive"))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(_vm.doIHaveAccess('edit'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.doIHaveAccess('delete'))?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1)],1),_c('ConfirmDialog',{attrs:{"confirm-dialog":_vm.confirmDialog,"being-deleted":_vm.beingDeleted,"processing":_vm.processing}}),_c('MassTranslate',{attrs:{"_id":_vm._id,"module":"categories","items":_vm.selectedItems,"translation-modified":_vm.translationModified},on:{"update:translationModified":function($event){_vm.translationModified=$event},"update:translation-modified":function($event){_vm.translationModified=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
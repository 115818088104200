import { api as fullscreen } from "vue-fullscreen";
import getSymbolFromCurrency from "currency-symbol-map";

import User from "../../../services/user";
import Store from "../../../services/store";
import Super from "../../../services/super";
import Order from "../../../services/order";
import config from "../../../config";

import MeetingScheduler from "../../../components/meeting-scheduler/meeting-scheduler.vue";

import moment from "moment";
import _ from "underscore";

import LineChart from "./line-chart.js";

const myID = "5f43ef6777d8450017693efb";
//const myID = "645603c18866ee2729391792";

const todayDate = moment().format("YYYY-MM-DD");
const yesterdayDate = moment()
  .subtract(1, "d")
  .format("YYYY-MM-DD");
const oneWeekBefore = moment()
  .subtract(7, "d")
  .format("YYYY-MM-DD");
const oneMonthBefore = moment()
  .subtract(1, "months")
  .format("YYYY-MM-DD");
const threeMonthsBefore = moment()
  .subtract(3, "months")
  .format("YYYY-MM-DD");
const sixMonthsBefore = moment()
  .subtract(6, "months")
  .format("YYYY-MM-DD");
const oneYearBefore = moment()
  .subtract(1, "years")
  .format("YYYY-MM-DD");

export default {
  name: "Default",

  components: {
    LineChart,
    MeetingScheduler,
  },

  created() {
    //if not superadmin, redirect to home
    if (!this.canProceed) return Store.redirectToHome();

    //subscribe to growcify-team-dashboard
    this.initPusher();

    this._id = this.$store.state.uuid;
    this.loading = true;

    const p1 = this.fetchStores();
    const p2 = this.fetchCommonEnums();
    const p3 = this.fetchCustomers();
    const p4 = this.fetchNumbers();
    const p5 = this.fetchSA();
    const p6 = this.fetchOrders(1);
    const p7 = this.fetchRoles();
    const p8 = this.fetchPricingEnums();
    const p9 = this.fetchPaymentLinks(1);
    const p10 = this.isSuperadmin() ? this.fetchChartData() : "";
    const p11 = this.fetchCountries();
    const p12 = this.fetchBillings();

    return Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12])
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false));
  },

  methods: {
    initPusher() {
      if (this.islocalhost) return;

      const channelName = "growcify-team-dashboard";
      const eventName1 = "newBusiness";
      const eventName2 = "newGrowcifyOrder";

      const pusher = this.$store.state.pusher;
      pusher.unsubscribe(channelName);

      const channel = pusher.subscribe(channelName);
      channel.unbind(eventName1);
      channel.unbind(eventName2);

      channel.bind(eventName1, () => {
        this.notify();
        if (this.$route.path == "/") {
          this.fetchDefaultStores();
          this.fetchCustomers();
        }
      });
      channel.bind(eventName2, () => {
        if (this.$route.path == "/") {
          this.fetchOrders(1);
          this.fetchCustomers();
        }
      });
    },

    amIGrowcifyEmployee() {
      return Store.amIGrowcifyEmployee(this.$store);
    },

    isSuperadmin() {
      return Store.amISuperadmin(this.$store);
    },

    textAccountManager(item) {
      return item.name || item.mobile;
    },

    notify() {
      this.$notification.show("New Business Registered! 👏", {}, {});
    },

    fetchChartData() {
      // this.cdfetching = true;
      // return Super.getCharts(false)
      //   .then((x) => {
      //     const chartInfo = x.data;
      //     this.salesForChart = chartInfo.sales;
      //     this.revenueForChart = chartInfo.revenue;
      //     this.salesForChartYearly = chartInfo.salesYearly || [];
      //     this.revenueForChartYearly = chartInfo.revenueYearly || [];
      //     this.fillData();
      //     this.cdfetching = false;
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     this.cdfetching = false;
      //   });
    },

    getDaysInMonth() {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      return new Date(year, month, 0).getDate();
    },

    fillData() {
      const allMonths = [
        "Oct, 23",
        "Nov, 23",
        "Dec, 23",
        "Jan, 24",
        "Feb, 24",
        "Mar, 24",
        "Apr, 24",
        "May, 24",
        "Jun, 24",
        "Jul, 24",
        "Aug, 24",
        "Sept, 24",
      ];

      const allYears = ["20-21", "21-22", "22-23", "23-24", "24-25"];

      const totalSales = this.salesForChart.reduce(function(x, y) {
        return x + y;
      }, 0);
      const totalRevenue = this.revenueForChart.reduce(function(x, y) {
        return x + y;
      }, 0);

      const label1 = this.$options.filters.fc(totalSales);
      const label2 = this.$options.filters.fc(totalRevenue);

      this.dataCollectionSales = {
        labels: allMonths,
        datasets: [
          {
            label: "Sales by Businesses - " + label1,
            backgroundColor: "#17a2b8",
            data: this.salesForChart,
          },
        ],
      };

      this.dataCollectionRevenue = {
        labels: allMonths,
        datasets: [
          {
            label: "Growcify's Revenue - " + label2,
            backgroundColor: "#007bff",
            data: this.revenueForChart,
          },
        ],
      };

      const totalSalesYearly = this.salesForChartYearly.reduce(function(x, y) {
        return x + y;
      }, 0);
      const totalRevenueYearly = this.revenueForChartYearly.reduce(function(
        x,
        y
      ) {
        return x + y;
      },
      0);

      const label3 = this.$options.filters.fc(totalSalesYearly);
      const label4 = this.$options.filters.fc(totalRevenueYearly);

      this.dataCollectionSalesYearly = {
        labels: allYears,
        datasets: [
          {
            label: "Sales by Businesses (FY) - " + label3,
            backgroundColor: "#17a2b8",
            data: this.salesForChartYearly,
          },
        ],
      };

      this.dataCollectionRevenueYearly = {
        labels: allYears,
        datasets: [
          {
            label: "Growcify's Revenue (FY) - " + label4,
            backgroundColor: "#007bff",
            data: this.revenueForChartYearly,
          },
        ],
      };
    },

    toggleFullscreen() {
      fullscreen.toggle(this.$el.querySelector(".fullscreen-wrapper"), {
        teleport: true,
        callback: () => {
          this.$store.state.fullscreenMode = !this.$store.state.fullscreenMode;
        },
      });
    },

    fetchSA() {
      return Super.getSuperadmins()
        .then((x) => (this.users = x.data))
        .catch((e) => console.log(e));
    },

    fetchRoles() {
      return Super.getRoles()
        .then((x) => {
          this.roles = x.data.map((x) => {
            return {
              key: x,
              value: x,
              disabled: x === "admin" || x === "customer",
            };
          });
        })
        .catch((e) => console.log(e));
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.enums = ce);

      this.processing = true;
      return Store.getCommonEnums()
        .then((x) => {
          this.enums = x.data;
          this.$store.state.commonEnums = x.data;
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    fetchPricingEnums() {
      return Store.getPricingEnums()
        .then((x) => (this.penums = x.data))
        .catch((e) => console.log(e));
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
        })
        .catch((e) => console.log(e));
    },

    // fetchStores() {
    //   this.processing = this.sfetching = true;
    //   return Store.getAllStores(false)
    //     .then((res) => {
    //       const mappedStores = res.data.map((x, index) => {
    //         x.index = index + 1 + ".";
    //         x.plan = x.plan || "-";
    //         x.transaction = x.transaction || { paymentStatus: "pending" };
    //         x.transaction.amount = x.transaction.amount || 0;
    //         x.transaction.paymentStatus = x.transaction.paymentStatus || "-";
    //         x.nextDueDateReadable =
    //           x.nextDueDate && x.paid && x.ownership === "rent"
    //             ? moment(x.nextDueDate).format("ll")
    //             : "-";
    //         x.posNextDueDateReadable =
    //           x.posNextDueDate && x.paid && x.posOwnership === "rent"
    //             ? moment(x.posNextDueDate).format("ll")
    //             : "-";
    //         x.statusd =
    //           x.transaction.paymentStatus.charAt(0).toUpperCase() +
    //           x.transaction.paymentStatus.slice(1);
    //         x.createdAtD = moment(x.createdAt).format("ll");
    //         x.countryName = x.country ? x.country.name : "India";

    //         //POS CHECK
    //         if (
    //           x.statusd == "Completed" &&
    //           x.posNextDueDate &&
    //           x.posPaid &&
    //           x.posOwnership === "rent"
    //         ) {
    //           if (moment().isSameOrAfter(x.posNextDueDate)) {
    //             x.statusd = "POS Due for Renewal";
    //             x.nextDueDateReadable = x.posNextDueDateReadable;
    //           }
    //         }

    //         if (
    //           Object.prototype.hasOwnProperty.call(x, "isActive") &&
    //           !x.isActive
    //         ) {
    //           x.statusd = "Inactive";
    //         } else if (x.nextDueDate && x.paid && x.ownership === "rent") {
    //           if (moment().isSameOrAfter(x.nextDueDate))
    //             x.statusd = "Due for Renewal";
    //         }

    //         //AMC DUE
    //         if (
    //           x.statusd == "Completed" &&
    //           x.ownership === "buy" &&
    //           x.transaction
    //         ) {
    //           if (moment().diff(x.transaction.updatedAt, "years")) {
    //             x.statusd = "AMC Due";
    //             x.nextDueDateReadable = moment(x.transaction.updatedAt).format(
    //               "ll"
    //             );
    //           }
    //         }

    //         if (x.nextDueDate) {
    //           x.nextDueDate = moment(x.nextDueDate).format("YYYY-MM-DD");
    //         }
    //         if (x.posNextDueDate) {
    //           x.posNextDueDate = moment(x.posNextDueDate).format("YYYY-MM-DD");
    //         }

    //         let ownerName = x.owner.name || x.owner.mobile || "-";
    //         x.ownerName = ownerName.split(" ")[0];

    //         let aname = x.accountManager
    //           ? x.accountManager.name || x.accountManager.mobile
    //           : "-";
    //         x.accountManagerd = aname.split(" ")[0];
    //         if (x.leadAssignedTo) {
    //           x.leadAssignedTo = x.leadAssignedTo.split(" ")[0];
    //         }

    //         return x;
    //       });

    //       let filteredStores = mappedStores;
    //       //starts: ignore internal businesses
    //       if (this.user._id !== myID) {
    //         filteredStores = mappedStores.filter(
    //           (x) =>
    //             !x.accountManager ||
    //             !(x.transaction.amount == 1 && x.accountManager._id == myID)
    //         );
    //       }
    //       // ends: ignore internal businesses

    //       this.stores = filteredStores;
    //       this.processing = this.sfetching = false;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       this.processing = this.sfetching = false;
    //     });
    // },

    fetchStores(
      pageNumber = 1,
      itemsPerPage = this.storesObject.itemsPerPage,
      keyword = this.search
    ) {
      this.processing = this.sfetching = true;
      const bodyObject = { filters: this.filters };

      return Store.getPaginatedStores(
        pageNumber,
        itemsPerPage,
        keyword,
        bodyObject,
        false
      )
        .then((res) => {
          const data = res.data;

          let filteredStores = data.items;
          //starts: ignore internal businesses
          if (this.user._id !== myID) {
            filteredStores = filteredStores.filter(
              (x) =>
                !x.accountManager ||
                !(x.transaction.amount == 1 && x.accountManager._id == myID)
            );
          }
          // ends: ignore internal businesses

          data.items = filteredStores;
          this.storesObject = data;
          this.processing = this.sfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.sfetching = false;
        });
    },

    fetchCustomers(
      pageNumber = 1,
      itemsPerPage = this.customersObject.itemsPerPage,
      keyword = ""
    ) {
      this.cloading = true;
      return User.getSuperadminUsers(pageNumber, itemsPerPage, keyword, false)
        .then((res) => {
          this.customersObject = res.data;
          this.customers = this.customersObject.items.map((x, index) => {
            x.index = index + 1 + ".";
            x.joined = moment(x.createdAt).fromNow();
            x.named = x.name || "-";
            x.emaild = x.email || "-";
            x.orders = x.orders || 0;
            x.status =
              x.disabled || x.deactivated || x.deleted ? "Inactive" : "Active";
            x.mobiled = "+" + (x.callingCode || "91") + " " + x.mobile;
            return x;
          });

          const { sortBy, sortDesc } = this.coptions;
          if (sortBy.length === 1 && sortDesc.length === 1) {
            this.translations = this.translations.sort((a, b) => {
              const sortA = a[sortBy[0]];
              const sortB = b[sortBy[0]];

              if (sortDesc[0]) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            });
          }
          this.cloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.cloading = false;
        });
    },

    fetchOrders(
      pageNumber,
      itemsPerPage = this.ordersObject.itemsPerPage,
      keyword = ""
    ) {
      this.oloading = true;
      return Order.getSuperadminOrders(pageNumber, itemsPerPage, keyword, false)
        .then((res) => {
          this.ordersObject = res.data;
          this.orders = this.ordersObject.items.map((x, index) => {
            x.index =
              pageNumber == 1
                ? index + 1 + "."
                : itemsPerPage * (pageNumber - 1) + index + 1 + ".";
            x.ago = moment(x.createdAt).format("lll");
            x.amount = x.amount || 0;
            x.sd = x.status
              ? x.status.charAt(0).toUpperCase() + x.status.slice(1)
              : "-";
            x.storeAppName = x.store ? x.store.appName || x.store.name : "-";
            return x;
          });
          this.oloading = false;
          this.firstLoad = true;
        })
        .catch((err) => {
          console.log(err);
          this.oloading = false;
        });
    },

    fetchNumbers() {
      this.fetchSpecificSuperNumber("sales");
      this.fetchSpecificSuperNumber("revenue");
      this.fetchSpecificSuperNumber("products");
      this.fetchSpecificSuperNumber("orders");
      this.fetchSpecificSuperNumber("users");
    },

    fetchSpecificSuperNumber(type) {
      this[type + "Loading"] = false;
      // return Store.getSuperadminNumbers(type)
      //   .then((res) => {
      //     //this.numbers = res.data;
      //     if (type == "sales")
      //       this.numbers.totalEarning = res.data.totalEarning;
      //     if (type == "revenue") this.numbers.total = res.data.total;
      //     if (type == "products") this.numbers.products = res.data.products;
      //     if (type == "users") this.numbers.users = res.data.users;
      //     if (type == "orders") {
      //       this.numbers.orders = res.data.orders;
      //       this.numbers.posOrders = res.data.posOrders;
      //     }
      //     this[type + "Loading"] = false;
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     this[type + "Loading"] = false;
      //   });
    },

    fetchVendors(storeId) {
      return Store.getVendors(storeId, false)
        .then((res) => (this.editedStoreVendors = res.data))
        .catch((e) => console.log(e));
    },

    text(item) {
      if (!this.editedStore.transaction) return;
      if (this.penums.growcifyPricing.find((x) => x._id == item._id))
        return item.name;

      const currencyCode = this.editedStore.transaction.currencyCode || "INR";
      const currencySymbol =
        currencyCode === "INR" ? "₹" : this.getSymbolFromCurrency(currencyCode);
      const ptext =
        item.name +
        " (" +
        this.$options.filters.currency(
          item[currencyCode] || item.price,
          currencySymbol
        );

      const ismonthly = [
        "rent-android-admin",
        "rent-android-ios-admin",
        "rent-everything",
        "rent-growcify-premium",
        "pos-monthly",
      ].includes(item._id);
      const isyearly = ["pos-yearly"].includes(item._id);

      if (ismonthly) return ptext + "/monthly)";
      else if (isyearly) return ptext + "/yearly)";
      return ptext + "/one-time)";
    },

    editStoreItem(item) {
      this.editedStoreIndex = this.storesObject.items.indexOf(item);
      this.editedStore = Object.assign({}, item);
      this.storeDialog = true;
    },

    deleteStoreItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
      this.bdType = "store";
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalStoreDelete() {
      this.processing = true;
      return Store.deleteStore(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchStores(
            this.storesObject.pageNumber,
            this.storesObject.itemsPerPage
          );
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    editCustomerItem(item) {
      this.editedCustomerIndex = this.customers.indexOf(item);
      this.editedCustomerItem = Object.assign({}, item);
      this.customerDialog = true;
    },

    deleteCustomerItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
      this.bdType = "customer";
    },

    finalCustomerDelete() {
      this.processing = true;
      return User.deleteMainUser(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchCustomers();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    closeCustomer() {
      this.customerDialog = this.processing = this.loading = false;
      this.editedCustomerItem = Object.assign({}, this.defaultCustomerItem);
    },

    closeStore() {
      this.storeDialog = this.processing = this.loading = false;
      this.editedStore = Object.assign({}, this.defaultStoreItem);
    },

    changeStoreStatus(item) {
      this.editedStore = Object.assign({}, item);
      this.saveStore();
    },

    fetchDefaultStores() {
      this.fetchStores(this.storeOptions.page, this.storeOptions.itemsPerPage);
    },

    saveStore() {
      if (this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedStore.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedStore);

      if (this.editedStore._id)
        request = Store.updateStore(this.editedStore._id, object);
      else request = Store.createNewStore(object);

      return request
        .then(() => {
          this.storeDialog = this.processing = this.editedStore.processing = false;
          Store.snackbarSuccess();
          this.fetchDefaultStores();
          this.editedStore = { transaction: {} };
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.editedStore.processing = false;
          Store.snackbarError();
        });
    },

    saveCustomer() {
      if (!this.$refs.formc.validate()) return;
      this.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedCustomerItem);
      if (this.editedCustomerItem._id)
        request = User.editMainUser(this.editedCustomerItem._id, object, false);
      else request = User.addMainUser(object, false);

      return request
        .then(() => {
          this.customerDialog = this.processing = false;
          Store.snackbarSuccess();
          this.fetchCustomers(1, this.coptions.itemsPerPage, this.csearch);
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    sendMessage(item, isWhatsappMessage = false, isEmail = false) {
      const items = JSON.parse(JSON.stringify(this.messages));
      items.forEach(
        (x) => (x.message = x.message.replace("##name##", item.owner.name))
      );

      this.items = items;
      this.editedMessageItem = {
        callingCode: item.owner.callingCode,
        number: item.owner.mobile,
        email: item.owner.email,
        name: item.owner.name,
        message: items[3].message,
        flow_id: items[3].flow_id,
        id: items[3].id,
        storeId: item._id,
        type: "renewal",
        hideAttachements: true,
      };

      if (isWhatsappMessage) this.$store.state.whatsappMessageModal = true;
      else if (isEmail) this.$store.state.emailModal = true;
      else this.$store.state.smsModal = true;
    },

    amountFormatted(order) {
      let formattedAmount = order.amount;
      if (order.currencyCode === "INR")
        formattedAmount = this.currencyPipe(order.amount, "₹");
      else if (order.currencyCode && order.currencyCode !== "INR")
        formattedAmount = this.currencyPipe(order.amount, order.currencyCode);
      else if (!this.$store.state.country.currencyCode && !order.currencyCode)
        formattedAmount = this.currencyPipe(order.amount, "₹");
      else
        formattedAmount = this.currencyPipe(
          order.amount,
          this.$store.state.country.currencySymbol
        );

      return formattedAmount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    copyID(_id) {
      navigator.clipboard.writeText(_id);
    },

    playAudio() {
      new Audio(config.newSuperadminOrderSound).play();
    },

    async refresh() {
      const p1 = this.fetchStores();
      const p2 = this.fetchCustomers();
      const p3 = this.fetchNumbers();
      const p4 = this.fetchOrders(1);
      const p5 = this.fetchPaymentLinks(1);
      const p6 = this.fetchChartData(1);
      const p7 = this.fetchBillings();
      await Promise.all([p1, p2, p3, p4, p5, p6, p7]);
    },

    fetchPaymentLinks(
      pageNumber = this.paymentLinksObject.pageNumber,
      itemsPerPage = this.paymentLinksObject.itemsPerPage,
      keyword = ""
    ) {
      this.plfetching = true;
      return Store.getPaymentLinks(
        "growcify",
        pageNumber,
        itemsPerPage,
        keyword,
        false
      )
        .then((x) => {
          if (x.data && x.data.items)
            x.data.items.forEach((x, index) => (x.index = index + 1 + "."));
          this.paymentLinksObject = x.data;
          this.paymentLinks = this.paymentLinksObject.items;
          this.plfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.plfetching = false;
        });
    },

    fetchBillings() {
      this.bfetching = true;
      return Store.getAllBillings()
        .then((x) => {
          this.billings = x.data;
          if (this.billings) {
            this.billings.forEach((x, index) => {
              x.index = index + 1 + ".";
              if (x.dueDate) x.dueDate = moment(x.dueDate).format("YYYY-MM-DD");
            });
          }
          this.bfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.bfetching = false;
        });
    },

    modifyPaymentLink(_id, action) {
      this.processing = true;
      Store.snackbarStart();

      let request;
      if (action == "cancel") request = Store.cancelPaymentLink(_id, false);
      else if (action == "delete")
        request = Store.deletePaymentLink(_id, false);
      else if (action == "notify")
        request = Store.notifyPaymentLink(_id, false);
      else if (action == "paid") request = Store.paymentLinkPaid(_id, false);

      return request
        .then(() => {
          this.processing = this.modifyPaymentLinkDialog = false;
          Store.snackbarSuccess();
          this.fetchPaymentLinks();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    exportData(type) {
      let endpoint = `${
        process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
      }store/export`;
      if (type != "businesses") endpoint += "/" + type + "/all/1/25000";
      return window.open(
        endpoint +
          "?x-request-user-agent=application/admin-app&token=" +
          this.$store.state.token,
        "_blank"
      );
    },

    scheduleNewMeeting() {
      this.meetingInfo = {};
      this.$store.state.meetingSchedulerModal = true;
    },

    isRenewalDue(item) {
      return ["Due for Renewal", "POS Due for Renewal", "AMC Due"].includes(
        item.statusd
      );
    },

    getSymbolFromCurrency(code) {
      return getSymbolFromCurrency(code);
    },

    formatDate(date, format = "lll") {
      if (!date) return "-";
      return moment(date).format(format);
    },

    getUSD(amount) {
      const usdAmount = parseFloat((amount / 74.5).toFixed(2));
      return this.amountFormatted({ amount: usdAmount, currencyCode: "$" });
    },

    deleteConfirm() {
      if (this.bdType == "store") this.finalStoreDelete();
      else if (this.bdType == "customer") this.finalCustomerDelete();
      else if (this.bdType == "billing") this.finalBillingDelete();
      else if (this.bdType == "mass-stores") this.finalMassStoresDelete();
    },

    closeBilling() {
      this.billingDialog = this.processing = this.loading = false;
      this.editedBillingItem = Object.assign({}, this.defaultBillingItem);
    },

    saveBilling() {
      this.processing = true;

      let Request;
      if (!this.editedBillingItem._id)
        Request = Store.createBilling(this.editedStore._id);
      else
        Request = Store.editBilling(
          this.editedBillingItem._id,
          this.editedBillingItem
        );

      return Request.then(() => {
        if (this.editedStore._id) this.editedStore.hasBilling = true;
        this.processing = this.confirmSetupBilling = false;
        this.billingDialog = false;
        this.fetchBillings();
      }).catch((e) => {
        console.log(e);
        this.processing = false;
      });
    },

    editBilling(item) {
      this.editedBillingIndex = this.billings.indexOf(item);
      this.editedBillingItem = Object.assign({}, item);
      this.billingDialog = true;
    },

    deleteBilling(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
      this.bdType = "billing";
    },

    finalBillingDelete() {
      this.processing = true;
      return Store.deleteBilling(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchBillings();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    isPAYG(item) {
      return item.plan == "growcify-pay-as-you-grow";
    },

    customSearch(value, search, item) {
      let input = search;
      if (search) {
        input = search.toLowerCase();
      }
      return Object.values(item).some(
        (v) =>
          v &&
          v
            .toString()
            .toLowerCase()
            .includes(input)
      );
    },

    processStoreSorting(sortBy, isDesc) {
      const storeItems = this.storesObject.items;
      if (sortBy == "default") {
        storeItems.sort((a, b) => a.createdAt < b.createdAt);
      } else if (sortBy == "status") {
        storeItems.sort((a, b) =>
          isDesc ? a.status < b.status : a.status > b.status
        );
      } else if (sortBy == "transactionalCredits") {
        storeItems.sort((a, b) =>
          isDesc
            ? a.transactionalCredits < b.transactionalCredits
            : a.transactionalCredits > b.transactionalCredits
        );
      } else if (sortBy == "createdAt") {
        storeItems.sort((a, b) =>
          !isDesc ? a.createdAt < b.createdAt : a.createdAt > b.createdAt
        );
      } else if (sortBy == "nextDueDate") {
        storeItems.sort((a, b) =>
          !isDesc
            ? a.nextDueDate < b.nextDueDate
            : a.nextDueDate > b.nextDueDate
        );
      }
      this.storesObject.items = storeItems;
    },

    initModifyBilling() {
      this.modifyBillingModal = true;
    },

    processModifyBilling() {
      if (!this.$refs.form4.validate()) return;
      this.processing = true;
      Store.snackbarStart();

      const object = {
        _ids: this.selectedBillings.map((x) => x._id),
        dueDate: this.modifyBillingObject.dueDate,
      };

      return Store.modifyBillingDueDate(object, false)
        .then(() => {
          this.modifyBillingModal = this.processing = false;
          this.modifyBillingObject = {};
          this.selectedBillings = [];

          Store.snackbarSuccess();
          this.fetchBillings();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    initDeleteStores() {
      this.bdType = "mass-stores";
      this.beingDeleted = {
        title: "selected businesses",
      };
      this.confirmDialog = true;
    },

    finalMassStoresDelete() {
      this.processing = true;
      Store.snackbarStart();

      const object = {
        _ids: this.selectedStores.map((x) => x._id),
      };

      return Store.massDeleteStores(object, false)
        .then(() => {
          this.processing = false;
          this.selectedStores = [];
          Store.snackbarSuccess();
          this.fetchStores();

          this.confirmDialog = false;
          this.beingDeleted = {};
          this.bdType = "";
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },
  },

  computed: {
    formStoreTitle() {
      return this.editedStoreIndex === -1
        ? "Add new business"
        : "Edit business";
    },

    formCustomerTitle() {
      return this.editedCustomerIndex === -1
        ? "Add new customer"
        : "Edit customer";
    },

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              return this.$options.filters.fc(tooltipItem.yLabel);
            },
          },
        },
      };
    },

    canProceed() {
      return (
        (Store.amISuperadmin(this.$store) ||
          Store.amIGrowcifyEmployee(this.$store)) &&
        this.$store.state.isGD
      );
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    lcolor() {
      return this.isDT ? "grey darken-4" : "grey lighten-4";
    },

    pplans() {
      return this.enums.plans.concat(this.penums.growcifyPricing);
    },

    user() {
      return this.$store.state.userInfo;
    },

    getIndexedStores() {
      return this.storesObject.items.map((x, i) => {
        x.index = i + 1 + ".";
        x.isSelectable = !x.paid;

        return x;
      });
    },
  },

  watch: {
    smsSent(n) {
      if (n) {
        this.fetchDefaultStores();
        this.editedMessageItem = {};
        this.smsSent = false;
      }
    },

    coptions: {
      handler(coptions) {
        if (this.customers.length)
          this.fetchCustomers(
            coptions.page,
            coptions.itemsPerPage,
            this.csearch
          );
      },
      deep: true,
    },

    csearch: _.debounce(function(nv, ov) {
      if (this.customers.length || nv == "" || nv != ov)
        this.fetchCustomers(1, this.coptions.itemsPerPage, nv);
    }, 500),

    osearch: _.debounce(function(nv) {
      this.fetchOrders(1, this.ooptions.itemsPerPage, nv);
    }, 500),

    ooptions: {
      handler(options) {
        if (this.firstLoad)
          this.fetchOrders(options.page, options.itemsPerPage, this.osearch);
      },
      deep: true,
    },

    "editedStore.senderKeyAndroid"(n) {
      if (n) this.editedStore.senderKeyAndroid = n.trim();
    },

    storeDialog(n) {
      if (n) {
        this.fetchVendors(this.editedStore._id);
      }
    },

    plsearch: _.debounce(function(nv) {
      this.fetchPaymentLinks(1, this.ploptions.itemsPerPage, nv);
    }, 500),

    ploptions: {
      handler(options) {
        if (this.firstLoad)
          this.fetchPaymentLinks(
            options.page,
            options.itemsPerPage,
            this.plsearch
          );
      },
      deep: true,
    },

    paymentLinkModified() {
      this.fetchPaymentLinks();
    },

    search: _.debounce(function(nv) {
      this.fetchDefaultStores();
    }, 500),

    "filters.accountManager"() {
      this.fetchDefaultStores();
    },

    "filters.status"() {
      this.fetchDefaultStores();
    },

    "filters.createdAt"() {
      this.fetchDefaultStores();
    },

    "filters.category"() {
      this.fetchDefaultStores();
    },

    storeOptions: {
      handler(options) {
        if (!this.processing) {
          if (
            options.itemsPerPage != this.storesObject.itemsPerPage ||
            options.page != this.storesObject.pageNumber
          ) {
            this.storesObject.pageNumber = options.page;
            this.storesObject.itemsPerPage = options.itemsPerPage;
            this.fetchDefaultStores();
          }

          if (options.sortBy.length) {
            const sortyBy = options.sortBy[0];
            const isDesc = options.sortDesc[0];
            this.processStoreSorting(sortyBy, isDesc);
          } else {
            this.processStoreSorting("default", false);
          }
        }
      },
      deep: true,
    },
  },

  data: () => {
    return {
      _id: "",
      bdType: "",
      search: "",
      search2: "",
      loading: false,
      sfetching: false,
      processing: false,
      storeDialog: false,
      customerDialog: false,
      rowItems: config.rowItems,

      enums: {
        plans: [],
      },
      penums: {
        growcifyPricing: [],
      },

      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      fnumbers: false,

      validStore: false,
      editedStoreIndex: -1,
      editedStore: { transaction: {} },
      defaultStoreItem: {},
      editedStoreVendors: [],

      validCustomer: false,
      editedCustomerIndex: -1,
      editedCustomerItem: {},
      defaultCustomerItem: {},

      confirmDialog: false,
      beingDeleted: {},

      salesLoading: true,
      revenueLoading: true,
      usersLoading: true,
      productsLoading: true,
      ordersLoading: true,

      users: [],
      roles: [],
      countries: [],

      billingCycles: ["payg", "monthly", "quarterly", "yearly", "onetime"],

      csearch: "",
      cprocessing: false,
      customersObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },
      coptions: {},

      cdfetching: false,
      dataCollectionSales: null,
      dataCollectionRevenue: null,

      dataCollectionSalesYearly: null,
      dataCollectionRevenueYearly: null,

      salesForChart: [],
      revenueForChart: [],

      salesForChartYearly: [],
      revenueForChartYearly: [],

      islocalhost: window.location.hostname == "localhost",
      selectedBillings: [],
      modifyBillingModal: false,
      modifyBillingObject: {},

      osearch: "",
      orders: [],
      oloading: false,
      ooptions: {},
      ordersObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },
      oheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Business", value: "storeAppName" },
        { text: "Name", align: "start", value: "address.name" },
        { text: "Amount", value: "amount" },
        { text: "Method", value: "paymentMethod" },
        { text: "Status", value: "sd" },
        { text: "Type", value: "type" },
        { text: "Date & Time", align: "right", sortable: false, value: "ago" },
      ],

      headers: [
        { text: "#", align: "start", value: "index", sortable: false },
        { text: "Name", value: "appName", sortable: false, width: "225px" },
        { text: "Owner", value: "owner.name", sortable: false },
        { text: "Amount", value: "transaction.amount", sortable: false },
        { text: "Status", value: "status" },
        { text: "Due Date", value: "nextDueDate" },
        { text: "Credits", value: "transactionalCredits" },
        { text: "Manager", value: "accountManager.name", sortable: false },
        { text: "Registered", value: "createdAt" },
        { text: "Access", value: "bypassDashboardAccess", sortable: false },
        { text: "Actions", align: "right", value: "actions", sortable: false },
      ],
      stores: [],

      numbers: {
        products: 0,
        thisMonthEarning: 0,
        totalEarning: 0,
      },

      editedMessageItem: {},
      items: [],
      messages: config.messages,
      smsSent: false,
      firstLoad: false,

      meetingInfo: {},
      editedEmailItem: {},
      selectedStores: [],

      customers: [],
      cloading: true,
      cheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", align: "start", value: "named" },
        { text: "Mobile", value: "mobiled", sortable: false },
        { text: "Email", value: "emaild", sortable: false },
        { text: "Status", value: "status" },
        //{ text: 'Stores', value: 'totalStores' },
        //{ text: 'Orders', value: 'orders' },
        { text: "Joined", value: "joined" },
        { text: "Actions", align: "right", value: "actions", sortable: false },
      ],

      plfetching: false,
      plloading: true,
      ploptions: {},
      plheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Business", value: "storeAppName" },
        { text: "Name", align: "start", value: "name" },
        { text: "Mobile", value: "mobile", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Amount", value: "amount" },
        //{ text: 'Link', value: 'link', sortable: false },
        { text: "Status", value: "status" },
        { text: "Created By", value: "createdBy", sortable: false },
        { text: "Created At", value: "createdAt", sortable: false },
        { text: "Actions", align: "right", value: "actions", sortable: false },
      ],

      paymentLinkObject: {},
      plsearch: "",
      copyPaymentLinkText: "Copy Payment Link",
      paymentLinks: [],
      paymentLinksObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },
      paymentLinkModified: false,
      modifyPaymentLinkDialog: false,
      beingModifiedPLItem: {},
      valid2: false,

      bsearch: "",
      billings: [],
      bfetching: false,
      validBilling: false,
      billingDialog: false,

      editedBillingItem: {},
      defaultBillingItem: {},
      editedBillingIndex: -1,
      confirmSetupBilling: false,

      todayDate: moment().format("YYYY-MM-DD"),
      yesNoArray: [
        { key: true, value: "Yes" },
        { key: false, value: "No" },
      ],

      filters: {},
      storeOptions: {},
      storesObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      filterBy1: [
        { value: "New", text: "New" },
        { value: "Active", text: "Active" },
        { value: "AMC Due", text: "AMC Due" },
        { value: "Billing Due", text: "Billing Due" },
        { value: "Renewal Due", text: "Renewal Due" },
      ],
      filterBy2: [],
      filterBy3: [
        { value: "createdAt:" + todayDate, text: "Today" },
        { value: "createdAt:" + yesterdayDate, text: "Yesterday" },
        {
          value: "createdAt:" + oneWeekBefore + "," + todayDate,
          text: "Last one week",
        },
        {
          value: "createdAt:" + oneMonthBefore + "," + todayDate,
          text: "Last one month",
        },
        {
          value: "createdAt:" + threeMonthsBefore + "," + todayDate,
          text: "Last three months",
        },
        {
          value: "createdAt:" + sixMonthsBefore + "," + todayDate,
          text: "Last six months",
        },
        {
          value: "createdAt:" + oneYearBefore + "," + todayDate,
          text: "Last one year",
        },
      ],

      bheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Business", value: "store.appName" },
        { text: "Due Date", value: "dueDate" },
        { text: "Total Amount", value: "amount" },
        { text: "POS Amount", value: "posAmount" },
        { text: "Website Amount", value: "websiteAmount" },
        { text: "App / Dashboard Amount", value: "regularAmount" },
        { text: "Status", value: "status" },
        { text: "Actions", align: "right", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "Name is required"],
        statusRule: [(v) => v || v === false || "Status is required"],
        amountRule: [(v) => v >= 0 || "Enter a valid amount"],
      },
    };
  },
};

<template>
  <v-dialog class="thermal-invoice-dialog" v-model="$store.state.thermalInvoiceModal" max-width="425px">
    <v-progress-linear v-if="processing" indeterminate color="primary" class="mb-0"></v-progress-linear>
    <v-card style="width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto;">
      <ThermalInvoiceContent :_id="_id" :order="order"></ThermalInvoiceContent>
    </v-card>
  </v-dialog>  
</template>

<script>
import ThermalInvoiceContent from '../thermal-invoice-content/thermal-invoice-content.vue';

export default {
  name: 'ThermalInvoice',

  components: {
    ThermalInvoiceContent
  },

  props: [
    '_id',
    'order'
  ],

  data() {
    return {
      processing: false
    }
  }
}
</script>
<style src="./thermal-invoice.css"></style>
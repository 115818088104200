import moment from "moment";

import Store from "../../../services/store";
import FileUpload from "../../../services/file-upload";
import router from "../../../router";

import config from "../../../config";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "Deals",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Deals")) return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchDeals();
    this.fetchDealEnums();
  },

  watch: {
    searchInput(newVal, oldVal) {
      const array = newVal ? newVal.split("Standard") : [];
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        array.length === 1
      )
        this.querySelections(newVal);
    },
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    text(item) {
      return `${item.name} (${item.size}${
        item.unit
      }) - Standard Price: ${this.$options.filters.fc(
        item.price
      )} (Deal Price: ${this.$options.filters.fc(
        this.calculateDealPrice(item)
      )})`;
    },

    querySelections(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;
      return Store.searchProducts(this._id, keyword, false)
        .then((x) => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.products = x.data;
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    },

    productSelected() {
      this.lastSearched = this.selectedProduct.name;
      if (this.editedItem.items.find((x) => x._id === this.selectedProduct._id))
        return;

      this.selectedProduct.dealPrice = this.calculateDealPrice(
        this.selectedProduct
      );
      this.selectedProduct.index = this.editedItem.items.length;
      this.editedItem.items.push(this.selectedProduct);
      this.searchInput = "";
      this.selectedProduct = {};
    },

    calculateDealPrice(item) {
      let dealPrice = item.price;
      if (this.editedItem.discountBy === "amount")
        dealPrice = dealPrice - this.editedItem.discount;
      else if (this.editedItem.discountBy === "percent")
        dealPrice = dealPrice - (dealPrice * this.editedItem.discount) / 100;
      return dealPrice;
    },

    fetchDeals() {
      this.processing = this.loading = true;
      this._id = this.$route.params._id;
      return Store.getDeals(this._id, false)
        .then((x) => {
          this.deals = x.data;
          this.deals.forEach((x, index) => {
            x.index = index + 1 + ".";
            x.sd = x.startsAt ? moment(x.startsAt).format("ll") : "--";
            x.ed = x.endsAt ? moment(x.endsAt).format("ll") : "--";

            if (x.startsAt)
              x.startsAt = moment(x.startsAt).format("YYYY-MM-DD");
            if (x.endsAt) x.endsAt = moment(x.endsAt).format("YYYY-MM-DD");
          });
          this.processing = this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = false;
        });
    },

    fetchDealEnums() {
      return Store.getDealEnums(this._id)
        .then((res) => {
          this.enums = res.data;
        })
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.deals.indexOf(item);
      this.editedItem = Object.assign(
        {},
        this.editedIndex === -1 ? JSON.parse(JSON.stringify(item)) : item
      );
      this.editedItem.items.forEach((x, i) => (x.index = i));

      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteDeal(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchDeals();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    removeItem(item) {
      this.editedItem.items = this.editedItem.items.filter(
        (x) => x._id != item._id
      );
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      setTimeout(
        () => (this.editedItem = Object.assign({}, this.defaultItem)),
        100
      );
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true, true);
    },

    save(closeModal = true, fetchDeals = true, forceSave = false) {
      if (
        !forceSave &&
        (!this.editedItem.file ||
          (this.$refs.form && !this.$refs.form.validate()))
      ) {
        if (!this.editedItem.file) {
          this.showErr = "Image is required";
          setTimeout(() => {
            this.showErr = false;
          }, 2500);
        }

        this.$refs.form.validate();
        return;
      }

      Store.snackbarStart();
      this.processing = this.editedItem.processing = true;
      let request;

      const object = Object.assign({}, this.editedItem);
      object.items = object.items.map((x) => x._id);

      if (this.editedItem._id)
        request = Store.editDeal(this.editedItem._id, object, false);
      else request = Store.createDeal(this._id, object, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          if (fetchDeals) this.fetchDeals();
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          this.loading = false;
          Store.snackbarError();
        });
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      return file && file["type"].split("/")[0] === "image";
    },

    getURL(data) {
      if (data && data.split(".")[1] === "png") {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError() {
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.editedItem.file = "";
      this.editedItem.file = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(this._id, "deals", file, this.editedItem.type)
        .then((x) => {
          this.editedItem.file = x.data.file;
          this.uploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Create new deal" : "Edit deal";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data() {
    return {
      _id: "",

      deals: [],
      enums: {},

      menu: false,
      menu2: false,

      search: "",

      valid: false,
      processing: false,
      loading: false,
      uploading: false,
      showErr: false,
      dialog: false,

      searchInput: "",
      lastSearched: "",
      selectedProduct: {},
      products: [],

      rowItems: config.rowItems,

      editedIndex: -1,
      editedItem: {
        items: [],
      },
      defaultItem: {
        isActive: true,
        file: undefined,
        isAlways: false,
        items: [],
      },

      confirmDialog: false,
      beingDeleted: {},

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        { text: "Validity", value: "frequency" },
        { text: "Discount", value: "discount" },
        { text: "Available always?", value: "isAlways" },
        { text: "Start Date", value: "sd" },
        { text: "End Date", value: "ed" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      isSearching: false,
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,

      ploading: false,
      pheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Category", value: "category.name" },
        { text: "Price", value: "price" },
        { text: "Deal Price", value: "dealPrice" },
        { text: "Quantity", value: "quantity" },
        { text: "Size", value: "size" },
        { text: "Status", value: "isActive" },
        { text: "Remove", value: "actions", sortable: false, align: "center" },
      ],

      validationRules: {
        codeRule: [(v) => (v && v.length > 3) || "Code must be unique"],
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        datesRule: [(v) => v.length === 2 || "Both dates are required"],
        itemsRule: [(v) => !!v.length || "One or more products are required"],
        discountRule: [(v) => v > 0 || "Enter valid discount"],
        orderAmountRule: [
          (v) =>
            v === "" ||
            v === undefined ||
            v > 0 ||
            v === 0 ||
            "Enter valid amount",
        ],
      },
    };
  },
};

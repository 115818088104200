import moment from 'moment';

import Store from '../../../services/store';
import router from '../../../router';

import config from '../../../config';

export default {
  name: 'Requests',

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed('Requests')) return router.push('/business/'+this._id);

    if (!this.isVendor) this.headers.splice(1, 0, { text: this.covidCategory ? 'Partner' : 'Vendor',  align: 'start', value: 'store.name' })
      
    this.fenums = true;
    const p1 = this.fetchRequests();
    const p2 = this.fetchRequestsEnums();

    return Promise.all([p1, p2])
      .then(() => this.fenums = false)
      .catch(() => this.fenums = false);
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchRequests() {
      this.loading = true;
      const request = this.isVendor ? Store.getRequests(this._id, false) : Store.getOwnerRequests(this._id, false);
      return request
        .then(res => {
          this.requests = res.data.map((x, index) => {
            x.index = (index+1)+'.';
            x.updatedAtd = moment(x.updatedAt).format('lll');
            return x;
          });
          this.loading = false;
        })
        .catch(e => console.log(e));
    },

    fetchRequestsEnums() {
      return Store.getRequestsEnums(this._id)
        .then(res => {
          this.enums = res.data
          this.enums.type = this.enums.type.map(x => { 
            return { key: x, value: this.$options.filters.firstLetterUppercase(x) };
          }); 
        })
        .catch(e => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.requests.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteRequest(this.beingDeleted._id, false)
        .then(() => { this.confirmDialog = this.processing = false; this.fetchRequests(); })
        .catch(e => { this.confirmDialog = this.processing = false; console.log(e)});
    },

    close () {
      this.dialog = this.processing = this.loading = false
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedItem);
      if (this.editedItem._id) request = Store.editRequest(this.editedItem._id, object, false)
      else request = Store.createRequest(this._id, this.$store.state.activeStore.vendorOwner._id, object, false);
          
      return request 
        .then(() => {
          if (closeModal) this.close();
          this.fetchRequests();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          this.loading = false;
          Store.snackbarError();
        });
    },

    saveDates(dates) {
      this.$refs.menu.save(dates);
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false, false);
    },

    action(item, type) {
      this.editedItem = item;
      this.beingActedAction = type;
      this.confirmActionDialog = true;
    },

    closeActionConfirm() {
      this.editedItem = {};
      this.beingActedAction = '';
      this.confirmActionDialog = false;
    },

    proceedAction(justApprove) {
      if (this.beingActedAction === 'reject' && !this.$refs.form2.validate()) return;

      let request = Store.editRequest(this.editedItem._id, this.editedItem, false);
      if (justApprove === true || this.beingActedAction === 'reject') this.editedItem.status = this.beingActedAction === 'approve' ? 'approved' : 'rejected';
      else request = Store.processRequest(this.editedItem._id, this.editedItem, false);

      Store.snackbarStart();
      this.processing = true;
      return request
        .then(() => {
          this.processing = false;
          this.fetchRequests();
          this.closeActionConfirm();
          this.$store.state.counts.requests--;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          this.loading = false;
          Store.snackbarError();
        });
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Create new request' : 'Edit request';
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    covidCategory() {
      return Store.covidCategory(this.$store);
    },

    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  data: () => {
    return {
      _id: '',
      requests: [],
      enums: {},
      search: '',
      loading: true,
      valid: false,
      dialog: false,
      processing: false,

      error: '',

      menu: false,
      cenums: {},

      fenums: false,

      translationModal: false,
      translationField: '',

      dates: [],

      editedIndex: -1,
      editedItem: {
        dates: []
      },
      defaultItem: {
        dates: [],
        isActive: true
      },

      confirmDialog: false,
      beingDeleted: {},
      rowItems: config.rowItems,

      confirmActionDialog: false,
      beingActedAction: '',
      beingActed: {},

      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Title',  align: 'start', value: 'name' },
        { text: 'Requested', value: 'type' },
        { text: 'Status', value: 'status'},
        { text: 'Info', value: 'description' },
        { text: 'Last action at', value: 'updatedAtd' },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false}
      ],

      validationRules: {
        basicRule: [
          v => !!v || 'It is required'
        ],
        statusRule: [
          v => v || v === false || 'It is required'
        ],
        datesRule: [
          v => v.length === 2 || 'Both dates are required'
        ],
        discountRule: [
          v => v > 0 || 'Enter valid discount'
        ],
        orderAmountRule: [
          v => v === '' || v === undefined || v > 0 || v === 0 || 'Enter valid amount'
        ],
      }
    }
  }
}
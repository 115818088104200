import config from "../../config";
import Store from "../../services/store";

export default {
  name: "KYC",

  created() {
    if (!this.canProceed) return Store.redirectToHome();

    this.fetchStores();
  },

  methods: {
    fetchStores() {
      this.loading = true;
      return Store.getKYC()
        .then((x) => {
          const data = x.data;
          this.items = data.map((x, i) => {
            x.index = i;
            return x;
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    initVerification(item) {
      this.beingVerified = item;
      this.verifyModal = true;
    },

    openFile(item) {
      const s3URL = config.growcifyProjectsS3BucketURL;
      window.open(s3URL + item.file, "_system");
    },

    initReject(item, index) {
      this.beingRejectedItem = item;
      this.beingRejectedIndex = index;

      this.rejectAlert = true;
    },

    finalReject() {
      let docs = this.beingVerified.kycDocuments;
      docs = docs.filter((x, i) => i !== this.beingRejectedIndex);
      this.beingVerified.kycDocuments = docs;
      this.saveStoreInfo("rejected");
    },

    finalApprove() {
      this.saveStoreInfo("approved");
    },

    saveStoreInfo(status) {
      this.processing = true;
      const payload = {
        status: status,
        reason: this.beingRejectedReason,
        title: this.beingRejectedItem.title,
        documentType: this.beingRejectedItem.documentType,
        kycDocuments: this.beingVerified.kycDocuments,
      };

      return Store.updateKYC(this.beingVerified._id, payload)
        .then((r) => {
          this.rejectAlert = this.approveAlert = false;
          if (status == "approved") this.verifyModal = false;
          this.processing = false;
          this.fetchStores();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },
  },

  computed: {
    canProceed() {
      return (
        (Store.amISuperadmin(this.$store) ||
          Store.amIGrowcifyEmployee(this.$store)) &&
        this.$store.state.isGD
      );
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data() {
    return {
      loading: false,
      items: [],
      search: "",

      verifyModal: false,
      processing: false,
      beingVerified: {},
      beingRejectedItem: {},
      beingRejectedIndex: false,
      beingRejectedReason: "",

      rejectAlert: false,
      approveAlert: false,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "appName" },
        { text: "Documents", value: "kycDocuments.length" },
        { text: "KYC Status", value: "kycApproved" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
      },
    };
  },
};

export default {
  apiDev: "http://localhost:9000/",
  apiProd: "https://api.growcify.com/",

  experiencesS3PhotoURL:
    "https://localites-user-experiences.s3.ap-south-1.amazonaws.com/",
  itinerariesS3PhotoURL:
    "https://localites-user-itineraries.s3.ap-south-1.amazonaws.com/",
  usersS3PhotoURL:
    "https://localites-user-photos.s3-ap-northeast-1.amazonaws.com/",

  fImageURL: "https://fakeimg.pl/480x480/0cd1e8,128/000,255?font=noto&text=",
  fDarkLURL: "https://fakeimg.pl/480x480/000000/?font=noto&text=",

  newOrderSound:
    "https://growcify.s3-us-west-1.amazonaws.com/mp3/new-order-notification.mp3",

  grocifyMasterInventory: "grocify-master-inventory",
  growcifyMasterInventory: "growcify-master-inventory",
  growcifyProjectsS3Bucket: "growcify-projects",
  growcifyProjectsS3BucketURL: "https://growcify-projects.s3.amazonaws.com/",

  grocifyDotCo: "grocify-dot-co",
  growcifyS3: "https://growcify.s3-us-west-1.amazonaws.com/",
  rowItems: [2, 5, 10, 15, 20, 25, 50, 75, 100, 150, 200, 250, 500],
  checkmarkAnimationJson:
    "https://assets3.lottiefiles.com/packages/lf20_pmYw5P.json",
  growcifyLogo: "https://growcify.com/images/logo.png",

  reCAPTCHAKey: "6LeeS8caAAAAAG5S0BRime1VvlvyPUoW-9qUs-2Y",

  meta: {
    placeholder: "Type here...",
    bsaveMsg: "Saving...",
    bsMsg: "Modified successfully.",
    bcMsg: "No new change detected.",

    beMsg: "Something went wrong. Please try again.",
    befMsg: "Something is yet to fill correctly. Please review the form.",
    bmneMsg: "Mobile number is already registered.",
    beeMsg: "Email is already registered.",
  },

  messages: [
    {
      id: 1,
      templateId: "lead-next-steps",
      title: "Next steps",
      flow_id: "6030a554522da735b2287060",
      message: `Hello ##name##, \r\n\r\nThanks for your time. Here's the link to Growcify Demo App: onelink.to/sg9hbb and Growcify Brochure: bit.ly/growcify-brochure. And please follow these steps to register your business: youtu.be/crb7uSxT_HA. In case you still need help, you can reach us via WhatsApp or call. \r\n\r\nRegards,\r\nThe Growcify Team\r\nCall: +918005771838\r\nhttps://growcify.com`,
      wamessage: `Hello ##name##, \r\n\r\nThanks for your time. Here are the links: \r\n\r\nApps developed by Growcify for other businesses: https://play.google.com/store/apps/dev?id=6776983213289151784 \r\n\r\nAndroid Demo App: https://play.google.com/store/apps/details?id=co.grocify\r\n\r\niOS Demo App: https://apps.apple.com/app/id1534266201\r\n\r\nWebsite Demo: https://web.growcify.com\r\n\r\nGrowcify Webstore Video: https://youtu.be/r0sFSGqYBuo\r\n\r\nGrowcify Ad Film Video: https://youtu.be/q2dJcHoCugM\r\n\r\nPlease follow these steps to register your business: https://youtu.be/8k1EuKUCz_M \r\n\r\nWebsite: https://growcify.com \r\n\r\n[Note: Please save the number to see links as WhatsApp doesn't display links unless the number is in your contacts.] \r\n\r\nIn case you still need help, you can reach us via WhatsApp or call. \r\n\r\nRegards,\r\nThe Growcify Team\r\nCall: +918005771838\r\nhttps://growcify.com`,
    },
    {
      id: 2,
      templateId: "lead-unreachable",
      title: "We could not reach you",
      flow_id: "6030a914ef74cf6aea21f4b9",
      message: `Hello ##name##, \r\n\r\nWe tried to reach you but couldn't. Give us a call back when you're available. And to help you, here's the link to the Growcify Demo App: onelink.to/sg9hbb and Growcify Brochure: bit.ly/growcify-brochure. And please follow these steps to register your business: youtu.be/crb7uSxT_HA. In case you still need help, you can reach us via call or WhatsApp. \r\n\r\nRegards,\r\nThe Growcify Team\r\nCall: +918005771838\r\nhttps://growcify.com`,
    },
    {
      id: 3,
      templateId: "lead-old",
      title: "Old lead message",
      flow_id: "60432cb252e60c6387232bea",
      message: `Hello ##name##, \r\n\r\nGreetings from Growcify, best place to get your e-commerce app made.\r\nYou had reached earlier, but sadly we could not connect. In case you are still looking for a complete e-commerce solution reach us at +918005771838 over Call/WhatsApp or visit our website for more details: https://growcify.com.\r\n\r\nRegards,\r\nThe Growcify Team\r\nCall: +918005771838\r\nhttps://growcify.com`,
    },
    {
      id: 4,
      templateId: "business-renewal-due",
      title: "Your renewal is due",
      flow_id: "60432d57baa9d375fc302a28",
      message: `Hello ##name##, \r\n\r\nYour plan has expired. To avoid any service disruption, please renew it at earliest. In case you need help, you can reach us via call or WhatsApp. \r\n\r\nRegards,\r\nThe Growcify Team\r\nCall: +918005771838\r\nhttps://growcify.com`,
    },
  ],

  keys: {
    //razorpayKey: 'rzp_test_NNjmkFKjZ9glv5',
    razorpayKey: "rzp_live_zO9BnTCHTqtlHv",
  },

  shipment: {
    minBalance: 200,
  },

  heroku: {
    mlab: "",
  },

  atlas: {
    username: "",
    password: "",
    uri: "",
  },

  grocify: {
    website: "https://growcify.com/",
    sampleProductsCSV: "files/Growcify_Products_CSV.csv",
    sampleCustomersCSV: "files/Growcify_Customers_CSV.csv",
    sampleTranslationsCSV: "files/Growcify_Translations.csv",
  },

  settingsSlug: [
    "general",
    "app-information",
    "orders",
    "domain",
    "business-hours",
    "subscriptions",
    "wallet",
    "referral",
    "invoice",
    "purchase",
    "localization",
    "sound-and-notifications",
    "app-ui",
    "multivendor",
    "sms",
    "shipment",
    "email",
    "whatsapp",
    "pickup-drop",
    "social-media",
    "add-ons",
    "documents",
  ],
  gst: 18,

  templates: [
    {
      _id: 1,
      title: "SMST1",
      text:
        "A new order has been assigned to you by {#var#}. Good luck with the delivery. Thank you. - ",
    },
    {
      _id: 2,
      title: "SMST2",
      text:
        "Your order of {#var#} has been shipped and it is on the way. Thank you for shopping from {#var#}. - ",
    },
    {
      _id: 3,
      title: "SMST3",
      text:
        "Your refund of {#var#} has been processed successfully. It should reflect in your {#var#} wallet. Thank you. - ",
    },
    {
      _id: 4,
      title: "SMST4",
      text:
        "Your order of {#var#} has been delivered successfully. Thank you for shopping from {#var#}. - ",
    },
    {
      _id: 5,
      title: "SMST5",
      text:
        "Your subscription order will not be processed due to an insufficient balance in your wallet. Please contact {#var#} team in case of any queries. - ",
    },
    {
      _id: 6,
      title: "SMST6",
      text:
        "Your order of {#var#} has been cancelled. Please contact {#var#} team for more. Thank you. - ",
    },
    {
      _id: 7,
      title: "SMST7",
      text:
        "Your subscription has been cancelled successfully. In case of any query, please contact {#var#} team. Thank you. - ",
    },
    {
      _id: 8,
      title: "SMST8",
      text:
        "Dear Customer, {#var#} has requested payment of {#var#}. You can pay through this link: {#var#} - ",
    },
    {
      _id: 9,
      title: "SMST9",
      text:
        "Your verification code for {#var#} is {#var#}. It'll expire in 10 minutes. - ",
    },
    {
      _id: 10,
      title: "SMST10",
      text:
        "Your order of {#var#} has been placed successfully. We will keep you posted for further updates. REF#: {#var#}. Thank you for shopping from {#var#}. - ",
    },
    {
      _id: 11,
      title: "SMST11",
      text:
        "Your subscription has been activated successfully. We will keep you posted for further updates. REF#: {#var#}. Thank you for shopping from {#var#}. - ",
    },
    {
      _id: 12,
      title: "SMST12",
      text:
        "Your order of {#var#} has been accepted and it is being processed. Thank you for shopping from {#var#}. - ",
    },
    {
      _id: 13,
      title: "SMST13",
      text:
        "You have received a new order of {#var#}. Please open the {#var#} dashboard to view items and the address. Thank you. - ",
    },
    {
      _id: 14,
      title: "SMST14",
      text:
        "You have received a new subscription of {#var#}. Please open the {#var#} dashboard to view items and the address. Thank you. - ",
    },
    {
      _id: 15,
      title: "SMST15",
      text:
        "Dear Customer, {#var#} has requested payment of {#var#}. You can pay through this link: {#var#} - ",
    },
  ],
};

<template>
  <section>
    <v-dialog fullscreen scrollable v-model="$store.state.leadTaskHistoryModal" persistent>
      <v-progress-linear v-if="processing" indeterminate color="primary" class="mb-0"></v-progress-linear>
      <v-card>
        <v-card-text style="padding-top: 15px;">
          <v-row>
            <v-col cols="1" class="vc" style="margin-right: -15px;">
              <v-btn fab icon outlined @click="$store.state.leadTaskHistoryModal = false" :color="isDT ? 'white' : 'black'">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>

            <v-col  class="vc" style="padding-left: 0">
              <h5 style="margin-bottom: 3px;" :class="tc">
                <b>Activities</b>
              </h5>
              <div :class="tc" v-if="filter.dates">
                By <b>{{ filter.by ? filter.byName : 'all' }}</b> {{filter.dates.length > 1 ? 'between' : 'on'}} <b>{{ formatDate(filter.dates[0], 'll') }}</b> - <b>{{ formatDate(filter.dates[1] ? filter.dates[1] : filter.dates[0], 'll') }}</b>
              </div>
            </v-col>

            <v-col cols="1" :class="['vc text-center', tc]">
              <h5 style="margin-bottom: 3px;">
                <b>{{ calls.length }}</b>
              </h5>
              <div>Calls</div>
            </v-col>


            <v-col cols="1" :class="['vc text-center', tc]">
              <h5 style="margin-bottom: 3px;">
                <b>{{ meetings.length }}</b>
              </h5>
              <div>Meetings</div>
            </v-col>

            <v-col cols="1" :class="['vc text-center', tc]">
              <h5 style="margin-bottom: 3px;">
                <b>{{ leads.length }}</b>
              </h5>
              <div>Leads</div>
            </v-col>

            <v-col cols="1" :class="['vc text-center', tc]">
              <h5 style="margin-bottom: 3px;">
                <b>{{ smsHistory.length }}</b>
              </h5>
              <div>Messages</div>
            </v-col>

            <v-col cols="2" :class="['vc text-center', tc]">
              <h5 style="margin-bottom: 3px;">
                <b>{{ totalCallingHours }}</b>
              </h5>
              <div>
                Total Calling Hours
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col style="padding-top: 0;"><v-divider></v-divider></v-col>
          </v-row>

          <section v-if="!filter.by && callingHours.length">
            <v-row>
              <v-col style="padding-top: 5px">
                <v-list-item-subtitle>
                  <b :class="tc">Calling Hours</b>
                </v-list-item-subtitle>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3" v-for="item in callingHours" :key="item._id">
                <v-card outlined style="border-radius: 5px;">
                  <div style="padding-left: 16px; padding-right: 16px">
                    <v-row>
                      <v-col cols="9">
                        <span><b>{{ formatHours(item.seconds) }}</b></span>
                        <div><small style="font-size: 14px">{{ item.name }}</small></div>
                      </v-col>
                      <v-col class="vc text-right">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <section v-bind="attrs" v-on="on">
                              <v-icon>mdi-information-variant</v-icon>
                            </section>
                          </template>
                          <span>Total calling hours of {{ item.name }} on {{ formatDate(filter.on, 'll') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>

              </v-col>
            </v-row>

            <v-row>
              <v-col><v-divider></v-divider></v-col>
            </v-row>
          </section>

          <LeadTaskHistoryItems 
            :tasks="['calls', 'meetings', 'leads', 'sms']"
            :cloading="cloading"
            :calls="calls"
            :leads="leads"
            :mloading="mloading"
            :meetings="meetings"
            :sloading="sloading"
            :sms-history="smsHistory"></LeadTaskHistoryItems>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import moment from 'moment';
import CRM from '../../services/crm';
import LeadTaskHistoryItems from '../../components/lead-task-history-items/lead-task-history-items.vue';

export default {
  name: 'LeadTaskHistory',

  components: {
    LeadTaskHistoryItems
  },

  props: [
    'filter',
    'users'
  ],

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? 'text-white' : 'text-black';
    }
  },

  methods: {
    formatDate(date, format = 'lll') {
      return moment(date).format(format);
    },

    fetchMeetingsHistory() {
      this.mloading = true;
      return CRM
        .getMeetingsHistory(this.filter, false)
        .then(x => {
          this.meetings = x.data.map((x, index) => { x.index = index + 1 + '.'; return x; });
          this.mloading = false;
        })
        .catch(e => {
          console.log(e);
          this.mloading = false;
        });
    },

    fetchSMSHistory() {
      this.sloading = true;
      return CRM
        .getSMSHistory(this.filter, false)
        .then(x => {
          this.smsHistory = x.data.map((x, index) => { x.index = index + 1 + '.'; return x; });
          this.sloading = false;
        })
        .catch(e => {
          console.log(e);
          this.sloading = false;
        });
    },

    fetchCallsHistory() {
      this.cloading = true;
      return CRM
        .getCallsHistory(this.filter, false)
        .then(x => {
          this.calls = x.data.map((x, index) => { x.index = index + 1 + '.'; return x; });
          this.cloading = false;
          this.calculateCallingHours();
          this.processCallingHours();
        })
        .catch(e => {
          console.log(e);
          this.cloading = false;
        });
    },

    fetchLeadsHistory() {
      this.lloading = true;
      return CRM
        .getLeadsHistory(this.filter, false)
        .then(x => {
          this.leads = x.data.map((x, index) => { x.index = index + 1 + '.'; return x; });
          this.lloading = false;
        })
        .catch(e => {
          console.log(e);
          this.lloading = false;
        });
    },

    calculateCallingHours() {
      let durations = this.calls.map(x => x.duration).filter(x => x && x != '00:00');
      if (!durations.length) return;

      let totalSeconds = 0;
      durations.forEach(x => totalSeconds = totalSeconds + this.calculateSeconds(x));
      this.totalCallingHours = this.formatHours(totalSeconds);
    },

    processCallingHours() {
      const callsDurationUsers = [];
      this.calls.forEach(x => {
        if (x.duration) {
          const existingItem = callsDurationUsers.find(u => u._id == x.createdBy._id);
          if (existingItem) existingItem.seconds += this.calculateSeconds(x.duration);
          else {
            callsDurationUsers.push({
              _id: x.createdBy._id,
              name: x.createdBy.name,
              seconds: this.calculateSeconds(x.duration)
            });
          }
        }
      });
      this.callingHours = callsDurationUsers;
    },

    calculateSeconds(timeString) {
      const array = timeString.split(':');
      let totalMinutes = parseInt(array[0]);
      let totalSeconds = parseInt(array[1]);
      return (totalMinutes * 60) + totalSeconds;
    },

    formatHours(d) {
      d = Number(d);
      var hours = Math.floor(d / 3600);
      var minutes = Math.floor(d % 3600 / 60);
      var seconds = Math.floor(d % 3600 % 60);

      let timeString = (hours >= 0 ? (hours.toString().padStart(1, '0') + 'h, ') : '') + 
         (minutes >= 0 ? minutes.toString().padStart(1, '0') + 'm, ' : '') + 
         (seconds >= 0 ? seconds.toString().padStart(1, '0') + 's' : '');

      return timeString;
    }
  },

  watch: {
    '$store.state.leadTaskHistoryModal'(n) {
      if (n) {
        //reset on open
        this.calls = [];
        this.meetings = [];
        this.smsHistory = [];
        this.callingHours = [];

        this.totalCallingHours = '0h, 0m, 0s';

        //fetch on open
        this.fetchMeetingsHistory();
        this.fetchSMSHistory();
        this.fetchCallsHistory();
        this.fetchLeadsHistory();
      }
    }
  },

  data() {
    return {
      processing: false,

      cloading: false,
      mloading: false,
      sloading: false,
      lloading: false,

      calls: [],
      leads: [],
      meetings: [],
      smsHistory: [],

      totalCallingHours: '0h, 0m, 0s',
      callingHours: []
    }
  }
}
</script>

<template>
  <v-dialog v-model="$store.state.barcodesModal" max-width="800">
    <v-progress-linear
      v-if="processing"
      indeterminate
      color="primary"
      class="mb-0"
    ></v-progress-linear>
    <v-card class="pl-4 pr-4">
      <v-row>
        <v-col cols="5" class="vc">
          <h5 class="m-0">Generate Barcodes</h5>
          <small>Customize and print barcodes</small>
        </v-col>

        <v-col class="vc text-right">
          <v-btn :disabled="processing" outlined @click="printBarcodes">
            <v-icon>mdi-printer</v-icon>
          </v-btn>

          <v-btn
            class="ml-2"
            :disabled="processing"
            outlined
            @click="saveSettings"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>

          <v-btn
            class="ml-2"
            :disabled="processing"
            outlined
            @click="expanded = !expanded"
          >
            <v-icon>mdi-settings</v-icon>
          </v-btn>

          <v-btn
            class="ml-2"
            outlined
            @click="$store.state.barcodesModal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <section v-if="!expanded" class="animate__animated animate__fadeIn">
        <v-divider></v-divider>
        <v-row>
          <v-col class="p0">
            <v-checkbox
              class="mt-0"
              hide-details
              label="Border"
              v-model="border"
            ></v-checkbox>
          </v-col>
          <v-col class="p0">
            <v-checkbox
              class="mt-0"
              hide-details
              label="Packaging Date"
              v-model="packagingDate"
            ></v-checkbox>
          </v-col>
          <v-col class="p0">
            <v-checkbox
              class="mt-0"
              hide-details
              label="Expiry Date"
              v-model="expiryDate"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="p0">
            <v-checkbox hide-details label="Name" v-model="name"></v-checkbox>
          </v-col>
          <v-col class="p0">
            <v-checkbox hide-details label="M.R.P." v-model="mrp"></v-checkbox>
          </v-col>
          <v-col class="p0">
            <v-checkbox
              hide-details
              label="Selling Price"
              v-model="price"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12"></v-col>
          <v-col class="p0" cols="4">
            <v-checkbox
              class="mt-0"
              hide-details
              label="Note"
              v-model="note"
            ></v-checkbox>
          </v-col>

          <v-col class="p0" cols="4">
            <v-checkbox
              class="mt-0"
              hide-details
              label="Flip?"
              v-model="flip"
            ></v-checkbox>
          </v-col>

          <v-col class="p0" cols="4">
            <v-checkbox
              class="mt-0"
              hide-details
              label="Longer?"
              v-model="longer"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="p0 mt-5" cols="4">
            <v-select
              hide-details
              dense
              outlined
              label="Alignment"
              v-model="align"
              :items="['center', 'left', 'right']"
            >
            </v-select>
          </v-col>

          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Barcode Height"
              v-model="height"
              suffix="px"
            >
            </v-text-field>
          </v-col>

          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Barcode Width"
              v-model="width"
              suffix="px"
            >
            </v-text-field>
          </v-col>

          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Padding Top"
              v-model="paddingTop"
              suffix="px"
            >
            </v-text-field>
          </v-col>

          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Padding Bottom"
              v-model="paddingBottom"
              suffix="px"
            >
            </v-text-field>
          </v-col>

          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Padding Left"
              v-model="paddingLeft"
              suffix="px"
            >
            </v-text-field>
          </v-col>

          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Padding Right"
              v-model="paddingRight"
              suffix="px"
            >
            </v-text-field>
          </v-col>

          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Middle Space"
              v-model="middleSpace"
              suffix="px"
            >
            </v-text-field>
          </v-col>

          <v-col class="p0 mt-5" cols="12">
            <span>
              <b>Paper Margins</b>
            </span>
          </v-col>

          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Margin Top"
              v-model="marginTop"
              suffix="px"
            >
            </v-text-field>
          </v-col>
          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Margin Bottom"
              v-model="marginBottom"
              suffix="px"
            >
            </v-text-field>
          </v-col>
          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Margin Left"
              v-model="marginLeft"
              suffix="px"
            >
            </v-text-field>
          </v-col>
          <v-col class="p0 mt-5" cols="4">
            <v-text-field
              hide-details
              dense
              outlined
              label="Margin Right"
              v-model="marginRight"
              suffix="px"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="note">
          <v-col class="mt-3 animate__animated animate__fadeIn">
            <v-text-field
              hide-details
              outlined
              v-model="noteText"
              placeholder="Enter here..."
            ></v-text-field>
          </v-col>
        </v-row>

        <div style="height: 15px"></div>
      </section>

      <v-divider></v-divider>

      <div
        class="p-2"
        style="width: 340px; margin: auto; padding-top: 4px !important"
      >
        <section
          id="barcodes"
          :style="
            'margin-left:' +
              marginLeft +
              'px; margin-right:' +
              marginRight +
              'px; margin-top:' +
              marginTop +
              'px; margin-bottom:' +
              marginBottom +
              'px'
          "
        >
          <section v-for="item in items" :key="item._id">
            <v-card
              :outlined="border"
              :flat="!border"
              :style="
                'border-radius: 8px; padding-left:' +
                  paddingLeft +
                  'px; padding-right:' +
                  paddingRight +
                  'px; padding-top:' +
                  paddingTop +
                  'px; padding-bottom:' +
                  paddingBottom +
                  'px'
              "
              :class="['barcode-item', 'text-' + align, flip ? 'reverse' : '']"
            >
              <div>
                <div
                  v-if="name"
                  :style="
                    (mrp && item.marketPrice) || price
                      ? 'margin-bottom: -2px;'
                      : 'margin-bottom: 0px;'
                  "
                >
                  <small class="item-text"
                    ><b
                      >{{ item.name }} - {{ item.size }}{{ item.unit }}</b
                    ></small
                  >
                </div>
                <span class="item-text" v-if="mrp && item.marketPrice"
                  ><small class="item-text"
                    >M.R.P.: {{ item.marketPrice | fc }}</small
                  ></span
                >
                <span v-if="mrp && item.marketPrice && price" class="mr-2 ml-2"
                  >&bull;</span
                >
                <span class="item-text" v-if="price"
                  ><small class="item-text"
                    >PRICE: {{ item.price | fc }}</small
                  ></span
                >
              </div>
              <img
                :id="'barcode-' + item._id"
                :height="height"
                :width="width"
              />
              <div v-if="packagingDate || expiryDate" style="margin-top: -8px">
                <span v-if="packagingDate"
                  ><small class="item-text"
                    >PKG DATE: {{ formatDate() }}</small
                  ></span
                >
                <div v-if="expiryDate && item.expiryDate">
                  <small class="item-text"
                    >EXP DATE: {{ formatDate(item.expiryDate) }}</small
                  >
                </div>
              </div>
              <div v-if="note && noteText" style="margin-top: -8px">
                <small>{{ noteText }}</small>
              </div>
            </v-card>

            <div :style="`height: ${middleSpace}px`"></div>
          </section>
        </section>
      </div>
    </v-card>
  </v-dialog>
</template>

<style>
.barcode-item {
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "monospace" !important;
}
.barcode-item small {
  font-size: 0.5rem;
}
.barcode-border {
  border: 1px solid rgba(0, 0, 0, 0.87);
}
.reverse {
  transform: rotate(-180deg);
  transform-origin: right, top;
  -ms-transform: rotate(-180deg);
  -ms-transform-origin: right, top;
  -webkit-transform: rotate(-180deg);
  -webkit-transform-origin: right, top;
}
</style>

<script type="text/javascript">
import * as JsBarcode from "jsbarcode";
import { Printd } from "printd";
import moment from "moment";

import Store from "../../services/store";

export default {
  methods: {
    generateBarcode(id, item) {
      const data = this.longer
        ? item.barcodeID || item._id
        : item.barcodeID || item.uuid;

      JsBarcode("#barcode-" + id, data, {
        text: this.getBarcodeText(item),
        textMargin: 5,
      });
    },

    getBarcodeText(item) {
      if (item.barcodeText) return item.barcodeText;
      const data = this.longer
        ? item.barcodeID || item._id
        : item.barcodeID || item.uuid;
      return data.toUpperCase();
      //return item.name +' - '+item.size+item.unit;
    },

    processBarcodes() {
      this.items.forEach((item) => {
        this.generateBarcode(item._id, item);
      });
    },

    printBarcodes() {
      const cssText = `
          .barcode-item {
            margin-top: 15px;
            margin-bottom: 15px;
            border-radius: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            font-family: 'monospace' !important;
          }
          .barcode-item small {
            font-size: 0.5rem;
          }
          .barcode-border {
            border: 1px solid rgba(0, 0, 0, 0.87);
          }
          .v-card.v-card--outlined {
            border: thin solid rgba(0, 0, 0, 0.12);
          }
          .mr-2 {
            margin-right: 8px !important;
          }
          .ml-2 {
            margin-left: 8px !important;
          }
          .text-center {
            text-align: center !important;
          }
          .reverse {
            transform: rotate(-180deg);
            transform-origin: right, top;
            -ms-transform: rotate(-180deg);
            -ms-transform-origin:right, top;
            -webkit-transform: rotate(-180deg);
            -webkit-transform-origin:right, top;
          }
          .item-text {
            font-size: 11px !important;
            font-family: 'monospace' !important;
          }
        `;
      const d = new Printd();
      d.print(document.getElementById("barcodes"), [cssText]);
    },

    formatDate(date) {
      return date
        ? moment(date).format("DD-MM-YYYY")
        : moment().format("DD-MM-YYYY");
    },

    saveSettings() {
      const barcodeSettings = {
        name: this.name,
        border: this.border,
        expiryDate: this.expiryDate,
        packagingDate: this.packagingDate,
        mrp: this.mrp,
        price: this.price,
        note: this.note,
        noteText: this.noteText,

        height: this.height,
        width: this.width,
        align: this.align,
        flip: this.flip,
        longer: this.longer,
        paddingTop: this.paddingTop,
        paddingBottom: this.paddingBottom,
        paddingLeft: this.paddingLeft,
        paddingRight: this.paddingRight,
      };
      localStorage.setItem(
        this.$store.state.activeStore._id + "_barcodeSettings",
        JSON.stringify(barcodeSettings)
      );
      Store.snackbarSuccess();
    },
  },

  props: ["items"],

  watch: {
    "$store.state.barcodesModal"(n) {
      if (n) {
        this.processing = true;
        setTimeout(() => {
          this.processBarcodes();
          this.processing = false;
        }, 1000);

        //find barcodeSettings
        const barcodeSettings = localStorage.getItem(
          this.$store.state.activeStore._id + "_barcodeSettings"
        );
        if (barcodeSettings) {
          const bc = JSON.parse(barcodeSettings);
          this.name = bc.name;
          this.border = bc.border;
          this.expiryDate = bc.expiryDate;
          this.packagingDate = bc.packagingDate;
          this.mrp = bc.mrp;
          this.price = bc.price;
          this.note = bc.note;
          this.noteText = bc.noteText;

          this.height = bc.height;
          this.width = bc.width;
          this.align = bc.align;
          this.flip = bc.flip;
          this.paddingTop = bc.paddingTop;
          this.paddingBottom = bc.paddingBottom;
          this.paddingBottom = bc.paddingBottom;
          this.paddingRight = bc.paddingRight;
        }
      }
    },

    longer() {
      this.processBarcodes();
    },
  },

  data() {
    return {
      processing: false,
      expanded: true,

      name: true,
      border: true,
      expiryDate: false,
      packagingDate: false,
      mrp: false,
      price: false,
      note: false,
      noteText: "",

      height: 80,
      width: 250,
      align: "center",
      flip: false,
      longer: false,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      middleSpace: 0,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    };
  },
};
</script>

import Store from '../../../services/store';
import router from '../../../router';
import config from '../../../config';

import PickupAddress from '../../../components/pickup-address/pickup-address.vue';

export default {
  name: 'Pickup',

  components: {
    PickupAddress
  },

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed('Orders')) return router.push('/business/'+this._id);
    if (!this.doIHaveAccess('actions')) this.headers = this.headers.filter(x => x.text !== 'Actions');
    this.fetchPickupAddresses();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchPickupAddresses() {
      this.loading = true;
      return Store.getPickupAddresses(this._id, false)
        .then(res => {
          this.addressModified = false;
          this.addresses = res.data.map((x, index) => {
            x.index = (index+1)+'.';
            return x;
          });
          this.loading = false;
        })
        .catch(e => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.addresses.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$store.state.pickupAddressModal = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deletePickupAddress(this.beingDeleted._id, false)
        .then(() => { this.confirmDialog = this.processing = false; this.fetchPickupAddresses(); })
        .catch(e => { this.confirmDialog = this.processing = false; console.log(e)});
    },
    
    changeStatus(item) {
      this.editedItem = item;
      this.updateAddress();
    },

    updateAddress() {
      Store.snackbarStart();
      return Store
        .editPickupAddress(this.editedItem._id, this.editedItem, false) 
        .then(() => {
          this.processing = this.editedItem.processing = false;
          this.fetchPickupAddresses();
          Store.snackbarSuccess();
        })
        .catch(e => {
          console.log(e);
          this.processing = this.editedItem.processing = false;
          Store.snackbarError();
        });
    }
  },

  watch: {
    addressModified(n) {
      if (n) this.fetchPickupAddresses();
    }
  },

  data() {
    return {
      _id: '',
      search: '',
      rowItems: config.rowItems,

      addresses: [],

      dialog: false,
      loading: false,
      processing: false,
      address: {},
      defaultItem: {},
      editedItem: {
        isActive: true
      },
      editedIndex: -1,

      addressModified: false,
      confirmDialog: false,
      beingDeleted: {},

      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name', value: 'name' },
        { text: 'Mobile', value: 'mobile' },
        { text: 'Email', value: 'email' },
        { text: 'Address', value: 'street' },
        { text: 'City', value: 'city' },
        { text: 'State', value: 'state' },
        { text: 'Country', value: 'country' },
        { text: 'Status', value: 'isActive' },
        { text: 'Actions', value: 'actions' }
      ]
    }
  }
}
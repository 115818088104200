<template>
  <v-dialog
    v-model="$store.state.contractFormModal"
    :persistent="uploading || processing"
    max-width="750px"
  >
    <v-progress-linear
      v-if="processing"
      indeterminate
      color="primary"
      class="mb-0"
    ></v-progress-linear>
    <v-card>
      <v-form ref="form" v-model="valid">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pt-0">
                <h5 class="mt-0 mb-1">{{ formTitle }}</h5>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  v-model="editedItem.title"
                  label="Title"
                  required
                  :rules="validationRules.basicRule"
                  :disabled="processing"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :disabled="loading || processing"
                      v-model="editedItem.expiresAt"
                      label="Set Expiry Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="editedItem.expiresAt"
                    :min="new Date().toISOString().substr(0, 10)"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  v-model="editedItem.signedBy"
                  label="Signed By"
                  required
                  :rules="validationRules.basicRule"
                  :disabled="processing"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  v-model="editedItem.acceptedBy"
                  label="Accepted By"
                  required
                  :rules="validationRules.basicRule"
                  :disabled="processing"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  required
                  outlined
                  v-model="editedItem.isActive"
                  :items="[
                    { key: true, value: 'Active' },
                    { key: false, value: 'Inactive' },
                  ]"
                  label="Status"
                  item-value="key"
                  item-text="value"
                  :disabled="loading || processing"
                  :rules="validationRules.statusRule"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  outlined
                  rows="2"
                  v-model="editedItem.description"
                  label="Description"
                  :disabled="processing"
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-card
                  outlined
                  style="padding: 25px; border: 1px solid var(--primary)"
                  class="text-center"
                >
                  <v-chip
                    @click="openDocument(item)"
                    class="mb-2 mr-2"
                    close
                    :disabled="uploading || processing"
                    v-for="item in editedItem.files"
                    :key="item._id"
                    @click:close="removeDocument(item)"
                  >
                    {{ item.name }} ({{ bytesToSize(item.size) }})
                  </v-chip>

                  <form enctype="multipart/form-data">
                    <input
                      v-show="false"
                      ref="inputUpload"
                      name="file"
                      type="file"
                      @change="fileSelected()"
                    />
                  </form>

                  <v-btn
                    @click="pickFile"
                    text
                    color="primary"
                    :loading="uploading"
                    :disabled="uploading || processing"
                  >
                    <v-icon left>mdi-upload</v-icon> Upload Contract Document
                  </v-btn>
                  <div
                    v-if="!uploading"
                    @click="pickFile"
                    style="color: var(--primary); margin-top: -5px; text-transform: uppercase; cursor: pointer;"
                  >
                    <small
                      >Supported formats:
                      {{ supportedFormats.join(", ") }}</small
                    >
                  </div>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  cache-items
                  outlined
                  v-model="selectedProduct"
                  :items="products"
                  :search-input.sync="searchInput"
                  :disabled="processing"
                  :item-text="productText"
                  return-object
                  :loading="isSearching"
                  placeholder="Search items..."
                  @change="productSelected()"
                  hide-details
                  hide-no-data
                ></v-autocomplete>
              </v-col>

              <v-col class="col-12">
                <v-data-table
                  :headers="pheaders"
                  :items="editedItem.items"
                  :loading="ploading"
                  loading-text="Loading..."
                >
                  <template v-slot:item.index="{ item }">
                    {{ item.index + 1 }}.
                  </template>

                  <template v-slot:item.name="{ item }">
                    <TextWrapper
                      v-if="item"
                      :text="item.name"
                      :length="25"
                    ></TextWrapper>
                  </template>

                  <template v-slot:item.size="{ item }">
                    {{ item.size }}{{ item.unit }}
                  </template>

                  <template v-slot:item.price="{ item }">
                    <v-text-field
                      style="max-width: 100px; margin-top: 0; padding-top: 0"
                      v-model="item.price"
                      hide-details
                      :prefix="$store.state.country.currencySymbol || '₹'"
                      :rules="validationRules.numberRule"
                    ></v-text-field>
                  </template>

                  <template v-slot:item.minOrderCap="{ item }">
                    <v-text-field
                      style="max-width: 80px; margin-top: 0; padding-top: 0; text-align: center"
                      v-model="item.minOrderCap"
                      hide-details
                      :rules="validationRules.numberRule"
                    ></v-text-field>
                  </template>

                  <template v-slot:item.dealPrice="{ item }">
                    <b>{{ calculateDealPrice(item) | fc }}</b>
                  </template>

                  <template v-slot:item.isActive="{ item }">
                    {{ item.isActive ? "Active" : "Inactive" }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      color="error"
                      small
                      class="mr-2"
                      @click="removeItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12"></v-col>
              <v-col class="text-right">
                <v-btn
                  color="error"
                  class="mr-2"
                  :disabled="processing || uploading"
                  text
                  @click="closeContract"
                  >Cancel</v-btn
                >
                <v-btn
                  color="success"
                  :disabled="processing || uploading"
                  outlined
                  @click="saveContract(true)"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
import _ from "underscore";
import Store from "../../services/store";
import FileUpload from "../../services/file-upload";
import { getS3URL } from "../../helpers/misc";

export default {
  props: ["_id", "editedItem", "contractModified"],

  methods: {
    saveContract(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.editedItem._id)
        request = Store.editContract(
          this.editedItem._id,
          this.editedItem,
          false
        );
      else request = Store.createContract(this._id, this.editedItem, false);

      return request
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();

          if (closeModal === true) {
            this.$store.state.contractFormModal = false;
            this.$emit("update:contractModified", true);
          }
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    closeContract() {
      this.$store.state.contractFormModal = false;
    },

    querySelections(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;
      return Store.searchProducts(this._id, keyword, false)
        .then((x) => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.products = x.data;
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    },

    productSelected() {
      this.lastSearched = this.selectedProduct.name;
      if (this.editedItem.items.find((x) => x._id === this.selectedProduct._id))
        return;

      const newItem = {
        _id: this.selectedProduct._id,
        name: this.selectedProduct.name,
        price: this.selectedProduct.price,
        size: this.selectedProduct.size,
        unit: this.selectedProduct.unit,
        minOrderCap: this.selectedProduct.minOrderCap || 1,
        index: this.editedItem.items.length,
      };

      this.editedItem.items.push(newItem);
      this.searchInput = "";
      this.selectedProduct = {};
    },

    removeItem(item) {
      this.editedItem.items = this.editedItem.items.filter(
        (x) => x._id != item._id
      );
    },

    productText(item) {
      return `${item.name} (${item.size}${
        item.unit
      }) - Standard Price: ${this.$options.filters.fc(item.price)}`;
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    isValidDocument(file) {
      if (!file) return false;
      const type = file["type"].split("/")[0];
      const type2 = file["type"].split("/")[1];
      if (
        file &&
        (this.supportedFormats.includes(type) ||
          this.supportedFormats.includes(type2))
      ) {
        this.fileObject = {
          name: file["name"],
          size: file["size"],
          type: this.supportedFormats.includes(type) ? type : type2,
        };
        return true;
      }
      return false;
    },

    getURL(data) {
      if (!data) return data;
      const ext = data.split(".")[1];
      if (data && (this.supportedFormats.includes(ext) || ext == "png")) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidDocError() {
      this.$store.state.snackbar.text = "Unsupported File Format";
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isValidDocument(file)) return this.invalidDocError();

      this.fileObject._id = "";
      this.fileObject._id = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(
        this._id,
        "contracts",
        file,
        this.fileObject.type
      )
        .then((x) => {
          if (!this.editedItem.files) this.editedItem.files = [];
          this.fileObject._id = x.data.file;
          this.editedItem.files.push(this.fileObject);
          this.uploading = false;
          if (this.editedItem._id) this.saveContract(false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    openDocument(item) {
      window.open(this.getURL(item._id), "_blank");
    },

    removeDocument(item) {
      const files = this.editedItem.files.filter((x) => x._id != item._id);
      this.editedItem.files = files;
      this.saveContract(false);
    },
  },

  watch: {
    searchInput: _.debounce(function(newVal, oldVal) {
      const array = newVal ? newVal.split("Standard") : [];
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        array.length === 1
      )
        this.querySelections(newVal);
    }, 700),
  },

  computed: {
    formTitle() {
      return !this.editedItem._id ? "Create new contract" : "Edit contract";
    },
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      loading: false,
      processing: false,
      uploading: false,
      valid: false,
      menu: false,

      searchInput: "",
      lastSearched: "",
      selectedProduct: {},
      outstandingRequests: 0,
      products: [],
      isSearching: false,
      supportedFormats: ["image", "pdf", "xls", "xsls", "doc", "docx"],

      fileObject: {},

      ploading: false,
      pheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Size", value: "size" },
        { text: "Contract Price", value: "price" },
        { text: "Min Qty", value: "minOrderCap", sortable: false },
        { text: "Remove", value: "actions", sortable: false, align: "center" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        emailRule: [(v) => !v || emailregex.test(v) || "Must be a valid email"],
        emailRequiredRule: [
          (v) => (v && emailregex.test(v)) || "Must be a valid email",
        ],
        numberRule: [(v) => (!isNaN(v) && v >= 0) || "Enter a valid number"],
      },
    };
  },
};
</script>

import moment from "moment";
import _ from "underscore";
import jsPDF from "jspdf";
import "jspdf-autotable";
import exportFromJSON from "export-from-json";

import Subscription from "../../../services/subscription";
import Order from "../../../services/order";
import Store from "../../../services/store";
import POS from "../../../services/pos";
import User from "../../../services/user";

import Util from "../../../services/util";
import router from "../../../router";

import config from "../../../config";
import ThermalInvoice from "../../../components/thermal-invoice/thermal-invoice.vue";
import ThermalInvoices from "../../../components/thermal-invoices/thermal-invoices.vue";
import OrderRefundDialog from "../../../components/order-refund-dialog/order-refund-dialog.vue";
import AbandonedOrder from "../../../components/abandoned-order/abandoned-order.vue";
import OrderIdInfo from "../../../components/order-id-info/order-id-info.vue";
import StatusChip from "../../../components/general/status-chip.vue";
import { get } from "jquery";

const todayDate = moment().format("YYYY-MM-DD");
const yesterdayDate = moment()
  .subtract(1, "d")
  .format("YYYY-MM-DD");
const oneWeekBefore = moment()
  .subtract(7, "d")
  .format("YYYY-MM-DD");
const oneMonthBefore = moment()
  .subtract(1, "months")
  .format("YYYY-MM-DD");
const threeMonthsBefore = moment()
  .subtract(3, "months")
  .format("YYYY-MM-DD");
const sixMonthsBefore = moment()
  .subtract(6, "months")
  .format("YYYY-MM-DD");
const oneYearBefore = moment()
  .subtract(1, "years")
  .format("YYYY-MM-DD");

export default {
  name: "Orders",

  components: {
    AbandonedOrder,
    ThermalInvoice,
    ThermalInvoices,
    OrderRefundDialog,
    OrderIdInfo,
    StatusChip,
  },

  // sockets: {
  //   // eslint-disable-next-line
  //   newOrder: function(data = {}) {
  //     this.notify();
  //     this.fetchOrders(1);
  //     this.$store.state.counts.orders++;
  //   }
  // },

  created() {
    this._id = this.$route.params._id;
    const tabID = this.$route.query.tab;
    if (tabID) {
      this.$nextTick(() => {
        const tabIndex = this.tabs.findIndex((x) => x._id == tabID);
        if (tabIndex > -1) this.tab = tabIndex;
      });
    }

    if (!this.amIAllowed("Orders")) return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    if (this.doIHaveAccess("edit")) {
      const actionsItem = {
        text: "Actions",
        align: "center",
        value: "actions",
        width: "90px",
        sortable: false,
        disabled: true,
      };
      this.headers.push(actionsItem);
      this.defaultHeaders.push(actionsItem);
      this.availableHeaders.push(actionsItem);
    }

    //add-vendor column if multivendor enabled
    this.availableHeaders.splice(4, 0, { text: "Vendor", value: "vendorName" });

    //set default columns
    const savedColumns = localStorage["ordersColumns"];
    if (savedColumns) this.headers = JSON.parse(savedColumns);

    this.initPusher();
    this.fetchOrders(1);
    this.fetchDeliverymen();
    this.fetchOrderEnums();
    this.fetchCommonEnums();
    this.fetchSlots();
    this.fetchDeliveryOptions();
    this.fetchCustomers();
    this.fetchSummary();
    this.getCounts();
    this.fetchSubscriptions();

    if (!this.isVendor) {
      this.fetchVendors();
      this.fetchEmployees();
      this.fetchCommunities();
      this.fetchVendorLocations();

      const item = { _id: "website", title: "Website Orders" };
      this.tabs.splice(1, 0, item);
    }
  },

  methods: {
    initPusher() {
      const channelName = this._id;
      const eventName = "newOrder";

      const pusher = this.$store.state.pusher;
      let channel = pusher.channel(channelName);
      if (!channel) channel = pusher.subscribe(channelName);

      if (
        this.$store.state.pusherBusinessEvents.includes("orders:" + eventName)
      )
        return;
      this.$store.state.pusherBusinessEvents.push("orders:" + eventName);

      channel.bind(eventName, () => {
        if (this.$route.name == "store-orders") {
          this.fetchOrders(
            this.options.page,
            this.options.itemsPerPage,
            this.search
          );
        }
      });
    },

    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    doIHaveAdminAccess() {
      return Store.doIHaveAdminAccess(this.$store);
    },

    copyID(_id) {
      navigator.clipboard.writeText(_id);
      this.copyOIDText = "Copied";
      setTimeout(() => (this.copyOIDText = "Copy"), 1500);
    },

    notify() {
      this.$notification.show("You've received a new order! ✌️", {}, {});
    },

    copyOrderDetails(item) {
      let text = "";
      text += `OID: ${item.oid}`;
      text += `\r\nAmount: ${this.amountFormatted(item)} (${
        item.paymentMethod
      })`;
      text += `\r\n`;
      text += `\r\nName: ${item.address ? item.address.name : item.user.name}`;
      text += `\r\nNumber: ${
        item.address ? item.address.mobile : item.user.mobile
      }`;
      text += `\r\n`;
      text += `\r\n`;

      if (item.type != "pickup-drop") {
        text += `Items: `;
        item.items.forEach((x, index) => {
          text += `\r\n${index + 1}) ${x._id.name} (${x.size}${
            x.unit
          }) / Qty (${x.qty})`;
        });
      } else {
        text += `Pickup Address: ${item.pickupAddress.name}${
          item.pickupAddress.area ? ", " + item.pickupAddress.area : ""
        }, ${item.pickupAddress.landmark}, ${item.pickupAddress.city}, ${
          item.pickupAddress.state
        } - ${item.pickupAddress.pincode}`;
        if (item.pickupAddress.lat && item.pickupAddress.long) {
          text += `\r\n`;
          text += `URL: https://www.google.com/maps/search/?api=1&query=${item.pickupAddress.lat},${item.pickupAddress.long}`;
        }
      }

      if (item.address) {
        text += `\r\n`;
        text += `\r\n`;
        text += `Delivery Address: ${item.address.name}${
          item.address.area ? ", " + item.address.area : ""
        }, ${item.address.landmark}, ${item.address.city}, ${
          item.address.state
        } - ${item.address.pincode}`;

        if (item.address.lat && item.address.long) {
          text += `\r\n`;
          text += `URL: https://www.google.com/maps/search/?api=1&query=${item.address.lat},${item.address.long}`;
        }
      }

      text += `\r\n`;
      text += `\r\n`;
      text += "Thank you!";

      navigator.clipboard.writeText(text);
      this.copyOrderDetailsText = "Copied";
      setTimeout(
        () => (this.copyOrderDetailsText = "Copy Order Details"),
        1500
      );
    },

    getChildrenVendorNames(orders) {
      return orders
        .map((x) => (x.vendor ? x.vendor.name : ""))
        .filter((x) => x)
        .join(", ");
    },

    getTabID(index) {
      return this.tabs[index]._id;
    },

    fetchOrders(
      pageNumber,
      itemsPerPage = this.ordersObject.itemsPerPage,
      keyword = ""
    ) {
      const tabID = this.getTabID(this.tab);
      if (this.isSummaryDatesFilter) {
        keyword = keyword + "&dates=" + this.summaryCalDates.join(",");
      }

      // return request tab
      if (tabID == "returns") {
        this.fetchReturns(pageNumber, itemsPerPage, keyword);
        this.fetchReturnEnums();
        return;
      }

      this.loading = true;
      return Order.getOrders(
        this._id,
        pageNumber,
        itemsPerPage,
        keyword,
        this.isVendor,
        tabID,
        false
      )
        .then((res) => {
          this.ordersObject = res.data;
          this.orders = this.ordersObject.items.map((x, index) => {
            x.index =
              pageNumber == 1
                ? index + 1 + "."
                : itemsPerPage * (pageNumber - 1) + index + 1 + ".";

            x.ago = moment(x.createdAt).format("lll");
            x.name = x.user?.name || "-";
            x.amount = x.amount || 0;
            x.paymentStatus = x.paymentStatus || "-";
            if (x.status) {
              x.sd =
                x.status?.charAt(0).toUpperCase() + x.status.slice(1) || "-";
            }
            x.mop = x.mop || "-";
            x.outletName = x.outlet?.name || this.$store.state.activeStore.name;
            x.communityName = x.community?.name || "-";
            x.vendorName = x.vendor?.name || "-";
            x.vendorLocationName = x.vendorLocation?.title || "-";
            x.deliverymanName = x.deliveryman?.name || "-";

            x.slotName = x.slot?.title || "-";
            x.optionName = x.deliveryOption?.title || "-";

            //child orders
            if (x.children && x.children.length) {
              x.children.forEach((xc) => {
                if (xc.status) {
                  xc.sd =
                    xc.status?.charAt(0).toUpperCase() + xc.status.slice(1) ||
                    "-";
                }
              });
            }
            return x;
          });
          this.loading = false;

          //display orders outlet
          if (this.orders.some((x) => x.outlet))
            this.availableHeaders.splice(this.headers.length - 2, 0, {
              text: "Outlet",
              value: "outletName",
            });

          //add new filter if not all regular orders
          if (
            this.orders.some((x) =>
              ["pickup-drop", "pos", "website"].includes(x.type)
            ) &&
            !this.filterBy.find((x) => x.header === "Types")
          ) {
            this.filterBy.push({ header: "Types" });
            this.filterBy.push({ value: "type:pos", text: "POS" });
            this.filterBy.push({ value: "type:website", text: "Website" });
            this.filterBy.push({ value: "type:regular", text: "Regular" });
            this.filterBy.push({
              value: "type:pickup-drop",
              text: "Pickup & Drop",
            });
          }
        })
        .catch((e) => console.log(e));
    },

    fetchReturns(
      pageNumber = this.returnsObject.pageNumber,
      itemsPerPage = this.returnsObject.itemsPerPage,
      keyword = this.search
    ) {
      this.loading = true;

      this.loading = true;
      return Order.getReturns(
        this._id,
        pageNumber,
        itemsPerPage,
        keyword,
        this.isVendor,
        false
      )
        .then((res) => {
          this.returnsObject = res.data;
          this.returns = this.getReturnItems(this.returnsObject.items);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchCustomers() {
      this.fcustomers = true;
      return User.getSelectableUsers(this._id, false)
        .then((res) => {
          this.customers = res.data;
          this.customers.sort((a, b) => a.name && a.name.localeCompare(b.name));
          this.fcustomers = false;
        })
        .catch((e) => {
          console.log(e);
          this.fcustomers = false;
        });
    },

    fetchSubscriptions() {
      return Subscription.getSubscriptions(this._id, false)
        .then((res) => {
          this.subscriptions = res.data;
          if (this.subscriptions && this.subscriptions.length) {
            this.filterBy.push({ header: "Subscriptions" });
            this.subscriptions.forEach((x) => {
              this.filterBy.push({
                value: "subscription:" + x._id,
                text: "SID: " + (x.sid || x._id),
              });
            });
          }
        })
        .catch((e) => console.log(e));
    },

    processStatus(order, status) {
      this.refundCheckAndContinue(order, status);
    },

    refundCheckAndContinue(order, status) {
      if (
        this.isVendor ||
        order.paymentMethod != "Online" ||
        order.status != "cancelled" ||
        order.isRefundProcessed
      )
        return this.changeStatus(order, status);
      this.refundOrder = order;
      this.$store.state.refundConfirmationModal = true;
    },

    changeStatus(order, status) {
      this.processing = true;
      Store.snackbarStart();
      return Order.changeStatus(order._id, status, true, false)
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();
          Util.refreshCounts(this._id);
          this.fetchOrders(
            this.options.page,
            this.options.itemsPerPage,
            this.search
          );
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    fetchSlots() {
      return Store.getDeliverySlots(this._id, false)
        .then((res) => {
          this.deliverySlots = res.data;
          if (this.deliverySlots && this.deliverySlots.length) {
            this.deliverySlots.sort((a, b) => a.title > b.title);

            this.filterBy.push({ header: "Delivery Slots" });
            this.deliverySlots.forEach((x) => {
              this.filterBy.push({
                value: "deliverySlot:" + x._id,
                text: x.title,
              });
            });
          }
        })
        .catch((e) => console.log(e));
    },

    fetchDeliveryOptions() {
      return Store.getDeliveryOptions(this._id, false)
        .then((res) => {
          this.deliveryOptions = res.data;
          if (this.deliveryOptions && this.deliveryOptions.length) {
            this.deliveryOptions.sort((a, b) => a.title > b.title);

            this.filterBy.push({ header: "Delivery Options" });
            this.deliveryOptions.forEach((x) => {
              this.filterBy.push({
                value: "deliveryOption:" + x._id,
                text: x.title,
              });
            });
          }
        })
        .catch((e) => console.log(e));
    },

    fetchCommunities() {
      return Store.getCommunities(this._id, false)
        .then((res) => {
          this.communities = res.data;
          if (this.communities && this.communities.length) {
            this.communities.sort((a, b) => a.name > b.name);

            this.filterBy.push({ header: "Communities" });
            this.communities.forEach((x) => {
              this.filterBy.push({ value: "community:" + x._id, text: x.name });
            });
          }
        })
        .catch((e) => console.log(e));
    },

    fetchOrderEnums() {
      return Order.getOrderEnums(this._id)
        .then((res) => {
          this.enums = res.data;
          this.enums.status = this.enums.status.map((x) => {
            return {
              key: x,
              filterKey: "status:" + x,
              value: x.charAt(0).toUpperCase() + x.slice(1),
            };
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    fetchReturnEnums() {
      return Order.getReturnOrderEnums(this._id)
        .then((res) => {
          this.renums = res.data;
          this.renums.status = this.renums.status.map((x) => {
            return {
              key: x,
              filterKey: "status:" + x,
              value: x.charAt(0).toUpperCase() + x.slice(1),
            };
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    processCEnums() {
      if (this.cenums.paymentMethods && this.cenums.paymentMethods.length) {
        this.filterBy.push({ header: "Payment Methods" });
        this.cenums.paymentMethods.forEach((x) => {
          this.filterBy.push({ value: "paymentMethod:" + x._id, text: x.name });
        });
      }
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) {
        this.cenums = ce;
        this.processCEnums();
        return;
      }

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
          this.processCEnums();
        })
        .catch((e) => console.log(e));
    },

    downloadInvoice(_id) {
      return Order.generateInvoice(_id, this.$store.state.token);
    },

    downloadInvoices() {
      const _ids = this.selectedOrders.map((x) => x._id).join(",");
      this.selectedOrders = [];
      return Order.downloadInvoices(this._id, _ids, this.$store.state.token);
    },

    fetchDeliverymen() {
      return Store.getEmployeesByRole(this._id, "deliveryman")
        .then((res) => {
          this.deliverymen = res.data.filter((x) => x.isActive);
          if (this.deliverymen && this.deliverymen.length) {
            this.filterBy.push({ header: "Deliverymen" });
            this.deliverymen.forEach((x) => {
              this.filterBy.push({
                value: "deliveryman:" + x.user,
                text: x.name,
              });
            });
          }
        })
        .catch((e) => console.log(e));
    },

    fetchEmployees() {
      return Store.getEmployees(this._id)
        .then((res) => {
          this.employees = res.data;
          if (this.employees && this.employees.length) {
            this.filterBy.push({ header: "Employees" });
            this.employees.forEach((x) => {
              this.filterBy.push({
                value: "createdBy:" + x.user._id,
                text: x.name,
              });
            });
          }
        })
        .catch((e) => console.log(e));
    },

    fetchVendors() {
      return Store.getVendors(this._id, false)
        .then((res) => {
          this.vendors = res.data.filter((x) => x.isActive);
          if (this.vendors && this.vendors.length) {
            this.filterBy.push({ header: "Vendors" });
            this.vendors.forEach((x) => {
              this.filterBy.push({
                value: "vendor:" + x.business,
                text: x.businessName,
              });
            });

            this.filterBy.push({ value: "main:true", text: "Main Business" });
          }
        })
        .catch((e) => console.log(e));
    },

    fetchVendorLocations() {
      return Store.getVendorLocations(this._id, false)
        .then((res) => {
          this.vendorLocations = res.data.filter((x) => x.isActive);
          if (this.vendorLocations && this.vendorLocations.length) {
            this.filterBy.push({ header: "Vendor Locations" });
            this.vendorLocations.forEach((x) => {
              this.filterBy.push({
                value: "vendorLocation:" + x._id,
                text: x.title,
              });
            });
          }
        })
        .catch((e) => console.log(e));
    },

    removeSelected(item) {
      this.selectedOrders = this.selectedOrders.filter(
        (x) => x._id != item._id
      );
    },

    assignDeliveryman() {
      this.massModalType = "deliveryman";
      this.massModal = true;
    },

    changeOrdersStatus() {
      this.massModalType = "status";
      this.massModal = true;
    },

    assignVendor() {
      this.massModalType = "vendor";
      this.massModal = true;
    },

    changeOrdersCommunity() {
      this.massModalType = "community";
      this.massModal = true;
    },

    changeOrdersDeliverySlot() {
      this.massModalType = "slot";
      this.massModal = true;
    },

    massProceed() {
      if (this.$refs.massForm && !this.$refs.massForm.validate()) return;
      this.massprocessing = true;
      Store.snackbarStart();
      const orderIds = this.selectedOrders.map((x) => x._id);

      let request;
      if (this.massModalType == "deliveryman")
        request = Order.massUpdateDeliveryman(
          this._id,
          this.massUpdate.deliveryman,
          { orderIds: orderIds },
          false
        );
      else if (this.massModalType == "vendor")
        request = Order.massUpdateVendor(
          this._id,
          this.massUpdate.vendor,
          { orderIds: orderIds },
          false
        );
      else if (this.massModalType == "community")
        request = Order.massUpdateCommunity(
          this._id,
          this.massUpdate.community,
          { orderIds: orderIds },
          false
        );
      else if (this.massModalType == "slot")
        request = Order.massUpdateSlot(
          this._id,
          this.massUpdate.slot,
          { orderIds: orderIds },
          false
        );
      else if (this.massModalType == "status")
        request = Order.massUpdateStatus(
          this._id,
          this.massUpdate.status,
          { orderIds: orderIds, notify: !!this.massUpdate.notify },
          false
        );

      return request
        .then(() => {
          this.selectedOrders = [];
          this.fetchOrders(
            this.options.page,
            this.options.itemsPerPage,
            this.search
          );
          this.massModal = this.massprocessing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.massModal = this.massprocessing = false;
          Store.snackbarError();
        });
    },

    amountFormatted(order) {
      let formattedAmount = order.amount;
      if (order.currencyCode === "INR")
        formattedAmount = this.currencyPipe(order.amount, "₹");
      else if (order.currencyCode && order.currencyCode !== "INR")
        formattedAmount = this.currencyPipe(order.amount, order.currencyCode);
      else if (!this.$store.state.country.currencyCode && !order.currencyCode)
        formattedAmount = this.currencyPipe(order.amount, "₹");
      else
        formattedAmount = this.currencyPipe(
          order.amount,
          this.$store.state.country.currencySymbol
        );

      return formattedAmount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    printReceipt(order, print = true) {
      this.printOrder = order;
      this.printOrder.print = print;
      this.$store.state.thermalInvoiceModal = true;
    },

    columnsChanged() {
      const nh = [];
      this.availableHeaders.forEach((x) => {
        if (
          this.headers.find((i) => i.value == x.value) &&
          !nh.find((j) => j.value == x.value)
        )
          nh.push(x);
      });
      this.headers = nh;
    },

    vendorText(item) {
      return `${item.businessName} (${item.name}) - ${item.mobile}`;
    },

    saveColumns() {
      localStorage["ordersColumns"] = JSON.stringify(this.headers);
      this.saveColumnsText = "Saved";
      setTimeout(() => {
        this.saveColumnsText = "Save";
        this.toggleColumnSelect = !this.toggleColumnSelect;
      }, 500);
    },

    resetColumns() {
      this.headers = this.defaultHeaders;
      localStorage.removeItem("ordersColumns");
    },

    exportOrders(all = true) {
      let _ids = false;
      if (!all) _ids = this.selectedOrders.map((x) => x._id);

      this.exporting = true;
      return Order.exportOrders(this._id, this.isVendor, _ids)
        .then(() => {
          this.exporting = false;
          this.$store.state.snackbar.text =
            "Your request has been submit. Once the orders report is ready, you'll receive it via email. Thanks.";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "success";
          this.$store.state.snackbar.show = true;
        })
        .catch(() => {
          this.exporting = false;
          Store.snackbarError();
          this.$store.state.snackbar.text = "No orders found; try later.";
        });
    },

    getNumberWithOrdinal(n) {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },

    openWhatsApp(info) {
      const callingCode =
        info.callingCode || this.$store.state.country.callingCode || "91";
      window.open(
        "https://api.whatsapp.com/send?phone=" + callingCode + info.mobile,
        "_system"
      );
    },

    formatDate(date, format = "ll") {
      return moment(date).format(format);
    },

    customerText(item) {
      if (!item.mobile) return item;
      return item.name ? item.name + " (" + item.mobile + ")" : item.mobile;
    },

    triggerOrderPlacedWebhook() {
      Store.snackbarStart();
      const _ids = this.selectedOrders.map((x) => x._id);
      return Store.triggerOrderWebhook(this._id, _ids, "placed")
        .then((res) => {
          console.log(res.data);
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.massModal = this.massprocessing = false;
          Store.snackbarError();
        });
    },

    fetchSummary() {
      this.summaryFetching = true;
      const object = { dates: this.summaryCalDates };

      return Order.getSummary(this._id, object, this.isVendor, false)
        .then((x) => {
          this.summary = x.data;
          this.summaryFetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.summaryFetching = false;
        });
    },

    getCounts() {
      return Order.getCounts(this._id, this.isVendor, false)
        .then((x) => {
          if (x.data) this.counts = x.data;
        })
        .catch((e) => console.log(e));
    },

    tileColor(status) {
      if (["new", "accepted"].includes(status)) return "primary";
      else if (status == "shipped") return "warning";
      else if (status == "delivered") return "success";
      else if (status == "cancelled") return "red";
      else if (status == "testing") return "indigo";
    },

    setFilter(value) {
      if (this.filterBySelected == value) {
        this.filterBySelected = "";
        this.isSummaryDatesFilter = false;
      } else {
        this.filterBySelected = value;
        this.isSummaryDatesFilter = true;
      }
    },

    refresh() {
      if (this.isSummaryDatesFilter) {
        this.search = this.filterBySelected = "";
        this.isSummaryDatesFilter = false;
      } else {
        this.fetchOrders(1);
        this.fetchSummary();
        this.getCounts();
      }
    },

    calculateItemsSummary() {
      const items = [];
      if (this.selectedOrders) {
        this.selectedOrders.forEach((x) => {
          const oItems = x.items;
          oItems.forEach((oi) => {
            const itemObject = items.find((i) => i._id._id == oi._id._id);
            if (itemObject) {
              itemObject.totalQty = itemObject.totalQty + oi.qty;
              itemObject.totalPrice = itemObject.totalQty * itemObject.price;

              if (!itemObject.orders) [{ oid: x.oid, qty: oi.qty }];
              else itemObject.orders.push({ oid: x.oid, qty: oi.qty });
            } else {
              oi.totalQty = oi.qty;
              oi.totalPrice = oi.price;
              oi.orders = [{ oid: x.oid, qty: oi.qty }];
              items.push(oi);
            }
          });
        });
      }

      items.sort((a, b) => a._id.name > b._id.name);
      items.forEach((x, index) => (x.index = index + 1 + "."));
      this.itemsSummaryItems = items;
      this.showItemsSummary = true;
    },

    downloadItemsSummary() {
      const columns = this.selectedItemsSummaryColumns;
      const rows = [];

      const doc = new jsPDF();
      doc.autoTable({ html: "" });

      this.itemsSummaryItems.forEach((x) => {
        const item = [];
        if (columns.includes("#")) item.push(x.index);
        if (columns.includes("Name")) item.push(x._id.name);
        if (columns.includes("SKU")) item.push(x._id.sku);
        if (columns.includes("Category")) item.push(x._id.category.name);
        if (columns.includes("Size")) item.push(x.size + x.unit);
        if (columns.includes("Orders"))
          x.orders
            ? item.push(
                x.orders.map((o) => o.oid + " (" + o.qty + ")").join(", ")
              )
            : "-";
        if (columns.includes("Total Qty")) item.push(x.totalQty);
        if (columns.includes("Total Price"))
          item.push(this.$options.filters.currency(x.totalPrice, ""));
        rows.push(item);
      });

      // Or use javascript directly:
      doc.autoTable({
        margin: { top: 0, left: 5, right: 5, bottom: 0 },
        head: [columns],
        body: rows,
        theme: "grid",
        headStyles: {
          fillColor: [0, 123, 255],
        },
        styles: { font: "times" },
      });

      doc.save(this.$store.state.activeStore.appName + "_Items_Summary.pdf");
    },

    downloadItemsSummaryAsExcel() {
      const columns = this.selectedItemsSummaryColumns;
      const data = [];

      this.itemsSummaryItems.forEach((x) => {
        const item = {};
        if (columns.includes("#")) item["#"] = x.index;
        if (columns.includes("Name")) item["Name"] = x._id.name;
        if (columns.includes("SKU")) item["SKU"] = x._id.sku;
        if (columns.includes("Category"))
          item["Category"] = x._id.category.name;
        if (columns.includes("Size")) item["Size"] = x.size + x.unit;
        if (columns.includes("Orders"))
          item["Orders"] = x.orders
            ? x.orders.map((o) => o.oid + " (" + o.qty + ")").join(", ")
            : "-";
        if (columns.includes("Total Qty")) item["TotalQty"] = x.totalQty;
        if (columns.includes("Total Price"))
          item["TotalPrice"] = this.$options.filters.currency(x.totalPrice, "");
        data.push(item);
      });

      const fileName = this.$store.state.activeStore.appName + "_Items_Summary";
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({ data, fileName, exportType });
    },

    getCallingCode(item) {
      let cc = this.$store.state.country.callingCode || "91";
      if (item.address && item.address.callingCode) {
        cc = item.address.callingCode;
      } else if (!item.address && item.user && item.user.callingCode) {
        cc = item.user.callingCode;
      }
      return cc;
    },

    sendAbandonedPaymentLink(item) {
      const object = Object.assign({}, item);
      delete object._id;
      delete object.oid;
      this.sendPaymentLink(object, "business");
    },

    sendPaymentLink(order, type = "order") {
      let amount = order.amount;
      if (amount && order.amountFromWallet)
        amount = order.amount - order.amountFromWallet;

      this.paymentLinkObject = {
        type: type,
        order: order._id,
        oid: order.oid,
        amount: amount,
        customer: {
          name: order.user.name,
          email: order.user.email,
          mobile: order.user.mobile,
          callingCode: order.user.callingCode,
          currencyCode: order.currencyCode || "INR",
        },
      };
      if (order.oid)
        this.paymentLinkObject.description =
          this.$store.state.activeStore.appName + " Order: " + order.oid;
      this.$store.state.paymentLinkModal = true;
    },

    expandAbandonedOrder(order) {
      this.aorder = order;
      this.aodialog = true;
    },

    expandReturnOrder(order) {
      this.returnOrder = order;
      this.roDialog = true;
    },

    markAsCompleted(order) {
      this.aorder = order;
      this.aoConfirmDialog = true;
    },

    closeAOConfirm() {
      this.aoConfirmDialog = false;
      this.aorder = {};
    },

    finalAOConfirm() {
      this.processing = true;
      const object = { status: "completed" };

      return Order.updatedAbandonedOrder(this.aorder._id, object, false)
        .then(() => {
          this.processing = this.aoConfirmDialog = false;
          this.fetchOrders(
            this.options.page,
            this.options.itemsPerPage,
            this.search
          );
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    sendNotification() {
      this.selectedCustomers = this.selectedOrders.map((x) => x.user);
      this.notificationModal = true;
    },

    printThermalInvoices() {
      const selectedOrders = this.selectedOrders;
      this.$store.state.mtiOrders = JSON.parse(JSON.stringify(selectedOrders));
      this.$store.state.multipleThermalInvoiceModal = true;
    },

    capitalize(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    saveReturnOrder(returnOrder) {
      this.processing = true;
      return Order.updateReturnOrder(returnOrder._id, returnOrder)
        .then(() => {
          this.fetchReturns();
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    processReturnStatus(item, status) {
      item.status = status;
      this.saveReturnOrder(item);
    },

    getChipColor(status) {
      if (["new", "accepted"].includes(status)) return "primary";
      else if (["completed", "delivered"].includes(status)) return "success";
      else if (["rejected", "cancelled"].includes(status)) return "error";
      else if (["replace", "shipped"].includes(status)) return "warning";
      else if (["wallet"].includes(status)) return "purple";
      else if (["refund"].includes(status)) return "cyan";
      return "";
    },

    getReturnItems(array) {
      return array.map((x, index) => {
        x.index = index + 1 + ".";
        x.name = x.user?.name || "-";
        x.amount = x.amount || 0;
        x.returnMethod = x.returnMethod || "-";
        x.vendorName = x.vendor?.name || "-";
        x.vendorLocationName = x.vendorLocation?.title || "-";
        return x;
      });
    },
  },

  computed: {
    store() {
      return this.$store.state.activeStore;
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    isMultivendor() {
      return this.$store.state.activeStore.isMultivendor;
    },

    isDateFilter() {
      return this.filterBySelected
        ? this.filterBySelected.includes("createdAt")
        : false;
    },

    isDateRange() {
      if (!this.filterBySelected) return false;
      const array = this.filterBySelected.split(":");
      if (!array || !array[1]) return false;
      const dates = array[1].split(",");
      if (!dates || !dates[1]) return false;
      return true;
    },

    searchDisabled() {
      if (this.filterBySelected) {
        if (this.filterBySelected.includes("deliveryOption")) return true;
        if (this.filterBySelected.includes("deliverySlot")) return true;
        if (this.filterBySelected.includes("deliveryMode")) return true;
        if (this.filterBySelected.includes("deliveryman")) return true;
        if (this.filterBySelected.includes("vendor")) return true;
        if (this.filterBySelected.includes("community")) return true;
        if (this.filterBySelected.includes("paymentMethod")) return true;
        if (this.filterBySelected.includes("status")) return true;
        if (this.filterBySelected.includes("type")) return true;
        if (this.filterBySelected.includes("main")) return true;
        if (this.filterBySelected.includes("subscription")) return true;
        if (this.filterBySelected.includes("subscriptionOrders")) return true;
        if (this.filterBySelected.includes("createdBy")) return true;
      }
      if (this.search && this.search.includes("user")) return true;
      return false;
    },

    posAccess() {
      return POS.posAccess() && this.amIAllowed("POS");
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    hcifv() {
      return (
        this.isVendor && this.store.vendorOwner.hideCustomerInfoFromVendors
      );
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  watch: {
    options: {
      handler(options) {
        if (!this.loading)
          this.fetchOrders(options.page, options.itemsPerPage, this.search);
      },
      deep: true,
    },

    search: _.debounce(function(nv) {
      this.fetchOrders(1, this.options.itemsPerPage, nv);
    }, 500),

    "selectedOrders.length"(n) {
      if (!n) this.massModal = false;
      if (this.showItemsSummary) this.calculateItemsSummary();
    },

    filterBySelected(n) {
      if (!n) this.options.itemsPerPage = 10;
      this.search = n;

      //for multi-filter
      // if (n && this.search && this.search != n) {
      //   const sliptArray1 = this.search.split('|');
      //   const sliptArray2 = n.split(':');

      //   const sa1 = sliptArray2[0];
      //   const sa2 = sliptArray2[1];

      //   const newArray = [];
      //   sliptArray1.forEach((x, index) => {
      //     if (x.includes(sa1)) newArray.push(sa1+':'+sa2);
      //     else newArray.push(x);
      //   });

      //   if (!this.search.includes(sa1)) newArray.push(n);

      //   this.search = newArray.join('|');
      // }
      // else this.search = n;
    },

    customer(n) {
      this.isSummaryDatesFilter = false;
      if (n) this.search = "user:" + n;
      else {
        this.options.itemsPerPage = 10;
        this.search = "";
      }
    },

    calModal(n) {
      if (!n) {
        if (!this.isDateRange) this.search = "createdAt:" + this.calDate;
        else if (this.calDates && this.calDates[0] && this.calDates[1])
          this.search =
            "createdAt:" + this.calDates[0] + "," + this.calDates[1];
      }
    },

    refundProcessed(n) {
      if (n) {
        Util.refreshCounts(this._id);
        this.fetchOrders(
          this.options.page,
          this.options.itemsPerPage,
          this.search
        );
      }
    },

    tab() {
      this.orders = [];
      this.selectedOrders = [];
      this.returnRequests = [];
      this.fetchOrders(
        this.options.page,
        this.options.itemsPerPage,
        this.search
      );
    },
  },

  data: () => {
    return {
      _id: "",
      orders: [],

      options: {},
      ordersObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      selectedOrders: [],
      rowItems: config.rowItems,

      printOrder: {
        items: [],
      },

      enums: {},

      massModal: false,
      massModalType: "deliveryman",
      massprocessing: false,
      massUpdate: {},

      deliverymen: [],
      vendors: [],
      employees: [],
      vendorLocations: [],

      copyOIDText: "Copy",
      copyOrderDetailsText: "Copy Order Details",

      toggleColumnSelect: false,
      saveColumnsText: "Save",

      exporting: false,
      fcustomers: false,
      customer: "",
      customers: [],

      summaryCalModal: false,
      summaryCalDates: [oneWeekBefore, todayDate],
      summaryFetching: false,
      summaryFetched: false,
      isSummaryDatesFilter: false,
      summary: {},

      refundOrder: {},
      refundProcessed: false,

      paymentLinkObject: {},

      calModal: false,
      calDate: new Date().toISOString().substr(0, 10),
      calDates: [oneWeekBefore, todayDate],
      filterBy: [
        { header: "Basic" },
        { value: "createdAt:" + todayDate, text: "Today" },
        { value: "createdAt:" + yesterdayDate, text: "Yesterday" },
        {
          value: "createdAt:" + oneWeekBefore + "," + todayDate,
          text: "Last one week",
        },
        {
          value: "createdAt:" + oneMonthBefore + "," + todayDate,
          text: "Last one month",
        },
        {
          value: "createdAt:" + threeMonthsBefore + "," + todayDate,
          text: "Last three months",
        },
        {
          value: "createdAt:" + sixMonthsBefore + "," + todayDate,
          text: "Last six months",
        },
        {
          value: "createdAt:" + oneYearBefore + "," + todayDate,
          text: "Last one year",
        },
        { value: "subscriptionOrders:true", text: "Subscription Orders" },
        { header: "Delivery Modes" },
        { value: "deliveryMode:home-delivery", text: "Home delivery" },
        { value: "deliveryMode:pick-up", text: "Pickup from the store" },
      ],
      filterBySelected: "",

      deliveryOptions: [],
      deliverySlots: [],
      communities: [],
      cenums: {},

      tab: 0,
      tabs: [
        { _id: "all", title: "All Orders" },
        { _id: "regular", title: "Regular Orders" },
        { _id: "pos", title: "POS Orders" },
        { _id: "abandoned", title: "Abandoned Checkout Orders" },
        { _id: "returns", title: "Returns" },
      ],

      showItemsSummary: false,
      itemsSummaryItems: [],
      itemsSummaryHeaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "_id.name" },
        { text: "SKU", value: "_id.sku" },
        { text: "Category", value: "_id.category.name" },
        { text: "Size", value: "size", sortable: false },
        { text: "Orders", value: "orders", sortable: false, width: "30%" },
        { text: "Total Qty", value: "totalQty", align: "center" },
        {
          text: "Total Price",
          value: "totalPrice",
          sortable: false,
          align: "right",
        },
      ],
      itemsSummaryColumns: [
        "#",
        "Name",
        "SKU",
        "Category",
        "Size",
        "Orders",
        "Total Qty",
        "Total Price",
      ],
      selectedItemsSummaryColumns: [
        "#",
        "Name",
        "Category",
        "Size",
        "Total Qty",
        "Total Price",
      ],

      search: "",
      loading: true,
      processing: false,
      expanded: [],
      childheaders: [
        { text: "OID", value: "oid" },
        { text: "Vendor", value: "vendorName" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "sd" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],

      headers: [
        {
          text: "#",
          align: "start",
          value: "index",
          width: "75px",
          disabled: true,
        },
        { text: "OID", value: "oid" },
        { text: "Name", align: "start", value: "address.name", disabled: true },
        { text: "Amount", value: "amount" },
        { text: "Method", value: "paymentMethod" },
        { text: "Status", value: "sd" },
        { text: "Date & Time", value: "ago" },
      ],

      defaultHeaders: [
        {
          text: "#",
          align: "start",
          value: "index",
          width: "75px",
          disabled: true,
        },
        { text: "OID", value: "oid" },
        { text: "Name", align: "start", value: "address.name", disabled: true },
        { text: "Amount", value: "amount" },
        { text: "Method", value: "paymentMethod" },
        { text: "Status", value: "sd" },
        { text: "Date & Time", value: "ago" },
      ],

      availableHeaders: [
        { text: "#", align: "start", value: "index", disabled: true },
        { text: "ID", value: "_id" },
        { text: "OID", value: "oid" },
        { text: "Name", align: "start", value: "address.name", disabled: true },
        { text: "Mobile", value: "address.mobile" },

        { text: "User Name", value: "user.name" },
        { text: "User Mobile", value: "user.mobile" },

        { text: "Email", value: "orderEmail" },
        { text: "Membership", value: "userMembership" },
        { text: "Vendor(s)", value: "vendorName" },
        { text: "Vendor Location", value: "vendorLocationName" },
        { text: "Community", value: "communityName" },
        { text: "Deliveryman", value: "deliverymanName" },
        { text: "Type", value: "type" },
        { text: "Delivery Charge", value: "deliveryCharge" },
        { text: "Cashback", value: "cashback" },
        { text: "Discount", value: "discount" },
        { text: "Amount", value: "amount" },
        { text: "Commission", value: "vendorCommissionAmount" },
        { text: "After Commission", value: "vendorAfterCommissionOrderAmount" },
        { text: "Paid From Wallet", value: "amountFromWallet" },
        { text: "Outstanding Amount", value: "outstandingAmount" },
        { text: "Method", value: "paymentMethod" },
        { text: "Delivery Option", value: "optionName" },
        { text: "Delivery Slot", value: "slotName" },
        { text: "Delivery Date", value: "deliveryDate" },
        { text: "Delivery Mode", value: "readableDeliveryMode" },
        { text: "Status", value: "sd" },
        { text: "Pincode", value: "address.pincode" },
        { text: "Courier Name", value: "courierName", sortable: false },
        { text: "AWB Code", value: "courierAWBCode", sortable: false },
        { text: "Tracking URL", value: "courierTrackingURL", sortable: false },
        { text: "Created By", value: "createdBy.name" },
        { text: "Modified By", value: "modifiedBy.name" },
        { text: "Date & Time", value: "ago" },
      ],

      counts: {},
      showSummary: false,
      notificationModal: false,
      selectedCustomers: [],
      editedNotificationItem: {
        notify: true,
        sendSMSIfNotificationFails: false,
        toAll: false,
        data: {
          entity: "regular",
        },
      },

      aorder: {},
      aodialog: false,
      aoConfirmDialog: false,
      aoHeaders: [
        {
          text: "#",
          align: "start",
          value: "index",
          width: "75px",
          disabled: true,
        },
        { text: "AOID", value: "oid" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile", sortable: false },
        { text: "Vendor(s)", value: "vendorName" },
        { text: "Vendor Location", value: "vendorLocationName" },
        { text: "Type", value: "type" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "sd" },
        { text: "Date & Time", value: "updatedAt" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      renums: {},
      returnOrder: {},
      roDialog: false,
      returns: [],
      returnsObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },
      returnHeaders: [
        {
          text: "#",
          align: "start",
          value: "index",
          width: "75px",
          disabled: true,
        },
        { text: "Returned OID", value: "oid" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile", sortable: false },
        { text: "Vendor(s)", value: "vendorName" },
        { text: "Return Method", value: "returnMethod" },
        { text: "Status", value: "status" },
        { text: "Date & Time", value: "updatedAt" },
        // { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      expandedReturn: [],
      returnChildHeaders: [
        { text: "OID", value: "oid" },
        { text: "Vendor", value: "vendorName" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "status" },
        // { text: "Actions", value: "actions", align: "center", sortable: false },
      ],

      validationRules: {
        lengthRule: [(v) => (v && v.length > 0) || "It is required"],
        basicRule: [(v) => !!v || "It is required"],
      },
    };
  },
};

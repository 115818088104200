import Store from "../../services/store";
import config from "../../config";

import _ from "underscore";

import i18n from "../../services/i18n";
import FileUpload from "../../services/file-upload";

export default {
  name: "i18n",

  created() {
    if (!this.canProceed) return Store.redirectToHome();

    this.loading = true;
    this._id = config.grocifyDotCo;

    const p1 = this.fetchLanguages();
    const p2 = this.fetchTranslations();
    const p3 = this.fetchCommonEnums();

    return Promise.all([p1, p2, p3])
      .then(() => (this.loading = false))
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });
  },

  methods: {
    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.cenums = ce);

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => console.log(e));
    },

    fetchLanguages() {
      this.lloading = true;
      return i18n
        .getLanguages(false)
        .then((res) => {
          this.languages = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            return x;
          });
          this.lloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.lloading = false;
        });
    },

    fetchTranslations(
      pageNumber = 1,
      itemsPerPage = this.translationsObject.itemsPerPage,
      keyword = ""
    ) {
      this.tloading = true;
      return i18n
        .getTranslations(pageNumber, itemsPerPage, keyword, false)
        .then((res) => {
          this.translationsObject = res.data;
          this.translations = this.translationsObject.items.map((x, index) => {
            x.languageName = x.language?.name || "--";
            x.language = x.language?._id || undefined;
            x.index =
              (pageNumber === 1
                ? index + 1
                : itemsPerPage * (pageNumber - 1) + index + 1) + ".";
            return x;
          });

          const { sortBy, sortDesc } = this.options;
          if (sortBy.length === 1 && sortDesc.length === 1) {
            this.translations = this.translations.sort((a, b) => {
              const sortA = a[sortBy[0]];
              const sortB = b[sortBy[0]];

              if (sortDesc[0]) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            });
          }

          this.tloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.tloading = false;
        });
    },

    editLanguageItem(item) {
      this.editedLanguageItem = JSON.parse(JSON.stringify(item));
      this.languageDialog = true;
    },

    editTranslationItem(item) {
      this.editedTranslationItem = JSON.parse(JSON.stringify(item));
      this.translationDialog = true;
    },

    deleteLanguageItem(item) {
      this.beingDeleted = item;
      this.confirmDialog = true;
    },

    deleteTranslationItem(item) {
      this.beingDeleted = item;
      this.confirmDialog = true;
    },

    closeConfirm() {
      this.confirmDialog = false;
      setTimeout(() => (this.beingDeleted = {}), 100);
    },

    finalDelete() {
      if (this.beingDeleted._id && !this.beingDeleted.language)
        this.finalDeleteLanguage();
      else this.finalDeleteTranslation();
    },

    finalDeleteLanguage() {
      this.lprocessing = true;
      return i18n
        .deleteLanguage(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.lprocessing = false;
          this.fetchLanguages();
        })
        .catch((e) => {
          this.confirmDialog = this.lprocessing = false;
          console.log(e);
        });
    },

    finalDeleteTranslation() {
      this.tprocessing = true;
      return i18n
        .deleteTranslation(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.tprocessing = false;
          this.fetchTranslations();
        })
        .catch((e) => {
          this.confirmDialog = this.tprocessing = false;
          console.log(e);
        });
    },

    close() {
      this.languageDialog = this.translationDialog = false;
      this.editedLanguageItem = this.editedTranslationItem = {};
    },

    changeLanguageStatus(item) {
      this.editedLanguageItem = item;
      this.saveLanguage();
    },

    changeTranslationStatus(item) {
      this.editedTranslationItem = item;
      this.saveTrans();
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedLanguageItem._id) this.saveLanguage();
    },

    saveLanguage() {
      if (this.$refs.languageForm && !this.$refs.languageForm.validate())
        return;

      Store.snackbarStart();
      this.lprocessing = true;
      let request;

      if (this.editedLanguageItem._id)
        request = i18n.editLanguage(
          this.editedLanguageItem._id,
          this.editedLanguageItem,
          false
        );
      else request = i18n.addLanguage(this.editedLanguageItem, false);

      return request
        .then(() => {
          this.lprocessing = false;
          Store.snackbarSuccess();
          this.close();
          this.fetchLanguages();
        })
        .catch((e) => {
          if (e.response && e.response.status === 401) {
            this.error = "Language already exists.";
            setTimeout(() => (this.error = ""), 2500);
          } else {
            Store.snackbarError();
          }
          this.lprocessing = false;
        });
    },

    saveTrans() {
      if (this.$refs.translationForm && !this.$refs.translationForm.validate())
        return;

      Store.snackbarStart();
      this.tprocessing = true;
      let request;

      if (this.editedTranslationItem._id)
        request = i18n.editTranslation(
          this.editedTranslationItem._id,
          this.editedTranslationItem,
          false
        );
      else request = i18n.addTranslation(this.editedTranslationItem, false);

      return request
        .then(() => {
          this.tprocessing = false;
          Store.snackbarSuccess();
          this.close();
          this.fetchTranslations(
            this.options.page,
            this.options.itemsPerPage,
            this.tsearch
          );
        })
        .catch((e) => {
          if (e.response.status === 401) {
            this.error = "This key already exists for the selected language.";
            setTimeout(() => (this.error = ""), 2500);
          }
          Store.snackbarError();
          this.tprocessing = false;
        });
    },

    downloadSampleCSV() {
      window.open(
        config.grocify.website + config.grocify.sampleTranslationsCSV,
        "_system"
      );
    },

    isFileCSV(file) {
      if (file && file["type"].split("/")[1] === "csv") {
        return true;
      }
      return false;
    },

    invalidFileError() {
      this.file = "";
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.$refs.inputUpload.files[0];
      if (!this.isFileCSV(file)) return this.invalidFileError();
      this.file = file;

      this.nextStep();
    },

    getURL(data) {
      const array = data.split(".");
      if (
        data &&
        !array.includes(config.growcifyMasterInventory) &&
        (array[1] === "png" || array[1] === "csv")
      )
        return `https://${this._id}.s3.amazonaws.com/${data}`;
      return data;
    },

    nextStep() {
      this.uploading = true;
      return FileUpload.upload(this._id, "translation-files", this.file, "csv")
        .then((x) => {
          this.uploading = false;
          this.csvurl = this.getURL(x.data.file);
          this.processCSV();
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    processCSV() {
      this.processingCSV = true;
      return i18n
        .processCSV(this.csvurl)
        .then(() => {
          this.processingCSV = false;
          this.fetchTranslations();
        })
        .catch((e) => {
          console.log(e);
          this.showErr =
            "Something went wrong, most likely it could be a wrong language name.";
          setTimeout(() => (this.showErr = false), 2500);
          this.processingCSV = false;
        });
    },

    syncModalToggle() {
      this.syncModal = !this.syncModal;
    },

    sync(_id) {
      this.beingSynced.push(_id);
      return i18n
        .sync(_id)
        .then(
          () => (this.beingSynced = this.beingSynced.filter((x) => x != _id))
        )
        .catch(
          () => (this.beingSynced = this.beingSynced.filter((x) => x != _id))
        );
    },

    isSyncing(_id) {
      return this.beingSynced.includes(_id);
    },

    isAnythingSyncing() {
      return !!this.beingSynced.length;
    },

    exportTranslations(_id) {
      let endpoint = `${config.apiProd}i18n/export/${_id}`;
      return window.open(
        endpoint +
          "?x-request-user-agent=application/admin-app&token=" +
          this.$store.state.token,
        "_blank"
      );
    },
  },

  watch: {
    options: {
      handler(options) {
        if (this.translations.length)
          this.fetchTranslations(
            options.page,
            options.itemsPerPage,
            this.tsearch
          );
      },
      deep: true,
    },

    tsearch: _.debounce(function (nv, ov) {
      if (this.translations.length || nv == "" || nv != ov)
        this.fetchTranslations(1, this.options.itemsPerPage, nv);
    }, 500),

    "editedTranslationItem.key": _.debounce(function (nv) {
      if (nv)
        this.editedTranslationItem.key = nv
          .replace(/\s+/g, "-")
          .toLowerCase()
          .trim();
    }, 500),

    "editedTranslationItem.value": _.debounce(function (nv) {
      if (nv) this.editedTranslationItem.value = nv.trim();
    }, 500),
  },

  computed: {
    formLanguageTitle() {
      return this.editedLanguageItem._id ? "Edit language" : "Add new language";
    },

    formTranslationTitle() {
      return this.editedTranslationItem._id
        ? "Edit translation"
        : "Add new translation";
    },

    canProceed() {
      return (
        (Store.amISuperadmin(this.$store) ||
          Store.amIGrowcifyEmployee(this.$store)) &&
        this.$store.state.isGD
      );
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data() {
    return {
      _id: "",
      error: "",

      languageValid: false,
      translationValid: false,

      languageDialog: false,
      translationDialog: false,

      translationModal: false,
      translationField: "",

      cenums: {},

      file: "",
      csvurl: "",

      syncModal: false,
      synchronizing: false,
      beingSynced: [],

      uploading: false,
      showErr: false,

      processingCSV: false,
      processedItems: [],

      languages: [],
      lheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name (English)", value: "name" },
        { text: "Name (Translated)", value: "tr" },
        { text: "Code", value: "code" },
        { text: "Status", value: "isActive", sortable: false },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],

      lsearch: "",
      lloading: false,
      lprocessing: false,

      tsearch: "",
      tloading: false,
      tprocessing: false,
      translationsObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      editedLanguageItem: {},
      defaultLanguageItem: {
        isActive: true,
      },

      editedTranslationItem: {},
      defaultTranslationItem: {
        isActive: true,
        language: "5f8370eb5d9df900174b52ce",
      },

      options: {},

      translations: [],
      theaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Language", value: "languageName" },
        { text: "Key", value: "key" },
        { text: "Translation", value: "value" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],

      confirmDialog: false,
      beingDeleted: {},

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => !!v || v === false || "It is required"],
        inlineRule: [(v) => !!v || ""],
        unitRule: [(v) => !!v || v === "" || "It is required"],
        numberRule: [(v) => !v || v >= 0 || "Must be a valid number"],
      },
    };
  },
};

<template>
  <v-dialog v-model="$store.state.refundConfirmationModal" max-width="650" persistent>
    <v-progress-linear v-if="processing" indeterminate color="primary" class="mb-0"></v-progress-linear>
    <v-card>
      <v-card-title>
        <span>Refund & Cancel Confirmation</span>
      </v-card-title>

      <v-card-text>
        As the customer has already paid for this order, do you want to refund the amount of <b>{{ amountFormatted(order.amount) }}</b> to the customer's <b>{{$store.state.activeStore.appName}} Wallet</b>?
        <div style="height: 20px"></div>
        <v-row>
          <v-col>
            <v-form ref="refundForm">
              <v-text-field
                required
                outlined
                hide-details
                :prefix="(order.currencyCode === 'INR' ? '₹' : order.currencyCode) || ($store.state.country.currencyCode === 'INR' || !$store.state.country.currencySymbol ? '₹' : $store.state.country.currencySymbol)"
                v-model="refundAmount"
                :rules="validationRules.amountRule"
                label="Refund Amount"></v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions style="padding-bottom: 30px; padding-right: 25px; padding-left: 25px">
        <v-btn color="black darken-1" :disabled="processing" text @click="closeRefundConfirmation">Close</v-btn>
        <v-spacer></v-spacer>

        <v-btn color="error darken-1" text :disabled="processing" @click="cancelWithoutRefund">Cancel without refund</v-btn>
        <v-btn color="green darken-1" outlined :disabled="processing" @click="cancelWithRefund">Yes, process refund</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Store from '../../services/store';
import Order from '../../services/order';

export default {
  name: 'OrderRefundDialog',

  props: [
    'order',
    'ooStatus'
  ],

  watch: {
    '$store.state.refundConfirmationModal'(n) {
      if (n) this.refundAmount = parseFloat(this.order.amount.toFixed(2));
    }
  },

  methods: {
    cancelWithoutRefund() {
      this.refundConfirmationModal = false;
      this.save(true);
    },

    closeRefundConfirmation() {
      this.order.status = this.ooStatus;
      this.$store.state.refundConfirmationModal = false;
    },

    cancelWithRefund() {
      if (this.$refs.refundForm && !this.$refs.refundForm.validate()) return;
      Store.snackbarStart();
      this.processing = true;
      return Order
        .cancelAndProcessRefund(this.order._id, {processRefund: true, refundAmount: this.refundAmount}, false)
        .then(() => {
          this.processing = this.$store.state.refundConfirmationModal = false;
          this.$emit('update:refundProcessed', true);
          Store.snackbarSuccess();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    save(notify = false) {
      Store.snackbarStart();
      this.processing = true;
      return Order
        .changeStatus(this.order._id, this.order.status, notify, false)
        .then(() => {
          this.processing = this.$store.state.refundConfirmationModal = false;
          this.dialog = false;
          Store.snackbarSuccess();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        })
    },

    amountFormatted(amount) {
      let formattedAmount = amount;
      if (this.order.currencyCode === 'INR') formattedAmount = this.currencyPipe(amount, '₹');
      else if (this.order.currencyCode && this.order.currencyCode !== 'INR') formattedAmount = this.currencyPipe(amount, this.order.currencyCode);
      else if (!this.$store.state.country.currencyCode && !this.order.currencyCode) formattedAmount = this.currencyPipe(amount, '₹');
      else formattedAmount = this.currencyPipe(amount, this.$store.state.country.currencySymbol);

      return formattedAmount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },
  },

  data() {
    return {
      processing: false,
      refundAmount: 0,

      validationRules: {
        amountRule: [
          v => v >= 0 || 'Enter valid amount'
        ]
      }
    }
  }
}
</script>

import Store from '../../../services/store';
import router from '../../../router';

import config from '../../../config';

export default {
  name: 'Outlets',

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed('Outlets') || this.$store.state.activeStore.isVendor) return router.push('/business/'+this._id);
    if (!this.doIHaveAccess('actions')) this.headers = this.headers.filter(x => x.text !== 'Actions');
      
    this.fenums = true;
    const p1 = this.fetchOutlets();
    const p2 = this.fetchCommonEnums();

    return Promise.all([p1, p2])
      .then(() => this.fenums = false)
      .catch(() => this.fenums = false);
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchOutlets() {
      this.loading = true;
      return Store.getOutlets(this._id, false)
        .then(res => {
          this.outlets = res.data.map((x, index) => {
            x.index = (index+1)+'.';
            x.statusd = x.isActive ? 'Active' : 'Inactive';
            return x;
          });
          this.loading = false;
        })
        .catch(e => console.log(e));
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return this.cenums = ce;

      return Store.getCommonEnums()
        .then(x => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch(e => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.outlets.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteOutlet(this.beingDeleted._id, false)
        .then(() => { this.confirmDialog = this.processing = false; this.fetchOutlets(); })
        .catch(e => { this.confirmDialog = this.processing = false; console.log(e)});
    },

    close () {
      this.dialog = this.processing = this.loading = false
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedItem);
      if (this.editedItem._id) request = Store.editOutlet(this.editedItem._id, object, false)
      else request = Store.addOutlet(this._id, object, false);
          
      return request 
        .then(() => {
          if (closeModal) this.close();
          this.fetchOutlets();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          this.loading = false;
          Store.snackbarError();
        });
    },

    saveDates(dates) {
      this.$refs.menu.save(dates);
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false, false);
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add new outlet' : 'Edit outlet';
    },
  },

  data: () => {
    return {
      _id: '',
      outlets: [],
      enums: {},
      search: '',
      loading: true,
      valid: false,
      dialog: false,
      processing: false,

      error: '',

      menu: false,
      cenums: {},

      fenums: false,

      translationModal: false,
      translationField: '',

      dates: [],

      editedIndex: -1,
      editedItem: {
        dates: []
      },
      defaultItem: {
        dates: [],
        isActive: true
      },

      confirmDialog: false,
      beingDeleted: {},
      rowItems: config.rowItems,

      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Name',  align: 'start', value: 'name' },
        { text: 'Address', value: 'address', sortable: false },
        { text: 'Contact Number', value: 'contactNumber', sortable: false },
        { text: 'Status', value: 'statusd'},
        { text: 'Actions', align: 'center', value: 'actions', sortable: false}
      ],

      validationRules: {
        basicRule: [
          v => !!v || 'It is required'
        ],
        statusRule: [
          v => v || v === false || 'It is required'
        ],
        datesRule: [
          v => v.length === 2 || 'Both dates are required'
        ],
        discountRule: [
          v => v > 0 || 'Enter valid discount'
        ],
        orderAmountRule: [
          v => v === '' || v === undefined || v > 0 || v === 0 || 'Enter valid amount'
        ],
      }
    }
  }
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-card',{staticClass:"p-4 pl-8 pr-8 pb-2"},[_c('v-row',[_c('v-col',[_c('h5',[_vm._v("Abandoned Checkout Order: "+_vm._s(_vm.order.oid))])])],1),_c('div',{staticClass:"mb-3"}),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item-subtitle',[_vm._v("Name")]),_c('v-list-item-title',[(_vm.hcifv)?_c('span',[_vm._v("-")]):_c('b',[_vm._v(_vm._s(_vm.order.user.name || "-"))])])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item-subtitle',[_vm._v("Mobile")]),_c('v-list-item-title',[(_vm.hcifv)?_c('span',[_vm._v("-")]):_c('b',[_vm._v(" +"+_vm._s(_vm.order.user.callingCode || "91")+" "+_vm._s(_vm.order.user.mobile)+" ")])])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item-subtitle',[_vm._v("Email")]),_c('v-list-item-title',[(_vm.hcifv)?_c('span',[_vm._v("-")]):_c('b',[_vm._v(_vm._s(_vm.order.user.email || "-"))])])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item-subtitle',[_vm._v("Amount")]),_c('v-list-item-title',[_c('b',[_vm._v(_vm._s(_vm.$parent.amountFormatted(_vm.order)))])])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item-subtitle',[_vm._v("Status")]),_c('v-list-item-title',[_c('b',[_vm._v(_vm._s(_vm._f("firstLetterUppercase")(_vm.order.status)))])])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list-item-subtitle',[_vm._v("Last Updated")]),_c('v-list-item-title',[_c('b',[_vm._v(_vm._s(_vm.$parent.formatDate(_vm.order.updatedAt, "lll")))])])],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.order.items},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index)+" ")]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.size)+_vm._s(item.unit)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fc")(item.price))+" ")]}},{key:"item.itemTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fc")(item.itemTotal))+" ")]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [(item.color)?_c('div',{style:('height: 25px; width: 25px; border-radius: 5px; background-color:' +
                  item.color)}):_c('div',[_vm._v("-")])]}}],null,false,2538872387)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.$parent.aodialog = false}}},[_vm._v("Close")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Store from '../../services/store';
import Inventory from '../../services/inventory';

export default {
  name: 'MassTranslate',
  
  props: [
    '_id',
    'items',
    'module',
    'translationModified'
  ],

  mounted() {
    this.fetchCommonEnums();
  },

  computed: {
    getFilteredLanguages() {
      return this.cenums.supportedLanguages ? this.cenums.supportedLanguages.filter(x => x.code != 'en') : [];
    }
  },

  watch: {
    '$store.state.massTranslateModal'(n) {
      if (n) {
        this.translationItems = this.items.map((item, index) => {
          item.i = index + 1 +'.';
          
          this.cenums.supportedLanguages.forEach(x => {
            if (item.translations && item.translations.name && item.translations.name[x.code]) {
              item[x.code] = item.translations.name[x.code];
              if (x.code !== 'en' && !this.selectedLanguages.includes(x.code)) this.selectedLanguages.push(x.code);
            }
          });

          return item;
        });
      }
    },  

    'selectedLanguages'(n) {
      if (n) {
        this.headers = this.headers.slice(0, 2);
        n.forEach(item => {
          const language = this.cenums.supportedLanguages.find(i => item === i.code);
          this.headers.push({ text: language.name + ' ('+language.tr+')', value: language.code, sortable: false });
          this.translationItems.forEach(x => x[language.code] = x[language.code] || '');
        });
      }
    },
  },

  methods: {
    save() {
      const translations = [];
      this.translationItems.forEach(x => {
        this.selectedLanguages.forEach(sl => {
          if (x[sl]) {
            const doesExists = translations.find(t => t._id === x._id);
            if (doesExists) doesExists.translations.name[sl] = x[sl];
            else {
              let tr = x.translations || {};
              if (!tr.name) tr.name = {};
              tr.name[sl] = x[sl];
              tr.name['en'] = x.name;
              translations.push({_id: x._id, translations: tr});
            }
          }
        });
      });

      if (!translations || !translations.length) {
        this.$store.state.snackbar.text = 'No translation to processs';
        this.$store.state.snackbar.color = 'error';
        this.$store.state.snackbar.show = true;
        return;
      }

      Store.snackbarStart(); 
      this.processing = true;

      let translationRequest;
      if (this.module === 'categories') translationRequest = Inventory.translateCategories(this._id, translations);
      else if (this.module === 'products') translationRequest = Inventory.translateProducts(this._id, translations);
      else return this.$store.state.massTranslateModal = false;

      return translationRequest
        .then(() => {
          this.$store.state.massTranslateModal = this.processing = false;
          this.$emit('update:translationModified', true);
          Store.snackbarSuccess();
        })
        .catch(e => {
          console.log(e);
          this.$store.state.massTranslateModal = this.processing = false;
          Store.snackbarError();
        });
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return this.cenums = ce;

      this.processing = true;
      return Store.getCommonEnums()
        .then(x => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
          this.processing = false;
        })
        .catch(e => {
          console.log(e)
          this.processing = false;
        });
    }
  },

  data() {
    return {
      processing: false,
      cenums: {},
      selectedLanguages: [],

      translationItems: [],

      search: '',
      headers: [
        { text: '#', value: 'i', align: 'start' },
        { text: 'Name (English)', value: 'name', sortable: false }
      ],
    }
  }
}
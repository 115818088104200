import Store from '../../services/store';
import Super from '../../services/super';

export default {
  name: 'Call',

  props: [
    'editedItem',
    'countries',
    'callCreated'
  ],

  methods: {
    createCall() {
      if (!this.$refs.form.validate()) return;

      this.processing = true;
      Store.snackbarStart();
      const request = !this.callItem._id ? Super.addCall(this.callItem, false) : Super.editCall(this.callItem._id, this.callItem, false);
      return request
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();
          this.$emit('update:callCreated', true);
          this.close();
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    close() {
      this.$store.state.callModal = false;
    },

    countryText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    }
  },

  computed: {
    formTitle() {
      return this.callItem._id ? 'Edit call' : 'Create a call';
    }
  },

  watch: {
    '$store.state.callModal'(n) {
      if (n) {
        this.callItem = Object.assign({}, this.editedItem);
        if (!this.callItem.callingCode) this.callItem.callingCode = '91';
        if (!this.callItem.fromCallingCode) this.callItem.fromCallingCode = '91';
        //if (!this.callItem.from) this.callItem.from = this.$store.getters.userInfo.mobile;
        if (!this.callItem.duration) this.callItem.duration = '00:00';
      }
    }
  },

  data() {
    const regexp = new RegExp(/^([0-5][0-9]):([0-5][0-9])$/)
    return {
      valid: false, 
      processing: false,

      error: false,
      success: false,
      callItem: {
        callingCode: '91'
      },

      validationRules: {
        basicRule: [
          v => !!v || 'It\'s required'
        ],

        durationRule: [
          v => regexp.test(v) || 'Invalid duration'
        ]
      }
    }
  }
}
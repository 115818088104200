<template>
  <section>
    <v-dialog
      v-model="$store.state.produtQuickFormModal"
      max-width="850px"
      persistent
    >
      <v-progress-linear
        v-if="processing || fenums || loading || fcategories"
        indeterminate
        color="primary"
        class="mb-0"
      ></v-progress-linear>
      <v-card>
        <v-card-text>
          <v-container>
            <v-form ref="form" valid="valid">
              <v-row>
                <v-col>
                  <v-card-title style="padding-left: 0;">
                    <span>{{ formTitle }}</span>
                  </v-card-title>
                </v-col>
              </v-row>

              <v-row v-if="!editedItem._id && isDuplicate">
                <v-col>
                  <v-alert
                    style="padding-bottom: 10px;"
                    :type="isHardDuplicate ? 'error' : 'warning'"
                    outlined
                    icon="mdi-content-duplicate"
                  >
                    <v-row style="margin-top: -5px">
                      <v-col
                        class="vc"
                        style="padding-top: 0; padding-bottom: 0"
                      >
                        You are creating a duplicate product.
                      </v-col>
                      <v-col
                        class="text-right vc"
                        style="padding-top: 0; padding-bottom: 0"
                      >
                        <v-btn
                          text
                          :color="isHardDuplicate ? 'error' : 'warning'"
                          @click="resetEditedItem"
                          ><b
                            >{{ duplicateItem.name }} ({{ duplicateItem.size
                            }}{{ duplicateItem.unit }}) -
                            {{
                              $options.filters.currency(
                                duplicateItem.price,
                                $store.state.country.currencySymbol || "₹"
                              )
                            }}
                            - Edit</b
                          ></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-text-field
                    required
                    outlined
                    v-model="editedItem.name"
                    label="Name"
                    :disabled="
                      loading || processing || !fields.includes('name')
                    "
                    :rules="validationRules.basicRule"
                    append-icon="mdi-translate"
                    @click:append="toggleTranslationModal('name')"
                  >
                  </v-text-field>
                </v-col>

                <v-col v-if="fields.includes('category')" cols="6">
                  <v-autocomplete
                    required
                    outlined
                    :items="categories"
                    v-model="editedItem.category"
                    item-text="name"
                    item-value="_id"
                    label="Category"
                    :loading="fcategories"
                    :disabled="loading || processing || fcategories"
                    :rules="validationRules.basicRule"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col v-if="fields.includes('size')" cols="6">
                  <v-text-field
                    required
                    outlined
                    v-model="editedItem.size"
                    label="Weight/Qty/Size"
                    :disabled="loading || processing"
                    hint="Eg: 1 or 500 or S/M/L"
                    :rules="validationRules.basicRule"
                    append-icon="mdi-translate"
                    @click:append="toggleTranslationModal('size')"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('unit')" cols="6">
                  <v-autocomplete
                    required
                    outlined
                    v-model="editedItem.unit"
                    :items="enums.unit"
                    label="Unit"
                    hint="Eg: kg, g, lt, m"
                    :disabled="loading || fenums || processing"
                    :rules="validationRules.unitRule"
                  ></v-autocomplete>
                </v-col>

                <v-col v-if="fields.includes('quantity')" cols="6">
                  <v-text-field
                    required
                    outlined
                    v-model="editedItem.quantity"
                    label="Available quantity"
                    :disabled="loading || processing"
                    :rules="validationRules.quantityRule"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('inStock')" cols="6">
                  <v-select
                    required
                    outlined
                    v-model="editedItem.inStock"
                    :items="[
                      { key: true, value: 'Yes' },
                      { key: false, value: 'No' },
                    ]"
                    label="Is in stock?"
                    item-value="key"
                    item-text="value"
                    :disabled="loading || processing"
                    :rules="validationRules.statusRule"
                  ></v-select>
                </v-col>

                <v-col v-if="fields.includes('marketPrice')" cols="6">
                  <v-text-field
                    outlined
                    v-model="editedItem.marketPrice"
                    label="M.R.P."
                    :disabled="loading || processing"
                    :rules="
                      editedItem.marketPrice ? validationRules.priceRule : []
                    "
                    :prefix="$store.state.country.currencySymbol || '₹'"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('price')" cols="6">
                  <v-text-field
                    required
                    outlined
                    v-model="editedItem.price"
                    label="Selling price"
                    :disabled="loading || processing"
                    :prefix="$store.state.country.currencySymbol || '₹'"
                    :rules="validationRules.sellingPriceRule"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('orderCap')" cols="6">
                  <v-text-field
                    outlined
                    v-model="editedItem.orderCap"
                    label="Order quantity cap"
                    :disabled="loading || processing"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('brand')" cols="6">
                  <v-text-field
                    outlined
                    v-model="editedItem.brand"
                    label="Brand name (if available)"
                    :disabled="loading || processing"
                    append-icon="mdi-translate"
                    :rules="validationRules.basicRule"
                    @click:append="toggleTranslationModal('brand')"
                  >
                  </v-text-field>
                </v-col>

                <v-col v-if="fields.includes('sku')" cols="6">
                  <v-text-field
                    outlined
                    v-model="editedItem.sku"
                    label="SKU"
                    :rules="validationRules.basicRule"
                    :disabled="loading || processing"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('gstn')" cols="6">
                  <v-text-field
                    outlined
                    v-model="editedItem.gstn"
                    label="GSTN"
                    :rules="validationRules.basicRule"
                    :disabled="loading || processing"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('hasnSacCode')" cols="6">
                  <v-text-field
                    outlined
                    v-model="editedItem.hsnSacCode"
                    label="HSN/SAC Code"
                    :rules="validationRules.basicRule"
                    :disabled="loading || processing"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('gst')" cols="6">
                  <v-text-field
                    outlined
                    v-model="editedItem.gst"
                    label="GST %"
                    :rules="validationRules.basicRule"
                    :disabled="loading || processing"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('barcodeID')" cols="6">
                  <v-text-field
                    outlined
                    v-model="editedItem.barcodeID"
                    label="Barcode ID"
                    :loading="isVerifingDuplicacy"
                    :disabled="loading || processing"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('barcodeText')" cols="6">
                  <v-text-field
                    outlined
                    v-model="editedItem.barcodeText"
                    label="Barcode Text"
                    :disabled="loading || processing"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('gst')" cols="6">
                  <v-text-field
                    outlined
                    v-model="editedItem.gst"
                    label="GST %"
                    :rules="validationRules.basicRule"
                    :disabled="loading || processing"
                  ></v-text-field>
                </v-col>

                <v-col v-if="fields.includes('isActive')" cols="6">
                  <v-select
                    required
                    outlined
                    v-model="editedItem.isActive"
                    :items="[
                      { key: true, value: 'Active' },
                      { key: false, value: 'Inactive' },
                    ]"
                    label="Status"
                    item-value="key"
                    item-text="value"
                    :disabled="loading || processing"
                    :rules="validationRules.statusRule"
                  ></v-select>
                </v-col>

                <div v-if="fields.includes('description')" style="clear: both;">
                  <vue-editor
                    v-model="editedItem.description"
                    :disabled="loading || processing"
                    placeholder="Enter description..."
                  ></vue-editor>
                </div>
              </v-row>

              <v-row v-if="fields.includes('photos')">
                <v-col cols="2" v-for="(photo, index) of photos" :key="index">
                  <v-card
                    class="cat-img"
                    @click="
                      !photo._id && !photo.uploading
                        ? handleClick(index, photo)
                        : ''
                    "
                  >
                    <form v-if="enableRefs" enctype="multipart/form-data">
                      <input
                        v-show="false"
                        :ref="`productImageInputUpload${index}`"
                        name="file"
                        type="file"
                        @change="fileSelected(index)"
                      />
                    </form>

                    <v-img
                      v-if="photo._id"
                      @click="handleClick(index, photo)"
                      :src="getPhotoURL(photo._id)"
                      height="100"
                    ></v-img>
                    <v-icon
                      v-else-if="!photo.uploading"
                      style="font-size: 2.5em;"
                      >mdi-plus</v-icon
                    >

                    <v-progress-circular
                      v-if="photo.uploading"
                      indeterminate
                      color="primary"
                      class="cstm-mini-placing-prod"
                    ></v-progress-circular>
                    <v-icon
                      @click="deletePhoto(index, photo)"
                      class="cstm-placing-delete"
                      v-if="!photo.uploading && photo._id"
                      style="font-size: 2.5em;"
                      color="error"
                    >
                      mdi-delete
                    </v-icon>
                  </v-card>
                </v-col>

                <v-col cols="12"></v-col>
              </v-row>

              <v-row>
                <v-col class="text-right" style="padding-bottom: 0">
                  <v-btn
                    color="error"
                    outlined
                    :disabled="processing || uploading"
                    @click="$parent.close"
                    >Cancel</v-btn
                  >
                  <v-btn
                    class="ml-4"
                    color="success"
                    :disabled="processing || isHardDuplicate || uploading"
                    outlined
                    @click="saveProduct(true)"
                    >Save</v-btn
                  >
                </v-col>

                <v-col cols="12"></v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Translator
      :cenums="cenums"
      :dialog="translationModal"
      :edited-item="editedItem"
      :field="translationField"
      :processing="loading || processing || fenums"
    >
    </Translator>

    <v-dialog v-model="confirmDialogPhoto" max-width="350" persistent>
      <v-progress-linear
        v-if="processing"
        indeterminate
        color="primary"
        class="mb-0"
      ></v-progress-linear>
      <v-card>
        <v-card-title>
          <span>Are you sure?</span>
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete this photo?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            :disabled="processing"
            text
            @click="closeConfirmPhoto"
            >No</v-btn
          >
          <v-btn
            color="green darken-1"
            :disabled="processing"
            text
            @click="finalDeletePhoto"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<style>
.cstm-mini-placing-prod {
  text-align: center !important;
  position: absolute !important;
  top: 35px !important;
  left: 35px !important;
  background: white !important;
  border-radius: 50% !important;
  color: var(--primary) !important;
  opacity: 0.8 !important;
}
</style>

<script type="text/javascript">
import Vue from "vue";
import _ from "underscore";

import MasterInventory from "../../services/master-inventory";
import Inventory from "../../services/inventory";
import FileUpload from "../../services/file-upload";
import Store from "../../services/store";
import config from "../../config";
import { getS3URL } from "../../helpers/misc";

export default {
  props: ["_id", "editedItem", "productModified", "fields", "returnProduct"],

  watch: {
    "$store.state.produtQuickFormModal"(n) {
      if (n) {
        this.enableRefs = true;
        this.duplicateItem = {};
        this.isDuplicate = false;
        this.editedItem.isAdded = true;
        this.fenums = true;
        const p1 = this.fetchCommonEnums();

        let p2;
        let p3;

        if (this._id === this.growcifyMasterInventory) {
          p2 = MasterInventory.getProductEnums()
            .then((x) => (this.enums = x.data))
            .catch((e) => console.log(e));
        } else {
          p2 = Inventory.getProductEnums(this._id)
            .then((x) => (this.enums = x.data))
            .catch((e) => console.log(e));

          if (this.fields.includes("category")) {
            let catRequest;
            if (this.$store.state.activeStore.isVendor)
              catRequest = Inventory.getVendorSubscribedCategories(
                this._id,
                false
              );
            else catRequest = Inventory.getCategories(this._id, false);

            this.fcategories = true;
            p3 = catRequest
              .then((x) => {
                this.categories = x.data;
                this.fcategories = false;
              })
              .catch((e) => {
                console.log(e);
                this.fcategories = false;
              });
          }
        }

        const photos = this.editedItem.photos.map((x) => {
          return { uploading: false, _id: x, progress: 0, sheet: false };
        });
        this.photos = photos;

        const pl = this.editedItem.photos.length;
        if (pl < 6)
          for (let p = 0; p < 6 - pl; p++) this.photos.push(this.photoItem);

        return Promise.all([p1, p2, p3])
          .then(() => (this.fenums = false))
          .catch(() => (this.fenums = false));
      }
    },

    "editedItem.name"(n) {
      if (n && !this.editedItem._id) this.verifyDuplicacy();
    },

    "editedItem.size"(n) {
      if (n && !this.editedItem._id) this.verifyDuplicacy();
    },

    "editedItem.unit"(n) {
      if (n && !this.editedItem._id) this.verifyDuplicacy();
    },

    "editedItem.price"(n) {
      if (n) {
        if (!this.editedItem._id) this.verifyDuplicacy();
      }
    },

    "editedItem.sku"(n) {
      if (n) this.verifyDuplicacy();
    },

    "editedItem.barcodeID"(n) {
      if (n) this.verifyDuplicacy();
    },
  },

  methods: {
    saveProduct(closeModal = true) {
      if (!this.$refs.form.validate()) return;

      Store.snackbarStart();
      const object = Object.assign({}, this.editedItem);
      if (this.photos && this.photos.length) {
        const newPhotos = this.photos.filter((x) => x._id).map((x) => x._id);
        object.photos = newPhotos;
      }

      object.photos = _.uniq(object.photos);
      if (!object.parent || object.parent === "") delete object.parent;
      if (!object.category || object.category === "") delete object.category;

      let request;
      if (this.editedItem._id)
        request = Inventory.editProduct(this.editedItem._id, object, false);
      else request = Inventory.addProduct(this._id, object, false);

      this.processing = true;
      return request
        .then((res) => {
          this.processing = this.confirmDialogPhoto = false;
          if (object.photos) this.editedItem.photos = object.photos;
          if (closeModal) {
            //close only if asked
            this.editedItem.isAdded = !this.editedItem._id;
            if (!this.editedItem._id) this.editedItem._id = res.data._id;
            this.$emit(
              "update:productModified",
              this.returnProduct ? this.editedItem : true
            );
            this.$store.state.produtQuickFormModal = false;
          }
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.saveProduct();
    },

    verifyDuplicacy: _.debounce(function() {
      this.isDuplicate = false;
      this.isVerifingDuplicacy = true;
      return Store.isDuplicateProduct(this._id, this.editedItem)
        .then((x) => {
          if (x.data) {
            this.duplicateItem = x.data;
            this.isDuplicate = true;

            if (
              this.duplicateItem.barcodeIDDuplicacy ||
              this.duplicateItem.skuDuplicacy
            ) {
              if (this.duplicateItem.skuDuplicacy)
                this.$store.state.snackbar.text = "Duplicate SKU";
              else this.$store.state.snackbar.text = "Duplicate Barcode ID";

              this.$store.state.snackbar.color = "error";
              this.$store.state.snackbar.show = true;
            }
          }
          this.isVerifingDuplicacy = false;
        })
        .catch(() => {
          this.isVerifingDuplicacy = false;
        });
    }, 500),

    resetEditedItem() {
      this.duplicateItem.photos = this.duplicateItem.photos.map((x) => {
        return {
          uploading: false,
          _id: x,
          progress: 0,
          sheet: false,
        };
      });

      const pl = this.duplicateItem.photos.length;
      if (pl < 6)
        for (let p = 0; p < 6 - pl; p++)
          this.duplicateItem.photos.push(this.photoItem);

      if (!this.duplicateItem.comboItems)
        this.duplicateItem.comboItems = JSON.parse(JSON.stringify([]));
      else {
        this.duplicateItem.comboItems = this.duplicateItem.comboItems.map(
          (x, i) => {
            if (x._id._id) {
              const obj = x._id;
              obj.deductQty = x.deductQty;
              x = obj;
            }
            x.index = i + 1 + ".";
            return x;
          }
        );
      }

      const oldItem = JSON.parse(
        JSON.stringify(Object.assign({}, this.duplicateItem))
      );
      this.$emit("update:editedItem", oldItem);
      this.duplicateItem = {};
      this.isDuplicate = false;
    },

    getPhotoURL(data) {
      const array = data.split(".");
      if (
        data &&
        !array.includes(config.growcifyMasterInventory) &&
        array[1] === "png"
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    handleClick(index, photo) {
      if (!photo.id) this.pickImage(index);
      else photo.sheet = !photo.sheet;
    },

    getRef(index) {
      return this.$refs["productImageInputUpload" + index][0];
    },

    pushPhotoObject(index, _id, uploading, uploadProgress, sheet) {
      Vue.set(this.photos, index, {
        uploading: uploading,
        _id: _id,
        uploadProgress: uploadProgress,
        sheet: sheet,
      });
    },

    pickImage(index) {
      this.getRef(index).click();
    },

    fileSelected(index) {
      const file = this.getRef(index).files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      //const localURL = URL.createObjectURL(file);
      this.pushPhotoObject(index, undefined, true, 0, false);
      this.uploading = true;
      return FileUpload.upload(this._id, "products", file)
        .then((x) => {
          const _id = x.data.file;
          this.pushPhotoObject(index, _id, false, 0, false);
          if (this.editedItem._id) this.saveProduct(false);
          this.uploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    invalidImageError() {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 2000);
    },

    isFileImage(file) {
      return file && file["type"].split("/")[0] === "image";
    },

    closeConfirmPhoto() {
      this.confirmDialogPhoto = false;
      this.beingDeletedPhoto = {};
    },

    deletePhoto(index, photo) {
      this.confirmDialogPhoto = true;
      this.beingDeletedPhoto = photo;
    },

    finalDeletePhoto() {
      this.photos = this.photos.filter(
        (x) => !x._id || x._id !== this.beingDeletedPhoto._id
      );
      this.photos.push(this.photoItem);
      this.saveProduct(false);
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.cenums = ce);

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => console.log(e));
    },
  },

  computed: {
    formTitle() {
      return this.editedItem._id
        ? "Edit product - " + this.editedItem.name
        : "Add new product";
    },

    isHardDuplicate() {
      return (
        this.duplicateItem.barcodeIDDuplicacy || this.duplicateItem.skuDuplicacy
      );
    },
  },

  data() {
    return {
      valid: false,
      loading: false,
      processing: false,

      photos: [],
      showError: false,
      uploading: false,
      enableRefs: false,
      photoItem: {
        uploading: false,
        _id: undefined,
        progress: 0,
        sheet: false,
      },
      confirmDialogPhoto: false,
      beingDeletedPhoto: {},

      translationModal: false,
      translationField: "",

      fenums: false,
      cenums: [],
      enums: [],
      categories: [],
      fcategories: false,
      isVerifingDuplicacy: false,

      growcifyMasterInventory: config.growcifyMasterInventory,

      isDuplicate: false,
      duplicateItem: {},

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        quantityRule: [(v) => !!v || v >= 0 || "Enter a valid number"],
        unitRule: [(v) => v === "" || !!v || "Select a unit"],
        statusRule: [(v) => !!v || v === false || "It's is required"],
        priceRule: [(v) => (v && v >= 0) || "Enter a valid price"],
        sellingPriceRule: [
          (v) => (!isNaN(v) && v >= 0) || "Enter a valid price",
          (v) =>
            !this.editedItem.marketPrice ||
            v <= parseFloat(this.editedItem.marketPrice) ||
            "Selling Price can not be greater than M.R.P.",
        ],
      },
    };
  },
};
</script>

import moment from "moment";

import Store from "../../../services/store";
import Order from "../../../services/order";
import Util from "../../../services/util";
import router from "../../../router";

import User from "../../../services/user";
import Transaction from "../../../services/transaction";

import _ from "underscore";

export default {
  name: "OrderForm",

  computed: {
    formTitle() {
      return !this.orderId ? "Create new order" : "Edit order";
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    isOrderWiseTax() {
      return this.activeStore.applyTax &&
        this.activeStore.taxType === "order" &&
        this.activeStore.tax
        ? true
        : false;
    },

    taxKey() {
      return "Tax";
    },
  },

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Orders")) return router.push("/business/" + this._id);
    //if (!this.doIHaveAccess('actions')) this.headers = this.headers.filter(x => x.text !== 'Actions');

    this.orderId = this.$route.params.orderId;
    this.activeStore = this.$store.state.activeStore;

    this.loading = true;

    const p1 = this.fetchCustomers();
    const p2 = this.fetchOrderEnums();
    const p3 = this.fetchTransactionEnums();
    const p4 = this.fetchDeliverymen();
    const p5 = this.fetchCommonEnums();
    const p6 = this.fetchCoupons();
    const p7 = this.fetchDeliverySlots();

    const promises = [p1, p2, p3, p4, p5, p6, p7];

    if (this.orderId) {
      const p8 = this.fetchOrder(true);
      promises.push(p8);
    } else {
      this.order.currencyCode = this.$store.state.country.currencyCode || "INR";
    }

    return Promise.all()
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false));
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchOrder(fl = false) {
      if (fl) this.oloading = true;
      return Order.getOrder(this.orderId, false)
        .then((res) => {
          this.order = res.data;
          this.order.items.forEach((x, i) => {
            x.index = i;
            x.name = x._id.name;
            x.inStock = x._id.inStock;
            x.quantity = x._id.quantity;
          });

          if (res.data.address) this.order.address = res.data.address._id;
          this.order.paymentStatus = res.data.transaction.paymentStatus;

          if (this.order.coupon && this.order.coupon._id)
            this.order.coupon = this.order.coupon._id;
          if (this.order.slot && this.order.slot._id)
            this.order.slot = this.order.slot._id;
          if (!this.order.currencyCode)
            this.order.currencyCode =
              this.$store.state.country.currencyCode || "INR";
          if (this.order.deliveryDate)
            this.order.deliveryDate = moment(this.order.deliveryDate).format(
              "YYYY-MM-DD"
            );

          if (!this.order.deliveryCharge) this.order.deliveryCharge = 0;
          if (!this.order.discount) this.order.discount = 0;

          this.fetchAddresses();
          this.pushCalculationItems();
          this.oloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.oloading = false;
        });
    },

    filterDeliveryModes() {
      this.filteredDeliveryModes = this.cenums.deliveryModes.filter((x) =>
        this.activeStore.deliveryModes.includes(x._id)
      );
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) {
        this.cenums = ce;
        this.filterDeliveryModes();
        return;
      }

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
          this.filterDeliveryModes();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    fetchCoupons() {
      return Store.getCoupons(this._id, false)
        .then((res) => (this.coupons = res.data.filter((x) => x.isActive)))
        .catch((e) => console.log(e));
    },

    fetchDeliverySlots() {
      return Store.getDeliverySlots(this._id, false)
        .then(
          (res) => (this.deliverySlots = res.data.filter((x) => x.isActive))
        )
        .catch((e) => console.log(e));
    },

    fetchCustomers() {
      return User.getSelectableUsers(this._id, false)
        .then((res) => (this.customers = res.data))
        .catch((e) => console.log(e));
    },

    fetchAddresses() {
      this.addressLoading = true;
      return User.getAddresses(
        this._id,
        this.order.user._id || this.order.user,
        false
      )
        .then((res) => {
          this.addresses = res.data;
          this.addressLoading = false;
        })
        .catch(() => (this.addressLoading = false));
    },

    fetchOrderEnums() {
      this.eloading = true;
      return Order.getOrderEnums(this._id)
        .then((res) => {
          this.oenums = res.data;
          this.oenums.status = this.oenums.status.map((x) => {
            return {
              key: x,
              value: x.charAt(0).toUpperCase() + x.slice(1),
            };
          });
          this.eloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.eloading = false;
        });
    },

    fetchTransactionEnums() {
      return Transaction.getEnums(this._id)
        .then((res) => {
          this.tenums = res.data;
          this.tenums.paymentStatus = this.tenums.paymentStatus.map((x) => {
            return {
              key: x,
              value: x.charAt(0).toUpperCase() + x.slice(1),
            };
          });
        })
        .catch((e) => console.log(e));
    },

    fetchDeliverymen() {
      this.dloading = true;
      return Store.getEmployeesByRole(this._id, "deliveryman")
        .then((res) => {
          this.deliverymen = res.data.filter((x) => x.isActive);
          this.dloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.dloading = false;
        });
    },

    getCallingCode(item) {
      return item.callingCode || this.$store.state.country.callingCode || "91";
    },

    customerText(item) {
      let text = "";
      if (item.name) text += item.name;
      if (text && item.mobile)
        text += " (+" + this.getCallingCode(item) + " " + item.mobile + ")";
      if (text && item.email) text += " [" + item.email + "]";

      if (!text) text = "+" + this.getCallingCode(item) + " " + item.mobile;

      return text;
    },

    addressText(item) {
      let text = "";
      if (item.name) text += item.name;
      if (item.mobile)
        text += " (+" + this.getCallingCode(item) + " " + item.mobile + ")";
      if (item.street) text += ", " + item.street;
      if (item.area) text += ", " + item.area;
      if (item.landmark) text += ", " + item.landmark;
      if (item.city) text += ", " + item.city;
      if (item.state) text += ", " + item.state;
      if (item.country) text += ", " + item.country;
      if (item.pincode) text += " - " + item.pincode;

      return text;
    },

    text(item) {
      return `${item.name} (${item.size}${
        item.unit
      }) - Price: ${this.$options.filters.fc(this.getPrice(item))}`;
    },

    querySelections(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;
      return Store.searchProducts(this._id, keyword, false)
        .then((x) => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;
          this.products = x.data.filter((x) => x.isActive);
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    },

    productSelected() {
      this.lastSearched = this.selectedProduct.name;
      if (
        this.order.items.find(
          (x) =>
            x._id === this.selectedProduct._id ||
            (x._id && x._id._id === this.selectedProduct._id)
        )
      )
        return;

      //remove the last element to re-enter at the end
      this.order.items = this.order.items.filter(
        (x) => !this.toFilterItems.includes(x.price)
      );

      this.order.items.push({
        index: this.order.items.length,
        _id: this.selectedProduct._id,
        name: this.selectedProduct.name,
        categoryName: this.selectedProduct.category.name,
        qty: 1,
        price: this.getPrice(this.selectedProduct),
        size: this.selectedProduct.size,
        unit: this.selectedProduct.unit,
        gst: this.selectedProduct.gst,
        inStock: this.selectedProduct.inStock,
        quantity: this.selectedProduct.quantity,
        purchasePrice: this.selectedProduct.purchasePrice,
        marketPrice: this.selectedProduct.marketPrice,
        beforeTaxPrice: this.selectedProduct.beforeTaxPrice,
      });

      this.pushCalculationItems();

      //this.products = this.products.filter(x => x._id !== this.selectedProduct._id);
      this.searchInput = "";
      this.selectedProduct = {};

      this.calculateDiscount();
    },

    pushCalculationItems() {
      this.order.items.push({ price: "Subtotal" });
      if (!this.isVendor) {
        this.order.items.push({ price: "Delivery Charge" });
        this.order.items.push({ price: "Packaging Charge" });
        this.order.items.push({ price: "Other Charges" });
        this.order.items.push({ price: "Discount" });
        if (this.isOrderWiseTax) this.order.items.push({ price: this.taxKey });
        this.order.items.push({ price: "Total" });
      }
    },

    calculateDiscount() {
      //this.couponMessage = '';
      this.coupon = this.coupons.find((x) => x._id === this.order.coupon);
      if (
        this.coupon &&
        this.order.coupon &&
        this.getOrderTotal() &&
        this.order.items.length > 4
      ) {
        const body = {
          paymentMethod: this.order.paymentMethod,
          amount: this.getOrderTotal(),
          code: this.coupon.code,
        };
        this.validating = true;
        return Store.validateCouponCode(this._id, body, false)
          .then((x) => {
            if (x.data.status && x.data.status === 403) {
              this.couponMessage = x.data.message;
              this.order.discount = 0;
              this.order.coupon = undefined;
            } else {
              this.couponMessage = "Code applied";
              this.order.discount = x.data.discount;
            }

            this.validating = false;
          })
          .catch((e) => {
            console.log(e);
            this.validating = false;
          });
      }
    },

    removeItem(item) {
      this.order.items = this.order.items.filter((x) => x._id != item._id);

      if (this.order.items.length === 4) this.order.items = [];
      //empty if there is no items
      else this.calculateDiscount();

      this.order.items = this.order.items.map((x, index) => {
        x.index = index;
        return x;
      });
    },

    getSubtotal() {
      const items = this.order.items.filter(
        (x) => !this.toFilterItems.includes(x.price)
      );
      let subtotal = items.reduce((a, b) => a + b.price * b.qty, 0);

      //calculations of add-ons
      items.forEach((x) => {
        if (x.selectedAddons) {
          x.selectedAddons.forEach((i) => {
            if (i) subtotal = subtotal + i.reduce((a, b) => a + b.price, 0);
          });
        }
      });

      return subtotal;
    },

    getItemTotal(item) {
      let itemTotal = item.price;
      if (item.selectedAddons) {
        item.selectedAddons.forEach((i) => {
          if (i) itemTotal = itemTotal + i.reduce((a, b) => a + b.price, 0);
        });
      }

      return itemTotal;
    },

    getDeliveryCharge() {
      const as = this.activeStore;
      return (
        this.order.deliveryCharge ||
        (!this.order._id &&
        this.order.deliveryMode === "home-delivery" &&
        (!as.deliveryFreeAbove || as.deliveryFreeAbove > this.getSubtotal())
          ? as.deliveryCharge || 0
          : 0)
      );
    },

    getOrderTotal() {
      if (!this.order.discount || !parseFloat(this.order.discount))
        return this.getSubtotal() + parseFloat(this.getDeliveryCharge());
      return this.getSubtotal() + parseFloat(this.getDeliveryCharge());
    },

    getTotal(taxable = false) {
      let totalPayable =
        this.getSubtotal() + parseFloat(this.getDeliveryCharge());
      if (this.order.discount) {
        totalPayable = totalPayable - parseFloat(this.order.discount);
      }

      if (!taxable && this.activeStore.applyTax) {
        totalPayable = totalPayable + this.totalTax();
      }

      if (this.order.packagingCharge > 0) {
        totalPayable = totalPayable + parseFloat(this.order.packagingCharge);
      }

      if (this.order.otherCharges > 0) {
        totalPayable = totalPayable + parseFloat(this.order.otherCharges);
      }

      return parseFloat(totalPayable.toFixed(2));
    },

    resetOrder() {
      this.order = {
        deliveryCharge: 0,
        discount: 0,
        items: [],
      };
    },

    totalTax() {
      let tax = 0;
      if (this.isOrderWiseTax) {
        const taxableAmount = this.getTotal(true);
        tax = parseFloat(
          ((taxableAmount * this.activeStore.tax) / 100).toFixed(2)
        );
      }
      return tax;
    },

    placeOrder() {
      if (this.$refs.form && !this.$refs.form.validate()) return;
      const orderObject = JSON.parse(JSON.stringify(this.order));
      orderObject.items = orderObject.items.filter(
        (x) => !this.toFilterItems.includes(x.price)
      );

      orderObject.amount = parseFloat(this.getTotal().toFixed(2));
      orderObject.discount = parseFloat(orderObject.discount);
      orderObject.deliveryCharge = parseFloat(this.getDeliveryCharge());
      if (!orderObject.deliveryCharge) orderObject.deliveryCharge = 0;

      orderObject.taxableAmount = this.getTotal(true);
      orderObject.taxInAmount = this.totalTax();
      orderObject.tax = this.isOrderWiseTax ? this.activeStore.tax : 0;

      Store.snackbarStart();
      this.processing = true;
      return Order.placeOrder(this._id, orderObject, false)
        .then((x) => {
          this.orderSuccess = true;
          Store.snackbarSuccess();
          Util.refreshCounts(this._id);
          this.processing = this.orderSuccess = false;
          return router.push("/business/" + this._id + "/orders/" + x.data._id);
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    editOrder() {
      if (this.$refs.form && !this.$refs.form.validate()) return;
      const orderObject = JSON.parse(JSON.stringify(this.order));
      orderObject.items = orderObject.items.filter(
        (x) => !this.toFilterItems.includes(x.price)
      );
      orderObject.items = orderObject.items.map((x) => {
        x._id = x._id._id || x._id;
        return x;
      });

      orderObject.amount = parseFloat(this.getTotal().toFixed(2));
      orderObject.discount = parseFloat(orderObject.discount);
      orderObject.deliveryCharge = parseFloat(this.getDeliveryCharge());
      orderObject.transaction.paymentStatus = orderObject.paymentStatus;
      orderObject.transaction.amount = orderObject.amount;

      //in case these haven't changed
      if (orderObject.coupon && orderObject.coupon._id)
        orderObject.coupon = orderObject.coupon._id;
      if (orderObject.slot && orderObject.slot._id)
        orderObject.slot = orderObject.slot._id;

      Store.snackbarStart();
      this.processing = true;
      return Order.editOrder(orderObject._id, orderObject, false)
        .then((x) => {
          this.orderSuccess = true;
          Store.snackbarSuccess();
          this.processing = this.orderSuccess = false;
          return router.push("/business/" + this._id + "/orders/" + x.data._id);
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    amountFormatted(amount) {
      let formattedAmount = amount;
      if (this.order.currencyCode === "INR")
        formattedAmount = this.currencyPipe(amount, "₹");
      else if (this.order.currencyCode && this.order.currencyCode !== "INR")
        formattedAmount = this.currencyPipe(amount, this.order.currencyCode);
      else if (
        !this.$store.state.country.currencyCode &&
        !this.order.currencyCode
      )
        formattedAmount = this.currencyPipe(amount, "₹");
      else
        formattedAmount = this.currencyPipe(
          amount,
          this.$store.state.country.currencySymbol
        );

      return formattedAmount;
    },

    getCount(product) {
      return this.findProduct(product)?.qty || 0;
    },

    findProduct(product) {
      return this.order.items.find((x) => x._id === product._id);
    },

    calculateDynamicPricing(product, price) {
      if (!product.pricing || !product.pricing.length) return price;
      const count = this.getCount(product);
      const pricing = product.pricing;

      pricing.forEach((item) => {
        if (count >= item.gqty) price = item.price;
      });

      return price;
    },

    getPrice(product) {
      if (!product.basePrice) product.basePrice = product.price;
      let price = this.calculateDynamicPricing(product, product.basePrice);

      let discount = 0;
      if (product.discountIsActive) {
        if (product.discountBy === "percent")
          discount = discount + (price * product.discount) / 100;
        else if (price >= product.discount)
          discount = discount + product.discount;
      }

      if (discount > 0) {
        discount = parseFloat(discount.toFixed(2));
        if (price >= discount) price = price - discount;
      }

      if (!product.beforeTaxPrice) product.beforeTaxPrice = price;
      if (
        this.activeStore.applyTax &&
        this.activeStore.taxType == "product" &&
        product.gst
      )
        price = price + this.calculateTax(price, product.gst);
      return parseFloat(Number(price).toFixed(2));
    },

    calculateTax(amount, tax) {
      let taxAmount = 0;
      if (amount && tax)
        taxAmount = parseFloat(((amount * tax) / 100).toFixed(2));
      return taxAmount;
    },

    totalItemTax(item) {
      if (
        !(
          this.activeStore.applyTax &&
          this.activeStore.taxType == "product" &&
          item.gst
        )
      )
        return false;

      const cartItem = this.findProduct(item);
      const amount = cartItem.beforeTaxPrice * cartItem.qty;
      return this.calculateTax(amount, cartItem.gst);
    },

    beforeTaxTotal(item) {
      const cartItem = this.findProduct(item);
      return cartItem.beforeTaxPrice * cartItem.qty;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    onBlurDC(n) {
      if (n && n.target.value == "") this.order.deliveryCharge = 0;
    },

    onBlurDisc(n) {
      if (n && n.target.value == "") this.order.discount = 0;
    },

    onBlurPC(n) {
      if (n && n.target.value == "") this.order.packagingCharge = 0;
    },

    onBlurOC(n) {
      if (n && n.target.value == "") this.order.otherCharges = 0;
    },
  },

  watch: {
    searchInput: _.debounce(function(newVal, oldVal) {
      const array = newVal ? newVal.split("(") : [];
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        array.length === 1
      )
        this.querySelections(newVal);
    }, 500),
  },

  data() {
    return {
      _id: "",
      activeStore: {},

      orderId: "", //for order/:_id/edit
      valid: false,

      cenums: {},
      oenums: {},
      tenums: {},
      filteredDeliveryModes: [],

      toFilterItems: [
        "Subtotal",
        "Discount",
        "Total",
        "Delivery Charge",
        "Packaging Charge",
        "Other Charges",
        "Tax",
      ],

      menu: false,
      loading: false,
      oloading: false,
      processing: false,
      validating: false,
      addressLoading: false,
      orderSuccess: false,

      isSearching: false,
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,

      searchInput: "",
      lastSearched: "",
      selectedProduct: {},
      coupon: {},

      couponMessage: "",

      ploading: false,
      pheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Category", value: "_id.category.name" },
        { text: "Size", value: "size" },
        { text: "Quantity", value: "qty", align: "center" },
        { text: "Price", value: "price", align: "right" },
        { text: "Total", value: "total", align: "right" },
      ],

      coupons: [],
      deliverySlots: [],
      products: [],
      customers: [],
      addresses: [],
      deliverymen: [],

      order: {
        discount: 0,
        deliveryCharge: 0,
        items: [],
        deliveryMode: "home-delivery",
        status: "delivered",
      },

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        amountRule: [(v) => v > 0 || "Enter a valid amount"],
        discountRule: [(v) => v >= 0 || "Enter a valid amount"],
        deliveryChargeRule: [(v) => v >= 0 || "Enter a valid amount"],
        numberRule: [(v) => !isNaN(v) || "Enter a valid number"],
      },
    };
  },
};

import Store from "../../../services/store";
import Order from "../../../services/order";
import router from "../../../router";
import config from "../../../config";
import moment from "moment";

export default {
  name: "Reports",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Reports")) {
      return router.push("/business/" + this._id);
    }

    if (!this.doIHaveAccess("actions")) {
      this.headers = this.headers.filter((x) => x.text !== "Actions");
    }

    this.fetchReports();
    this.fetchCommonData("modules,deliveryModes");
    this.fetchOrderEnums();
    this.fetchEmployees();
  },

  computed: {
    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    fileFormats() {
      const formats = [
        { key: "pdf", value: "PDF" },
        { key: "xls", value: "Excel" },
      ];
      if (!this.isVendor) formats.push({ key: "json", value: "JSON" });
      return formats;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchCommonData(props) {
      this.fechingcd = true;
      return Store.getCommonData(props)
        .then((x) => {
          if (!this.isVendor) {
            this.modules = x.data.modules;
          } else {
            this.modules = x.data.modules.filter((x) => x.forVendor);
          }
          this.deliveryModes = x.data.deliveryModes;
          this.fechingcd = false;
        })
        .catch(() => {
          this.fechingcd = false;
        });
    },

    getItemsOfModule() {
      let items = [];
      const module = this.modules.find((x) => x.name === this.report.module);
      if (!module) return items;
      items = module.fields;
      if (
        this.report.module == "Orders" &&
        this.isVendor &&
        this.$store.state.activeStore.vendorOwner.hideDeliveryChargeFromVendors
      )
        items = module.fields.filter((x) => x.key != "deliveryCharge");
      return items;
    },

    saveDates(dates) {
      this.$refs.menu.save(dates);
    },

    download(item) {
      this.downloading = true;
      return Store.getReport(item._id)
        .then(() => {
          this.downloading = false;
          this.$store.state.snackbar.text =
            "Your request has been submit. Once the report is ready, you'll receive it via email. Thanks.";
          this.$store.state.snackbar.timeout = 2500;
          this.$store.state.snackbar.color = "success";
          this.$store.state.snackbar.show = true;
        })
        .catch(() => {
          this.downloading = false;
        });
    },

    editItem(item) {
      this.report = item;
      this.createMode = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteReport(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchReports();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    resetMode() {
      this.report = { dates: [] };
      this.createMode = false;
    },

    fetchReports() {
      this.loading = true;
      return Store.getReports(this._id, false)
        .then((res) => {
          this.reports = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            x.readableDates = x.dates
              .map((x) => moment(x).format("ll"))
              .join(" - ");
            x.formatR = x.format ? x.format.toUpperCase() : "XLS";
            x.createdAtR = moment(x.updatedAt).format("lll");
            return x;
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    generateReport() {
      if (this.$refs.form && !this.$refs.form.validate()) return;

      this.processing = true;
      Store.snackbarStart();

      let request;
      const object = Object.assign({}, this.report);
      if (this.report._id)
        request = Store.editReport(this.report._id, object, false);
      else request = Store.generateReport(this._id, object, false);

      return request
        .then((res) => {
          if (res.data._id) this.download(res.data);
          this.processing = false;
          this.resetMode();
          this.fetchReports();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    allAction() {
      if (this.report.fields && this.report.fields.length) {
        return (this.report.fields = []);
      }

      this.report.fields = [...this.getItemsOfModule().map((x) => x.key)];
    },

    fetchOrderEnums() {
      return Order.getOrderEnums(this._id)
        .then((res) => {
          this.oenums = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    fetchEmployees() {
      return Store.getEmployees(this._id)
        .then((res) => {
          this.employees = res.data;
        })
        .catch((e) => console.log(e));
    },

    fetchVendors() {
      this.vendorsLoading = true;
      return Store.getVendors(this._id, false)
        .then((res) => {
          this.vendors = res.data;
          this.vendorsLoading = false;
        })
        .catch((e) => console.log(e));
    },
  },

  watch: {
    "report.module"(n) {
      if (n == "Orders") {
        this.fetchVendors();
      }
    },
  },

  data() {
    return {
      createMode: false,
      processing: false,
      downloading: false,
      vendorsLoading: false,
      loading: false,
      menu: false,

      fechingcd: false,

      valid: false,

      vendors: [],
      employees: [],
      oenums: {},
      report: {
        dates: [],
        fields: [],
        filters: {
          createdBy: [],
          type: [],
          status: [],
          paymentMethod: [],
          vendors: [],
        },
      },

      search: "",
      reports: [],

      confirmDialog: false,
      beingDeleted: {},

      rowItems: config.rowItems,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Module", value: "module" },
        { text: "Dates", value: "readableDates" },
        { text: "Format", value: "formatR" },
        { text: "Date & Time", value: "createdAtR" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      modules: [],
      deliveryModes: [],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        datesRule: [(v) => v.length === 2 || "Both dates are required"],
        lengthRule: [(v) => v.length > 0 || "It is required"],
      },
    };
  },
};

import moment from "moment";

import User from "../../../services/user";
import Store from "../../../services/store";
import router from "../../../router";

import ContractForm from "../../../components/contract-form/contract-form.vue";
import exportFromJSON from "export-from-json";
import getSymbolFromCurrency from "currency-symbol-map";

export default {
  name: "User",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Customers"))
      return router.push("/business/" + this._id);
    //if (!this.doIHaveAccess('actions')) this.headers = this.headers.filter(x => x.text !== 'Actions');

    this.userId = this.$route.params.customerId;
    this.fetchUser();
    this.fetchProfile();
    this.fetchWallet();
    this.fetchOrders();
    this.fetchReferrals();
    this.fetchContracts();
    this.fetchCountries();
    this.fetchAddresses();
    this.fetchWishlist();
    this.fetchRecentlyOrdered();
    this.fetchActiveMemberships();
    this.fetchDevices();
  },

  components: {
    ContractForm,
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },

    formAddressTitle() {
      return this.editedAddressIndex === -1
        ? "Add new address (" + (this.user.name || this.user.mobile) + ")"
        : "Edit address (" + (this.user.name || this.user.mobile) + ")";
    },

    country() {
      return this.$store.state.activeStore.country;
    },

    astore() {
      return this.$store.state.activeStore;
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    walletBalance() {
      if (!this.wallet) return 0;
      return (Math.floor(100 * this.wallet.balance) / 100).toFixed(2);
    },

    wishlistItems() {
      let items = [];
      const wishlist = this.wishlist;
      if (this.wishlist && wishlist.items) {
        wishlist.items.reverse();
        items = wishlist.items.map((x, index) => {
          x.index = index + 1 + ".";
          return x;
        });
      }
      return items;
    },

    roItems() {
      let items = [];
      if (this.recentlyOrdered) {
        items = this.recentlyOrdered.map((x, index) => {
          x.index = index + 1 + ".";
          return x;
        });
      }
      return items;
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  watch: {
    membership(n) {
      if (this.dontCall) return (this.dontCall = false);
      this.saveMembership(n || "");
    },

    contractModified(n) {
      if (n) {
        this.fetchContracts();
        this.contractModified = false;
      }
    },

    addressDialog(n) {
      if (n && !this.editedAddressItem.callingCode) {
        this.editedAddressItem.callingCode = this.$store.state.activeStore.country.callingCode;
      }
    },

    "profile.isApproved"() {
      this.editProfile();
    },
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchActiveMemberships() {
      return Store.getActiveMemberships(this._id)
        .then((x) => (this.memberships = x.data))
        .catch((e) => console.log(e));
    },

    fetchWallet() {
      return User.getWallet(this._id, this.userId, false)
        .then((x) => {
          if (x.data) {
            this.wallet = x.data;
            this.fetchWalletHistory();
          }
        })
        .catch((e) => console.log(e));
    },

    fetchWalletHistory() {
      this.whLoading = true;
      return User.getWalletHistory(this._id, this.wallet._id, false)
        .then((x) => {
          if (x.data && x.data.length) {
            this.walletHistory = x.data;
            this.walletHistory.forEach((x, i) => {
              x.index = i + 1 + ".";
            });
          }
          this.whLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.whLoading = false;
        });
    },

    exportWalletHistory() {
      const data = [];

      this.walletHistory.forEach((x) => {
        const item = {};
        item["#"] = x.index;
        item["REF ID"] = x._id;
        item["Currency Code"] = x.currencyCode;
        item["Amount"] = x.amount;
        item["TXN"] = x.txn;
        item["Transaction Type"] = x.txn;
        item["Payment Method"] = x.paymentMethod;
        item["Previous Wallet Balance"] = x.previousWalletBalance;
        item["Remarks"] = x.remarks;
        item["Date & Time"] = moment(x.createdAt).format("lll");

        data.push(item);
      });

      const name = this.user.name || this.user.mobile;
      const fileName = name + "_Wallet_History";
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({ data, fileName, exportType });
    },

    fetchUser() {
      this.loading = true;
      return User.getUser(this._id, this.userId, false)
        .then((res) => {
          res.data.named = res.data.name || "--";
          this.user = res.data;

          this.addressesDefaultItem.name = this.user.name;
          this.addressesDefaultItem.mobile = this.user.callingCode;
          this.addressesDefaultItem.mobile = this.user.mobile;
          //this.addressesDefaultItem.city = this.$store.state.activeStore.city;
          //this.addressesDefaultItem.state = this.$store.state.activeStore.state;
          this.addressesDefaultItem.country = this.country.name || "India";
          this.user.ago = moment(this.user.createdAt).format("lll");

          // this.user.orders.forEach((x, i) => {
          //   x.index = (i+1)+'.';
          //   x.id = x._id.toUpperCase();
          //   x.sd = x.status ? x.status.charAt(0).toUpperCase() + x.status.slice(1) : '--';
          //   x.time = moment(x.createdAt).format('lll');
          // });

          if (this.user.memberships) {
            const item = this.user.memberships.find(
              (x) => x.store === this._id
            );
            if (item) {
              this.dontCall = true;
              this.membership = item.membership;
            }
          } else {
            this.user.memberships = [];
          }

          this.ou = JSON.parse(JSON.stringify(this.user));
          this.loading = false;

          //show addAddress popup automatically
          if (this.$route.query.addAddress)
            setTimeout(
              () => this.editAddressItem(this.addressesDefaultItem),
              500
            );
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          router.push("/business/" + this._id);
        });
    },

    fetchProfile() {
      return User.getProfile(this._id, this.userId, false)
        .then((x) => {
          if (x.data) this.profile = x.data;
        })
        .catch((e) => console.log(e));
    },

    fetchReferrals() {
      this.rloading = true;
      return User.getReferrals(this._id, this.userId, false)
        .then((res) => {
          res.data.forEach((x, i) => (x.index = i + 1 + "."));
          this.referrals = res.data;
          this.rloading = false;
        })
        .catch(() => (this.rloading = false));
    },

    fetchOrders() {
      this.oloading = true;
      return User.getOrders(this._id, this.userId, false)
        .then((res) => {
          this.orders = res.data;
          this.orders.forEach((x, i) => {
            x.index = i + 1 + ".";
            x.id = x._id.toUpperCase();
            x.sd = x.status
              ? x.status.charAt(0).toUpperCase() + x.status.slice(1)
              : "--";
            x.time = moment(x.createdAt).format("lll");
          });
          this.oloading = false;
        })
        .catch(() => (this.oloading = false));
    },

    fetchAddresses() {
      this.addressLoading = true;
      return User.getAddresses(this._id, this.userId, false)
        .then((res) => {
          res.data.forEach((x, i) => {
            x.index = i + 1 + ".";
            x.landmarkd = x.landmark || "-";
            x.aread = x.area || "-";
            x.ago = moment(x.createdAt).format("lll");
          });
          this.addresses = res.data;
          this.addressLoading = false;
        })
        .catch(() => (this.addressLoading = false));
    },

    fetchWishlist() {
      this.wloading = true;
      return User.getWishlist(this._id, this.userId, false)
        .then((res) => {
          if (res.data) this.wishlist = res.data;
          this.wloading = false;
        })
        .catch(() => (this.wloading = false));
    },

    fetchRecentlyOrdered() {
      this.roloading = true;
      return User.getRecentlyOrdered(this._id, this.userId, false)
        .then((res) => {
          if (res.data) this.recentlyOrdered = res.data.items;
          this.roloading = false;
        })
        .catch(() => (this.roloading = false));
    },

    fetchUserEnums() {
      return User.getUserEnums(this._id)
        .then((res) => {
          this.enums = res.data;
        })
        .catch((e) => console.log(e));
    },

    openMap(lat, long) {
      const url = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      window.open(url, "_system");
    },

    editAddressItem(item) {
      this.editedAddressIndex = this.addresses.indexOf(item);
      this.editedAddressItem = Object.assign({}, item);
      this.addressDialog = true;
    },

    deleteAddressItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return User.deleteAddress(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchAddresses();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    closeAddress() {
      this.addressDialog = this.processing = this.loading = false;
      this.addressEditedItem = Object.assign({}, this.addressesDefaultItem);
    },

    saveAddress(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = true;
      Store.snackbarStart();
      let request;

      if (this.editedAddressItem._id)
        request = User.editAddress(
          this.editedAddressItem._id,
          this.editedAddressItem,
          false
        );
      else
        request = User.addAddress(
          this._id,
          this.userId,
          this.editedAddressItem,
          false
        );

      return request
        .then(() => {
          if (closeModal) this.closeAddress();
          this.fetchAddresses();
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          this.loading = false;
          Store.snackbarError();
        });
    },

    saveMembership(membership) {
      Store.snackbarStart();
      this.uloading = true;
      return User.updateMembership(
        this._id,
        this.user._id,
        { membership: membership },
        false
      )
        .then(() => {
          this.uloading = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.uloading = false;
          Store.snackbarError();
        });
    },

    addBalance(editedBalanceItem) {
      this.editedBalanceItem = editedBalanceItem;
      this.editedBalanceItem.currencyCode = this.country.currencyCode;
      this.balanceDialog = true;
    },

    saveBalance() {
      if (!this.$refs.formBalance.validate()) return;

      Store.snackbarStart();
      this.processing = true;
      const balance = Object.assign({}, this.editedBalanceItem);
      balance.isManual = true;
      return User.addWalletBalance(this._id, this.userId, balance, false)
        .then(() => {
          this.fetchWallet();
          this.processing = false;
          this.closeBalance();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    closeBalance() {
      this.editedBalanceItem = {};
      this.balanceDialog = false;
    },

    amountFormatted(item) {
      let formattedAmount = item.amount;
      if (item.currencyCode === "INR") {
        formattedAmount = this.currencyPipe(item.amount, "₹");
      } else if (item.currencyCode && item.currencyCode !== "INR") {
        formattedAmount = this.currencyPipe(
          item.amount,
          this.currencySymbol(item.currencyCode)
        );
      } else if (
        !this.$store.state.country.currencyCode &&
        !item.currencyCode
      ) {
        formattedAmount = this.currencyPipe(item.amount, "₹");
      } else {
        formattedAmount = this.currencyPipe(
          item.amount,
          this.$store.state.country.currencySymbol
        );
      }

      return formattedAmount;
    },

    currencySymbol(currencyCode) {
      return getSymbolFromCurrency(currencyCode);
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    fetchContracts() {
      this.conLoading = true;
      return Store.getUserContracts(this._id, this.userId, false)
        .then((x) => {
          if (x.data) {
            x.data.forEach((x, index) => {
              x.index = index + 1 + ".";
              if (x.items && x.items.length > 0) x.totalItems = x.items.length;
            });
          }
          this.contracts = x.data;
          this.conLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.conLoading = false;
        });
    },

    editContractItem(item) {
      if (!item.user) item.user = this.userId;
      if (item.expiresAt)
        item.expiresAt = moment(item.expiresAt).format("YYYY-MM-DD");
      this.editedContractIndex = this.contracts.indexOf(item);
      this.editedContractItem = Object.assign({}, item);

      if (this.editedContractItem.items) {
        const items = this.editedContractItem.items.map((x, index) => {
          x.index = index;
          x.name = x.name || x._id.name;
          x._id = x._id || x._id._id;
          return x;
        });
        this.editedContractItem.items = items;
      }

      this.$store.state.contractFormModal = true;
    },

    deleteContractItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
      this.beingDeleted.isContract = true;
    },

    finalDeleteContract() {
      this.processing = true;
      return Store.deleteContract(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchContracts();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    formatDate(date, format = "ll") {
      return moment(date).format(format);
    },

    getCallingCode(item) {
      return item.callingCode || this.$store.state.country.callingCode || "91";
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
        })
        .catch((e) => console.log(e));
    },

    countryText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    massDeleteInit() {
      this.confirmMassAddressDelete = true;
    },

    massAddressDeleteProcess() {
      const massUpdates = { deleted: true };
      const massIds = this.selectedAddresses.map((x) => x._id);

      this.massprocessing = true;
      return User.massAddressUpdate(this._id, {
        massIds: massIds,
        massUpdates: massUpdates,
      })
        .then(() => {
          this.selectedAddresses = [];
          this.massprocessing = this.confirmMassAddressDelete = false;
          this.fetchAddresses();
        })
        .catch((e) => {
          console.log(e);
          this.massprocessing = false;
        });
    },

    editProfile() {
      this.processing = true;
      return User.editProfile(this._id, this.userId, this.profile, false)
        .then(() => (this.processing = false))
        .catch(() => (this.processing = false));
    },

    fetchDevices() {
      this.dfetching = true;
      return User.getDevices(this._id, this.userId, false)
        .then((response) => {
          this.devices = (response?.data || []).map((item, index) => {
            item.index = index + 1 + ".";
            item.createdAtTime = moment(item.createdAt).format("lll");
            return item;
          });
          this.dfetching = false;
        })
        .catch(() => {
          this.dfetching = false;
        });
    },

    massDeleteDeviceInit() {
      this.confirmMassDeviceDelete = true;
    },

    massDeviceDeleteProcess() {
      const massIds = this.selectedDevices.map((x) => x._id);
      this.massprocessing = true;
      return User.bulkDeviceDelete(this._id, { ids: massIds })
        .then(() => {
          this.selectedDevices = [];
          this.massprocessing = this.confirmMassDeviceDelete = false;
          this.fetchDevices();
        })
        .catch((e) => {
          console.log(e);
          this.massprocessing = false;
        });
    },
  },

  data() {
    return {
      _id: "",
      userId: "",
      dialog: false,

      roloading: false,
      wloading: false,
      oloading: false,
      rloading: false,
      loading: false,
      processing: false,
      dfetching: false,
      user: {
        addresses: [],
        orders: [],
      },
      oc: {},
      enums: {},
      profile: false,

      addressLoading: false,
      addressValid: false,
      addressDialog: false,
      editedAddressItem: {},
      editedAddressIndex: -1,
      addressesDefaultItem: {},

      confirmDialog: false,
      beingDeleted: {},

      devices: [],
      memberships: [],
      membership: "",
      dontCall: false,
      uloading: false,

      wallet: {
        balance: 0,
      },
      editedBalanceItem: {},
      balanceDialog: false,
      validBalance: false,

      whLoading: false,
      walletHistory: [],

      conLoading: false,
      contracts: [],
      contractModified: false,
      editedContractItem: {
        isActive: true,
        items: [],
        files: [],
      },
      contractDefaultItem: {
        isActive: true,
        items: [],
        files: [],
      },

      selectedDevices: [],
      selectedAddresses: [],
      confirmMassAddressDelete: false,
      confirmMassDeviceDelete: false,
      massprocessing: false,

      referrals: [],
      addresses: [],
      orders: [],

      wishlist: false,
      recentlyOrdered: false,

      countries: [],
      aheaders: [
        { text: "#", align: "start", value: "index", sortable: false },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "Address", value: "street" },
        { text: "City", value: "city" },
        { text: "Navigate", value: "navigate", sortable: false },
        { text: "Date & Time", value: "ago" },
        { text: "Action", value: "actions", align: "right", sortable: false },
      ],

      oheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "OID", value: "id" },
        { text: "Amount", value: "amount" },
        { text: "Status", value: "sd" },
        { text: "Items", value: "items" },
        { text: "Date & Time", value: "time" },
      ],

      sheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Ownership", value: "ownership" },
        { text: "Is Vendor?", value: "isVendor" },
        { text: "Apps", value: "apps" },
        { text: "Date & Time", value: "time" },
      ],

      wheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Amount (Credit/Debit)", value: "amount" },
        { text: "TXN", value: "txn" },
        { text: "Previous Wallet Balance", value: "previousWalletBalance" },
        { text: "Payment Method", value: "paymentMethod" },
        { text: "Remarks", value: "remarks" },
        { text: "Date & Time", value: "readableCreatedAt" },
      ],

      conheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        { text: "Accepted By", value: "acceptedBy" },
        { text: "Signed By", value: "signedBy" },
        { text: "Items", value: "totalItems", sortable: false },
        { text: "Status", value: "isActive" },
        { text: "Expires At", value: "expiresAt" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      rheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Code", value: "referralCode" },
        { text: "Rule", value: "rule" },
        { text: "Name", value: "referredTo.name" },
        { text: "Mobile", value: "referredTo.mobile" },
        {
          text: "Referred To Discount",
          value: "referredToDiscount",
          sortable: false,
        },
        {
          text: "Referred By Discount",
          value: "referredByDiscount",
          sortable: false,
        },
        { text: "Processed?", value: "hasCredited" },
        {
          text: "Date & Time",
          value: "createdAt",
          align: "right",
          sortable: false,
        },
      ],

      pheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Category", value: "category.name" },
        { text: "Size", value: "size" },
        { text: "Price", value: "price" },
        { text: "M.R.P.", value: "marketPrice" },
        { text: "In Stock?", value: "inStock" },
        { text: "Status", value: "isActive" },
      ],

      dheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Model", align: "start", value: "model" },
        { text: "Platform", align: "start", value: "platform" },
        { text: "UDID", align: "start", value: "udid" },
        { text: "OS Version", align: "start", value: "version" },
        { text: "Created At", align: "start", value: "createdAtTime" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        amountRule: [
          (v) => (!!v && (v > 0 || v < 0)) || "Enter a valid amount",
        ],
      },
    };
  },
};

import Vue from "vue";
import Inventory from "../../../services/inventory";
import FileUpload from "../../../services/file-upload";
import Store from "../../../services/store";
import router from "../../../router";

import CategoryForm from "../../../components/category-form/category-form.vue";
import MassTranslate from "../../../components/mass-translate/mass-translate.vue";

import config from "../../../config";

export default {
  name: "Categories",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Categories"))
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchCategories();
    for (let p = 0; p < 4; p++) this.defaultItem.photos.push(this.photoItem);

    //filter columns
    if (this.isVendor)
      this.headers = this.headers.filter((x) => x.value != "actions");
  },

  components: {
    CategoryForm,
    MassTranslate,
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      if (["create", "edit", "delete"].includes(access) && this.isVendor)
        return false;
      return Store.doIHaveAccess(this.$store, access);
    },

    resetPhotos() {
      this.defaultItem.photos.forEach((x) => (x._id = undefined));
    },

    fetchCategories() {
      this.loading = true;

      let catRequest = Inventory.getCategories(this._id, false);
      if (this.isVendor)
        catRequest = Inventory.getVendorCategories(this._id, false);

      return catRequest
        .then((res) => {
          this.categories = res.data.map((x, index) => {
            x.parentName = x.parent && x.parent.name ? x.parent.name : "-";
            x.index = index + 1 + ".";

            x.photos = x.photos.map((x) => {
              return {
                _id: x || undefined,
                uploading: false,
                uploadProgress: 0,
                sheet: false,
              };
            });

            const totalEmpty = 4 - x.photos.length;
            for (let p = 0; p < totalEmpty; p++) x.photos.push(this.photoItem);
            return x;
          });
          this.loading = false;
        })
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.categories.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.beingDeleted = item;
      this.confirmDialog = true;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Inventory.deleteCategory(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchCategories();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign(
        {},
        JSON.parse(JSON.stringify(this.defaultItem))
      );
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true, true);
    },

    save(closeModal = true, fetchCategories = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedItem);
      object.photos = object.photos.map((x) => x._id);
      object.photos = object.photos.filter((x) => x);

      if (!object.parent || object.parent === "") delete object.parent;

      if (this.editedItem._id)
        request = Inventory.editCategory(this.editedItem._id, object, false);
      else request = Inventory.addCategory(this._id, object, false);

      return request
        .then(() => {
          this.processing = this.loading = this.editedItem.processing = false;
          if (closeModal) this.close();
          if (fetchCategories) this.fetchCategories();
          this.closeConfirmPhoto();
          this.resetPhotos();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = this.editedItem.processing = false;
          this.closeConfirmPhoto();
          this.resetPhotos();
          Store.snackbarError();
        });
    },

    handleClick(index, photo) {
      if (!photo.id) this.pickImage(index);
      else photo.sheet = !photo.sheet;
    },

    getRef(index) {
      return this.$refs["inputUpload" + index][0];
    },

    pushPhotoObject(index, _id, uploading, uploadProgress, sheet) {
      Vue.set(this.editedItem.photos, index, {
        uploading: uploading,
        _id: _id,
        uploadProgress: uploadProgress,
        sheet: sheet,
      });
    },

    pickImage(index) {
      this.getRef(index).click();
    },

    fileSelected(index) {
      const file = this.getRef(index).files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      //const localURL = URL.createObjectURL(file);
      this.pushPhotoObject(index, undefined, true, 0, false);
      this.processing = true;
      return FileUpload.upload(this._id, "categories", file)
        .then((x) => {
          const _id = x.data.file;
          this.pushPhotoObject(index, _id, false, 0, false);
          if (this.editedItem._id) this.save(false, false); //only while editing
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    invalidImageError() {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 2000);
    },

    isFileImage(file) {
      return file && file["type"].split("/")[0] === "image";
    },

    closeConfirmPhoto() {
      this.confirmDialogPhoto = false;
      this.beingDeletedPhoto = {};
    },

    deletePhoto(index, photo) {
      this.confirmDialogPhoto = true;
      this.beingDeletedPhoto = photo;
    },

    finalDeletePhoto() {
      this.editedItem.photos = this.editedItem.photos.filter(
        (x) => !x._id || x._id !== this.beingDeletedPhoto._id
      );
      this.editedItem.photos.push(this.photoItem);
      this.save(false, true, true);
    },

    vendorCategoryAction(item) {
      this.processing = true;
      Store.snackbarStart();

      let request;
      if (!item.vendors || !item.vendors.includes(this._id))
        request = Inventory.subscribeCategory(this._id, item._id, false);
      else request = Inventory.unsubscribeCategory(this._id, item._id, false);

      return request
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();
          this.fetchCategories();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    copyID(_id) {
      navigator.clipboard.writeText(_id);
      this.copyCIDText = "Copied";
      setTimeout(() => (this.copyCIDText = "Copy Category ID"), 1500);
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add new category" : "Edit category";
    },

    filteredCategories() {
      return this.categories.filter((x) => x._id != this.editedItem._id);
    },

    pi() {
      return this.photoItem;
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  watch: {
    translationModified(n) {
      if (n) {
        this.selectedItems = [];
        this.fetchCategories();
      }
      this.translationModified = false;
    },
  },

  data: () => {
    return {
      _id: "",
      activeStore: {},

      search: "",
      loading: false,
      processing: false,
      dialog: false,

      rowItems: config.rowItems,

      editedIndex: -1,

      editedItem: {
        name: "",
        isActive: true,
        parent: "",
        photos: ["", "", "", ""],
        isVendorsOnly: false,
      },
      defaultItem: {
        name: "",
        isActive: true,
        parent: "",
        photos: [],
        isVendorsOnly: false,
      },

      confirmDialog: false,
      beingDeleted: {},

      confirmDialogPhoto: false,
      beingDeletedPhoto: {},

      photoItem: {
        uploading: false,
        _id: undefined,
        progress: 0,
        sheet: false,
      },

      translationModified: false,
      selectedItems: [],

      copyCIDText: "Copy Category ID",

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Parent category", value: "parentName" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      categories: [],

      validationRules: {
        basicRule: [(v) => !!v || "Name is required"],
        statusRule: [(v) => v || v === false || "Status is required"],
      },
    };
  },
};

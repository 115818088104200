import TicketInfo from '../../../components/ticket-info/ticket-info.vue';
import router from '../../../router';

export default {
  name: 'SupportTicket',

  created() {
    this._id = this.$route.params._id;
    if (this.$store.state.activeStore.isVendor) return router.push('/business/'+this._id);
    this.ticketId = this.$route.params.ticketId;
  },

  components: {
    TicketInfo
  },

  data() {
    return {
      _id: '',
      ticketId: ''
    }
  }
}
<template>
  <router-link :to="{ name: name, params: params }">
    <span style="color: var(--primary)">{{ text }}</span>
  </router-link>
</template>
<script>
export default {
  name: "Link",

  props: ["name", "params", "text"],
};
</script>

import Store from "../../../services/store";
import Inventory from "../../../services/inventory";
import router from "../../../router";
import config from "../../../config";

import _ from "underscore";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

import Barcodes from "../../../components/barcodes/barcodes.vue";

export default {
  name: "ProductStock",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Products"))
      return router.push("/business/" + this._id);
    this.fetchStockEntries();
    this.fetchSuppliers();
  },

  components: {
    Barcodes,
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    querySelections(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;
      return Store.searchProducts(this._id, keyword, false)
        .then((x) => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.products = x.data;
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    },

    productSelected() {
      this.lastSearched = this.selectedProduct.name;
      if (this.editedItem.items.find((x) => x._id === this.selectedProduct._id))
        return;

      const newItem = {
        _id: this.selectedProduct._id,
        name: this.selectedProduct.name,
        size: this.selectedProduct.size,
        unit: this.selectedProduct.unit,
        oldQuantity: this.selectedProduct.quantity,
        marketPrice: this.selectedProduct.marketPrice,
        price: this.selectedProduct.price,
        index: this.editedItem.items.length,
      };
      if (this.selectedProduct.purchasePrice)
        newItem.purchasePrice = this.selectedProduct.purchasePrice;

      this.editedItem.items.push(newItem);
      this.searchInput = "";
      this.selectedProduct = {};
    },

    removeItem(item) {
      this.editedItem.items = this.editedItem.items.filter(
        (x) => x._id != item._id
      );
    },

    productText(item) {
      let itemText = `${item.name} (${item.size}${item.unit}) | ID: ${item._id}`;
      if (item.barcodeID)
        itemText = itemText + " | Barcode ID: " + item.barcodeID;
      return itemText;
    },

    fetchStockEntries(
      pageNumber = this.entriesObject.pageNumber,
      itemsPerPage = this.entriesObject.itemsPerPage,
      keyword = ""
    ) {
      this.loading = true;
      return Inventory.getStockEntries(
        this._id,
        pageNumber,
        itemsPerPage,
        keyword,
        false
      )
        .then((res) => {
          this.entriesObject = res.data;
          this.entries = this.entriesObject.items.map((x, index) => {
            x.index = index + 1 + ".";
            x.createdByName = x.createdBy?.name || "-";
            x.modifiedByName = x.modifiedBy?.name || "-";
            x.supplierName = x.supplier?.name || "-";

            if (x.entryDate)
              x.entryDate = moment(x.entryDate).format("YYYY-MM-DD");
            return x;
          });

          this.allEntries = JSON.parse(JSON.stringify(this.entries));
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchSuppliers() {
      this.sloading = true;
      return Store.getSuppliers(this._id, false)
        .then((res) => {
          this.suppliers = res.data;
          this.sloading = false;
        })
        .catch(() => {
          this.sloading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.entries.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (this.editedItem.items) {
        const items = this.editedItem.items.map((x, index) => {
          x.index = index;
          x.name = x.name || x._id.name;
          x.size = x.size || x._id.size;
          x.unit = x.unit || x._id.unit;
          x._id = x._id || x._id._id;
          return x;
        });
        this.editedItem.items = items;
      }

      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      item.title = "stock entry";
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Inventory.deleteStockEntry(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchStockEntries();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    formatDate(date = "", format = "ll") {
      return date ? moment(date).format(format) : moment().format(format);
    },

    saveStockEntry() {
      if (this.$refs.form && !this.$refs.form.validate()) return;
      if (!this.editedItem.items || !this.editedItem.items.length) {
        Store.snackbarError();
        this.$store.state.snackbar.text = "One or more items required";
        return;
      }

      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.editedItem._id)
        request = Inventory.editStockEntry(
          this.editedItem._id,
          this.editedItem,
          false
        );
      else request = Inventory.addStockEntry(this._id, this.editedItem, false);

      return request
        .then(() => {
          this.processing = this.dialog = false;
          this.fetchStockEntries();
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    getItemsText(items) {
      return items
        .map((x) => x._id.name + "(" + x.newQuantity + ")")
        .join(", ");
    },

    printBarcodes(item) {
      const entryItems = [];
      item.items.forEach((x) => {
        entryItems.push(x._id);
      });
      this.entryItems = entryItems;
      this.$store.state.barcodesModal = true;
    },

    exportEntries() {
      let endpoint = `${
        process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
      }product/stock/export/${this._id}`;
      endpoint =
        endpoint +
        "?x-request-user-agent=application/admin-app&token=" +
        this.$store.state.token;
      const _ids = this.selectedEntries.map((x) => x._id);
      endpoint = endpoint + "&_ids=" + _ids;
      return window.open(endpoint, "_blank");
    },

    downloadEntry(item) {
      if (!item || !item.items) return;
      const columns = [
        "#",
        "Name",
        "Size",
        "Old Qty",
        "New Qty",
        "M.R.P.",
        "Price",
        "Purchase Price",
      ];
      const rows = [];

      const doc = new jsPDF();
      doc.autoTable({ html: "" });

      item.items.forEach((x, index) => {
        const item = [];
        if (columns.includes("#")) item.push(index + 1 + ".");
        if (columns.includes("Name")) item.push(x._id.name);
        if (columns.includes("Size")) item.push(x._id.size + x._id.unit);
        if (columns.includes("Old Qty")) item.push(x.oldQuantity);
        if (columns.includes("Old Qty")) item.push(x.oldQuantity);
        if (columns.includes("New Qty")) item.push(x.newQuantity);
        if (columns.includes("M.R.P.")) item.push(x.marketPrice);
        if (columns.includes("Selling Price")) item.push(x.price);
        if (columns.includes("Purchase Price")) item.push(x.purchasePrice);
        rows.push(item);
      });

      // Or use javascript directly:
      doc.autoTable({
        margin: { top: 0, left: 5, right: 5, bottom: 0 },
        head: [columns],
        body: rows,
        theme: "grid",
        headStyles: {
          fillColor: [0, 123, 255],
        },
        styles: { font: "times" },
      });

      doc.save("Stock Entry - " + (item.seid || item._id) + ".pdf");
    },

    supplierText(item) {
      let name = item.name;
      if (item.legalName) name += " (" + item.legalName + ")";
      return name;
    },
  },

  watch: {
    searchInput: _.debounce(function(newVal, oldVal) {
      const array = newVal ? newVal.split("| ID") : [];
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        array.length === 1
      )
        this.querySelections(newVal);
    }, 700),

    supplier(n) {
      if (!n) this.entries = this.allEntries;
      else
        this.entries = this.allEntries.filter(
          (x) => x.supplier && x.supplier._id == n
        );
    },

    options: {
      handler(options) {
        if (!this.loading)
          this.fetchStockEntries(
            options.page,
            options.itemsPerPage,
            this.search
          );
      },
      deep: true,
    },
  },

  computed: {
    formTitle() {
      return !this.editedItem._id
        ? "Create new stock entry"
        : "Edit stock entry";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    csPrefix() {
      return this.$store.state.country.currencySymbol || "₹";
    },
  },

  data() {
    return {
      _id: "",
      valid: false,
      loading: false,
      processing: false,
      confirmDialog: false,
      dialog: false,

      search: "",
      entries: [],
      rowItems: config.rowItems,

      sloading: false,
      suppliers: [],

      supplier: "",

      options: { sortBy: [] },
      entriesObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      editedItem: { items: [] },
      defaultItem: { items: [] },
      beingDeleted: {},
      calModal: false,

      searchInput: "",
      lastSearched: "",
      selectedProduct: {},
      products: [],
      isSearching: false,
      outstandingRequests: 0,

      ploading: false,
      pheaders: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Size", value: "size" },
        { text: "Current Qty", value: "oldQuantity" },
        { text: "New Qty", value: "newQuantity", sortable: false },
        { text: "Market Price", value: "marketPrice", sortable: false },
        { text: "Selling Price", value: "price", sortable: false },
        { text: "Purchase Price", value: "purchasePrice", sortable: false },
        { text: "Remove", value: "actions", sortable: false, align: "right" },
      ],

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "SEID", value: "seid" },
        { text: "Items", value: "items" },
        { text: "Supplier", value: "supplierName" },
        // { text: 'Created By', value: 'createdByName' },
        // { text: 'Modified By', value: 'modifiedByName' },
        { text: "Entry Date", value: "entryDate" },
        { text: "Updated At", value: "updatedAt" },
        { text: "Actions", align: "right", value: "actions", sortable: false },
      ],

      entryItems: [],
      selectedEntries: [],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        numberRule: [(v) => !isNaN(v) || "Must be a valid number"],
        priceRule: [(v) => (!isNaN(v) && v >= 0) || "Enter a valid price"],
        itemsRule: [(v) => !!v.length || "One or more items required"],
      },
    };
  },
};

import Super from "../../services/super";
import Store from "../../services/store";
import FileUpload from "../../services/file-upload";
import moment from "moment";
import { getS3URL } from "../../helpers/misc";

export default {
  name: "TicketInfo",

  props: ["isGrowcify"],

  created() {
    this._id = this.$route.params._id;
    this.ticketId = this.$route.params.ticketId;

    this.fetchTicket();
    this.fetchComments();
    if (this.isGrowcify) this.fetchSA();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchTicket() {
      this.loading = true;
      return Store.getTicket(this.ticketId, false)
        .then((x) => {
          this.oot = x.data;
          this.ticket = x.data;
          this.loading = false;
          setTimeout(() => (this.fl = true), 250);
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchComments() {
      this.cprocessing = true;

      return Store.getTicketComments(this.ticketId, false)
        .then((x) => {
          this.comments = x.data;
          this.cprocessing = false;
        })
        .catch((e) => {
          console.log(e);
          this.cprocessing = false;
        });
    },

    formatDate(date, format = "lll") {
      return moment(date).format(format);
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    isValidDocument(file) {
      if (!file) return false;
      const type = file["type"].split("/")[0];
      const type2 = file["type"].split("/")[1];
      if (
        file &&
        (this.supportedFormats.includes(type) ||
          this.supportedFormats.includes(type2))
      ) {
        this.fileObject = {
          name: file["name"],
          size: file["size"],
          type: this.supportedFormats.includes(type) ? type : type2,
        };
        return true;
      }
      return false;
    },

    getURL(data) {
      if (!data) return data;
      const ext = data.split(".")[1];
      if (data && (this.supportedFormats.includes(ext) || ext == "png")) {
        return getS3URL(this.ticket.store, data);
      }
      return data;
    },

    invalidDocError() {
      this.$store.state.snackbar.text = "Unsupported File Format";
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isValidDocument(file)) return this.invalidDocError();

      this.fileObject._id = "";
      this.fileObject._id = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(
        this.ticket.store,
        "support",
        file,
        this.fileObject.type
      )
        .then((x) => {
          if (!this.editedItem.files) this.editedItem.files = [];
          this.fileObject._id = x.data.file;
          this.editedItem.files.push(this.fileObject);
          this.uploading = false;
          if (this.editedItem._id) this.save(false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    openDocument(item) {
      window.open(this.getURL(item._id), "_blank");
    },

    removeDocument(item) {
      const files = this.editedItem.files.filter((x) => x._id != item._id);
      this.editedItem.files = files;
      this.save(false);
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.commentModal = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteTicketComment(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchComments();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.commentModal = false;
    },

    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.isGrowcify) this.editedItem.isGrowcifyResponse = true;
      if (this.editedItem._id)
        request = Store.editTicketComment(
          this.editedItem._id,
          this.editedItem,
          false
        );
      else
        request = Store.createTicketComment(
          this.ticketId,
          this.editedItem,
          false
        );

      return request
        .then(() => {
          this.processing = false;
          if (closeModal) this.close();
          Store.snackbarSuccess();
          this.fetchComments();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    saveTicket() {
      Store.snackbarStart();
      this.processing = true;
      return Store.editTicket(this.ticketId, this.ticket, false)
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    fetchSA() {
      return Super.getSuperadmins()
        .then((x) => (this.users = x.data))
        .catch((e) => console.log(e));
    },

    text(item) {
      return item.name || item.mobile;
    },
  },

  watch: {
    "ticket.status"() {
      if (this.fl) this.saveTicket();
    },

    "ticket.assignedTo"() {
      if (this.fl) this.saveTicket();
    },
  },

  data() {
    return {
      _id: "",
      ticketId: "",
      oot: {},

      users: [],
      ticket: {},
      comments: [],
      loading: false,
      processing: false,
      cprocessing: false,
      fl: false,

      editedItem: {},
      defaultItem: {},

      supportedFormats: ["image", "pdf", "xls", "xsls", "doc", "docx"],
      fileObject: {},
      uploading: false,
      ploading: false,

      commentModal: false,

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        numberRule: [(v) => (!isNaN(v) && v >= 0) || "Enter a valid number"],
      },
    };
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"nobp"},[_c('h5',{class:_vm.isDT ? '' : 'text-primary',staticStyle:{"margin-bottom":"0"}},[_vm._v("Support Cases")]),_c('small',[_vm._v("Visible to Growcify Team")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","outlined":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.fetchTickets}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"start":""}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Refresh")])])],1)],1)],1),_c('div',{staticStyle:{"height":"15px"}}),_c('b-row',[_c('b-col',[_c('v-text-field',{attrs:{"clearable":"","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-col',{staticClass:"col-12"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tickets,"search":_vm.search,"loading":_vm.loading || _vm.processing,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'support-info', params: { ticketId: item._id }}}},[_c('span',{staticStyle:{"color":"var(--primary)"}},[_c('TextWrapper',{attrs:{"text":item.subject,"length":60}})],1)])]}},{key:"item.tid",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'support-info', params: { ticketId: item._id }}}},[_c('span',{staticStyle:{"color":"var(--primary)"}},[_vm._v(" "+_vm._s(item.tid)+" ")])])]}},{key:"item.status",fn:function(props){return [_c('v-edit-dialog',{attrs:{"large":"","return-value":props.item.status},on:{"save":function($event){return _vm.changeStatus(props.item, props.item.status)},"update:returnValue":function($event){return _vm.$set(props.item, "status", $event)},"update:return-value":function($event){return _vm.$set(props.item, "status", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4"},[_vm._v("Edit status")]),_c('v-select',{staticStyle:{"padding-top":"5px"},attrs:{"autofocus":"","hide-details":"","items":_vm.enums.status,"item-value":"key","item-text":"value"},model:{value:(props.item.status),callback:function ($$v) {_vm.$set(props.item, "status", $$v)},expression:"props.item.status"}})]},proxy:true}],null,true)},[_c('div',[(props.item.status == 'Open')?_c('v-chip',{attrs:{"small":"","link":"","color":"primary"}},[_vm._v("Open")]):(props.item.status == 'Closed')?_c('v-chip',{attrs:{"small":"","link":"","color":"error"}},[_vm._v("Closed")]):_c('v-chip',{attrs:{"small":"","link":"","color":"warning"}},[_vm._v("Reopened")])],1)])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:[item.class, 'mr-2'],attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{class:item.class,attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1),_c('ConfirmDialog',{attrs:{"confirm-dialog":_vm.confirmDialog,"being-deleted":_vm.beingDeleted,"processing":_vm.processing}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
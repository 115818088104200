import Store from "../../../services/store";
import router from "../../../router";

import FileUpload from "../../../services/file-upload";
import config from "../../../config";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "Employees",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Employees"))
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchEmployees();
    this.fetchEmployeeEnums();
    //this.fetchOutlets();
    this.fetchCountries();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchEmployees() {
      this.loading = true;
      return Store.getEmployees(this._id, false)
        .then((res) => {
          this.employees = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            return x;
          });
          this.loading = false;

          //show addAddress popup automatically
          if (this.$route.query.addDeliveryman) {
            setTimeout(() => {
              this.defaultItem.role = "deliveryman";
              this.defaultItem.isRestricted = true;
              this.editItem(this.defaultItem);
            }, 500);
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchOutlets() {
      return Store.getOutlets(this._id, false)
        .then((res) => (this.outlets = res.data.filter((x) => x.isActive)))
        .catch((e) => console.log(e));
    },

    fetchEmployeeEnums() {
      return Store.getEmployeeEnums(this._id)
        .then((x) => {
          const roles = [];
          x.data.role.forEach((x) => {
            if (x !== "superadmin" && x !== "customer")
              roles.push({
                key: x,
                value: x.charAt(0).toUpperCase() + x.slice(1),
              });
          });
          x.data.role = roles;
          this.enums = x.data;
        })
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (!this.editedItem.callingCode) {
        this.editedItem.callingCode = this.$store.state.activeStore.country.callingCode;
      }
      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteEmployee(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchEmployees();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    save(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedItem);
      if (object.role === "tester") object.modules = [];

      if (this.editedItem._id)
        request = Store.editEmployee(this.editedItem._id, object, false);
      else request = Store.addEmployee(this._id, object, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          this.fetchEmployees();
          this.processing = this.confirmDialogPhoto = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          Store.snackbarError();
          if (e.response.status === 400 && e.response.data) {
            if (e.response.data.error == "already-exists-owner")
              this.$store.state.snackbar.text =
                "Number or email is associated with the owner";
            if (e.response.data.error == "already-exists-employee")
              this.$store.state.snackbar.text = "This employee already exists";
            if (e.response.data.error == "already-exists-vendor")
              this.$store.state.snackbar.text =
                "This number or email already exists in vendors";
          }
          this.processing = this.loading = this.editedItem.processing = false;
        });
    },

    text(item) {
      return item.toUpperCase();
    },

    allAction() {
      if (this.editedItem.modules && this.editedItem.modules.length)
        return (this.editedItem.modules = []);
      this.editedItem.modules = this.enums.modules;
    },

    setDefaultDeliveryman(item) {
      Store.snackbarStart();
      this.processing = item.processing = true;
      return Store.editDefaultDeliveryman(
        this._id,
        {
          employeeId: item._id,
          isDefaultDeliveryman: item.isDefaultDeliveryman,
        },
        false
      )
        .then(() => {
          this.fetchEmployees();
          this.processing = item.processing = false;
          Store.snackbarSuccess();
        })
        .catch(() => {
          Store.snackbarError();
          this.processing = item.processing = false;
        });
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
        })
        .catch((e) => console.log(e));
    },

    countryText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    getRefDoc() {
      return this.$refs.inputDocUpload;
    },

    pickFileDoc() {
      this.getRefDoc().click();
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    isValidDocument(file) {
      if (!file) return false;
      const type = file["type"].split("/")[0];
      const type2 = file["type"].split("/")[1];
      if (
        file &&
        (this.supportedFormats.includes(type) ||
          this.supportedFormats.includes(type2))
      ) {
        this.fileObject = {
          name: file["name"],
          size: file["size"],
          type: this.supportedFormats.includes(type) ? type : type2,
        };
        return true;
      }
      return false;
    },

    getURLDoc(data) {
      if (!data) return data;
      const ext = data.split(".")[1];
      if (data && (this.supportedFormats.includes(ext) || ext == "png")) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidDocError() {
      this.$store.state.snackbar.text = "Unsupported File Format";
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelectedDoc() {
      const file = this.getRefDoc().files[0];
      if (!this.isValidDocument(file)) return this.invalidDocError();

      this.fileObject._id = "";
      this.fileObject._id = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(
        this._id,
        "employees",
        file,
        this.fileObject.type
      )
        .then((x) => {
          if (!this.editedItem.files) this.editedItem.files = [];
          this.fileObject._id = x.data.file;
          this.editedItem.files.push(this.fileObject);
          this.uploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    openDocument(item) {
      window.open(this.getURLDoc(item._id), "_blank");
    },

    removeDocument(item) {
      const files = this.editedItem.files.filter((x) => x._id != item._id);
      this.editedItem.files = files;
      this.save(false, false);
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      if (file && file["type"].split("/")[0] === "image") {
        this.editedItem.type = file["type"].split("/")[0];
        return true;
      }
      return false;
    },

    getURL(data) {
      if (data && data.split(".")[1] === "png") {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError() {
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.editedItem.photo = "";
      this.editedItem.photo = URL.createObjectURL(file);

      this.puploading = true;
      return FileUpload.upload(
        this._id,
        "employees",
        file,
        this.editedItem.type
      )
        .then((x) => {
          this.editedItem.photo = x.data.file;
          this.puploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch((e) => {
          console.log(e);
          this.puploading = false;
        });
    },

    deletePhoto() {
      this.confirmDialogPhoto = true;
    },

    finalDeletePhoto() {
      this.editedItem.photo = "";
      this.save(false, false);
    },

    closeConfirmPhoto() {
      this.confirmDialogPhoto = false;
    },

    getCallingCode(item) {
      return item.callingCode || this.$store.state.country.callingCode || "91";
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add new employee" : "Edit employee";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    store() {
      return this.$store.state.activeStore;
    },

    disableAdd() {
      return (
        (this.store.plan == "growcify-basic" && this.employees.length == 10) ||
        (this.store.plan == "growcify-premium" && this.employees.length == 25)
      );
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  watch: {
    "editedItem.role"(n) {
      if (n == "tester" || n == "deliveryman") {
        this.editedItem.modules = [];
      }
    },
  },

  data: () => {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      _id: "",
      employees: [],
      search: "",
      dialog: false,
      loading: false,
      processing: false,
      uploading: false,
      puploading: false,

      countries: [],
      supportedFormats: ["image", "pdf", "xls", "xsls", "doc", "docx"],

      error: "",

      enums: {},
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        modules: [],
        isActive: true,
      },

      confirmDialogPhoto: false,
      confirmDialog: false,
      beingDeleted: {},

      outlets: [],
      rowItems: config.rowItems,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", align: "start", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "Email", value: "email" },
        { text: "Role", value: "role" },
        { text: "Is Default Deliveryman?", value: "isDefaultDeliveryman" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        lengthRule: [(v) => (!!v && !!v.length) || "It is required"],
        mobileRule: [
          (v) =>
            (!!v && v.length >= 5 && !isNaN(v)) || "Must be a valid number",
        ],
        emailRule: [
          (v) => (!!v && emailregex.test(v)) || "Must be a valid email",
        ],
      },
    };
  },
};

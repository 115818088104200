<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-card v-if="dialog" class="p-4 pl-8 pr-8 pb-2">
      <v-row>
        <v-col>
          <h5>Abandoned Checkout Order: {{ order.oid }}</h5>
        </v-col>
      </v-row>

      <div class="mb-3"></div>

      <v-row>
        <v-col cols="4">
          <v-list-item-subtitle>Name</v-list-item-subtitle>
          <v-list-item-title>
            <span v-if="hcifv">-</span>
            <b v-else>{{ order.user.name || "-" }}</b>
          </v-list-item-title>
        </v-col>

        <v-col cols="4">
          <v-list-item-subtitle>Mobile</v-list-item-subtitle>
          <v-list-item-title>
            <span v-if="hcifv">-</span>
            <b v-else>
              +{{ order.user.callingCode || "91" }} {{ order.user.mobile }}
            </b>
          </v-list-item-title>
        </v-col>

        <v-col cols="4">
          <v-list-item-subtitle>Email</v-list-item-subtitle>
          <v-list-item-title>
            <span v-if="hcifv">-</span>
            <b v-else>{{ order.user.email || "-" }}</b>
          </v-list-item-title>
        </v-col>

        <v-col cols="4">
          <v-list-item-subtitle>Amount</v-list-item-subtitle>
          <v-list-item-title
            ><b>{{ $parent.amountFormatted(order) }}</b></v-list-item-title
          >
        </v-col>

        <v-col cols="4">
          <v-list-item-subtitle>Status</v-list-item-subtitle>
          <v-list-item-title
            ><b>{{ order.status | firstLetterUppercase }}</b></v-list-item-title
          >
        </v-col>

        <v-col cols="4">
          <v-list-item-subtitle>Last Updated</v-list-item-subtitle>
          <v-list-item-title
            ><b>{{
              $parent.formatDate(order.updatedAt, "lll")
            }}</b></v-list-item-title
          >
        </v-col>
      </v-row>

      <v-row class="mt-2">
        <v-col>
          <v-data-table :headers="headers" :items="order.items">
            <template v-slot:item.index="{ item }">
              {{ item.index }}
            </template>

            <template v-slot:item.size="{ item }">
              {{ item.size }}{{ item.unit }}
            </template>

            <template v-slot:item.price="{ item }">
              {{ item.price | fc }}
            </template>

            <template v-slot:item.itemTotal="{ item }">
              {{ item.itemTotal | fc }}
            </template>

            <template v-slot:item.color="{ item }">
              <div
                v-if="item.color"
                :style="
                  'height: 25px; width: 25px; border-radius: 5px; background-color:' +
                    item.color
                "
              ></div>
              <div v-else>-</div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-btn text @click="$parent.aodialog = false">Close</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script type="text/javascript">
export default {
  name: "AbandonedOrder",

  props: ["_id", "order", "dialog"],

  watch: {
    dialog(n) {
      if (n && this.order) {
        this.order.items.forEach((x, i) => {
          x.index = i + 1 + ".";
          x.itemTotal = x.price * x.qty;
        });
      }
    },
  },

  computed: {
    store() {
      return this.$store.state.activeStore;
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    hcifv() {
      return (
        this.isVendor && this.store.vendorOwner.hideCustomerInfoFromVendors
      );
    },
  },

  data() {
    return {
      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Size", value: "size", sortable: false },
        { text: "Qty", value: "qty" },
        { text: "Price", value: "price" },
        { text: "Total", value: "itemTotal" },
      ],
    };
  },
};
</script>

import _ from "underscore";
import moment from "moment";

import config from "../../config";
import { getS3URL } from "../../helpers/misc";
import FileUpload from "../../services/file-upload";
import Store from "../../services/store";

import SupplierForm from "../supplier-form/supplier-form.vue";
import SignatureBox from "../general/signature-box.vue";
import PIParty from "../pi-party/pi-party.vue";
import PIInfoItem from "../pi-info-item/pi-info-item.vue";
import PISignature from "../pi-signature/pi-signature.vue";
import PITaxSlabs from "../pi-tax-slabs/pi-tax-slabs.vue";

import getSymbolFromCurrency from "currency-symbol-map";

const dateFormat = "YYYY-MM-DD";
const todayDate = moment().format(dateFormat);
const after30DaysDate = moment()
  .add(30, "days")
  .format(dateFormat);

export default {
  name: "PurchaseReturns",

  props: ["_id", "loading", "suppliers", "supplierModified", "filters"],

  components: {
    SupplierForm,
    SignatureBox,
    PIParty,
    PIInfoItem,
    PISignature,
    PITaxSlabs,
  },

  created() {
    this.processBuyerInfo();
    this.fetchCommonEnums();
    this.fetchPurchaseReturns();
    this.fetchPurchaseReturnEnums();

    if (!this.doIHaveAccess("actions")) {
      this.headers = this.headers.filter((x) => x.text !== "Actions");
    }
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchPurchaseReturns() {
      this.piLoading = true;
      return Store.getPurchaseReturns(this._id)
        .then((response) => {
          const data = response.data || [];
          data.forEach((x, i) => {
            x.index = i + 1;
          });

          this.returns = data;
          this.piLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.piLoading = false;
        });
    },

    fetchPurchaseReturnEnums() {
      return Store.getPurchaseReturnEnums(this._id)
        .then((response) => {
          const data = response.data;
          if (data) this.pienums = data;
        })
        .catch(() => {});
    },

    fetchCommonEnums() {
      return Store.getCommonEnums()
        .then((response) => {
          const data = response.data;
          if (data) this.cenums = data;
        })
        .catch(() => {});
    },

    processBuyerInfo() {
      this.processBuyerBasicInfo();
      this.processBuyerState();
      this.processBuyerCity();
    },

    processBuyerBasicInfo() {
      this.defaultItem.buyer._id = this.store._id;
      this.defaultItem.buyer.name = this.store.name;
      this.defaultItem.buyer.address = this.store.address;
      this.defaultItem.buyer.contactNumber = this.store.contactNumber;
      this.defaultItem.buyer.email = this.store.email;
      this.defaultItem.buyer.taxCode = this.store.taxCode || this.store.gstn;
      this.defaultItem.buyer.pan = this.store.pan;
      this.defaultItem.buyer.cin = this.store.cin;
      this.defaultItem.buyer.fssai = this.store.fssaiRegistrationNumber;
      this.defaultItem.buyer.drugLicenceNumber = this.store.drugLicenceNumber;
      this.defaultItem.buyer.logo = this.logo;
      this.defaultItem.buyer.country = this.store.country._id;
      this.defaultItem.buyer.countryName = this.store.country.name;
    },

    processBuyerState() {
      const stateId = this.store.state;
      if (!stateId) return;
      return Store.getState(stateId)
        .then((response) => {
          const data = response.data;
          if (data) {
            this.defaultItem.buyer.state = data._id;
            this.defaultItem.buyer.stateName = data.name;
            this.defaultItem.buyer.stateGSTCode = data.gstCode;
          }
        })
        .catch(() => {});
    },

    processBuyerCity() {
      const cityId = this.store.city;
      if (!cityId) return;
      return Store.getCity(cityId)
        .then((response) => {
          const data = response.data;
          if (data) {
            this.defaultItem.buyer.city = data._id;
            this.defaultItem.buyer.cityName = data.name;
          }
        })
        .catch(() => {});
    },

    processSupplierCountry(countryId) {
      return Store.getCountry(countryId)
        .then((response) => {
          const data = response.data;
          if (data) {
            this.editedItem.supplier.country = data._id;
            this.editedItem.supplier.countryName = data.name;
          }
        })
        .catch(() => {});
    },

    processSupplierState(stateId) {
      return Store.getState(stateId)
        .then((response) => {
          const data = response.data;
          if (data) {
            this.editedItem.supplier.state = data._id;
            this.editedItem.supplier.stateName = data.name;
            this.editedItem.supplier.stateGSTCode = data.gstCode;
          }
        })
        .catch(() => {});
    },

    processSupplierCity(cityId) {
      return Store.getCity(cityId)
        .then((response) => {
          const data = response.data;
          if (data) {
            this.editedItem.supplier.city = data._id;
            this.editedItem.supplier.cityName = data.name;
          }
        })
        .catch(() => {});
    },

    editItem(item) {
      if (!item._id) {
        const itemColumns = localStorage["itemColumns"];
        if (itemColumns) this.editedItem.headers = JSON.parse(itemColumns);

        // set terms and conditions
        if (this.ps.termsAndConditions) {
          item.hasTNC = true;
          item.termsAndConditions = this.ps.termsAndConditions;
        }

        // set default invoice number
        let pin = this.returns.length + 1;
        if (this.ps.purchaseReturnStartsFrom) {
          pin = pin + this.ps.purchaseReturnStartsFrom;
        }

        if (this.ps.purchaseReturnPrefix) {
          pin = this.ps.purchaseReturnPrefix + pin;
        }
        item.invoiceNumber = pin;
        item.currencyCode = this.country.currencyCode;
      }

      if (item.buyer && !item.buyer.logo) {
        item.buyer.logo = this.logo;
      }

      this.editedItem = item;
      this.dialog = true;

      setTimeout(() => (this.dialogOpened = true), 0);
    },

    closeDialog() {
      this.dialog = false;
      setTimeout(() => (this.dialogOpened = false), 0);
    },

    getPhotoURL(data) {
      if (data && data.split(".")[1] === "png") {
        return getS3URL(this._id, data);
      }

      return data;
    },

    getMobile(item) {
      return "+" + item.callingCode + " " + item.mobile;
    },

    processSupplier(sid) {
      let object = {};
      const sinfo = this.suppliers.find((x) => x._id === sid);
      if (sinfo) {
        object = {
          _id: sinfo._id,
          name: sinfo.name,
          contactNumber: this.getMobile(sinfo),
          email: sinfo.email,
          taxCode: sinfo.taxCode,
          pan: sinfo.pan,
          cin: sinfo.ci,
          fssai: sinfo.fssai,
          billingAddress: sinfo.billingAddress,
          isShippingSameAsBilling: sinfo.isShippingSameAsBilling,
          state: sinfo.state,
          stateGSTCode: "",
          stateName: "",
        };

        if (object.isShippingSameAsBilling) {
          object.shippingAddress = object.billingAddress;
        } else {
          object.shippingAddress = sinfo.shippingAddress;
        }

        if (sinfo.country) this.processSupplierCountry(sinfo.country);
        if (sinfo.state) this.processSupplierState(sinfo.state);
        if (sinfo.city) this.processSupplierCity(sinfo.city);
      }

      this.editedItem.supplier = object;
    },

    createSupplier() {
      const obj = Object.assign({}, this.defaultSupplierItem);
      obj.callingCode = this.country.callingCode;
      obj.currencyCode = this.country.currencyCode;
      this.editedSupplierItem = obj;

      this.$store.state.supplierModal = true;
    },

    processColumn(item, index) {
      let headers = JSON.parse(JSON.stringify(this.editedItem.headers || []));
      if (headers.includes(item)) headers = headers.filter((x) => x != item);
      else headers.splice(index, 0, item);
      this.editedItem.headers = headers;
    },

    saveColumns() {
      if (!this.editedItem.headers) return;

      localStorage["itemColumns"] = JSON.stringify(this.editedItem.headers);
      this.columnsModal = false;
    },

    amountFormatted(amount, currencyCode) {
      return this.currencyPipe(
        amount,
        getSymbolFromCurrency(currencyCode || "INR")
      );
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    processChargeItem() {
      const ac = this.editedItem.additionalCharges;
      let canAdd = true;
      if (ac.length) {
        const lastItem = ac[ac.length - 1];
        if (!lastItem.text) canAdd = false;
      }

      if (canAdd) {
        this.editedItem.additionalCharges.push({
          charge: 0,
          total: 0,
          text: "",
          taxSlab: { _id: "none", text: "None", tax: 0 },
        });
      }
    },

    removeAdditionalCharge(index) {
      this.editedItem.additionalCharges.splice(index, 1);
    },

    getAdditionalChargeTax(item) {
      let taxAmount = 0;
      if (
        item &&
        item.taxSlab &&
        !["none", "exempted"].includes(item.taxSlab._id)
      ) {
        taxAmount = parseFloat((item.charge * item.taxSlab.tax) / 100);
        taxAmount = this.getAmount(taxAmount);
        item.tax = item.taxSlab.tax;

        if (this.isIGST) {
          item.igst = item.tax;
          item.igst = taxAmount;
        } else {
          item.sgst = item.tax / 2;
          item.cgst = item.tax / 2;
          item.sgstAmount = taxAmount / 2;
          item.cgstAmount = taxAmount / 2;
        }
      }

      if (taxAmount) taxAmount = this.getAmount(taxAmount);
      item.taxAmount = taxAmount;
      return taxAmount;
    },

    getAdditionalChargeTotal(item) {
      item.charge = parseFloat(item.charge);
      let taxAmount = this.getAdditionalChargeTax(item);
      let total = item.charge + taxAmount;
      total = this.getAmount(total);
      item.total = total;
      return this.getAmount(total);
    },

    getAdditionalChargesTotal() {
      let amount = 0;
      const acs = this.editedItem.additionalCharges;
      if (!acs) return amount;

      acs.forEach((x) => {
        amount = amount + x.total;
      });

      return this.getAmount(amount);
    },

    validateAdditionalCharge(item) {
      if (["", "NaN"].includes(item.charge) || item.charge.includes("NaN")) {
        item.charge = 0;
      }
    },

    getRefDoc() {
      return this.$refs.inputDocUpload;
    },

    pickFileDoc() {
      this.getRefDoc().click();
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    isValidDocument(file) {
      if (!file) return false;
      const type = file["type"].split("/")[0];
      const type2 = file["type"].split("/")[1];
      if (
        file &&
        (this.supportedFormats.includes(type) ||
          this.supportedFormats.includes(type2))
      ) {
        this.fileObject = {
          name: file["name"],
          size: file["size"],
          type: this.supportedFormats.includes(type) ? type : type2,
        };
        return true;
      }
      return false;
    },

    getURLDoc(data) {
      if (!data) return data;
      const ext = data.split(".")[1];
      if (data && (this.supportedFormats.includes(ext) || ext == "png")) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidDocError() {
      this.$store.state.snackbar.text = "Unsupported File Format";
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelectedDoc() {
      const file = this.getRefDoc().files[0];
      if (!this.isValidDocument(file)) return this.invalidDocError();

      this.fileObject._id = "";
      this.fileObject._id = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(
        this._id,
        "purchases",
        file,
        this.fileObject.type
      )
        .then((x) => {
          if (!this.editedItem.files) this.editedItem.files = [];
          this.fileObject._id = x.data.file;
          this.editedItem.files.push(this.fileObject);
          this.uploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch(() => {
          this.uploading = false;
        });
    },

    openDocument(item) {
      window.open(this.getURLDoc(item._id), "_blank");
    },

    removeDocument(item) {
      if (!this.editedItem.files) return;
      const files = this.editedItem.files.filter((x) => x._id != item._id);
      this.editedItem.files = files;
    },

    processDueDate() {
      const dueDate = this.editedItem.dueDate;
      this.editedItem.paymentTerms = moment(dueDate).diff(todayDate, "days");
    },

    productText(item) {
      let string = item.name + " (" + item.size + item.unit + ")";
      if (item.barcodeID) {
        string += " - (Barcode ID: " + item.barcodeID;

        if (item.barcodeText) {
          string += " - " + item.barcodeText;
        }

        string += ")";
      }

      return string;
    },

    removeItem(item) {
      this.editedItem.items = this.editedItem.items.filter(
        (x) => x._id != item._id
      );
    },

    querySelections(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;

      return Store.searchProducts(this._id, keyword, false)
        .then((x) => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.products = x.data;
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    },

    productSelected() {
      const products = this.editedItem.items || [];
      this.lastSearched = this.selectedProduct.name;
      if (products.find((x) => x._id === this.selectedProduct._id)) return;

      const newItem = JSON.parse(JSON.stringify(this.selectedProduct));
      this.itemSearchInput = "";
      this.selectedProduct = {};

      newItem.qty = 1;
      newItem.discount = 0;
      newItem.tax = newItem.gst || newItem.tax || 0;
      newItem.purchasePrice = newItem.purchasePrice || newItem.price;
      newItem.discountBy = "amount";
      products.push(newItem);
      this.editedItem.items = products;
    },

    processItemDiscountBy(item) {
      item.discount = 0;
      item.discountBy = item.discountBy == "amount" ? "percent" : "amount";
    },

    getItemDiscount(item) {
      let amount = 0;
      if (item.discountBy == "percent" && item.discountPercent) {
        const total = this.getItemPriceTotal(item);
        amount = (total * item.discountPercent) / 100;
        item.discount = amount;
      } else if (item.discountBy == "amount") {
        amount = item.discount;
      }

      return this.getAmount(amount);
    },

    getItemTax(item) {
      let amount = 0;

      if (item.tax) {
        const total = this.getItemWithoutTaxTotal(item);
        amount = (total * item.tax) / 100;
        item.taxAmount = amount;

        // process item gst info
        if (this.isIGST) {
          item.igst = item.tax;
          item.igstAmount = item.taxAmount;
        } else {
          item.sgst = item.tax / 2;
          item.cgst = item.tax / 2;
          item.sgstAmount = (total * item.sgst) / 100;
          item.cgstAmount = (total * item.cgst) / 100;
        }
      }

      return this.getAmount(amount);
    },

    getItemPriceTotal(item) {
      return item.purchasePrice * item.qty;
    },

    getItemWithoutTaxTotal(item) {
      return this.getItemPriceTotal(item) - item.discount;
    },

    getItemWithTaxTotal(item) {
      let amount = this.getItemWithoutTaxTotal(item) + this.getItemTax(item);
      amount = this.getAmount(amount);
      item.total = amount;
      return amount;
    },

    getSubtotal() {
      let amount = 0;
      if (!this.editedItem.items) return amount;

      this.editedItem.items.forEach((x) => {
        amount = amount + this.getItemWithTaxTotal(x);
      });

      amount = this.getAmount(amount);
      this.editedItem.subtotal = amount;
      return amount;
    },

    getTaxableAmount() {
      let amount = 0;
      if (!this.editedItem.items) return amount;

      this.editedItem.items.forEach((x) => {
        if (x.tax) {
          amount = amount + this.getItemWithoutTaxTotal(x);
        }
      });

      const acs = this.editedItem.additionalCharges;
      if (acs && acs.length) {
        acs.forEach((x) => {
          if (x.taxSlab && x.taxSlab.tax) {
            amount = amount + x.charge;
          }
        });
      }

      this.editedItem.taxableAmount = this.getAmount(amount);
      return amount;
    },

    getTotal() {
      let amount = this.getSubtotal();
      amount = amount + this.getAdditionalChargesTotal();

      const discount = this.editedItem.discount;
      if (discount && discount <= amount) {
        amount = amount - discount;
      }

      this.editedItem.amount = this.getAmount(amount);
      return amount;
    },

    getDueAmount() {
      let amount = 0;
      amount = this.getTotal() - this.editedItem.amountPaid;
      this.editedItem.amountDue = this.getAmount(amount);
      return amount;
    },

    getAmount(amount) {
      return parseFloat(parseFloat(amount).toFixed(2));
    },

    savePurchaseInvoice() {
      if (!this.$refs.form.validate()) return;

      const validated = this.processValidations();
      if (!validated) return;

      this.processing = true;
      Store.snackbarStart();

      const piObject = Object.assign({}, this.editedItem);

      let Request;
      if (piObject._id) {
        Request = Store.editPurchaseReturn(piObject._id, piObject, false);
      } else {
        Request = Store.createPurchaseReturn(this._id, piObject, false);
      }

      return Request.then(() => {
        this.processing = false;
        this.fetchPurchaseReturns();
        Store.snackbarSuccess();
        this.closeDialog();
      }).catch((e) => {
        console.log(e);
        this.processing = false;
        Store.snackbarError();
      });
    },

    processValidations() {
      let ok = true;
      if (!this.supplier._id) {
        this.throwError("Error: Supplier is required");
        ok = false;
      }

      if (ok && (!this.editedItem.items || !this.editedItem.items.length)) {
        this.throwError("Error: Bill Item(s) is required");
        ok = false;
      }

      return ok;
    },

    throwError(message) {
      this.$store.state.snackbar.text = message;
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    getPaymentStatusText(status) {
      const statuses = {
        paid: "Paid",
        "partially-paid": "Partially Paid",
        due: "Due",
      };

      return statuses[status];
    },

    getPaymentStatusColor(status) {
      return status == "paid"
        ? "success"
        : status == "due"
        ? "error"
        : "warning";
    },

    getFormattedDate(date, format = "lll") {
      return moment(date).format(format);
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deletePurchaseReturn(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchPurchaseReturns();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    downloadInvoice(item) {
      return Store.downloadPurchaseReturn(item._id, this.$store.state.token);
    },

    viewInvoice(item) {
      this.beingViewed = item;
      this.viewModal = true;
    },

    getInvoiceHeaders(item) {
      const itemHeaders = item.headers;
      if (!itemHeaders) return [];
      return this.availableHeaders.filter((x) => itemHeaders.includes(x.value));
    },

    getFormattedItems(item) {
      if (!item || !item.items) return [];
      return item.items.map((x, i) => {
        x.index = i + 1;
        return x;
      });
    },

    isIndia(buyer) {
      return buyer.countryName == "India";
    },

    downloadAsPDF() {
      return Store.downloadPurchaseReturn(
        this.beingViewed._id,
        this.$store.state.token
      );
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    formTitle() {
      return this.editedItem._id
        ? "Edit Purchase Return - " + this.editedItem.invoiceNumber
        : "Create Purchase Return";
    },

    store() {
      return this.$store.state.activeStore;
    },

    logo() {
      return this.store.purchaseSettings
        ? this.store.purchaseSettings.logo
        : "";
    },

    country() {
      return this.$store.state.activeStore.country;
    },

    currencyCode() {
      return this.editedItem.currencyCode;
    },

    currencyPrefix() {
      return getSymbolFromCurrency(this.currencyCode);
    },

    getHeaders() {
      const itemHeaders = this.editedItem.headers;
      if (!itemHeaders) return [];

      return this.availableHeaders.filter((x) => itemHeaders.includes(x.value));
    },

    getAvailableHeaders() {
      return this.availableHeaders.map((x) => {
        if (this.editedItem.headers) {
          x.selected = this.editedItem.headers.includes(x.value);
        }

        return x;
      });
    },

    ps() {
      return this.store.purchaseSettings || {};
    },

    getPurchaseReturnDate() {
      return moment(this.editedItem.invoiceDate).format("ll");
    },

    getPurchaseDueDate() {
      if (!this.editedItem.dueDate) return "";
      return moment(this.editedItem.dueDate).format("ll");
    },

    isIGST() {
      const igst = this.buyer.state == this.supplier.state;
      this.editedItem.isIGST = igst;
      return igst;
    },

    getTaxSlabs() {
      if (!this.editedItem.items) return [];

      const csSlabs = this.cenums.taxSlabs;
      if (!csSlabs) return [];

      // process all slabs
      const taxSlabs = [];

      // process item tax slabs
      this.editedItem.items.forEach((x) => {
        const slabItem = csSlabs.find((i) => i.tax === x.tax);
        if (slabItem && slabItem.tax) {
          const itemTotal = this.getItemWithoutTaxTotal(x);

          const ts = taxSlabs.find((i) => i._id == slabItem._id);
          if (!ts) {
            if (this.isIGST) {
              slabItem.igst = slabItem.tax;
            } else {
              slabItem.sgst = slabItem.tax / 2;
              slabItem.cgst = slabItem.tax / 2;
            }

            slabItem.taxSlab = {
              _id: slabItem._id,
              text: slabItem.text,
              tax: slabItem.tax,
            }; // for storage in future cases

            slabItem.slabTotal = itemTotal;
            taxSlabs.push(slabItem);
          } else {
            ts.slabTotal = ts.slabTotal + itemTotal;
          }
        }
      });

      // process additional charges tax slabs
      const acs = this.editedItem.additionalCharges;
      if (acs && acs.length) {
        acs.forEach((x) => {
          const slabItem = csSlabs.find((i) => i._id === x.taxSlab._id);
          if (slabItem && slabItem.tax) {
            const ts = taxSlabs.find((i) => i._id == slabItem._id);

            if (!ts) {
              if (this.isIGST) {
                slabItem.igst = slabItem.tax;
              } else {
                slabItem.sgst = slabItem.tax / 2;
                slabItem.cgst = slabItem.tax / 2;
              }
              slabItem.slabTotal = x.charge;

              slabItem.taxSlab = {
                _id: x.taxSlab._id,
                text: x.taxSlab.text,
                tax: x.taxSlab.tax,
              }; // for storage in future cases

              taxSlabs.push(slabItem);
            } else {
              ts.slabTotal = ts.slabTotal + x.charge;
            }
          }
        });
      }

      taxSlabs.forEach((x) => {
        if (this.isIGST) {
          x.igstAmount = (x.slabTotal * x.igst) / 100;
          x.taxAmount = x.igstAmount;
        } else {
          x.sgstAmount = (x.slabTotal * x.sgst) / 100;
          x.cgstAmount = (x.slabTotal * x.cgst) / 100;
          x.taxAmount = x.sgstAmount + x.cgstAmount;
        }
      });

      this.editedItem.taxes = taxSlabs;
      return taxSlabs;
    },

    buyer() {
      return this.editedItem.buyer || {};
    },

    supplier() {
      return this.editedItem.supplier || {};
    },

    bvBuyer() {
      return this.beingViewed.buyer || {};
    },

    bvSupplier() {
      return this.beingViewed.supplier || {};
    },

    getReturns() {
      let returns = this.returns;

      const supplier = this.filters.supplier;
      if (supplier) {
        returns = returns.filter((x) => x.supplier._id == supplier);
      }

      return returns;
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  watch: {
    selectedSupplier(n) {
      if (!this.dialogOpened) return;

      this.processSupplier(n);
    },

    supplierModifiedA(n) {
      if (!this.dialogOpened) return;

      if (n) {
        this.$emit("update:supplierModified", true);
      }
    },

    "editedItem.paymentTerms"(n) {
      if (!this.dialogOpened) return;

      if (isNaN(n) || !n) {
        this.editedItem.dueDate = todayDate;
      } else {
        this.editedItem.dueDate = moment()
          .add(n, "days")
          .format(dateFormat);
      }
    },

    itemSearchInput: _.debounce(function(newVal, oldVal) {
      if (!this.dialogOpened) return;

      const array = newVal ? newVal.split("(") : [];
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        array.length === 1
      ) {
        this.querySelections(newVal);
      }
    }, 500),

    "editedItem.discountBy"() {
      if (!this.dialogOpened) return;

      this.editedItem.discount = 0;
      this.editedItem.discountPercent = 0;
    },

    "editedItem.discountPercent"(n) {
      if (!this.dialogOpened) return;

      let amount = 0;
      if (n && n > 0) {
        const total = this.getSubtotal() + this.getAdditionalChargesTotal();
        amount = (total * n) / 100;
      }
      this.editedItem.discount = amount;
    },

    "editedItem.paymentStatus"(n) {
      if (!this.dialogOpened) return;

      if (n == "paid") {
        this.editedItem.amountPaid = this.editedItem.amount;
      } else {
        this.editedItem.amountPaid = 0;
      }
    },
  },

  data() {
    return {
      dialog: false,
      dialogOpened: false,
      processing: false,
      uploading: false,
      piLoading: false,

      valid: false,
      rowItems: config.rowItems,

      pienums: {},
      prenums: {},
      cenums: {},
      returns: [],

      defaultItem: {
        currencyCode: "",
        paymentMethod: "CASH",
        paymentStatus: "due",

        amount: 0,
        amountDue: 0,
        amountPaid: 0,
        additionalCharges: [],

        discount: 0,
        discountPercent: 0,
        discountBy: "amount",

        invoiceDate: todayDate,
        hasDueDate: false,
        paymentTerms: 30,
        dueDate: after30DaysDate,

        buyer: {},
        supplier: {},

        items: [],
        files: [],

        hasNotes: false,
        hasTNC: false,
        headers: [
          "index",
          "name",
          "hsnSac",
          "qty",
          "purchasePrice",
          "discount",
          "tax",
          "total",
        ],
      },
      editedItem: {},
      fileObject: {},

      selectedSupplier: "",

      itemSearchInput: "",
      lastSearched: "",
      lastRequest: 0,
      lastResponse: 0,
      outstandingRequests: 0,
      isSearching: false,

      selectedProduct: {},
      products: [],

      paymentMethods: ["CASH", "CARD", "COD", "DOC", "UPI", "BT"],
      supportedFormats: ["image", "pdf", "xls", "xsls", "doc", "docx"],
      date1Menu: false,
      date2Menu: false,

      headers: [
        {
          text: "#",
          align: "start",
          value: "index",
          disabled: true,
        },
        { text: "Purchase Return No.", value: "invoiceNumber" },
        {
          text: "Supplier",
          align: "start",
          value: "supplier.name",
          disabled: true,
        },
        { text: "Amount", value: "amount" },
        { text: "Paid", value: "amountPaid" },
        { text: "Due", value: "amountDue" },

        { text: "Status", value: "paymentStatus" },
        { text: "Date & Time", value: "createdAt" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      requiredHeaders: ["index", "name", "qty", "purchasePrice", "total"],

      availableHeaders: [
        { text: "S. No.", value: "index", sortable: false, selected: true },
        { text: "Item", value: "name", selected: true },
        {
          text: "Serial Number",
          value: "serialNumber",
          sortable: false,
          selected: false,
        },
        { text: "IMEI", value: "imei", sortable: false, selected: false },
        {
          text: "Expiry Date",
          value: "expiryDate",
          sortable: false,
          selected: false,
        },
        {
          text: "HSN/SAC",
          value: "hsnSacCode",
          sortable: false,
          selected: true,
        },
        {
          text: "Qty",
          value: "qty",
          sortable: false,
          align: "center",
          selected: true,
        },
        {
          text: "M.R.P.",
          value: "marketPrice",
          sortable: false,
          selected: false,
        },
        {
          text: "Price (Return)",
          value: "purchasePrice",
          sortable: false,
          selected: true,
          hint: "Help",
        },
        {
          text: "Discount",
          value: "discount",
          sortable: false,
          selected: true,
        },
        {
          text: "Tax",
          value: "tax",
          sortable: false,
          selected: true,
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          align: "right",
          selected: true,
        },
      ],

      editedSupplierItem: { isActive: true },
      defaultSupplierItem: {
        isActive: true,
        isShippingSameAsBilling: true,
        balanceType: "collect",
        currencyCode: "",
      },
      supplierModifiedA: false,

      columnsModal: false,
      confirmDialog: false,
      beingDeleted: {},

      beingViewed: {},
      viewModal: false,

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        mobileRule: [(v) => (!!v && !isNaN(v)) || "Must be a valid number"],
        amountRule: [(v) => (!v && v != 0) || v >= 0 || "Must be valid amount"],
        addressRule: [(v) => (!!v && !!v.description) || "It is required"],
        countryRule: [(v) => !!v || "Country is required"],
        priceRule: [(v) => (v && v >= 0) || "Must be valid amount"],
        numberRule: [(v) => !isNaN(v) || "Enter a valid number"],
      },
    };
  },
};

<template>
  <div>
    <v-chip
      :link="isLink"
      small
      v-if="status === 'new' || status === 'accepted'"
      color="primary"
    >
      {{ status | firstLetterUppercase }}
    </v-chip>
    <v-chip
      :link="isLink"
      small
      v-else-if="status === 'shipped'"
      color="warning"
    >
      {{ status | firstLetterUppercase }}
    </v-chip>
    <v-chip
      :link="isLink"
      small
      v-else-if="status === 'delivered'"
      color="success"
    >
      {{ status | firstLetterUppercase }}
    </v-chip>
    <v-chip
      :link="isLink"
      small
      v-else-if="status === 'cancelled'"
      color="error"
    >
      {{ status | firstLetterUppercase }}
    </v-chip>
    <v-chip :link="isLink" small v-else>
      {{ status | firstLetterUppercase }}
    </v-chip>
  </div>
</template>
<script>
export default {
  name: "StatusChip",
  props: ["status", "isLink"],
};
</script>

<template>
  <v-dialog v-model="$store.state.shipmentBalanceModal" max-width="400px" persistent>
    <v-progress-linear
      v-if="processing"
      indeterminate
      color="primary"
      class="mb-0"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col style="text-align: center;">
            <span>Recharge your shipment wallet</span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text style="margin-top: -15px;">
        <v-container>
          <v-form ref="form" valid="valid">
            <v-row>
              <v-col cols="12">
                <p class="pi-title" style="margin-bottom: 0">Enter amount</p>
                <small>Minimum acceptable amount is <b>{{ minShipmentWalletBalance | fc }}</b>.</small>
                <div style="height: 15px"></div>
                <v-text-field 
                  outlined 
                  dense
                  hide-details
                  :prefix="$store.state.country.currencySymbol"
                  placeholder="Enter here"
                  :disabled="processing"
                  :rules="validationRules.walletBalanceRule"
                  v-model="shipmentWalletRecharge.balance"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col><h6 style="margin: 0"><b>Amount:</b></h6></v-col>
              <v-col class="text-right"><h6 style="margin: 0">{{ shipmentWalletRecharge.balance | fc }}</h6></v-col>
            </v-row>

            <v-row>
              <v-col><h6 style="margin: 0"><b>Tax (18%):</b></h6></v-col>
              <v-col class="text-right"><h6 style="margin: 0">{{ calculateWalletBalanceTax() | fc }}</h6></v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col><h6 style="margin: 0"><b>Total Payable:</b></h6></v-col>
              <v-col class="text-right">
                <h6 style="margin: 0"><b>{{ calculateWalletPayable() | fc }}</b></h6>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col class="text-right">
                <v-spacer></v-spacer>
                <v-btn color="error" :disabled="processing" text @click="$store.state.shipmentBalanceModal = false;">Cancel</v-btn>
                <v-btn class="ml-2" color="primary" :disabled="processing || parseFloat(shipmentWalletRecharge.balance) < minShipmentWalletBalance" outlined @click="checkoutWalletRecharge">Proceed to checkout</v-btn>
              </v-col>
            </v-row>

          </v-form>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  import config from '../../config';
  import Store from '../../services/store';
  import Global from '../../services/global';

  export default {
    props: [
      '_id',
      'walletModified'
    ],

    methods: {
      calculateWalletBalanceTax() {
        const balance = parseFloat(this.shipmentWalletRecharge.balance);
        return parseFloat((balance * config.gst / 100).toFixed(2));
      },

      calculateWalletPayable() {
        const balance = parseFloat(this.shipmentWalletRecharge.balance);
        return Global.getTaxAmount(balance);
      },

      checkoutWalletRecharge() {
        const amount = parseFloat(this.calculateWalletPayable().toFixed(2));
        const description = 'Growcify Shipment Wallet Recharge';
        
        const options = Global.getRazOptions(amount, description, this.$store.state.country.currencyCode);
        const newthis = this;
        options.handler = function(response) { newthis.processPayment(response.razorpay_payment_id) };

        new window.Razorpay(options).open();
      },

      processPayment(paymentId) {
        const walletBalanceObj = {
          currencyCode: this.$store.state.country.currencyCode,
          amount: this.shipmentWalletRecharge.balance,
          total: this.calculateWalletPayable(),
          paymentId: paymentId
        };

        this.processing = true;
        return Store.addShipmentWalletBalance(this._id, walletBalanceObj, false)
          .then(() => {
            this.$emit('update:walletModified', true);
            this.processing = this.$store.state.shipmentBalanceModal = false;
          })
          .catch(e => { console.log(e); this.processing = false; });
      }
    },

    data() {
      return {
        processing: false,
        minShipmentWalletBalance: config.shipment.minBalance,
        
        shipmentWalletRecharge: {
          balance: 200
        },

        validationRules: {
          walletBalanceRule: [
            v => v >= config.shipment.minBalance || 'Minimum amount 200'
          ]
        }
      }
    }
  }
</script>
import Store from "../../services/store";
import FileUpload from "../../services/file-upload";
import { getS3URL } from "../../helpers/misc";
import _ from "underscore";
import Inventory from "../../services/inventory";

export default {
  name: "Notification",

  props: ["dialog", "users", "_id", "toAll", "editedItem"],

  methods: {
    send() {
      if (!this.$refs.form.validate()) return;
      this.processing = true;
      Store.snackbarStart();

      this.editedItem.sentTo = this.users.map((x) => x._id);
      const request = this.editedItem._id
        ? Store.editNotification(this.editedItem._id, this.editedItem, false)
        : Store.createNotification(this._id, this.editedItem, false);
      return request
        .then((x) => {
          this.processing = false;
          this.close();
          this.reset();
          if (this.editedItem.notify && !this.editedItem._id && x.data._id)
            this.processNotification(x.data._id);
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    processNotification(_id) {
      this.$store.state.backgroundProcesses.push("notification");
      return Store.processNotification(_id, false)
        .then(() => this.$store.state.backgroundProcesses.pop())
        .catch((e) => {
          console.log(e);
          this.$store.state.backgroundProcesses.pop();
        });
    },

    close() {
      this.$emit("update:dialog", false);
    },

    reset() {
      this.$emit("update:users", []);

      const defaultObj = {
        notify: true,
        sendSMSIfNotificationFails: false,
        data: {
          entity: "regular",
        },
      };
      this.$emit("update:editedItem", defaultObj);
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      if (file && file["type"].split("/")[0] === "image") {
        this.editedItem.type = file["type"].split("/")[0];
        return true;
      }
      return false;
    },

    getURL(data) {
      if (
        data &&
        (data.split(".")[1] === "png" || data.split(".")[1] === "mp4")
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError() {
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.editedItem.file = "";
      this.editedItem.file = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(
        this._id,
        "notifications",
        file,
        this.editedItem.type
      )
        .then((x) => {
          this.editedItem.file = x.data.file;
          this.uploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    productText(item) {
      return item.name + " (" + item.size + item.unit + ")";
    },

    querySelections(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;
      return Store.searchProducts(this._id, keyword, false)
        .then((x) => {
          this.outstandingRequests--;
          if (thisRequest < this.lastResponse) return;
          this.lastResponse = thisRequest;

          this.products = this.parseJSON(x.data);
          this.isSearching = false;
        })
        .catch(() => {
          this.outstandingRequests--;
          this.isSearching = false;
        });
    },

    parseJSON(array) {
      return JSON.parse(JSON.stringify(array));
    },

    fetchCategories() {
      this.cloading = true;
      return Inventory.getCategories(this._id, false)
        .then((res) => {
          this.categories = res.data;
          this.cloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.cloading = false;
        });
    },

    fetchVendors() {
      if (this.isVendor) return;
      this.vloading = true;
      return Store.getVendors(this._id, false)
        .then((res) => {
          this.vendors = res.data;
          this.vloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.vloading = false;
        });
    },

    fetchGroups() {
      this.gloading = true;
      return Store.getGroups(this._id, false)
        .then((x) => {
          this.groups = x.data;
          this.gloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.gloading = false;
        });
    },
  },

  watch: {
    searchInput: _.debounce(function(newVal, oldVal) {
      const array = newVal ? newVal.split("(") : [];
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        array.length === 1
      )
        this.querySelections(newVal);
    }, 500),

    "editedItem.redirectTo"(n) {
      if (n) this.editedItem.data.entity = n;
      else this.editedItem.data.entity = "regular";

      if (n == "category" && !this.categories.length) this.fetchCategories();
      else if (n == "vendor" && !this.vendors.length) this.fetchVendors();
      else if (n == "group" && !this.groups.length) this.fetchGroups();
    },
  },

  computed: {
    formTitle() {
      return this.editedItem._id
        ? "Edit notification"
        : "Compose new notification";
    },

    redirectionItems() {
      const items = [
        { key: "category", value: "Category" },
        { key: "product", value: "Product" },
        { key: "url", value: "External URL" },
        { key: "group", value: "Group" },
      ];
      return items;
    },
  },

  data() {
    return {
      valid: false,
      processing: false,
      title: { title: "message", message: "" },
      notifications: [],
      uploading: false,
      showErr: false,

      loading: false,
      cloading: false,
      vloading: false,
      gloading: false,

      categories: [],
      products: [],
      vendors: [],
      groups: [],

      searchInput: "",
      lastSearched: "",
      isSearching: false,
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,

      validationRules: {
        basicRule: [(v) => !!v || "It's required"],
      },
    };
  },
};

import router from "../../../router";
import Store from "../../../services/store";
import Order from "../../../services/order";
import InfoCard from "../../../components/general/info-card.vue";
import AddressString from "../../../components/general/address-string.vue";
import AddressNavigation from "../../../components/general/address-navigation.vue";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb.vue";

export default {
  name: "Return",

  components: {
    InfoCard,
    AddressString,
    AddressNavigation,
    Breadcrumb,
  },

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Orders")) {
      return router.push("/business/" + this._id);
    }

    this.returnId = this.$route.params.returnId;
    this.fetchReturnOrder();
    this.fetchCommonEnums();
    this.fetchReturnOrderEnums();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchReturnOrder() {
      this.loading = true;
      return Order.getReturnOrder(this.returnId)
        .then((res) => {
          this.loading = false;
          this.returnOrder = res.data;
          this.oro = JSON.parse(JSON.stringify(this.returnOrder));
        })
        .catch((err) => {
          console.log(err);
          router.go(-1);
          this.loading = false;
        });
    },

    fetchReturnOrderEnums() {
      this.eloading = true;
      return Order.getReturnOrderEnums(this._id)
        .then((res) => {
          this.eloading = false;
          this.enums = res.data || {};
          if (this.enums.status) {
            this.enums.status = this.enums.status.map((x) => {
              return {
                key: x,
                value: x.charAt(0).toUpperCase() + x.slice(1),
              };
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.eloading = false;
        });
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) {
        this.cenums = ce;
        return;
      }

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => console.log(e));
    },

    getCallingCode(item) {
      let cc = this.$store.state.country.callingCode || "91";
      if (item.address && item.address.callingCode) {
        cc = item.address.callingCode;
      } else if (!item.address && item.user && item.user.callingCode) {
        cc = item.user.callingCode;
      }
      return cc;
    },

    getReturnMethod(key) {
      if (!this.cenums?.returnMethods) return "";
      const method = this.cenums.returnMethods.find((x) => x.key === key);
      return method ? method.value : "";
    },

    amountFormatted(amount) {
      let formattedAmount = amount;
      if (this.returnOrder.currencyCode === "INR") {
        formattedAmount = this.currencyPipe(amount, "₹");
      } else if (
        this.returnOrder.currencyCode &&
        this.returnOrder.currencyCode !== "INR"
      ) {
        formattedAmount = this.currencyPipe(
          amount,
          this.returnOrder.currencyCode
        );
      } else if (
        !this.$store.state.country.currencyCode &&
        !this.returnOrder.currencyCode
      ) {
        formattedAmount = this.currencyPipe(amount, "₹");
      } else {
        formattedAmount = this.currencyPipe(
          amount,
          this.$store.state.country.currencySymbol
        );
      }

      return formattedAmount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },

    capitalize(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    saveReturnOrder() {
      if (this.processing) return;
      this.processing = true;
      Store.snackbarStart();
      return Order.updateReturnOrder(this.returnId, this.returnOrder)
        .then(() => {
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.processing = false;
          Store.snackbarError();
        });
    },
  },

  computed: {
    store() {
      return this.$store.state.activeStore;
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    isMultivendor() {
      return this.$store.state.activeStore.isMultivendor;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    hcifv() {
      return (
        this.isVendor && this.store.vendorOwner.hideCustomerInfoFromVendors
      );
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    reorderedItems() {
      if (!this.returnOrder || !this.returnOrder.items) return [];
      const items = [];
      this.returnOrder.items.forEach((x, i) => {
        x.index = i + 1 + ".";
        items.push(x);
        if (x.isCombo) {
          x.comboItems.forEach((c) => {
            const cobject = {
              _id: c._id,
              qty: c.deductQty,
              name: c._id.name,
              size: c._id.size,
              unit: c._id.unit,
              color: c._id.color,
              ignore: true,
            };
            if (c._id.category && c._id.category.name) {
              cobject.category = c._id.category.name;
            }
            items.push(cobject);
          });
        }
      });

      return items;
    },

    breadcrumbItems() {
      return [
        {
          title: "Orders",
          to: { name: "store-orders", params: { _id: this._id } },
        },
        {
          title: "Returns",
          to: {
            name: "store-orders",
            params: { _id: this._id },
            query: { tab: "returns" },
          },
        },
        {
          title: this.loading
            ? "Loading..."
            : "Returned OID: " + this.returnOrder.oid,
          isLast: true,
        },
      ];
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  watch: {
    "returnOrder.status"(n) {
      if (this.oro.status == n || !n) return;
      this.saveReturnOrder();
    },
  },

  data() {
    return {
      _id: "",
      returnId: "",
      loading: false,
      eloading: false,
      processing: false,

      oro: {},
      enums: {},
      cenums: {},
      returnOrder: {},

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "_id.name" },
        { text: "Size", value: "size", sortable: false },
        { text: "Qty", value: "qty" },
        { text: "Price", value: "price", sortable: false },
        { text: "Return Reason", value: "reason", sortable: false },
        /*{ text: 'Actions', value: 'actions', sortable: false},*/
      ],
    };
  },
};

<template>
  <v-dialog v-model="$store.state.customerFormModal" max-width="650px">
    <v-progress-linear
      v-if="processing"
      indeterminate
      color="primary"
      class="mb-0"
    ></v-progress-linear>
    <v-card>
      <v-form ref="form" v-model="valid">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="pt-0">
                <h5 class="mt-0 mb-1">{{ formTitle }}</h5>
                <small v-if="isInProcess" style="font-size: 0.8em"
                  >Customer's email is required for shipment</small
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  v-model="editedItem.name"
                  label="Name"
                  required
                  :rules="validationRules.basicRule"
                  :disabled="processing"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  v-model="editedItem.email"
                  label="Email"
                  :disabled="processing"
                  :rules="
                    isInProcess
                      ? validationRules.emailRequiredRule
                      : validationRules.emailRule
                  "
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  required
                  outlined
                  hide-details
                  v-model="editedItem.callingCode"
                  :items="countries"
                  :item-text="countryText"
                  item-value="callingCode"
                  label="Country"
                  :disabled="processing"
                  :rules="validationRules.basicRule"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  v-model="editedItem.mobile"
                  label="Mobile"
                  required
                  :maxlength="10"
                  :rules="validationRules.numberRule"
                  :disabled="processing"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="canDeactivate(editedItem.mobile)">
                <v-select
                  outlined
                  label="Status"
                  :items="[
                    { key: false, value: 'Active' },
                    { key: true, value: 'Inactive' },
                  ]"
                  v-model="editedItem.deactivated"
                  item-text="value"
                  item-value="key"
                  :disabled="processing"
                  :rules="validationRules.statusRule"
                ></v-select>
              </v-col>
            </v-row>

            <v-row v-if="error">
              <v-col cols="12" class="text-danger text-right">
                {{ error }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-right pt-0">
                <v-btn
                  color="error"
                  class="mr-2"
                  :disabled="processing"
                  text
                  @click="$parent.close"
                  >Cancel</v-btn
                >
                <v-btn
                  color="success"
                  :disabled="processing"
                  outlined
                  @click="save"
                  >{{ isInProcess ? "Save & Continue" : "Save" }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
import Store from "../../services/store";
import User from "../../services/user";

export default {
  props: ["_id", "editedItem", "editedIndex", "userModified", "isInProcess"],

  methods: {
    // eslint-disable-next-line
    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.editedItem._id)
        request = User.editUser(
          this._id,
          this.editedItem._id,
          this.editedItem,
          false
        );
      else request = User.addUser(this._id, this.editedItem, false);

      return request
        .then(() => {
          this.$emit("update:userModified", true);
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          if (e.response && e.response.status === 401) {
            this.error = "Email or mobile number already exists.";
            setTimeout(() => (this.error = ""), 2500);
          } else {
            Store.snackbarError();
          }
          this.processing = false;
          this.loading = false;
        });
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
        })
        .catch((e) => console.log(e));
    },

    countryText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },

    canDeactivate(mobile) {
      return (
        !["9829410994", "8005771838"].includes(mobile) &&
        !this.user.amIOwner &&
        !this.isVendor
      );
    },
  },

  watch: {
    "$store.state.customerFormModal"(n) {
      if (n) {
        this.fetchCountries();
        if (!this.editedItem.callingCode)
          this.editedItem.callingCode = this.$store.state.activeStore.country.callingCode;
      }
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add new customer" : "Edit customer";
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    user() {
      return this.$store.state.userInfo;
    },
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      processing: false,
      valid: false,
      error: "",
      countries: [],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        numberRule: [(v) => (v && !isNaN(v)) || "Enter a valid number"],
        emailRule: [(v) => !v || emailregex.test(v) || "Must be a valid email"],
        emailRequiredRule: [
          (v) => (v && emailregex.test(v)) || "Must be a valid email",
        ],
      },
    };
  },
};
</script>

import Vue from "vue";

export default {
  addCategory(storeId, category, noProgress = true) {
    return Vue.axios.post(`category/${storeId}`, category, {
      noProgress: noProgress,
    });
  },

  editCategory(_id, category, noProgress = true) {
    return Vue.axios.put(`category/${_id}`, category, {
      noProgress: noProgress,
    });
  },

  getCategory(_id, noProgress = true) {
    return Vue.axios.get(`category/${_id}`, { noProgress: noProgress });
  },

  deleteCategory(_id, noProgress = true) {
    return Vue.axios.delete(`category/${_id}`, { noProgress: noProgress });
  },

  getCategories(storeId, noProgress = true) {
    return Vue.axios.get(`category/list/${storeId}/all`, {
      noProgress: noProgress,
    });
  },

  getActiveCategories(storeId, noProgress = true) {
    return Vue.axios.get(`category/list/${storeId}/active`, {
      noProgress: noProgress,
    });
  },

  getSubCategories(storeId, categoryId, noProgress = true) {
    return Vue.axios.get(
      `category/list/sub-categories/${storeId}/${categoryId}/all`,
      { noProgress: noProgress }
    );
  },

  addProduct(storeId, product, noProgress = true) {
    return Vue.axios.post(`product/${storeId}`, product, {
      noProgress: noProgress,
    });
  },

  editProduct(_id, product, noProgress = true) {
    return Vue.axios.put(`product/${_id}`, product, { noProgress: noProgress });
  },

  getProduct(_id, noProgress = true) {
    return Vue.axios.get(`product/${_id}/detailed`, { noProgress: noProgress });
  },

  deleteProduct(_id, noProgress = true) {
    return Vue.axios.delete(`product/${_id}`, { noProgress: noProgress });
  },

  getProducts(
    storeId,
    pageNumber,
    pageSize,
    keyword,
    tabID,
    noProgress = true
  ) {
    if (keyword)
      return Vue.axios.get(
        `product/list/${tabID}/${storeId}/${pageNumber}/${pageSize}?keyword=${keyword}`,
        { noProgress: noProgress }
      );
    return Vue.axios.get(
      `product/list/${tabID}/${storeId}/${pageNumber}/${pageSize}`,
      { noProgress: noProgress }
    );
  },

  getProductEnums(storeId, noProgress = true) {
    return Vue.axios.get(`product/enums/${storeId}`, {
      noProgress: noProgress,
    });
  },

  massUpdate(storeId, updates, noProgress = true) {
    return Vue.axios.put(`product/mass-update/${storeId}`, updates, {
      noProgress: noProgress,
    });
  },

  bulkUpdateProducts(storeId, items, noProgress = true) {
    return Vue.axios.post(`product/bulk-update/${storeId}`, items, {
      noProgress: noProgress,
    });
  },

  getParentProducts(storeId, noProgress = true) {
    return Vue.axios.get(`product/list/parent/${storeId}`, {
      noProgress: noProgress,
    });
  },

  getProductHistory(storeId, productId, isVendor, noProgress = true) {
    return Vue.axios.get(
      `product/${isVendor ? "v-history" : "history"}/${storeId}/${productId}`,
      { noProgress: noProgress }
    );
  },

  getProductReviews(storeId, productId, noProgress = true) {
    return Vue.axios.get(`product/list/all/reviews/${storeId}/${productId}`, {
      noProgress: noProgress,
    });
  },

  deleteProductReview(reviewId, noProgress = true) {
    return Vue.axios.delete(`product/review/${reviewId}`, {
      noProgress: noProgress,
    });
  },

  getSubscribableProducts(storeId, noProgress = true) {
    return Vue.axios.get(`product/list/subscribable/${storeId}`, {
      noProgress: noProgress,
    });
  },

  //add-ons
  addAddon(productId, addon, noProgress = true) {
    return Vue.axios.post(`product/add-on/${productId}`, addon, {
      noProgress: noProgress,
    });
  },

  editAddon(_id, addon, noProgress = true) {
    return Vue.axios.put(`product/add-on/${_id}`, addon, {
      noProgress: noProgress,
    });
  },

  deleteAddon(_id, noProgress = true) {
    return Vue.axios.delete(`product/add-on/${_id}`, {
      noProgress: noProgress,
    });
  },

  getBrands(storeId, noProgress = true) {
    return Vue.axios.get(`brand/list/${storeId}/all`, {
      noProgress: noProgress,
    });
  },

  //add-on groups
  createAddonGroup(productId, addonGroup, noProgress = true) {
    return Vue.axios.post(`product/add-on/group/${productId}`, addonGroup, {
      noProgress: noProgress,
    });
  },

  editAddonGroup(_id, addonGroup, noProgress = true) {
    return Vue.axios.put(`product/add-on/group/${_id}`, addonGroup, {
      noProgress: noProgress,
    });
  },

  deleteAddonGroup(_id, noProgress = true) {
    return Vue.axios.delete(`product/add-on/group/${_id}`, {
      noProgress: noProgress,
    });
  },

  getProductAddonGroups(productId, noProgress = true) {
    return Vue.axios.get(`product/list/add-on/groups/${productId}`, {
      noProgress: noProgress,
    });
  },

  addBrand(storeId, brand, noProgress = true) {
    return Vue.axios.post(`brand/${storeId}`, brand, {
      noProgress: noProgress,
    });
  },

  editBrand(_id, brand, noProgress = true) {
    return Vue.axios.put(`brand/${_id}`, brand, { noProgress: noProgress });
  },

  deleteBrand(_id, noProgress = true) {
    return Vue.axios.delete(`brand/${_id}`, { noProgress: noProgress });
  },

  //submit mass-transalations
  translateCategories(_id, body, noProgress = true) {
    return Vue.axios.post(`category/mass-translate/${_id}`, body, {
      noProgress: noProgress,
    });
  },

  translateProducts(_id, body, noProgress = true) {
    return Vue.axios.post(`product/mass-translate/${_id}`, body, {
      noProgress: noProgress,
    });
  },

  //vendor inventory; storeId = vendor's storeId
  getVendorCategories(storeId, noProgress = true) {
    return Vue.axios.get(`vendor/category/list/${storeId}/active`, {
      noProgress: noProgress,
    });
  },

  getVendorBrands(storeId, noProgress = true) {
    return Vue.axios.get(`vendor/brand/list/${storeId}/active`, {
      noProgress: noProgress,
    });
  },

  getVendorSubscribedCategories(storeId, noProgress = true) {
    return Vue.axios.get(`vendor/category/list/${storeId}/subscribed`, {
      noProgress: noProgress,
    });
  },

  subscribeCategory(storeId, categoryId, noProgress = true) {
    return Vue.axios.post(
      `vendor/category/subscribe/${storeId}/${categoryId}`,
      {},
      { noProgress: noProgress }
    );
  },

  unsubscribeCategory(storeId, categoryId, noProgress = true) {
    return Vue.axios.post(
      `vendor/category/unsubscribe/${storeId}/${categoryId}`,
      {},
      { noProgress: noProgress }
    );
  },

  //stock entries
  getStockEntries(storeId, pageNumber, pageSize, keyword, noProgress = true) {
    let endpoint = `product/stock/list/${storeId}/${pageNumber}/${pageSize}`;
    if (keyword) endpoint = `?keyword=${keyword}`;
    return Vue.axios.get(endpoint, { noProgress: noProgress });
  },

  addStockEntry(storeId, entry, noProgress = true) {
    return Vue.axios.post(`product/stock/${storeId}`, entry, {
      noProgress: noProgress,
    });
  },

  editStockEntry(_id, entry, noProgress = true) {
    return Vue.axios.put(`product/stock/${_id}`, entry, {
      noProgress: noProgress,
    });
  },

  deleteStockEntry(_id, noProgress = true) {
    return Vue.axios.delete(`product/stock/${_id}`, { noProgress: noProgress });
  },
};

import _ from "underscore";

import config from "../../../config";

import Store from "../../../services/store";
import Global from "../../../services/global";

import AppColorPicker from "../../../components/app-color-picker/app-color-picker.vue";

const payg = "growcify-pay-as-you-grow";

export default {
  name: "NewBusiness",

  created() {
    if (!this.isGD) return Store.redirectToHome();
  },

  components: {
    AppColorPicker,
  },

  mounted() {
    this.step = 1;
    this.fetchCountries();
    this.fetchCommonEnums();
    this.fetchPricing();
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isGD() {
      return this.$store.state.isGD;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    currencyCode() {
      return this.currency || "USD";
    },

    gst() {
      return config.gst;
    },

    pricingPlans() {
      return !this.isMobile || this.bcycle !== "payg" ? this.pricing.plans : [];
    },
  },

  methods: {
    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      this.cfetching = true;
      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
          this.cfetching = false;
        })
        .catch((e) => console.log(e));
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) {
        this.enums = ce;
        return;
      }

      this.cefetching = true;
      return Store.getCommonEnums()
        .then((x) => {
          this.enums = x.data;
          this.$store.state.commonEnums = x.data;
          this.cefetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.cefetching = false;
        });
    },

    fetchPricing() {
      this.pfetching = true;
      return Store.getPricing()
        .then((x) => {
          this.pricing = x.data;
          this.pfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.pfetching = false;
        });
    },

    processPlan() {
      if (!this.bcycle) return;

      this.store.billingCycle = this.bcycle;
      if (["payg", "onetime"].includes(this.bcycle))
        this.store.ownership = "buy";
      else this.store.ownership = "rent";

      const plan = this.store.plan;
      if (plan == payg) {
        const planInfo = this.pricing.payg;
        const price = planInfo[this.currency].onetimeFee;
        const security = planInfo[this.currency].onetimeSecurityDeposit;
        this.store.amount = this.getTaxedAmount(price) + security;

        this.planInfo = planInfo;
      } else {
        const planInfo = this.pricing.plans.find((x) => x._id == plan);
        this.store.amount = this.getTaxedAmount(
          planInfo[this.currency][this.bcycle]
        );
        this.planInfo = planInfo;
      }
    },

    next() {
      if (this.step == 1 && this.$refs.form1.validate()) {
        if (!this.completed.includes(this.step)) this.completed.push(1);
        this.step = 2;
        return;
      } else if (this.step == 2 && this.$refs.form2.validate()) {
        if (!this.completed.includes(this.step)) this.completed.push(2);
        this.processPlan();
        this.step = 3;
        return;
      } else if (this.step == 3 && this.$refs.form3.validate()) this.register();
      else console.log("invalid");
    },

    register() {
      this.processing = true;
      return Store.createNewStore(this.store)
        .then((x) => {
          this.processing = false;
          this.$router.push(`/business/${x.data._id}/`);
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    verifyGSTN() {
      this.gstVerifing = true;
      return Store.verifyGSTN("growcify", this.store.gstn, false)
        .then((x) => {
          if (x && x.data && x.data.success) {
            this.store.name = x.data.taxpayerInfo.lgnm;
          } else {
            this.snackbarText = "Invalid GST Number";
            this.esnackbar = true;
          }
          this.gstVerifing = false;
        })
        .catch(() => {
          this.gstVerifing = false;
        });
    },

    getTaxedAmount(amount) {
      return Global.getTaxAmount(amount);
    },

    getTaxAmount(amount) {
      return parseFloat(((amount * config.gst) / 100).toFixed(2));
    },

    planSelected(plan) {
      this.store.plan = plan._id;
    },
  },

  watch: {
    "store.country"(n) {
      if (n) {
        this.selectedCountry = this.countries.find((x) => x._id == n);
        this.currency =
          this.selectedCountry.currencyCode != "INR" ? "USD" : "INR";
        this.store.currencyCode = this.currency;
        this.processPlan();
      }
    },

    bcycle(n) {
      if (n == "payg") this.store.plan = payg;
      else if (!this.store.plan || this.store.plan == payg)
        this.store.plan = "growcify-premium";
      this.processPlan();
    },

    "store.gstn": _.debounce(function(n) {
      if (n) return this.verifyGSTN();
    }, 750),

    "store.plan"() {
      this.processPlan();
    },
  },

  data() {
    return {
      dialog: true,
      processing: false,

      pfetching: false,
      cfetching: false,
      cefetching: false,
      colorDialog: false,
      gstVerifing: false,

      step: 2,
      completed: [],
      planInfo: false,

      store: {
        plan: "",
        name: "",
        address: "",
        ownership: "",
        googlePlaceId: "",
        appName: "",
        appPackage: "",
        appColor: "#FF0000",
        apps: ["Android", "iOS"],
        amount: 0,
        currencyCode: "",
        transactionId: "",
        paymentStatus: "pending",
      },

      enums: {
        storeCategories: [],
      },
      countries: [],
      pricing: [],

      selectedCountry: { currencyCode: "INR", currencySymbol: "₹" },
      currency: "INR",
      bcycle: "",
      btypes: [
        // {
        //   _id: "monthly",
        //   name: "Monthly",
        // },
        {
          _id: "quarterly",
          name: "Quarterly",
        },
        {
          _id: "yearly",
          name: "Yearly",
        },
        {
          _id: "payg",
          name: "Pay as you Grow",
        },
        {
          _id: "onetime",
          name: "One-time Purchase",
        },
      ],

      validationRules: {
        appName: [(v) => !!v || "App name is required"],
        appPackage: [(v) => !!v || "App package is required"],
        storeName: [(v) => !!v || "Name is required"],
        address: [(v) => !!v || "Address is required"],
        ownership: [(v) => !!v || "Ownership is required"],
        apps: [(v) => !!v.length || "One of apps is required"],
        planRules: [(v) => !!v || "Plan is required"],
        countryRule: [(v) => !!v || "Country is required"],
        categoryRule: [(v) => !!v || "Category is required"],
        cityRule: [(v) => !!v || "City is required"],
        billingRule: [(v) => !!v || "Billing cycle is required"],
        colorRule: [(v) => !!v || "App color is required"],
      },
    };
  },
};

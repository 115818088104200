import Vue from 'vue';
import store from '../store';
import Store from '../services/store';

export default {
  sendSMS(object, noProgress = true) {
    return Vue.axios.post(`sms/send`, object, {noProgress: noProgress});
  },

  sendWhatsappMessage(object, noProgress = true) {
    return Vue.axios.post(`sms/whatsapp/send`, object, {noProgress: noProgress});
  },

  sendEmail(object, noProgress = true) {
    return Vue.axios.post(`email/send`, object, {noProgress: noProgress});
  },

  getRoles(noProgress = true) {
    return Vue.axios.get(`role/list/all`, {noProgress: noProgress});
  },

  refreshCounts(_id, noProgress = true) {
    return Store.getUnreadCounts(_id, noProgress)
      .then(x => store.state.counts = x.data)
      .catch(e => console.log(e))
  }
}
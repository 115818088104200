import Store from '../../services/store';

import moment from 'moment';
import _ from 'underscore';

export default {
  name: 'Headers',

  created() {
    if (!this.canProceed) return Store.redirectToHome();

    this.fetchStores();
    this.fetchTemplates();
    this.fetchHeaders();
    this.fetchHREnums();
    this.fetchSMSHistory(1);
    this.fetchTemplateEnums();
  },

  methods: {
    fetchHREnums() {
      return Store.getHREnums()
        .then(res => this.enums = res.data)
        .catch(e => console.log(e));
    },

    fetchTemplateEnums() {
      return Store.getTemplateEnums()
        .then(res => this.tenums = res.data)
        .catch(e => console.log(e));
    },

    fetchStores() {
      return Store.getAllActiveStores()
        .then(res => this.stores = res.data)
        .catch(e => console.log(e));
    },

    fetchHeaders() {
      this.loading = true;
      return Store.getHRs(false)
        .then(res => {
          this.headers = res.data.map((x, index) => {
            x.index = index + 1 + '.';
            return x;
          });
          this.loading = false;
        })
        .catch(e => { console.log(e); this.loading = false; });
    },

    fetchTemplates() {
      this.tloading = true;
      return Store.getTemplates(false)
        .then(res => {
          this.templates = res.data.map((x, index) => {
            x.index = index + 1 + '.';
            x.storeName = x.store && x.store.name ? x.store.name : '-';
            return x;
          });
          this.tloading = false;
        })
        .catch(e => { console.log(e); this.tloading = false; });
    },

    fetchSMSHistory(pageNumber, itemsPerPage = this.smsHistoryObject.itemsPerPage, keyword = '') {
      this.shloading = true;
      return Store
        .getSMSHistory(pageNumber, itemsPerPage, keyword)
        .then(res => {
          this.smsHistoryObject = res.data;
          this.smsHistory = this.smsHistoryObject.items.map((x, index) => {
            x.index = index + 1 + '.';
            x.mobiled = '+'+x.callingCode+' '+x.mobile;
            x.createdAtd = moment(x.createdAt).format('lll');
            return x;
          });
          this.shloading = this.firstTime = false;
        })
        .catch(e => { console.log(e); this.shloading = false; });
    },

    editItem(item) {
      this.editedIndex = this.headers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    close () {
      this.dialog = this.processing = this.loading = false
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save();
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save();
    },

    save() {
      if (!this.$refs.form.validate() || !this.editedItem._id) return;
      this.processing = true;
      Store.snackbarStart();

      const object = Object.assign({}, this.editedItem);

      let request;
      if (this.editedItem._id) request = Store.editHR(this.editedItem._id, object, false);
      //else request = Store.addHR(object, false);
          
      return request 
        .then(() => {
          this.processing = this.dialog = false;
          Store.snackbarSuccess();
          this.fetchHeaders();
        })
        .catch(e => {
          console.log(e);
          this.processing = this.loading = false;
          Store.snackbarError();
        });
    },

    editTemplateItem(item) {
      this.editedTemplateIndex = this.templates.indexOf(item)
      this.editedTemplateItem = Object.assign({}, item)
      this.templateDialog = true
    },

    deleteTemplateItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteTemplate(this.beingDeleted._id, false)
        .then(() => { this.confirmDialog = this.processing = false; this.fetchTemplates(); })
        .catch(e => { this.confirmDialog = this.processing = false; console.log(e)});
    },

    closeConfirm() {
      this.confirmDialog = false
      this.beingDeleted = {};
    },

    closeTemplate() {
      this.templateDialog = this.processing = this.loading = false
      this.editedTemplateItem = Object.assign({}, this.defaultTemplateItem);
    },

    changeTemplateStatus(item) {
      this.editedTemplateItem = item;
      this.saveTemplate(true);
    },

    saveTemplate(forceSave = false) {
      if (this.$refs.form2 && !this.$refs.form2.validate() && !forceSave) return;
      this.processing = true;
      Store.snackbarStart();

      const object = Object.assign({}, this.editedTemplateItem);

      let request;
      if (this.editedTemplateItem._id) request = Store.editTemplate(this.editedTemplateItem._id, object, false);
      else request = Store.createTemplate(object, false);
          
      return request 
        .then(() => {
          this.processing = this.templateDialog = false;
          Store.snackbarSuccess();
          this.fetchTemplates();
        })
        .catch(e => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            this.error = 'Template ID already exists.'
            setTimeout(() => this.error = '', 2500);
          }
          Store.snackbarError();
          this.processing = this.loading = false;
        });
    },

    copyTemplate(text) {
      navigator.clipboard.writeText(text.trim());
      this.copySingleContentText = 'Copied';
      setTimeout(() => this.copySingleContentText = 'Copy Template', 1500);
    },

    copyAllTemplates() {
      let text = '';
      this.templates.forEach(item => {
        text += item.content+'\r\n\r\n';
      });

      navigator.clipboard.writeText(text.trim());

      this.$store.state.snackbar.text = 'Copied';
      this.$store.state.snackbar.timeout = 3000;
      this.$store.state.snackbar.color = 'success';
      this.$store.state.snackbar.show = true;

      setTimeout(() => { this.$store.state.snackbar.show = false; this.copyAllContentText = 'Copy All Template'; }, 1500);
    },

    processMapping() {
      const filteredItems = this.templates.filter(x => !['lead-new', 'lead-next-steps', 'lead-unreachable', 'lead-old', 'business-renewal-due'].includes(x.templateId) && !x.store);
      this.mappingItems = filteredItems.map(x => {
        return {
          templateId: x.templateId,
          title: x.title,
          content: x.content
        }
      })
      this.mappingDialog = true;
    },

    saveMapping() {
      if (!this.$refs.form3.validate()) return;

      Store.snackbarStart();
      this.processing = true;
      return Store.processMapping(this.mappingItems, false) 
        .then(() => {
          this.processing = this.mappingDialog = false;
          Store.snackbarSuccess();
          this.fetchTemplates();
        })
        .catch(e => {
          console.log(e);
          if (e.response && e.response.status === 400) {
            this.error = 'Template IDs already exists.'
            setTimeout(() => this.error = '', 2500);
          }
          Store.snackbarError();
          this.processing = this.loading = false;
        });
    }
  },

  watch: {
    'editedItem.sender'(nv) {
      if (nv) this.editedItem.sender = this.editedItem.sender.toUpperCase();
    },

    'editedTemplateItem.templateId':  _.debounce(function(nv) {
      if (nv) this.editedTemplateItem.templateId = nv.replace(/\s+/g, '-').toLowerCase().trim();
    }, 500),

    shoptions: {
      handler (options) {
        if (!this.firstTime) this.fetchSMSHistory(options.page, options.itemsPerPage, this.search);
      },
      deep: true,
    },

    'shsearch':  _.debounce(function(nv) {
      this.fetchSMSHistory(1, this.shoptions.itemsPerPage, nv)
    }, 500),

    mappingBusiness(n) {
      if (n) {
        this.mappingItems.forEach(x => {
          x.store = n._id;
          x.content = x.content.replace('Growcify', n.appName);
        });
      }
    },

    mappingBrandName(n) {
      if (n) {
        this.mappingItems.forEach(x => {
          let index = x.content.indexOf('-');
          if (index > 0) x.content = x.content.substring(0, index);
          x.content += '- '+n;
        });
      }
    }
  },

  computed: {
    formTempalteTitle() {
      return this.editedTemplateItem._id ? 'Edit template' : 'Add new template';
    },

    formTempalteMapTitle() {
      return 'Map new business SMS templates'
    },

    canProceed() {
      return (Store.amISuperadmin(this.$store) || Store.amIGrowcifyEmployee(this.$store)) && this.$store.state.isGD
    },

    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  data: () => {
    return {
      headers: [],
      search: '',

      loading: true,
      valid: false,
      dialog: false,
      processing: false,

      error: '',

      enums: {},

      translationModal: false,
      translationField: '',

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true
      },

      confirmDialog: false,
      beingDeleted: {},
      stores: [],

      tenums: {},
      tsearch: '',
      tloading: false,
      templates: [],
      templateDialog: false,
      defaultTemplateItem: { isActive: true, type: 'Transactional' },
      editedTemplateItem: {},
      editedTemplateIndex: -1,

      theaders: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Title', value: 'title' },
        { text: 'Business', value: 'storeName' },
        { text: 'Template ID',  value: 'templateId' },
        { text: 'Content', value: 'content' },
        { text: 'Status', value: 'isActive' },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false}
      ],

      columns: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Business', value: 'store.name' },
        { text: 'Header',  value: 'sender' },
        { text: 'Stage', value: 'stage' },
        { text: 'Activated', value: 'activated' },
        { text: 'Amount', value: 'amount'},
        { text: 'Actions', align: 'center', value: 'actions', sortable: false}
      ],

      smsHistory: [],
      shcolumns: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Mobile', value: 'mobiled' },
        { text: 'Template ID',  value: 'templateId' },
        { text: 'Message', value: 'message', width: '50%' },
        { text: 'Date & Time', value: 'createdAtd' }
      ],

      firstTime: true,
      shloading: false,
      shsearch: '',
      shoptions: {},
      smsHistoryObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10
      },

      copySingleContentText: 'Copy Template',
      copyAllContentText: 'Copy All Templates',

      mappingDialog: false,
      mappingItems: [],
      mappingBusiness: '',
      mappingBrandName: '',

      validationRules: {
        basicRule: [
          v => !!v || 'It is required'
        ],
        statusRule: [
          v => v || v === false || 'It is required'
        ],
        amountRule: [
          v => v >= 0 || 'Enter digits only'
        ]
      }
    }
  }
}
<template>
  <v-dialog v-model="confirmDialog" max-width="400" persistent>
    <v-progress-linear
      v-if="processing"
      indeterminate
      color="primary"
      class="mb-0"
    ></v-progress-linear>
    <v-card>
      <v-card-title>
        <span>Are you sure?</span>
      </v-card-title>

      <v-card-text>
        Are you sure you want to delete
        <b>{{
          beingDeleted.appName ||
            beingDeleted.name ||
            beingDeleted.mobile ||
            beingDeleted.named ||
            beingDeleted.code ||
            beingDeleted.title ||
            beingDeleted.slot ||
            beingDeleted.module ||
            (beingDeleted.key
              ? beingDeleted.key + " (" + beingDeleted.languageName + ")"
              : "") ||
            beingDeleted.invoiceNumber ||
            beingDeleted._id
        }}</b
        >?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="error darken-1"
          :disabled="processing"
          text
          @click="$parent.closeConfirm"
          >No</v-btn
        >
        <v-btn
          color="green darken-1"
          :disabled="processing"
          text
          @click="$parent.finalDelete"
          >Yes</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
export default {
  props: ["confirmDialog", "beingDeleted", "processing"],
};
</script>

import _ from "underscore";
import moment from "moment";

import Store from "../../../services/store";
import FileUpload from "../../../services/file-upload";
import router from "../../../router";
import config from "../../../config";
import { getS3URL } from "../../../helpers/misc";

const todayDate = moment().format("YYYY-MM-DD");
const oneWeekBefore = moment()
  .subtract(7, "d")
  .format("YYYY-MM-DD");

export default {
  name: "Vendors",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Vendors") || this.$store.state.activeStore.isVendor) {
      return router.push("/business/" + this._id);
    }
    if (!this.doIHaveAccess("actions")) {
      this.headers = this.headers.filter((x) => x.text !== "Actions");
    }

    this.fenums = true;
    const p1 = this.fetchVendors();
    const p2 = this.fetchCommonEnums();
    const p3 = this.fetchCountries();
    const p4 = this.fetchVendorEnums();
    const p5 = this.fetchVendorLocations();

    return Promise.all([p1, p2, p3, p4, p5])
      .then(() => (this.fenums = false))
      .catch(() => (this.fenums = false));
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchVendors() {
      this.loading = true;
      return Store.getVendors(this._id, false)
        .then((res) => {
          this.vendors = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            x.statusd = x.isActive ? "Active" : "Inactive";

            x.stateName = "-";
            if (x.state) {
              x.stateName = x.state.name;
              x.state = x.state._id;
            }

            x.cityName = "-";
            if (x.city) {
              x.cityName = x.city.name;
              x.city = x.city._id;
            }

            return x;
          });
          this.allVendors = JSON.parse(JSON.stringify(this.vendors));
          this.loading = false;
        })
        .catch((e) => console.log(e));
    },

    fetchVendorLocations() {
      return Store.getVendorLocations(this._id, false)
        .then((x) => (this.vendorLocations = x.data || []))
        .catch(() => {});
    },

    fetchVendorEnums() {
      return Store.getVendorEnums(this._id)
        .then((x) => (this.enums = x.data))
        .catch((e) => console.log(e));
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.cenums = ce);

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => console.log(e));
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
        })
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.vendors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.businessAddress) {
        this.selectedPlace = {
          description: this.editedItem.businessAddress,
          placeId: this.editedItem.placeId,
        };
      }

      if (!this.editedItem.callingCode) {
        this.editedItem.callingCode = this.$store.state.activeStore.country.callingCode;
      }

      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteVendor(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchVendors();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    saveItem(item) {
      this.editedItem = item;
      this.save(false, true, true);
    },

    save(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedItem);

      if (this.editedItem._id)
        request = Store.editVendor(this.editedItem._id, object, false);
      else request = Store.addVendor(this._id, object, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          this.processing = this.editedItem.processing = false;
          this.fetchVendors();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          Store.snackbarError();
          if (e.response && e.response.status === 400 && e.response.data) {
            if (e.response.data.error == "already-exists-owner")
              this.$store.state.snackbar.text =
                "Number or email is associated with the owner";
            if (e.response.data.error == "already-exists-vendor")
              this.$store.state.snackbar.text = "This vendor already exists";
            if (e.response.data.error == "already-exists-employee")
              this.$store.state.snackbar.text =
                "This number or email already exists in employees";
            if (e.response.data.error == "already-exists-vendor-taxcode")
              this.$store.state.snackbar.text =
                "This GSTN/Tax Code already exists";
          }
          this.processing = this.loading = this.editedItem.processing = false;
        });
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      if (file && file["type"].split("/")[0] === "image") {
        this.editedItem.type = file["type"].split("/")[0];
        return true;
      }
      return false;
    },

    getURL(data) {
      if (data && data.split(".")[1] === "png") {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError() {
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.editedItem.file = "";
      this.editedItem.file = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(this._id, "vendors", file, this.editedItem.type)
        .then((x) => {
          this.editedItem.file = x.data.file;
          this.uploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    getRefDoc() {
      return this.$refs.inputDocUpload;
    },

    pickFileDoc() {
      this.getRefDoc().click();
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    isValidDocument(file) {
      if (!file) return false;
      const type = file["type"].split("/")[0];
      const type2 = file["type"].split("/")[1];
      if (
        file &&
        (this.supportedFormats.includes(type) ||
          this.supportedFormats.includes(type2))
      ) {
        this.fileObject = {
          name: file["name"],
          size: file["size"],
          type: this.supportedFormats.includes(type) ? type : type2,
        };
        return true;
      }
      return false;
    },

    getURLDoc(data) {
      if (!data) return data;
      const ext = data.split(".")[1];
      if (data && (this.supportedFormats.includes(ext) || ext == "png")) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidDocError() {
      this.$store.state.snackbar.text = "Unsupported File Format";
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelectedDoc() {
      const file = this.getRefDoc().files[0];
      if (!this.isValidDocument(file)) return this.invalidDocError();

      this.fileObject._id = "";
      this.fileObject._id = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(this._id, "vendors", file, this.fileObject.type)
        .then((x) => {
          if (!this.editedItem.files) this.editedItem.files = [];
          this.fileObject._id = x.data.file;
          this.editedItem.files.push(this.fileObject);
          this.uploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    openDocument(item) {
      window.open(this.getURLDoc(item._id), "_blank");
    },

    removeDocument(item) {
      const files = this.editedItem.files.filter((x) => x._id != item._id);
      this.editedItem.files = files;
      this.save(false, false);
    },

    saveDates(dates) {
      this.$refs.menu.save(dates);
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false, false);
    },

    getSequenceItems() {
      const arr = [1];
      for (let i = 0; i < this.vendors.length; i++) arr.push(i + 2);
      return arr;
    },

    handleTags() {
      const allTags = this.editedItem.tags;
      const newArray = allTags.join(", ").split(",");
      this.editedItem.tags = newArray && newArray[0] ? newArray : [];
    },

    handleDescriptors() {
      const allDes = this.editedItem.descriptors;
      let newArray = allDes.join(", ").split(",");

      if (newArray && newArray.length > 3) {
        newArray = newArray.slice(0, 3);
      }

      this.editedItem.descriptors = newArray && newArray[0] ? newArray : [];
    },

    removeTag(item) {
      const newArray = this.editedItem.tags.filter((x) => x != item);
      this.editedItem.tags = newArray;
    },

    removeDescriptor(item) {
      const newArray = this.editedItem.descriptors.filter((x) => x != item);
      this.editedItem.descriptors = newArray;
    },

    fetchStatesByCountry(country = this.editedItem.country) {
      this.sfetching = true;
      return Store.getStatesByCountry(country, false)
        .then((x) => {
          this.states = x.data;
          this.sfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.sfetching = false;
        });
    },

    fetchCitiesByState(state = this.editedItem.state) {
      this.cfetching = true;
      return Store.getCitiesByState(state, false)
        .then((x) => {
          this.cities = x.data;
          this.cfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.cfetching = false;
        });
    },

    filterVendors() {
      this.vendors = this.allVendors;
      if (this.country)
        this.vendors = this.allVendors.filter((x) => x.country == this.country);
      if (this.state)
        this.vendors = this.allVendors.filter((x) => x.state == this.state);
      if (this.city)
        this.vendors = this.allVendors.filter((x) => x.city == this.city);
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    fetchSummary() {
      this.summaryFetching = true;
      const _ids = this.selectedVendors.map((x) => x.business);
      const object = { _ids: _ids, dates: this.calDates };

      return Store.getVendorsSummary(this._id, object, false)
        .then((x) => {
          if (x.data) {
            x.data.forEach((x) => {
              let vendor = this.selectedVendors.find(
                (v) => v.business == x._id
              );
              delete x._id;
              vendor = Object.assign(vendor, x);
            });
          }
          this.summaryFetched = true;
          this.summaryFetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.summaryFetching = false;
        });
    },

    exportOrders(ids) {
      let endpoint = `${
        process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
      }order/${this.isVendor ? "export-vendor" : "export"}/${this._id}`;
      endpoint =
        endpoint +
        "?x-request-user-agent=application/admin-app&token=" +
        this.$store.state.token;
      endpoint = endpoint + "&_ids=" + ids;
      return window.open(endpoint, "_blank");
    },

    countryText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add new vendor" : "Edit vendor";
    },

    covidCategory() {
      return Store.covidCategory(this.$store);
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    store() {
      return this.$store.state.activeStore;
    },

    numberMinLength() {
      let length = this.store.country.minNumberLength || 10;
      const cc = this.editedItem.callingCode;
      const cinfo = this.countries.find((x) => x.callingCode == cc);
      if (cinfo) length = cinfo.minNumberLength || 5;

      return length;
    },

    disableAdd() {
      return (
        this.store.plan == "growcify-basic" ||
        (this.store.plan == "growcify-premium" && this.vendors.length == 10)
      );
    },

    mobileRule() {
      return [
        (v) =>
          (!!v && v.length >= this.numberMinLength) || "Must be a valid number",
      ];
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  watch: {
    input: _.debounce(function(val) {
      if (
        val === this.lastInput ||
        !val ||
        val == this.selectedPlace.description
      ) {
        return;
      }

      let country = this.$store.state.activeStore.country.countryCode;
      if (this.editedItem.country) {
        const cinfo = this.countries.find(
          (x) => x._id == this.editedItem.country
        );
        if (cinfo) country = cinfo.countryCode;
      }

      this.isLoading = true;
      return Store.searchPlaces(this._id, val, true, country)
        .then((res) => {
          this.places = res.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }, 500),

    selectedPlace(val) {
      if (!val || !val.placeId || !val.description) return;

      this.editedItem.placeId = val.placeId;
      this.editedItem.businessAddress = val.description;
      this.editedItem.placeDescription = val.description;
    },

    "editedItem.country"(n) {
      if (n) this.fetchStatesByCountry(n);
    },

    "editedItem.state"(n) {
      if (n) this.fetchCitiesByState();
    },

    country(n) {
      if (n) this.fetchStatesByCountry(n);
      this.filterVendors();
    },

    state(n) {
      if (n) this.fetchCitiesByState(n);
      this.filterVendors();
    },

    city() {
      this.filterVendors();
    },

    selectedVendors(n) {
      if (n && n.length) this.fetchSummary();
    },

    calModal(n) {
      if (!n && this.calDates && this.calDates.length) this.fetchSummary();
    },
  },

  data: () => {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      _id: "",
      vendors: [],
      allVendors: [],
      enums: {},
      search: "",
      loading: true,
      valid: false,
      dialog: false,
      processing: false,
      supportedFormats: ["image", "pdf", "xls", "xsls", "doc", "docx"],

      error: "",

      menu: false,
      cenums: {},

      fenums: false,

      translationModal: false,
      translationField: "",

      dates: [],

      editedIndex: -1,
      editedItem: {
        dates: [],
      },
      defaultItem: {
        dates: [],
        isActive: true,
        isPinned: false,
        isServiceBusiness: false,
        uiType: "Grouped",
        descriptors: [],
        listInApp: true,
        dashboardAccess: true,
      },

      confirmDialog: false,
      beingDeleted: {},
      rowItems: config.rowItems,

      uploading: false,
      showErr: false,

      input: null,
      lastInput: null,
      places: [],
      isLoading: false,
      selectedPlace: {},
      cfetching: false,
      sfetching: false,

      vendorLocations: [],

      countries: [],
      states: [],
      cities: [],

      city: "",
      state: "",
      country: "",

      selectedVendors: [],
      calModal: false,
      calDates: [oneWeekBefore, todayDate],
      summaryFetching: false,
      summaryFetched: false,

      fileObject: {},

      headers: [
        {
          text: "# - Quickers",
          align: "start",
          value: "index",
          width: "170px",
        },
        { text: "Business", value: "businessName" },
        { text: "Owner", value: "name" },
        { text: "Mobile", value: "mobile" },
        //{ text: 'Email', value: 'email' },
        //{ text: "State", value: "stateName" },
        { text: "City", value: "cityName" },
        //{ text: 'Category', value: 'businessCategory' },
        { text: "Listing", value: "listInApp" },
        //{ text: "Access", value: "dashboardAccess" },
        { text: "Status", value: "statusd" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        datesRule: [(v) => v.length === 2 || "Both dates are required"],
        discountRule: [(v) => v > 0 || "Enter valid discount"],
        orderAmountRule: [
          (v) =>
            v === "" ||
            v === undefined ||
            v > 0 ||
            v === 0 ||
            "Enter valid amount",
        ],
        mobileRule: [
          (v) => (!!v && v.length === 10) || "Must be a valid number",
        ],
        emailRule: [
          (v) => (!!v && emailregex.test(v)) || "Must be a valid email",
        ],
        numberRule: [(v) => (!v && v != 0) || v >= 0 || "Must be valid number"],

        addressRule: [(v) => (!!v && !!v.description) || "It is required"],
        countryRule: [(v) => !!v || "Country is required"],
      },
    };
  },
};

import _ from "underscore";

import Store from "../../services/store";
import Inventory from "../../services/inventory";

export default {
  name: "GroupForm",

  props: ["_id", "loading", "groups", "editedItem", "groupUpdated"],

  methods: {
    fetchCategories() {
      let catRequest = Inventory.getCategories(this._id, false);
      if (this.isVendor)
        catRequest = Inventory.getVendorSubscribedCategories(this._id, false);

      this.cloading = true;
      return catRequest
        .then((res) => {
          this.categories = res.data;
          this.cloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.cloading = false;
        });
    },

    fetchVendors() {
      this.vloading = true;
      return Store.getVendors(this._id, false)
        .then((res) => {
          this.vendors = res.data;
          this.vloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.vloading = false;
        });
    },

    fetchBrands() {
      this.bloading = true;
      return Inventory.getBrands(this._id, false)
        .then((x) => {
          this.brands = x.data;
          this.bloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.bloading = false;
        });
    },

    fetchEnums() {
      return Store.getGroupsEnums(this._id, false)
        .then((x) => (this.enums = x.data))
        .catch((e) => console.log(e));
    },

    close() {
      this.$store.state.groupModel = this.processing = false;
    },

    saveGroup() {
      if (this.$refs.form && !this.$refs.form.validate()) return;
      Store.snackbarStart();
      this.processing = true;

      let request;
      const object = Object.assign({}, this.editedItem);
      if (object.products)
        object.products = object.products.map((x) => x._id || x);

      if (this.editedItem._id)
        request = Store.editGroup(this.editedItem._id, object, false);
      else request = Store.createGroup(this._id, object, false);

      return request
        .then(() => {
          this.close();
          this.processing = false;
          Store.snackbarSuccess();
          this.$emit("update:groupUpdated", true);
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    productSelected() {
      this.lastSearched = this.selectedProduct.name;
      if (
        this.editedItem.products.find((x) => x._id === this.selectedProduct._id)
      )
        return;

      this.selectedProduct.index = this.editedItem.products.length;
      this.editedItem.products.push(this.selectedProduct);
      this.selectedProduct = {};
      this.searchInput = "";
    },

    productText(item) {
      return item.name + " (" + item.size + item.unit + ")";
    },

    removeItem(item) {
      this.editedItem.products = this.editedItem.products.filter(
        (x) => x._id != item._id
      );
    },

    querySelections(keyword) {
      this.lastSearched = keyword;
      let thisRequest = ++this.lastRequest;
      this.outstandingRequests++;
      this.isSearching = true;

      let Request;
      const isCWMV = Store.isMultivendorUI(["categories"]);
      if (!isCWMV) {
        Request = Store.searchProducts(this._id, keyword, false);
      } else {
        Request = Store.searchMVProducts(this._id, keyword, false);
      }

      return Request.then((x) => {
        this.outstandingRequests--;
        if (thisRequest < this.lastResponse) return;
        this.lastResponse = thisRequest;

        this.products = x.data;
        this.isSearching = false;
      }).catch(() => {
        this.outstandingRequests--;
        this.isSearching = false;
      });
    },

    ofText(item) {
      return this.$options.filters.firstLetterUppercase(item);
    },

    getSequenceItems() {
      const arr = [1];
      for (let i = 0; i < this.groups.length; i++) arr.push(i + 2);
      return arr;
    },
  },

  computed: {
    isVendor() {
      return this.store.isVendor;
    },

    formTitle() {
      return this.editedItem._id ? "Edit group" : "Create new group";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    store() {
      return this.$store.state.activeStore;
    },
  },

  watch: {
    "$store.state.groupModel"(n) {
      if (n) {
        if (!this.enums.of) this.fetchEnums();
        if (this.editedItem.products) {
          this.editedItem.products.forEach((x, i) => (x.index = i));
        }
      }
    },

    "editedItem.of"(n) {
      if (n == "categories" && !this.categories.length) this.fetchCategories();
      else if (n == "vendors" && !this.vendors.length) this.fetchVendors();
      else if (n == "brands" && !this.brands.length) this.fetchBrands();
    },

    searchInput: _.debounce(function(newVal, oldVal) {
      const array = newVal ? newVal.split("(") : [];
      if (
        newVal &&
        newVal != oldVal &&
        newVal != this.lastSearched &&
        array.length === 1
      )
        this.querySelections(newVal);
    }, 500),
  },

  data() {
    return {
      processing: false,
      cloading: false,
      vloading: false,
      bloading: false,

      enums: {},

      brands: [],
      vendors: [],
      products: [],
      categories: [],

      searchInput: "",
      lastSearched: "",
      lastRequest: 0,
      lastResponse: 0,
      isSearching: false,
      selectedProduct: {},
      outstandingRequests: 0,

      pheaders: [
        { text: "#", align: "start", value: "index", sortable: false },
        { text: "Name", value: "name" },
        { text: "Size", value: "size", sortable: false },
        { text: "Status", value: "isActive", sortable: false },
        { text: "Remove", value: "actions", sortable: false, align: "center" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => !!v || v === false || "It's is required"],
      },
    };
  },
};

//import Vue from 'vue'
import Inventory from "../../../services/inventory";
//import FileUpload from '../../../services/file-upload';
import Store from "../../../services/store";
import router from "../../../router";
import config from "../../../config";

import convert from "convert-units";

import Barcodes from "../../../components/barcodes/barcodes.vue";
import ProductForm from "../../../components/product-form/product-form.vue";
import MassTranslate from "../../../components/mass-translate/mass-translate.vue";
import CurrentStockDialog from "../../../components/current-stock-dialog/current-stock-dialog.vue";
import BulkUpdateProducts from "../../../components/bulk-update-products/bulk-update-products.vue";
import _ from "underscore";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "Products",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Products"))
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions")) {
      this.headers = this.headers.filter((x) => x.text !== "Actions");
      this.defaultHeaders = this.defaultHeaders.filter(
        (x) => x.text !== "Actions"
      );
      this.availableHeaders = this.availableHeaders.filter(
        (x) => x.text !== "Actions"
      );
    }

    this.fetchProducts();
    // for (let p = 0; p < 6; p++) this.defaultItem.photos.push(this.photoItem);

    //set default columns
    const savedColumns = localStorage["productsColumns"];
    if (savedColumns) this.headers = JSON.parse(savedColumns);
  },

  components: {
    Barcodes,
    ProductForm,
    MassTranslate,
    CurrentStockDialog,
    BulkUpdateProducts,
  },

  methods: {
    resetPhotos() {
      // this.defaultItem.photos.forEach((x) => (x._id = undefined));
      this.defaultItem.photos = [];
    },

    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    doIHaveAdminAccess() {
      return Store.doIHaveAdminAccess(this.$store);
    },

    fetchProducts() {
      const p1 = this.fetchProductsOnly(1);

      let catRequest = Inventory.getCategories(this._id, false);
      let brandRequest = Inventory.getBrands(this._id, false);
      if (this.$store.state.activeStore.isVendor) {
        catRequest = Inventory.getVendorSubscribedCategories(this._id, false);
        brandRequest = Inventory.getVendorBrands(this._id, false);
      }

      const p2 = catRequest
        .then((res) => (this.categories = res.data))
        .catch((e) => console.log(e));

      const p3 = Inventory.getProductEnums(this._id)
        .then((res) => (this.enums = res.data))
        .catch((e) => console.log(e));

      const p4 = brandRequest
        .then((res) => {
          this.brands = res.data;
          if (this.brands.length) {
            this.filterBy.push({ divider: true });
            this.filterBy.push({ header: "Brands" });
            this.brands.forEach((x) => {
              this.filterBy.push({ value: "brand:" + x._id, text: x.name });
            });
          }
        })
        .catch((e) => console.log(e));

      return Promise.all([p1, p2, p3, p4])
        .then(() => (this.loading = false))
        .catch((e) => console.log(e));
    },

    getTabID(index) {
      return this.tabs[index]._id;
    },

    fetchProductsOnly(
      pageNumber,
      itemsPerPage = this.productsObject.itemsPerPage,
      keyword = ""
    ) {
      this.loading = true;
      const tabID = this.getTabID(this.tab);
      return Inventory.getProducts(
        this._id,
        pageNumber,
        itemsPerPage,
        keyword,
        tabID,
        false
      )
        .then((res) => {
          this.productsObject = res.data;
          this.products = []; //reset and let it update
          this.products = this.productsObject.items.map((x, index) => {
            x.categoryName = x.category?.name || "-";
            x.category = x.category?._id || undefined;
            x.index =
              pageNumber === 1
                ? index + 1 + "."
                : itemsPerPage * (pageNumber - 1) + index + 1 + ".";
            x.sizeName = x.size + x.unit;

            x.brandName = "-";
            if (x.productBrand && x.productBrand.name)
              x.brandName = x.productBrand.name;
            else if (x.brand) x.brandName = x.brand;

            x.createdByName =
              x.createdBy && x.createdBy.name ? x.createdBy.name : "-";
            if (x.createdByName != "-")
              x.createdByName =
                x.createdByName.split(" ")[0] || x.createdByName;

            x.ooPhotos = x.photos;
            x.image =
              x.photos && x.photos.length
                ? !this.isVideo(x.photos[0])
                  ? x.photos[0]
                  : ""
                : "";

            // x.photos = x.photos.map((x) => {
            //   return {
            //     _id: x || undefined,
            //     uploading: false,
            //     uploadProgress: 0,
            //     sheet: false,
            //   };
            // });

            // const totalEmpty = 6 - x.photos.length;
            // for (let p = 0; p < totalEmpty; p++) x.photos.push(this.photoItem);
            return x;
          });

          const { sortBy, sortDesc } = this.options;
          if (sortBy.length === 1 && sortDesc.length === 1) {
            this.products = this.products.sort((a, b) => {
              const sortA = a[sortBy[0]];
              const sortB = b[sortBy[0]];

              if (sortDesc[0]) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            });
          }

          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    isVideo(item) {
      if (!item) return;
      const array = item.split(".");
      return array.includes("mp4");
    },

    editItem(item) {
      if (item._id) {
        if (
          item.wholesalePrice ||
          item.weight ||
          item.length ||
          item.width ||
          item.height
        )
          item.expanded = true;
        else item.expanded = false;

        item.offerExpanded = item.discount ? true : false;
      }

      if (!item.tags) item.tags = [];
      if (!item.comboItems) item.comboItems = JSON.parse(JSON.stringify([]));
      else {
        item.comboItems = item.comboItems.map((x, i) => {
          if (x._id._id) {
            const obj = x._id;
            obj.deductQty = x.deductQty;
            x = obj;
          }
          x.index = i + 1 + ".";
          return x;
        });
      }
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(Object.assign({}, item)));
      this.dialog = true;
      this.showActiveProductModal = false;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Inventory.deleteProduct(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchProductsOnly(
            this.productsObject.pageNumber,
            this.options.itemsPerPage,
            this.search
          );
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    duplicateItem(item) {
      this.duplicateDialog = true;
      this.beingDuplicated = JSON.parse(
        JSON.stringify(Object.assign({}, item))
      );
    },

    closeDuplicate() {
      this.duplicateDialog = this.duplicatePromptDialog = this.creatingVariant = this.removingAsVariant = false;
      this.beingDuplicated = {};
    },

    nextDuplicate() {
      this.duplicateDialog = false;

      delete this.beingDuplicated._id;
      delete this.beingDuplicated.__v;
      delete this.beingDuplicated.createdAt;
      delete this.beingDuplicated.updatedAt;
      delete this.beingDuplicated.barcodeID;
      this.beingDuplicated.name = this.beingDuplicated.name + " (Copy)";
      this.duplicatePromptDialog = true;
    },

    finalDuplicate() {
      if (
        !this.beingDuplicated.name ||
        (this.$refs.dupRef && !this.$refs.dupRef.validate())
      )
        return;
      this.editedItem = this.beingDuplicated;
      this.save(true, true, true);
    },

    createVariant(item) {
      this.removingAsVariant = false;
      this.duplicateDialog = this.creatingVariant = true;
      this.beingDuplicated = JSON.parse(
        JSON.stringify(Object.assign({}, item))
      );
    },

    removeAsVariant(item) {
      this.duplicateDialog = this.removingAsVariant = true;
      this.beingDuplicated = JSON.parse(
        JSON.stringify(Object.assign({}, item))
      );
    },

    nextConvertVariant() {
      this.editedItem = this.beingDuplicated;
      this.editedItem.parent = undefined;
      this.save(false, true, true);
    },

    nextCreateVariant() {
      this.duplicateDialog = false;

      this.beingDuplicated.parent = this.beingDuplicated._id;
      delete this.beingDuplicated._id;
      delete this.beingDuplicated.__v;
      delete this.beingDuplicated.createdAt;
      delete this.beingDuplicated.updatedAt;
      delete this.beingDuplicated.barcodeID;
      this.duplicatePromptDialog = true;
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      setTimeout(
        () => (this.editedItem = Object.assign({}, this.defaultItem)),
        500
      );
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true, true);
    },

    saveItem(item) {
      this.editedItem = item;
      this.save(false, true, true);
    },

    save(closeModal = true, fetchProducts = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.ec.saving = true;
      if (!this.ec._id) this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedItem);
      object.photos = object.photos.map((x) => (x && x._id ? x._id : x));
      object.photos = object.photos.filter(
        (x) => x && x != null && x != "null"
      );

      if (!object.parent || object.parent === "") delete object.parent;
      if (!object.category || object.category === "") delete object.category;
      if (object.comboItems) {
        object.comboItems = object.comboItems.map((x) => {
          return { _id: x._id, deductQty: x.deductQty };
        });
      }

      if (this.editedItem._id) {
        request = Inventory.editProduct(this.editedItem._id, object, false);
      } else {
        request = Inventory.addProduct(this._id, object, false);
      }

      return request
        .then(() => {
          this.processing = this.loading = this.ec.saving = this.editedItem.processing = this.colorDialog = false;

          if (closeModal) this.close();

          if (!this.ec._id && fetchProducts && this.search) {
            this.fetchProductsOnly(
              this.options.page,
              this.options.itemsPerPage,
              this.search
            );
          } else if (!this.ec._id && fetchProducts) {
            this.fetchProductsOnly(this.productsObject.pageNumber);
          }

          this.resetColumn();
          this.resetPhotos();
          this.closeDuplicate();
          //this.closeConfirmPhoto();
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = this.ec.saving = this.editedItem.processing = false;
          this.resetColumn();
          this.closeDuplicate();
          //this.closeConfirmPhoto();
          Store.snackbarError();
        });
    },

    editColumn(_id, value, index, type) {
      this.ec._id = _id;
      this.ec.value = value;
      this.ec.index = index;
      this.ec.type = type;
    },

    saveCloumn() {
      if (!this.ec.value) return;

      this.editedItem = this.products.find((x) => x._id === this.ec._id);
      if (this.ec.type === "price") this.editedItem.price = this.ec.value;
      if (this.ec.type === "quantity") this.editedItem.quantity = this.ec.value;
      if (this.ec.type === "name") this.editedItem.name = this.ec.value;

      this.save();
    },

    resetColumn() {
      this.ec = {
        _id: "",
        value: "",
        index: 0,
        type: "",
        saving: false,
      };
    },

    updateDiscountOffer() {
      if (!this.editedItem.discountBy || !this.editedItem.discount) return;
      if (this.editedItem.discountBy === "amount")
        this.editedItem.discountedPrice =
          this.editedItem.price - this.editedItem.discount;
      else
        this.editedItem.discountedPrice = parseFloat(
          (
            this.editedItem.price -
            (this.editedItem.price * this.editedItem.discount) / 100
          ).toFixed(2)
        );
    },

    saveCloumnItem(item) {
      this.editedItem = item;
      this.updateDiscountOffer();
      this.save(false, true, true);
    },

    removeSelected(item) {
      this.selected = this.selected.filter((x) => x._id != item._id);
    },

    massUpdate() {
      const massUpdates = {};
      Object.keys(this.massItem).forEach((x) => {
        if (x) {
          if (x === "size") massUpdates.size = this.massItem.size;
          else if (x === "parent") massUpdates.parent = this.massItem.parent;
          else if (x === "productBrand")
            massUpdates.productBrand = this.massItem.productBrand;
          else if (x === "unit") massUpdates.unit = this.massItem.unit;
          else if (x === "price" && this.massItem.price >= 0)
            massUpdates.price = parseInt(this.massItem.price);
          else if (x === "quantity" && this.massItem.quantity >= 0)
            massUpdates.quantity = parseInt(this.massItem.quantity);
          else if (x === "orderCap" && this.massItem.orderCap)
            massUpdates.orderCap = parseInt(this.massItem.orderCap);
          else if (x === "isActive")
            massUpdates.isActive = this.massItem.isActive;
          else if (x === "isComingSoon")
            massUpdates.isComingSoon = this.massItem.isComingSoon;
          else if (x === "inStock") massUpdates.inStock = this.massItem.inStock;
          else if (x === "description" && this.massItem.description)
            massUpdates.description = this.massItem.description;
          else if (x === "category" && this.massItem.category)
            massUpdates.category = this.massItem.category;
        }
      });

      if (!Object.keys(massUpdates).length) return;

      const massIds = this.selected.map((x) => x._id);
      this.massprocessing = true;
      return Inventory.massUpdate(this._id, {
        massIds: massIds,
        massUpdates: massUpdates,
      })
        .then(() => {
          this.massItem = {};
          this.selected = [];
          this.massprocessing = this.massdialog = false;
          this.fetchProductsOnly(
            this.productsObject.pageNumber,
            this.options.itemsPerPage,
            this.search
          );
        })
        .catch((e) => {
          console.log(e);
          this.massprocessing = false;
          this.resetColumn();
        });
    },

    massDeleteInit() {
      this.confirmMassDelete = true;
    },

    massDeleteProcess() {
      const massUpdates = { deleted: true };
      const massIds = this.selected.map((x) => x._id);
      this.massprocessing = true;
      return Inventory.massUpdate(this._id, {
        massIds: massIds,
        massUpdates: massUpdates,
      })
        .then(() => {
          this.massItem = {};
          this.selected = [];
          this.massprocessing = this.confirmMassDelete = false;
          this.fetchProductsOnly(1, this.options.itemsPerPage, this.search);
        })
        .catch((e) => {
          console.log(e);
          this.massprocessing = false;
          this.resetColumn();
        });
    },

    downloadSampleCSV() {
      window.open(
        config.grocify.website + config.grocify.sampleProductsCSV,
        "_system"
      );
    },

    processImportCSV() {
      this.$store.state.csvConfirmDialog = false;
      return router.push("/business/" + this._id + "/products/import");
    },

    exportProducts() {
      let endpoint = `${
        process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
      }product/export/${this._id}`;
      return window.open(
        endpoint +
          "?x-request-user-agent=application/admin-app&token=" +
          this.$store.state.token,
        "_blank"
      );
    },

    // eslint-disable-next-line
    columnsChanged(item) {
      const nh = [];
      this.availableHeaders.forEach((x) => {
        if (this.headers.find((i) => i.value == x.value)) nh.push(x);
      });
      this.headers = nh;
    },

    refreshProducts() {
      this.fetchProductsOnly(
        this.options.page,
        this.options.itemsPerPage,
        this.search
      );
    },

    saveColumns() {
      localStorage["productsColumns"] = JSON.stringify(this.headers);
      this.saveColumnsText = "Saved";
      setTimeout(() => {
        this.saveColumnsText = "Save";
        this.toggleColumnSelect = !this.toggleColumnSelect;
      }, 250);
    },

    resetColumns() {
      this.headers = this.defaultHeaders;
      localStorage.removeItem("productsColumns");
    },

    parentText(item) {
      return item.name + " (" + item.size + item.unit + ")";
    },

    // getPhotoURL(data, original = false) {
    //   if (!data) return;
    //   const array = data.split(".");
    //   if (
    //     data &&
    //     !array.includes(config.growcifyMasterInventory) &&
    //     array[1] === "png"
    //   ) {
    //     if (original) data = data.replace("resized-", "");
    //     return `https://${this._id}.s3.amazonaws.com/${data}`;
    //   }
    //   return data;
    // },

    getURL(data, original = false) {
      if (
        data &&
        (data.split(".")[1] === "png" || data.split(".")[1] === "mp4")
      ) {
        if (original) data = data.replace("resized-", "");
        return getS3URL(this._id, data);
      }
      return data;
    },

    viewInfo(item) {
      this.showActiveProductModal = true;
      this.activeProduct = item;
    },

    changeColor(item) {
      if (item.color) this.selectedColor = item.color;
      this.editedItem = item;
      this.colorDialog = true;
    },

    selectColor() {
      if (typeof this.selectedColor === "string") {
        this.editedItem.color = this.selectedColor;
      } else {
        this.editedItem.color = this.selectedColor.hex;
      }
      this.saveCloumnItem(this.editedItem);
    },
  },

  watch: {
    "selected.length"(n) {
      if (!n) this.massdialog = this.confirmMassDelete = false;
    },

    options: {
      handler(options) {
        if (!this.loading)
          this.fetchProductsOnly(
            options.page,
            options.itemsPerPage,
            this.search
          );
      },
      deep: true,
    },

    search: _.debounce(function(nv) {
      this.fetchProductsOnly(1, this.options.itemsPerPage, nv);
    }, 500),

    "editedItem.dimensionUnit"(n, o) {
      if (n && o) {
        if (this.editedItem.width)
          this.editedItem.width = convert(this.editedItem.width)
            .from(o)
            .to(n);
        if (this.editedItem.length)
          this.editedItem.length = convert(this.editedItem.length)
            .from(o)
            .to(n);
        if (this.editedItem.height)
          this.editedItem.height = convert(this.editedItem.height)
            .from(o)
            .to(n);
      }
    },

    "editedItem.parent"(n) {
      if (n) {
        const np = this.products.find((x) => x._id === n);
        if (np) this.editedItem.category = np.category;
      }
    },

    filterBySelected(n) {
      if (!n) this.options.itemsPerPage = 10;
      this.search = n;
    },

    filterBySelectedCategory(n) {
      if (n) this.search = "category:" + n;
      else {
        this.options.itemsPerPage = 10;
        this.search = "";
      }
    },

    translationModified(n) {
      if (n) {
        this.selected = [];
        if (this.search)
          this.fetchProductsOnly(
            this.options.page,
            this.options.itemsPerPage,
            this.search
          );
        else {
          this.fetchProductsOnly(
            this.productsObject.pageNumber,
            this.options.itemsPerPage,
            this.search
          );
        }
      }
      this.translationModified = false;
    },

    bulkUpdated(n) {
      if (n) {
        this.selected = [];
        if (this.search)
          this.fetchProductsOnly(
            this.options.page,
            this.options.itemsPerPage,
            this.search
          );
        else {
          this.fetchProductsOnly(
            this.productsObject.pageNumber,
            this.options.itemsPerPage,
            this.search
          );
        }
      }
      this.bulkUpdated = false;
    },

    "beingDuplicated.price"(n) {
      if (n && this.beingDuplicated.discountBy) {
        if (this.beingDuplicated.discountBy === "amount")
          this.beingDuplicated.discountedPrice =
            this.beingDuplicated.price - this.beingDuplicated.discount;
        else
          this.beingDuplicated.discountedPrice = parseFloat(
            (
              this.beingDuplicated.price -
              (this.beingDuplicated.price * this.beingDuplicated.discount) / 100
            ).toFixed(2)
          );
      } else if (
        this.beingDuplicated.discountBy &&
        this.beingDuplicated.discount
      )
        this.beingDuplicated.discountedPrice = this.beingDuplicated.price;
    },

    tab() {
      this.products = [];
      this.selected = [];
      this.fetchProducts(
        this.options.page,
        this.options.itemsPerPage,
        this.search
      );
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add new product" : "Edit product";
    },

    pi() {
      return this.photoItem;
    },

    searchDisabled() {
      if (this.filterBySelected && this.filterBySelected.includes("brand"))
        return true;
      if (!this.filterBySelected && this.filterBySelectedCategory) return true;
      const filterItem = this.filterBy.find((x) => x.value == this.search);
      if (filterItem && this.search && !this.search.includes("quantity"))
        return true;
      return false;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isOwner() {
      return this.$store.state.activeStore.whoAmI == "owner";
    },

    isWhitelabeled() {
      return !this.isOwner && this.$store.state.activeStore.isWhitelabeled;
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  data: () => {
    return {
      _id: "",
      search: "",
      loading: false,
      processing: false,
      dialog: false,

      enums: {},
      editedIndex: -1,
      editedItem: {
        name: "",
        isActive: true,
        expanded: false,
        offerExpanded: false,
        isPreorderable: false,
        isSubscribable: false,
        hideAddToCart: false,
        isCombo: false,
        parent: "",
        category: "",
        photos: [],
        tags: [],
        comboItems: [],
        dimensionUnit: "cm",
        incrementor: 1,
        isScheduled: false,
        schedule: [],
      },
      defaultItem: {
        name: "",
        isActive: true,
        isComingSoon: false,
        isSubscribable: false,
        inStock: true,
        expanded: false,
        offerExpanded: false,
        isPreorderable: false,
        hideAddToCart: false,
        isCombo: false,
        parent: "",
        photos: [],
        tags: [],
        comboItems: [],
        dimensionUnit: "cm",
        incrementor: 1,
        isScheduled: false,
        schedule: [],
      },

      confirmDialog: false,
      beingDeleted: {},

      duplicateDialog: false,
      beingDuplicated: {},
      duplicatePromptDialog: false,

      creatingVariant: false,
      removingAsVariant: false,

      massdialog: false,
      massprocessing: false,
      massItem: {},

      confirmDialogPhoto: false,
      beingDeletedPhoto: {},

      brands: [],

      ec: {
        _id: "",
        type: "",
        value: "",
        index: 0,
        saving: false,
      },

      photoItem: {
        uploading: false,
        _id: undefined,
        progress: 0,
        sheet: false,
      },

      headers: [
        {
          text: "# - Quickers",
          align: "start",
          width: "165px",
          value: "index",
          disabled: true,
          sortable: false,
        },
        { text: "Name", value: "name", disabled: true },
        { text: "Category", value: "categoryName", sortable: false },
        { text: "Size", value: "sizeName", sortable: false },
        { text: "Price", value: "price" },
        { text: "Quantity", value: "quantity" },
        { text: "In stock?", value: "inStock" },
        { text: "Status", value: "isActive" },
        {
          text: "Actions",
          align: "right",
          width: "110px",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      defaultHeaders: [
        {
          text: "# - Quickers",
          align: "start",
          width: "140px",
          value: "index",
          disabled: true,
        },
        { text: "Name", value: "name", disabled: true },
        { text: "Category", value: "categoryName" },
        { text: "Size", value: "sizeName", sortable: false },
        { text: "Price", value: "price" },
        { text: "Quantity", value: "quantity" },
        { text: "In stock?", value: "inStock" },
        { text: "Status", value: "isActive" },
        {
          text: "Actions",
          align: "right",
          width: "110px",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      toggleColumnSelect: false,
      saveColumnsText: "Save",
      availableHeaders: [
        {
          text: "# - Quickers",
          align: "start",
          width: "140px",
          value: "index",
          disabled: true,
        },
        { text: "Image", value: "image", sortable: false },
        { text: "Name", value: "name", disabled: true },
        { text: "Full Name", value: "fullName", sortable: false },
        { text: "Category", value: "categoryName", sortable: false },
        { text: "Size", value: "sizeName", sortable: false },
        { text: "Color", value: "color", sortable: false },
        { text: "M.R.P.", value: "marketPrice", sortable: false },
        { text: "Price", value: "price" },
        { text: "Quantity", value: "quantity" },
        { text: "In stock?", value: "inStock" },
        { text: "Minimum (Orderable Qty)", value: "minOrderCap" },
        { text: "Maximum (Orderable Qty)", value: "orderCap" },
        { text: "GSTN or Tax Code", value: "gstn" },
        { text: "HSN/SAC Code", value: "hsnSacCode" },
        { text: "GST %", value: "gst" },
        { text: "Vegan", value: "vegan" },
        { text: "Brand", value: "brandName" },
        { text: "SKU", value: "sku" },
        { text: "Is Subscribable?", value: "isSubscribable" },
        { text: "Is Preorderable?", value: "isPreorderable" },
        { text: "Is Coming Soon?", value: "isComingSoon" },
        { text: "Is it Combo?", value: "isCombo" },
        { text: "Sequence Number", value: "sequenceNumber" },
        { text: "Status", value: "isActive" },
        { text: "Created By", value: "createdByName", sortable: false },
        {
          text: "Actions",
          align: "right",
          width: "110px",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      products: [],
      categories: [],

      options: { sortBy: [] },
      productsObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      filterBy: [
        { header: "Basic" },
        { value: "isActive:true", text: "Active" },
        { value: "isActive:false", text: "Inactive" },
        { value: "inStock:true", text: "In Stock" },
        { value: "inStock:false", text: "Out of Stock" },
        { value: "quantity:0", text: "Zero Quantity" },
        { value: "isParent:true", text: "Main Products" },
        { value: "isNewArrival:true", text: "New Arrivals" },
        { value: "isTrending:true", text: "Trending" },
        { value: "isRecommended:true", text: "Recommended" },
        { value: "isFeatured:true", text: "Featured" },
        { value: "isSubscribable:true", text: "Subscribable" },
        { value: "isPreorderable:true", text: "Preorderable" },
        { value: "isComingSoon:true", text: "Coming Soon" },
        { value: "isCombo:true", text: "Combo" },
      ],
      filterBySelected: "",
      filterBySelectedCategory: "",

      selected: [],
      singleSelect: false,
      rowItems: config.rowItems,

      confirmMassDelete: false,
      translationModified: false,
      bulkUpdated: false,

      showActiveProductModal: false,
      activeProduct: {},

      colorDialog: false,
      selectedColor: "",

      counts: {},
      tab: 0,
      tabs: [
        { _id: "all", title: "All Products" },
        {
          _id: "fast-moving",
          title: "Fast Moving Products",
          description:
            "Products have been ordered twice or more in the last 1 week",
        },
        {
          _id: "slow-moving",
          title: "Slow Moving Products",
          description:
            "Products have no order in the last 1 week but have one or more orders in the last 1 month",
        },
        {
          _id: "non-moving",
          title: "Non Moving Products",
          description: "Products have no order in the last 15 days or more",
        },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => !!v || v === false || "It is required"],
        inlineRule: [(v) => !!v || ""],
        unitRule: [(v) => !!v || v === "" || "It is required"],
        numberRule: [(v) => v >= 0 || "Must be a valid number"],
        sellingPriceRule: [
          (v) => (!isNaN(v) && v >= 0) || "Enter a valid price",
        ],
      },
    };
  },
};

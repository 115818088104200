import EventBus from '../../event-bus';
import Banner from '../banner/banner.vue';
import router from '../../router';

import Store from '../../services/store';

export default {
  name: 'Header',
  computed: {
    currentPath() { return this.$route.path; },
    cpName() { return this.$route.name; },
    authStatus() { return this.$store.getters.authStatus },
    userInfo() { return this.$store.getters.userInfo },
    getUserPhoto() { return this.$store.getters.getUserPhoto },
    firstName() { return this.userInfo.name ? (this.userInfo.name.split(' ').length === 1 ? this.userInfo.name : this.userInfo.name.split(' ').slice(0, -1).join(' ')) : (this.userInfo.mobile || ''); },
    uuid() { return this.$store.state.uuid },

    name() { return this.userInfo.name || this.userInfo.mobile },

    isDark() { 
      return this.$route.name === 'experience-detail' || this.$route.name === 'member-detail' || this.$route.name === 'itinerary-detail' || this.$route.name === 'careers';
    },

    firstCharacters() {
      const fullName = this.userInfo.name.split(' ');
      return fullName.shift().charAt(0) + (fullName.length > 0 ? fullName.pop().charAt(0) : '');
    },

    disableRouting() {
      return [
        'countries', 
        'i18n', 
        'invoices', 
        'sms', 
        'crm', 
        'helpdesk', 
        'support', 
        'master-inventory', 
        'my-businesses', 
        'support-info'].includes(this.$route.name) || !this.$route.name;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? 'text-white' : 'text-black';
    },

    amIAllowedAdmin() {
      return Store.doIHaveAdminAccess(this.$store);
    },
  },

  components: {
    Banner
  },

  methods: {
    showCurrencyModal() {
      this.$refs.currencyModal.$refs['currency-modal'].show();
    },
    login() {
      EventBus.$emit('prompt-login', {});
    },
    logout() {
      this.$store.commit('logout');
      this.hide();
    },

    isSuperadmin() {
      return this.$store.state.userInfo.roles.includes('superadmin');
    },

    amIGrowcifyEmployee() {
      return this.$store.state.userInfo.roles.includes('growcify-employee');
    },

    unsubscribePusherAndRedirect() {
      if (this.disableRouting) return;

      this.$store.state.planInfo = {};
      if (this.$store.state.activeStore._id) {
        this.$store.state.pusher.unsubscribe(this.$store.state.activeStore._id);
        this.$store.state.pusherBusinessEvents = [];
      }
      
      if (this.isSuperadmin() || this.amIGrowcifyEmployee()) router.push('/my-businesses');
      else router.push('/businesses');

      Store.setDefaultAppearance();
    },

    hide() {
      this.showDropdown = false;
      setTimeout(() => this.showDropdown = true, 250);
    },

    toggleTheme() {
      if (!this.fl) return;
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem('dark', this.$vuetify.theme.dark);
    }
  },

  watch: {
    'darkMode'() {
      this.toggleTheme();
    }
  },

  mounted() {
    const theme = localStorage.getItem('dark');
    if (theme) {
      if (theme == 'true') this.darkMode = true;
      else this.darkMode = false;
    }
    setTimeout(() => this.fl = true, 500);
  },

  data() {
    return {
      fl: false,
      darkMode: false,
      showDropdown: true,
      themes: [
        {
          text: "Dark",
          icon: "mdi-clock"
        },
        {
          text: "Light",
          icon: "mdi-account"
        }
      ]
    }
  }
}
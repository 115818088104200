import { api as fullscreen } from "vue-fullscreen";
import moment from "moment";
import _ from "underscore";

import CRM from "../../services/crm";
import Super from "../../services/super";
import Store from "../../services/store";
import config from "../../config";

import Call from "../../components/call/call.vue";
import LeadDetail from "../../components/lead-detail/lead-detail.vue";

import LeadTaskHistory from "../../components/lead-task-history/lead-task-history.vue";
import MeetingScheduler from "../../components/meeting-scheduler/meeting-scheduler.vue";

const todayDate = moment().format("YYYY-MM-DD");
const yesterdayDate = moment()
  .subtract(1, "d")
  .format("YYYY-MM-DD");
const tomorrowDate = moment()
  .add(1, "d")
  .format("YYYY-MM-DD");

export default {
  name: "Leads",

  // sockets: {
  //   // eslint-disable-next-line
  //   newLead(data = {}) {
  //     this.notify();
  //     this.fetchLeads();
  //   }
  // },

  components: {
    Call,
    LeadDetail,
    LeadTaskHistory,
    MeetingScheduler,
  },

  created() {
    if (!this.canProceed) return Store.redirectToHome();

    //subscribe to new lead event
    this.initPusher();

    //set default columns
    //const savedColumns = localStorage['leadsColumns'];
    //if (savedColumns) this.headers = JSON.parse(savedColumns);

    this.fetchHome();
    this.fetchSA();
    this.getLeadEnums();
    this.fetchCountries();
  },

  methods: {
    initPusher() {
      const channelName = "growcify-new-lead";
      const eventName = "newLead";

      const pusher = this.$store.state.pusher;
      pusher.unsubscribe(channelName);
      const channel = pusher.subscribe(channelName);
      channel.unbind(eventName);
      channel.bind(eventName, () => {
        this.notify();
        if (this.$route.name === "crm") this.fetchLeads();
      });

      //lead-callback remidner pusher
      const channelName2 = this.$store.getters.userInfo._id;
      const eventName2 = "leadCallbackReminder";
      const eventName3 = "demoReminder";

      pusher.unsubscribe(channelName2);
      const channel2 = pusher.subscribe(channelName2);
      channel2.unbind(eventName2);
      channel2.bind(eventName2, (data) => {
        this.notify(data.title, { body: data.message });
      });

      channel2.unbind(eventName3);
      channel2.bind(eventName3, (data) => {
        this.notify(data.title, { body: data.message });
      });
    },

    toggleFullscreen() {
      fullscreen.toggle(this.$el.querySelector(".fullscreen-wrapper"), {
        teleport: true,
        callback: () => {
          this.$store.state.fullscreenMode = !this.$store.state.fullscreenMode;
        },
      });
    },

    fetchHome() {
      this.fetchLeads();
      this.fetchLeadCounts();
      this.fetchAnalytics();
    },

    notify(title, body = {}) {
      this.$notification.show(
        title || "You've received a new lead! ✌️",
        body,
        {}
      );
    },

    fetchLeads(
      pageNumber = this.crmObject.pageNumber,
      itemsPerPage = this.crmObject.itemsPerPage,
      keyword = this.search
    ) {
      const filters = {};
      const sortBy = {};

      if (this.options.page) pageNumber = this.options.page;
      if (this.options.itemsPerPage) itemsPerPage = this.options.itemsPerPage;
      if (this.assignedTo) filters.assignedTo = this.assignedTo;
      if (this.status) filters.status = this.status;
      if (this.filterBySelected)
        filters.filterBySelected = this.filterBySelected;
      if (this.showStatusLeadWiseCounts)
        filters.showStatusLeadWiseCounts = this.showStatusLeadWiseCounts;
      if (this.options.sortBy && this.options.sortBy.length)
        sortBy[this.options.sortBy[0]] = this.options.sortDesc[0] ? -1 : 1;
      else sortBy.createdAt = -1;

      if (sortBy.lastCall) return;

      const object = { filter: filters, sortBy: sortBy };
      this.loading = true;
      return CRM.getLeads(object, pageNumber, itemsPerPage, keyword, false)
        .then((res) => {
          this.crmObject = res.data;
          this.leads = res.data.items;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    formatDate(date, format = "ll") {
      return moment(date).format(format);
    },

    fromNow(date) {
      return moment(date).fromNow();
    },

    fetchCountries() {
      const cs = this.$store.state.countries;
      if (cs && cs.length) return (this.countries = cs);

      return Store.getActiveCountries(false)
        .then((res) => {
          this.countries = res.data;
          this.$store.state.countries = res.data;
        })
        .catch((e) => console.log(e));
    },

    getLeadEnums() {
      return Super.getLeadEnums()
        .then((x) => (this.enums = x.data))
        .catch((e) => console.log(e));
    },

    fetchLeadCounts() {
      this.cfetching = true;
      return Super.getLeadCounts()
        .then((res) => {
          this.counts = res.data;
          this.cfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.cfetching = false;
        });
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.cenums = ce);

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => console.log(e));
    },

    fetchSA() {
      return Super.getSuperadmins()
        .then((x) => (this.users = x.data))
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.leads.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (this.editedItem.callback)
        this.editedItem.callback = new Date(this.editedItem.callback);
      if (this.editedItem.demoAt)
        this.editedItem.demoAt = new Date(this.editedItem.demoAt);
      if (this.editedItem.convertedAt)
        this.editedItem.convertedAt = new Date(this.editedItem.convertedAt);

      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Super.deleteLead(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchLeads();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    saveItem(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false);
    },

    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();

      const object = Object.assign({}, this.editedItem);

      let request;
      if (this.editedItem._id)
        request = Super.editLead(this.editedItem._id, object, false);
      else request = Super.createLead(object, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
          this.fetchLeads();
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            this.error = "Lead already exists";
            setTimeout(() => (this.error = ""), 2500);
          }
          Store.snackbarError();
          this.processing = false;
          this.loading = false;
        });
    },

    text(item) {
      return item.name || item.mobile;
    },

    sendMessage(item, isWhatsappMessage = false, isEmail = false) {
      const firstName = item.name.split(" ")[0];

      const items = JSON.parse(JSON.stringify(this.messages));
      items.forEach((x) => {
        x.message = x.message.replace("##name##", firstName);
        if (x.wamessage)
          x.wamessage = x.wamessage.replace("##name##", firstName);
      });

      this.items = items;
      const object = {
        number: item.contactNumber,
        name: firstName,
        email: item.email,
        message:
          isWhatsappMessage && items[0].wamessage
            ? items[0].wamessage
            : items[0].message,
        flow_id: items[0].flow_id,
        templateId: items[0].templateId,
        id: items[0].id,
        _id: item._id,
        type: "lead",
      };
      this.editedMessageItem = Object.assign({}, object);

      this.messageSent = false;
      if (isWhatsappMessage) this.$store.state.whatsappMessageModal = true;
      else if (isEmail) this.$store.state.emailModal = true;
      else this.$store.state.smsModal = true;
    },

    scheduleMeeting(lead) {
      this.meetingInfo = {
        name: lead.name,
        email: lead.email,
        contactNumber: lead.contactNumber,
        leadId: lead._id,
      };
      this.meetingScheduled = false;
      this.$store.state.meetingSchedulerModal = true;
    },

    scheduleNewMeeting() {
      this.meetingInfo = {};
      this.meetingScheduled = false;
      this.$store.state.meetingSchedulerModal = true;
    },

    createCall(lead, isAdding = true) {
      const callItem = JSON.parse(JSON.stringify(lead));
      if (isAdding) {
        this.callItem = {
          lead: callItem._id,
          callingCode: "91",
          to: callItem.contactNumber.substr(-10),
          type: "Outbound",
          isNew: true,
        };
      } else this.callItem = callItem;

      this.callCreated = false;
      this.$store.state.callModal = true;
    },

    exportLeads() {
      let endpoint = `${
        process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd
      }lead/export`;
      return window.open(
        endpoint +
          "?x-request-user-agent=application/admin-app&token=" +
          this.$store.state.token,
        "_blank"
      );
    },

    openLead(item) {
      this.firstLoad = false;
      this.activeLead = item;
      this.$store.state.leadDetailModal = true;
      setTimeout(() => (this.firstLoad = true), 1000);
    },

    getLabel(item) {
      if (item == "calls") return "Calls";
      else if (item == "meetingsCreated") return "Meetings Created";
      else if (item == "meetingsScheduled") return "Meetings Scheduled";
      else if (item == "leadsAssigned") return "Leads Assigned";
      else if (item == "sms") return "Messages";
    },

    fetchAnalytics() {
      if (
        !this.basicAnalyticsSee ||
        !this.basicAnalyticsOn ||
        !this.basicAnalyticsDates.length ||
        !this.showAnalytics
      )
        return;
      this.afetching = true;
      const filters = {
        items: this.basicAnalyticsSee,
        by: this.basicAnalyticsBy,
        date: this.basicAnalyticsOn,
        dates: this.basicAnalyticsDates,
      };
      return Super.getLeadAnalytics(filters, false)
        .then((x) => {
          this.afetching = false;
          this.analyticsData = x.data;
          this.showAnalyticsData = true;
        })
        .catch((e) => {
          console.log(e);
          this.afetching = false;
        });
    },

    columnsChanged() {
      const nh = [];
      this.availableHeaders.forEach((x) => {
        if (
          this.headers.find((i) => i.value == x.value) &&
          !nh.find((j) => j.value == x.value)
        )
          nh.push(x);
      });
      this.headers = nh;
    },

    saveColumns() {
      localStorage["leadsColumns"] = JSON.stringify(this.headers);
      this.saveColumnsText = "Saved";
      setTimeout(() => {
        this.saveColumnsText = "Save";
        this.showColumns = !this.showColumns;
        this.additionalFilters = this.additionalFilters.filter(
          (x) => x != "showColumns"
        );
      }, 500);
    },

    resetColumns() {
      this.headers = this.defaultHeaders;
    },

    showHistory(type) {
      this.taskHistoryFilter = {
        items: this.basicAnalyticsSee,
        by: this.basicAnalyticsBy,
        on: this.basicAnalyticsOn,
        dates: this.basicAnalyticsDates,
        type: type,
      };
      if (this.basicAnalyticsBy) {
        const user = this.users.find((x) => x._id == this.basicAnalyticsBy);
        this.taskHistoryFilter.byName = user ? user.name : "-";
      }
      this.$store.state.leadTaskHistoryModal = true;
    },

    getDays(a, b) {
      return moment(a).diff(b, "days");
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Create new lead" : "Edit lead";
    },

    leadsWithIndex() {
      let leads = this.leads;
      if (this.hideJunk) leads = leads.filter((x) => x.status != "Junk");
      leads = leads.map((x, index) => {
        x.index = index + 1 + ".";
        return x;
      });

      if (this.options.sortBy && this.options.sortBy.length) {
        const sortBy = this.options.sortBy[0];
        if (sortBy == "lastCall") {
          const direction = this.options.sortDesc[0];
          if (direction == 1)
            leads = leads.sort((a, b) => b.lastCall > a.lastCall);
          else leads = leads.sort((a, b) => b.lastCall < a.lastCall);
        }
      }

      return leads;
    },

    amIGrowcifyEmployee() {
      return Store.amIGrowcifyEmployee(this.$store);
    },

    canProceed() {
      return (
        (Store.amISuperadmin(this.$store) ||
          Store.amIGrowcifyEmployee(this.$store)) &&
        this.$store.state.isGD
      );
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  watch: {
    options(n) {
      if (n) {
        this.fetchLeads();
      }
    },

    smsSent(n) {
      if (n) {
        this.fetchLeads();
        this.editedMessageItem = {};
        this.smsSent = false;
      }
    },

    search: _.debounce(function() {
      this.fetchLeads();
    }, 500),

    status() {
      this.fetchLeads();
    },

    assignedTo() {
      this.fetchLeads();
    },

    filterBySelected() {
      this.fetchLeads();
    },

    leadModified(n) {
      if (n) this.leadModified = false;
    },

    additionalFilters(n) {
      if (n.includes("hideJunk")) this.hideJunk = true;
      else this.hideJunk = false;

      if (n.includes("showAnalytics")) {
        this.showAnalytics = true;
        this.fetchAnalytics();
      } else this.showAnalytics = false;

      if (n.includes("showColumns")) this.showColumns = true;
      else this.showColumns = false;

      if (n.includes("showNewLeadCounts")) this.showNewLeadCounts = true;
      else this.showNewLeadCounts = false;

      if (n.includes("showStatusLeadWiseCounts")) {
        this.showStatusLeadWiseCounts = true;
        this.fetchLeads();
      } else this.showStatusLeadWiseCounts = false;
    },

    basicAnalyticsSee(n) {
      if (n.length) this.fetchAnalytics();
      else this.showAnalyticsData = false;
    },

    basicAnalyticsBy() {
      this.fetchAnalytics();
    },

    basicAnalyticsOnModal(n) {
      if (!n) this.fetchAnalytics();
    },
  },

  data: () => {
    return {
      allLeads: [],
      leads: [],
      search: "",
      status: "",
      assignedTo: "",
      options: {},

      filterBy: [
        { header: "Callback" },
        { value: "callback:" + todayDate, text: "Callback - Today" },
        { value: "callback:" + yesterdayDate, text: "Callback - Yesterday" },
        { value: "callback:" + tomorrowDate, text: "Callback - Tomorrow" },
        { header: "Demo" },
        { value: "demo:" + todayDate, text: "Demo - Today" },
        { value: "demo:" + yesterdayDate, text: "Demo - Yesterday" },
        { value: "demo:" + tomorrowDate, text: "Demo - Tomorrow" },
        { header: "Created" },
        { value: "created:" + todayDate, text: "Created - Today" },
        { value: "created:" + yesterdayDate, text: "Created - Yesterday" },
        { value: "created:" + tomorrowDate, text: "Created - Tomorrow" },
        { header: "Modified" },
        { value: "modified:" + todayDate, text: "Modified - Today" },
        { value: "modified:" + yesterdayDate, text: "Modified - Yesterday" },
        { value: "modified:" + tomorrowDate, text: "Modified - Tomorrow" },
        { header: "Business" },
        { value: "businessRegistered", text: "Registered Businesses" },
        { value: "isPotential", text: "Potential Leads" },
      ],
      filterBySelected: "",

      loading: false,
      valid: false,
      dialog: false,
      processing: false,

      error: "",

      enums: {},
      cenums: {},

      translationModal: false,
      translationField: "",

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        status: "New",
        country: "India",
      },
      menu: false,

      confirmDialog: false,
      beingDeleted: {},
      users: [],

      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
          disabled: true,
        },
        { text: "Name", align: "start", value: "name", disabled: true },
        {
          text: "Contact",
          value: "contactNumber",
          sortable: false,
          disabled: true,
        },
        { text: "Stage", value: "status" },
        { text: "Callback / Demo", value: "callback" },
        { text: "Assigned", value: "assignedTo" },
        { text: "Last Call", value: "lastCall" },
        { text: "Modified", value: "updatedAt", width: "10%" },
        {
          text: "Actions",
          align: "right",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      saveColumnsText: "Save",
      defaultHeaders: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
          disabled: true,
        },
        { text: "Name", align: "start", value: "name", disabled: true },
        {
          text: "Contact",
          value: "contactNumber",
          sortable: false,
          disabled: true,
        },
        { text: "Last Call", value: "lastCall" },
        { text: "Stage", value: "status" },
        { text: "Callback / Demo", value: "callback" },
        { text: "Assigned", value: "assignedTo" },
        { text: "Modified", value: "updatedAt", width: "10%" },
        {
          text: "Actions",
          align: "right",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      availableHeaders: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
          disabled: true,
        },
        { text: "Name", align: "start", value: "name", disabled: true },
        {
          text: "Contact",
          value: "contactNumber",
          sortable: false,
          disabled: true,
        },
        { text: "Last Call", value: "lastCall" },
        { text: "City", value: "city" },
        { text: "State", value: "state" },
        { text: "Country", value: "country" },
        { text: "Stage", value: "status" },
        { text: "By", value: "by" },
        { text: "Source", value: "source" },
        { text: "Callback / Demo", value: "callback" },
        { text: "Remarks", value: "remarks" },
        { text: "Assigned", value: "assignedTo" },
        { text: "Created", value: "createdAt" },
        { text: "Modified", value: "updatedAt" },
        { text: "Converted", value: "convertedAt" },
        {
          text: "Actions",
          align: "right",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      editedMessageItem: {},
      items: [],
      messages: config.messages,
      smsSent: false,

      hideJunk: false,
      hideArchived: false,

      activeLead: {},
      leadModified: false,
      firstLoad: false,
      messageSent: false,

      meetingInfo: {},
      meetingScheduled: false,

      callCreated: false,
      callItem: {},

      countries: [],

      afetching: false,
      basicAnalytics: [
        { key: "calls", value: "Calls Made" },
        { key: "meetingsCreated", value: "Meetings Created" },
        { key: "meetingsScheduled", value: "Meetings Scheduled" },
        { key: "leadsAssigned", value: "Leads Assigned" },
        { key: "sms", value: "Messages Sent" },
      ],
      basicAnalyticsSee: ["calls", "meetingsScheduled", "leadsAssigned", "sms"],
      basicAnalyticsBy: "",
      basicAnalyticsOn: todayDate,
      basicAnalyticsOnModal: false,
      basicAnalyticsDates: [todayDate, todayDate],

      maxBasicAnalyticsOn: todayDate,
      showAnalyticsData: false,
      showAnalytics: false,
      analyticsData: {},

      counts: {},
      countFilter: "",
      cfetching: false,
      showNewLeadCounts: false,
      showStatusLeadWiseCounts: false,

      additionalFilters: [""],
      additionalItems: [
        { key: "showColumns", value: "Columns" },
        { key: "hideJunk", value: "Hide Junk" },
        { key: "showAnalytics", value: "Analytics Filters" },
        { key: "showNewLeadCounts", value: "New Lead Counts" },
        { key: "showStatusLeadWiseCounts", value: "Status Wise Lead Counts" },
      ],

      showColumns: false,
      taskHistoryFilter: {},

      crmObject: {
        pageNumber: 1,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 25,
        counts: {},
      },

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        callingCode: [(v) => v >= 0 || "Enter digits only"],
      },
    };
  },
};

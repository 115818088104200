<template>
  <v-dialog :persistent="$store.state.posSubscriptionModalPersistent" v-model="$store.state.posSubscriptionModal" max-width="550px" content-class="rounded-dialog">
    <v-progress-linear v-if="processing || efetching" indeterminate color="primary" class="mb-0"></v-progress-linear>

    <v-card v-if="!efetching" class="p-3">
      <v-card-text style="padding-bottom: 0">
        <v-container v-if="!posSubscriptionSuccess">
          <v-row>
            <v-col cols="12" :class="['text-center', $store.state.posSubscriptionModalRenewal ? 'text-error' : '']">
              <h5><b>Growcify POS Subscription{{ $store.state.posSubscriptionModalRenewal ? ' Renewal' : 's' }}</b></h5>
              <span v-if="!processing">Please select a subscription to continue</span>
              <span v-else class="sc">Please wait while we process your request...</span>
            </v-col> 

            <v-col cols="12" class="text-center" style="padding-top: 0">
              <v-card :disabled="processing" v-for="(item, i) in enums.posPlans" :key="i" outlined style="padding: 5px 15px; border-radius: 10px" :class="['mt-4 mt-4', isSelected(item) ? 'selected-plan' : '']" @click="selectPlan(item, i)">
                <v-row>
                  <v-col cols="8" class="text-left">
                    <span class="ctitle">{{item.name}}</span><br>
                    <span v-if="item._id == 'pos-monthly'">
                      
                      <span v-if="$store.state.posSubscriptionModalRenewal && planInfo.posPlanID == 'pos-monthly'" class="text-error">
                        <b>Due Date {{ planInfo.posNextDueDateReadable }}</b>
                      </span>
                      <span v-else>
                        Billed <b>{{ amountFormatted(item) }}</b> monthly
                      </span>  

                    </span>
                    <span v-if="item._id == 'pos-yearly'">
                      
                      <span v-if="$store.state.posSubscriptionModalRenewal && planInfo.posPlanID == 'pos-yearly'">
                        <b>Due Date {{ planInfo.posNextDueDateReadable }}</b>
                      </span>
                      <span v-else>
                        Billed <b>{{ amountFormatted(item) }}</b> yearly
                      </span>

                    </span>
                    <span v-if="item._id == 'pos-onetime'">A wise man once said nothing.</span><br>
                    <span v-if="item._id == 'pos-onetime'">Billed <b>{{ amountFormatted(item) }}</b> one-time </span>
                  </v-col>
                  <v-col cols="4" class="vc text-right">
                    <span class="ctitle">{{ amountFormatted(item) }}</span><br>
                    <span v-if="item._id == 'pos-monthly'">Monthly</span>
                    <span v-if="item._id == 'pos-yearly'" >Yearly</span>
                    <span v-if="item._id == 'pos-onetime'">Onetime</span>
                    <div>
                      <v-chip v-if="item._id == 'pos-onetime'" :color="isSelected(item) ? 'white' : 'primary'" x-small :class="isDT ? 'text-black' : ''">Popular</v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <span>All prices are exclusive of <b>18%</b> GST.</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="!$store.state.posSubscriptionModalPersistent" cols="4">
              <v-btn :disabled="processing" outlined @click="$store.state.posSubscriptionModal = false" block large color="error">Cancel</v-btn>
            </v-col>
            <v-col :class="$store.state.posSubscriptionModalPersistent ? 'text-center' : 'text-right'" @click="posCheckout">
              <v-btn :disabled="processing" block outlined large color="primary">Proceed to Checkout</v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <span style="font-size: 0.8rem;"><b>Growcify POS</b> is available without additional cost in our <b class="text-primary">Growcify Enterprise</b> plan. Please contact our team to upgrade your plan.</span>
            </v-col>
          </v-row>
        </v-container>

        <v-container v-else class="animate__animated animate__fadeIn">
          <div class="close-icon-ss"><v-icon @click="$store.state.posSubscriptionModal = false">mdi-close</v-icon></div>

          <v-row>
            <v-col class="text-center">
              <lottie-player :src="checkmarkAnimationJson"  background="transparent"  speed="0.5"  style="width: 300px; height: 300px; margin: auto"  autoplay></lottie-player>
              <h5><b>Payment Successful!</b></h5>
              <p>Your <b>Growcify POS Subscription</b> purchase has been completed successfully.
              <span v-if="planInfo.posPlanID != 'pos-onetime'">Your next renewal date is <b>{{ planInfo.posNextDueDateReadable }}</b>. If you face any issue, feel free to reach our team.</span></p>
              <span><b>Thank you for choosing Growcify! </b></span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Global from '../../services/global';
import Store from '../../services/store';
import POS from '../../services/pos';

import config from '../../config';

export default {

  props: [
    '_id'
  ],
  
  methods: {
    selectPlan(plan) {
      if (!plan.currencyCode) {
        plan = this.enums.posPlans.find(p => p._id == plan._id);
        plan.currencyCode = this.$store.state.country.currencyCode || 'USD';
        plan.price = plan[plan.currencyCode];
      }

      const planObject = {
        _id: plan._id,
        name: plan.name,
        price: plan.price,
        currencyCode: plan.currencyCode
      }

      this.selectedPlan = planObject;
    },

    fetchPricingEnums() {
      this.efetching = true;
      return Store.getPricingEnums()
        .then(x => {
          this.enums = x.data;
          if (this.enums.posPlans) {
            //SET PRICING AS PER BUSINESS
            if (this.planInfo.posPlanID) {
              const plan = this.enums.posPlans.find(p => p._id == this.planInfo.posPlanID);
              plan.price = this.planInfo.posAmount;
              plan.currencyCode = this.planInfo.posTransaction.currencyCode;
              plan.transactionId = this.planInfo.posTransaction._id;
              this.selectedPlan = plan;
            } else {
              const posOnetime = this.enums.posPlans.find(p => p._id == 'pos-onetime');
              this.selectedPlan = posOnetime;
              this.selectedPlan.currencyCode = this.$store.state.country.currencyCode || 'USD'
            }
          }
          this.efetching = false;
        })
        .catch(e => {
          console.log(e);
          this.efetching = false;
        })
    },

    isSelected(item) {
      return item._id == this.selectedPlan._id;
    },

    taxedAmount() {
      const cc = this.selectedPlan.currencyCode;
      const isINR = cc == 'INR';
      const isExclusiveGST = this.enums.posPlans.find(x => x[cc] == this.selectedPlan.price);
      
      return isINR && isExclusiveGST ? 
        Global.getTaxAmount(this.selectedPlan.price) : 
        this.selectedPlan.price;
    },

    posCheckout() {
      const currencyCode = this.selectedPlan.currencyCode;
      const amount = this.taxedAmount();
      const string = this.selectedPlan.posPlanID === 'pos-onetime' ? 'Purchase' : 'Subscription';

      const options = Global.getRazOptions(amount, 'Growcify POS '+string, currencyCode);
      const newthis = this;
      options.handler = function(response) { newthis.processPayment(response.razorpay_payment_id) };
      new window.Razorpay(options).open();
    },

    processPayment(paymentId) {
      const object = {
        paymentId: paymentId,
        amount: this.taxedAmount(),
        posPlanID: this.selectedPlan._id,
        currencyCode: this.selectedPlan.currencyCode,
      };
      this.processing = true;
      return POS
        .processPayment(this._id, object, false)
        .then(() => this.fetchStoreStatus())
        .catch(() => this.processing = false);
    },

    fetchStoreStatus() {
      return Store.getRetnalStatus(this._id, true)
        .then(x => {
          this.$store.state.planInfo = x.data;
          this.processing = false;
          this.posSubscriptionSuccess = true;
        })
        .catch(e => console.log(e));
    },

    getPlanPrice(plan) {
      if (plan.currencyCode) return plan.price;
      return plan[this.$store.state.country.currencyCode || 'USD'];
    },

    amountFormatted(plan) {
      plan.price = this.getPlanPrice(plan);
      let formattedAmount = plan.price;
      if (plan.currencyCode === 'INR') formattedAmount = this.currencyPipe(plan.price, '₹');
      else if (plan.currencyCode === 'USD') formattedAmount = this.currencyPipe(plan.price, '$');
      else if (plan.currencyCode && plan.currencyCode !== 'INR') formattedAmount = this.currencyPipe(plan.price, plan.currencyCode);
      else if (!this.$store.state.country.currencyCode && !plan.currencyCode) formattedAmount = this.currencyPipe(plan.price, '₹');
      else formattedAmount = this.currencyPipe(plan.price, this.$store.state.country.currencySymbol);

      return formattedAmount;
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },
  },

  watch: {
    '$store.state.posSubscriptionModal'(n) {
      if (n) this.fetchPricingEnums();
    }
  },

  computed: {
    planInfo() {
      return this.$store.state.planInfo;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  data() {
    return {
      processing: false,
      efetching: false,
      posSubscriptionSuccess: false,
      enums: {
        posPlans: []
      },

      checkmarkAnimationJson: config.checkmarkAnimationJson,
      selectedPlan: {}
    }
  }
}
</script>

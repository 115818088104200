import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import moment from "moment";
import VueAxios from "vue-axios";
import AOS from "aos";
import titleMixin from "./mixins/titleMixin";
import http from "./http";
import store from "./store";
//import GAuth from 'vue-google-oauth2'
import vuetify from "./plugins/vuetify";
import NProgress from "nprogress";
import Vue2Filters from "vue2-filters";
import Vue2Editor from "vue2-editor";
import DatetimePicker from "vuetify-datetime-picker";
import VueNativeNotification from "vue-native-notification";

//css import
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/custom.scss";
import "aos/dist/aos.css";

Vue.config.productionTip = false;
/*const gauthOption = {
  clientId: '',
  scope: 'profile email',
  prompt: 'select_account'
}*/

Vue.mixin(titleMixin);
Vue.use(VueAxios, http);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
//Vue.use(GAuth, gauthOption);
Vue.use(Vue2Filters);
Vue.use(Vue2Editor);
Vue.use(DatetimePicker);
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true,
});

Vue.filter("date", (date) => moment(date).format("MMMM DD, YYYY"));

// Vue.filter('fc', function (value) {
//   const symbol = store.state.country.currencySymbol || '$';
//   return Vue.options.filters.currency(value, symbol);
// });

Vue.filter("fc", function(value) {
  const currency = store.state.country.currencyCode || "INR";
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: currency,
  }).format(value);
});

Vue.filter("fcn", function(value, currency = "INR") {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: currency,
  }).format(value);
});

Vue.filter("firstLetterUppercase", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("firstName", function(value) {
  if (!value || value == "-") return value;
  return value.split(" ").length === 1
    ? value
    : value
        .split(" ")
        .slice(0, -1)
        .join(" ");
});

vuetify.framework.theme.themes.light.primary = "#007bff";

NProgress.start();
new Vue({
  created() {
    AOS.init();
  },
  router,
  render: (h) => h(App),
  store,
  vuetify,
}).$mount("#app");

import Vue from 'vue';

export default {
  getLeads(status = 'all', noProgress = true) {
    return Vue.axios.get(`lead/list/${status}`, {noProgress: noProgress});
  },

  createLead(object, noProgress = true) {
    return Vue.axios.post(`lead`, object, {noProgress: noProgress});
  },

  getLead(_id, noProgress = true) {
    return Vue.axios.get(`lead/${_id}`, {noProgress: noProgress});
  },

  editLead(_id, object, noProgress = true) {
    return Vue.axios.put(`lead/${_id}`, object, {noProgress: noProgress});
  },

  deleteLead(_id, noProgress = true) {
    return Vue.axios.delete(`lead/${_id}`, {noProgress: noProgress});
  },

  getLeadEnums(noProgress = true) {
    return Vue.axios.get(`lead/enums`, {noProgress: noProgress});
  },

  getSuperadmins(noProgress = false) {
    return Vue.axios.get(`user/list/g-sa-e`, {noProgress: noProgress});
  },

  sendLeadSMS(_id, object, noProgress = true) {
    return Vue.axios.post(`lead/send-sms/${_id}`, object, {noProgress: noProgress});
  },

  getLeadSMSHistory(_id, noProgress = true) {
    return Vue.axios.get(`sms/history/lead/${_id}`, {noProgress: noProgress});
  },

  sendSMS(object, noProgress = true) {
    return Vue.axios.post(`sms/send`, object, {noProgress: noProgress});
  },

  sendWhatsappMessage(object, noProgress = true) {
    return Vue.axios.post(`sms/whatsapp/send`, object, {noProgress: noProgress});
  },

  //helpdesk items
  getHelpdeskItems(status = 'all', noProgress = true) {
    return Vue.axios.get(`helpdesk/list/${status}`, {noProgress: noProgress});
  },

  getHelpdeskItemsActive(status = 'all', noProgress = true) {
    return Vue.axios.get(`helpdesk/list/${status}/active`, {noProgress: noProgress});
  },

  createHelpdeskItem(object, noProgress = true) {
    return Vue.axios.post(`helpdesk`, object, {noProgress: noProgress});
  },

  editHelpdeskItem(_id, object, noProgress = true) {
    return Vue.axios.put(`helpdesk/${_id}`, object, {noProgress: noProgress});
  },

  deleteHelpdeskItem(_id, noProgress = true) {
    return Vue.axios.delete(`helpdesk/${_id}`, {noProgress: noProgress});
  },

  getHelpdeskEnums(noProgress = true) {
    return Vue.axios.get(`helpdesk/enums`, {noProgress: noProgress});
  },

  getRoles(noProgress = true) {
    return Vue.axios.get(`role/list/all`, {noProgress: noProgress});
  },

  //calls
  getLeadCalls(_id, noProgress = true) {
    return Vue.axios.get(`call/history/lead/${_id}`, {noProgress: noProgress});
  },

  addCall(object, noProgress = true) {
    return Vue.axios.post(`call`, object, {noProgress: noProgress});
  },

  editCall(_id, object, noProgress = true) {
    return Vue.axios.put(`call/${_id}`, object, {noProgress: noProgress});
  },

  deleteCall(_id, noProgress = true) {
    return Vue.axios.delete(`call/${_id}`, {noProgress: noProgress});
  },

  //lead analytics
  getLeadAnalytics(object, noProgress = true) {
    return Vue.axios.post(`lead/analytics`, object, {noProgress: noProgress});
  },

  getLeadCounts(noProgress = true) {
    return Vue.axios.get(`lead/count`, {noProgress: noProgress});
  },

  getGrowcifyCounts(noProgress = true) {
    return Vue.axios.get(`store/sa/counts/unread`, {noProgress: noProgress});
  },

  //superadmin
  getCharts(noProgress = true) {
    return Vue.axios.get(`store/sa/charts`, {noProgress: noProgress});
  }

}
import Store from "../../services/store";
import Super from "../../services/super";

export default {
  name: "WhatsappMessage",

  props: ["editedItem", "countries", "number", "message", "messageSent"],

  methods: {
    send() {
      if (!this.$refs.form.validate()) return;

      Store.snackbarStart();
      this.processing = true;
      return Super.sendWhatsappMessage(this.messageItem, false)
        .then(() => {
          this.close();
          Store.snackbarSuccess();
          this.processing = false;
          this.$emit("update:messageSent", true);
        })
        .catch((e) => {
          console.log(e);
          Store.snackbarError();
          this.processing = false;
        });
    },

    close() {
      this.$store.state.whatsappMessageModal = false;
    },

    countryText(item) {
      return `(+${item.callingCode}) ${item.name}`;
    },
  },

  computed: {
    formTitle() {
      return "Send WhatsApp Message";
    },
  },

  watch: {
    "$store.state.whatsappMessageModal"(n) {
      if (n) {
        if (this.editedItem.number)
          this.messageItem.number = this.editedItem.number.substr(-10);
        if (this.editedItem.message) {
          let text = this.editedItem.message;
          const cu = this.$store.getters.userInfo;
          if (cu.mobile != "9829410994") {
            text = text.replace("The Growcify Team", cu.name);
            text = text.replace("8005771838", cu.mobile);
          }
          this.messageItem.message = text;
        }
        if (this.editedItem._id) this.messageItem.leadId = this.editedItem._id;
      }
    },
  },

  data() {
    return {
      valid: false,
      processing: false,

      error: false,
      success: false,
      messageItem: {
        callingCode: "91",
      },

      validationRules: {
        basicRule: [(v) => !!v || "It's required"],
      },
    };
  },
};

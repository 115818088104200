import Inventory from "../../../services/inventory";
import Store from "../../../services/store";
import FileUpload from "../../../services/file-upload";
import router from "../../../router";

import config from "../../../config";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "Popups",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Banners"))
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchPopups();
    this.fetchCategories();
    this.fetchVendors();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchPopups() {
      this.processing = this.loading = true;
      return Store.getPopups(this._id, false)
        .then((x) => {
          this.banners = x.data;
          this.banners.forEach((x, index) => (x.index = index + 1 + "."));
          this.processing = this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = false;
        });
    },

    fetchCategories() {
      let catRequest = Inventory.getCategories(this._id, false);
      if (this.isVendor)
        catRequest = Inventory.getVendorSubscribedCategories(this._id, false);

      return catRequest
        .then((res) => (this.categories = res.data))
        .catch((e) => console.log(e));
    },

    fetchVendors() {
      if (this.isVendor) return;
      return Store.getVendors(this._id, false)
        .then((res) => (this.vendors = res.data))
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.banners.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.editedItem.order = this.editedIndex >= 0 ? this.editedIndex + 1 : 1;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deletePopup(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchPopups();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true, true);
    },

    save(closeModal = true, fetchBanners = true, forceSave) {
      if (
        !forceSave &&
        (!this.editedItem.file ||
          (this.$refs.form && !this.$refs.form.validate()))
      ) {
        if (!this.editedItem.file) {
          this.showErr = "Video or image is required";
          setTimeout(() => {
            this.showErr = false;
          }, 2500);
        }

        this.$refs.form.validate();
        return;
      }

      Store.snackbarStart();
      this.processing = this.editedItem.processing = true;
      let request;

      const object = Object.assign({}, this.editedItem);
      if (
        !object.redirectToCategory ||
        object.redirectToCategory === "" ||
        object.redirectTo != "category"
      )
        delete object.redirectToCategory;
      if (
        !object.redirectToProduct ||
        object.redirectToProduct === "" ||
        object.redirectTo != "product"
      )
        delete object.redirectToProduct;
      if (
        !object.redirectToVendor ||
        object.redirectToVendor === "" ||
        object.redirectTo != "vendor"
      )
        delete object.redirectToVendor;

      if (this.editedItem._id)
        request = Store.editPopup(this.editedItem._id, object, false);
      else request = Store.createPopup(this._id, object, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          if (fetchBanners) this.fetchPopups();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.loading = this.editedItem.processing = false;
          Store.snackbarError();
        });
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileImage(file) {
      if (
        file &&
        (file["type"].split("/")[0] === "image" ||
          file["type"].split("/")[0] === "video")
      ) {
        this.editedItem.type = file["type"].split("/")[0];
        return true;
      }
      return false;
    },

    getURL(data) {
      if (
        data &&
        (data.split(".")[1] === "png" || data.split(".")[1] === "mp4")
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError() {
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.editedItem.file = "";
      this.editedItem.file = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(this._id, "popups", file, this.editedItem.type)
        .then((x) => {
          this.editedItem.file = x.data.file;
          this.uploading = false;
          if (this.editedItem._id) this.save(false, false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Create new pop-up" : "Edit pop-up";
    },

    isVendor() {
      return this.$store.state.activeStore.isVendor;
    },

    redirectionItems() {
      const items = [
        { key: "category", value: "Category" },
        { key: "url", value: "External URL" },
      ];

      if (!this.isVendor) items.push({ key: "vendor", value: "Vendor" });

      return items;
    },
  },

  data() {
    return {
      _id: "",
      banners: [],

      valid: false,
      processing: false,
      loading: false,
      uploading: false,
      showErr: false,
      dialog: false,

      search: "",

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
        type: "image",
        file: undefined,
      },

      confirmDialog: false,
      beingDeleted: {},
      rowItems: config.rowItems,

      categories: [],

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        { text: "Type", value: "type" },
        { text: "Status", value: "isActive" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It can not be empty"],
      },
    };
  },
};

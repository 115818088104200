import Store from "../../services/store";
import FileUpload from "../../services/file-upload";
import { getS3URL } from "../../helpers/misc";

export default {
  name: "KYCDocuments",

  props: ["_id", "store"],

  created() {
    if (!this.store.kycDocuments) {
      this.store.kycDocuments = [];
    } else {
      const docs = this.store.kycDocuments;
      docs.forEach((x) => {
        const objectInfo = {
          slug: x.slug,
          file: x.file,
          uploading: false,
          documentType: x.documentType,
        };

        if (x.slug == "company-cooperation-proof") {
          this.companyCooperationProof = objectInfo;
        } else if (x.slug == "company-address-proof") {
          this.companyAddressProof = objectInfo;
        } else if (x.slug == "company-gst-certificate") {
          this.companyGSTCertificate = objectInfo;
        } else if (x.slug == "company-pan-card") {
          this.companyPANCard = objectInfo;
        } else if (x.slug == "authorized-person-identity-proof") {
          this.personIdentityProof = objectInfo;
        } else if (x.slug == "authorized-person-tax-proof") {
          this.personTaxProof = objectInfo;
        }
      });
    }
  },

  methods: {
    getRefDoc(item) {
      const refType = this.$refs["inputDocUpload" + item.refSuffix][0];
      return refType;
    },

    pickFileDoc(item) {
      this.getRefDoc(item).click();
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    isValidDocument(file) {
      if (!file) return false;
      const type = file["type"].split("/")[0];
      const type2 = file["type"].split("/")[1];
      if (
        file &&
        (this.supportedFormats.includes(type) ||
          this.supportedFormats.includes(type2))
      ) {
        this.fileObject = {
          name: file["name"],
          size: file["size"],
          type: this.supportedFormats.includes(type) ? type : type2,
        };
        return true;
      }
      return false;
    },

    getURLDoc(data) {
      if (!data) return data;
      const ext = data.split(".")[1];
      if (data && (this.supportedFormats.includes(ext) || ext == "png")) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidDocError() {
      this.$store.state.snackbar.text = "Unsupported File Format";
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelected(item) {
      const file = this.getRefDoc(item).files[0];
      if (!this.isValidDocument(file)) return this.invalidDocError();

      Store.snackbarStart();
      item.uploading = true;
      this.uploading = true;
      return FileUpload.upload(
        this._id,
        "kyc-documents",
        file,
        this.fileObject.type
      )
        .then((x) => {
          if (!this.store.kycDocuments) {
            this.store.kycDocuments = [];
          }

          const file = x.data.file;
          item.file = file;

          const slugInfo = this.getDocumentInfoBySlug(item.slug);
          item.documentType = slugInfo.documentType;

          this.store.kycDocuments.push(item);
          this.saveStore(item);
          this.updateDocumentFile(item.slug, file);
          Store.snackbarSuccess();
        })
        .catch(() => {
          item.uploading = this.uploading = false;
          Store.snackbarError();
        });
    },

    updateDocumentFile(slug, file) {
      if (slug == "company-cooperation-proof") {
        this.companyCooperationProof.file = file;
      }

      if (slug == "company-address-proof") {
        this.companyAddressProof.file = file;
      }

      if (slug == "company-gst-certificate") {
        this.companyGSTCertificate.file = file;
      }

      if (slug == "company-pan-card") {
        this.companyPANCard.file = file;
      }

      if (slug == "authorized-person-identity-proof") {
        this.personIdentityProof.file = file;
      }

      if (slug == "authorized-person-tax-proof") {
        this.personTaxProof.file = file;
      }
    },

    saveStore(item) {
      return Store.updateStore(this._id, this.store, false)
        .then(() => {
          item.uploading = this.uploading = false;
        })
        .catch(() => {
          item.uploading = this.uploading = false;
        });
    },

    openDocument(item) {
      window.open(this.getURLDoc(item._id), "_blank");
    },

    getDocumentInfoBySlug(slug) {
      if (slug == "company-cooperation-proof") {
        return this.companyCooperationProof;
      }

      if (slug == "company-address-proof") {
        return this.companyAddressProof;
      }

      if (slug == "company-gst-certificate") {
        return this.companyGSTCertificate;
      }

      if (slug == "company-pan-card") {
        return this.companyPANCard;
      }

      if (slug == "authorized-person-identity-proof") {
        return this.personIdentityProof;
      }

      if (slug == "authorized-person-tax-proof") {
        return this.personTaxProof;
      }
    },

    isUploaded(item) {
      if (item.slug == "company-cooperation-proof") {
        return this.companyCooperationProof.file || false;
      }

      if (item.slug == "company-address-proof") {
        return this.companyAddressProof.file || false;
      }

      if (item.slug == "company-gst-certificate") {
        return this.companyGSTCertificate.file || false;
      }

      if (item.slug == "company-pan-card") {
        return this.companyPANCard.file || false;
      }

      if (item.slug == "authorized-person-identity-proof") {
        return this.personIdentityProof.file || false;
      }

      if (item.slug == "authorized-person-tax-proof") {
        return this.personTaxProof.file || false;
      }
    },
  },

  data() {
    return {
      companyCooperationProof: {
        slug: "company-cooperation-proof",
        documentType: "coi",
        file: "",
        uploading: false,
      },
      companyAddressProof: {
        slug: "company-address-proof",
        documentType: "bank-statement",
        file: "",
        uploading: false,
      },
      companyGSTCertificate: {
        slug: "company-gst-certificate",
        documentType: "gst-certificate",
        file: "",
        uploading: false,
      },
      companyPANCard: {
        slug: "company-pan-card",
        documentType: "pancard",
        file: "",
        uploading: false,
      },
      personIdentityProof: {
        slug: "authorized-person-identity-proof",
        documentType: "aadhar-card",
        file: "",
        uploading: false,
      },
      personTaxProof: {
        slug: "authorized-person-tax-proof",
        documentType: "pancard",
        file: "",
        uploading: false,
      },

      items: [
        {
          _id: 1,
          refSuffix: "cop",
          uploading: false,
          slug: "company-cooperation-proof",
          title: "Company / Firm - Cooperation Proof",
          subtitle: "Upload your company's cooperation proof",
          types: [
            {
              key: "coi",
              value: "Certificate of Incorporation",
            },
            {
              key: "apmc-mand-certificate",
              value: "APMC/Mandi License/Certificate",
            },
            {
              key: "labour-license-certificate",
              value: "Labour License/Certificate",
            },
            {
              key: "professional-tax-registration-certificate",
              value: "Professional Tax Registration Certificate",
            },
            {
              key: "trade-mark-registration-certificate",
              value: "Trade Mark Registration Certificate",
            },
            {
              key: "liquor-certificate",
              value: "Liquor License/Registration Certificate",
            },
            {
              key: "drug-license",
              value: "Drug License",
            },
            {
              key: "excise-customs-certificate",
              value:
                "Registration Certificate issued by Excise & Customs Department",
            },
            {
              key: "police-department-license",
              value: "Police Department Permission/License/Certificate",
            },
            {
              key: "inporter-exporter-certificate",
              value: "Importer - Exporter Code Certificate",
            },
            {
              key: "indian-medical-council-license",
              value: "Certificate/License issued by Indian Medical Council",
            },
            {
              key: "food-drug-control-license",
              value: "License issued by Food and Drug Control Authorities",
            },
            {
              key: "trade-license",
              value: "Trade License in the name of entity",
            },
            {
              key: "factory-registration-certificate",
              value: "Factory Registration Certificate in the name of entity",
            },
            {
              key: "sebi-registration-certificate",
              value: "SEBI Registration Certificate in the name of the entity",
            },
            {
              key: "shops-certificate-andhra-pradesh",
              value:
                "Shops & Establishment Certificate issued by E-Seva Kendra’s (Andhra Pradesh)",
            },
            {
              key: "provision-trade-license-west-bengal",
              value:
                "Provision trade license issued by Agartala Municipal Council / Grampanchayat in West Bengal",
            },
            {
              key: "pan-card-firm",
              value: "PAN Card in the Name of Firm",
            },
            {
              key: "partnership-registration-certificate",
              value:
                "Partnership registration certificate issued by Registrar of Firms",
            },
            {
              key: "license-under-explosive-act",
              value: "License issued under Explosive Act in the name of Firm",
            },
          ],
        },
        {
          _id: 2,
          refSuffix: "cap",
          uploading: false,
          slug: "company-address-proof",
          title: "Company / Firm - Address Proof",
          subtitle: "Upload your company's address proof",
          types: [
            {
              key: "coi",
              value: "Certificate of Incorporation",
            },
            {
              key: "utility-bill",
              value: "Utility (Telephone, Water, Electricity) Bill",
            },
            {
              key: "rental-agreement",
              value: "Rental Agreement",
            },
            {
              key: "bank-statement",
              value: "Bank Statement",
            },
            {
              key: "sale-deed",
              value: "Sale Deed",
            },
          ],
        },
        {
          _id: 3,
          refSuffix: "cgc",
          uploading: false,
          slug: "company-gst-certificate",
          title: "Company / Firm - GST Certificate",
          subtitle: "Upload your company's GST certificate",
          types: [
            {
              key: "gst-certificate",
              value: "GST Certificate",
            },
          ],
        },
        {
          _id: 4,
          refSuffix: "cpc",
          uploading: false,
          slug: "company-pan-card",
          title: "Company / Firm - PAN Card",
          subtitle: "Upload your company's PAN Card",
          types: [
            {
              key: "pancard",
              value: "PAN Card",
            },
          ],
        },
        {
          _id: 5,
          refSuffix: "apip",
          uploading: false,
          slug: "authorized-person-identity-proof",
          title: "Authorized Person - Identity Proof",
          subtitle: "Upload identity proof of the authorized person",
          types: [
            {
              key: "aadhar-card",
              value: "Aadhar Card",
            },
            {
              key: "driving-license",
              value: "Driving License",
            },
            {
              key: "passport",
              value: "Passport",
            },
          ],
        },
        {
          _id: 6,
          refSuffix: "aptp",
          uploading: false,
          slug: "authorized-person-tax-proof",
          title: "Authorized Person - PAN Card / Tax",
          subtitle: "Upload PAN Card / Tax of the authorized person",
          types: [
            {
              key: "pancard",
              value: "PAN Card",
            },
          ],
        },
      ],

      uploading: false,
      fileObject: {},
      supportedFormats: ["image", "pdf", "doc", "docx"],
    };
  },
};

import Store from '../../services/store';
import Super from '../../services/super';

export default {
  name: 'SMS',

  props: [
    'editedItem',
    'items',
    'messageSent'
  ],

  methods: {

    send() {
      if (!this.$refs.form.validate()) return;
      //if (!['lead', 'renewal'].includes(this.editedItem.type)) return;

      Store.snackbarStart();
      this.processing = true;
      const objectInfo = {
        flow_id: this.editedItem.flow_id,
        name: this.editedItem.name,
        number: this.editedItem.number,
        callingCode: this.editedItem.callingCode || '91',
        templateId: this.editedItem.templateId,
        sendWhatsappMessage: this.editedItem.sendWhatsappMessage,
        message: this.editedItem.message
      }
      const request = this.editedItem.type == 'lead' ? Super.sendLeadSMS(this.editedItem._id, objectInfo, false) : Super.sendSMS(objectInfo, false);
      return request
        .then(() => {
          this.close();
          Store.snackbarSuccess();
          this.processing = false;
          this.$emit('update:messageSent', true);
        })
        .catch(e => {
          console.log(e);
          Store.snackbarError();
          this.processing = false;
        });
    },

    close() {
      this.$store.state.smsModal = false;
    },

    text(item) {
      return item.title;
    } 
  },

  computed: {
    formTitle() {
      return this.editedItem._id && this.editedItem.type !== 'lead' ? 'Edit message' : 'Send a new message';
    }
  },

  watch: {
    selectedMessage(n) {
      const sms = this.items.find(x => x.id == n);
      this.editedItem.message = sms.message;
      this.editedItem.flow_id = sms.flow_id;
      this.editedItem.templateId = sms.templateId;
    },

    '$store.state.smsModal'(n) {
      if (n) this.selectedMessage = this.editedItem.id;
    }
  },

  data() {

    return {
      valid: false, 
      processing: false,
      selectedMessage: 1,

      error: false,
      success: false,

      validationRules: {
        basicRule: [
          v => !!v || 'It\'s required'
        ]
      }
    }
  }
}
<template>
  <v-dialog scrollable v-model="$store.state.domainInstructionModal" max-width="800" persistent>
    <v-card>
      <v-card-text style="padding-top: 15px;">
        <v-row>
          <v-col>
            <h5 class="mb-5"><b>Connecting your domain</b></h5>

            <div class="text-danger">[IMP: Your domain's nameservers must be pointed to <b>Cloudflare</b>.]</div>
            <div style="height: 15px"></div>

            <div>To connect your domain / subdomain, you need to log in to your provider account and change your settings. Follow the provider step-by-step instructions to get started. </div>
            <div style="height: 15px"></div>
            <p style="margin-bottom: 5px">
              A subdomain is a subset for your root domain at the beginning of a URL.<br>For example, in the URL <code>subdomain.example.com</code>, <code>subdomain</code> is the subdomain.
            </p>
            <p style="margin-bottom: 25px">
              A domain is the root domain. For example, in the URL <code>example.com</code>, <code>example.com</code> is the domain.
            </p>

            <v-divider></v-divider>

            <p>
              <b>To connect a domain/subdomain, complete the following steps.</b>
            </p>
            <ul>
              <li>Log in to the account that you have with your third-party domain provider. </li>
              <li>Find your DNS settings, domain management area, DNS configuration, or similar. </li>
              
              <li v-if="isWeb">[If you're connecting to the main domain]: In your DNS settings area, edit your A record and point to <code>75.2.60.5</code>.</li>
              <li><span v-if="isWeb">[If you're connecting to a subdomain]: </span> In your DNS settings area, edit your domain/subdomain's CNAME record to point to <code>{{ growcifyDomain }}</code>.</li>
              
              <ul>
                <li>In the <b>Host name</b> or <b>Alias</b> field, enter the subdomain that you want to connect to your Growcify admin.</li>
                <li>In the <b>Type</b> or <b>Record Type</b> field, enter or choose the <b>CNAME</b> record. </li>
                <li>In the Points to field, enter <code>{{ growcifyDomain }}</code> as the destination for the CNAME record. CNAME records must always point to a domain name, never to an IP address.</li>
              </ul>
              <li>Save the CNAME Record by clicking <b>Save</b>, <b>Save Zone File</b>, <b>Add record</b>, or similar. </li>

              <li>If it does not work, please reach our team for support and help.</li>
            </ul>

            <p>Note: It might take up to 48 hours for your subdomain to point to your Growcify. If you have problems or need help configuring your third-party domain, then you can reach out to your domain provider or contact our tech team at <b>team@growcify.com</b>.</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            <v-btn outlined @click="$store.state.domainInstructionModal = false">
              Close
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'growcifyDomain',
    'isWeb'
  ]
}
</script>
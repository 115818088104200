var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.tasks.includes('calls'))?_c('section',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-list-item-subtitle',[_c('b',{class:_vm.isDT ? 'text-white' : 'text-black'},[_vm._v("Calls")])])],1)],1),_c('div',{staticStyle:{"height":"15px"}}),_c('v-data-table',{attrs:{"headers":_vm.callColumns,"items":_vm.callItems,"loading":_vm.cloading,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"item.lead.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lead.name || '-')+" ")]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [_c('TextWrapper',{attrs:{"text":item.remarks,"length":60}})]}},{key:"item.calledFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.from || '-')+" ")]}},{key:"item.calledTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" +"+_vm._s(item.callingCode)+" "+_vm._s(item.to || '-')+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.duration || '-')+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.createdByName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdBy && item.createdBy.name ? item.createdBy.name : '-')+" ")]}}],null,false,2006038223)})],1):_vm._e(),(_vm.tasks.includes('meetings'))?_c('section',[_c('div',{staticStyle:{"height":"50px"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-list-item-subtitle',[_c('b',{class:_vm.isDT ? 'text-white' : 'text-black'},[_vm._v("Meetings")])])],1)],1),_c('div',{staticStyle:{"height":"15px"}}),_c('v-data-table',{attrs:{"headers":_vm.meetingColoumns,"items":_vm.meetingsItems,"loading":_vm.mloading,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"item.lead.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lead ? item.lead.name : '-')+" ")]}},{key:"item.summary",fn:function(ref){
var item = ref.item;
return [_c('TextWrapper',{attrs:{"text":item.summary,"length":50}})]}},{key:"item.attendees",fn:function(ref){
var item = ref.item;
return [_c('TextWrapper',{attrs:{"text":item.attendees.join(', '),"length":50}})]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.start))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.scheduledByName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.scheduledBy && item.scheduledBy.name ? item.scheduledBy.name : '-')+" ")]}}],null,false,2628995544)})],1):_vm._e(),(_vm.tasks.includes('sms'))?_c('section',[_c('div',{staticStyle:{"height":"50px"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-list-item-subtitle',[_c('b',{class:_vm.isDT ? 'text-white' : 'text-black'},[_vm._v("Leads Assigned")])])],1)],1),_c('div',{staticStyle:{"height":"15px"}}),_c('v-data-table',{attrs:{"headers":_vm.leadColumns,"items":_vm.leads,"loading":_vm.lloading,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name || '-')+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.city || '-')+" ")]}},{key:"item.contactNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contactNumber)+" ")]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [_c('TextWrapper',{attrs:{"text":item.remarks || '-',"length":75}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.convertedAt",fn:function( ref ){
var item = ref.item;
return [(item.convertedAt)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.convertedAt, 'll'))+" ("+_vm._s(_vm.getDays(item.convertedAt, item.createdAt))+" days)")]):_c('span',[_vm._v("-")])]}},{key:"item.assignedTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignedTo && item.assignedTo.name ? item.assignedTo.name : '-')+" ")]}}],null,false,3765913722)})],1):_vm._e(),(_vm.tasks.includes('sms'))?_c('section',[_c('div',{staticStyle:{"height":"50px"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-list-item-subtitle',[_c('b',{class:_vm.isDT ? 'text-white' : 'text-black'},[_vm._v("Messages")])])],1)],1),_c('div',{staticStyle:{"height":"15px"}}),_c('v-data-table',{attrs:{"headers":_vm.smsColumns,"items":_vm.smsItems,"loading":_vm.sloading,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"item.lead.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lead.name || '-')+" ")]}},{key:"item.sentTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" +"+_vm._s(item.callingCode || '91')+" "+_vm._s(item.mobile)+" ")]}},{key:"item.sentFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type == 'SMS' ? 'GROCFY' : '+91 8005771838')+" ")]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('TextWrapper',{attrs:{"text":item.message,"length":75}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.sentByName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sentBy && item.sentBy.name ? item.sentBy.name : '-')+" ")]}}],null,false,947127039)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
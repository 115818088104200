import Inventory from "../../../services/inventory";
import Store from "../../../services/store";
import config from "../../../config";
import { getS3URL } from "../../../helpers/misc";
import Papa from "papaparse";

export default {
  name: "ProductsImport",

  created() {
    this._id = this.$route.params._id;
    this.fetchEnums();
    this.initPusher();
  },

  methods: {
    // nextStep() {
    //   if (this.cstep === 1) {
    //     this.uploading = true;
    //     return FileUpload.upload(this._id, "files", this.file, "csv")
    //       .then((x) => {
    //         this.uploading = false;
    //         this.csvurl = this.getURL(x.data.file);
    //         this.processCSV();
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //         this.uploading = false;
    //       });
    //   } else if (this.cstep == 2) {
    //     let isInvalid = false;
    //     this.processedItems.forEach((x) => {
    //       if (!this.$refs["form_" + x.name][0].validate())
    //         x.expanded = isInvalid = true;
    //     });

    //     if (!isInvalid) this.processProducts();
    //   }
    // },

    // processCSV() {
    //   this.processingCSV = true;
    //   return Store.processCSV(this._id, this.csvurl)
    //     .then((x) => {
    //       this.processedItems = x.data;
    //       this.processedItems.forEach((x) => {
    //         if (!this.enums.vegan.includes(x.vegan)) x.vegan = undefined;
    //       });
    //       this.processingCSV = false;
    //       this.cstep++;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       this.processingCSV = false;
    //     });
    // },

    // processProducts() {
    //   this.processing = true;
    //   return Store.processProducts(this._id, this.processedItems)
    //     .then(() => {
    //       this.processing = false;
    //       this.csvurl = this.file = "";
    //       this.processedItems = [];
    //       this.cstep++;
    //       setTimeout(() => this.$router.back(), 2500);
    //     })
    //     .catch((e) => {
    //       this.processing = false;
    //       this.insErr =
    //         "Insertion operation could not complete. Please check if data is correct and try again.";
    //       setTimeout(() => (this.insErr = ""), 2500);
    //     });
    // },

    initPusher() {
      const channelName = this._id;
      const eventName = "productsImportCompleted";

      const pusher = this.$store.state.pusher;
      let channel = pusher.channel(channelName);
      if (!channel) channel = pusher.subscribe(channelName);

      if (
        this.$store.state.pusherBusinessEvents.includes("products:" + eventName)
      ) {
        return;
      }

      this.$store.state.pusherBusinessEvents.push("products:" + eventName);
      channel.bind(eventName, () => {
        this.importCompleted();
      });

      // event 2
      const eventName2 = "productsImportFailed";
      if (
        this.$store.state.pusherBusinessEvents.includes(
          "products:" + eventName2
        )
      ) {
        return;
      }

      this.$store.state.pusherBusinessEvents.push("products:" + eventName2);
      channel.bind(eventName2, () => {
        this.importFailed();
      });
    },

    importCompleted() {
      this.cstep++;
      this.iProducts = [];
      this.processing = false;
      setTimeout(() => this.$router.back(), 2000);
      this.$notification.show("Your import is completed! ✌️", {}, {});
      this.$store.state.backgroundProcesses.pop();
    },

    importFailed() {
      this.processing = false;
      this.$notification.show("Your import failed!", {}, {});
      this.throwError(
        "Your import failed. Please check the CSV file again and make sure you're following rules correctly. If you need help, contact our team. Thanks."
      );
      this.$store.state.backgroundProcesses.pop();
    },

    throwError(message) {
      this.$store.state.snackbar.text = message;
      this.$store.state.snackbar.show = true;
      this.$store.state.snackbar.color = "error";
    },

    isValidItem(item) {
      let valid = false;
      if (
        item._id ||
        (item.name &&
          item.categoryID &&
          item.size &&
          item.price >= 0 &&
          !isNaN(item.quantity) &&
          item.inStock)
      ) {
        valid = true;
      }

      return valid;
    },

    getColumnText(value) {
      let str = value;
      if (!str) return str;

      if (
        [
          "name",
          "size",
          "unit",
          "quantity",
          "price",
          "marketPrice",
          "purchasePrice",
          "sequenceNumber",
          "description",
          "tags",
          "color",
          "vegan",
        ].includes(str)
      ) {
        str = this.$options.filters.firstLetterUppercase(str);
      } else if (["sku", "gstn", "gst"].includes(str)) {
        str = str.toUpperCase(str);
      } else if (str == "categoryID") {
        str = "Category";
      } else if (str == "brandID") {
        str = "Brand";
      } else if (str == "orderCap") {
        str = "Order Cap";
      } else if (str == "inStock") {
        str = "In Stock?";
      } else if (str == "hsnSacCode") {
        str = "HSN/SAC Code";
      } else if (str == "barcodeID") {
        str = "Barcode ID";
      } else if (str == "barcodeText") {
        str = "Barcode Text";
      } else if (str == "isComingSoon") {
        str = "Is Coming Soon?";
      } else if (str == "isNewArrival") {
        str = "Is New Arrival?";
      } else if (str == "isTrending") {
        str = "Is Trending?";
      } else if (str == "isRecommended") {
        str = "Is Recommended?";
      } else if (str == "isFeatured") {
        str = "Is Featured?";
      }

      return str;
    },

    nextStep() {
      this.processingCSV = true;
      const file = this.file;
      Papa.parse(file, {
        complete: (results) => {
          const list = results.data;

          if (list && list.length > 1001) {
            this.processingCSV = false;
            this.throwError("Up to 1000 items are supported");
          } else {
            // process all contacts for preview
            const iHeaders = [];
            const iProducts = [];

            list.forEach((item, index) => {
              if (!index) {
                item.forEach((x, i) => {
                  if (!iHeaders.includes(x)) {
                    iHeaders.push({
                      text: this.getColumnText(x),
                      value: x,
                    });
                  }
                });
              } else {
                const iObject = {};
                iHeaders.forEach((i, i2) => {
                  iObject[i.value] = item[i2];
                });
                const aiobj = iProducts.find(
                  (x) =>
                    x._id == iObject._id &&
                    x.name == iObject.name &&
                    x.categoryID == iObject.categoryID &&
                    x.size == iObject.size &&
                    x.unit == iObject.unit &&
                    x.price == iObject.price
                );
                if (!aiobj && this.isValidItem(iObject)) {
                  iObject.expanded = false;
                  iProducts.push(iObject);
                }
              }
            });

            this.iHeaders = iHeaders;
            this.iProducts = iProducts;

            this.iHeaders.unshift({
              text: "#",
              value: "index",
              sortable: false,
            });
          }
          this.processingCSV = false;
          this.cstep++;
        },
      });
    },

    importItems() {
      this.processing = true;
      return Store.heavyImportProducts(this._id, this.iProducts)
        .then(() => {
          this.importStarted();
        })
        .catch((e) => {
          this.processing = false;
          this.throwError(
            "Insertion operation could not complete. Please check if data is correct and try again."
          );
        });
    },

    importStarted() {
      this.$store.state.snackbar.text =
        "Your import request has been submit. Once the import is completed, you'll be notified and redirected automatically. Thanks.";
      this.$store.state.snackbar.timeout = 5000;
      this.$store.state.snackbar.color = "success";
      this.$store.state.snackbar.show = true;

      this.$store.state.backgroundProcesses.push("products");
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    fetchEnums() {
      let catRequest;
      if (this.$store.state.activeStore.isVendor) {
        catRequest = Inventory.getVendorCategories(this._id, false);
      } else {
        catRequest = Inventory.getCategories(this._id, false);
      }

      const p1 = catRequest
        .then((res) => (this.categories = res.data))
        .catch((e) => console.log(e));

      const p2 = Inventory.getProductEnums(this._id)
        .then((res) => (this.enums = res.data))
        .catch((e) => console.log(e));

      const p3 = Inventory.getParentProducts(this._id)
        .then((res) => (this.products = res.data))
        .catch((e) => console.log(e));

      let brandRequest;
      if (this.$store.state.activeStore.isVendor) {
        brandRequest = Inventory.getVendorBrands(this._id, false);
      } else {
        brandRequest = Inventory.getBrands(this._id, false);
      }

      const p4 = brandRequest
        .then((res) => (this.brands = res.data))
        .catch((e) => console.log(e));

      const p5 = Store.getCommonEnums()
        .then((res) => (this.cenums = res.data))
        .catch((e) => console.log(e));

      return Promise.all([p1, p2, p3, p4, p5]);
    },

    getRef() {
      return this.$refs.inputUpload[0]
        ? this.$refs.inputUpload[0]
        : this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    isFileCSV(file) {
      if (
        file &&
        (file["type"].split("/")[1] === "csv" ||
          file["type"] === "application/vnd.ms-excel")
      )
        return true;
      return false;
    },

    getURL(data) {
      const array = data.split(".");
      if (
        data &&
        !array.includes(config.growcifyMasterInventory) &&
        (array[1] === "png" || array[1] === "csv")
      ) {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidFileError() {
      this.file = "";
      this.showErr = "Invalid file format";
      setTimeout(() => {
        this.showErr = false;
      }, 2000);
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isFileCSV(file)) return this.invalidFileError();
      this.file = file;

      this.nextStep(); //auto process
    },

    removeItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      const index = this.processedItems.indexOf(this.beingDeleted);
      this.processedItems.splice(index, 1);
      setTimeout(() => {
        this.confirmDialog = this.processing = false;
      }, 500);
    },

    text(item) {
      return item.name + " (" + item.size + item.unit + ")";
    },
  },

  computed: {
    indexProducts() {
      return this.iProducts.map((x, index) => {
        x.index = index + 1 + ".";
        return x;
      });
    },
  },

  data() {
    return {
      _id: "",
      cstep: 1,
      valid: false,

      file: "",
      csvurl: "",

      uploading: false,
      showErr: false,

      processingCSV: false,
      processedItems: [],

      insErr: "",
      enums: {},
      cenums: {},
      categories: [],
      brands: [],
      search: "",

      confirmDialog: false,
      beingDeleted: {},

      processing: false,
      iProducts: [],
      iHeaders: [],

      footerList: [5, 10, 15, 25, 50, 100, 250, 500, 1000],

      validationRules: {
        quantityRule: [(v) => !isNaN(v) || "Enter a valid number"],
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => !!v || v === false || "It is required"],
        inlineRule: [(v) => !!v || ""],
        sizeRule: [(v) => !!v || "Size is required"],
        unitRule: [(v) => !!v || v === "" || "Unit is required"],
        categoryRule: [
          (v) =>
            (!!v && this.categories.findIndex((c) => v === c._id) >= 0) ||
            "Category is required",
        ],
      },
    };
  },
};

<template>
  <section>
    <v-chip small v-if="['new', 'completed'].includes(status)" color="primary">
      {{ capitalize }}
    </v-chip>
    <v-chip small v-else-if="status === 'paused'" color="warning">
      {{ capitalize }}
    </v-chip>
    <v-chip small v-else-if="status === 'active'" color="success">
      {{ capitalize }}
    </v-chip>
    <v-chip small v-else-if="status === 'cancelled'" color="error">
      {{ capitalize }}
    </v-chip>
    <v-chip small v-else>{{ capitalize }}</v-chip>
  </section>
</template>

<script>
export default {
  name: "SubscriptionStatus",

  props: ["status"],

  computed: {
    capitalize() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1);
    },
  },
};
</script>

import TicketInfo from '../../components/ticket-info/ticket-info.vue';

export default {
  name: 'Ticket',

  created() {
    this.ticketId = this.$route.params.ticketId;
  },

  components: {
    TicketInfo
  },

  data() {
    return {
      ticketId: '',
    }
  }
}
import moment from 'moment';

import Super from '../../services/super';
import Store from '../../services/store';

export default {
  name: 'Helpdesk',

  created() {
    if (!this.canProceed) return Store.redirectToHome();  
    
    this.fetchHelpdeskItems();
    this.fetchCommonEnums();
    this.fetchHelpdeskEnums();
  },

  methods: {
    fetchHelpdeskItems() {
      this.loading = true;
      return Super.getHelpdeskItems('all', false)
        .then(res => {
          this.helpdeskItems = res.data.map((x, index) => {
            x.index = index + 1 + '.';
            x.ago = moment(x.createdAt).format('lll');
            return x;
          });
          this.loading = false;
        })
        .catch(e => { console.log(e); this.loading = false; });
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return this.cenums = ce;

      return Store.getCommonEnums()
        .then(x => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch(e => console.log(e));
    },

    fetchHelpdeskEnums() {
      return Super.getHelpdeskEnums()
        .then(res => {
          this.enums = res.data;
          this.enums.modules = this.enums.modules.sort();
        })
        .catch(e => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.helpdeskItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Super.deleteHelpdeskItem(this.beingDeleted._id, false)
        .then(() => { this.confirmDialog = this.processing = false; this.fetchHelpdeskItems(); })
        .catch(e => { this.confirmDialog = this.processing = false; console.log(e)});
    },

    close() {
      this.dialog = this.processing = this.loading = false
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    toggleTranslationModal(field) {
      this.translationModal = !this.translationModal;
      this.translationField = field;
    },

    saveTranslation() {
      this.translationModal = !this.translationModal;
      if (this.editedItem._id) this.save(false, false);
    },

    // eslint-disable-next-line
    save(closeModal = true, farceSave = false) {
      if (this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();

      const object = Object.assign({}, this.editedItem);

      let request;
      if (this.editedItem._id) request = Super.editHelpdeskItem(this.editedItem._id, object, false)
      else request = Super.createHelpdeskItem(object, false);
          
      return request 
        .then(() => {
          if (closeModal) this.close();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
          this.fetchHelpdeskItems();
        })
        .catch(e => {
          if (e.response.status == 400) {
            this.$store.state.snackbar.text = 'Helpdesk item already exists';
            this.$store.state.snackbar.timeout = 2500;
            this.$store.state.snackbar.color = 'error';
            this.$store.state.snackbar.show = true;
          } else Store.snackbarError();
          this.processing = this.loading = this.editedItem.processing = false;
        });
    },

    text(item) {
      return item.name || item.mobile;
    },

    allAction() {
      if (this.editedItem.modules && this.editedItem.modules.length) return this.editedItem.modules = [];
      this.editedItem.modules = this.enums.modules;
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? (this.editedItem.type == 'faq' ? 'Add new FAQ' : 'Publish new video') : (this.editedItem.type == 'faq' ? 'Edit FAQ' : 'Edit video');
    },

    canProceed() {
      return (Store.amISuperadmin(this.$store) || Store.amIGrowcifyEmployee(this.$store)) && this.$store.state.isGD;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    }
  },

  data: () => {
    return {
      helpdeskItems: [],
      search: '',

      loading: true,
      valid: false,
      dialog: false,
      processing: false,

      error: '',
      cenums: {},
      enums: {},

      editedIndex: -1,
      editedItem: {},
      defaultItem: { },
      menu: false,

      confirmDialog: false,
      beingDeleted: {},
      users: [],

      translationModal: false,
      translationField: '',

      headers: [
        { text: '#', align: 'start', value: 'index' },
        { text: 'Type', value: 'type'},
        { text: 'Title',  value: 'title' },
        { text: 'Description', value: 'description' },
        { text: 'Status', value: 'isActive' },
        { text: 'Date & Time', value: 'ago' },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false}
      ],

      validationRules: {
        basicRule: [
          v => !!v || 'It is required'
        ],
        statusRule: [
          v => v || v === false || 'It is required'
        ],
        callingCode: [
          v => v >= 0 || 'Enter digits only'
        ]
      }
    }
  }
}
import Store from "../../../services/store";
import router from "../../../router";
import moment from "moment";

export default {
  name: "VendorInfo",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Vendors") || this.$store.state.activeStore.isVendor) {
      return router.push("/business/" + this._id);
    }

    if (!this.doIHaveAccess("actions")) {
      this.rheaders = this.rheaders.filter((x) => x.text !== "Actions");
    }

    this.vendorId = this.$route.params.vendorId;
    this.fetchVendor();
    this.fetchVendorReviews();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchVendor() {
      this.loading = true;
      return Store.getVendor(this.vendorId, false)
        .then((res) => {
          const data = res.data;
          this.vendor = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          router.push("/business/" + this._id);
        });
    },

    fetchVendorReviews() {
      this.rloading = true;
      return Store.getVendorReviews(this._id, this.vendorId, false)
        .then((response) => {
          const data = response.data || [];
          data.forEach((item, index) => (item.index = index + 1 + "."));
          this.reviews = data;
          this.rloading = false;
        })
        .catch(() => {
          this.rloading = false;
        });
    },

    getCallingCode(item) {
      return item.callingCode || this.country.callingCode || "91";
    },

    getMobile(item) {
      return "+" + this.getCallingCode(item) + " " + item.mobile;
    },

    formatDate(date, format = "lll") {
      return moment(date).format(format);
    },

    closeConfirmReview() {
      this.confirmDialogReview = false;
      this.beingDeletedReview = {};
    },

    deleteReviewItem(item) {
      this.confirmDialogReview = true;
      this.beingDeletedReview = item;
    },

    finalDeleteReview() {
      this.processing = true;
      return Store.deleteVendorReview(this.beingDeletedReview._id, false)
        .then(() => {
          this.closeConfirmReview();
          this.processing = false;
          this.fetchVendorReviews();
        })
        .catch((e) => {
          this.processing = false;
          console.log(e);
        });
    },
  },

  computed: {
    country() {
      return this.$store.state.activeStore.country;
    },
  },

  data: () => {
    return {
      _id: "",
      vendorId: "",
      loading: true,
      processing: false,

      vendor: {},
      headers: [],

      confirmDialogReview: false,
      beingDeletedReview: {},
      rsearch: "",
      reviews: [],
      rloading: false,
      reviewEditedItem: {},
      rheaders: [
        {
          text: "#",
          align: "start",
          value: "index",
        },
        { text: "Customer", value: "user.name" },
        { text: "Rating", value: "rating" },
        { text: "Review", value: "review", width: "50%" },
        { text: "Date & Time", value: "createdAt", align: "right" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    };
  },
};

import Store from "../../../services/store";
import router from "../../../router";

export default {
  name: "ContactUs",

  created() {
    this._id = this.$route.params._id;
    if (this.$store.state.activeStore.isVendor)
      return router.push("/business/" + this._id);
    return this.fetchContact();
  },

  methods: {
    fetchContact() {
      if (!this._id) return;
      this.loading = true;
      return Store.getContact(this._id, false)
        .then((res) => {
          if (res.data) this.editedItem = res.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    save() {
      if (!this.editedItem.description) {
        this.error = "Contact us is empty";
        setTimeout(() => (this.error = false), 2500);
        return;
      }

      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.editedItem._id)
        request = Store.editContact(
          this.editedItem._id,
          this.editedItem,
          false
        );
      else request = Store.addContact(this._id, this.editedItem, false);

      return request
        .then((x) => {
          this.success = "Saved successfully";
          setTimeout(() => (this.success = false), 1500);
          this.processing = false;
          if (!this.editedItem._id) this.editedItem._id = x.data._id;
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data() {
    return {
      _id: "",
      tab: 0,
      items: ["Contact Us", "Preview"],

      editedItem: {},

      error: "",
      success: "",

      processing: false,
      loading: false,
    };
  },
};

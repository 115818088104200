import moment from "moment";

import router from "../../../router";
import Store from "../../../services/store";
import FileUpload from "../../../services/file-upload";

import GroupForm from "../../../components/group-form/group-form.vue";
import BannerForm from "../../../components/banner-form/banner-form.vue";
import Stories from "../../../components/stories/stories.vue";
import OnboardingScreens from "../../../components/onboarding-screens/onboarding-screens.vue";
import DeprecatedWarning from "../../../components/deprecated-warning/deprecated-warning.vue";
import { getS3URL } from "../../../helpers/misc";

export default {
  name: "DesignStudio",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Web Studio") && !this.amIAllowed("Design Studio")) {
      return router.push("/business/" + this._id);
    }

    if (!this.doIHaveAccess("actions")) {
      this.bannerHeaders = this.bannerHeaders.filter(
        (x) => x.text !== "Actions"
      );
      this.groupHeaders = this.groupHeaders.filter((x) => x.text !== "Actions");
    }

    this.fetchMainBanners();
    this.fetchMiniBanners();
    this.fetchWebstore();
    this.fetchGroups();
  },

  components: {
    BannerForm,
    GroupForm,
    Stories,
    OnboardingScreens,
    DeprecatedWarning,
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchMainBanners() {
      this.miloading = true;
      return Store.getMainBanners(this._id, false)
        .then((x) => {
          this.mainBanners = x.data;
          this.mainBanners.forEach((x, index) => (x.index = index + 1 + "."));
          this.miloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.miloading = false;
        });
    },

    fetchMiniBanners() {
      this.mnloading = true;
      return Store.getMiniBanners(this._id, false)
        .then((x) => {
          this.miniBanners = x.data;
          this.miniBanners.forEach((x, index) => (x.index = index + 1 + "."));
          this.mnloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.mnloading = false;
        });
    },

    fetchGroups() {
      this.gloading = true;
      return Store.getGroups(this._id, false)
        .then((x) => {
          this.groups = x.data;
          this.groups.forEach((x, index) => (x.index = index + 1 + "."));
          this.gloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.gloading = false;
        });
    },

    fetchWebstore() {
      this.wloading = false;
      return Store.getWebstore(this._id, false)
        .then((x) => {
          this.webstore = x.data || {};
          this.wloading = false;
        })
        .catch((e) => {
          console.log(e);
          this.wloading = false;
        });
    },

    formatDateTime(value) {
      return moment(value).format("lll");
    },

    editBannerItem(item) {
      this.editedBannerItem = Object.assign({}, item);
      this.$store.state.bannerModel = true;
    },

    editGroupItem(item) {
      this.editedGroupItem = Object.assign({}, item);
      this.$store.state.groupModel = true;
    },

    selectColor() {
      if (typeof this.selectedColor === "string")
        this.webstore.color = this.selectedColor;
      else this.webstore.color = this.selectedColor.hex;
      this.colorDialog = false;
    },

    deleteBannerItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      if (this.beingDeleted.of) return this.finalDeleteGroup();
      this.processing = true;
      return Store.deleteBanner(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.bannerUpdated = true;
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    finalDeleteGroup() {
      this.processing = true;
      return Store.deleteGroup(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.groupUpdated = true;
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    changeBannerStatus(item) {
      this.editedBannerItem = Object.assign({}, item);
      this.saveBanner();
    },

    saveBanner() {
      Store.snackbarStart();
      this.processing = this.editedBannerItem.processing = true;

      const object = Object.assign({}, this.editedBannerItem);
      return Store.editBanner(this.editedBannerItem._id, object, false)
        .then(() => {
          this.bannerUpdated = true;
          this.processing = this.editedBannerItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.editedBannerItem.processing = false;
          Store.snackbarError();
        });
    },

    setCategoryGridUI(grid) {
      this.webstore.categoryGridUI = grid;
    },

    setProductGridUI(grid) {
      this.webstore.productGridUI = grid;
    },

    setVendorGridUI(grid) {
      this.webstore.vendorGridUI = grid;
    },

    saveWebstore() {
      if (this.$refs.form2 && !this.$refs.form2[0].validate()) return;
      Store.snackbarStart();
      this.processing = true;
      let request;

      const object = Object.assign({}, this.webstore);
      if (this.webstore._id)
        request = Store.editWebstore(this.webstore._id, object, false);
      else request = Store.createWebstore(this._id, object, false);

      return request
        .then((x) => {
          this.webstore._id = x.data._id;
          this.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          Store.snackbarError();
        });
    },

    changeGroupStatus(item) {
      this.editedGroupItem = Object.assign({}, item);
      this.saveGroup();
    },

    saveGroup() {
      Store.snackbarStart();
      this.processing = this.editedGroupItem.processing = true;

      const object = Object.assign({}, this.editedGroupItem);
      return Store.editGroup(this.editedGroupItem._id, object, false)
        .then(() => {
          this.groupUpdated = true;
          this.processing = this.editedGroupItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          console.log(e);
          this.processing = this.editedGroupItem.processing = false;
          Store.snackbarError();
        });
    },

    getRef(refType) {
      if (refType == "favicon") return this.$refs["inputFavicon"][0];
      return this.$refs["inputLogo"][0];
    },

    pickFile(refType) {
      this.getRef(refType).click();
    },

    isFileImage(file) {
      return file && file["type"].split("/")[0] === "image";
    },

    getURL(data) {
      if (data && data.split(".")[1] === "png") {
        return getS3URL(this._id, data);
      }
      return data;
    },

    invalidImageError() {
      this.$store.state.snackbar.text = "Invalid File Format";
      this.$store.state.snackbar.timeout = 2500;
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelected(type) {
      const file = this.getRef(type).files[0];
      if (!this.isFileImage(file)) return this.invalidImageError();

      this.webstore[type] = "";
      this.webstore[type] = URL.createObjectURL(file);

      if (type == "logo") this.luploading = true;
      else this.fuploading = true;

      return FileUpload.upload(this._id, "webstore", file)
        .then((x) => {
          this.webstore[type] = x.data.file;
          this.luploading = this.fuploading = false;
          if (this.webstore._id) this.saveWebstore();
        })
        .catch((e) => {
          console.log(e);
          this.luploading = this.fuploading = false;
        });
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    canCreate() {
      return this.doIHaveAccess("create");
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  watch: {
    bannerUpdated(n) {
      if (n) {
        if (
          this.editedBannerItem.bannerType == "main" ||
          this.beingDeleted.bannerType == "main"
        )
          this.fetchMainBanners();
        if (
          this.editedBannerItem.bannerType == "mini" ||
          this.beingDeleted.bannerType == "mini"
        )
          this.fetchMiniBanners();
      }
      this.bannerUpdated = false;
    },

    groupUpdated(n) {
      if (n) this.fetchGroups();
      this.groupUpdated = false;
    },
  },

  data() {
    return {
      _id: "",
      valid2: false,
      loading: false,
      processing: false,

      miloading: false,
      mnloading: false,
      gloading: false,
      wloading: false,

      fuploading: false,
      luploading: false,

      mbsearch: "",
      mnsearch: "",
      mainBanners: [],
      miniBanners: [],
      bannerModel: false,

      editedBannerIndex: -1,
      editedBannerItem: {},

      defaultMainBanner: {
        type: "image",
        for: "web",
        isActive: true,
        bannerType: "main",
        contentAlignment: "left",
        buttonText: "Shop Now",
        showContent: false,
        placement: "before",
      },
      defaultMiniBanner: {
        type: "image",
        for: "web",
        isActive: true,
        bannerType: "mini",
        contentAlignment: "left",
        buttonText: "Shop Now",
        showContent: false,
        placement: "before",
      },

      gsearch: "",
      groups: [],
      groupModel: false,
      groupUpdated: false,
      editedGroupItem: {},
      defaultGroupItem: {
        isActive: true,
        categories: [],
        products: [],
        vendors: [],
        of: "products",
      },

      colorDialog: false,
      webstore: {
        title: "",
        color: "",
      },
      selectedColor: {},

      bannerHeaders: [
        { text: "#", align: "start", value: "index", sortable: false },
        { text: "Title", align: "start", value: "title" },
        { text: "Status", value: "isActive", sortable: false },
        { text: "Actions", align: "right", value: "actions", sortable: false },
      ],

      groupHeaders: [
        { text: "#", align: "start", value: "index", sortable: false },
        { text: "Title", align: "start", value: "title" },
        { text: "Group Of", value: "of", sortable: false },
        { text: "Sequence", value: "sequenceNumber" },
        { text: "Status", value: "isActive", sortable: false },
        { text: "Actions", align: "right", value: "actions", sortable: false },
      ],

      categories: [],
      products: [],
      vendors: [],

      searchInput: "",
      lastSearched: "",
      isSearching: false,
      outstandingRequests: 0,
      lastRequest: 0,
      lastResponse: 0,

      bannerUpdated: false,
      confirmDialog: false,
      beingDeleted: {},

      studioTab: "",
      tabs: [
        { _id: "both", title: "App & Web Both" },
        { _id: "app", title: "App Only" },
        { _id: "web", title: "Web Only" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
      },
    };
  },
};

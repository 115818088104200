import moment from "moment";

import Store from "../../../services/store";
import router from "../../../router";
import config from "../../../config";
import getSymbolFromCurrency from "currency-symbol-map";
import SupplierForm from "../../../components/supplier-form/supplier-form.vue";

export default {
  name: "Suppliers",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Suppliers")) {
      return router.push("/business/" + this._id);
    }

    if (!this.doIHaveAccess("actions")) {
      this.headers = this.headers.filter((x) => x.text !== "Actions");
    }

    this.fetchSuppliers();
  },

  components: {
    SupplierForm,
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchSuppliers() {
      this.loading = true;
      return Store.getSuppliers(this._id, false)
        .then((res) => {
          this.suppliers = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            return x;
          });

          this.processPayments();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    processPayments() {
      if (!this.suppliers || !this.suppliers.length) return;

      const toPay = [];
      const toCollect = [];

      this.suppliers.forEach((x) => {
        const cc = x.currencyCode || "INR";

        if (x.balanceType == "pay") {
          const ccPayment = toPay.find((i) => i.currencyCode == cc);
          if (ccPayment) {
            if (x.balance) ccPayment.balance = ccPayment.balance + x.balance;
          } else {
            toPay.push({ currencyCode: cc, balance: x.balance || 0 });
          }
        } else if (x.balanceType == "collect") {
          const ccPayment = toCollect.find((i) => i.currencyCode == cc);
          if (ccPayment) {
            if (x.balance) ccPayment.balance = ccPayment.balance + x.balance;
          } else {
            toCollect.push({ currencyCode: cc, balance: x.balance || 0 });
          }
        }
      });

      this.payPayments = toPay;
      this.collectPayments = toCollect;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      if (!this.editedItem.callingCode) {
        this.editedItem.callingCode = this.$store.state.activeStore.country.callingCode;
      }

      if (!this.editedItem.isShippingSameAsBilling) {
        this.editedItem.isShippingSameAsBilling = false;
      }

      if (!this.editedItem.currencyCode) {
        this.editedItem.currencyCode = this.country.currencyCode;
      }

      this.$store.state.supplierModal = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteSupplier(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchSuppliers();
        })
        .catch(() => {
          this.confirmDialog = this.processing = false;
        });
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save();
    },

    save() {
      if (!this.editedItem._id) return;
      this.editedItem.processing = true;
      Store.snackbarStart();

      const object = Object.assign({}, this.editedItem);
      return Store.editSupplier(object._id, object, false)
        .then(() => {
          this.editedItem.processing = false;
          this.fetchSuppliers();
          Store.snackbarSuccess();
        })
        .catch(() => {
          Store.snackbarError();
          this.editedItem.processing = false;
        });
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    amountFormatted(item) {
      return this.currencyPipe(
        item.balance,
        getSymbolFromCurrency(item.currencyCode || "INR")
      );
    },

    currencyPipe(amount, symbol) {
      return this.$options.filters.currency(amount, symbol);
    },
  },

  computed: {
    covidCategory() {
      return Store.covidCategory(this.$store);
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },

    store() {
      return this.$store.state.activeStore;
    },

    country() {
      return this.$store.state.country;
    },
  },

  watch: {
    supplierModified(n) {
      if (n) {
        this.fetchSuppliers();
        this.supplierModified = false;
      }
    },
  },

  data: () => {
    return {
      _id: "",
      suppliers: [],
      search: "",
      loading: true,
      dialog: false,
      processing: false,

      error: "",
      payPayments: [],
      collectPayments: [],

      supplierModified: false,
      editedItem: {
        isActive: true,
      },
      defaultItem: {
        isActive: true,
        isShippingSameAsBilling: true,
        balanceType: "collect",
        currencyCode: "",
      },

      beingDeleted: {},
      confirmDialog: false,
      rowItems: config.rowItems,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Name", value: "name" },
        { text: "Contact", value: "mobile" },
        { text: "Firm / Company", value: "legalName" },
        { text: "Opening Balance", value: "balance" },
        { text: "Status", value: "statusd" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    };
  },
};

import Store from "../../services/store";

import FileUpload from "../../services/file-upload";
import config from "../../config";
import moment from "moment";

export default {
  name: "Invoices",

  created() {
    if (!this.canProceed) return Store.redirectToHome();

    this.fetchInvoices();
    this.fetchStores();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchInvoices() {
      this.processing = true;
      return Store.getAllInvoices()
        .then((x) => {
          this.invoices = x.data.map((x, index) => {
            x.index = index + 1 + ".";
            x.createdByName =
              x.createdBy && x.createdBy.name ? x.createdBy.name : "-";
            x.businessName = x.store && x.store.name ? x.store.name : "-";
            if (x.invoiceDate)
              x.invoiceDate = this.formatDate(x.invoiceDate, "YYYY-MM-DD");
            return x;
          });
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    fetchStores() {
      return Store.getAllActiveStores()
        .then((res) => (this.stores = res.data))
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.invoiceModal = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteInvoice(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchInvoices();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.invoiceModal = false;
    },

    getRef() {
      return this.$refs.inputUpload;
    },

    pickFile() {
      this.getRef().click();
    },

    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    isValidDocument(file) {
      if (!file) return false;
      const type = file["type"].split("/")[0];
      const type2 = file["type"].split("/")[1];
      if (
        file &&
        (this.supportedFormats.includes(type) ||
          this.supportedFormats.includes(type2))
      ) {
        this.fileObject = {
          name: file["name"],
          size: file["size"],
          type: this.supportedFormats.includes(type) ? type : type2,
        };
        return true;
      }
      return false;
    },

    getURL(data) {
      if (!data) return data;
      const ext = data.split(".")[1];
      if (data && (this.supportedFormats.includes(ext) || ext == "png"))
        return `https://${this.bucketID}.s3.amazonaws.com/${data}`;
      return data;
    },

    invalidDocError() {
      this.$store.state.snackbar.text = "Unsupported File Format";
      this.$store.state.snackbar.color = "error";
      this.$store.state.snackbar.show = true;
    },

    fileSelected() {
      const file = this.getRef().files[0];
      if (!this.isValidDocument(file)) return this.invalidDocError();

      this.fileObject._id = "";
      this.fileObject._id = URL.createObjectURL(file);

      this.uploading = true;
      return FileUpload.upload(
        "growcify",
        "invoices",
        file,
        this.fileObject.type
      )
        .then((x) => {
          this.fileObject._id = x.data.file;
          this.editedItem.file = this.fileObject;
          this.uploading = false;
          if (this.editedItem._id) this.save(false);
        })
        .catch((e) => {
          console.log(e);
          this.uploading = false;
        });
    },

    openDocument(item) {
      if (!item.isAutomated)
        return window.open(this.getURL(item.file._id), "_blank");

      const token = this.$store.state.token;
      const baseURL =
        process.env.NODE_ENV !== "production" ? config.apiDev : config.apiProd;
      const endpoint = `${baseURL}invoice/${item._id}/download`;

      window.open(
        endpoint + "?x-request-user-agent=application/admin-app&token=" + token,
        "_blank"
      );
    },

    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      Store.snackbarStart();
      this.processing = true;
      let request;

      if (this.editedItem._id)
        request = Store.editInvoice(
          this.editedItem._id,
          this.editedItem,
          false
        );
      else request = Store.createInvoice(this.editedItem, false);

      return request
        .then(() => {
          this.processing = false;
          if (closeModal) this.close();
          Store.snackbarSuccess();
          this.fetchInvoices();
        })
        .catch(() => {
          this.processing = false;
          Store.snackbarError();
        });
    },

    formatDate(date, format = "lll") {
      return moment(date).format(format);
    },

    downloadInvoices() {
      const _ids = this.selectedInvoices
        .filter((x) => x.isAutomated)
        .map((x) => x._id)
        .join(",");
      this.selectedInvoices = [];
      return Store.downloadInvoices("growcify", _ids, this.$store.state.token);
    },
  },

  computed: {
    formTitle() {
      return !this.editedItem._id ? "Create new invoice" : "Edit invoice";
    },

    canProceed() {
      return (
        (Store.amISuperadmin(this.$store) ||
          Store.amIGrowcifyEmployee(this.$store)) &&
        this.$store.state.isGD
      );
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    tc() {
      return this.isDT ? "text-white" : "text-black";
    },
  },

  data() {
    return {
      menu: false,
      valid: false,
      invoiceModal: false,
      loading: false,
      processing: false,

      search: "",
      invoices: [],
      stores: [],
      enums: {},

      selectedInvoices: [],

      rowItems: config.rowItems,
      bucketID: "growcify",

      editedItem: { file: {} },
      defaultItem: {
        file: {},
        qty: 1,
        igst: 0,
        cgst: 0,
        sgst: 0,
        discount: 0,
        status: "Paid",
        isAutomated: true,
        currencyCode: "INR",
      },

      confirmDialog: false,
      beingDeleted: {},

      supportedFormats: ["image", "pdf", "xls", "xsls", "doc", "docx"],
      fileObject: {},
      uploading: false,
      ploading: false,

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Title", value: "title" },
        { text: "Invoice ID", value: "invoiceID" },
        { text: "Invoice Date", value: "invoiceDate" },
        { text: "Amount", value: "amount" },
        { text: "Business", value: "businessName" },
        { text: "Status", value: "status" },
        //{ text: 'Created By', value: 'createdByName' },
        { text: "Date & Time", value: "createdAt" },
        { text: "Actions", value: "actions", align: "right", sortable: false },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        numberRule: [(v) => (!isNaN(v) && v >= 0) || "Enter a valid number"],
      },
    };
  },
};

import Vue from "vue";

export default {
  getUsers(storeId, pageNumber, pageSize, keyword, tabID, noProgress = true) {
    let endpoint = `user/list/customers/${tabID}/${storeId}/${pageNumber}/${pageSize}`;
    if (keyword) endpoint += `?keyword=${keyword}`;
    return Vue.axios.get(endpoint, { noProgress: noProgress });
  },

  getSelectableUsers(storeId, noProgress = true) {
    return Vue.axios.get(`user/list/customers/${storeId}/selectable`, {
      noProgress: noProgress,
    });
  },

  getRecentUsers(storeId, noProgress = true) {
    return Vue.axios.get(`user/list/customers/${storeId}/recent`, {
      noProgress: noProgress,
    });
  },

  getEmployees(storeId, noProgress = true) {
    return Vue.axios.get(`user/list/employees/${storeId}`, {
      noProgress: noProgress,
    });
  },

  exportCustomers(storeId, noProgress = true) {
    return Vue.axios.get(`user/customers/export/${storeId}`, {
      noProgress: noProgress,
    });
  },

  getUser(storeId, userId, noProgress = true) {
    return Vue.axios.get(`user/${storeId}/${userId}`, {
      noProgress: noProgress,
    });
  },

  getProfile(storeId, userId, noProgress = true) {
    return Vue.axios.get(`profile/${storeId}/${userId}`, {
      noProgress: noProgress,
    });
  },

  editProfile(storeId, userId, profile, noProgress = true) {
    return Vue.axios.put(`profile/${storeId}/${userId}`, profile, {
      noProgress: noProgress,
    });
  },

  addMainUser(user, noProgress = true) {
    return Vue.axios.post(`user`, user, { noProgress: noProgress });
  },

  editMainUser(userId, user, noProgress = true) {
    return Vue.axios.put(`user/${userId}`, user, { noProgress: noProgress });
  },

  deleteMainUser(uid) {
    return Vue.axios.delete(`user/${uid}`, { noProgress: false });
  },

  //store-based
  addUser(storeId, user, noProgress = true) {
    return Vue.axios.post(`user/${storeId}`, user, { noProgress: noProgress });
  },

  editUser(storeId, userId, user, noProgress = true) {
    return Vue.axios.put(`user/${storeId}/${userId}`, user, {
      noProgress: noProgress,
    });
  },

  deleteUser(storeId, userId, noProgress = true) {
    return Vue.axios.delete(`user/${storeId}/${userId}`, {
      noProgress: noProgress,
    });
  },

  processCSV(_id, url, noProgress = true) {
    return Vue.axios.post(
      `csv/users/${_id}`,
      { url: url },
      { noProgress: noProgress }
    );
  },

  processUsers(_id, products, noProgress = true) {
    return Vue.axios.post(`user/import/${_id}`, products, {
      noProgress: noProgress,
    });
  },

  //user-address
  addAddress(storeId, userId, address, noProgress = true) {
    return Vue.axios.post(`user/address/${userId}/${storeId}`, address, {
      noProgress: noProgress,
    });
  },

  editAddress(addressId, user, noProgress = true) {
    return Vue.axios.put(`user/address/${addressId}`, user, {
      noProgress: noProgress,
    });
  },

  deleteAddress(addressId, noProgress = true) {
    return Vue.axios.delete(`user/address/${addressId}`, {
      noProgress: noProgress,
    });
  },

  getAddresses(storeId, userId, noProgress = false) {
    return Vue.axios.get(`user/list/addresses/${userId}/${storeId}`, {
      noProgress: noProgress,
    });
  },

  massAddressUpdate(storeId, updates, noProgress = true) {
    return Vue.axios.put(`user/address/mass-update/${storeId}`, updates, {
      noProgress: noProgress,
    });
  },

  //memberships
  updateMembership(storeId, userId, membership, noProgress = false) {
    return Vue.axios.put(`user/membership/${storeId}/${userId}`, membership, {
      noProgress: noProgress,
    });
  },

  //wallet
  getWallet(storeId, userId, noProgress = false) {
    return Vue.axios.get(`wallet/${storeId}/${userId}`, {
      noProgress: noProgress,
    });
  },

  addWalletBalance(storeId, userId, balance, noProgress = false) {
    return Vue.axios.post(`wallet/add/${storeId}/${userId}`, balance, {
      noProgress: noProgress,
    });
  },

  getWalletHistory(storeId, walletId, noProgress = false) {
    return Vue.axios.get(`wallet/history/${storeId}/${walletId}`, {
      noProgress: noProgress,
    });
  },

  getOrders(storeId, userId, noProgress = false) {
    return Vue.axios.get(`user/list/orders/${storeId}/${userId}`, {
      noProgress: noProgress,
    });
  },

  getReferrals(storeId, userId, noProgress = false) {
    return Vue.axios.get(`user/list/referrals/${storeId}/${userId}`, {
      noProgress: noProgress,
    });
  },

  getWishlist(storeId, userId, noProgress = false) {
    return Vue.axios.get(`wishlist/list/all/${storeId}/${userId}`, {
      noProgress: noProgress,
    });
  },

  getRecentlyOrdered(storeId, userId, noProgress = false) {
    return Vue.axios.get(
      `product/list/recently-ordered/all/${storeId}/${userId}/1/50`,
      { noProgress: noProgress }
    );
  },

  getDevices(storeId, userId, noProgress = true) {
    return Vue.axios.get(`user/devices/${storeId}/${userId}`, {
      noProgress: noProgress,
    });
  },

  bulkDeviceDelete(storeId, body, noProgress = true) {
    return Vue.axios.post(`user/devices/bulk-delete/${storeId}`, body, {
      noProgress: noProgress,
    });
  },

  //superadmin
  getSuperadminUsers(pageNumber, pageSize = 10, keyword, noProgress = true) {
    if (keyword) {
      return Vue.axios.get(
        `user/all/${pageNumber}/${pageSize}?keyword=${keyword}`,
        { noProgress: noProgress }
      );
    }
    return Vue.axios.get(`user/all/${pageNumber}/${pageSize}`, {
      noProgress: noProgress,
    });
  },
};

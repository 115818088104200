import Store from "../../services/store";
import Global from "../../services/global";

import Util from "../../services/util";
import router from "../../router";
import * as $ from "jquery";

import config from "../../config";
import { getS3URL } from "../../helpers/misc";

export default {
  name: "Store",

  // sockets: {
  //   newOrder: function(data = {}) {
  //     const ns = this.$store.state.activeStore.notificationSettings;
  //     if (ns && ns.newOrder && ns.newOrder.notification) {
  //       this.$store.state.newOrderNotification = true;
  //       this.$store.state.socketEventData = data;
  //       this.notify();
  //     }
  //     if (ns && ns.newOrder && ns.newOrder.sound) this.playAudio();
  //   }
  // },

  created() {
    this.$store.state.planInfo = {}; //reset
    this.$store.state.activeStore = {}; //reset

    this._id = this.$route.params._id;
    //subscribe to the store room
    //this.$socket.emit('subscribe', this._id);
    this.initPusher();

    this.loading = this.$store.state.superloading = true;
    //to show banner
    this.getPlanInfo();

    const p1 = Store.getStore(this._id, false)
      .then((x) => {
        this.store = x.data;
        this.$store.state.activeStore = this.store;
        this.$store.state.country = this.store.country || {
          currencySymbol: "$",
          correncyCode: "USD",
        };
        if (this.store.country)
          this.$store.state.cs = this.store.country.currencySymbol;

        Store.setStoreAppearance();
        document.title = this.store.name;
        if (!this.amIAllowed("Dashboard")) {
          if (this.store.modules.length) {
            let firstRoute = "";
            this.modules.forEach((x) => {
              if (!firstRoute && this.store.modules.includes(x)) firstRoute = x;
            });
            router.push({
              name: `store-${firstRoute.toLowerCase()}`,
              _id: this._id,
            });
          } else if (Store.doIHaveTestingAccess(this.$store))
            router.push({ name: "store-releases", _id: this._id });
        }
        $(".notDashboard").removeClass("v-list-item--active");
        this.loading = this.$store.state.superloading = false;

        //refresh counts
        Util.refreshCounts(this._id);
      })
      .catch((e) => {
        console.log(e);
        this.$store.commit("logout");
        this.loading = this.$store.state.superloading = false;
      });

    return Promise.all([p1]);
  },

  methods: {
    initPusher() {
      const channelName = this._id;
      const eventName = "newOrder";

      const pusher = this.$store.state.pusher;
      let channel = pusher.channel(channelName);
      if (!channel) channel = pusher.subscribe(channelName);

      if (this.$store.state.pusherBusinessEvents.includes("store:" + eventName))
        return;
      this.$store.state.pusherBusinessEvents.push("store:" + eventName);

      channel.bind(eventName, (data) => {
        Util.refreshCounts(this._id);
        const ns = this.$store.state.activeStore.notificationSettings;
        if (ns && ns.newOrder && ns.newOrder.notification) {
          this.$store.state.newOrderNotification = true;
          this.$store.state.socketEventData = data;
          this.notify();
        }
        if (ns && ns.newOrder && ns.newOrder.sound) this.playAudio();
      });
    },

    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    getPlanInfo() {
      return Store.getRetnalStatus(this._id, false)
        .then((x) => (this.$store.state.planInfo = x.data))
        .catch((e) => console.log(e));
    },

    processWAModal() {
      const ac = this.$store.state.activeStore;
      if (
        !ac.amIOwner ||
        ac.isVendor ||
        (ac.whatsAppSettings && ac.whatsAppSettings.accID)
      )
        return;

      if (localStorage["cwam"]) return;
      setTimeout(() => (this.$store.state.waesyapiModal = true), 5000);
    },

    playAudio() {
      let audioFile = config.newOrderSound;
      const ns = this.$store.state.activeStore.notificationSettings;
      if (ns.newOrder && ns.newOrder.file) {
        audioFile = getS3URL(
          this.$store.state.activeStore._id,
          ns.newOrder.file
        );
      }

      console.log(audioFile);

      new Audio(audioFile).play();
    },

    notify() {
      this.$notification.show("You've received a new order! ✌️", {}, {});
    },

    manageRedirection(to) {
      router.push("/business/" + this._id + "/" + to);
      if (!this.$store.state.activeStore.paid)
        return Store.snackbarAccessDenied();
    },

    // process AMC
    payAMC() {
      console.log(this.amcObject);
      const options = Global.getRazOptions(
        this.amcObject.amount,
        "AMC Transaction",
        this.amcObject.currencyCode
      );
      const newthis = this;
      options.handler = function(response) {
        newthis.processPayment(response.razorpay_payment_id);
      };
      new window.Razorpay(options).open();
    },

    processPayment(paymentId) {
      if (!paymentId) return;
      const object = {
        transactionId: "",
        paymentId: paymentId,
        amount: this.amcObject.amount,
      };

      this.processing = true;
      const _id = this._id;
      return Store.processPayment(_id, object, false)
        .then(() => {
          this.getPlanInfo();
          this.processing = false;
        })
        .catch((e) => console.log(e));
    },
  },

  computed: {
    covidCategory() {
      return Store.covidCategory(this.$store);
    },

    amIAllowedAdmin() {
      return Store.doIHaveAdminAccess(this.$store);
    },

    doIHaveTestingAccess() {
      return Store.doIHaveTestingAccess(this.$store);
    },

    hideSideBar() {
      return [
        "store-products-import",
        "store-customers-import",
        "store-pos",
        "store-pos-page",
      ].includes(this.$route.name);
    },

    isFullpage() {
      return ["store-pos", "store-pos-page"].includes(this.$route.name);
    },

    planInfo() {
      return this.$store.state.planInfo;
    },

    lockStore() {
      return (
        ((this.planInfo.paid && this.planInfo.isFiveDaysPassed) ||
          this.planInfo.isBillingDue ||
          this.planInfo.isAMCDue) &&
        !["store-settings", "store-billing"].includes(this.$route.name)
      );
    },

    isSuperadmin() {
      return Store.amISuperadmin(this.$store);
    },

    amIGrowcifyEmployee() {
      return Store.amIGrowcifyEmployee(this.$store);
    },

    isMultivendor() {
      return this.$store.state.activeStore.isMultivendor;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isGD() {
      return this.$store.state.isGD;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    isOwner() {
      return this.$store.state.activeStore.whoAmI == "owner";
    },

    isWhitelabeled() {
      return !this.isOwner && this.$store.state.activeStore.isWhitelabeled;
    },

    amcObject() {
      return {
        amount: this.planInfo.amcAmount || 2,
        currencyCode: this.planInfo.amcCurrencyCode || "INR",
      };
    },
  },

  data() {
    return {
      loading: false,
      store: {},
      _id: "",

      counts: {},
      isMini: false,

      modules: [
        "Dashboard",
        "Orders",
        "Customers",
        "Notifications",
        "Categories",
        "Products",
        "Coupons",
        "Deals",
        "Employees",
        "Locations",
        "Transactions",
        "Delivery Slots",
        "Banners",
        "Vendors",
        "Requests",
      ],
    };
  },
};

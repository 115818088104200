import config from "../config";

const gcfyBucket = config.growcifyProjectsS3Bucket;
const growcifyS3 = "https://" + gcfyBucket + ".s3.amazonaws.com/";

const storeS3 = (sid) => {
  return `https://${sid}.s3.amazonaws.com/`;
};

const getS3URL = (sid, data) => {
  if (data && data.includes(sid)) {
    return growcifyS3 + data;
  }
  return storeS3(sid) + data;
};

export { getS3URL };

import Store from "../../../services/store";
import moment from "moment";
import router from "../../../router";

import config from "../../../config";

export default {
  name: "Coupons",

  created() {
    this._id = this.$route.params._id;
    if (!this.amIAllowed("Coupons") || this.$store.state.activeStore.isVendor)
      return router.push("/business/" + this._id);
    if (!this.doIHaveAccess("actions"))
      this.headers = this.headers.filter((x) => x.text !== "Actions");

    this.fetchCoupons();
    this.fetchEnums();
    this.fetchCommonEnums();
    this.fetchVendors();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    fetchCoupons() {
      this.loading = true;
      return Store.getCoupons(this._id, false)
        .then((res) => {
          this.coupons = res.data.map((x, index) => {
            x.index = index + 1 + ".";
            x.status = x.isActive ? "Active" : "Inactive";
            x.anytime = x.isDeliveryFree ? "-" : x.isAlways ? "Yes" : "No";
            x.created = moment(x.createdAt).fromNow();
            x.applicableDates =
              !x.isAlways && x.dates && x.dates.length === 2
                ? moment(x.dates[0]).format("Do MMM YYYY") +
                  " - " +
                  moment(x.dates[1]).format("Do MMM YYYY")
                : "NA";
            return x;
          });
          this.loading = false;
        })
        .catch((e) => console.log(e));
    },

    fetchEnums() {
      return Store.getCouponEnums(this._id)
        .then((res) => (this.enums = res.data))
        .catch((e) => console.log(e));
    },

    fetchCommonEnums() {
      const ce = this.$store.state.commonEnums;
      if (ce) return (this.cenums = ce);

      return Store.getCommonEnums()
        .then((x) => {
          this.cenums = x.data;
          this.$store.state.commonEnums = x.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    editItem(item) {
      this.editedIndex = this.coupons.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Store.deleteCoupon(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchCoupons();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    save(closeModal = true, farceSave = false) {
      if (!farceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      const object = Object.assign({}, this.editedItem);
      object.code = object.code.toUpperCase();

      if (this.editedItem._id)
        request = Store.editCoupon(this.editedItem._id, object, false);
      else request = Store.createCoupon(this._id, object, false);

      return request
        .then(() => {
          if (closeModal) this.close();
          this.fetchCoupons();
          this.processing = this.editedItem.processing = false;
          Store.snackbarSuccess();
        })
        .catch((e) => {
          if (e.response.status === 401) {
            this.error = "Coupon code already exists";
            setTimeout(() => (this.error = ""), 2500);
          }
          this.processing = false;
          this.loading = false;
          Store.snackbarError();
        });
    },

    saveDates(dates) {
      this.$refs.menu.save(dates);
    },

    fetchVendors() {
      return Store.getVendors(this._id, false)
        .then((res) => (this.vendors = res.data))
        .catch((e) => console.log(e));
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add new discount"
        : "Edit discount coupon";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  data: () => {
    return {
      _id: "",
      coupons: [],
      enums: {},
      search: "",
      loading: true,
      valid: false,
      dialog: false,
      processing: false,

      rowItems: config.rowItems,

      error: "",

      menu: false,
      cenums: {},

      dates: [],

      editedIndex: -1,
      editedItem: {
        dates: [],
      },
      defaultItem: {
        dates: [],
        isActive: true,
        isPrivate: false,
        isDeliveryFree: false,
        forSubscriptions: false,
      },

      confirmDialog: false,
      beingDeleted: {},
      vendors: [],

      headers: [
        { text: "#", align: "start", value: "index" },
        { text: "Code", align: "start", value: "code" },
        { text: "Discount", value: "discount" },
        //{ text: 'Can apply anytime?', value: 'anytime'},
        { text: "Applicable Between", value: "applicableDates" },
        { text: "Status", value: "status" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],

      validationRules: {
        codeRule: [(v) => (v && v.length > 3) || "Code must be unique"],
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        datesRule: [(v) => v.length === 2 || "Both dates are required"],
        discountRule: [(v) => v >= 0 || "Enter valid discount"],
        orderAmountRule: [
          (v) => v === "" || !v || v >= 0 || "Enter valid amount",
        ],
      },
    };
  },
};

import Transaction from "../../../services/transaction";
import moment from "moment";
import _ from "underscore";
import getSymbolFromCurrency from "currency-symbol-map";

import Store from "../../../services/store";
import router from "../../../router";
import config from "../../../config";

export default {
  name: "Customers",

  created() {
    this._id = this.$route.params._id;
    if (
      !this.amIAllowed("Transactions") ||
      this.$store.state.activeStore.isVendor
    ) {
      return router.push("/business/" + this._id);
    }
    //if (!this.amISuperadmin) this.headers = this.headers.filter(x => x.text !== 'Actions');

    //set default columns
    const savedColumns = localStorage["transactionsColumns"];
    if (savedColumns) this.headers = JSON.parse(savedColumns);

    this.fetchTransactions();
    this.fetchTransactionsEnums();
  },

  methods: {
    amIAllowed(module) {
      return Store.amIAllowed(this.$store, module);
    },

    doIHaveAccess(access) {
      return Store.doIHaveAccess(this.$store, access);
    },

    getTabID(index) {
      return this.tabs[index]._id;
    },

    fetchTransactions(
      pageNumber = 1,
      itemsPerPage = this.transactionsObject.itemsPerPage,
      keyword = ""
    ) {
      this.loading = true;

      const tabID = this.getTabID(this.tab);
      return Transaction.getPaginatedTransactions(
        this._id,
        pageNumber,
        itemsPerPage,
        keyword,
        tabID,
        false
      )
        .then((res) => {
          this.transactionsObject = res.data;
          this.transactions = this.transactionsObject.items.map((x, index) => {
            x.index = index + 1 + ".";

            x.refID = "-";
            if (x.type == "order")
              x.refID = x.order && x.order._id ? x.order._id : x.order || "-";
            if (x.type == "subscription")
              x.refID =
                x.subscription && x.subscription._id
                  ? x.subscription._id
                  : x.subscription || "-";

            if (x.type == "order") {
              x.name =
                x.order && x.order.address && x.order.address.name
                  ? x.order.address.name
                  : "-";
              x.mobile =
                x.order && x.order.address && x.order.address.mobile
                  ? x.order.address.mobile
                  : "-";
            }

            x.ago = moment(x.createdAt).format("lll");
            x.amountd = x.amount || 0;
            x.paymentStatusd = x.paymentStatus || "-";
            x.remarksd = x.remarks || "-";
            x.paymentIdd = x.paymentId || "-";
            return x;
          });
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchTransactionsEnums() {
      return Transaction.getEnums(this._id, false)
        .then((res) => (this.enums = res.data))
        .catch((e) => console.log(e));
    },

    editItem(item) {
      this.editedIndex = this.transactions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      setImmediate(() => (this.dialog = true), 100);
    },

    deleteItem(item) {
      this.confirmDialog = true;
      this.beingDeleted = item;
    },

    closeConfirm() {
      this.confirmDialog = false;
      this.beingDeleted = {};
    },

    finalDelete() {
      this.processing = true;
      return Transaction.deleteTransaction(this.beingDeleted._id, false)
        .then(() => {
          this.confirmDialog = this.processing = false;
          this.fetchTransactions();
        })
        .catch((e) => {
          this.confirmDialog = this.processing = false;
          console.log(e);
        });
    },

    close() {
      this.dialog = this.processing = this.loading = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    changeStatus(item) {
      this.editedItem = item;
      this.save(false, true);
    },

    save(closeModal = true, forceSave = false) {
      if (!forceSave && this.$refs.form && !this.$refs.form.validate()) return;
      this.processing = this.editedItem.processing = true;
      Store.snackbarStart();
      let request;

      if (this.editedItem._id)
        request = Transaction.editTransaction(
          this.editedItem._id,
          this.editedItem,
          false
        );
      else
        request = Transaction.createTransaction(
          this._id,
          this.editedItem,
          false
        );

      return request
        .then(() => {
          if (closeModal) this.close();
          this.processing = this.editedItem.processing = false;
          this.fetchTransactions();
          Store.snackbarSuccess();
        })
        .catch(() => {
          this.processing = this.loading = this.editedItem.processing = false;
          Store.snackbarError();
        });
    },

    columnsChanged() {
      const nh = [];
      this.availableHeaders.forEach((x) => {
        if (
          this.headers.find((i) => i.value == x.value) &&
          !nh.find((j) => j.value == x.value)
        )
          nh.push(x);
      });
      this.headers = nh;
    },

    saveColumns() {
      localStorage["transactionsColumns"] = JSON.stringify(this.headers);
      this.saveColumnsText = "Saved";
      setTimeout(() => {
        this.saveColumnsText = "Save";
        this.toggleColumnSelect = !this.toggleColumnSelect;
      }, 500);
    },

    resetColumns() {
      this.headers = this.defaultHeaders;
    },

    getSymbolFromCurrency(code) {
      return getSymbolFromCurrency(code);
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Create new transaction"
        : "Edit transaction";
    },

    amISuperadmin() {
      return Store.amISuperadmin(this.$store);
    },

    amIGrowcifyEmployee() {
      return Store.amIGrowcifyEmployee(this.$store);
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    canEdit() {
      return this.doIHaveAccess("edit");
    },
  },

  watch: {
    options: {
      handler(options) {
        if (!this.loading)
          this.fetchTransactions(
            options.page,
            options.itemsPerPage,
            this.search
          );
      },
      deep: true,
    },

    search: _.debounce(function(nv) {
      this.fetchTransactions(1, this.options.itemsPerPage, nv);
    }, 500),

    tab() {
      this.transactions = [];
      this.fetchTransactions();
    },
  },

  data() {
    const storeId = this.$route.params._id;
    return {
      _id: "",
      transactions: [],
      search: "",
      loading: true,

      dialog: false,
      processing: false,

      enums: {},

      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        isActive: true,
        store: storeId,
      },

      confirmDialog: false,
      beingDeleted: {},

      rowItems: config.rowItems,
      options: {},
      transactionsObject: {
        pageNumber: 0,
        items: [],
        totalItems: 0,
        totalPages: 0,
        itemsPerPage: 10,
      },

      headers: [
        { text: "#", value: "index", disabled: true },
        { text: "REF ID", value: "refID" },
        { text: "Type", value: "type" },
        { text: "Amount", value: "amountd" },
        { text: "Payment Status", value: "paymentStatusd" },
        { text: "Payment Method", value: "paymentMethod" },
        { text: "TXN", value: "txn" },
        { text: "Remark", value: "remarksd" },
        { text: "Date & Time", value: "ago" },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      saveColumnsText: "Save",
      toggleColumnSelect: false,
      defaultHeaders: [
        { text: "#", value: "index", disabled: true },
        { text: "REF ID", value: "refID" },
        { text: "Type", value: "type" },
        { text: "Amount", value: "amountd" },
        { text: "Payment Status", value: "paymentStatusd" },
        { text: "Payment Method", value: "paymentMethod" },
        { text: "TXN", value: "txn" },
        { text: "Remark", value: "remarksd" },
        { text: "Date & Time", value: "ago" },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      availableHeaders: [
        //{ text: 'Transaction ID', align: 'start', value: '_id' },
        { text: "#", value: "index", disabled: true },
        { text: "REF ID", value: "refID" },
        { text: "Type", value: "type" },
        { text: "Name", value: "name" },
        { text: "Mobile", value: "mobile" },
        { text: "Amount", value: "amountd" },
        { text: "Payment Status", value: "paymentStatusd" },
        { text: "Payment Method", value: "paymentMethod" },
        { text: "Online Payment Id", value: "paymentIdd" },
        { text: "Previous Wallet Balance", value: "previousWalletBalance" },
        { text: "TXN", value: "txn" },
        { text: "Remark", value: "remarksd" },
        { text: "Created By", value: "createdBy" },
        { text: "Modified By", value: "modifiedBy" },
        { text: "Date & Time", value: "ago" },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
          disabled: true,
        },
      ],

      tab: 0,
      tabs: [
        { _id: "all", title: "All Transactions" },
        { _id: "order", title: "Order Transactions" },
        { _id: "wallet", title: "Wallet Transactions" },
        { _id: "subscription", title: "Subscription Transactions" },
      ],

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        statusRule: [(v) => v || v === false || "It is required"],
        lengthRule: [(v) => (!!v && !!v.length) || "It is required"],
      },
    };
  },
};
